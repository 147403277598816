export const getSelectedPropertyGroup = (state) => {
  return state.propertyGroup && state.propertyGroup.selectedPropertyGroup
    ? state.propertyGroup.selectedPropertyGroup
    : false;
};

export const getSelectedPropertyGroupProperties = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupProperties &&
    state.propertyGroup.selectedPropertyGroupProperties.available
    ? state.propertyGroup.selectedPropertyGroupProperties.available
    : false;
};

export const getSelectedPropertyGroupPropertiesTotal = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupProperties &&
    state.propertyGroup.selectedPropertyGroupProperties.count
    ? state.propertyGroup.selectedPropertyGroupProperties.count
    : 0;
};
export const getSelectedPropertyGroupPropertiesIsLoading = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupProperties &&
    state.propertyGroup.selectedPropertyGroupProperties.isLoading
    ? state.propertyGroup.selectedPropertyGroupProperties.isLoading
    : false;
};

export const getSelectedPropertyGroupPagination = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupProperties &&
    state.propertyGroup.selectedPropertyGroupProperties.pagination
    ? state.propertyGroup.selectedPropertyGroupProperties.pagination
    : false;
};

export const getSelectedPropertyGroupWorkflows = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupWorkflows &&
    state.propertyGroup.selectedPropertyGroupWorkflows.available
    ? state.propertyGroup.selectedPropertyGroupWorkflows.available
    : false;
};
export const getSelectedPropertyGroupWorkflowsIsLoading = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupWorkflows &&
    state.propertyGroup.selectedPropertyGroupWorkflows.isLoading
    ? state.propertyGroup.selectedPropertyGroupWorkflows.isLoading
    : false;
};

export const getSelectedPropertyGroupUsers = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupUsers &&
    state.propertyGroup.selectedPropertyGroupUsers.available
    ? state.propertyGroup.selectedPropertyGroupUsers.available
    : false;
};

export const getSelectedPropertyGroupUsersTotal = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupUsers &&
    state.propertyGroup.selectedPropertyGroupUsers.count
    ? state.propertyGroup.selectedPropertyGroupUsers.count
    : false;
};

export const getSelectedPropertyGroupUsersIsLoading = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupUsers &&
    state.propertyGroup.selectedPropertyGroupUsers.isLoading
    ? state.propertyGroup.selectedPropertyGroupUsers.isLoading
    : false;
};

export const getSelectedGroupUsersPageNumber = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupUsersPagination &&
    state.propertyGroup.selectedPropertyGroupUsersPagination.page
    ? state.propertyGroup.selectedPropertyGroupUsersPagination.page
    : false;
};
export const getSelectedGroupUsersOffset = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupUsersPagination &&
    state.propertyGroup.selectedPropertyGroupUsersPagination.offset
    ? state.propertyGroup.selectedPropertyGroupUsersPagination.offset
    : false;
};
export const getSelectedGroupUsersSearch = (state) => {
  return state.propertyGroup &&
    state.propertyGroup.selectedPropertyGroupUsersPagination &&
    state.propertyGroup.selectedPropertyGroupUsersPagination.search
    ? state.propertyGroup.selectedPropertyGroupUsersPagination.search
    : false;
};

export const getPropertyGroupStatus = (state) => {
  return state.propertyGroup.status;
};
