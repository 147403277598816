import React, { Component } from "react";

export default class ProtectedRoute extends Component {
  componentDidMount() {
    this.setModalContent();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.hasPermission !== this.props.hasPermission)
      this.setModalContent();
  }

  hideModal = () => {
    const { hideModal, navigate } = this.props;
    navigate("../");
    hideModal();
  };

  setModalContent = () => {
    const { getLabel, setModalContent, showModal, hasPermission, hideModal } =
      this.props;
    if (hasPermission) return hideModal();
    setModalContent({
      showCloseButton: true,
      header: getLabel("ACCESS_DENIED_TITLE_LABEL"),
      body: getLabel("ACCESS_DENIED_BODY_LABEL"),
      buttons: [
        {
          buttonLabel: getLabel("CLOSE_MODAL_BUTTON_LABEL"),
          onClick: this.hideModal
        }
      ]
    });
    showModal();
  };
  render() {
    const { hasPermission, Component } = this.props;
    return <div>{hasPermission ? <Component {...this.props} /> : null}</div>;
  }
}
