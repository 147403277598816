import { connect } from "react-redux";
import UpdateWorkflow from "./UpdateWorkflow";
import * as actions from "../../../actions";
import {
  getLabels,
  getSelectedWorkflow,
  getSelectedWorkflowStatus,
  getAbortController
} from "../../../selectors";

export default connect(
  (state) => ({
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedWorkflow: getSelectedWorkflow(state),
    selectedWorkflowStatus: getSelectedWorkflowStatus(state),
    abortController: getAbortController(state)
  }),
  actions
)(UpdateWorkflow);
