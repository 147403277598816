import { createLogic } from "redux-logic";
import {
  LOAD_ROLE,
  LOAD_ROLE_WORKFLOWS,
  LOAD_ROLE_USERS,
  CREATE_ROLE,
  UPDATE_ROLE,
  UPDATE_ROLE_ADMIN,
  UPDATED_ROLE,
  ABORT_ERROR_NAME,
  REMOVE_ROLE
} from "@base/constants";
import { pollJob } from "../common";

const loadRoleLogic = createLogic({
  type: LOAD_ROLE,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedRole, setPageTitle } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));
      dispatch(setPageTitle(""));

      const token = getToken(state);
      const { orgId, roleId } = action.payload;

      const { requestRole } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const role = await requestRole(orgId, roleId, token, signal);
      dispatch(loadedRole(role));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadRoleWorkflowsLogic = createLogic({
  type: LOAD_ROLE_WORKFLOWS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedRoleWorkflows } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);

      const { orgId, roleId } = action.payload;
      const { requestRoleWorkflows } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const roleWorkflows = await requestRoleWorkflows(
        orgId,
        roleId,
        token,
        signal
      );
      dispatch(loadedRoleWorkflows(roleId, roleWorkflows));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError, loadRoleWorkflowsError } = globalActions;
      dispatch(setError(e));
      dispatch(loadRoleWorkflowsError());
      done();
    }
  }
});

const loadRoleUsersLogic = createLogic({
  type: LOAD_ROLE_USERS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedRoleUsers } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);

      const { orgId, roleId } = action.payload;
      const { requestRoleUsers } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const roleUsers = await requestRoleUsers(orgId, roleId, token, signal);
      dispatch(loadedRoleUsers(roleId, roleUsers));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const createRoleLogic = createLogic({
  type: CREATE_ROLE,
  async process(
    {
      globalActions,
      agBoxApiCreates,
      agBoxApiRequests,
      getState,
      selectors,
      action
    },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, createdRole } = globalActions;
      const { CREATING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(CREATING_LABEL));

      const token = getToken(state);
      const { orgId, data } = action.payload;
      const { createRole } = agBoxApiCreates;
      const { requestCreateRoleStatus } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const role = await createRole(orgId, data, token, signal);
      const { roleId, jobId } = role;
      await pollJob(
        requestCreateRoleStatus,
        orgId,
        roleId,
        jobId,
        token,
        signal
      );
      dispatch(createdRole(role));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const updateRoleLogic = createLogic({
  type: UPDATE_ROLE,
  async process(
    { globalActions, agBoxApiUpdates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, updatedRole } = globalActions;
      const { UPDATING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(UPDATING_LABEL));

      const token = getToken(state);
      const { orgId, roleId, data } = action.payload;
      const { updateRole } = agBoxApiUpdates;
      const signal = getAbortSignal(getState());
      await updateRole(orgId, roleId, data, token, signal);
      dispatch(updatedRole(orgId, roleId));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});
const updateRoleAdminLogic = createLogic({
  type: UPDATE_ROLE_ADMIN,
  async process(
    { globalActions, agBoxApiUpdates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, updatedRole } = globalActions;
      const { UPDATING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(UPDATING_LABEL));

      const token = getToken(state);
      const { orgId, roleId, data } = action.payload;
      const { updateRoleAdmin } = agBoxApiUpdates;
      const signal = getAbortSignal(getState());
      await updateRoleAdmin(orgId, roleId, data, token, signal);
      dispatch(updatedRole(orgId, roleId));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const updatedRoleLogic = createLogic({
  type: UPDATED_ROLE,
  async process(
    { globalActions, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const { getLabels } = selectors;
      const { setLoadingText, loadRole, loadRoleWorkflows, loadRoleUsers } =
        globalActions;
      const { UPDATING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(UPDATING_LABEL));

      const { orgId, roleId } = action.payload;
      dispatch(loadRole(orgId, roleId));
      dispatch(loadRoleWorkflows(orgId, roleId));
      dispatch(loadRoleUsers(orgId, roleId));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const removeRoleLogic = createLogic({
  type: REMOVE_ROLE,
  async process(
    { globalActions, agBoxApiRemoves, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;

      if (!action.payload) {
        const { PAYLOAD_IS_NULL_ERROR } = getLabels(getState());
        throw PAYLOAD_IS_NULL_ERROR;
      }

      const { removedRole } = globalActions;
      const token = getToken(state);
      const { roleId, orgId, newRoleId } = action.payload;
      const { removeRole, requestRemoveRoleStatus } = agBoxApiRemoves;
      const signal = getAbortSignal(getState());
      const { jobId } = await removeRole(
        orgId,
        roleId,
        newRoleId,
        token,
        signal
      );
      await pollJob(
        requestRemoveRoleStatus,
        orgId,
        roleId,
        jobId,
        token,
        signal
      );
      dispatch(removedRole());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

export default [
  loadRoleLogic,
  loadRoleWorkflowsLogic,
  loadRoleUsersLogic,
  createRoleLogic,
  updateRoleLogic,
  updateRoleAdminLogic,
  updatedRoleLogic,
  removeRoleLogic
];
