export default (API_URL, API_KEY) => ({
  removeWorkflow: (orgId, workflowId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/workflows/${workflowId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "DELETE",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  removeProperty: (orgId, propId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/properties/${propId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "DELETE",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  archiveProperty: (orgId, propId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/properties/${propId}/organisations/${orgId}/archive`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "DELETE",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  requestRemovePropertyStatus: (orgId, propId, jobId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/properties/${propId}/organisations/${orgId}/jobs/${jobId}/remove`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          method: "GET",
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  removeLandkindUser: (userId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/users/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "DELETE",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  removeUserMembership: (orgId, userId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/users/${userId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "DELETE",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  removePropertyGroup: (orgId, groupId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/propertyGroups/${groupId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "DELETE",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  requestRemovePropertyGroupStatus: (orgId, groupId, jobId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/propertyGroups/${groupId}/organisations/${orgId}/jobs/${jobId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          method: "GET",
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  removeOrganisation: (orgId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "DELETE",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  removeInvite: (orgId, inviteId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/invites/${inviteId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "DELETE",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  removeRole: (orgId, roleId, newRoleId, token, signal) => {
    return new Promise((resolve, reject) => {
      const url = newRoleId
        ? `${API_URL}/roles/${roleId}/organisations/${orgId}?newRoleId=${newRoleId}`
        : `${API_URL}/roles/${roleId}/organisations/${orgId}?useCache=true`;
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "DELETE",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  requestRemoveRoleStatus: (orgId, roleId, jobId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/roles/${roleId}/organisations/${orgId}/jobs/${jobId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "GET",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  }
});
