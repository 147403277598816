import React, { Component } from "react";
import { GlobalStyle, defaultTheme, Modal } from "@UIKit";
import { ThemeProvider } from "styled-components";
import { Router } from "@reach/router";
import Main from "./Main";
import Login from "./Login";
import AddOrganisation from "./Organisations/AddOrganisation";
import OrganisationOverview from "./Organisations/OrganisationOverview";
import OrganisationDetail from "./Organisations/OrganisationDetail";
import PropertyGroupOverview from "./PropertyGroups/PropertyGroupOverview";
import PropertyGroupDetail from "./PropertyGroups/PropertyGroupDetail";
import PropertyOverview from "./Properties/PropertyOverview";
import AddProperty from "./Properties/AddProperty";
import PropertyDetail from "./Properties/PropertyDetail";
import RoleOverview from "./Roles/RoleOverview";
import AddRole from "./Roles/AddRole";
import RoleDetail from "./Roles/RoleDetail";
import RendererOverview from "./Renderers/RendererOverview";
import UserOverview from "./Users/UserOverview";
import InviteUserToOrganisation from "./Users/InviteUserToOrganisation";
import UserDetail from "./Users/UserDetail";
import WorkflowOverview from "./Workflows/WorkflowOverview";
import AddWorkflow from "./Workflows/AddWorkflow";
import WorkflowDetail from "./Workflows/WorkflowDetail";
import UpdateWorkflow from "./Workflows/UpdateWorkflow";
import UpdateOrganisation from "./Organisations/UpdateOrganisation";
import UpdateRole from "./Roles/UpdateRole";
import UpdateUser from "./Users/UpdateUser";
import AddPropertyGroup from "./PropertyGroups/AddPropertyGroup";
import UpdatePropertyGroup from "./PropertyGroups/UpdatePropertyGroup";
import UpdateProperty from "./Properties/UpdateProperty";
import InvitedUserDetails from "./Users/InvitedUserDetails";
import ArchivedPropertyDetail from "./Properties/ArchivedPropertyDetail";
import UpdateUserInvite from "./Users/UpdateUserInvite";
import Logout from "./Logout";
import ProtectedRoute from "./ProtectedRoute";
import AccessDenied from "./AccessDenied";
import SuperHumanUsersDetail from "./Users/SuperHumanUsersDetail";
import UpdateSuperHumanUsersDetail from "./Users/UpdateSuperHumanUsersDetail";
import RendererDetail from "./Renderers/RendererDetail";
import AddRenderer from "./Renderers/AddRenderer";
import UpdateRenderer from "./Renderers/UpdateRenderer";
import ImportDatasets from "./Properties/ImportDatasets";
import DatasetOverview from "./Datasets/DatasetOverview";
import DatasetDetail from "./Datasets/DatasetDetail";
import AddDatasets from "./Datasets/AddDatasets";

import {
  HOME_URL,
  PROPERTY_GROUPS_URL,
  PROPERTIES_URL,
  ROLES_URL,
  RENDERERS_URL,
  USERS_URL,
  WORKFLOWS_URL,
  NEW_URL,
  UPDATE_URL,
  UPDATE_ORG_URL,
  UPDATE_USER_URL,
  IMPORT_DATASETS_URL,
  DATASETS_URL,
  INVITES_TEXT,
  ARCHIVED_SLUG,
  INVITE_ID_SLUG,
  ORG_ID_SLUG,
  USER_ID_SLUG,
  WORKFLOW_ID_SLUG,
  ROLE_ID_SLUG,
  GROUP_ID_SLUG,
  PROP_ID_SLUG,
  DATASET_ID_SLUG,
  CREATE_PERMISSION,
  READ_PERMISSION,
  UPDATE_PERMISSION,
  SUPER_HUMAN_PERMISSION,
  INVITES_SECTION,
  ORGANISATIONS_SECTION,
  PROPERTIES_SECTION,
  PROPERTY_GROUPS_SECTION,
  ROLES_SECTION,
  RENDERERS_SECTION,
  USERS_SECTION,
  WORKFLOWS_SECTION,
  READ_DETAILS_PERMISSION,
  UPDATE_USER_INVITE_URL,
  RENDERER_ID_SLUG,
  DATASETS_SECTION
} from "@base/constants";
import { ScrollToTop } from "./ScrollToTop";

class App extends Component {
  componentDidMount = () => {
    this.setAbortController();
  };

  setAbortController = () => {
    const { setAbortController } = this.props;
    const controller = new AbortController();
    setAbortController(controller);
  };
  getAppLoading = () => {
    const { appLoading } = this.props;
    return appLoading ? appLoading : false;
  };

  getIsAuthenticated = () => {
    const { isAuthenticated } = this.props;
    return isAuthenticated ? isAuthenticated : false;
  };

  getLoadingText = () => {
    const { loadingText, getLabel } = this.props;
    const { LOADING_LABEL } = getLabel();

    return loadingText ? loadingText : LOADING_LABEL;
  };

  getModalVisibility = () => {
    const { modalVisibility } = this.props;
    return modalVisibility;
  };

  getModalHeader = () => {
    const { modalHeader } = this.props;
    return modalHeader;
  };

  getModalBody = () => {
    const { modalBody } = this.props;
    return modalBody;
  };

  getModalButtons = () => {
    const { modalButtons } = this.props;
    return modalButtons;
  };

  getShowCloseButton = () => {
    const { modalshowClose } = this.props;
    return modalshowClose;
  };

  hideModal = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  isLoggingOut = () => {
    const { loggingOut } = this.props;
    return loggingOut;
  };

  getOrgId = () => {
    const splitUrl = window.location.pathname.split("/");
    if (splitUrl.length > 2) return splitUrl[2];
    return "";
  };

  hasPermission = (section, permissionType) => {
    const { getHasPermission } = this.props;
    if (!permissionType) return true;
    return getHasPermission(this.getOrgId(), section, permissionType);
  };

  getProtectedRoute = ({ Component, path, section, permissionType }) => {
    return (
      <ProtectedRoute
        path={path}
        hasPermission={this.hasPermission(section, permissionType)}
        Component={Component}
      />
    );
  };

  getSections = () => {
    return [
      {
        section: ORGANISATIONS_SECTION,
        views: [
          {
            Component: OrganisationOverview,
            path: HOME_URL
          },
          {
            Component: AddOrganisation,
            path: NEW_URL,
            permissionType: CREATE_PERMISSION
          },
          {
            Component: OrganisationDetail,
            path: `${ORG_ID_SLUG}/*`
          },
          {
            Component: UpdateOrganisation,
            path: `${ORG_ID_SLUG}/${UPDATE_ORG_URL}`,
            permissionType: UPDATE_PERMISSION
          },
          {
            Component: SuperHumanUsersDetail,
            path: `/${USERS_URL}/${USER_ID_SLUG}`,
            permissionType: SUPER_HUMAN_PERMISSION
          },
          {
            Component: UpdateSuperHumanUsersDetail,
            path: `/${USERS_URL}/${USER_ID_SLUG}/${UPDATE_USER_URL}`,
            permissionType: SUPER_HUMAN_PERMISSION
          }
        ]
      },
      {
        section: PROPERTY_GROUPS_SECTION,
        views: [
          {
            Component: PropertyGroupOverview,
            path: `${ORG_ID_SLUG}/${PROPERTY_GROUPS_URL}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: PropertyGroupDetail,
            path: `${ORG_ID_SLUG}/${PROPERTY_GROUPS_URL}/${GROUP_ID_SLUG}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: AddPropertyGroup,
            path: `${ORG_ID_SLUG}/${PROPERTY_GROUPS_URL}/${NEW_URL}`,
            permissionType: CREATE_PERMISSION
          },
          {
            Component: UpdatePropertyGroup,
            path: `${ORG_ID_SLUG}/${PROPERTY_GROUPS_URL}/${GROUP_ID_SLUG}/${UPDATE_URL}`,
            permissionType: UPDATE_PERMISSION
          }
        ]
      },
      {
        section: PROPERTIES_SECTION,
        views: [
          {
            Component: PropertyOverview,
            path: `${ORG_ID_SLUG}/${PROPERTIES_URL}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: AddProperty,
            path: `${ORG_ID_SLUG}/${PROPERTIES_URL}/${NEW_URL}`,
            permissionType: CREATE_PERMISSION
          },
          {
            Component: PropertyDetail,
            path: `${ORG_ID_SLUG}/${PROPERTIES_URL}/${PROP_ID_SLUG}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: ArchivedPropertyDetail,
            path: `${ORG_ID_SLUG}/${PROPERTIES_URL}/${PROP_ID_SLUG}/${ARCHIVED_SLUG}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: UpdateProperty,
            path: `${ORG_ID_SLUG}/${PROPERTIES_URL}/${PROP_ID_SLUG}/${UPDATE_URL}`,
            permissionType: UPDATE_PERMISSION
          },
          {
            Component: ImportDatasets,
            path: `${ORG_ID_SLUG}/${PROPERTIES_URL}/${PROP_ID_SLUG}/${IMPORT_DATASETS_URL}`,
            permissionType: UPDATE_PERMISSION
          }
        ]
      },
      {
        section: ROLES_SECTION,
        views: [
          {
            Component: RoleOverview,
            path: `${ORG_ID_SLUG}/${ROLES_URL}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: AddRole,
            path: `${ORG_ID_SLUG}/${ROLES_URL}/${NEW_URL}`,
            permissionType: CREATE_PERMISSION
          },
          {
            Component: RoleDetail,
            path: `${ORG_ID_SLUG}/${ROLES_URL}/${ROLE_ID_SLUG}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: UpdateRole,
            path: `${ORG_ID_SLUG}/${ROLES_URL}/${ROLE_ID_SLUG}/${UPDATE_URL}`,
            permissionType: UPDATE_PERMISSION
          }
        ]
      },
      {
        section: WORKFLOWS_SECTION,
        views: [
          {
            Component: WorkflowOverview,
            path: `${ORG_ID_SLUG}/${WORKFLOWS_URL}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: AddWorkflow,
            path: `${ORG_ID_SLUG}/${WORKFLOWS_URL}/${NEW_URL}`,
            permissionType: CREATE_PERMISSION
          },
          {
            Component: WorkflowDetail,
            path: `${ORG_ID_SLUG}/${WORKFLOWS_URL}/${WORKFLOW_ID_SLUG}`,
            permissionType: READ_DETAILS_PERMISSION
          },
          {
            Component: UpdateWorkflow,
            path: `${ORG_ID_SLUG}/${WORKFLOWS_URL}/${WORKFLOW_ID_SLUG}/${UPDATE_URL}`,
            permissionType: UPDATE_PERMISSION
          }
        ]
      },
      {
        section: USERS_SECTION,
        views: [
          {
            Component: UserOverview,
            path: `${ORG_ID_SLUG}/${USERS_URL}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: UserDetail,
            path: `${ORG_ID_SLUG}/${USERS_URL}/${USER_ID_SLUG}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: UpdateUser,
            path: `${ORG_ID_SLUG}/${USERS_URL}/${USER_ID_SLUG}/${UPDATE_USER_URL}`,
            permissionType: UPDATE_PERMISSION
          }
        ]
      },
      {
        section: INVITES_SECTION,
        views: [
          {
            Component: InviteUserToOrganisation,
            path: `${ORG_ID_SLUG}/${USERS_URL}/${NEW_URL}`,
            permissionType: CREATE_PERMISSION
          },
          {
            Component: InvitedUserDetails,
            path: `${ORG_ID_SLUG}/${USERS_URL}/${INVITES_TEXT}/${INVITE_ID_SLUG}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: UpdateUserInvite,
            path: `${ORG_ID_SLUG}/${USERS_URL}/${INVITE_ID_SLUG}/${UPDATE_USER_INVITE_URL}`,
            permissionType: UPDATE_PERMISSION
          }
        ]
      },
      {
        section: RENDERERS_SECTION,
        views: [
          {
            Component: RendererOverview,
            path: `${ORG_ID_SLUG}/${RENDERERS_URL}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: AddRenderer,
            path: `${ORG_ID_SLUG}/${RENDERERS_URL}/${NEW_URL}`,
            permissionType: CREATE_PERMISSION
          },
          {
            Component: RendererDetail,
            path: `${ORG_ID_SLUG}/${RENDERERS_URL}/${RENDERER_ID_SLUG}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: UpdateRenderer,
            path: `${ORG_ID_SLUG}/${RENDERERS_URL}/${RENDERER_ID_SLUG}/${UPDATE_URL}`,
            permissionType: UPDATE_PERMISSION
          }
        ]
      },
      {
        section: DATASETS_SECTION,
        views: [
          {
            Component: DatasetOverview,
            path: `${ORG_ID_SLUG}/${DATASETS_URL}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: DatasetDetail,
            path: `${ORG_ID_SLUG}/${DATASETS_URL}/${DATASET_ID_SLUG}`,
            permissionType: READ_PERMISSION
          },
          {
            Component: AddDatasets,
            path: `${ORG_ID_SLUG}/${DATASETS_URL}/${NEW_URL}`,
            permissionType: CREATE_PERMISSION
          }
        ]
      }
    ];
  };

  getViews = () => {
    const sections = this.getSections();

    return [].concat(
      sections.map(({ section, views }) =>
        views.map((view) => this.getProtectedRoute({ ...view, section }))
      )
    );
  };

  getShowAccessDenied = () => {
    const { user, orgsLoading, availableOrgs } = this.props;
    if ((user && user.superHuman) || orgsLoading || !availableOrgs)
      return false;
    return availableOrgs.length ? false : true;
  };

  getShowLogin = () => {
    return (
      (!this.getIsAuthenticated() && !this.isLoggingOut()) ||
      this.getAppLoading()
    );
  };

  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <Modal
          visible={this.getModalVisibility()}
          headerContent={this.getModalHeader()}
          bodyContent={this.getModalBody()}
          buttons={this.getModalButtons()}
          onCloseClick={this.hideModal}
          showCloseButton={this.getShowCloseButton()}
        />
        <Router>
          {this.getShowLogin() ? (
            <Login default data-name={"Login"} />
          ) : this.getShowAccessDenied() ? (
            <AccessDenied default />
          ) : (
            <Main path={HOME_URL} default>
              <ScrollToTop path="/">{this.getViews()}</ScrollToTop>
            </Main>
          )}
          <Logout path={"logout"} data-name={"Logout"} />
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;

// export default process.env.REACT_APP_HOT == true ? hot(App) : App
