import React, { Component } from "react";
import { Loader, ContextHeader, BottomButtonBar, DataTable } from "@UIKit";
import { Heading3, P } from "@typography";
import { Section, Container, Wrapper, LinkButton } from "@core";
import {
  STATUS_LOADING,
  STATUS_UPDATING,
  STATUS_READY,
  PROPERTIES_URL,
  PROPERTY_GROUPS_URL,
  USERS_URL,
  CONTENT_VIEW,
  UPDATE_USER_URL,
  CONTEXT_HEADER_CONTAINER,
  STATUS_REMOVING,
  BUTTON_TYPE_SECONDARY,
  USERS_SECTION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
  PROPERTIES_SECTION,
  PROPERTY_GROUPS_SECTION,
  READ_PERMISSION,
  SECTION_HEADER_STYLING,
  HEIGHT_AUTO_TEXT,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  PROPERTY_GROUP_TEXT,
  PROPERTY_TEXT,
  READ_DETAILS_PERMISSION,
  ORGANISATION_URL
} from "@base/constants";
import { navigate } from "@reach/router";
import moment from "moment";
import {
  filterList,
  getStatusColorByKey,
  getStatusLabelConstByKey,
  getUserStatusKey
} from "@base/common";

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyFilterText: "",
      propertyGroupFilterText: ""
    };
  }

  componentDidMount() {
    this.setupUserDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps) {
    const {
      selectedUserMembership,
      setPageTitle,
      pageTitle,
      selectedUserStatus
    } = this.props;

    if (
      selectedUserMembership &&
      prevProps.selectedUserStatus === STATUS_LOADING &&
      selectedUserStatus === STATUS_READY
    ) {
      if (pageTitle !== selectedUserMembership.name)
        setPageTitle(selectedUserMembership.name);
    }

    if (
      selectedUserStatus === STATUS_READY &&
      prevProps.selectedUserStatus === STATUS_REMOVING
    ) {
      this.showSuccess();
    }

    if (
      prevProps.selectedUserStatus === STATUS_UPDATING &&
      selectedUserStatus === STATUS_READY
    ) {
      this.showUpdateSuccess();
    }
  }

  setupUserDetails = () => {
    const { orgId, userId, loadUserMembership } = this.props;
    loadUserMembership(orgId, userId);
  };

  getCurrentUserMembership = () => {
    const { selectedUserMembership } = this.props;
    return selectedUserMembership ? selectedUserMembership : false;
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    const user = this.getCurrentUserMembership();
    if (!user || (!user.firstName && !user.lastName))
      return getLabel("USERS_TEXT");
    return getLabel("USER_HEADING_TEXT", {
      name: `${user.firstName} ${user.lastName}`
    });
  };

  createSecondaryText = () => {
    const { getLabel } = this.props;
    return getLabel("DETAILS_LABEL", { item: getLabel("USER_TEXT") });
  };

  getUserMembershipStatus = () => {
    const { getLabel, selectedPropertyLoading } = this.props;
    if (selectedPropertyLoading) return getLabel("LOADING_LABEL");
    if (!this.getCurrentUserMembership()) return;

    const userDetails = this.getCurrentUserMembership();
    const status =
      userDetails.active === true
        ? getLabel("ACTIVE_LABEL")
        : getLabel("INACTIVE_LABEL");
    if (userDetails && status) {
      return (
        <P styleType={"descriptionText"} key={status}>
          {getLabel("MEMBERSHIP_STATUS_LABEL")}: {status}
        </P>
      );
    }
    return null;
  };

  getUserRole = () => {
    const { getLabel, selectedPropertyLoading } = this.props;
    if (selectedPropertyLoading) return getLabel("LOADING_LABEL");
    if (!this.getCurrentUserMembership()) return;
    const userDetails = this.getCurrentUserMembership();
    const { title } = userDetails;
    if (title) {
      return (
        <P styleType={"descriptionText"} key={title}>
          {"Role"}: {title}
        </P>
      );
    }
    return null;
  };

  getUserEmail = () => {
    const { getLabel, selectedPropertyLoading } = this.props;
    if (selectedPropertyLoading) return getLabel("LOADING_LABEL");
    const userDetails = this.getCurrentUserMembership();
    if (!userDetails) return getLabel("GENERAL_NO_DETAILS_FOUND");

    const emailAddress = userDetails.emailAddress;
    if (userDetails && emailAddress) {
      return (
        <P key={emailAddress}>
          {`Email address: `}
          <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
        </P>
      );
    }
    return null;
  };

  getExpiryDate = () => {
    const { getLabel, selectedPropertyLoading } = this.props;
    if (selectedPropertyLoading) return getLabel("LOADING_LABEL");
    if (!this.getCurrentUserMembership()) return;

    const expiryDate = this.getCurrentUserMembership().expiryDate;
    const formatedData = expiryDate
      ? moment(expiryDate).format("DD/MM/YYYY")
      : getLabel("NO_EXPIRY_DATE_LABEL");

    return (
      <P styleType={"descriptionText"} key={`user-${expiryDate}`}>
        {getLabel("MEMBERSHIP_EXPIRY_LABEL")}: {formatedData}
      </P>
    );
  };

  getStatus = () => {
    const { getLabel } = this.props;
    const statusKey = getUserStatusKey(this.getCurrentUserMembership());
    const labelConst = getStatusLabelConstByKey(statusKey);
    const iconColor = getStatusColorByKey(statusKey);
    return {
      label: getLabel(labelConst),
      iconColor
    };
  };

  isUserDetailLoading = () => {
    const { selectedUserStatus } = this.props;
    return selectedUserStatus === STATUS_LOADING;
  };

  createNavigationLink = (link) => {
    return () => {
      navigate(link);
    };
  };

  returnToUsersOverview = () => {
    const { orgId } = this.props;
    navigate(`/${ORGANISATION_URL}/${orgId}/${USERS_URL}`);
  };

  updateUserAction = () => {
    const { hideModal, orgId, userId } = this.props;

    hideModal();
    navigate(
      `/${ORGANISATION_URL}/${orgId}/users/${userId}/${UPDATE_USER_URL}`
    );
  };

  removeUserMembership = () => {
    const { orgId, userId, getLabel, removeUserMembership } = this.props;

    removeUserMembership(orgId, userId);
    this.showLoading(getLabel("REMOVING_LABEL"));
  };

  showLoading = (label) => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: label,
        item: getLabel("USER_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
  };

  showRemoveConfirmation = () => {
    const { showModal, setModalContent, getLabel, hideModal } = this.props;

    setModalContent({
      header: getLabel("CONFIRM_DELETE_HEADER", {
        item: getLabel("USER_TEXT")
      }),
      body: getLabel("CONFIRM_DELETE_BODY", {
        item: getLabel("USER_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
            item: getLabel("USER_TEXT")
          }),
          onClick: this.removeUserMembership
        },
        {
          buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
          onClick: hideModal
        }
      ]
    });
    showModal();
  };

  updateActiveStatus = async () => {
    try {
      const { active } = this.getCurrentUserMembership();
      const { orgId, userId, getLabel, updateUserMembership, showModal } =
        this.props;
      this.showLoading(getLabel("UPDATING_LABEL"));
      showModal();

      await updateUserMembership(
        orgId,
        userId,
        JSON.stringify({
          active: !active
        })
      );
    } catch (e) {
      throw new Error(e);
    }
  };

  getHasPermission = (section, type) => {
    const { orgId, getHasPermission } = this.props;
    return getHasPermission(orgId, section, type);
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    const { active } = this.getCurrentUserMembership();
    const childLinks = [];
    if (this.getHasPermission(USERS_SECTION, DELETE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.showRemoveConfirmation,
        buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
          item: getLabel("USER_TEXT")
        })
      });
    }
    if (this.getHasPermission(USERS_SECTION, UPDATE_PERMISSION)) {
      childLinks.push(
        ...[
          {
            isDisabled: false,
            onClick: this.updateUserAction,
            buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
              item: getLabel("USER_TEXT"),
              action: getLabel("UPDATE_ACTION_LABEL")
            })
          },
          {
            isDisabled: false,
            onClick: this.updateActiveStatus,
            buttonLabel: getLabel("SET_USER_STATUS_ACTION", {
              item: active
                ? getLabel("INACTIVE_LABEL")
                : getLabel("ACTIVE_LABEL")
            })
          }
        ]
      );
    }
    if (this.getHasPermission(USERS_SECTION, UPDATE_PERMISSION)) {
      childLinks.push();
    }
    return {
      childLinks,
      isDisabled: false,
      visible: childLinks.length ? true : false
    };
  };

  returnToUserDetailAction = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  showUpdateSuccess = () => {
    const { orgId, userId, getLabel, setModalContent, loadUserMembership } =
      this.props;
    loadUserMembership(orgId, userId);
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_UPDATED_LABEL", {
        item: getLabel("USER_TEXT")
      }),
      body: getLabel("ITEM_UPDATED_SUCCESS", {
        item: getLabel("USER_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("CLOSE_MODAL_BUTTON_LABEL"),
          onClick: this.returnToUserDetailAction
        }
      ]
    });
    return null;
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_REMOVED_LABEL", {
        item: getLabel("USER_TEXT")
      }),
      body: getLabel("USER_MEMBERSHIP_REMOVED_SUCCESS_LABEL"),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("USER_TEXT").toLowerCase()
          }),
          onClick: this.returnToOverview
        }
      ]
    });
  };

  returnToOverview = () => {
    const { hideModal } = this.props;
    hideModal();
    navigate("./");
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        isDisabled: false,
        onClick: this.returnToUsersOverview,
        buttonLabel: getLabel("BACK_TO_OVERVIEW_BUTTON", {
          item: getLabel("USERS_TEXT")
        })
      }
    ];
  };

  setFilterText = (type, value) => {
    const filterText = `${type}FilterText`;
    const pageNumber = `${type}PageNumber`;
    this.setState({
      [filterText]: value,
      [pageNumber]: 1
    });
  };

  getFilterText = (type) => {
    return this.state[`${type}FilterText`];
  };

  getNoResultsText = (type) => {
    const { getLabel } = this.props;
    const filterText = this.getFilterText(type);
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getFilterPlaceholderText = (type) => {
    const { getLabel } = this.props;
    return getLabel(
      type === PROPERTY_GROUP_TEXT
        ? "PROPERTY_GROUP_FILTER_PLACEHOLDER_LABEL"
        : "PROPERTIES_FILTER_PLACEHOLDER_LABEL"
    );
  };

  getFilterLabel = (type) => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel(
        type === PROPERTY_GROUP_TEXT
          ? "PROPERTY_GROUPS_TEXT"
          : "PROPERTIES_TEXT"
      )
    });
  };

  getPropertyGroupsTableData = (type) => {
    const { orgId, getHasPermission } = this.props;
    const selectedUserMembership = this.getCurrentUserMembership();
    if (!selectedUserMembership) return [];
    const { propertyGroups } = selectedUserMembership;
    if (!propertyGroups || propertyGroups.length === 0) return [];
    const groups = filterList(propertyGroups, this.getFilterText(type));
    return groups.map((group) => {
      const title = getHasPermission(
        orgId,
        USERS_SECTION,
        READ_DETAILS_PERMISSION
      ) ? (
        <LinkButton
          url={`/${orgId}/${PROPERTY_GROUPS_URL}/${group.groupId}`}
          buttonLabel={group.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      ) : (
        group.title
      );
      return {
        cells: [title]
      };
    });
  };

  getPropertiesTableData = (type) => {
    const { orgId, getHasPermission } = this.props;
    const selectedUserMembership = this.getCurrentUserMembership();
    if (!selectedUserMembership) return [];
    const { properties } = selectedUserMembership;
    if (!properties || properties.length === 0) return [];
    const propertiesList = filterList(properties, this.getFilterText(type));
    return propertiesList
      .filter((property) => !property.propertyGroupAccess)
      .map((prop) => {
        const title = getHasPermission(
          orgId,
          USERS_SECTION,
          READ_DETAILS_PERMISSION
        ) ? (
          <LinkButton
            url={`/${orgId}/${PROPERTIES_URL}/${prop.propId}`}
            buttonLabel={prop.title}
            buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
          />
        ) : (
          prop.title
        );
        return {
          cells: [title]
        };
      });
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isUserDetailLoading() === true ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={this.getContextMenu()}
                status={this.getStatus()}
              />
            </Container>
            <Section>
              <Heading3 styleType={SECTION_HEADER_STYLING}>
                {this.createSecondaryText()}
              </Heading3>
              {this.getUserRole()}
              {this.getUserMembershipStatus()}
              {this.getExpiryDate()}
              {this.getUserEmail()}
            </Section>
            {this.getHasPermission(
              PROPERTY_GROUPS_SECTION,
              READ_PERMISSION
            ) && (
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <DataTable
                  noResultsText={this.getNoResultsText(PROPERTY_GROUP_TEXT)}
                  title={getLabel(
                    "USER_PROPERTY_GROUP_MEMBERSHIP_HEADING_TEXT"
                  )}
                  tableFilter={{
                    filterLabel: this.getFilterLabel(PROPERTY_GROUP_TEXT),
                    showFilter: true,
                    filterFunction: (e) =>
                      this.setFilterText(PROPERTY_GROUP_TEXT, e),
                    placeholderText:
                      this.getFilterPlaceholderText(PROPERTY_GROUP_TEXT),
                    inputId: this.getFilterLabel(PROPERTY_GROUP_TEXT)
                  }}
                  header={{
                    cells: [{ content: getLabel("PROPERTY_GROUPS_TEXT") }]
                  }}
                  rows={this.getPropertyGroupsTableData(PROPERTY_GROUP_TEXT)}
                />
              </Section>
            )}
            {this.getHasPermission(PROPERTIES_SECTION, READ_PERMISSION) && (
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <DataTable
                  noResultsText={this.getNoResultsText(PROPERTY_TEXT)}
                  title={getLabel("USER_PROPERTY_MEMBERSHIP_HEADING_TEXT")}
                  tableFilter={{
                    filterLabel: this.getFilterLabel(PROPERTY_TEXT),
                    showFilter: true,
                    filterFunction: (e) => this.setFilterText(PROPERTY_TEXT, e),
                    placeholderText:
                      this.getFilterPlaceholderText(PROPERTY_TEXT),
                    inputId: this.getFilterLabel(PROPERTY_TEXT)
                  }}
                  header={{
                    cells: [{ content: getLabel("PROPERTIES_TEXT") }]
                  }}
                  rows={this.getPropertiesTableData(PROPERTY_TEXT)}
                />
              </Section>
            )}
            <BottomButtonBar buttons={this.getButtons()} />
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default UserDetail;
