import React, { Component } from "react";
import {
  defaultTheme,
  Loader,
  RadioGroup,
  ContextHeader,
  BottomButtonBar,
  DataTable
} from "@UIKit";
import { Container, Wrapper, Section, FormLabel, LinkButton } from "@core";
import { navigate } from "@reach/router";
import { filterList, getSession, storeSession } from "@base/common";
import {
  WORKFLOWS_SECTION,
  CREATE_PERMISSION,
  READ_DETAILS_PERMISSION,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  BUTTON_TYPE_PRIMARY,
  WORKFLOWS_URL,
  LINK_BUTTON,
  DEVICE_WEB,
  DEVICE_MOBILE,
  NEW_URL,
  BUTTON_TYPE_PRIMARY_FULLWIDTH,
  DATA_TABLE_SECTION_STYLING,
  PAGINATION_KEY,
  PAGINATION_OPTIONS,
  PROPERTY_GROUPS_URL,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  PROPERTY_TEXT,
  PROPERTY_GROUP_TEXT,
  ORGANISATION_URL
} from "@base/constants";
class WorkflowOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyPageNumber: 1,
      propertyFilterText: "",
      propertyFilterType: "All",
      propertyGroupPageNumber: 1,
      propertyGroupFilterText: "",
      propertyGroupFilterType: "All"
    };
  }

  componentDidMount() {
    this.loadWorkflowsDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  loadWorkflowsDetails = () => {
    const { orgId, loadOrganisationWorkflows } = this.props;
    loadOrganisationWorkflows(orgId);
  };

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  setFilterType = (type, value) => {
    const filterType = `${type}FilterType`;
    this.setState({
      [filterType]: value
    });
  };

  getFilterType = (type) => {
    return this.state[`${type}FilterType`];
  };

  setFilterText = (type, value) => {
    const filterText = `${type}FilterText`;
    const pageNumber = `${type}PageNumber`;
    this.setState({
      [filterText]: value,
      [pageNumber]: 1
    });
  };

  getFilterText = (type) => {
    return this.state[`${type}FilterText`];
  };

  getFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("WORKFLOWS_TEXT")
    });
  };

  getOrganisationWorkflows = () => {
    const { selectedOrganisationWorkflows } = this.props;
    return selectedOrganisationWorkflows
      ? selectedOrganisationWorkflows
      : false;
  };

  createHeaderText = () => {
    const { getLabel, availableOrganisations, orgId } = this.props;
    return availableOrganisations
      ? getLabel("WORKFLOWS_HEADING_TEXT", {
          organisation: availableOrganisations.find(
            (org) => org.orgId === orgId
          ).title
        })
      : getLabel("WORKFLOWS_TEXT");
  };

  getSecondHeaderText = () => {
    const { getLabel } = this.props;
    const { WORKFLOWS_TEXT } = getLabel();
    return WORKFLOWS_TEXT;
  };

  createNavigationLink = (link) => {
    return () => {
      navigate(link);
    };
  };

  createNewWorkflowLink = () => {
    const { orgId } = this.props;
    return this.createNavigationLink(
      `/${ORGANISATION_URL}/${orgId}/${WORKFLOWS_URL}/${NEW_URL}`
    );
  };

  getFilterRadioButtons = () => {
    const { getLabel } = this.props;

    return [
      {
        label: getLabel("ALL_LABEL"),
        value: "All"
      },
      {
        label: getLabel("WEB_LABEL"),
        value: DEVICE_WEB
      },
      {
        label: getLabel("MOBILE_LABEL"),
        value: DEVICE_MOBILE
      }
    ];
  };

  hasCreatePermission = () => {
    const { getHasPermission, orgId } = this.props;
    return getHasPermission(orgId, WORKFLOWS_SECTION, CREATE_PERMISSION);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY_FULLWIDTH,
        isDisabled: false,
        onClick: this.createNewWorkflowLink(),
        buttonLabel: getLabel("ADD_NEW_LABEL", {
          item: getLabel("WORKFLOW_TEXT")
        }),
        icon: {
          type: "plus",
          bgWidth: "25px",
          bgHeight: "15px",
          iconHeight: "16px",
          iconWidth: "15px",
          iconColor: defaultTheme.agWhite
        }
      }
    ];
  };

  getContextHeaderButtons = () => {
    const { getLabel } = this.props;
    return this.hasCreatePermission()
      ? [
          {
            buttonStyleType: BUTTON_TYPE_PRIMARY,
            isDisabled: false,
            onClick: this.createNewWorkflowLink(),
            buttonLabel: getLabel("ADD_NEW_LABEL", {
              item: getLabel("WORKFLOW_TEXT")
            })
          }
        ]
      : [];
  };

  isLoadingWorkflows = () => {
    const { selectedOrganisationWorkflowsLoading } = this.props;
    return selectedOrganisationWorkflowsLoading
      ? selectedOrganisationWorkflowsLoading
      : false;
  };

  getOrganisationWorkflowsByType = (type) => {
    const workflows = this.getOrganisationWorkflows();
    if (!workflows) return [];
    const filterText = this.state[`${type}FilterText`];
    const filteredWorkflows = filterList(workflows, filterText).filter(
      (workflow) => {
        if (
          this.getFilterType(type) !== DEVICE_WEB &&
          this.getFilterType(type) !== DEVICE_MOBILE
        )
          return workflow;
        return (
          workflow.device === String(this.getFilterType(type)).toLowerCase()
        );
      }
    );
    return filteredWorkflows.filter((item) => {
      return item.title && type === item.type;
    });
  };

  getOrganisationWorkflowsList = (type) => {
    const pageNumber = this.state[`${type}PageNumber`];
    const pageLimit = this.getPageLimit(type);
    const workflowsList = this.getOrganisationWorkflowsByType(type);
    if (!workflowsList) return [];
    const lastWorkflow = pageNumber * pageLimit;
    const firstWorkflow = lastWorkflow - pageLimit;
    const workflowList = workflowsList.slice(firstWorkflow, lastWorkflow);
    return workflowList;
  };

  getNoResultsText = (type) => {
    const { getLabel } = this.props;
    const filterText = this.getFilterText(type);
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getResultsSubtext = (type) => {
    const { getLabel } = this.props;
    const pageNumber = this.state[`${type}PageNumber`];
    const pageLimit = this.getPageLimit(type);
    const total = this.getOrganisationWorkflowsTotal(type);
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      pageNumber === 1 ? pageNumber : (pageNumber - 1) * pageLimit + 1;

    const limitEndNumber = pageNumber * pageLimit;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;

    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  getFilterPlaceholderText = () => {
    const { getLabel } = this.props;
    return getLabel("WORKFLOWS_FILTER_PLACEHOLDER_LABEL");
  };

  getTableHeader = (type) => {
    const { getLabel } = this.props;
    return {
      cells: [
        {
          content: getLabel(
            type === PROPERTY_GROUP_TEXT
              ? "WORKFLOWS_PROPERTY_GROUP_TYPE_TEXT"
              : "WORKFLOWS_PROPERTY_TYPE_TEXT"
          )
        }
      ]
    };
  };

  getTableData = (type) => {
    const { orgId, getHasPermission } = this.props;
    const workflowList = this.getOrganisationWorkflowsList(type);
    if (!workflowList) return;
    return workflowList.map((workflow) => {
      const title = getHasPermission(
        orgId,
        WORKFLOWS_SECTION,
        READ_DETAILS_PERMISSION
      ) ? (
        <LinkButton
          url={`/${orgId}/${WORKFLOWS_URL}/${workflow.workflowId}`}
          buttonLabel={workflow.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      ) : (
        workflow.title
      );
      return {
        cells: [title]
      };
    });
  };

  setPaginationContent = (type, value) => {
    const pageNumber = `${type}PageNumber`;
    this.setState({ [pageNumber]: value });
  };

  getPageLimit = (type) => {
    const result = getSession(`${type}-workflows-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getCurrentPageNumber = (type) => {
    return this.state[`${type}PageNumber`];
  };

  getOrganisationWorkflowsTotal = (type) => {
    if (!this.getOrganisationWorkflowsByType(type)) return [];
    return this.getOrganisationWorkflowsByType(type).length;
  };

  showDropdown = (type) => {
    return (
      this.getOrganisationWorkflowsTotal(type) >
      this.getDropDownOptions()[0].value
    );
  };

  showPagination = (type) => {
    return this.getOrganisationWorkflowsTotal(type) > this.getPageLimit(type);
  };

  getPaginationContent = (type) => {
    return {
      setPaginationContent: (e) => this.setPaginationContent(type, e),
      activeNumber: this.getCurrentPageNumber(type),
      numberOfButtons: this.getPageLimit(type),
      totalResults: this.getOrganisationWorkflowsTotal(type),
      showPagination: this.showPagination(type),
      paginationInput: true
    };
  };

  dropDownOnSelectFunction = (type, value) => {
    const pageNumber = `${type}PageNumber`;
    this.setState({ [pageNumber]: 1 });
    storeSession(`${type}-workflows-${PAGINATION_KEY}`, value);
  };

  getDropDownLabel = (type) => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit(type)
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getPageDropDown = (type) => {
    return {
      showDropdown: this.showDropdown(type),
      dropDownValue: this.getPageLimit(type),
      dropDownLabel: this.getDropDownLabel(type),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: (e) => this.dropDownOnSelectFunction(type, e)
    };
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoadingWorkflows() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper
            data-name={"Wrapper"}
            subStyle="App"
            styleType={CONTENT_VIEW}
          >
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
                buttons={this.getContextHeaderButtons()}
              />
            </Container>

            <Container>
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <RadioGroup
                  legend={getLabel("FILTER_WORFKLOWS_BY_DEVICE_LABEL")}
                  direction={"horizontal"}
                  value={this.getFilterType(PROPERTY_GROUP_TEXT)}
                  isDisabled={false}
                  name={"propertyGroupFilterRadioGroup"}
                  radioButtons={this.getFilterRadioButtons()}
                  onClick={(event) => {
                    this.setFilterType(PROPERTY_GROUP_TEXT, event);
                  }}
                ></RadioGroup>
                <DataTable
                  loading={this.isLoadingWorkflows()}
                  noResultsText={this.getNoResultsText(PROPERTY_GROUP_TEXT)}
                  title={this.getSecondHeaderText()}
                  showResultsText={true}
                  resultsSubtext={this.getResultsSubtext(PROPERTY_GROUP_TEXT)}
                  tableFilter={{
                    filterLabel: this.getFilterLabel(),
                    showFilter: true,
                    filterFunction: (e) =>
                      this.setFilterText(PROPERTY_GROUP_TEXT, e),
                    isDisabled: false,
                    placeholderText: this.getFilterPlaceholderText(),
                    inputId: getLabel("GENERIC_ACTION_MESSAGE", {
                      action: getLabel("PROPERTY_GROUP_TEXT"),
                      item: getLabel("WORKFLOWS_TEXT")
                    })
                  }}
                  header={this.getTableHeader(PROPERTY_GROUP_TEXT)}
                  rows={this.getTableData(PROPERTY_GROUP_TEXT)}
                  pagination={this.getPaginationContent(PROPERTY_GROUP_TEXT)}
                  pageDropDown={this.getPageDropDown(PROPERTY_GROUP_TEXT)}
                />
              </Section>
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <RadioGroup
                  legend={getLabel("FILTER_WORFKLOWS_BY_DEVICE_LABEL")}
                  direction={"horizontal"}
                  value={this.getFilterType(PROPERTY_TEXT)}
                  isDisabled={false}
                  name={"propertyFilterRadioGroup"}
                  radioButtons={this.getFilterRadioButtons()}
                  onClick={(event) => {
                    this.setFilterType(PROPERTY_TEXT, event);
                  }}
                ></RadioGroup>
                <DataTable
                  loading={this.isLoadingWorkflows()}
                  noResultsText={this.getNoResultsText(PROPERTY_TEXT)}
                  title={this.getSecondHeaderText()}
                  showResultsText={true}
                  resultsSubtext={this.getResultsSubtext(PROPERTY_TEXT)}
                  tableFilter={{
                    filterLabel: this.getFilterLabel(),
                    showFilter: true,
                    filterFunction: (e) => this.setFilterText(PROPERTY_TEXT, e),
                    isDisabled: false,
                    placeholderText: this.getFilterPlaceholderText(),
                    inputId: getLabel("GENERIC_ACTION_MESSAGE", {
                      action: getLabel("PROPERTY_TEXT"),
                      item: getLabel("WORKFLOWS_TEXT")
                    })
                  }}
                  header={this.getTableHeader(PROPERTY_TEXT)}
                  rows={this.getTableData(PROPERTY_TEXT)}
                  pagination={this.getPaginationContent(PROPERTY_TEXT)}
                  pageDropDown={this.getPageDropDown(PROPERTY_TEXT)}
                />
              </Section>
              {this.hasCreatePermission() && (
                <BottomButtonBar buttons={this.getButtons()} />
              )}
            </Container>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default WorkflowOverview;
