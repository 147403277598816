import { createActions } from "redux-actions";
import {
  LOAD_PROPERTY,
  LOADED_PROPERTY,
  LOAD_PROPERTY_PROPERTY_GROUPS,
  LOADED_PROPERTY_PROPERTY_GROUPS,
  LOAD_PROPERTY_WORKFLOWS,
  LOADED_PROPERTY_WORKFLOWS,
  REMOVE_PROPERTY,
  REMOVED_PROPERTY,
  LOAD_PROPERTY_USERS,
  LOADED_PROPERTY_USERS,
  UPDATE_PROPERTY,
  UPDATED_PROPERTY,
  ARCHIVE_PROPERTY,
  ARCHIVED_PROPERTY,
  RESTORE_PROPERTY,
  RESTORED_PROPERTY,
  LOAD_ARCHIVED_PROPERTY,
  LOADED_ARCHIVED_PROPERTY,
  SET_PROPERTY_ERROR,
  IMPORT_DATA_FOR_PROPERTIES,
  IMPORTED_DATA_FOR_PROPERTIES,
  CREATE_PROPERTIES,
  CREATED_PROPERTIES,
  UPDATE_PROPERTY_DATA,
  UPDATED_PROPERTY_DATA
} from "@base/constants";

export const {
  loadProperty,
  loadedProperty,
  loadPropertyPropertyGroups,
  loadedPropertyPropertyGroups,
  loadPropertyWorkflows,
  loadedPropertyWorkflows,
  removeProperty,
  removedProperty,
  loadPropertyUsers,
  loadedPropertyUsers,
  updateProperty,
  updatedProperty,
  archiveProperty,
  archivedProperty,
  restoreProperty,
  restoredProperty,
  loadArchivedProperty,
  loadedArchivedProperty,
  setPropertyError,
  importDataForProperties,
  importedDataForProperties,
  createProperties,
  createdProperties,
  updatePropertyData,
  updatedPropertyData
} = createActions({
  [LOAD_PROPERTY]: (orgId, propertyId) => ({
    orgId,
    propertyId
  }),
  [LOADED_PROPERTY]: (property) => property,
  [LOAD_PROPERTY_PROPERTY_GROUPS]: (orgId, propertyId) => ({
    orgId,
    propertyId
  }),
  [LOADED_PROPERTY_PROPERTY_GROUPS]: (propertyGroup) => propertyGroup,
  [LOAD_PROPERTY_WORKFLOWS]: (orgId, propertyId) => ({
    orgId,
    propertyId
  }),
  [LOADED_PROPERTY_WORKFLOWS]: (workflows) => workflows,
  [REMOVE_PROPERTY]: (orgId, propertyId) => ({
    orgId,
    propertyId
  }),
  [REMOVED_PROPERTY]: () => {},
  [LOAD_PROPERTY_USERS]: (orgId, propId) => ({ orgId, propId }),
  [LOADED_PROPERTY_USERS]: (users) => users,
  [UPDATE_PROPERTY]: (orgId, propId, roleId, data) => ({
    orgId,
    propId,
    roleId,
    data
  }),
  [UPDATED_PROPERTY]: () => {},
  [ARCHIVE_PROPERTY]: (orgId, propId) => ({ orgId, propId }),
  [ARCHIVED_PROPERTY]: () => {},
  [RESTORE_PROPERTY]: (orgId, propId, data) => ({ orgId, propId, data }),
  [RESTORED_PROPERTY]: () => {},
  [LOAD_ARCHIVED_PROPERTY]: (orgId, propertyId) => ({
    orgId,
    propertyId
  }),
  [LOADED_ARCHIVED_PROPERTY]: (property) => property,
  [SET_PROPERTY_ERROR]: (propertyError) => propertyError,
  [IMPORT_DATA_FOR_PROPERTIES]: (orgId, data) => ({
    orgId,
    data
  }),
  [IMPORTED_DATA_FOR_PROPERTIES]: () => {},
  [CREATE_PROPERTIES]: (data, orgId) => ({ data, orgId }),
  [CREATED_PROPERTIES]: (properties) => ({ properties }),
  [UPDATE_PROPERTY_DATA]: (orgId, propId, data) => ({
    orgId,
    propId,
    data
  }),
  [UPDATED_PROPERTY_DATA]: () => {}
});
