import {
  JOB_STATUS_RUNNING,
  JOB_STATUS_FAILED,
  JOB_STATUS_SUCCEEDED
} from "@base/constants";

export const pollJob = async (requestMethod, ...args) => {
  const result = await requestMethod(...args);
  let errorMessage;
  let isRunning;
  if (Array.isArray(result)) {
    isRunning = result.some(({ status }) => status === JOB_STATUS_RUNNING);
    if (result.some(({ status }) => status === JOB_STATUS_FAILED)) {
      errorMessage = result
        .reduce((prevResult, item) => {
          if (!item.error) return prevResult;
          return [...prevResult, item.error];
        }, [])
        .join("\n");
    }
  } else {
    const { status, error } = result;
    isRunning = status === JOB_STATUS_RUNNING;
    errorMessage = error;
  }
  if (errorMessage) {
    throw new Error(errorMessage);
  } else if (isRunning) {
    return pollJob(requestMethod, ...args);
  } else return JOB_STATUS_SUCCEEDED;
};
