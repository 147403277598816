import { createActions } from "redux-actions";
import {
  LOAD_ORGANISATIONS,
  LOADED_ORGANISATIONS,
  LOAD_ORGANISATION,
  LOADED_ORGANISATION,
  UNLOAD_ORGANISATIONS,
  LOAD_ORGANISATION_USERS,
  LOADED_ORGANISATION_USERS,
  LOAD_ORGANISATION_PROPERTY_GROUPS,
  LOADED_ORGANISATION_PROPERTY_GROUPS,
  LOAD_ORGANISATION_WORKFLOWS,
  LOADED_ORGANISATION_WORKFLOWS,
  LOAD_ORGANISATION_ROLES,
  LOADED_ORGANISATION_ROLES,
  LOAD_ORGANISATION_PROPERTIES,
  LOADED_ORGANISATION_PROPERTIES,
  LOAD_ORGANISATION_RENDERERS,
  LOADED_ORGANISATION_RENDERERS,
  LOAD_ORGANISATION_RENDERER,
  LOADED_ORGANISATION_RENDERER,
  LOAD_ORG_ARCHIVED_PROPERTIES,
  LOADED_ORG_ARCHIVED_PROPERTIES,
  LOAD_ORGANISATION_PROPERTY_WORKFLOWS,
  LOADED_ORGANISATION_PROPERTY_WORKFLOWS,
  LOAD_ORGANISATION_PROPERTY_PROPERTY_GROUPS,
  LOADED_ORGANISATION_PROPERTY_PROPERTY_GROUPS,
  SET_ORG_ID,
  UPDATE_ORGANISATION,
  UPDATED_ORGANISATION,
  LOAD_ORGANISATION_USERS_ROLES,
  LOADED_ORGANISATION_USERS_ROLES,
  CREATE_ORGANISATION,
  CREATED_ORGANISATION,
  SET_ORG_PROPERTIES_PAGINATION,
  SET_ORG_ARCHIVED_PROPS_PAGINATION,
  REMOVE_ORGANISATION,
  REMOVED_ORGANISATION,
  UPLOAD_ORG_LOGO,
  LOAD_ORGANISATION_INVITED_USERS,
  LOADED_ORGANISATION_INVITED_USERS,
  LOAD_ORGANISATION_ACCEPTED_USERS,
  LOADED_ORGANISATION_ACCEPTED_USERS,
  LOAD_ORGANISATION_DATASETS,
  LOADED_ORGANISATION_DATASETS,
  CREATE_RENDERER,
  CREATED_RENDERER,
  UPDATE_RENDERER,
  UPDATED_RENDERER,
  CREATE_DATASET,
  CREATED_DATASET
} from "@base/constants";

export const {
  loadOrganisations,
  loadedOrganisations,
  loadOrganisation,
  loadedOrganisation,
  unloadOrganisations,
  loadOrganisationUsers,
  loadedOrganisationUsers,
  loadOrganisationPropertyGroups,
  loadedOrganisationPropertyGroups,
  loadOrganisationWorkflows,
  loadedOrganisationWorkflows,
  loadOrganisationRoles,
  loadedOrganisationRoles,
  loadOrganisationProperties,
  loadedOrganisationProperties,
  loadOrganisationRenderers,
  loadedOrganisationRenderers,
  loadOrganisationRenderer,
  loadedOrganisationRenderer,
  loadOrgArchivedProperties,
  loadedOrgArchivedProperties,
  loadOrganisationPropertyWorkflows,
  loadedOrganisationPropertyWorkflows,
  loadOrganisationPropertyPropertyGroups,
  loadedOrganisationPropertyPropertyGroups,
  setOrgId,
  updateOrganisation,
  updatedOrganisation,
  loadOrganisationUsersRoles,
  loadedOrganisationUsersRoles,
  createOrganisation,
  createdOrganisation,
  setOrgPropertiesPagination,
  setOrgArchivedPropsPagination,
  uploadOrgLogo,
  removeOrganisation,
  removedOrganisation,
  loadOrganisationInvitedUsers,
  loadedOrganisationInvitedUsers,
  loadOrganisationAcceptedUsers,
  loadedOrganisationAcceptedUsers,
  loadOrganisationDatasets,
  loadedOrganisationDatasets,
  createRenderer,
  createdRenderer,
  updateRenderer,
  updatedRenderer,
  createDataset,
  createdDataset
} = createActions({
  [LOAD_ORGANISATIONS]: () => {},
  [LOADED_ORGANISATIONS]: (orgs) => orgs,
  [LOAD_ORGANISATION]: (orgId, returnCounts) => ({ orgId, returnCounts }),
  [LOADED_ORGANISATION]: (org) => org,
  [UNLOAD_ORGANISATIONS]: () => {},
  [LOAD_ORGANISATION_USERS]: (orgId) => orgId,
  [LOADED_ORGANISATION_USERS]: (users) => users,
  [LOAD_ORGANISATION_PROPERTY_GROUPS]: (orgId) => orgId,
  [LOADED_ORGANISATION_PROPERTY_GROUPS]: (propertyGroups) => propertyGroups,
  [LOAD_ORGANISATION_WORKFLOWS]: (orgId) => orgId,
  [LOADED_ORGANISATION_WORKFLOWS]: (workflows) => workflows,
  [LOAD_ORGANISATION_ROLES]: (orgId) => orgId,
  [LOADED_ORGANISATION_ROLES]: (roles) => roles,
  [LOAD_ORGANISATION_PROPERTIES]: (orgId) => orgId,
  [LOADED_ORGANISATION_PROPERTIES]: (prop) => prop,
  [LOAD_ORGANISATION_RENDERERS]: (orgId) => orgId,
  [LOADED_ORGANISATION_RENDERERS]: (renderers) => renderers,
  [LOAD_ORGANISATION_RENDERER]: (orgId, rendererId) => ({ orgId, rendererId }),
  [LOADED_ORGANISATION_RENDERER]: (renderer) => renderer,
  [LOAD_ORG_ARCHIVED_PROPERTIES]: (orgId) => orgId,
  [LOADED_ORG_ARCHIVED_PROPERTIES]: (properties) => properties,
  [LOAD_ORGANISATION_PROPERTY_WORKFLOWS]: (orgId, propId, roleId) => ({
    orgId,
    propId,
    roleId
  }),
  [LOADED_ORGANISATION_PROPERTY_WORKFLOWS]: (count, propId) => ({
    count,
    propId
  }),
  [LOAD_ORGANISATION_PROPERTY_PROPERTY_GROUPS]: (orgId, propId) => ({
    orgId,
    propId
  }),
  [LOADED_ORGANISATION_PROPERTY_PROPERTY_GROUPS]: (propertyGroups, propId) => ({
    propertyGroups,
    propId
  }),
  [SET_ORG_ID]: (orgId) => orgId,
  [UPDATE_ORGANISATION]: (orgId, data) => ({ orgId, data }),
  [UPDATED_ORGANISATION]: (orgId) => ({ orgId }),
  [LOAD_ORGANISATION_USERS_ROLES]: (orgId, roleId) => ({ orgId, roleId }),
  [LOADED_ORGANISATION_USERS_ROLES]: (roleTitle, users, roleId) => ({
    roleTitle,
    users,
    roleId
  }),
  [CREATE_ORGANISATION]: (data) => data,
  [CREATED_ORGANISATION]: () => {},
  [SET_ORG_PROPERTIES_PAGINATION]: (page, limit, offset, search) => ({
    page,
    limit,
    offset,
    search
  }),
  [SET_ORG_ARCHIVED_PROPS_PAGINATION]: (page, limit, offset, search) => ({
    page,
    limit,
    offset,
    search
  }),
  [UPLOAD_ORG_LOGO]: (orgId, logo) => ({ orgId, logo }),
  [REMOVE_ORGANISATION]: (orgId) => orgId,
  [REMOVED_ORGANISATION]: () => {},
  [LOAD_ORGANISATION_INVITED_USERS]: (orgId) => orgId,
  [LOADED_ORGANISATION_INVITED_USERS]: (users) => users,
  [LOAD_ORGANISATION_ACCEPTED_USERS]: (orgId) => orgId,
  [LOADED_ORGANISATION_ACCEPTED_USERS]: (users) => users,
  [LOAD_ORGANISATION_DATASETS]: (orgId, datasets) => ({ orgId, datasets }),
  [LOADED_ORGANISATION_DATASETS]: (datasets) => datasets,
  [CREATE_RENDERER]: (orgId, data) => ({ orgId, data }),
  [CREATED_RENDERER]: () => {},
  [UPDATE_RENDERER]: (orgId, rendererId, data) => ({
    orgId,
    rendererId,
    data
  }),
  [UPDATED_RENDERER]: (orgId, rendererId) => ({
    orgId,
    rendererId
  }),
  [CREATE_DATASET]: (orgId, data, isFromTemplate = false) => ({
    orgId,
    data,
    isFromTemplate
  }),
  [CREATED_DATASET]: (dataset) => dataset
});
