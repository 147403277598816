export const getSelectedUser = (state) => {
  return state.user && state.user.selectedUser
    ? state.user.selectedUser
    : false;
};

export const getSelectedUserStatus = (state) => {
  return state.user && state.user.status ? state.user.status : false;
};

export const getUserPropertiesMembership = (state) => {
  return state.user &&
    state.user.selectedUserProperties &&
    state.user.selectedUserProperties.available
    ? state.user.selectedUserProperties.available
    : false;
};

export const getUserPropertiesMembershipLoading = (state) => {
  return state.user &&
    state.user.selectedUserProperties &&
    state.user.selectedUserProperties.isLoading
    ? state.user.selectedUserProperties.isLoading
    : false;
};

export const getUserPropertyGroups = (state) => {
  return state.user &&
    state.user.selectedUserPropertyGroups &&
    state.user.selectedUserPropertyGroups.available
    ? state.user.selectedUserPropertyGroups.available
    : false;
};

export const getUserPropertyGroupsLoading = (state) => {
  return state.user &&
    state.user.selectedUserPropertyGroups &&
    state.user.selectedUserPropertyGroups.isLoading
    ? state.user.selectedUserPropertyGroups.isLoading
    : false;
};

export const getUserInviteId = (state) => {
  return state.user && state.user.inviteId ? state.user.inviteId : false;
};

export const getInvitedUser = (state) => {
  return state.user &&
    state.user.selectedInvitedUser &&
    state.user.selectedInvitedUser.available
    ? state.user.selectedInvitedUser.available
    : false;
};

export const getInvitedUserLoading = (state) => {
  return state.user &&
    state.user.selectedInvitedUser &&
    state.user.selectedInvitedUser.isLoading
    ? state.user.selectedInvitedUser.isLoading
    : false;
};

export const getCreateInviteErrorMessage = (state) => {
  return state.user && state.user.errorMessage
    ? state.user.errorMessage
    : false;
};
