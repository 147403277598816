import { connect } from "react-redux";
import InviteUserToOrganisation from "./InviteUserToOrganisation";
import * as actions from "../../../actions";
import {
  getLabels,
  getSelectedUserStatus,
  getAvailableOrgs,
  getOrgsStatus,
  getSelectedOrgLoading,
  getSelectedOrg,
  getSelectedOrgRoles,
  getSelectedOrgRolesLoading,
  getSelectedOrgProperties,
  getSelectedOrgPropertiesLoading,
  getSelectedOrgPropertyGroups,
  getSelectedOrgPropertyGroupsLoading,
  getAbortSignal,
  getToken,
  getSelectedOrgUsers,
  getAbortController,
  getPageTitle,
  getSelectedOrgPropertiesTotal,
  getOrgPropertiesPageNumber,
  getOrgPropertiesOffset,
  getUserInviteId,
  getUserLogin,
  getCreateInviteErrorMessage,
  getSelectedOrgInvitedUsers,
  getSelectedOrgAcceptedUsers
} from "../../../selectors";

import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    availableOrganisations: getAvailableOrgs(state),
    organisationsStatus: getOrgsStatus(state),
    organisationsLoading: getOrgsStatus(state) === STATUS_LOADING,
    selectedOrganisationLoading: getSelectedOrgLoading(state),
    selectedOrganisation: getSelectedOrg(state),
    selectedOrganisationRoles: getSelectedOrgRoles(state),
    selectedOrganisationRolesLoading: getSelectedOrgRolesLoading(state),
    selectedUserLoading: () => {
      return getSelectedUserStatus(state) === STATUS_LOADING;
    },
    selectedUserStatus: getSelectedUserStatus(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedOrganisationProperties: getSelectedOrgProperties(state),
    selectedOrganisationPropertiesLoading:
      getSelectedOrgPropertiesLoading(state),
    selectedOrgPropertyGroups: getSelectedOrgPropertyGroups(state),
    selectedOrgPropertyGroupsLoading:
      getSelectedOrgPropertyGroupsLoading(state),
    signal: getAbortSignal(state),
    token: getToken(state),
    selectedOrgUsers: getSelectedOrgUsers(state),
    abortController: getAbortController(state),
    selectedOrgPropertiesTotal: getSelectedOrgPropertiesTotal(state),
    selectedOrgPropertiesPageNumber: getOrgPropertiesPageNumber(state),
    selectedOrgPropertiesOffset: getOrgPropertiesOffset(state),
    inviteId: getUserInviteId(state),
    user: getUserLogin(state),
    inviteUserErrorMessage: getCreateInviteErrorMessage(state),
    selectedOrganisationInvitedUsers: getSelectedOrgInvitedUsers(state),
    selectedOrganisationAcceptedUsers: getSelectedOrgAcceptedUsers(state)
  }),
  actions
)(InviteUserToOrganisation);
