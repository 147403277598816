import React, { Component } from "react";
import { defaultTheme } from "../../defaultTheme";
import { Container, Button, Wrapper, Image } from "@core";
import { Heading1 } from "@typography";

import PropTypes from "prop-types";
import { ApplicationHeaderElement } from "./style";
import {
  APPLICATION_HEADER_ID,
  BUTTON_TYPE_SIGN_OUT,
  BUTTON_TYPE_USER,
  LANDKIND_LOGO
} from "@base/constants";

/**
 * The Application Header sits ontop of all pages and provides username and button to sign out.
 * Comprises of Wrapper, Container, Icon, Heading1, Button components.
 */
class ApplicationHeader extends Component {
  static propTypes = {
    /** The users name */
    headingTitle: PropTypes.string,
    userName: PropTypes.string,
    signOutLink: PropTypes.func
  };

  /**
   * Returns the userName from props if passed in, otherwise returns empty string.
   * @public
   */
  getUserName = () => {
    return this.props.userName ? this.props.userName : "";
  };

  /**
   * Returns the title for the header from props if passed in, otherwise returns empty string.
   * @public
   */
  getHeadingTitle = () => {
    return this.props.headingTitle ? this.props.headingTitle : "";
  };

  /**
   * The function that is called when the Sign out button is clicked.
   * @public
   */
  getSignOutLink = () => {
    return this.props.signOutLink;
  };

  render() {
    return (
      <ApplicationHeaderElement>
        <Wrapper styleType={APPLICATION_HEADER_ID}>
          <Container styleType={APPLICATION_HEADER_ID}>
            <Image
              height={"40px"}
              altText="Landkind Logo"
              source={LANDKIND_LOGO}
              styleType={"logo-image"}
            />
            <Heading1 styleType={"headerText"}>
              {this.getHeadingTitle()}
            </Heading1>
            <Button
              buttonStyleType={BUTTON_TYPE_USER}
              isDisabled={true}
              buttonLabel={this.getUserName()}
              icon={{
                type: "user",
                bgWidth: "30px",
                bgHeight: "30px",
                iconHeight: "20px",
                iconWidth: "20px",
                iconColor: defaultTheme.agMischkaGray
              }}
            />
            <Button
              buttonStyleType={BUTTON_TYPE_SIGN_OUT}
              isDisabled={false}
              onClick={this.getSignOutLink()}
              buttonLabel={"Sign out"}
              icon={{
                type: "sign-out",
                bgWidth: "30px",
                bgHeight: "30px",
                iconHeight: "20px",
                iconWidth: "20px",
                iconColor: defaultTheme.agMischkaGray
              }}
            />
          </Container>
        </Wrapper>
      </ApplicationHeaderElement>
    );
  }
}

export default ApplicationHeader;
