import React from "react";
import { Heading1Element } from "./style";
import PropTypes from "prop-types";

/** Renders Heading1 Element - There should only ever be a single Heading1 on a page at a time. Use for the main heading that gets the most emphasis */
const Heading1 = ({ styleType, children }) => {
  return <Heading1Element styleType={styleType}>{children}</Heading1Element>;
};

Heading1.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  styleType: PropTypes.string
};

export default Heading1;
