import { handleActions } from "redux-actions";
import {
  LOAD_ORGANISATIONS,
  LOADED_ORGANISATIONS,
  LOAD_ORGANISATION,
  LOADED_ORGANISATION,
  UNLOAD_ORGANISATIONS,
  LOAD_ORGANISATION_USERS,
  LOADED_ORGANISATION_USERS,
  LOAD_ORGANISATION_PROPERTY_GROUPS,
  LOADED_ORGANISATION_PROPERTY_GROUPS,
  LOAD_ORGANISATION_WORKFLOWS,
  LOADED_ORGANISATION_WORKFLOWS,
  LOAD_ORGANISATION_ROLES,
  LOADED_ORGANISATION_ROLES,
  LOAD_ORGANISATION_PROPERTIES,
  LOADED_ORGANISATION_PROPERTIES,
  LOAD_ORGANISATION_RENDERERS,
  LOADED_ORGANISATION_RENDERERS,
  LOAD_ORGANISATION_RENDERER,
  LOADED_ORGANISATION_RENDERER,
  LOAD_ORG_ARCHIVED_PROPERTIES,
  LOADED_ORG_ARCHIVED_PROPERTIES,
  LOAD_ORGANISATION_PROPERTY_WORKFLOWS,
  LOADED_ORGANISATION_PROPERTY_WORKFLOWS,
  LOAD_ORGANISATION_PROPERTY_PROPERTY_GROUPS,
  LOADED_ORGANISATION_PROPERTY_PROPERTY_GROUPS,
  SET_ORG_ID,
  UPDATE_ORGANISATION,
  UPDATED_ORGANISATION,
  STATUS_READY,
  STATUS_REMOVING,
  STATUS_LOADING,
  STATUS_UPDATING,
  STATUS_CREATING,
  LOAD_ORGANISATION_USERS_ROLES,
  LOADED_ORGANISATION_USERS_ROLES,
  CREATE_ORGANISATION,
  CREATED_ORGANISATION,
  SET_ORG_PROPERTIES_PAGINATION,
  SET_ORG_ARCHIVED_PROPS_PAGINATION,
  REMOVE_ORGANISATION,
  REMOVED_ORGANISATION,
  UPLOAD_ORG_LOGO,
  LOADED_ORGANISATION_INVITED_USERS,
  LOADED_ORGANISATION_ACCEPTED_USERS,
  SET_ERROR,
  LOAD_ORGANISATION_DATASETS,
  LOADED_ORGANISATION_DATASETS,
  STATUS_ERROR,
  CREATE_RENDERER,
  CREATED_RENDERER,
  CREATE_DATASET,
  CREATED_DATASET
} from "@base/constants";

export const defaultState = {
  status: STATUS_LOADING,
  createRendererStatus: STATUS_LOADING,
  createDatasetStatus: STATUS_LOADING,
  available: null,
  page: 1,
  limit: 10,
  offset: 0,
  search: "",
  selectedOrganisation: {},
  selectedOrganisationPropertyGroups: {
    isLoading: false,
    available: false
  },
  selectedOrganisationProperties: {
    isLoading: false,
    available: false
  },
  selectedOrgArchivedProperties: {
    isLoading: false,
    available: false
  },
  selectedOrgArchivedPagination: { page: 1, limit: 10, offset: 0, search: "" },
  selectedOrganisationRoles: {
    isLoading: false,
    available: false
  },
  selectedOrganisationUsers: {
    isLoading: false,
    available: false
  },
  selectedOrganisationWorkflows: {
    isLoading: false,
    available: false
  },
  selectedOrganisationInvitedUsers: {
    isLoading: false,
    available: false
  },
  selectedOrganisationAcceptedUsers: {
    isLoading: false,
    available: false
  },
  selectedOrganisationDatasets: {
    isLoading: false,
    available: false
  },
  selectedOrganisationRenderers: {
    isLoading: false,
    available: false
  },
  selectedOrganisationRenderer: {
    isLoading: false,
    available: false
  }
};
// skipcq JS-0128

export default handleActions(
  {
    [LOAD_ORGANISATIONS]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      available: null
    }),
    [LOADED_ORGANISATIONS]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      available: payload
    }),
    [LOAD_ORGANISATION]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      selectedOrganisation: {},
      isSelecting: true,
      selected: false
    }),
    [LOADED_ORGANISATION]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      status: STATUS_READY,
      selectedOrganisation: {
        ...payload
      }
    }),
    [UNLOAD_ORGANISATIONS]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      available: null
    }),
    [LOAD_ORGANISATION_USERS]: (state) => ({
      ...state,
      selectedOrganisationUsers: {
        ...state.selectedOrganisationUsers,
        isLoading: true
      }
    }),
    [LOADED_ORGANISATION_USERS]: (state, { payload }) => ({
      ...state,
      selectedOrganisationUsers: {
        isLoading: false,
        available: payload
      }
    }),
    [LOAD_ORGANISATION_PROPERTY_GROUPS]: (state) => ({
      ...state,
      selectedOrganisationPropertyGroups: {
        isLoading: true
      }
    }),
    [LOADED_ORGANISATION_PROPERTY_GROUPS]: (state, { payload }) => ({
      ...state,
      selectedOrganisationPropertyGroups: {
        isLoading: false,
        available: payload
      }
    }),

    [LOAD_ORGANISATION_WORKFLOWS]: (state) => ({
      ...state,
      selectedOrganisationWorkflows: {
        isLoading: true
      }
    }),
    [LOADED_ORGANISATION_WORKFLOWS]: (state, { payload }) => ({
      ...state,
      selectedOrganisationWorkflows: {
        isLoading: false,
        available: payload
      }
    }),
    [LOAD_ORGANISATION_ROLES]: (state) => ({
      ...state,
      selectedOrganisationRoles: {
        isLoading: true
      }
    }),
    [LOADED_ORGANISATION_ROLES]: (state, { payload }) => ({
      ...state,
      selectedOrganisationRoles: {
        isLoading: false,
        available: payload
      }
    }),
    [LOAD_ORGANISATION_PROPERTIES]: (state) => ({
      ...state,
      selectedOrganisationProperties: {
        isLoading: true
      }
    }),
    [LOADED_ORGANISATION_PROPERTIES]: (state, { payload }) => {
      return {
        ...state,
        selectedOrganisationProperties: {
          isLoading: false,
          available: payload,
          count: payload.meta.total
        }
      };
    },
    [LOAD_ORGANISATION_RENDERERS]: (state) => ({
      ...state,
      selectedOrganisationRenderers: {
        isLoading: true,
        available: []
      }
    }),
    [LOADED_ORGANISATION_RENDERERS]: (state, { payload }) => ({
      ...state,
      selectedOrganisationRenderers: {
        isLoading: false,
        available: payload.items
      }
    }),
    [LOAD_ORGANISATION_RENDERER]: (state) => ({
      ...state,
      selectedOrganisationRenderer: {
        isLoading: true,
        available: []
      }
    }),
    [LOADED_ORGANISATION_RENDERER]: (state, { payload }) => ({
      ...state,
      selectedOrganisationRenderer: {
        isLoading: false,
        available: payload
      }
    }),
    [LOAD_ORG_ARCHIVED_PROPERTIES]: (state) => ({
      ...state,
      selectedOrgArchivedProperties: {
        isLoading: true
      }
    }),
    [LOADED_ORG_ARCHIVED_PROPERTIES]: (state, { payload }) => {
      return {
        ...state,
        selectedOrgArchivedProperties: {
          isLoading: false,
          available: payload,
          count: payload.meta.total
        }
      };
    },
    [LOAD_ORGANISATION_PROPERTY_WORKFLOWS]: (state, { payload }) => {
      const items = state.selectedOrganisationProperties.available.items.map(
        (item) => {
          if (item.propId === payload.propId) {
            return {
              ...item,
              workflows: {
                count: null,
                status: STATUS_LOADING
              }
            };
          }
          return item;
        }
      );

      return {
        ...state,
        selectedOrganisationProperties: {
          isLoading: false,
          available: {
            ...state.selectedOrganisationProperties.available,
            items
          }
        }
      };
    },

    [LOADED_ORGANISATION_PROPERTY_WORKFLOWS]: (state, { payload }) => {
      const items = state.selectedOrganisationProperties.available.items.map(
        (item) => {
          if (item.propId === payload.propId) {
            return {
              ...item,
              workflows: {
                count: payload.count,
                status: STATUS_READY
              }
            };
          }
          return item;
        }
      );

      return {
        ...state,
        selectedOrganisationProperties: {
          isLoading: false,
          available: {
            ...state.selectedOrganisationProperties.available,
            items
          }
        }
      };
    },

    [LOAD_ORGANISATION_PROPERTY_PROPERTY_GROUPS]: (state, { payload }) => {
      const items = state.selectedOrganisationProperties.available.items.map(
        (item) => {
          if (item.propId === payload.propId) {
            return {
              ...item,
              propertyGroups: {
                titles: [],
                count: null,
                status: STATUS_LOADING
              }
            };
          }
          return item;
        }
      );

      return {
        ...state,
        selectedOrganisationProperties: {
          isLoading: false,
          available: {
            ...state.selectedOrganisationProperties.available,
            items
          }
        }
      };
    },

    [LOADED_ORGANISATION_PROPERTY_PROPERTY_GROUPS]: (state, { payload }) => {
      const items = state.selectedOrganisationProperties.available.items.map(
        (item) => {
          if (item.propId === payload.propId) {
            const propertyGroupTitles = payload.propertyGroups.items.map(
              (propertyGroup) => propertyGroup.title
            );
            return {
              ...item,
              propertyGroups: {
                titles: propertyGroupTitles,
                count: payload.propertyGroups.meta.total,
                status: STATUS_READY
              }
            };
          }
          return item;
        }
      );

      return {
        ...state,
        selectedOrganisationProperties: {
          isLoading: false,
          available: {
            ...state.selectedOrganisationProperties.available,
            items
          }
        }
      };
    },

    [SET_ORG_ID]: (state, { payload }) => ({
      ...state,
      selectedOrganisation: {
        ...state.selectedOrganisation,
        orgId: payload
      }
    }),
    [UPDATE_ORGANISATION]: (state) => ({
      ...state,
      status: STATUS_UPDATING
    }),
    [UPDATED_ORGANISATION]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [LOAD_ORGANISATION_USERS_ROLES]: (state) => {
      return {
        ...state,
        selectedOrganisationUsers: {
          isLoading: true,
          available: {
            ...state.selectedOrganisationUsers.available
          }
        }
      };
    },
    [LOADED_ORGANISATION_USERS_ROLES]: (state, { payload }) => {
      const items = state.selectedOrganisationUsers.available.items.map(
        (item) => {
          const payloadUserId = payload.users.items.map((user) => user.userId);
          if (payloadUserId.includes(item.userId)) {
            return {
              ...item,
              roleTitle: payload.roleTitle,
              roleId: payload.roleId
            };
          }
          return item;
        }
      );
      return {
        ...state,
        selectedOrganisationUsers: {
          isLoading: false,
          available: {
            ...state.selectedOrganisationUsers.available,
            items
          }
        }
      };
    },
    [CREATE_ORGANISATION]: (state) => ({
      ...state,
      status: STATUS_CREATING
    }),
    [CREATED_ORGANISATION]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [SET_ORG_PROPERTIES_PAGINATION]: (state, { payload }) => ({
      ...state,
      page: payload.page,
      limit: payload.limit,
      offset: payload.offset,
      search: payload.search,
      selectedOrganisationProperties: {
        isLoading: true
      }
    }),
    [SET_ORG_ARCHIVED_PROPS_PAGINATION]: (state, { payload }) => ({
      ...state,
      selectedOrgArchivedPagination: {
        page: payload.page,
        limit: payload.limit,
        offset: payload.offset,
        search: payload.search
      }
    }),

    [REMOVE_ORGANISATION]: (state) => ({
      ...state,
      status: STATUS_REMOVING
    }),
    [REMOVED_ORGANISATION]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [UPLOAD_ORG_LOGO]: (state) => ({
      ...state,
      status: STATUS_UPDATING
    }),
    [LOADED_ORGANISATION_INVITED_USERS]: (state, { payload }) => ({
      ...state,
      selectedOrganisationInvitedUsers: {
        isLoading: false,
        available: payload
      }
    }),
    [LOADED_ORGANISATION_ACCEPTED_USERS]: (state, { payload }) => ({
      ...state,
      selectedOrganisationAcceptedUsers: {
        isLoading: false,
        available: payload
      }
    }),
    [SET_ERROR]: (state) => ({
      ...state,
      isSelecting: false,
      status: STATUS_ERROR
    }),
    [LOAD_ORGANISATION_DATASETS]: (state) => ({
      ...state,
      selectedOrganisationDatasets: {
        isLoading: true,
        available: []
      }
    }),
    [LOADED_ORGANISATION_DATASETS]: (state, { payload }) => ({
      ...state,
      selectedOrganisationDatasets: {
        isLoading: false,
        available: payload
      }
    }),
    [CREATE_RENDERER]: (state) => ({
      ...state,
      createRendererStatus: STATUS_CREATING
    }),
    [CREATED_RENDERER]: (state) => {
      return {
        ...state,
        createRendererStatus: STATUS_READY
      };
    },
    [CREATE_DATASET]: (state) => ({
      ...state,
      createDatasetStatus: STATUS_CREATING
    }),
    [CREATED_DATASET]: (state) => {
      return {
        ...state,
        createDatasetStatus: STATUS_READY
      };
    }
  },

  defaultState
);
