import styled, { css } from "styled-components";
import { ImageElement } from "../../core/Image/style";
import { ContainerElement } from "../../core/Container/style";
import { ButtonElement } from "../../core/Button/style";
import { Heading2Element } from "../../typography/Heading2/style";
import { ORG_LIST_CONTENT_STYLE_TYPE } from "@base/constants";

export const ListContentElement = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-bottom: 3rem;

  & + ${ButtonElement} {
    margin-top: 2.4rem;
  }

  & + ${Heading2Element} {
    margin-top: 2.4rem;
  }
`;
ListContentElement.displayName = "ListContentElement";

export const ListContentItemElement = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.agLightestGray};
  padding: 0.8rem;
  width: calc(100% - 1.6rem);
  margin-bottom: 1.875rem;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.1875rem 0.375rem #00000029;
  border: 2px solid #ffffff;
  border-radius: 0.25rem;
  opacity: 1;

  & ${ImageElement} {
    min-width: 5rem;
    max-width: 5rem;
    max-height: 3.125rem;
  }

  & ${ContainerElement} {
    background: none;
  }
`;
ListContentItemElement.displayName = "ListContentItemElement";

export const ListContentItemSpan = styled.span`
  width: 100%;
  color: ${(props) => props.theme.agCommonBlack};
  font-size: ${(props) => props.theme.fontSizeTwo};
  font-weight: ${(props) => props.theme.baseFontWeight};
  padding: 1rem 1.2rem;
`;
ListContentItemSpan.displayName = "ListContentItemSpan";

export const ListContentChildItemSpan = styled.span`
  color: ${(props) => props.theme.agCommonBlack};
  font-size: 0.8rem;
  font-weight: ${(props) => props.theme.baseFontWeight};
  padding: 1rem 1.2rem;
`;
ListContentChildItemSpan.displayName = "ListContentChildItemSpan";
