import React from "react";
import { PaginationButton } from "../style";
import { Button } from "@core";
import PropTypes from "prop-types";

const PaginationPageButton = (props) => {
  return (
    <PaginationButton
      key={props.page}
      active={props.active}
      styleType={props.styleType}
      page={props.page}
    >
      <Button
        buttonStyleType={"paginationButtons"}
        isDisabled={props.isDisabled}
        onClick={props.onClick}
        buttonLabel={props.page}
      />
    </PaginationButton>
  );
};

PaginationPageButton.propTypes = {
  /** The page number being rendered */
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Called on click of the button */
  onClick: PropTypes.func,
  /** If the button is active */
  active: PropTypes.bool,
  /** isDisabled true or false */
  isDisabled: PropTypes.bool,
  /** styleType for styling */
  styleType: PropTypes.bool
};

export default PaginationPageButton;
