import React from "react";
import PropTypes from "prop-types";
import { ImageElement } from "./style";

/** Renders a Image  */
const Image = (props) => {
  return (
    <ImageElement
      styleType={props.styleType}
      src={props.source}
      alt={props.altText}
      data-name={"ImageElement"}
      width={props.width}
      height={props.height}
      onError={props.onError}
    />
  );
};

Image.propTypes = {
  /** Image source */
  source: PropTypes.string,
  /** Alt text for image */
  altText: PropTypes.string,
  /** Max Width for Image */
  width: PropTypes.string,
  /** Max Height for Image */
  height: PropTypes.string,
  /** styleType for Image */
  styleType: PropTypes.string,
  /** function called on error loading the image source */
  onError: PropTypes.func
};

export default Image;
