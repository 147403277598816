import { connect } from "react-redux";
import AddPropertyGroup from "./AddPropertyGroup";
import * as actions from "../../../actions";
import {
  getLabels,
  getSelectedOrgProperties,
  getOrgsStatus,
  getSelectedOrgLoading,
  getSelectedOrgPropertiesLoading,
  getPropertyGroupStatus,
  getAbortController,
  getPageTitle,
  getSelectedOrgPropertiesTotal,
  getOrgPropertiesPageNumber,
  getOrgPropertiesOffset
} from "../../../selectors";
import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedOrganisationProperties: getSelectedOrgProperties(state),
    organisationsLoading: getOrgsStatus(state) === STATUS_LOADING,
    selectedOrganisationLoading: getSelectedOrgLoading(state),
    selectedOrganisationPropertiesLoading:
      getSelectedOrgPropertiesLoading(state),
    status: getPropertyGroupStatus(state),
    abortController: getAbortController(state),
    selectedOrgPropertiesTotal: getSelectedOrgPropertiesTotal(state),
    selectedOrgPropertiesPageNumber: getOrgPropertiesPageNumber(state),
    selectedOrgPropertiesOffset: getOrgPropertiesOffset(state)
  }),
  actions
)(AddPropertyGroup);
