import { handleActions } from "redux-actions";
import {
  SUCCESSFUL_AUTHENTICATION,
  ERROR_AUTHENTICATION,
  CLEAR_AUTHENTICATION,
  CLEAR_ERRORS,
  LOAD_USER_LOGIN,
  LOADED_USER_LOGIN,
  SET_AUTHZERO_CLIENT,
  INVALIDATE_AUTHENTICATION,
  RESET_LOGGING_OUT
} from "@base/constants";

export const defaultState = {
  isAuthenticated: false,
  userId: null,
  token: null,
  error: false,
  user: {
    name: "User Name Test",
    isLoading: true,
    superHuman: false
  },
  authClient: null,
  loggingOut: false
};
// skipcq JS-0128
// @ts-ignore
export default handleActions(
  {
    [SUCCESSFUL_AUTHENTICATION]: (state, { payload }) => ({
      ...state,
      isAuthenticated: true,
      userId: payload.userId,
      token: payload.token,
      error: false
    }),
    [ERROR_AUTHENTICATION]: (state, { payload }) => ({
      ...state,
      isAuthenticated: false,
      userId: null,
      token: null,
      error: payload
    }),
    [CLEAR_AUTHENTICATION]: (state) => ({
      ...state,
      isAuthenticated: false,
      userId: null,
      token: null,
      error: false
    }),
    [CLEAR_ERRORS]: (state) => ({
      ...state,
      error: false
    }),
    [LOAD_USER_LOGIN]: (state) => ({
      ...state,
      user: {
        isLoading: true,
        superHuman: false,
        name: null
      }
    }),
    [LOADED_USER_LOGIN]: (state, { payload }) => {
      return {
        ...state,
        userId: payload.userId,
        user: {
          ...payload,
          name: `${payload.firstName} ${payload.lastName}`,
          isLoading: false,
          superHuman: payload.superHuman ? true : false
        }
      };
    },
    [SET_AUTHZERO_CLIENT]: (state, { payload }) => ({
      ...state,
      authClient: payload
    }),
    [INVALIDATE_AUTHENTICATION]: (state) => ({
      ...state,
      loggingOut: true
    }),
    [RESET_LOGGING_OUT]: (state) => ({
      ...state,
      loggingOut: false
    })
  },
  defaultState
);
