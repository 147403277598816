import { connect } from "react-redux";
import UserOverview from "./UserOverview";
import * as actions from "../../../actions";
import {
  getLabels,
  getAvailableOrgs,
  getSelectedOrgUsers,
  getSelectedOrgUsersLoading,
  getUserSuperHuman,
  getPageTitle,
  getAbortController,
  getSelectedOrgInvitedUsers,
  getHasPermission
} from "../../../selectors";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    availableOrganisations: getAvailableOrgs(state),
    selectedOrganisationUsers: getSelectedOrgUsers(state),
    selectedOrganisationUsersLoading: getSelectedOrgUsersLoading(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    superHumanUser: getUserSuperHuman(state),
    abortController: getAbortController(state),
    selectedOrganisationInvitedUsers: getSelectedOrgInvitedUsers(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType)
  }),
  actions
)(UserOverview);
