import React, { Component } from "react";
import AttachmentList from "./subComponents/AttachmentList";
import FileInput from "./subComponents/FileInput";
import { AttachmentContainer } from "./style";
import PropTypes from "prop-types";
import { FormLabel, Container } from "@core";
import { STYLETYPE_ERROR } from "@base/constants";
import { P } from "@typography";

export default class Attachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInvalid: false
    };
  }
  static propTypes = {
    /** If isEditable is true then the fil;e upload box will be visible. */
    isEditable: PropTypes.bool,
    /** The existing attachments (e.g. those that have been uploaded and have a URL to download them) */
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string
      })
    ),
    /** Called when 'clear' button next to an attachment is clicked */
    handleClearExistingFile: PropTypes.func,
    /** Called when a file is dropped into the dropzone, or when a file is selected via the file explorer browser */
    handleSelectFile: PropTypes.func,
    /** Called when the 'clear' button is clicked on a not-yet-uploaded item */
    handleClearFile: PropTypes.func,
    /** Files that have been dropped/selected from the explorer and are listed, but have not yet been uploaded (e.g. don't have a URL to download them) */
    importFiles: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    ),
    labels: PropTypes.shape({
      /** Displayed as a header when isEditable is true  */
      add: PropTypes.string,
      /** Displayed on the drop zone */
      drop: PropTypes.string,
      /** Displayed when an invalid file type is dropped into the dropzone */
      invalid: PropTypes.string,
      /** Displayed on the dropzone to describe accepted file types (is automatically followed by the acceptFileTypes prop) */
      fileAccept: PropTypes.string
    }),
    /** The types of files that are accepted, in string format - defaults to 'image/*' */
    acceptFileTypes: PropTypes.string
  };
  /**
   * Returns the isEditable prop
   * @public
   */
  isEditable = () => {
    const { isEditable } = this.props;
    return isEditable;
  };
  /**
   * Returns the attachments prop if set, else []
   * @public
   */
  getAttachments = () => {
    const { attachments } = this.props;
    return attachments ? attachments : [];
  };

  /**
   * Called on drop/select of a file
   * @param {object[]} [files]
   * @public
   */
  handleSelectFile = (files) => {
    const { handleSelectFile } = this.props;
    if (!handleSelectFile) return;
    handleSelectFile(files);
  };
  /**
   * Called on clear of a file that has not yet been uploaded
   * @param {object} [file] - the file to be removed from the list
   * @param {i} [i] - the index of the file in the array
   * @public
   */
  handleClearFile = (file, i) => {
    const { handleClearFile } = this.props;
    if (!handleClearFile) return;
    handleClearFile(file, i);
  };
  /**
   * Called on clear of a file that has been uploaded alread
   * @param {object} [file] - the file to be removed from the list
   * @public
   */
  handleClearExistingFile = (file) => {
    const { handleClearExistingFile } = this.props;
    if (!handleClearExistingFile) return;
    handleClearExistingFile(file);
  };

  /**
   * Returns importFiles prop if set, else []
   * @public
   */
  getImportFiles = () => {
    const { importFiles } = this.props;
    return importFiles ? importFiles : [];
  };

  /**
   * Returns add from labels props if set, else ""
   * @public
   */
  addAttachmentLabel = () => {
    const { add } = this.props.labels;
    return add ? add : "";
  };

  /**
   * Returns drop from labels props if set, else ""
   * @public
   */
  dropLabel = () => {
    const { drop } = this.props.labels;
    return drop ? drop : "";
  };
  /**
   * Returns acceptFileTypes from props if set, else "image/*"
   * @public
   */
  acceptFileTypes = () => {
    const { acceptFileTypes } = this.props;
    return acceptFileTypes ? acceptFileTypes : "image/*";
  };

  /**
   * Sets isInvalid in state to be true. Called when a file is rejected on drop/select
   * @public
   */
  handleRejectedFiles = () => {
    this.setState({
      isInvalid: true
    });
  };
  /**
   * Sets isInvalid in state to be false. Called when a file is accepted on drop/select
   * @public
   */
  handleAcceptedFiles = () => {
    this.setState({
      isInvalid: false
    });
  };

  /**
   * Returns invalid from labels props if set, else ""
   * @public
   */
  invalidLabel = () => {
    const { invalid } = this.props.labels;
    return invalid ? invalid : "";
  };
  /**
   * Returns isInvalid from state
   * @public
   */
  isInvalid = () => {
    const { isInvalid } = this.state;
    return isInvalid;
  };

  /**
   * Returns fileAccept from labels props if set, else ""
   * @public
   */
  fileAcceptLabel = () => {
    const { fileAccept } = this.props.labels;
    return fileAccept ? fileAccept : "";
  };

  render() {
    return (
      <AttachmentContainer>
        {this.getAttachments().length > 0 && (
          <AttachmentList
            attachments={this.getAttachments()}
            editable={this.isEditable()}
            clearAttachment={this.handleClearExistingFile}
          />
        )}
        {this.isEditable() && (
          <Container>
            {this.addAttachmentLabel() && (
              <FormLabel
                styleType={this.isInvalid() ? STYLETYPE_ERROR : ""}
                htmlFor={"file-input"}
              >
                {this.addAttachmentLabel()}
              </FormLabel>
            )}
            {this.getImportFiles().length > 0 && (
              <AttachmentList
                editable={true}
                attachments={this.getImportFiles()}
                clearAttachment={this.handleClearFile}
              />
            )}
            <FileInput
              handleSelectFile={this.handleSelectFile}
              dropLabel={this.dropLabel()}
              accept={this.acceptFileTypes()}
              onDropRejected={this.handleRejectedFiles}
              onDropAccepted={this.handleAcceptedFiles}
              isInvalid={this.isInvalid()}
              fileAcceptLabel={this.fileAcceptLabel()}
            />
            {this.isInvalid() && (
              <P styleType={STYLETYPE_ERROR}>{this.invalidLabel()}</P>
            )}
          </Container>
        )}
      </AttachmentContainer>
    );
  }
}
