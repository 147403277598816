import React, { Component } from "react";
import { FormLabelElement } from "./style";
import PropTypes from "prop-types";

/** Renders Form Label Element - Used for generic blocks of text */

class FormLabel extends Component {
  static propTypes = {
    styleType: PropTypes.string,
    /**Whether the radio button is vertical or horizontal */
    direction: PropTypes.oneOf(["horizontal", "vertical"]),
    /** Accessibility label */
    htmlFor: PropTypes.string,
    /** id for the label */
    id: PropTypes.string
  };

  /**
   * Function to return props.direction
   * @public
   */
  getDirection = () => {
    return this.props.direction ? this.props.direction : "vertical";
  };

  /**
   * Function to return props.styleType
   * @public
   */
  getStyleType = () => {
    return this.props.styleType ? this.props.styleType : "";
  };

  render() {
    return (
      <FormLabelElement
        id={this.props.id}
        htmlFor={this.props.htmlFor}
        direction={this.getDirection()}
        styleType={this.getStyleType()}
      >
        {this.props.children}
      </FormLabelElement>
    );
  }
}

export default FormLabel;
