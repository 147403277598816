import React, { Component } from "react";
import { navigate } from "@reach/router";
import { P, Legend } from "@typography";
import AgBoxApiRequests from "../../../apis/agbox/requests";
import {
  Container,
  Wrapper,
  Fieldset,
  CheckboxV2,
  TextArea,
  Section,
  FormLabel
} from "@core";
import { ContextHeader, DataTable, Loader, BottomButtonBar } from "@UIKit";
import { CSSTransition } from "react-transition-group";

import {
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  STYLETYPE_FORM_FIELD,
  DATASETS_URL,
  ORGANISATION_URL,
  AGBOX_API_URL,
  AGBOX_API_KEY,
  STATUS_CREATING,
  STATUS_READY,
  HEIGHT_AUTO_TEXT,
  CONTENT_FADE_TIMEOUT,
  STYLETYPE_ERROR
} from "@base/constants";

const { requestOrganisationDatasets } = AgBoxApiRequests(
  AGBOX_API_URL,
  AGBOX_API_KEY
);
class AddDatasets extends Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    const localRawModeValue = localStorage.getItem("datasetRawMode");
    const rawModeValue =
      localRawModeValue !== null ? localRawModeValue == "false" : true;
    this.state = {
      dataSets: [],
      existingDataSets: [],
      isLoading: false,
      rawMode: rawModeValue,
      textInputValue: ""
    };
  }

  componentDidMount() {
    this.loadDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedOrgCreateDatasetStatus } = this.props;
    if (
      prevProps.selectedOrgCreateDatasetStatus === STATUS_CREATING &&
      selectedOrgCreateDatasetStatus === STATUS_READY
    ) {
      this.showSuccess();
    }
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  valueIsEmpty = (field) => {
    return (
      !this.getValue(field) ||
      (this.getValue(field) && this.getValue(field).length === 0) ||
      this.getValue(field) === ""
    );
  };

  isInvalid = () => {
    return this.valueIsEmpty("dataSets") && this.valueIsEmpty("textInputValue");
  };

  isDetailsLoading = () => {
    const { datasetTemplatesLoading } = this.props;
    const isLoading = this.getValue("isLoading");
    return datasetTemplatesLoading || isLoading;
  };

  returnToOverviewAction = () => {
    const { hideModal, orgId } = this.props;
    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${DATASETS_URL}/`);
  };

  showErrorModal = (error) => {
    const { showModal } = this.props;
    this.setErrorModalContent(error.message);
    showModal();
  };

  setErrorModalContent = (errorMessage) => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("GENERIC_ERROR_HEADING"),
      body: <P>{errorMessage}</P>,
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("DATASETS_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
  };

  getDatasets = async () => {
    const { orgId, token } = this.props;
    let availableDatasets = await requestOrganisationDatasets(
      orgId,
      token,
      this.controller.signal
    );
    return availableDatasets.items;
  };

  loadDetails = async () => {
    this.setState({ isLoading: true });
    const { loadDatasetTemplates } = this.props;
    try {
      const existingDataSets = await this.getDatasets();
      this.setState({
        existingDataSets,
        isLoading: false
      });
      loadDatasetTemplates();
    } catch (error) {
      this.showErrorModal(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getHeaderText = () => {
    const { getLabel } = this.props;
    return this.state.rawMode
      ? getLabel("CREATE_LABEL", { item: getLabel("DATASET_TEXT") })
      : getLabel("FORM_LABEL_ORG_CREATE_DATASETS_FROM_TEMPLATE");
  };

  getSecondHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("FORM_LABEL_ORG_CREATE_DATASETS");
  };

  getDataSetsTemplates = () => {
    const { datasetTemplates } = this.props;
    return datasetTemplates || [];
  };

  getSelectedDataSetsTemplates = () => {
    const { dataSets } = this.state;
    return dataSets || [];
  };

  getSelectableDataSetsTemplates = () => {
    const existingDataSetNames = this.state.existingDataSets.map(
      (ds) => ds.name
    );
    return this.getDataSetsTemplates().filter(
      (template) => !existingDataSetNames.includes(template.title)
    );
  };

  getSelectAllDataSets = () => {
    const dataSets = this.getSelectedDataSetsTemplates();
    return this.getSelectableDataSetsTemplates().every((template) =>
      dataSets.some((selected) => selected.templateId === template.templateId)
    );
  };

  setSelectedDataSets = (e) => {
    const { value, checked } = e.currentTarget;
    const selectedDataSets = this.getSelectedDataSetsTemplates();
    const template = this.getDataSetsTemplates().find(
      (template) => template.templateId === value
    );
    if (!template) return;
    const { title, templateId } = template;
    const newDataset = { title, templateId };
    this.setValue(
      checked
        ? [...selectedDataSets, newDataset]
        : selectedDataSets.filter((dataset) => dataset.templateId !== value),
      "dataSets"
    );
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: "primary",
        isDisabled: this.isInvalid(),
        onClick: this.callCreateDatasets,
        buttonLabel: getLabel("ADD_LABEL", {
          item: this.getDatasetLabel()
        })
      },
      {
        buttonStyleType: "secondary",
        isDisabled: false,
        onClick: this.returnToOverviewAction,
        buttonLabel: getLabel("BACK_TO_OVERVIEW_BUTTON", { item: "" })
      }
    ];
  };

  getTextInputValue = () => {
    return this.getValue("textInputValue");
  };

  callCreateDatasets = async () => {
    try {
      const { createDataset, showModal, orgId } = this.props;
      const isRawMode = this.getValue("rawMode");
      const data = isRawMode
        ? this.getTextInputValue()
        : this.getSelectedDataSetsTemplates();
      this.showLoading();
      showModal();
      await createDataset(orgId, data, !isRawMode);
    } catch (e) {
      throw new Error(e);
    }
  };

  getDataSetsTemplatesRows = () => {
    const { existingDataSets } = this.state;
    const selectedDataSetsTemplates = this.getSelectedDataSetsTemplates();
    const existingDataSetNames = existingDataSets.map((ds) => ds.name);

    return this.getDataSetsTemplates().map((template) => {
      const isChecked =
        selectedDataSetsTemplates.some(
          (selected) => selected.templateId === template.templateId
        ) || existingDataSetNames.includes(template.title);
      return {
        cells: [
          <CheckboxV2
            handleUpdate={this.setSelectedDataSets}
            name={"dataset"}
            id={template.templateId}
            rowId={template.templateId}
            value={template.templateId}
            checked={isChecked}
            title={`${template.title} - ${template.description}`}
            disabled={existingDataSetNames.includes(template.title)}
          />
        ],
        id: template.templateId
      };
    });
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_CREATED_LABEL", {
        item: this.getDatasetLabel()
      }),
      body: getLabel("ITEM_CREATED_LABEL", {
        item: this.getDatasetLabel()
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("DATASETS_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("CREATING_LABEL"),
        item: this.getDatasetLabel()
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  setSelectAllDataSets = (e) => {
    const selectableDataSetsTemplates =
      this.getSelectableDataSetsTemplates().map((template) => ({
        title: template.title,
        templateId: template.templateId
      }));
    this.setValue(
      e.currentTarget.checked ? selectableDataSetsTemplates : [],
      "dataSets"
    );
  };

  getDatasetLabel = () => {
    const { getLabel } = this.props;
    const isSingleDataset =
      this.getValue("rawMode") ||
      this.getSelectedDataSetsTemplates().length <= 1;
    return getLabel(isSingleDataset ? "DATASET_TEXT" : "DATASETS_TEXT");
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    const { rawMode } = this.state;
    return {
      childLinks: [
        {
          isDisabled: false,
          onClick: this.switchModes,
          buttonLabel: rawMode
            ? getLabel("FORM_MODE_LABEL")
            : getLabel("RAW_MODE_LABEL")
        }
      ],
      isDisabled: false,
      visible: true
    };
  };

  switchModes = () => {
    this.setState(
      {
        rawMode: !this.state.rawMode,
        dataSets: [],
        textInputValue: ""
      },
      () => {
        localStorage.setItem("workflowRawMode", this.state.rawMode);
      }
    );
  };

  handleTextAreaChange = (value) => {
    this.setValue(value, "textInputValue");
  };

  getRawModeContent = () => {
    const { getLabel } = this.props;
    const textInputValue = this.getValue("textInputValue");
    const isEmpty = this.valueIsEmpty("textInputValue");
    return (
      <Section>
        <FormLabel
          htmlFor="dataset-json-textarea"
          styleType={isEmpty ? STYLETYPE_ERROR : ""}
        >
          {getLabel("ADD_JSON", {
            item: getLabel("DATASET_TEXT")
          })}
        </FormLabel>
        <TextArea
          id="dataset-json-textarea"
          value={textInputValue}
          onChange={this.handleTextAreaChange}
          styleType={isEmpty ? STYLETYPE_ERROR : ""}
          maxLength={30000}
        />
      </Section>
    );
  };

  getFormModeContent = () => {
    const { getLabel } = this.props;
    return (
      <Fieldset>
        <DataTable
          title={this.getSecondHeaderText()}
          id={"datasets-table"}
          TitleComponent={Legend}
          header={{
            cells: [
              {
                content: (
                  <CheckboxV2
                    handleUpdate={this.setSelectAllDataSets}
                    name={"dataSets"}
                    rowId={"dataSets"}
                    value={this.getSelectAllDataSets()}
                    checked={this.getSelectAllDataSets()}
                    title={getLabel("ENABLE_ALL_DATASETS_LABEL")}
                  />
                )
              }
            ],
            showToggle: false
          }}
          rows={this.getDataSetsTemplatesRows()}
          loading={this.isDetailsLoading()}
          noResultsText={getLabel("NO_DATASETS_LABEL")}
        />
      </Fieldset>
    );
  };

  render() {
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isDetailsLoading() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper
            data-name={"Wrapper"}
            subStyle="App"
            styleType={CONTENT_VIEW}
          >
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.getHeaderText()}
                contextMenu={this.getContextMenu()}
              />
            </Container>
            <Container styleType={STYLETYPE_FORM_FIELD}>
              <CSSTransition
                in={!this.getValue("rawMode")}
                timeout={CONTENT_FADE_TIMEOUT}
                classNames="css-transition"
                unmountOnExit
              >
                {this.getFormModeContent()}
              </CSSTransition>
              <CSSTransition
                in={this.getValue("rawMode")}
                timeout={CONTENT_FADE_TIMEOUT}
                classNames="css-transition"
                unmountOnExit
              >
                {this.getRawModeContent()}
              </CSSTransition>
            </Container>
            <BottomButtonBar buttons={this.getButtons()} />
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default AddDatasets;
