import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormInputElement } from "./style";
import { FORM_STYLE_TYPES, STYLETYPE_TEXT } from "@base/constants";

/**
 * Button component that creates a new button from style components and optionally includes an Icon
 */
class FormInput extends Component {
  static propTypes = {
    /**Whether the text area is disabled or not */
    isDisabled: PropTypes.bool,
    /**The max length of characters in the text area */
    maxLength: PropTypes.number,
    /**The optional placeholder */
    placeholder: PropTypes.string,
    /**Whether the text area is read only */
    readOnly: PropTypes.bool,
    /**What to display in the textArea */
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.bool,
      PropTypes.number
    ]),
    /**Used to handle styling */
    styleType: PropTypes.string,
    /**What type of input */
    type: PropTypes.string,
    /**A unique ID for the form input */
    id: PropTypes.string,
    /** width scales based on input length */
    width: PropTypes.number
  };

  /**
   * Returns if the text area is set to disabled, defaults to false
   * @public
   */
  isDisabled = () => {
    return this.props.isDisabled ? this.props.isDisabled : false;
  };

  /**
   * Returns maximum character count of the text area, defaults to 1000
   * @public
   */
  getMaxLength = () => {
    return this.props.maxLength ? this.props.maxLength : 300;
  };

  /**
   * Returns the placeholder to display in the text area, defaults to empty string
   * @public
   */
  getPlaceholder = () => {
    return this.props.placeholder ? this.props.placeholder : "";
  };

  /**
   * Returns if the text area is set to read only, defaults to false
   * @public
   */
  isReadOnly = () => {
    return this.props.readOnly ? this.props.readOnly : false;
  };

  /**
   * Returns the value to display in the text area
   * @public
   */
  getValue = () => {
    return this.props.value;
  };

  /**
   * Function that runs on focus
   * @public
   */
  getOnFocus = (event) => {
    const { onFocus } = this.props;
    return onFocus && event ? onFocus(event) : null;
  };

  /**
   * Function to set a particular style onto the textArea
   * @public
   */
  getStyleType = () => {
    return this.props.styleType ? this.props.styleType : "";
  };

  /**
   * Function on change
   * @private
   */
  getOnChange = (event) => {
    const { onChange } = this.props;
    return onChange ? onChange(event) : null;
  };

  /**
   * Function on Focus
   * @private
   */
  getOnFocus = (event) => {
    const { onFocus } = this.props;
    return onFocus && event ? onFocus(event) : null;
  };

  /**
   * returns prop type
   * @public
   */
  getType = () => {
    const { type } = this.props;
    return type ? type : STYLETYPE_TEXT;
  };

  getRequired = () => {
    const { required } = this.props;
    return required === true;
  };

  getAriaDescribedBy = () => {
    const { ariaDescribedBy } = this.props;
    return ariaDescribedBy ? { "aria-describedby": ariaDescribedBy } : null;
  };

  getAriaLabel = () => {
    const { ariaLabel } = this.props;
    return ariaLabel;
  };

  getMinValue = () => {
    const { min } = this.props;
    return min;
  };

  getMaxValue = () => {
    const { max } = this.props;
    return max;
  };

  getMinLength = () => {
    const { minLength } = this.props;
    return minLength;
  };

  getPattern = () => {
    const { pattern } = this.props;
    return pattern;
  };

  render() {
    return (
      <FormInputElement
        {...this.props}
        {...this.getAriaDescribedBy()}
        styleType={this.getStyleType()}
        type={this.getType()}
        data-name={"FormInput"}
        maxLength={this.getMaxLength()}
        placeholder={this.getPlaceholder()}
        readonly={this.isReadOnly()}
        disabled={this.isDisabled()}
        value={this.getValue()}
        onChange={(event) => {
          this.getOnChange(event.target.value);
        }}
        onFocus={(event) => {
          this.getOnFocus(event.target.value);
        }}
        required={this.getRequired()}
        min={this.getMinValue()}
        max={this.getMaxValue()}
        minLength={this.getMinLength()}
        pattern={this.getPattern()}
        aria-label={this.getAriaLabel()}
      />
    );
  }
}

export default FormInput;
