import app from "./app";
import user from "./user";
import login from "./login";
import organisations from "./organisations";
import propertyGroup from "./propertyGroup";
import role from "./role";
import workflow from "./workflow";
import property from "./property";

export default [
  ...app,
  ...user,
  ...login,
  ...organisations,
  ...propertyGroup,
  ...role,
  ...workflow,
  ...property
];
