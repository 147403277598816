import React, { Component } from "react";
import {
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  DATA_TABLE_SECTION_STYLING,
  PAGINATION_OPTIONS,
  RENDERER_KEYS,
  ORGANISATION_URL,
  RENDERERS_URL,
  REACT_APP_ASSET_PROXY_URL_ENV,
  UPDATE_URL,
  RENDERERS_SECTION,
  UPDATE_PERMISSION,
  PAGINATION_KEY
} from "@base/constants";
import { Container, Wrapper, Section, Image } from "@core";
import { ContextHeader, Loader, DataTable } from "@UIKit";
import {
  getURLPathParameter,
  filterList,
  getSession,
  storeSession
} from "@base/common";

import { navigate } from "@reach/router";

class RendererDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRendererLoading: false,
      fullRenderer: false,
      filteredRenderers: []
    };
  }

  componentDidMount() {
    this.loadDetails();
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganisationRendererLoading } = this.props;
    if (
      prevProps.selectedOrganisationRendererLoading === true &&
      selectedOrganisationRendererLoading === false
    ) {
      const renderer = this.getOrganisationRenderer();
      this.getPointLineAndPolyRenderers(renderer);
      this.setState({ fullRenderer: renderer });
    }
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  loadDetails = async () => {
    const { loadOrganisationRenderer } = this.props;
    const orgId = getURLPathParameter(ORGANISATION_URL);
    const rendererId = getURLPathParameter(RENDERERS_URL);
    loadOrganisationRenderer(orgId, rendererId);
  };

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  getHasPermission = (section, type) => {
    const { orgId, getHasPermission } = this.props;
    return getHasPermission(orgId, section, type);
  };

  getOrganisationRenderer = () => {
    const { selectedOrganisationRenderer } = this.props;
    return selectedOrganisationRenderer
      ? filterList(selectedOrganisationRenderer)
      : false;
  };

  getOrganisationRendererLoading = () => {
    const { selectedOrganisationRendererLoading } = this.props;
    return selectedOrganisationRendererLoading;
  };

  getFullRenderer = () => {
    const { fullRenderer } = this.state;
    return fullRenderer;
  };

  getFilteredRenderersFromState = () => {
    const { filteredRenderers } = this.state;
    return filteredRenderers;
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return this.getFullRenderer() && this.getFullRenderer().type
      ? getLabel("RENDERER_HEADING_TEXT", {
          title: this.getFullRenderer().type
        })
      : getLabel("RENDERER_TEXT");
  };

  getRendererDetailsTitle = (renderer) => {
    const { getLabel } = this.props;
    return getLabel("RENDERER_DETAILS_TITLE", {
      title: Object.keys(renderer)[0]
    });
  };

  getRendererDetailsTableHeaders = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        { content: getLabel("FORM_TYPE_LABEL") },
        { content: getLabel("FORM_VALUE_EXPRESSION_LABEL") },
        { content: getLabel("FORM_DEFAULT_VALUE_LABEL") },
        { content: getLabel("FORM_DEFAULT_SYMBOL_LABEL") }
      ]
    };
  };

  getSymbolForDetailsRowData = (defaultSymbol) => {
    if (!defaultSymbol) return null;

    if (defaultSymbol.url) {
      return (
        <Image
          source={`${REACT_APP_ASSET_PROXY_URL_ENV}/${defaultSymbol.url}`}
        />
      );
    } else if (defaultSymbol.type === "esriSLS") {
      return (
        <div
          style={{
            width: "6vw",
            height: "2px",
            backgroundColor: `rgba(${defaultSymbol.color.join(", ")})`
          }}
        />
      );
    } else if (defaultSymbol.type === "esriSFS") {
      return (
        <div
          style={{
            width: "2vw",
            height: "2vw",
            backgroundColor: `rgba(${defaultSymbol.color.join(", ")})`,
            border: `1px solid rgba(${defaultSymbol.outline.color.join(", ")})`
          }}
        />
      );
    } else if (defaultSymbol.type === "esriSMS") {
      return (
        <div
          style={{
            width: "1vw",
            height: "1vw",
            backgroundColor: `rgba(${defaultSymbol.color.join(", ")})`,
            border: `1px solid rgba(${defaultSymbol.outline.color.join(", ")})`,
            borderRadius: "50%"
          }}
        />
      );
    }
  };

  getRendererDetailsRowData = (rendererObject) => {
    const firstKey = Object.keys(rendererObject)[0];
    const renderer = rendererObject[firstKey];
    const symbol = this.getSymbolForDetailsRowData(renderer.defaultSymbol);
    return [
      {
        cells: [
          renderer.type,
          renderer.valueExpression,
          renderer.defaultLabel,
          symbol
        ]
      }
    ];
  };

  getRendererUniqueValueTitle = (renderer) => {
    const { getLabel } = this.props;
    return getLabel("RENDERER_UNIQUE_VALUES_TITLE", {
      title: Object.keys(renderer)[0]
    });
  };

  getRendererUniqueValueTableHeaders = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        { content: getLabel("FORM_LABEL_LABEL") },
        { content: getLabel("FORM_VALUE_LABEL") },
        { content: getLabel("FORM_ICON_LABEL") }
      ]
    };
  };

  getSymbolForUniqueValueRowData = (item) => {
    if (!item.symbol) return null;
    if (item.symbol.url) {
      return (
        <Image source={`${REACT_APP_ASSET_PROXY_URL_ENV}/${item.symbol.url}`} />
      );
    } else if (item.symbol.type === "esriSLS") {
      return (
        <div
          style={{
            width: "6vw",
            height: "2px",
            backgroundColor: `rgba(${item.symbol.color.join(", ")})`
          }}
        />
      );
    } else if (item.symbol.type === "esriSFS") {
      return (
        <div
          style={{
            width: "2vw",
            height: "2vw",
            backgroundColor: `rgba(${item.symbol.color.join(", ")})`,
            border: `1px solid rgba(${item.symbol.outline.color.join(", ")})`
          }}
        />
      );
    } else if (item.symbol.type === "esriSMS") {
      return (
        <div
          style={{
            width: "1vw",
            height: "1vw",
            backgroundColor: `rgba(${item.symbol.color.join(", ")})`,
            border: `1px solid rgba(${item.symbol.outline.color.join(", ")})`,
            borderRadius: "50%"
          }}
        />
      );
    }
  };

  getRendererUniqueValueRowData = (rendererObject) => {
    const rendererName = Object.keys(rendererObject)[0];
    const uniqueValueInfos = rendererObject[rendererName].uniqueValueInfos;
    const rendererUniqueValueInfosList = this.getRendererUniqueValueInfosList(
      rendererName,
      uniqueValueInfos
    );
    return rendererUniqueValueInfosList.map((item) => {
      const symbol = this.getSymbolForUniqueValueRowData(item);
      return {
        cells: [item.label, item.value, symbol]
      };
    });
  };

  getPointLineAndPolyRenderers = (fullRenderer) => {
    if (!fullRenderer) return;
    const renderers = fullRenderer.renderer;
    let filteredRenderers = [];
    let matchingRendererKeys = [];

    RENDERER_KEYS.flatMap((key) => {
      const rendererKeys = Object.keys(fullRenderer.renderer).filter((r) =>
        r.toLowerCase().includes(key.toLowerCase())
      );
      matchingRendererKeys.push(...rendererKeys);
    });

    matchingRendererKeys.forEach((rendererKey) => {
      const renderer = renderers[rendererKey];
      if (!renderer) return;
      if (
        fullRenderer.type === "layerRenderersJSON" &&
        renderer.type === "uniqueValue"
      ) {
        filteredRenderers.push({ [rendererKey]: renderer });
      } else if (
        fullRenderer.type === "layerLabelsJSON" &&
        (rendererKey.includes("Point") ||
          rendererKey.includes("Poly") ||
          rendererKey.includes("Line"))
      ) {
        filteredRenderers.push({ [rendererKey]: renderer });
      }
    });
    this.setState({ filteredRenderers });
    return filteredRenderers;
  };

  getSpecificRenderer = (rendererType) => {
    const filteredRenderers = this.getFilteredRenderersFromState();
    if (!filteredRenderers) return;
    const rendererObject = filteredRenderers.find((obj) =>
      Object.keys(obj).some((key) => key.includes(rendererType))
    );
    return rendererObject;
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getUniqueValuePageNumber = (rendererName) => {
    const rendererNamePageNumber = `${rendererName}PageNumber`;
    return this.state[rendererNamePageNumber] || 1;
  };

  getUniqueValuePageLimit = (rendererName) => {
    const result = getSession(`renderer-${rendererName}-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getUniqueValueTotal = (renderer) => {
    return renderer?.uniqueValueInfos ? renderer.uniqueValueInfos.length : 0;
  };

  showUniqueValueDropdown = (renderer) => {
    return this.getUniqueValueTotal(renderer) > this.getUniqueValuePageLimit();
  };

  setUniqueValuePaginationContent = (value, rendererName) => {
    const rendererNamePageNumber = `${rendererName}PageNumber`;
    this.setState({ [rendererNamePageNumber]: value });
  };

  getUniqueValuePaginationContent = (rendererObject) => {
    const rendererName = Object.keys(rendererObject)[0];
    const renderer = rendererObject[rendererName];
    if (!renderer) return;
    return {
      setPaginationContent: (value) =>
        this.setUniqueValuePaginationContent(value, rendererName),
      activeNumber: this.getUniqueValuePageNumber(rendererName),
      numberOfButtons: this.getUniqueValuePageLimit(rendererName),
      totalResults: this.getUniqueValueTotal(renderer),
      showPagination: this.showUniqueValueDropdown(renderer),
      paginationInput: true
    };
  };

  getRendererUniqueValueInfosList = (rendererName, uniqueValueInfos) => {
    if (!uniqueValueInfos) return;
    const currentPageNumber = this.getUniqueValuePageNumber(rendererName);
    const pageLimit = this.getUniqueValuePageLimit(rendererName);
    const lastUniqueValueInfo = currentPageNumber * pageLimit;
    const firstUniqueValueInfo = lastUniqueValueInfo - pageLimit;
    return uniqueValueInfos.slice(firstUniqueValueInfo, lastUniqueValueInfo);
  };

  getRendererDetailsView = () => {
    const fullRenderer = this.getFullRenderer();
    const filteredRenderers = this.getFilteredRenderersFromState();
    if (!filteredRenderers || !fullRenderer) return;
    if (fullRenderer.type === "layerRenderersJSON") {
      return this.getLayerRenderersJSONData(filteredRenderers);
    } else if (fullRenderer.type === "layerLabelsJSON") {
      return this.getLayerLabelJSONData(filteredRenderers);
    }
  };

  updateRendererAction = () => {
    const { hideModal, orgId, selectedOrganisationRenderer } = this.props;
    const { rendererId } = selectedOrganisationRenderer;

    hideModal();
    navigate(
      `/${ORGANISATION_URL}/${orgId}/${RENDERERS_URL}/${rendererId}/${UPDATE_URL}`
    );
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    const childLinks = [];
    if (this.getHasPermission(RENDERERS_SECTION, UPDATE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.updateRendererAction,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("RENDERER_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      });
    }
    return {
      childLinks,
      isDisabled: false,
      visible: childLinks.length ? true : false
    };
  };

  getLabelLayerRendererDetailsTableHeaders = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        { content: getLabel("FORM_PROPERTY_LABEL") },
        { content: getLabel("FORM_VALUE_LABEL") }
      ]
    };
  };

  getLabelLayerSymbol = (symbol) => {
    const { getLabel } = this.props;
    if (!symbol) return null;

    const defaultFont = {
      family: "Arial, sans-serif",
      size: 16,
      weight: "normal"
    };

    const defaultColor = [0, 0, 0, 1];
    const defaultHaloColor = null;

    const font = symbol.font || defaultFont;
    const color = symbol.color || defaultColor;
    const haloColor = symbol.haloColor || defaultHaloColor;

    return (
      <div style={{ backgroundColor: "#51B181", padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontFamily: font.family,
              fontSize: `${font.size}px`,
              fontWeight: font.weight,
              color: `rgba(${color.join(", ")})`,
              textShadow: haloColor
                ? `0 0 2px rgba(${haloColor.join(
                    ", "
                  )}), 0 0 5px rgba(${haloColor.join(", ")})`
                : "none",
              margin: "0.5rem"
            }}
          >
            {getLabel("FORM_LABEL_SYMBOL_LABEL")}
          </span>
        </div>
      </div>
    );
  };

  getLabelLayerRendererRowData = (renderer) => {
    const { getLabel } = this.props;
    const rendererObject = Object.values(renderer)[0];
    const expression =
      rendererObject?.labelExpressionInfo?.expression ||
      getLabel("FORM_NO_DATA_LABEL");
    const labelPlacement =
      rendererObject.labelPlacement || getLabel("FORM_NO_DATA_LABEL");
    const symbol =
      this.getLabelLayerSymbol(rendererObject.symbol) ||
      getLabel("FORM_NO_DATA_LABEL");
    return [
      {
        cells: [getLabel("FORM_LABEL_EXPRESSION_LABEL"), expression]
      },
      {
        cells: [getLabel("FORM_LABEL_PLACEMENT_LABEL"), labelPlacement]
      },
      {
        cells: [getLabel("FORM_SYMBOL_LABEL"), symbol]
      }
    ];
  };

  getLayerRenderersJSONData = (filteredRenderers) => {
    return filteredRenderers.map((renderer, index) => {
      const rendererName = Object.keys(renderer)[0];
      return (
        <Container key={index}>
          <Section styleType={DATA_TABLE_SECTION_STYLING}>
            <DataTable
              title={this.getRendererDetailsTitle(
                this.getSpecificRenderer(rendererName)
              )}
              header={this.getRendererDetailsTableHeaders()}
              rows={this.getRendererDetailsRowData(
                this.getSpecificRenderer(rendererName)
              )}
            />
          </Section>
          <Section styleType={DATA_TABLE_SECTION_STYLING}>
            <DataTable
              title={this.getRendererUniqueValueTitle(
                this.getSpecificRenderer(rendererName)
              )}
              header={this.getRendererUniqueValueTableHeaders()}
              rows={this.getRendererUniqueValueRowData(
                this.getSpecificRenderer(rendererName)
              )}
              pagination={this.getUniqueValuePaginationContent(
                this.getSpecificRenderer(rendererName)
              )}
              pageDropDown={this.getUniqueValuePageDropdown(
                this.getSpecificRenderer(rendererName)
              )}
            />
          </Section>
        </Container>
      );
    });
  };

  getLayerLabelJSONData = (filteredRenderers) => {
    return filteredRenderers.map((renderer, index) => {
      const rendererName = Object.keys(renderer)[0];
      return (
        <Container key={index}>
          <Section styleType={DATA_TABLE_SECTION_STYLING}>
            <DataTable
              title={this.getRendererDetailsTitle(
                this.getSpecificRenderer(rendererName)
              )}
              header={this.getLabelLayerRendererDetailsTableHeaders()}
              rows={this.getLabelLayerRendererRowData(
                this.getSpecificRenderer(rendererName)
              )}
            />
          </Section>
        </Container>
      );
    });
  };

  getDropDownLabel = (limit) => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: limit
    });
  };

  dropDownOnSelectFunction = (value, rendererObject) => {
    const rendererName = Object.keys(rendererObject)[0];
    storeSession(`renderer-${rendererName}-${PAGINATION_KEY}`, value);
    this.setUniqueValuePaginationContent(1, rendererName);
  };

  getUniqueValuePageDropdown = (rendererObject) => {
    const rendererName = Object.keys(rendererObject)[0];
    const renderer = rendererObject[rendererName];
    if (!renderer) return;
    return {
      showDropdown: this.showUniqueValueDropdown(renderer),
      dropDownValue: this.getUniqueValuePageLimit(rendererName),
      dropDownLabel: this.getDropDownLabel(
        this.getUniqueValuePageLimit(rendererName)
      ),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: (value) =>
        this.dropDownOnSelectFunction(value, rendererObject)
    };
  };

  render() {
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.getOrganisationRendererLoading() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={this.getContextMenu()}
              />
            </Container>
            {!this.getFilteredRenderersFromState() ? (
              <Loader fullPage={false} />
            ) : (
              this.getRendererDetailsView()
            )}
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default RendererDetail;
