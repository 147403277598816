import { createActions } from "redux-actions";
import {
  LOAD_PROPERTY_GROUP,
  LOADED_PROPERTY_GROUP,
  LOAD_PROPERTY_GROUP_USERS,
  LOADED_PROPERTY_GROUP_USERS,
  LOAD_PROPERTY_GROUP_WORKFLOWS,
  LOADED_PROPERTY_GROUP_WORKFLOWS,
  CREATE_PROPERTY_GROUP,
  UPDATE_PROPERTY_GROUP,
  UPDATED_PROPERTY_GROUP,
  REMOVE_PROPERTY_GROUP,
  REMOVED_PROPERTY_GROUP,
  LOAD_PROPERTY_GROUP_PROPERTIES,
  LOADED_PROPERTY_GROUP_PROPERTIES,
  SET_GROUP_USERS_PAGINATION,
  ADD_PROPERTIES_TO_GROUPS
} from "@base/constants";

export const {
  loadPropertyGroup,
  loadedPropertyGroup,
  loadPropertyGroupUsers,
  loadedPropertyGroupUsers,
  loadPropertyGroupWorkflows,
  loadedPropertyGroupWorkflows,
  createPropertyGroup,
  updatePropertyGroup,
  updatedPropertyGroup,
  removePropertyGroup,
  removedPropertyGroup,
  loadPropertyGroupProperties,
  loadedPropertyGroupProperties,
  setGroupUsersPagination,
  addPropertiesToGroups
} = createActions({
  [LOAD_PROPERTY_GROUP]: (orgId, groupId, returnProperties) => ({
    orgId,
    groupId,
    returnProperties
  }),
  [LOADED_PROPERTY_GROUP]: (propertyGroup) => propertyGroup,
  [LOAD_PROPERTY_GROUP_USERS]: (orgId, groupId) => ({ orgId, groupId }),
  [LOADED_PROPERTY_GROUP_USERS]: (propertyGroupUsers) => propertyGroupUsers,
  [LOAD_PROPERTY_GROUP_WORKFLOWS]: (orgId, groupId) => ({
    orgId,
    groupId
  }),
  [LOADED_PROPERTY_GROUP_WORKFLOWS]: (groupId, propertyGroupWorkflows) => ({
    groupId,
    propertyGroupWorkflows
  }),
  [CREATE_PROPERTY_GROUP]: (orgId, title, description, properties) => ({
    orgId,
    title,
    description,
    properties
  }),
  [UPDATE_PROPERTY_GROUP]: (orgId, groupId, updates) => ({
    orgId,
    groupId,
    updates
  }),
  [UPDATED_PROPERTY_GROUP]: () => {},
  [REMOVE_PROPERTY_GROUP]: (orgId, groupId) => ({ orgId, groupId }),
  [REMOVED_PROPERTY_GROUP]: () => {},
  [LOAD_PROPERTY_GROUP_PROPERTIES]: (
    orgId,
    groupId,
    page,
    offset,
    limit,
    search
  ) => ({
    orgId,
    groupId,
    page,
    offset,
    limit,
    search
  }),
  [LOADED_PROPERTY_GROUP_PROPERTIES]: (propertyResults) => propertyResults,
  [SET_GROUP_USERS_PAGINATION]: (page, limit, offset, search) => ({
    page,
    limit,
    offset,
    search
  }),
  [ADD_PROPERTIES_TO_GROUPS]: (orgId, groupIds, propIds) => ({
    orgId,
    groupIds,
    propIds
  })
});
