import React, { Component } from "react";
import { navigate } from "@reach/router";
import { Container, Wrapper, Section, LinkButton } from "@core";
import {
  ContextHeader,
  DataTable,
  Loader,
  BottomButtonBar,
  defaultTheme
} from "@UIKit";
import { getURLPathParameter, filterList } from "@base/common";
import {
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  RENDERERS_URL,
  ORGANISATION_URL,
  CREATE_PERMISSION,
  RENDERERS_SECTION,
  BUTTON_TYPE_PRIMARY_FULLWIDTH,
  NEW_URL
} from "@base/constants";

class RendererOverview extends Component {
  componentDidMount() {
    this.loadDetails();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  loadDetails = () => {
    const { loadOrganisationRenderers } = this.props;
    const orgId = getURLPathParameter(ORGANISATION_URL);
    loadOrganisationRenderers(orgId);
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("RENDERERS_TEXT");
  };

  getSecondHeaderText = () => {
    const { orgId, getLabel, availableOrganisations } = this.props;
    if (!availableOrganisations || !availableOrganisations.length) return "";
    const org = availableOrganisations.find((org) => org.orgId === orgId);
    if (!org) return "";
    return getLabel("RENDERERS_FOR_ORG_TEXT", {
      item: org.title
    });
  };

  getTableHeader = () => {
    const { getLabel } = this.props;
    return {
      cells: [{ content: getLabel("RENDERER_TEXT") }, { content: "ID" }]
    };
  };

  getTableData = () => {
    const orgId = getURLPathParameter(ORGANISATION_URL);
    const orgRenderers = this.getOrganisationRenderers();
    if (!orgRenderers || !orgRenderers.length) return [];
    const filteredRenderers = orgRenderers.filter((rendererObject) => {
      if (!rendererObject.renderer) return false;
      if (rendererObject.type === "layerLabelsJSON") return true;
      return Object.values(rendererObject.renderer).some((property) => {
        return typeof property === "object" && property.type === "uniqueValue";
      });
    });
    if (!filteredRenderers) return [];
    return filteredRenderers
      .filter((renderer) => renderer.type)
      .map((renderer) => {
        const title = (
          <LinkButton
            url={`/${orgId}/${RENDERERS_URL}/${renderer.rendererId}`}
            buttonLabel={renderer.type}
            buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
          />
        );
        return {
          cells: [title, renderer.rendererId]
        };
      });
  };

  getOrganisationRenderers = () => {
    const { selectedOrganisationRenderers } = this.props;
    return selectedOrganisationRenderers
      ? filterList(selectedOrganisationRenderers)
      : false;
  };

  getOrganisationRenderersLoading = () => {
    const { selectedOrganisationRenderersLoading } = this.props;
    return selectedOrganisationRenderersLoading;
  };

  getNoResultsText = () => {
    const { getLabel } = this.props;
    return getLabel("NO_RENDERERS_TEXT");
  };

  hasCreatePermission = () => {
    const { getHasPermission, orgId } = this.props;
    return getHasPermission(orgId, RENDERERS_SECTION, CREATE_PERMISSION);
  };

  createNewRendererLink = () => {
    const { orgId } = this.props;
    navigate(`/${ORGANISATION_URL}/${orgId}/${RENDERERS_URL}/${NEW_URL}`);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY_FULLWIDTH,
        isDisabled: false,
        onClick: this.createNewRendererLink,
        buttonLabel: getLabel("ADD_NEW_LABEL", {
          item: getLabel("RENDERER_TEXT")
        }),
        icon: {
          type: "plus",
          bgWidth: "25px",
          bgHeight: "15px",
          iconHeight: "16px",
          iconWidth: "15px",
          iconColor: defaultTheme.agWhite
        }
      }
    ];
  };

  render() {
    return (
      <Container styleType={CONTENT_VIEW}>
        <Wrapper data-name={"Wrapper"} subStyle="App" styleType={CONTENT_VIEW}>
          <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
            <ContextHeader
              headerTitle={this.createHeaderText()}
              contextMenu={{ visible: false }}
            />
          </Container>
          <Section styleType={DATA_TABLE_SECTION_STYLING}>
            {this.getOrganisationRenderersLoading() ? (
              <Loader fullPage={false} />
            ) : (
              <DataTable
                noResultsText={this.getNoResultsText()}
                title={this.getSecondHeaderText()}
                tableFilter={{
                  showFilter: false
                }}
                header={this.getTableHeader()}
                rows={this.getTableData()}
              />
            )}
          </Section>
          {this.hasCreatePermission() && (
            <BottomButtonBar buttons={this.getButtons()} />
          )}
        </Wrapper>
      </Container>
    );
  }
}

export default RendererOverview;
