import styled, { css } from "styled-components";
import { DATA_TABLE_FILTER_INPUT_STYLING } from "@base/constants";

// @ts-ignore

export const FilterInputElement = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;
  flex-grow: 0;
  flex-shrink: 100;
  padding: 0 0.1rem;

  input {
    width: calc(100% - 41rem);
    min-width: 18.75rem;
    padding-right: 2.5rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #ffffff;
    border-radius: 0.125rem;
    opacity: 1;
  }

  label {
    width: auto;
    margin-right: 1.688rem;
    letter-spacing: 0;
    font-family: ${(props) => props.theme.baseFont};
    font-weight: ${(props) => props.theme.baseFontWeight};
    color: ${(props) => props.theme.agDarkestGray};
  }

  button {
    position: absolute;
    right: 0.313rem;
    top: 0.375rem;
    border: none;
    flex-grow: 100;
  }

  ${(props) =>
    props.styleType === DATA_TABLE_FILTER_INPUT_STYLING &&
    css`
      padding: 0;
      margin-right: 0;
      margin-left: auto;

      input {
        padding: 0.1rem;
        border: 1px solid #a7a9ac;
        ${(props) =>
          props.isDisabled === true &&
          css`
            border: 1px solid #d4d4d4;
          `}
      }
      button {
        right: 0rem;
        top: 0rem;
      }
      ${(props) =>
        props.isDisabled === true &&
        css`
          opacity: 0.5;
        `}
    `}
`;
FilterInputElement.displayName = "FilterInputElement";
