import { connect } from "react-redux";
import ProtectedRoute from "./ProtectedRoute";
import * as actions from "../../actions";

import { getLabels } from "../../selectors";

export default connect(
  (state) => ({
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data })
  }),
  actions
)(ProtectedRoute);
