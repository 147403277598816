import React, { Component } from "react";
import { ListContent, ContextHeader, Loader } from "@UIKit";
import { Heading3 } from "@typography";
import { Section, Container, Wrapper, Table, Icon } from "@core";
import { navigate } from "@reach/router";
import { defaultTheme } from "@UIKit";
import {
  PROPERTY_GROUPS_URL,
  PROPERTIES_URL,
  WORKFLOWS_URL,
  UPDATE_URL,
  STATUS_EMPTY,
  STATUS_UPDATING,
  STATUS_LOADING,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  WORKFLOW_DETAILS_TABLES_STYLETYPE,
  LINK_BUTTON,
  WORKFLOWS_SECTION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
  PROPERTIES_SECTION,
  PROPERTY_GROUPS_SECTION,
  READ_PERMISSION,
  SECTION_HEADER_STYLING,
  ORGANISATION_URL
} from "@base/constants";

class WorkflowDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTypes: null,
      drawingTools: null,
      featureList: null,
      permissions: null,
      domainValues: null,
      displayTitles: null,
      workflowState: null,
      defaultValues: null,
      validationRules: null,
      customAttributes: null,
      hiddenAttributes: null,
      contextualAttributes: null,
      nonEditableAttributes: null
    };
  }

  componentDidMount() {
    this.setupWorkflowDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  static getDerivedStateFromProps(props, state) {
    const { selectedWorkflow, workflowId } = props;
    if (
      selectedWorkflow &&
      selectedWorkflow.state &&
      selectedWorkflow.workflowId === workflowId &&
      !state.featureList &&
      !state.permissions &&
      !state.domainValues &&
      !state.displayTitles &&
      !state.workflowState &&
      !state.defaultValues &&
      !state.validationRules &&
      !state.customAttributes &&
      !state.hiddenAttributes &&
      !state.contextualAttributes &&
      !state.nonEditableAttributes &&
      !state.searchTypes &&
      !state.drawingTools
    ) {
      const {
        searchTypes,
        drawingTools,
        featureList,
        permissions,
        domainValues,
        displayTitles,
        defaultValues,
        validationRules,
        customAttributes,
        hiddenAttributes,
        contextualAttributes,
        nonEditableAttributes
      } = selectedWorkflow.state;
      return {
        searchTypes,
        drawingTools,
        featureList,
        permissions,
        domainValues,
        displayTitles,
        defaultValues,
        validationRules,
        customAttributes,
        hiddenAttributes,
        contextualAttributes,
        nonEditableAttributes,
        workflowState: selectedWorkflow.state
      };
    }
    return state;
  }

  componentDidUpdate() {
    const { setPageTitle, pageTitle } = this.props;
    const workflow = this.getCurrentWorkflow();
    if (workflow && workflow.title && pageTitle !== workflow.title) {
      setPageTitle(workflow.title);
    }
  }

  setupWorkflowDetails = () => {
    const { orgId, workflowId, loadWorkflow } = this.props;
    loadWorkflow(orgId, workflowId);
    this.loadWorkflowProperties();
    this.loadWorkflowPropertyGroups();
  };

  loadWorkflowProperties = () => {
    const { orgId, workflowId, loadWorkflowProperties } = this.props;
    if (!this.getHasPermission(PROPERTIES_SECTION, READ_PERMISSION)) return;
    loadWorkflowProperties(orgId, workflowId);
  };

  loadWorkflowPropertyGroups = () => {
    const { orgId, workflowId, loadWorkflowPropertyGroups } = this.props;
    if (!this.getHasPermission(PROPERTY_GROUPS_SECTION, READ_PERMISSION))
      return;
    loadWorkflowPropertyGroups(orgId, workflowId);
  };

  getCurrentWorkflow = () => {
    const { selectedWorkflow } = this.props;
    return selectedWorkflow ? selectedWorkflow : false;
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return this.getCurrentWorkflow() && this.getCurrentWorkflow().title
      ? getLabel("WORKFLOW_HEADING_TEXT", {
          title: this.getCurrentWorkflow().title
        })
      : getLabel("WORKFLOW_TEXT");
  };

  createPropertyGroupsHeader = () => {
    const { getLabel } = this.props;

    return this.getCurrentWorkflow() &&
      this.getCurrentWorkflow().propertyGroups &&
      this.getCurrentWorkflow().propertyGroups.length > 0
      ? getLabel("WORKFLOW_PROPERTY_GROUP_SUBHEADING", {
          count: this.getCurrentWorkflow().propertyGroups.length
        })
      : getLabel("WORKFLOW_NO_DETAIL_PROPERTY_GROUP");
  };

  createPropertiesHeader = () => {
    const { getLabel } = this.props;

    return this.getCurrentWorkflow() &&
      this.getCurrentWorkflow().propIds &&
      this.getCurrentWorkflow().propIds.length > 0
      ? getLabel("WORKFLOW_PROPERTY_SUBHEADING", {
          count: this.getCurrentWorkflow().propIds.length
        })
      : getLabel("WORKFLOW_NO_DETAIL_PROPERTY");
  };

  createRoleHeader = () => {
    const { getLabel } = this.props;

    return this.getCurrentWorkflow() &&
      this.getCurrentWorkflow().roleIds &&
      this.getCurrentWorkflow().roleIds.length > 0
      ? getLabel("WORKFLOW_ROLE_SUBHEADING", {
          count: this.getCurrentWorkflow().roleIds.length
        })
      : getLabel("WORKFLOW_NO_DETAIL_ROLE");
  };

  createWorkflowSubHeader = (subHeader) => {
    const { getLabel } = this.props;
    return this.getCurrentWorkflow() &&
      this.getCurrentWorkflow().featureServicesV2 &&
      this.getCurrentWorkflow().featureServicesV2.length !== 0
      ? getLabel("WORKFLOW_DETAILS_SUBHEADING", { title: getLabel(subHeader) })
      : getLabel("NO_WORKFLOW_DETAILS_SUBHEADING", {
          title: getLabel(subHeader)
        });
  };

  getCurrentWorkflowPropertyGroups = () => {
    const {
      getLabel,
      selectedWorkflowStatus,
      selectedWorkflowPropertyGroups,
      selectedWorkflowPropertyGroupsLoading
    } = this.props;
    const { LOADING_LABEL } = this.props.getLabel();
    if (
      !this.getCurrentWorkflow() ||
      selectedWorkflowStatus === STATUS_LOADING ||
      selectedWorkflowStatus === STATUS_UPDATING
    ) {
      return [
        {
          icon: {
            type: "loading",
            spinning: true,
            iconWidth: "25px",
            iconHeight: "25px",
            bgColor: defaultTheme.agBrightGreen,
            iconColor: defaultTheme.agWhite,
            bgShape: "round"
          },
          headerLabel: LOADING_LABEL,
          childLinks: []
        }
      ];
    }

    const { orgId } = this.props;

    const propertyGroups = this.getCurrentWorkflow().propertyGroups;

    if (!propertyGroups || propertyGroups.length === 0)
      return [
        {
          headerLabel: getLabel("NO_DATA_FOUND_TEXT", {
            dataItem: getLabel("PROPERTY_GROUPS_TEXT")
          }),
          childLinks: []
        }
      ];

    const listOfPropGroups = propertyGroups.map((propertyGroup) => {
      const title =
        selectedWorkflowPropertyGroups &&
        selectedWorkflowPropertyGroups.find(
          (group) => propertyGroup === group.groupId
        ) &&
        selectedWorkflowPropertyGroups.find(
          (group) => propertyGroup === group.groupId
        ).title
          ? selectedWorkflowPropertyGroups.find(
              (group) => propertyGroup === group.groupId
            ).title
          : selectedWorkflowPropertyGroupsLoading
          ? `${propertyGroup} ${getLabel("LOADING_TITLE_TEXT")}`
          : propertyGroup;

      return {
        icon: selectedWorkflowPropertyGroupsLoading
          ? {
              type: "loading",
              spinning: true,
              iconWidth: "25px",
              iconHeight: "25px",
              bgColor: defaultTheme.agBrightGreen,
              iconColor: defaultTheme.agWhite,
              bgShape: "round"
            }
          : null,
        headerLabel: title,
        childLinks: [],
        buttonType: LINK_BUTTON,
        url: propertyGroup.groupId
          ? `/${orgId}/${PROPERTY_GROUPS_URL}/${propertyGroup.groupId}`
          : `/${orgId}/${PROPERTY_GROUPS_URL}/${propertyGroup}`
      };
    });
    return listOfPropGroups;
  };

  getCurrentWorkflowProperties = () => {
    const {
      getLabel,
      selectedWorkflowStatus,
      selectedWorkflowProperties,
      selectedWorkflowPropertiesLoading,
      orgId
    } = this.props;
    const { LOADING_LABEL } = this.props.getLabel();

    if (
      !this.getCurrentWorkflow() ||
      selectedWorkflowStatus === STATUS_LOADING ||
      selectedWorkflowStatus === STATUS_UPDATING
    ) {
      return [
        {
          icon: {
            type: "loading",
            spinning: true,
            iconWidth: "25px",
            iconHeight: "25px",
            bgColor: defaultTheme.agBrightGreen,
            iconColor: defaultTheme.agWhite,
            bgShape: "round"
          },
          headerLabel: LOADING_LABEL,
          childLinks: []
        }
      ];
    }

    const properties = this.getCurrentWorkflow().propIds;

    if (!properties || properties.length === 0)
      return [
        {
          headerLabel: getLabel("NO_DATA_FOUND_TEXT", {
            dataItem: getLabel("PROPERTIES_TEXT")
          }),
          childLinks: []
        }
      ];

    const listOfProperties = properties.map((propId) => {
      let title = propId;
      if (selectedWorkflowProperties) {
        const matchingProperty = selectedWorkflowProperties.find(
          (property) => propId === property.propId
        );
        if (matchingProperty && matchingProperty.title)
          title = matchingProperty.title;
      }
      if (selectedWorkflowPropertiesLoading)
        title = `${propId} ${getLabel("LOADING_TITLE_TEXT")}`;

      return {
        icon: selectedWorkflowPropertiesLoading
          ? {
              type: "loading",
              spinning: true,
              iconWidth: "25px",
              iconHeight: "25px",
              bgColor: defaultTheme.agBrightGreen,
              iconColor: defaultTheme.agWhite,
              bgShape: "round"
            }
          : null,
        headerLabel: title,
        childLinks: [],
        buttonType: LINK_BUTTON,
        url: propId.propId
          ? `/${orgId}/${PROPERTIES_URL}/${propId.propId}`
          : `/${orgId}/${PROPERTIES_URL}/${propId}`
      };
    });
    return listOfProperties;
  };

  getCurrentWorkflowFeatureServices = () => {
    const { getLabel, selectedWorkflowStatus } = this.props;
    const { LOADING_LABEL } = this.props.getLabel();
    if (
      !this.getCurrentWorkflow() ||
      selectedWorkflowStatus === STATUS_LOADING ||
      selectedWorkflowStatus === STATUS_UPDATING
    ) {
      return [
        {
          icon: {
            type: "loading",
            spinning: true,
            iconWidth: "25px",
            iconHeight: "25px",
            bgColor: defaultTheme.agBrightGreen,
            iconColor: defaultTheme.agWhite,
            bgShape: "round"
          },
          headerLabel: LOADING_LABEL,
          childLinks: []
        }
      ];
    }

    const featureServices = this.getCurrentWorkflow().featureServicesV2;
    if (!featureServices || featureServices.length === 0)
      return [
        {
          headerLabel: getLabel("NO_DATA_FOUND_TEXT", {
            dataItem: getLabel("WORKFLOW_DETAILS_FEATURE_SERVICES_SUBHEADING")
          }),
          childLinks: []
        }
      ];

    const listOffeatureServices = featureServices.map(
      ({ featureService, layers }) => {
        return {
          headerLabel: `${featureService}: (${layers.join(", ")})`
        };
      }
    );
    return listOffeatureServices;
  };

  getCurrentWorkflowPluginTable = () => {
    const { getLabel } = this.props;
    const selectedWorkflow = this.getCurrentWorkflow();
    const plugins = selectedWorkflow?.state?.plugins;
    if (!plugins || this.isEmpty(plugins)) return null;

    return (
      <Table>
        <thead>
          <tr>
            <th>{getLabel("WORKFLOW_DETAILS_PLUGIN_SUBHEADING")}</th>
            <th>{this.capitaliseFirstChar(getLabel("POSITION_LABEL"))}</th>
            <th>{this.capitaliseFirstChar(getLabel("ENABLED_LABEL"))}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(plugins).map(([pluginName, pluginData]) => (
            <tr key={pluginName}>
              <td>{this.capitaliseFirstChar(pluginName)}</td>
              <td>{this.capitaliseFirstChar(pluginData.position)}</td>
              <td>{this.capitaliseFirstChar(pluginData.enabled.toString())}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  capitaliseFirstChar = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  getHasPermission = (section, type) => {
    const { orgId, getHasPermission } = this.props;
    return getHasPermission(orgId, section, type);
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    const childLinks = [];
    if (this.getHasPermission(WORKFLOWS_SECTION, UPDATE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.updateWorkflowAction,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("WORKFLOW_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      });
    }
    if (this.getHasPermission(WORKFLOWS_SECTION, DELETE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.showDeleteConfirmation,
        buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
          item: getLabel("WORKFLOW_TEXT")
        })
      });
    }
    return {
      childLinks,
      isDisabled: false,
      visible: childLinks.length ? true : false
    };
  };

  showDeleteConfirmation = () => {
    const { showModal, setModalContent, getLabel, hideModal } = this.props;

    setModalContent({
      header: getLabel("CONFIRM_DELETE_HEADER", {
        item: getLabel("WORKFLOW_TEXT")
      }),
      body: getLabel("CONFIRM_DELETE_BODY", {
        item: getLabel("WORKFLOW_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
            item: getLabel("WORKFLOW_TEXT")
          }),
          onClick: this.deleteAction
        },
        {
          buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
          onClick: hideModal
        }
      ]
    });
    showModal();
    return null;
  };

  deleteAction = async () => {
    const { removeWorkflow, orgId, workflowId } = this.props;
    await removeWorkflow(orgId, workflowId);
    this.showDeleteSuccess();
  };

  showDeleteSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_DELETED_LABEL", {
        item: getLabel("WORKFLOW_TEXT")
      }),
      body: getLabel("ITEM_DELETED_SUCCESS", {
        item: getLabel("WORKFLOW_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("WORKFLOW_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  returnToOverviewAction = () => {
    const { hideModal, orgId } = this.props;

    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${WORKFLOWS_URL}/`);
  };

  updateWorkflowAction = () => {
    const { hideModal, orgId, selectedWorkflow } = this.props;
    const { workflowId } = selectedWorkflow;

    hideModal();
    navigate(
      `/${ORGANISATION_URL}/${orgId}/${WORKFLOWS_URL}/${workflowId}/${UPDATE_URL}`
    );
  };

  isWorkflowDetailsLoading = () => {
    const { selectedWorkflowStatus } = this.props;
    return (
      selectedWorkflowStatus === STATUS_EMPTY ||
      selectedWorkflowStatus === STATUS_LOADING
    );
  };

  isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  getNameValues = (attr) => {
    const { displayTitles } = this.state;
    return displayTitles[attr] ? displayTitles[attr] : attr;
  };

  getRegularDomainValueTexts = (domainValues) => {
    if (!domainValues) return "";
    const domainValueItems = domainValues
      .reduce((result, domainValue) => {
        const { title, value } = domainValue;
        const valueItem = title ? `${title} (${value})` : value;
        return [...result, valueItem];
      }, [])
      .join(", ");
    return domainValueItems;
  };

  getContextualDomainValueTexts = (domainValues) => {
    if (!domainValues) return "";
    const { getLabel } = this.props;

    const items = domainValues.reduce((result, item) => {
      const { filterField, filterValue, title, value } = item;
      const filterFields = Array.isArray(filterField)
        ? filterField
        : [filterField];
      const filterValues = Array.isArray(filterValue)
        ? filterValue
        : [filterValue];
      if (filterFields.length > 1 && filterFields.length < filterValues.length)
        return result;
      const valueString = title ? `${title} (${value})` : value;
      if (
        filterFields.length === 1 &&
        filterFields.length < filterValues.length
      ) {
        const key = filterFields[0];
        const item = result[key] ? result[key] : {};
        filterValues.forEach((valueItem) => {
          const fieldValueItem = item[valueItem] ? item[valueItem] : [];
          item[valueItem] = [...fieldValueItem, valueString];
        });
        return {
          ...result,
          [key]: item
        };
      } else {
        filterFields.forEach((field, i) => {
          const fieldItem = Object.prototype.hasOwnProperty.call(result, field)
            ? result[field]
            : {};
          const valueKey = filterValues[i];
          const fieldValueItem = fieldItem[valueKey] ? fieldItem[valueKey] : [];
          result[field] = {
            ...fieldItem,
            [valueKey]: [...fieldValueItem, valueString]
          };
        });

        return result;
      }
    }, {});

    return Object.keys(items).reduce((result, filterField) => {
      const item = items[filterField];
      return [
        ...result,
        ...Object.keys(item).map((filterValue) => {
          return getLabel("WORKFLOW_TYPES_ARRAY_VALUES", {
            item: filterValue,
            filterField,
            values: item[filterValue].join(", ")
          });
        })
      ];
    }, []);
  };

  getAttrDomainValues = (domainValues) => {
    const { getLabel } = this.props;

    const contextualDomainValues = [];
    const regularDomainValues = [];

    // domainValues.forEach((domainValue) => {
    //   const { filterField, filterValue } = domainValue;
    //   if (filterField || filterValue) {
    //     contextualDomainValues.push(domainValue);
    //   } else {
    //     regularDomainValues.push(domainValue);
    //   }
    // });

    const regularDomainValueTexts =
      this.getRegularDomainValueTexts(domainValues);
    // const contextualDomainValueTexts = this.getContextualDomainValueTexts(
    //   contextualDomainValues
    // );
    const texts = [
      regularDomainValueTexts
      //...contextualDomainValueTexts
    ].filter((text) => text !== "");
    return `${getLabel("TYPE_DROPDOWN_TEXT")}: ${texts.join("\n")}`;
  };

  getTypeValues = (attr) => {
    const { getLabel } = this.props;
    const { displayTitles, domainValues, customAttributes } = this.state;
    if (
      !domainValues ||
      !customAttributes ||
      this.isEmpty(domainValues) ||
      this.isEmpty(customAttributes)
    )
      return null;
    let type;
    if (
      domainValues &&
      Object.prototype.hasOwnProperty.call(domainValues, attr)
    ) {
      type = this.getAttrDomainValues(domainValues[attr]);
    } else if (
      displayTitles[attr] &&
      Object.prototype.hasOwnProperty.call(customAttributes, attr)
    ) {
      const dataType = customAttributes[attr].type;
      if (dataType === "date") {
        type = getLabel("DATA_TYPE_DATE");
      } else if (dataType === "integer" || dataType === "int") {
        type = getLabel("DATA_TYPE_NUMBER");
      } else if (dataType === "percentage") {
        type = getLabel("DATA_TYPE_PERCENTAGE");
      } else if (dataType === "boolean") {
        type = getLabel("DATA_TYPE_BOOLEAN");
      } else if (dataType === "double") {
        type = getLabel("DATA_TYPE_DOUBLE");
      } else if (
        (dataType === "string" || dataType === "String") &&
        (!domainValues || !domainValues[attr])
      ) {
        type = getLabel("FREE_TEXT_LABEL");
      }
    }
    return type;
  };

  getDefaultValues = (attr) => {
    const { getLabel } = this.props;
    const { defaultValues, domainValues } = this.state;
    if (!defaultValues || this.isEmpty(defaultValues)) return null;
    const customDefaultValue =
      domainValues[attr] &&
      defaultValues[attr] !== undefined &&
      domainValues[attr].reduce((result, acc) => {
        if (acc.value === defaultValues[attr] && acc.title) {
          return `(${acc.value}) ${acc.title}`;
        }
        return result;
      }, "");

    const customDefaultArrayValue = Array.isArray(defaultValues[attr])
      ? defaultValues[attr].reduce((result, acc) => {
          if (acc) {
            const defaultArrayValues = getLabel(
              "WORKFLOW_DEFAULT_ARRAY_VALUES",
              {
                filterField: acc.filterField,
                filterValue: acc.filterValue,
                value: acc.value
              }
            );
            return [...result, defaultArrayValues];
          }
        }, [])
      : false;

    const renderCustomDefaultArrayValue =
      customDefaultArrayValue &&
      customDefaultArrayValue.map((i) => {
        return (
          <ul key={i}>
            <li key={i}>{i}</li>
          </ul>
        );
      });

    const value =
      customDefaultValue !== undefined
        ? customDefaultValue
        : customDefaultArrayValue
        ? renderCustomDefaultArrayValue
        : defaultValues[attr];

    return value;
  };

  getRulesArrayValues = (rule) => {
    const { getLabel } = this.props;

    const rulesObject = Object.keys(rule).reduce((result, item) => {
      const ruleAttribute = rule[item];

      if (Array.isArray(ruleAttribute)) {
        let returnRule = rule[item].map((ruleTest) => {
          return getLabel("WORKFLOW_RULES_ARRAY_VALUES", {
            filterField: ruleTest.filterField,
            filterValue: ruleTest.filterValue,
            minMaxText: item,
            value: ruleTest.value,
            softKey: ruleTest.soft ? "Soft" : ""
          });
        });

        if (item === "min") {
          returnRule.splice(0, 0, `${getLabel("MINIMUM_TEXT")}: `);
        } else if (item === "max") {
          returnRule.splice(0, 0, `${getLabel("MAXIMUM_TEXT")}: `);
        }

        return [...result, returnRule];
      } else {
        const itemValue =
          item === "required" ? getLabel("RULE_REQUIRED_LABEL") : "";
        return [itemValue, ...result];
      }
    }, []);

    return rulesObject.map((i) => {
      if (Array.isArray(i)) {
        return i.map((a) => {
          return (
            <ul key={a}>
              <li key={a}>{a}</li>
            </ul>
          );
        });
      } else {
        return (
          <ul key={i}>
            <li key={i}>{i}</li>
          </ul>
        );
      }
    });
  };

  getRulesObjectValues = (rule) => {
    const { getLabel } = this.props;
    if (rule.required) {
      return getLabel("RULE_REQUIRED_LABEL");
    } else if (rule.maxLength && !rule.minLength) {
      return getLabel("RULE_MAX_CHAR_LENGTH_LABEL", { item: rule.maxLength });
    } else if (rule.minLength && !rule.maxLength) {
      return getLabel("RULE_MIN_CHAR_LENGTH_LABEL", { item: rule.minLength });
    } else if (rule.minLength && rule.maxLength) {
      return getLabel("RULE_MIN_MAX_CHAR_LENGTH_LABEL", {
        minLength: rule.minLength,
        maxLength: rule.maxLength
      });
    } else if (rule.max && !rule.min) {
      return getLabel("RULE_MAX_VALUE_LABEL", {
        item: rule.max
      });
    } else if (rule.min && !rule.max) {
      return getLabel("RULE_MIN_VALUE_LABEL", {
        item: rule.min
      });
    } else if (rule.min && rule.max) {
      return getLabel("RULE_MIN_MAX_VALUE_LABEL", {
        min: rule.min,
        max: rule.max
      });
    } else if (rule.minDate && !rule.maxDate) {
      return getLabel("RULE_DATE_AFTER_LABEL", { item: rule.minDate });
    } else if (rule.maxDate && !rule.minDate) {
      return getLabel("RULE_DATE_BEFORE_LABEL", { item: rule.maxDate });
    } else if (rule.maxDate && rule.minDate) {
      return getLabel("RULE_DATE_BETWEEN_LABEL", {
        maxDate: rule.maxDate,
        minDate: rule.minDate
      });
    } else if (rule.pattern) {
      return getLabel("RULE_REGEX_PATTERN_LABEL", { item: rule.pattern });
    }
  };

  getRulesValues = (attr) => {
    const { getLabel } = this.props;
    const { validationRules, hiddenAttributes, nonEditableAttributes } =
      this.state;
    if (
      !validationRules ||
      this.isEmpty(validationRules) ||
      !hiddenAttributes ||
      !nonEditableAttributes
    )
      return null;
    let rules;
    if (nonEditableAttributes.includes(attr)) {
      rules = getLabel("RULE_READ_ONLY_LABEL");
    } else if (hiddenAttributes.includes(attr)) {
      rules = getLabel("RULE_HIDDEN_LABEL");
    } else if (Object.prototype.hasOwnProperty.call(validationRules, attr)) {
      const rule = validationRules[attr];

      // Checks if any rule attributes item is array
      const haveArray = Object.keys(rule).map((item) => {
        return Array.isArray(rule[item]);
      });
      rules = haveArray.includes(true)
        ? this.getRulesArrayValues(rule)
        : this.getRulesObjectValues(rule);
    }
    return rules;
  };

  getFilterItem = (item) => {
    const { filterValue, filterField, filters } = item;
    if (!filterField && !filterValue && !filters) return [];
    let attributeFilters = filters ? filters : [];
    if (filterValue && filterField) {
      attributeFilters = [
        {
          filterValue,
          filterField
        }
      ];
    }
    attributeFilters = attributeFilters.map(({ filterValue, filterField }) => ({
      filterValue: Array.isArray(filterValue) ? filterValue : [filterValue],
      filterField: Array.isArray(filterField) ? filterField : [filterField]
    }));
    return attributeFilters;
  };

  getVisibilityValues = (attr) => {
    const { getLabel } = this.props;
    const { contextualAttributes } = this.state;
    if (!contextualAttributes || this.isEmpty(contextualAttributes))
      return null;
    if (
      contextualAttributes &&
      Object.prototype.hasOwnProperty.call(contextualAttributes, attr)
    ) {
      const attribute = contextualAttributes[attr];
      const { effect, filterRule } = attribute;
      const attributeFilters = this.getFilterItem(attribute);
      const effectValue = effect.charAt(0).toUpperCase() + effect.slice(1);
      if (!attributeFilters.length) return getLabel("VISIBILITY_NO_RULES");
      const filterLabels = attributeFilters.reduce((result, filter) => {
        const { filterField, filterValue } = filter;
        const filterValues = filterValue.join(", ");
        const label = filterField
          .map((field) =>
            getLabel("VISIBILITY_FILTER_VALUE_ANY_LABEL", {
              filterField: field,
              filterValueItems: filterValues
            })
          )
          .join(", ");
        return [...result, label];
      }, []);
      if (filterRule === "any") {
        return getLabel("VISIBILITY_FILTER_ANY_LABEL", {
          effectValue,
          filters: filterLabels
        });
      } else {
        return getLabel("VISIBILITY_FILTER_ALL_LABEL", {
          effectValue,
          filters: filterLabels
        });
      }
    }
  };

  getStateObjectKeys = () => {
    const { selectedWorkflow } = this.props;
    if (!selectedWorkflow || !selectedWorkflow.state) return [];
    return [
      "displayTitles",
      "customAttributes",
      "defaultValues",
      "domainValues",
      "validationRules",
      "hiddenAttributes",
      "nonEditableAttributes",
      "contextualAttributes"
    ].filter((item) => selectedWorkflow.state[item]);
  };

  getUniqueFieldNames = (state) => {
    const stateObjectKeys = this.getStateObjectKeys();
    const fieldNames = Object.keys(state).reduce((result, attr) => {
      const names = state[attr];
      let newArray = [];
      if (Array.isArray(names) && stateObjectKeys.includes(attr)) {
        newArray = newArray.concat(...names);
      } else if (typeof names === "object" && stateObjectKeys.includes(attr)) {
        newArray = Object.keys(names);
      }
      return [...result, ...newArray];
    }, []);
    const uniqueFieldNames = [...new Set(fieldNames)];
    return uniqueFieldNames;
  };

  getWorkflowFieldsTable = () => {
    const { getLabel } = this.props;
    const { displayTitles, workflowState } = this.state;
    if (
      !workflowState ||
      !displayTitles ||
      this.isEmpty(workflowState) ||
      this.isEmpty(displayTitles)
    )
      return null;

    const uniqueFieldNames = this.getUniqueFieldNames(workflowState);

    const tableValues = uniqueFieldNames.reduce((result, attr) => {
      const name = this.getNameValues(attr);
      const type = this.getTypeValues(attr);
      const defaultValues = this.getDefaultValues(attr);
      const rules = this.getRulesValues(attr);
      const visibilityRules = this.getVisibilityValues(attr);

      const defaultValue =
        defaultValues === "::date::today"
          ? getLabel("TODAYS_DATE_LABEL")
          : defaultValues === false
          ? "false"
          : defaultValues;

      return [
        ...result,
        {
          name,
          type,
          defaultValue,
          rules,
          visibilityRules
        }
      ];
    }, []);

    const tableHeaders = [
      getLabel("FIELDS_TABLE_NAME_HEADER"),
      getLabel("FIELDS_TABLE_TYPE_HEADER"),
      getLabel("FIELDS_TABLE_DEFAULT_VALUE_HEADER"),
      getLabel("FIELDS_TABLE_RULES_HEADER"),
      getLabel("FIELDS_TABLE_VISIBILITY_RULES_HEADER")
    ];

    return (
      <Table evenSpace={5}>
        <thead>
          <tr>
            {tableHeaders.map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableValues.map((item, index) => (
            <tr key={`key-${item.name}-${index}`}>
              <td>{item.name ? item.name : ""}</td>
              <td>{item.type ? item.type : ""}</td>
              <td>{item.defaultValue ? item.defaultValue : ""}</td>
              <td>{item.rules ? item.rules : ""}</td>
              <td>{item.visibilityRules ? item.visibilityRules : ""}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  getFeatureList = (attr) => {
    const {
      getLabel,
      selectedWorkflow: { state }
    } = this.props;
    const { featureList, defaultValues, displayTitles } = state;
    if (attr === "titleField") {
      const displayTitle =
        displayTitles && displayTitles.title ? displayTitles.title : "";
      const defaultValue =
        defaultValues && defaultValues.title ? defaultValues.title : "";
      return [
        {
          name: "Title",
          label: displayTitle,
          fieldToDisplay: displayTitle,
          willDisplayAs: defaultValue
        }
      ];
    } else {
      const subtitle = featureList[attr].map((item) => {
        const displayAs =
          defaultValues && defaultValues[item.field] !== undefined
            ? defaultValues[item.field] === "::date::today"
              ? getLabel("TODAYS_DATE_LABEL")
              : defaultValues[item.field]
            : "";
        const displayValue = displayAs
          ? displayAs
          : getLabel("PLACEHOLDER_VALUE_LABEL");

        return {
          name: "Subtitle",
          label: item.label || item.title,
          fieldToDisplay: item.field,
          willDisplayAs: `${item.label} ${displayValue}`
        };
      });
      return subtitle;
    }
  };

  getWorkflowFeatureListTable = () => {
    const { getLabel } = this.props;
    const selectedWorkflow = this.getCurrentWorkflow();
    if (!selectedWorkflow || this.isEmpty(selectedWorkflow)) return null;
    const { state } = selectedWorkflow;
    if (!state || this.isEmpty(state)) return null;
    const { featureList } = state;
    if (!featureList || this.isEmpty(featureList)) return null;
    const tableValues = Object.keys(featureList).reduce((result, attr) => {
      if (attr === "sort") return [...result];
      const featureList = this.getFeatureList(attr);
      return [...result, { featureList }];
    }, []);

    if (!tableValues || tableValues.length === 0) return null;

    const finalTableValues =
      tableValues.length === 1
        ? tableValues[0].featureList
        : [...tableValues[0].featureList, ...tableValues[1].featureList];

    const tableHeaders = [
      "",
      getLabel("LABEL_TEXT"),
      getLabel("FEATURE_LIST_FIELD_TO_DISPLAY_HEADER"),
      getLabel("FEATURE_LIST_WILL_DISPLAY_AS_HEADER")
    ];

    return (
      <Table evenSpace={5}>
        <thead>
          <tr>
            {tableHeaders.map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {finalTableValues.map((item, index) => (
            <tr key={`key-${item.label}-${index}`}>
              <td>{item.name ? item.name : ""}</td>
              <td>{item.label ? item.label : ""}</td>
              <td>{item.fieldToDisplay ? item.fieldToDisplay : ""}</td>
              <td>{item.willDisplayAs ? item.willDisplayAs : ""}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  getPermissionsTableValues = (permissions) => {
    const getRoles = Object.keys(permissions).reduce((result, attr) => {
      const roleIds = permissions[attr].map((item) => item);
      return ["", ...result, ...roleIds];
    }, []);

    const uniqueRoles = getRoles.filter(
      (item, index) => getRoles.indexOf(item) === index
    );

    const getRows = Object.keys(permissions).reduce((result, attr) => {
      const roleIds = uniqueRoles
        .filter((e) => e)
        .map((item) => {
          if (permissions[attr].includes(item)) {
            return { icon: "tick", color: "#00ab7a" };
          }
          return { icon: "close", color: "#d17e21" };
        });
      return [...result, { name: attr, roleIds }];
    }, []);

    return { uniqueRoles, getRows };
  };

  getWorkflowPermissionsTable = () => {
    const selectedWorkflow = this.getCurrentWorkflow();
    if (!selectedWorkflow || this.isEmpty(selectedWorkflow)) return null;

    const { state } = selectedWorkflow;
    if (!state || this.isEmpty(state)) return null;

    const { permissions } = state;
    if (!permissions || this.isEmpty(permissions)) return null;

    const { uniqueRoles, getRows } =
      this.getPermissionsTableValues(permissions);
    if (getRows.length === 0 && uniqueRoles.length === 0) return null;

    const renderRows = getRows.map((items, index) => {
      return (
        <tr key={`key-${items.name}-${index}`}>
          <td>{items.name}</td>
          {items.roleIds.map((role, index) => (
            <td
              key={`key-${role.icon}-${index}`}
              aria-label={role.icon === "tick" ? true : false}
            >
              <Icon
                type={role.icon}
                bgWidth="30px"
                bgHeight="30px"
                iconHeight="20px"
                iconWidth="20px"
                iconColor={role.color}
              />
            </td>
          ))}
        </tr>
      );
    });

    return (
      <Table>
        <thead>
          <tr>
            {uniqueRoles.map((item, index) => (
              <th key={`${item}-${index}`}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </Table>
    );
  };

  getSearchTypesTable = () => {
    const selectedWorkflow = this.getCurrentWorkflow();
    if (!selectedWorkflow || this.isEmpty(selectedWorkflow)) return null;

    const { state } = selectedWorkflow;
    if (!state || this.isEmpty(state)) return null;

    const { searchTypes } = state;
    if (!searchTypes || (searchTypes && searchTypes.length === 0)) return null;

    return (
      <Table evenSpace={5}>
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {searchTypes.map((item, index) => (
            <tr key={`key-${item}-${index}`}>
              <td>{item ? item : ""}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  getDrawingToolsTable = () => {
    const selectedWorkflow = this.getCurrentWorkflow();
    if (!selectedWorkflow || this.isEmpty(selectedWorkflow)) return null;

    const { state } = selectedWorkflow;
    if (!state || this.isEmpty(state)) return null;

    const { drawingTools } = state;
    if (!drawingTools || (drawingTools && drawingTools.length === 0))
      return null;

    return (
      <Table evenSpace={5}>
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {drawingTools.map((item, index) => (
            <tr key={`key-${item}-${index}`}>
              <td>{item ? item : ""}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  getWorkflowConfigurationHeading = () => {
    const { getLabel } = this.props;

    if (
      this.getWorkflowFieldsTable() ||
      this.getWorkflowFeatureListTable() ||
      this.getWorkflowFeatureListSortTable() ||
      this.getWorkflowPermissionsTable() ||
      this.getSearchTypesTable() ||
      this.getDrawingToolsTable()
    ) {
      return (
        <Heading3 styleType={SECTION_HEADER_STYLING}>
          {getLabel("WORKFLOW_DETAILS_CONFIGURATION_SUBHEADING")}
        </Heading3>
      );
    }
  };

  getWorkflowFeatureListSortTable = () => {
    const selectedWorkflow = this.getCurrentWorkflow();
    if (!selectedWorkflow || this.isEmpty(selectedWorkflow)) return null;
    const { state } = selectedWorkflow;
    if (!state || this.isEmpty(state)) return null;
    const { featureList } = state;

    if (
      !featureList ||
      this.isEmpty(featureList) ||
      !featureList.sort ||
      this.isEmpty(featureList.sort)
    )
      return null;

    return (
      <Table evenSpace={5}>
        <thead>
          <tr>
            {Object.entries(featureList.sort).map(([key]) => (
              <th key={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {Object.entries(featureList.sort).map(([key, value]) => (
              <td key={key}>
                {Array.isArray(value) ? value.join(", ") : value.toString()}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    );
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isWorkflowDetailsLoading() === true ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={this.getContextMenu()}
              />
            </Container>
            {this.getCurrentWorkflow() && (
              <Container>
                <Container>
                  {this.getHasPermission(
                    PROPERTY_GROUPS_SECTION,
                    READ_PERMISSION
                  ) && (
                    <Section>
                      <Heading3
                        data-name="groups-header"
                        styleType={SECTION_HEADER_STYLING}
                      >
                        {this.createPropertyGroupsHeader()}
                      </Heading3>
                      <ListContent
                        rows={this.getCurrentWorkflowPropertyGroups()}
                      />
                    </Section>
                  )}

                  {this.getHasPermission(
                    PROPERTIES_SECTION,
                    READ_PERMISSION
                  ) && (
                    <Section>
                      <Heading3
                        data-name="properties-header"
                        styleType={SECTION_HEADER_STYLING}
                      >
                        {this.createPropertiesHeader()}
                      </Heading3>
                      <ListContent rows={this.getCurrentWorkflowProperties()} />
                    </Section>
                  )}

                  <Section>
                    <Heading3 styleType={SECTION_HEADER_STYLING}>
                      {this.createWorkflowSubHeader(
                        "WORKFLOW_DETAILS_FEATURE_SERVICES_SUBHEADING"
                      )}
                    </Heading3>
                    <ListContent
                      rows={this.getCurrentWorkflowFeatureServices()}
                    />
                  </Section>

                  {this.getCurrentWorkflowPluginTable() && (
                    <Section>
                      <Heading3 styleType={SECTION_HEADER_STYLING}>
                        {getLabel("WORKFLOW_DETAILS_SUBHEADING", {
                          title: getLabel("WORKFLOW_DETAILS_PLUGIN_SUBHEADING")
                        })}
                      </Heading3>
                      {this.getCurrentWorkflowPluginTable()}
                    </Section>
                  )}
                </Container>
                <Container>
                  <Section>{this.getWorkflowConfigurationHeading()}</Section>

                  {this.getWorkflowFieldsTable() && (
                    <Section styleType={WORKFLOW_DETAILS_TABLES_STYLETYPE}>
                      <Heading3 styleType={"tableHeading"}>
                        {getLabel("WORKFLOW_DETAILS_FIELDS_TABLE_HEADING")}
                      </Heading3>
                      {this.getWorkflowFieldsTable()}
                    </Section>
                  )}

                  {this.getWorkflowFeatureListTable() && (
                    <Section styleType={WORKFLOW_DETAILS_TABLES_STYLETYPE}>
                      <Heading3 styleType={"tableHeading"}>
                        {getLabel(
                          "WORKFLOW_DETAILS_FEATURE_LIST_TABLE_HEADING"
                        )}
                      </Heading3>
                      {this.getWorkflowFeatureListTable()}
                    </Section>
                  )}

                  {this.getWorkflowFeatureListSortTable() && (
                    <Section styleType={WORKFLOW_DETAILS_TABLES_STYLETYPE}>
                      <Heading3 styleType={"tableHeading"}>
                        {getLabel(
                          "WORKFLOW_DETAILS_FEATURE_LIST_SORT_TABLE_HEADING"
                        )}
                      </Heading3>
                      {this.getWorkflowFeatureListSortTable()}
                    </Section>
                  )}

                  {this.getWorkflowPermissionsTable() && (
                    <Section styleType={WORKFLOW_DETAILS_TABLES_STYLETYPE}>
                      <Heading3 styleType={"tableHeading"}>
                        {getLabel("WORKFLOW_DETAILS_PERMISSIONS_TABLE_HEADING")}
                      </Heading3>
                      {this.getWorkflowPermissionsTable()}
                    </Section>
                  )}

                  {this.getSearchTypesTable() && (
                    <Section styleType={WORKFLOW_DETAILS_TABLES_STYLETYPE}>
                      <Heading3 styleType={"tableHeading"}>
                        {getLabel(
                          "WORKFLOW_DETAILS_SEARCH_TYPES_TABLE_HEADING"
                        )}
                      </Heading3>
                      {this.getSearchTypesTable()}
                    </Section>
                  )}

                  {this.getDrawingToolsTable() && (
                    <Section styleType={WORKFLOW_DETAILS_TABLES_STYLETYPE}>
                      <Heading3 styleType={"tableHeading"}>
                        {getLabel(
                          "WORKFLOW_DETAILS_DRAWING_TOOLS_TABLE_HEADING"
                        )}
                      </Heading3>
                      {this.getDrawingToolsTable()}
                    </Section>
                  )}
                </Container>
              </Container>
            )}
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default WorkflowDetail;
