import React, { Component } from "react";
import { Container, Wrapper, Section, TextArea, FormLabel } from "@core";
import { Loader, ContextHeader, BottomButtonBar } from "@UIKit";
import {
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  ORGANISATION_URL,
  RENDERERS_URL,
  BUTTON_TYPE_PRIMARY,
  BUTTON_TYPE_SECONDARY,
  STYLETYPE_ERROR,
  BUTTON_TYPE_PRIMARY_FULLWIDTH,
  BUTTON_TYPE_SECONDARY_FULLWIDTH,
  HEIGHT_AUTO_TEXT
} from "@base/constants";
import { navigate } from "@reach/router";
import { P } from "@typography";

class UpdateRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonTextInputValue: ""
    };
  }

  componentDidMount() {
    this.setupRendererDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedOrganisationRendererLoading } = this.props;
    if (
      prevProps.selectedOrganisationRendererLoading === true &&
      selectedOrganisationRendererLoading === false
    ) {
      this.showSuccess();
    }
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  static getDerivedStateFromProps(props, state) {
    const { selectedOrganisationRenderer } = props;
    const { jsonTextInputValue } = state;
    if (
      selectedOrganisationRenderer &&
      typeof selectedOrganisationRenderer === "object" &&
      Object.keys(selectedOrganisationRenderer).length > 0 &&
      !jsonTextInputValue
    ) {
      const rendererJSON = JSON.stringify(selectedOrganisationRenderer, "", 2);
      return {
        originalRendererJSON: rendererJSON,
        jsonTextInputValue: rendererJSON
      };
    }
    return state;
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  setupRendererDetails = () => {
    const { orgId, rendererId, loadOrganisationRenderer } = this.props;
    loadOrganisationRenderer(orgId, rendererId);
  };

  isLoading = () => {
    const {
      organisationsLoading,
      selectedOrganisationLoading,
      selectedOrganisationRendererLoading
    } = this.props;
    return (
      organisationsLoading ||
      selectedOrganisationLoading ||
      selectedOrganisationRendererLoading === true
    );
  };

  createHeaderText = () => {
    const { getLabel, selectedOrganisationRenderer } = this.props;
    if (!selectedOrganisationRenderer || !selectedOrganisationRenderer.type)
      return getLabel("GENERIC_ACTION_MESSAGE", {
        item: getLabel("RENDERER_TEXT"),
        action: getLabel("UPDATE_ACTION_LABEL")
      });
    return getLabel("EDIT_RENDERER_ACTION_LABEL", {
      item: selectedOrganisationRenderer.type
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  valueIsEmpty = (field) => {
    return (
      !this.getValue(field) ||
      (this.getValue(field) && this.getValue(field).length === 0) ||
      this.getValue(field) === ""
    );
  };

  isInvalid = () => {
    return this.valueIsEmpty("jsonTextInputValue");
  };

  getStateChanged = () => {
    const { originalRendererJSON, jsonTextInputValue } = this.state;
    if (jsonTextInputValue !== originalRendererJSON) {
      return true;
    }
    return false;
  };

  returnToRendererAction = () => {
    const { hideModal, orgId, selectedOrganisationRenderer } = this.props;
    const { rendererId } = selectedOrganisationRenderer;
    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${RENDERERS_URL}/${rendererId}`);
  };

  callAction = async () => {
    try {
      const data = this.getValue("jsonTextInputValue");
      const { orgId, rendererId, updateRenderer, showModal } = this.props;
      this.showLoading();
      showModal();

      await updateRenderer(orgId, rendererId, data);
    } catch (e) {
      throw new Error(e);
    }
  };

  showLoading = () => {
    const { showModal, setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("UPDATE_ACTION_LABEL"),
        item: getLabel("RENDERER_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  resetRendererJSON = () => {
    const { originalRendererJSON } = this.state;
    this.setValue(originalRendererJSON, "jsonTextInputValue");
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_UPDATED_LABEL", {
        item: getLabel("RENDERER_TEXT")
      }),
      body: getLabel("ITEM_UPDATED_SUCCESS", {
        item: getLabel("RENDERER_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("RENDERER_TEXT")
          }),
          onClick: this.returnToRendererAction
        }
      ]
    });
    return null;
  };

  showMessage = () => {
    const { setModalContent, showModal, getLabel } = this.props;
    showModal();
    setModalContent({
      showCloseButton: false,
      header: getLabel("UPDATE_ACTION_HEADER_LABEL", {
        item: getLabel("RENDERER_TEXT")
      }),
      body: getLabel("BACK_TO_DETAILS_MESSAGE"),
      buttons: [
        {
          buttonStyleType: BUTTON_TYPE_PRIMARY_FULLWIDTH,
          isDisabled: this.isInvalid() || this.isLoading(),
          buttonLabel: getLabel("YES_LABEL"),
          onClick: this.callAction
        },
        {
          buttonStyleType: BUTTON_TYPE_SECONDARY_FULLWIDTH,
          buttonLabel: getLabel("NO_LABEL"),
          onClick: this.returnToRendererAction
        }
      ]
    });
    return null;
  };

  getButtons = () => {
    const { getLabel } = this.props;
    const stateChanged = this.getStateChanged();
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        isDisabled: this.isInvalid() || this.isLoading(),
        onClick: this.callAction,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("RENDERER_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      },
      {
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        isDisabled: this.isInvalid() || this.isLoading(),
        onClick: this.resetRendererJSON,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("RENDERER_TEXT"),
          action: getLabel("RESET_LABEL")
        })
      },
      {
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        onClick: stateChanged ? this.showMessage : this.returnToRendererAction,
        buttonLabel: getLabel("RETURN_TO_DETAILS", {
          item: getLabel("RENDERER_TEXT")
        })
      }
    ];
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoading() ? (
          <Loader
            fullpage={false}
            loadingText={getLabel("GENERIC_ACTION_MESSAGE", {
              item: getLabel("RENDERER_TEXT"),
              action: getLabel("LOADING_LABEL")
            })}
          />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
              />
            </Container>
            <Section>
              <FormLabel htmlFor={"jsontextinputvalue"}>
                {getLabel("ADD_JSON", {
                  item: getLabel("RENDERER_TEXT")
                })}
              </FormLabel>
              <TextArea
                id={"jsontextinputvalue"}
                maxLength={999999999}
                value={this.getValue("jsonTextInputValue")}
                onChange={(value) => {
                  this.setValue(value, "jsonTextInputValue");
                }}
              ></TextArea>
            </Section>
            {this.isInvalid() ? (
              <P styleType={STYLETYPE_ERROR}>{getLabel("PLEASE_FILL_FORM")}</P>
            ) : null}
            <BottomButtonBar buttons={this.getButtons()} />
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default UpdateRenderer;
