import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, FormInput, Button } from "@core";
import { PAGINATION_STYLING } from "@base/constants";

const PaginationV2InputGroup = ({
  currentPageNumber,
  handleSelectPage,
  pageCount,
  ariaLabel
}) => {
  const [inputValue, setInputValue] = useState(currentPageNumber);
  const [width, setWidth] = useState(2.5);

  return (
    <Container styleType={PAGINATION_STYLING} direction={"row"}>
      <FormInput
        min={1}
        max={pageCount}
        width={width}
        type={"number"}
        value={inputValue}
        styleType={PAGINATION_STYLING}
        ariaLabel={ariaLabel}
        onChange={(value) => {
          // skipcq JS-0417
          if (Number(value) < 1 || Number(value) > pageCount) {
            return;
          }
          setWidth(Number(value.length) + 1);
          setInputValue(Number(value));
        }}
      />
      <Button
        buttonStyleType={PAGINATION_STYLING}
        isDisabled={currentPageNumber === Number(inputValue)}
        // skipcq JS-0417
        onClick={() =>
          currentPageNumber !== inputValue && handleSelectPage(inputValue)
        }
        buttonLabel={"GO"}
      />
    </Container>
  );
};

PaginationV2InputGroup.propTypes = {
  /** currentPageNumber returns current page number */
  currentPageNumber: PropTypes.number,
  /** handleSelectPage gets called with page number */
  handleSelectPage: PropTypes.func,
  /** pageCount number */
  pageCount: PropTypes.number
};

export default PaginationV2InputGroup;
