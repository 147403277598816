import { createGlobalStyle } from "styled-components";
import ApplicationHeader from "./Components/ApplicationHeader/ApplicationHeader.js";
import BreadCrumbNavigation from "./Components/BreadCrumbNavigation/BreadCrumbNavigation.js";
import ListContent from "./Components/ContentList/ListContent.js";
import Loader from "./Components/Loader/Loader.js";
import Modal from "./Components/Modal/Modal.js";
import ContextMenu from "./Components/ContextMenu/ContextMenu.js";
import ContextHeader from "./Components/ContextHeader/ContextHeader.js";
import FilterInput from "./Components/FilterInput/FilterInput.js";
import RadioGroup from "./Components/RadioGroup/RadioGroup.js";
import RadioGroupTable from "./Components/RadioGroupTable/RadioGroupTable.js";
import Pagination from "./Components/Pagination/Pagination.js";
import ToggleSwitch from "./Components/ToggleSwitch/ToggleSwitch.js";
import BottomButtonBar from "./Components/BottomButtonBar/BottomButtonBar.js";
import Combobox from "./Components/Combobox/Combobox.js";
import Attachments from "./Components/Attachments/Attachments.js";
import PaginationV2 from "./Components/PaginationV2/PaginationV2.js";
import Dropdown from "./Components/Dropdown/Dropdown";
import DataTable from "./Components/DataTable/DataTable";
import { defaultTheme } from "./defaultTheme";

export const GlobalStyle = createGlobalStyle`
  body {
    margin:0;
    font-family: "Poppins", sans-serif;
    color: #333333;
    font-size: 100%;
    font-weight: 400;
    line-height: 1.3em;
  }

p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin:0;
    padding:0;
    font-family: "Poppins", sans-serif;
  }

  p,
  a,
  input,
  button,
  select,
  option {
    margin:0;
    padding:0;
    font-family: "Poppins", sans-serif;
  }

  p {
    width:100%;
  }

  a {
    color: #4f57a6;
    &:hover {
      color: @ag-button-hover;
      cursor: pointer;
      opacity: 0.8;
    }
    text-decoration: none;
    border: none;
    background: none;
    font-family: "Poppins", sans-serif;
  }

  ul {
    list-style: none;
    margin:0;
    padding:0;
  }

  button {
    border: none;
    background: none;
    color: #4f57a6;
    font-family: "Poppins", sans-serif;

    &:hover {
      color: @ag-button-hover;
      cursor: pointer;
      opacity: 0.8;
    }
  }

  button:focus{
    outline-color: #0079c1;
    outline-offset: -4px;
    outline-width: 1px;
    outline-style: solid;
  }

  .esri-ui-top-left {
    right:0;
    left:unset;
  }

  @keyframes ckw {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes fadeIn {
      0% { opacity: 0;  }
    100% { opacity: 1; }
  }

  @keyframes fadeOut {
      0% { opacity: 1;  }
    100% { opacity: 0; }
  }

  .css-transition-enter {
    opacity: 0;
  }
  .css-transition-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .css-transition-exit {
    opacity: 1;
  }
  .css-transition-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
  [data-name="main-router"] {
    padding: 20px
  }

  .sr-only {
    position:absolute;
    left: -100000px;
    top:0;
  }
  .modal-open {
    overflow: hidden;
  }
`;

export {
  ApplicationHeader,
  BottomButtonBar,
  BreadCrumbNavigation,
  ListContent,
  Loader,
  Modal,
  ContextMenu,
  ContextHeader,
  FilterInput,
  RadioGroup,
  RadioGroupTable,
  Pagination,
  PaginationV2,
  ToggleSwitch,
  defaultTheme,
  Combobox,
  Attachments,
  Dropdown,
  DataTable
};
