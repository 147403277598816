import { connect } from "react-redux";
import RoleDetail from "./RoleDetail";
import * as actions from "../../../actions";
import {
  getLabels,
  getPageTitle,
  getSelectedRole,
  getSelectedRoleUsers,
  getSelectedRoleUsersStatus,
  getSelectedRoleUsersTotal,
  getAbortController,
  getSelectedRoleStatus,
  getSelectedOrgRoles,
  getSelectedOrgRolesLoading,
  getHasPermission,
  getSelectedRoleWorkflows,
  getSelectedRoleWorkflowsStatus
} from "../../../selectors";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    selectedRole: getSelectedRole(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedRoleUsers: getSelectedRoleUsers(state),
    selectedRoleUsersTotal: getSelectedRoleUsersTotal(state),
    selectedRoleUsersStatus: getSelectedRoleUsersStatus(state),
    abortController: getAbortController(state),
    selectedRoleStatus: getSelectedRoleStatus(state),
    selectedOrganisationRoles: getSelectedOrgRoles(state),
    selectedOrganisationRolesLoading: getSelectedOrgRolesLoading(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType),
    selectedRoleWorkflows: getSelectedRoleWorkflows(state),
    selectedRoleWorkflowsStatus: getSelectedRoleWorkflowsStatus(state)
  }),
  actions
)(RoleDetail);
