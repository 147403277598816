import styled, { css } from "styled-components";
import { LOADER_SIZE_REGULAR } from "@base/constants";

export const LoadingText = styled.p`
  color: ${props => props.theme.agBlack};
  font-size: ${props =>
    props.size === LOADER_SIZE_REGULAR ? "1em" : "0.85em"};
  text-align: center;
`;
LoadingText.displayName = "LoadingText";

export const LoadingTextContainer = styled.div`
  padding: 1em;
`;
LoadingTextContainer.displayName = "LoadingTextContainer";

export const LoadingTextWrapper = styled.div`
  background: ${props => {
    if (props.bgColor) return props.bgColor;
    return "none";
  }};
  border-radius: 0.2em;
`;
LoadingTextWrapper.displayName = "LoadingTextWrapper";
