import { connect } from "react-redux";
import Error from "./Error";
import * as actions from "../../actions";

import { getLabels, getErrorMessage } from "../../selectors";

export default connect(
  (state) => ({
    errorMessage: getErrorMessage(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data })
  }),
  actions
)(Error);
