import { connect } from "react-redux";
import App from "./App";
import * as actions from "../actions";

import {
  getUserLogin,
  getUserLoadingLogin,
  getIsAuthenticated,
  getAppLoading,
  getLoadingText,
  getLabels,
  getModalVisibility,
  getModalHeader,
  getModalBody,
  getModalButtons,
  getModalshowClose,
  getLoggingOut,
  getHasPermission,
  getAvailableOrgs,
  getOrgsStatus
} from "../selectors";
import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    user: getUserLogin(state),
    userLoading: getUserLoadingLogin(state),
    isAuthenticated: getIsAuthenticated(state),
    appLoading: getAppLoading(state),
    loadingText: getLoadingText(state),
    modalVisibility: getModalVisibility(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    modalHeader: getModalHeader(state),
    modalBody: getModalBody(state),
    modalButtons: getModalButtons(state),
    modalshowClose: getModalshowClose(state),
    loggingOut: getLoggingOut(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType),
    availableOrgs: getAvailableOrgs(state),
    orgsLoading: getOrgsStatus(state) === STATUS_LOADING
  }),
  actions
)(App);
