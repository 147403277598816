import React from "react";
import PropTypes from "prop-types";
import { TableElement } from "./style";

/** Renders a Table  */
const Table = (props) => {
  return (
    <TableElement evenSpace={props.evenSpace}>{props.children}</TableElement> // skipcq JS-0463
  );
};

Table.propTypes = {
  /** If you want to set the table to make the columns split up into even spaces - Note if you specify a number lower than the number of columns, it only affects the first n number of columns, e.g: Settings evenSpace 2 to a table with 3 columns, the first two columns will take up the even space and the last column will be crushed. */
  evenSpace: PropTypes.number
};

export default Table;
