import { connect } from "react-redux";
import UpdateProperty from "./UpdateProperty";
import * as actions from "../../../actions";
import {
  getLabels,
  getPageTitle,
  getSelectedProperty,
  getSelectedPropertyStatus,
  getSelectedUser,
  getAbortController,
  getSelectedUserStatus
} from "../../../selectors";
import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    propState: state,
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedProperty: getSelectedProperty(state),
    selectedPropertyStatus: getSelectedPropertyStatus(state),
    selectedUserMembership: getSelectedUser(state),
    abortController: getAbortController(state),
    selectedUserMembershipLoading:
      getSelectedUserStatus(state) === STATUS_LOADING
  }),
  actions
)(UpdateProperty);
