import React, { Component } from "react";
import { Container, Wrapper, Image, LinkButton } from "@core";
import {
  AUTH_VIEW,
  AUTH_TEXT,
  LOGIN_BUTTON,
  LANDKIND_LOGO_SVG,
  LANDKIND_LOGO_ALT_TEXT,
  LANDKIND_LOGO_AUTH_WIDTH
} from "@base/constants";
import { P } from "@typography";

export default class Logout extends Component {
  componentDidMount() {
    const { clearAuthentication } = this.props;
    clearAuthentication();
  }

  resetLoggingOut = () => {
    const { resetLoggingOut } = this.props;
    resetLoggingOut();
  };
  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={AUTH_VIEW}>
        <Wrapper styleType={AUTH_VIEW}>
          <Image
            altText={LANDKIND_LOGO_ALT_TEXT}
            source={LANDKIND_LOGO_SVG}
            width={LANDKIND_LOGO_AUTH_WIDTH}
          />
          <P styleType={AUTH_TEXT}>{getLabel("LOG_OUT_SUCCESSFUL_LABEL")}</P>
          <P styleType={AUTH_TEXT}>{getLabel("LOG_OUT_CLOSE_BROWSER_LABEL")}</P>
          <LinkButton
            buttonLabel={getLabel("LOG_BACK_IN_LABEL")}
            buttonStyleType={LOGIN_BUTTON}
            url={"../../"}
            onClick={this.resetLoggingOut}
          />
        </Wrapper>
      </Container>
    );
  }
}
