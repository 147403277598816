import styled, { css } from "styled-components";

// @ts-ignore
export const ImageElement = styled.img`
  width: auto;
  height: auto;
  max-width: ${(props) => props.width};
  max-height: ${(props) => props.height};
  object-fit: contain;

  ${(props) =>
    props.styleType === "logo-image" &&
    css`
      padding: 0rem 0.4rem 0.8rem 0rem;
    `};

  ${(props) =>
    props.styleType === "org-logo-image" &&
    css`
      margin-right: 1.5em;
    `};

  ${(props) =>
    props.styleType === "Loading-screen-loader" &&
    css`
      margin-bottom: 1rem;
    `};
`;

ImageElement.displayName = "ImageElement";
