import React, { Component } from "react";
import { Button } from "@core";
import { BottomButtonWrapper, BottomButtonContainer } from "./style";
import PropTypes from "prop-types";

class BottomButtonBar extends Component {
  static propTypes = {
    /** buttons array  */
    buttons: PropTypes.array
  };

  getButtons = () => {
    const { buttons } = this.props;
    if (!buttons) return;
    return buttons.map((item) => (
      <Button
        key={`${item.buttonLabel}-${item.buttonStyleType}`}
        buttonStyleType={item.buttonStyleType}
        isDisabled={item.isDisabled || false}
        onClick={item.onClick}
        buttonLabel={item.buttonLabel}
        icon={item.icon}
      />
    ));
  };

  render() {
    return (
      <BottomButtonWrapper>
        <BottomButtonContainer>{this.getButtons()}</BottomButtonContainer>
      </BottomButtonWrapper>
    );
  }
}

export default BottomButtonBar;
