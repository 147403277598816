import React, { Component } from "react";

import {
  Loader,
  ContextHeader,
  BottomButtonBar,
  Dropdown,
  DataTable
} from "@UIKit";
import { Section, Container, Wrapper, LinkButton, Fieldset } from "@core";
import { navigate } from "@reach/router";
import { filterList, getSession, storeSession } from "@base/common";

import {
  ROLES_URL,
  USERS_URL,
  WORKFLOWS_URL,
  STATUS_LOADING,
  UPDATE_URL,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  PROPERTY_TEXT,
  PROPERTY_GROUP_TEXT,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  BUTTON_TYPE_SECONDARY,
  ROLES_SECTION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
  WORKFLOWS_SECTION,
  READ_DETAILS_PERMISSION,
  READ_PERMISSION,
  USERS_SECTION,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  STATUS_REMOVING,
  STATUS_READY,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL,
  STYLETYPE_FORM_FIELD,
  DATASETS_SECTION,
  DATASETS_URL
} from "@base/constants";
import { P, Legend, Heading3 } from "@typography";

class RoleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newRoleId: false,
      usersFilterText: "",
      currentPageNumber: 1,
      propertyFilterText: "",
      propGroupFilterText: "",
      deleteModalOpen: false,
      datasetsFilterText: ""
    };
  }

  componentDidMount() {
    this.loadRoleDetails();
  }

  componentDidUpdate(prevProps) {
    const { deleteModalOpen } = this.state;
    const { selectedOrganisationRolesLoading } = this.props;
    if (
      deleteModalOpen &&
      !selectedOrganisationRolesLoading &&
      prevProps.selectedOrganisationRolesLoading
    ) {
      this.showDeleteConfirmation();
    }
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  loadRoleDetails = () => {
    const { orgId, roleId, loadRole, loadOrganisationRoles } = this.props;
    loadRole(orgId, roleId);
    loadOrganisationRoles(orgId);
    this.loadRoleUsers();
    this.loadRoleWorkflows();
  };

  loadRoleUsers = () => {
    const { orgId, roleId, loadRoleUsers } = this.props;
    if (!this.getHasPermission(USERS_SECTION, READ_PERMISSION)) return;
    loadRoleUsers(orgId, roleId);
  };

  loadRoleWorkflows = () => {
    const { orgId, roleId, loadRoleWorkflows } = this.props;
    if (!this.getHasPermission(WORKFLOWS_SECTION, READ_PERMISSION)) return;
    loadRoleWorkflows(orgId, roleId);
  };

  componentDidUpdate(prevProps) {
    const { setPageTitle, pageTitle, selectedRoleStatus } = this.props;
    const role = this.getCurrentRole();
    if (role && role.title && pageTitle !== role.title) {
      setPageTitle(role.title);
    }

    if (
      prevProps.selectedRoleStatus === STATUS_REMOVING &&
      selectedRoleStatus === STATUS_READY
    ) {
      this.showDeleteSuccess();
    }
  }

  getCurrentRole = () => {
    const { selectedRole } = this.props;
    return selectedRole ? selectedRole : false;
  };

  getSelectedRoleUsers = () => {
    const { selectedRoleUsers } = this.props;
    if (!selectedRoleUsers) return false;
    const updateObj = selectedRoleUsers.map((s) => {
      if (Object.prototype.hasOwnProperty.call(s, "firstName")) {
        s.title = s.firstName;
        delete s.firstName;
      }
      return s;
    });
    const filteredList = filterList(updateObj, this.getUsersFilterText());
    return filteredList;
  };
  getSelectedRoleUsersStatus = () => {
    const { selectedRoleUsersStatus } = this.props;
    return selectedRoleUsersStatus ? selectedRoleUsersStatus : false;
  };
  getSelectedRoleUsersTotal = () => {
    const { selectedRoleUsersTotal } = this.props;
    return selectedRoleUsersTotal ? selectedRoleUsersTotal : false;
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    const currentRole = this.getCurrentRole();
    if (!currentRole) return "";
    return getLabel("ROLE_HEADING_TEXT", {
      title: currentRole.title
    });
  };

  isLoadingRolesDetails = () => {
    const { selectedRoleUsersStatus, selectedRoleWorkflowsStatus } = this.props;
    return (
      selectedRoleUsersStatus === STATUS_LOADING ||
      selectedRoleWorkflowsStatus === STATUS_LOADING
    );
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    const childLinks = [];
    if (this.getHasPermission(ROLES_SECTION, UPDATE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.updateRoleAction,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("ROLE_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      });
    }
    if (this.getHasPermission(ROLES_SECTION, DELETE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.showDeleteConfirmation,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("ROLE_TEXT"),
          action: getLabel("DELETE_ACTION_LABEL")
        })
      });
    }
    return {
      childLinks,
      isDisabled: false,
      visible: childLinks.length ? true : false
    };
  };

  updateRoleAction = () => {
    const { hideModal, orgId, roleId } = this.props;

    hideModal();
    navigate(
      `/${ORGANISATION_URL}/${orgId}/${ROLES_URL}/${roleId}/${UPDATE_URL}`
    );
  };

  getWorkflowsWithTitle = (workflows = [], filterText) => {
    const orgWorkflows = this.getRoleWorkflows();
    if (!workflows || !orgWorkflows) return;
    let res = [];
    res = workflows.map((obj) => {
      const index = orgWorkflows.findIndex(
        (el) => el.workflowId === obj.workflowId
      );
      const { title, device } = index !== -1 ? orgWorkflows[index] : {};
      return {
        ...obj,
        title,
        device
      };
    });
    return filterList(res, filterText);
  };

  getRoleWorkflows = () => {
    const { selectedRoleWorkflows } = this.props;
    return selectedRoleWorkflows ? selectedRoleWorkflows : false;
  };

  getRoleWorkflowsByType = (type) => {
    const { selectedRole } = this.props;
    if (
      !selectedRole ||
      !selectedRole.workflows ||
      selectedRole.workflows.length === 0
    )
      return false;
    return selectedRole.workflows.filter((item) => item.workflowType === type);
  };

  getPropertyWorkflows = () => {
    return this.getRoleWorkflowsByType(PROPERTY_TEXT);
  };

  getWorkflowsHeader = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        { content: getLabel("WORKFLOW_NAME_TEXT") },
        { content: getLabel("PERMISSIONS_ENABLED_TEXT") },
        { content: getLabel("DEVICE_LABEL") }
      ]
    };
  };

  getWorkflowPermissions = (workflow) => {
    const { getLabel } = this.props;
    const noPermissions = getLabel("NO_DATA_FOUND_TEXT", {
      dataItem: getLabel("ROLE_PERMISSIONS")
    });
    if (!workflow.workflowPermissions.length) {
      return noPermissions;
    }
    const permissionsEnabled = workflow.workflowPermissions
      .filter((item) => item.permissionValue)
      .map(
        (item) =>
          item.permissionTitle.charAt(0).toUpperCase() +
          item.permissionTitle.slice(1)
      )
      .join(", ");

    return permissionsEnabled || noPermissions;
  };

  getPropertyWorkflowsTableData = () => {
    const { orgId } = this.props;
    const workflows = this.getWorkflowsWithTitle(
      this.getPropertyWorkflows(),
      this.getPropertyFilterText()
    );
    if (!workflows) return;
    const hasWorkflowPermission = this.getHasPermission(
      WORKFLOWS_SECTION,
      READ_DETAILS_PERMISSION
    );
    return workflows.map((workflow) => {
      const permissions = this.getWorkflowPermissions(workflow);
      const title = hasWorkflowPermission ? (
        <LinkButton
          url={`/${orgId}/${WORKFLOWS_URL}/${workflow.workflowId}`}
          buttonLabel={workflow.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      ) : (
        workflow.title
      );
      return {
        cells: [title, permissions, workflow.device]
      };
    });
  };

  getFilterLabel = (item) => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item
    });
  };

  setPropertyFilterText = (value) => {
    this.setState({
      propertyFilterText: value
    });
  };

  getPropertyFilterText = () => {
    const { propertyFilterText } = this.state;
    return propertyFilterText;
  };

  getTableHeaderText = (value) => {
    const { getLabel } = this.props;
    return getLabel("WORKFLOWS_FOR_ROLE_LABEL", {
      item: value
    });
  };

  getPlaceholderText = () => {
    const { getLabel } = this.props;
    return getLabel("WORKFLOWS_FILTER_PLACEHOLDER_LABEL");
  };

  getPropNoResultsText = () => {
    const { getLabel } = this.props;
    const propertyFilterText = this.getPropertyFilterText();
    if (propertyFilterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText: propertyFilterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getPropGroupsWorkflows = () => {
    return this.getRoleWorkflowsByType(PROPERTY_GROUP_TEXT);
  };

  getPropGroupFilterText = () => {
    const { propGroupFilterText } = this.state;
    return propGroupFilterText;
  };

  setPropGroupFilterText = (value) => {
    this.setState({
      propGroupFilterText: value
    });
  };

  getPropGroupsNoResultsText = () => {
    const { getLabel } = this.props;
    const propGroupFilterText = this.getPropGroupFilterText();
    if (propGroupFilterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText: propGroupFilterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getPropGroupsWorkflowsTableData = () => {
    const { orgId } = this.props;
    const workflows = this.getWorkflowsWithTitle(
      this.getPropGroupsWorkflows(),
      this.getPropGroupFilterText()
    );
    if (!workflows) return;
    const hasWorkflowPermission = this.getHasPermission(
      WORKFLOWS_SECTION,
      READ_DETAILS_PERMISSION
    );
    return workflows.map((workflow) => {
      const permissions = this.getWorkflowPermissions(workflow);
      const title = hasWorkflowPermission ? (
        <LinkButton
          url={`/${orgId}/${WORKFLOWS_URL}/${workflow.workflowId}`}
          buttonLabel={workflow.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      ) : (
        workflow.title
      );
      return {
        cells: [title, permissions, workflow.device]
      };
    });
  };

  getRoleUsersList = () => {
    const { currentPageNumber } = this.state;
    const pageLimit = this.getUsersPageLimit();
    const roleUsers = this.getSelectedRoleUsers();
    if (!roleUsers) return false;
    const lastUser = currentPageNumber * pageLimit;
    const firstUser = lastUser - pageLimit;
    const currentRoleUsers = roleUsers.slice(firstUser, lastUser);
    return currentRoleUsers;
  };

  getUsersTableData = () => {
    const { orgId } = this.props;
    const roleUsers = this.getRoleUsersList();
    if (!roleUsers || roleUsers.length === 0) return [];
    return roleUsers.map((user) => {
      const title = (
        <LinkButton
          url={`/${orgId}/${USERS_URL}/${user.userId}`}
          buttonLabel={user.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      );
      return {
        cells: [title, user.lastName]
      };
    });
  };

  getUsersResultsSubtext = () => {
    const { getLabel } = this.props;
    const { currentPageNumber } = this.state;
    const pageLimit = this.getUsersPageLimit();
    const total = this.getSelectedRoleUsers().length;
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      currentPageNumber === 1
        ? currentPageNumber
        : (currentPageNumber - 1) * pageLimit + 1;

    const limitEndNumber = currentPageNumber * pageLimit;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;

    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  setUsersFilterText = (value) => {
    this.setState({
      usersFilterText: value,
      currentPageNumber: 1
    });
  };

  getUsersFilterText = () => {
    const { usersFilterText } = this.state;
    return usersFilterText;
  };

  getUsersPlaceholderText = () => {
    const { getLabel } = this.props;
    return getLabel("USERS_FILTER_PLACEHOLDER_LABEL");
  };

  getUsersNoResultsText = () => {
    const { getLabel } = this.props;
    const usersFilterText = this.getUsersFilterText();
    if (usersFilterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText: usersFilterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  setUsersPaginationContent = (value) => {
    this.setState({ currentPageNumber: value });
  };

  getUsersPageLimit = () => {
    const result = getSession(`role-users-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getUsersCurrentPageNumber = () => {
    const { currentPageNumber } = this.state;
    return currentPageNumber;
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  showUsersPagination = () => {
    return this.getSelectedRoleUsers().length > this.getUsersPageLimit();
  };

  getUsersPaginationContent = () => {
    return {
      setPaginationContent: this.setUsersPaginationContent,
      activeNumber: this.getUsersCurrentPageNumber(),
      numberOfButtons: this.getUsersPageLimit(),
      totalResults: this.getSelectedRoleUsersTotal(),
      showPagination: this.showUsersPagination(),
      paginationInput: true
    };
  };

  showUsersDropdown = () => {
    return (
      this.getSelectedRoleUsers().length >
      this.getUsersDropDownOptions()[0].value
    );
  };

  usersDropDownOnSelectFn = (value) => {
    this.setState({ currentPageNumber: 1 });
    storeSession(`role-users-${PAGINATION_KEY}`, value);
  };

  getUsersDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getUsersPageLimit()
    });
  };

  getUsersDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getUsersPageDropDown = () => {
    return {
      showDropdown: this.showUsersDropdown(),
      dropDownValue: this.getUsersPageLimit(),
      dropDownLabel: this.getUsersDropDownLabel(),
      dropDownOptions: this.getUsersDropDownOptions(),
      dropDownOnSelectFunction: this.usersDropDownOnSelectFn
    };
  };

  returnToRoleOverview = () => {
    const { orgId } = this.props;
    navigate(`/${ORGANISATION_URL}/${orgId}/${ROLES_URL}`);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        onClick: this.returnToRoleOverview,
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
          item: getLabel("ROLE_TEXT")
        })
      }
    ];
  };

  getHasPermission = (section, type) => {
    const { orgId, getHasPermission } = this.props;
    return getHasPermission(orgId, section, type);
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("DELETE_ACTION_LABEL"),
        item: getLabel("ROLE_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
  };

  deleteRoleAction = () => {
    const { newRoleId } = this.state;
    const { orgId, roleId, removeRole } = this.props;
    const roleIdValue = newRoleId && newRoleId.value ? newRoleId.value : false;
    removeRole(orgId, roleId, roleIdValue);
    this.showLoading();
    this.setState({ deleteModalOpen: false });
  };

  getDeleteModalBody = () => {
    const { getLabel } = this.props;
    return getLabel("CONFIRM_DELETE_BODY", {
      item: getLabel("ROLE_TEXT")
    });
  };

  getSelectedOrganisationRoles = () => {
    const { selectedOrganisationRoles } = this.props;
    return selectedOrganisationRoles ? selectedOrganisationRoles : false;
  };

  getOrgRolesDropDownOptions = () => {
    const { roleId } = this.props;
    const organisationRoles = this.getSelectedOrganisationRoles();
    return organisationRoles
      .filter((role) => role.roleId !== roleId)
      .map((item) => ({
        label: item.title,
        value: item.roleId
      }));
  };

  showDeleteConfirmation = () => {
    this.setState({ deleteModalOpen: true }, () => {
      const { showModal } = this.props;
      this.setDeleteModalContent();
      showModal();
    });
  };

  dropDownFunction = (value) => {
    this.setState({ newRoleId: value }, () => {
      this.setDeleteModalContent();
    });
  };

  getDropDown = () => {
    const { newRoleId } = this.state;
    return (
      <Dropdown
        id={"role-details"}
        placeholder={"Select a role"}
        onClick={this.dropDownFunction}
        label={newRoleId.label}
        value={newRoleId.value}
        options={this.getOrgRolesDropDownOptions()}
      />
    );
  };

  getReassignModalBody = () => {
    const { getLabel } = this.props;
    const userTotal = this.getSelectedRoleUsersTotal();
    return (
      <>
        <P>
          {getLabel("REMOVE_ROLE_REASSIGN_LABEL", {
            item: userTotal
          })}
        </P>
        {this.getDropDown()}
      </>
    );
  };

  showModalLoading = () => {
    const { getLabel, setModalContent } = this.props;
    setModalContent({
      header: getLabel("CONFIRM_DELETE_HEADER", {
        item: getLabel("ROLE_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />
    });
  };

  closeDeleteModal = () => {
    const { hideModal } = this.props;
    hideModal();
    this.setState({ deleteModalOpen: false });
  };

  setDeleteModalContent = () => {
    const { newRoleId } = this.state;
    const { setModalContent, getLabel, selectedOrganisationRolesLoading } =
      this.props;
    selectedOrganisationRolesLoading && this.getSelectedRoleUsersTotal()
      ? this.showModalLoading()
      : setModalContent({
          header: getLabel("CONFIRM_DELETE_HEADER", {
            item: getLabel("ROLE_TEXT")
          }),
          body: !this.getSelectedRoleUsersTotal()
            ? this.getDeleteModalBody()
            : this.getReassignModalBody(),
          buttons: [
            {
              buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
                item: getLabel("ROLE_TEXT")
              }),
              onClick: this.deleteRoleAction,
              isDisabled:
                this.getSelectedRoleUsersTotal() && !newRoleId ? true : false
            },
            {
              buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
              onClick: this.closeDeleteModal
            }
          ]
        });
  };

  returnToOverviewAction = () => {
    const { hideModal } = this.props;
    hideModal();
    navigate("./");
  };

  showDeleteSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_DELETED_LABEL", {
        item: getLabel("ROLE_TEXT")
      }),
      body: getLabel("ITEM_DELETED_SUCCESS", {
        item: getLabel("ROLE_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("ROLE_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  getAdminAccess = () => {
    const currentRole = this.getCurrentRole();
    const { getLabel } = this.props;

    if (!currentRole?.adminConsoleAccess) {
      const noAdminAccessSet = getLabel("ADMIN_CONSOLE_ACCESS_TOGGLE_LABEL", {
        status: getLabel("DISABLED_LABEL")
      });
      return <P>{noAdminAccessSet}</P>;
    }

    const { adminAccess, adminRole } = currentRole.adminConsoleAccess;

    const accessEnabled =
      adminAccess && adminRole?.create && adminRole?.read && adminRole?.update;
    const adminAccessLabel = getLabel("ADMIN_CONSOLE_ACCESS_TOGGLE_LABEL", {
      status: accessEnabled
        ? getLabel("ENABLED_LABEL")
        : getLabel("DISABLED_LABEL")
    });

    return <P>{adminAccessLabel}</P>;
  };

  getDatasetsFilterText = () => {
    const { datasetsFilterText } = this.state;
    return datasetsFilterText;
  };

  setDatasetsFilterText = (value) => {
    this.setState({
      datasetsFilterText: value
    });
  };

  getDatasetsPlaceholderText = () => {
    const { getLabel } = this.props;
    return getLabel("DATASETS_FILTER_PLACEHOLDER_LABEL");
  };

  getDatasetsNoResultsText = () => {
    const { getLabel } = this.props;
    const datasetsFilterText = this.getDatasetsFilterText();
    if (datasetsFilterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText: datasetsFilterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getDatasetsPermissionsHeader = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        { content: getLabel("FIELDS_TABLE_DATASET_NAME_HEADER") },
        { content: getLabel("FIELDS_TABLE_ADD_HEADER") },
        { content: getLabel("DELETE_ACTION_LABEL") },
        { content: getLabel("FIELDS_TABLE_READ_HEADER") },
        { content: getLabel("UPDATE_ACTION_LABEL") }
      ]
    };
  };

  getFilteredDatasetsWithCapabilities = (datasets = {}, filterText) => {
    if (!datasets) return;
    const filteredDatasets = Object.entries(datasets).filter(([dataset]) =>
      dataset.toLowerCase().includes(filterText.toLowerCase())
    );
    return filteredDatasets.map(([dataset, capabilities]) => ({
      dataset,
      capabilities
    }));
  };

  getCapabilityLabel = (capability) => {
    const { getLabel } = this.props;
    return capability && capability.length > 0
      ? getLabel("YES_LABEL").charAt(0).toUpperCase() +
          getLabel("YES_LABEL").slice(1).toLowerCase()
      : "--";
  };

  getDatasetsPermissionsTableData = () => {
    const { orgId } = this.props;
    const filterText = this.getDatasetsFilterText();
    const hasDatasetPermission = this.getHasPermission(
      DATASETS_SECTION,
      READ_DETAILS_PERMISSION
    );
    const role = this.getCurrentRole();
    const datasets = role ? role.roleCapabilities : {};
    const filteredDatasets = this.getFilteredDatasetsWithCapabilities(
      datasets,
      filterText
    );
    return filteredDatasets.map(({ dataset, capabilities }) => {
      const title = hasDatasetPermission ? (
        <LinkButton
          url={`/${orgId}/${DATASETS_URL}/${dataset}`}
          buttonLabel={dataset}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      ) : (
        dataset
      );
      return {
        cells: [
          title,
          this.getCapabilityLabel(capabilities.add),
          this.getCapabilityLabel(capabilities.delete),
          this.getCapabilityLabel(capabilities.read),
          this.getCapabilityLabel(capabilities.update)
        ]
      };
    });
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoadingRolesDetails() === true ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={this.getContextMenu()}
              />
            </Container>

            <Legend>{getLabel("ADMIN_CONSOLE_ACCESS_LABEL")}</Legend>
            {this.getAdminAccess()}

            {this.getHasPermission(WORKFLOWS_SECTION, READ_PERMISSION) && (
              <Container>
                <Section styleType={DATA_TABLE_SECTION_STYLING}>
                  <DataTable
                    noResultsText={this.getPropGroupsNoResultsText()}
                    title={this.getTableHeaderText(
                      getLabel("PROPERTY_GROUP_TEXT")
                    )}
                    showResultsText={false}
                    tableFilter={{
                      filterLabel: this.getFilterLabel(
                        getLabel("WORKFLOWS_TEXT")
                      ),
                      showFilter: true,
                      filterFunction: this.setPropGroupFilterText,
                      isDisabled: false,
                      placeholderText: this.getPlaceholderText(),
                      inputId: this.getTableHeaderText(
                        getLabel("PROPERTY_GROUP_TEXT")
                      )
                    }}
                    header={this.getWorkflowsHeader()}
                    rows={this.getPropGroupsWorkflowsTableData()}
                  />
                </Section>
                <Section styleType={DATA_TABLE_SECTION_STYLING}>
                  <DataTable
                    noResultsText={this.getPropNoResultsText()}
                    title={this.getTableHeaderText(getLabel("PROPERTY_TEXT"))}
                    showResultsText={false}
                    tableFilter={{
                      filterLabel: this.getFilterLabel(
                        getLabel("WORKFLOWS_TEXT")
                      ),
                      showFilter: true,
                      filterFunction: this.setPropertyFilterText,
                      isDisabled: false,
                      placeholderText: this.getPlaceholderText(),
                      inputId: this.getTableHeaderText(
                        getLabel("PROPERTY_TEXT")
                      )
                    }}
                    header={this.getWorkflowsHeader()}
                    rows={this.getPropertyWorkflowsTableData()}
                  />
                </Section>
              </Container>
            )}

            {this.getHasPermission(DATASETS_SECTION, READ_PERMISSION) && (
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <DataTable
                  title={getLabel("DATASETS_FOR_ORG_TEXT", {
                    item: getLabel("ROLE_TEXT")
                  })}
                  noResultsText={this.getDatasetsNoResultsText()}
                  showResultsText={false}
                  tableFilter={{
                    filterLabel: this.getFilterLabel(getLabel("DATASETS_TEXT")),
                    showFilter: true,
                    filterFunction: this.setDatasetsFilterText,
                    isDisabled: false,
                    placeholderText: this.getDatasetsPlaceholderText(),
                    inputId: this.getFilterLabel(getLabel("DATASETS_TEXT"))
                  }}
                  header={this.getDatasetsPermissionsHeader()}
                  rows={this.getDatasetsPermissionsTableData()}
                />
              </Section>
            )}

            {this.getHasPermission(USERS_SECTION, READ_PERMISSION) && (
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <DataTable
                  noResultsText={this.getUsersNoResultsText()}
                  title={getLabel("ROLES_USERS_TEXT")}
                  showResultsText={true}
                  resultsSubtext={this.getUsersResultsSubtext()}
                  tableFilter={{
                    filterLabel: this.getFilterLabel(getLabel("USERS_TEXT")),
                    showFilter: true,
                    filterFunction: this.setUsersFilterText,
                    isDisabled: false,
                    placeholderText: this.getUsersPlaceholderText(),
                    inputId: this.getFilterLabel(getLabel("USERS_TEXT"))
                  }}
                  header={{
                    cells: [
                      { content: getLabel("FORM_FIRST_NAME_LABEL") },
                      { content: getLabel("FORM_LAST_NAME_LABEL") }
                    ]
                  }}
                  rows={this.getUsersTableData()}
                  pagination={this.getUsersPaginationContent()}
                  pageDropDown={this.getUsersPageDropDown()}
                />
              </Section>
            )}

            <BottomButtonBar buttons={this.getButtons()} />
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default RoleDetail;
