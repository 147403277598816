export const AGBOX_API_URL = process.env.REACT_APP_AGBOX_API_URL;
export const AGOL_PROXY_URL = process.env.REACT_APP_AGOL_PROXY_URL;
export const AGOL_SERVICE_URL = process.env.REACT_APP_AGOL_SERVICE_URL;

export const ESRI_API_URL = `https://js.arcgis.com/${process.env.REACT_APP_ESRI_API_VERSION}/`;
export const ESRI_PORTAL_URL = `https://${process.env.REACT_APP_ESRI_PORTAL_INSTANCE}.maps.arcgis.com`;
export const ESRI_APP_ID = process.env.REACT_APP_ESRI_APP_ID;

export const AUTHZERO_USERID_CLAIM = "https://agbox.io/claims/userId";
export const AGBOX_API_KEY = "Y4OrkxlAIH37UF6Ys3wXF8C1OQTSfMDq1OkINPC0";
export const AGBOX_AUTH0_URL =
  "https://gpsittest.au.auth0.com/samlp/o8gl3J2aCStDbpdiqkWn0cpwvPwMRly9";

export const HOME_URL = "/";
export const NEW_ORG_URL = "/new";
export const ORGANISATION_URL = "organisation";
export const PROPERTY_GROUPS_URL = "propertyGroups";
export const PROPERTIES_URL = "properties";
export const ROLES_URL = "roles";
export const USERS_URL = "users";
export const WORKFLOWS_URL = "workflows";
export const RENDERERS_URL = "renderers";
export const NEW_URL = "new";
export const UPDATE_URL = "update";
export const UPDATE_ORG_URL = "updateorganisation";
export const UPDATE_USER_URL = "updateuser";
export const UPDATE_USER_INVITE_URL = "updateuserinvite";
export const IMPORT_DATASETS_URL = "importdatasets";
export const DATASETS_URL = "datasets";

export const ORGANISATION = "ORGANISATION";
export const PROPERTY_GROUPS = "PROPERTY_GROUPS";
export const PROPERTIES = "PROPERTIES";
export const ROLES = "ROLES";
export const USERS = "USERS";
export const WORKFLOWS = "WORKFLOWS";
export const RENDERERS = "RENDERERS";
export const UPDATE = "UPDATE";
export const UPDATE_ORG = "UPDATE_ORG";
export const DATASETS = "DATASETS";

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const SET_WEB_MAP_ID = "SET_WEB_MAP_ID";
export const SET_AUTHZERO_CLIENT = "SET_AUTHZERO_CLIENT";
export const LOADED_MAP = "LOADED_MAP";
export const LOAD_WEB_MAP = "LOAD_WEB_MAP";
export const LOADED_WEB_MAP = "LOADED_WEB_MAP";
export const ERROR_LOADING_WEB_MAP = "ERROR_LOADING_WEB_MAP";
export const CHECK_AUTHZERO_AUTHENTICATION = "CHECK_AUTHZERO_AUTHENTICATION";
export const SET_ERROR = "SET_ERROR";

export const LOAD_LABELS = "LOAD_LABELS";
export const LOADED_LABELS = "LOADED_LABELS";

export const SET_PAGE_TITLE = "SET_PAGE_TITLE";

export const SET_ORG_ID = "SET_ORG_ID";
export const GET_ORG_ID = "GET_ORG_ID";

export const LOAD_ORGANISATIONS = "LOAD_ORGANISATIONS";
export const LOADED_ORGANISATIONS = "LOADED_ORGANISATIONS";
export const LOAD_ORGANISATION = "LOAD_ORGANISATION";
export const LOADED_ORGANISATION = "LOADED_ORGANISATION";
export const UNLOAD_ORGANISATIONS = "UNLOAD_ORGANISATIONS";

export const LOAD_USER_LOGIN = "LOAD_USER_LOGIN";
export const LOADED_USER_LOGIN = "LOADED_USER_LOGIN";

export const LOAD_USER_MEMBERSHIP = "LOAD_USER_MEMBERSHIP";
export const LOADED_USER_MEMBERSHIP = "LOADED_USER_MEMBERSHIP";

export const LOAD_ORGANISATION_USERS = "LOAD_ORGANISATION_USERS";
export const LOADED_ORGANISATION_USERS = "LOADED_ORGANISATION_USERS";

export const LOAD_ORGANISATION_USERS_ROLES = "LOAD_ORGANISATION_USERS_ROLES";
export const LOADED_ORGANISATION_USERS_ROLES =
  "LOADED_ORGANISATION_USERS_ROLES";

export const LOAD_ORGANISATION_PROPERTY_GROUPS =
  "LOAD_ORGANISATION_PROPERTY_GROUPS";
export const LOADED_ORGANISATION_PROPERTY_GROUPS =
  "LOADED_ORGANISATION_PROPERTY_GROUPS";

export const LOAD_ORGANISATION_WORKFLOWS = "LOAD_ORGANISATION_WORKFLOWS";
export const LOADED_ORGANISATION_WORKFLOWS = "LOADED_ORGANISATION_WORKFLOWS";

export const LOAD_ORGANISATION_ROLES = "LOAD_ORGANISATION_ROLES";
export const LOADED_ORGANISATION_ROLES = "LOADED_ORGANISATION_ROLES";

export const LOAD_ORGANISATION_PROPERTIES = "LOAD_ORGANISATION_PROPERTIES";
export const LOADED_ORGANISATION_PROPERTIES = "LOADED_ORGANISATION_PROPERTIES";

export const LOAD_ORGANISATION_PROPERTY_WORKFLOWS =
  "LOAD_ORGANISATION_PROPERTY_WORKFLOWS";

export const LOAD_ORGANISATION_PROPERTY_PROPERTY_GROUPS =
  "LOAD_ORGANISATION_PROPERTY_PROPERTY_GROUPS";

export const LOADED_ORGANISATION_PROPERTY_PROPERTY_GROUPS =
  "LOADED_ORGANISATION_PROPERTY_PROPERTY_GROUPS";

export const LOADED_ORGANISATION_PROPERTY_WORKFLOWS =
  "LOADED_ORGANISATION_PROPERTY_WORKFLOWS";

export const LOAD_PROPERTY_GROUP = "LOAD_PROPERTY_GROUP";
export const LOADED_PROPERTY_GROUP = "LOADED_PROPERTY_GROUP";

export const LOAD_PROPERTY_GROUP_USERS = "LOAD_PROPERTY_GROUP_USERS";
export const LOADED_PROPERTY_GROUP_USERS = "LOADED_PROPERTY_GROUP_USERS";

export const LOAD_PROPERTY_GROUP_WORKFLOWS = "LOAD_PROPERTY_GROUP_WORKFLOWS";
export const LOADED_PROPERTY_GROUP_WORKFLOWS =
  "LOADED_PROPERTY_GROUP_WORKFLOWS";

export const LOAD_PROPERTY_GROUP_PROPERTIES = "LOAD_PROPERTY_GROUP_PROPERTIES";
export const LOADED_PROPERTY_GROUP_PROPERTIES =
  "LOADED_PROPERTY_GROUP_PROPERTIES";

export const LOAD_ROLE = "LOAD_ROLE";
export const LOADED_ROLE = "LOADED_ROLE";

export const LOAD_ROLE_WORKFLOWS = "LOAD_ROLE_WORKFLOWS";
export const LOADED_ROLE_WORKFLOWS = "LOADED_ROLE_WORKFLOWS";
export const LOAD_ROLE_WORKFLOWS_ERROR = "LOAD_ROLE_WORKFLOWS_ERROR";

export const LOAD_ROLE_USERS = "LOAD_ROLE_USERS";
export const LOADED_ROLE_USERS = "LOADED_ROLE_USERS";

export const LOAD_PROPERTY = "LOAD_PROPERTY";
export const LOADED_PROPERTY = "LOADED_PROPERTY";

export const LOAD_PROPERTY_PROPERTY_GROUPS = "LOAD_PROPERTY_PROPERTY_GROUPS";
export const LOADED_PROPERTY_PROPERTY_GROUPS =
  "LOADED_PROPERTY_PROPERTY_GROUPS";

export const LOAD_PROPERTY_WORKFLOWS = "LOAD_PROPERTY_WORKFLOWS";
export const LOADED_PROPERTY_WORKFLOWS = "LOADED_PROPERTY_WORKFLOWS";

export const LOAD_USER = "LOAD_USER";
export const LOADED_USER = "LOADED_USER";

export const LOAD_LANDKIND_USERS = "LOAD_LANDKIND_USERS";
export const LOADED_LANDKIND_USERS = "LOADED_LANDKIND_USERS";

export const REMOVE_LANDKIND_USER = "REMOVE_LANDKIND_USER";
export const REMOVED_LANDKIND_USER = "REMOVED_LANDKIND_USER";

export const UPDATE_LANDKIND_USER = "UPDATE_LANDKIND_USER";
export const UPDATED_LANDKIND_USER = "UPDATED_LANDKIND_USER";

export const LOAD_LANDKIND_USER_ORGANISATIONS =
  "LOAD_LANDKIND_USER_ORGANISATIONS";
export const LOADED_LANDKIND_USER_ORGANISATIONS =
  "LOADED_LANDKIND_USER_ORGANISATIONS";

export const LOAD_LANDKIND_USER_INVITES = "LOAD_LANDKIND_USER_INVITES";
export const LOADED_LANDKIND_USER_INVITES = "LOADED_LANDKIND_USER_INVITES";

export const LOAD_USER_PROPERTIES_MEMBERSHIP =
  "LOAD_USER_PROPERTIES_MEMBERSHIP";
export const LOADED_USER_PROPERTIES_MEMBERSHIP =
  "LOADED_USER_PROPERTIES_MEMBERSHIP";

export const LOAD_USER_PROPERTY_GROUPS = "LOAD_USER_PROPERTY_GROUPS";
export const LOADED_USER_PROPERTY_GROUPS = "LOADED_USER_PROPERTY_GROUPS";

export const LOAD_WORKFLOW = "LOAD_WORKFLOW";
export const LOADED_WORKFLOW = "LOADED_WORKFLOW";
export const LOAD_WORKFLOW_PROPERTY_GROUPS = "LOAD_WORKFLOW_PROPERTY_GROUPS";
export const LOADED_WORKFLOW_PROPERTY_GROUPS =
  "LOADED_WORKFLOW_PROPERTY_GROUPS";
export const LOAD_WORKFLOW_PROPERTIES = "LOAD_WORKFLOW_PROPERTIES";
export const LOADED_WORKFLOW_PROPERTIES = "LOADED_WORKFLOW_PROPERTIES";
export const CREATE_PROPERTIES = "CREATE_PROPERTIES";
export const CREATED_PROPERTIES = "CREATED_PROPERTIES";
export const REMOVE_PROPERTY = "REMOVE_PROPERTY";
export const REMOVED_PROPERTY = "REMOVED_PROPERTY";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const UPDATED_PROPERTY = "UPDATED_PROPERTY";
export const UPDATE_PROPERTY_DATA = "UPDATE_PROPERTY_DATA";
export const UPDATED_PROPERTY_DATA = "UPDATED_PROPERTY_DATA";
export const CREATE_WORKFLOW = "CREATE_WORKFLOW";
export const CREATED_WORKFLOW = "CREATED_WORKFLOW";
export const CREATE_WORKFLOW_FAILED = "CREATE_WORKFLOW_FAILED";
export const REMOVE_WORKFLOW = "REMOVE_WORKFLOW";
export const REMOVED_WORKFLOW = "REMOVED_WORKFLOW";
export const UPDATE_WORKFLOW = "UPDATE_WORKFLOW";
export const UPDATED_WORKFLOW = "UPDATED_WORKFLOW";
export const CREATE_ROLE = "CREATE_ROLE";
export const CREATED_ROLE = "CREATED_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_ADMIN = "UPDATE_ROLE_ADMIN";
export const UPDATED_ROLE = "UPDATED_ROLE";
export const UPDATE_ORGANISATION = "UPDATE_ORGANISATION";
export const UPDATED_ORGANISATION = "UPDATED_ORGANISATION";
export const CREATE_ORGANISATION = "CREATE_ORGANISATION";
export const CREATED_ORGANISATION = "CREATED_ORGANISATION";
export const REMOVE_ORGANISATION = "REMOVE_ORGANISATION";
export const REMOVED_ORGANISATION = "REMOVED_ORGANISATION";
export const CREATE_DATASET = "CREATE_DATASET";
export const CREATED_DATASET = "CREATED_DATASET";

export const CREATE_INVITE = "CREATE_INVITE";
export const CREATED_INVITE = "CREATED_INVITE";
export const CREATE_INVITE_ERROR = "CREATE_INVITE_ERROR";

export const RESEND_INVITE = "RESEND_INVITE";
export const RESENDED_INVITE = "RESENDED_INVITE";

export const UPDATE_USER_MEMBERSHIP = "UPDATE_USER_MEMBERSHIP";
export const UPDATED_USER_MEMBERSHIP = "UPDATED_USER_MEMBERSHIP";
export const UPDATE_USER_MEMBERSHIP_FAILED = "UPDATE_USER_MEMBERSHIP_FAILED";

export const STATUS_EMPTY = "EMPTY";
export const STATUS_LOADING = "LOADING";
export const STATUS_UPDATING = "UPDATING";
export const STATUS_ERROR = "ERROR";
export const STATUS_READY = "READY";
export const STATUS_CREATING = "CREATING";
export const STATUS_REMOVING = "STATUS_REMOVING";
export const STATUS_RESENDING = "STATUS_RESENDING";
export const STATUS_ARCHIVING = "STATUS_ARCHIVING";
export const STATUS_RESTORING = "STATUS_RESTORING";

export const SUCCESSFUL_AUTHENTICATION = "SUCCESSFUL_AUTHENTICATION";
export const ERROR_AUTHENTICATION = "ERROR_AUTHENTICATION";
export const INVALIDATE_AUTHENTICATION = "INVALIDATE_AUTHENTICATION";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_LOADING_TEXT = "SET_LOADING_TEXT";
export const GET_LOADING_TEXT = "GET_LOADING_TEXT";
export const SET_APP_LOADING = "SET_APP_LOADING";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SET_MODAL_CONTENT = "SET_MODAL_CONTENT";

export const DEFAULT_LANGUAGE = "english";
export const DEFAULT_LANGUAGE_JSON_URL =
  "http://agbox-assets.s3-us-west-2.amazonaws.com/language/english.json";

export const BUTTON_TYPE_PRIMARY = "primary";
export const BUTTON_TYPE_PRIMARY_FULLWIDTH = "primaryFullWidth";
export const BUTTON_TYPE_PRIMARY_BIG = "bigPrimary";
export const BUTTON_TYPE_PRIMARY_BIG_FULLWIDTH = "bigPrimaryFullWidth";
export const BUTTON_TYPE_SECONDARY = "secondary";
export const BUTTON_TYPE_SECONDARY_FULLWIDTH = "secondaryFullWidth";
export const BUTTON_TYPE_SECONDARY_BIG = "bigSecondary";
export const BUTTON_TYPE_SECONDARY_BIG_FULLWIDTH = "bigSecondaryFullWidth";

export const BUTTON_TYPE_SIGN_OUT = "signOut";
export const BUTTON_TYPE_USER = "user";
export const BUTTON_TYPE_SIBLING_MENU = "siblingMenu";
export const BUTTON_TYPE_LIST_CONTENT = "listContent";
export const BUTTON_TYPE_LIST_CONTENT_CHILD = "listContentChild";
export const BUTTON_TYPE_CONTEXT_MENU_CHILD = "contextMenuChild";
export const DESCRIPTION_TEXT_PARAGRAPH = "descriptionText";
export const STYLETYPE_ERROR = "error";
export const STYLETYPE_CODE = "code";
export const STYLETYPE_FORM_FIELD = "formField";
export const STYLETYPE_RADIOBUTTON = "radoButton";
export const STYLETYPE_RADIO_TABLE = "radioTable";
export const STYLETYPE_PASSWORD = "password";
export const STYLETYPE_DATE = "date";
export const STYLETYPE_TEXT = "text";
export const STYLETYPE_HELPER = "helper";
export const STYLETYPE_HELPTEXT = "helpText";
export const STYLETYPE_CHECKBOX = "checkbox";
export const STYLETYPE_CHECKBOX_CONTAINER = "checkboxContainer";
export const STYLE_TYPE_EMAIL = "email";
export const STYLE_TYPE_NAME = "name";
export const STYLE_TYPE_NUMBER = "number";
export const STYLETYPE_FORM_FIELD_FLEX = "formFieldFlex";

export const FORM_STYLE_TYPES = [STYLETYPE_ERROR, STYLETYPE_PASSWORD];

export const APPLICATION_HEADER_ID = "ApplicationHeader";
export const BREADCRUMB_NAVIGATION_ID = "BreadCrumbNavigation";
export const LISTCONTENT_ITEM_LIST_ID = "ListContentItemList";
export const MODAL_ID = "Modal";
export const MODAL_BOTTOM_BUTTONS_ID = "ModalBottomButtons";
export const MODAL_BODY_ID = "ModalBody";
export const MODAL_HEADER_ID = "ModalHeader";
export const CONTEXT_MENU_ID = "ContextMenu";
export const CONTEXT_MENU_ID_LIST = "ContextMenuList";
export const CONTEXT_MENU_ID_LIST_ITEM = "ContextMenuListItem";
export const CONTEXT_HEADER_ID = "ContextHeader";
export const CONTEXT_HEADER_CONTAINER = "contextHeaderContainer";
export const DROPDOWN_ID = "Dropdown";
export const DROPDOWN_LIST_ID = "DropdownList";
export const DROPDOWN_LIST_ITEM_ID = "DropdownListItem";
export const FILTER_INPUT_ID = "FilterInput";

export const LOADER_SIZE_LARGE = "LOADER_SIZE_LARGE";
export const LOADER_SIZE_REGULAR = "LOADER_SIZE_REGULAR";
export const LOADER_SIZE_SMALL = "LOADER_SIZE_SMALL";
export const LOADER_ID = "LOADER";

export const FIELD_SIZE_LARGE = "FIELD_SIZE_LARGE";
export const FIELD_SIZE_MEDIUM = "FIELD_SIZE_MEDIUM";
export const FIELD_SIZE_SMALL = "FIELD_SIZE_SMALL";

export const LOADER_FADE_TIMEOUT = 200;
export const CONTENT_FADE_TIMEOUT = 400;

export const HEADER_1 = "Header1";
export const HEADER_2 = "Header2";
export const HEADER_3 = "Header3";

export const COMMON_FILTER_LIST = "COMMON_FILTER_LIST";
export const PROGRESS_BAR_STATUS_TODO = "todo";
export const PROGRESS_BAR_STATUS_INPROGRESS = "inProgress";
export const PROGRESS_BAR_STATUS_COMPLETE = "complete";
export const DEVICE_WEB = "web";
export const DEVICE_MOBILE = "mobile";
export const BACK_BUTTON = "BACK_BUTTON";
export const LINK_BUTTON = "LINK_BUTTON";

export const CONTENT_VIEW = "CONTENT_VIEW";
export const ADD_BUTTON = "ADD_BUTTON";

export const REACT_APP_ASSET_PROXY_URL_ENV = `${process.env.REACT_APP_ASSET_PROXY_URL}`;

export const LANDKIND_LOGO = `${REACT_APP_ASSET_PROXY_URL_ENV}/agbox-assets.s3-us-west-2.amazonaws.com/logos/Landkind-Transparent-logo.png`;
export const EMAIL_REGEXP = new RegExp(
  `^[a-zA-Z0-9.!#$%&'*+\/=?^_\`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$`
);
export const ABORT_ERROR_NAME = "AbortError";
export const CREATE_PROPERTY_GROUP = "CREATE_PROPERTY_GROUP";
export const UPDATE_PROPERTY_GROUP = "UPDATE_PROPERTY_GROUP";
export const ADD_PROPERTIES_TO_PROPERTY_GROUP =
  "ADD_PROPERTIES_TO_PROPERTY_GROUP";
export const UPDATED_PROPERTY_GROUP = "UPDATED_PROPERTY_GROUP";

export const PASSWORD_REGEXP = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,100})`
);
export const CHECKBOX_ICON_STRING = `url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 230.4 229.9' style='enable-background:new 0 0 230.4 229.9;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23ffffff;%7D%0A%3C/style%3E%3Cpath id='Icon_awesome-check' class='st0' d='M83.5,185.7l-63.9-63.9c-3.8-3.8-3.8-10,0-13.9l13.9-13.9c3.8-3.8,10-3.8,13.9,0 l43.1,43l92.2-92.2c3.8-3.8,10-3.8,13.9,0l13.9,13.9c3.8,3.8,3.8,10,0,13.9l-113,113C93.6,189.5,87.3,189.5,83.5,185.7z'/%3E%3C/svg%3E")`;
export const CONTENT_LIST_TABLE_HEADER = "CONTENT_LIST_TABLE_HEADER";
export const CONTENT_LIST_TABLE = "CONTENT_LIST_TABLE";
export const REMOVE_USER_MEMBERSHIP = "REMOVE_USER_MEMBERSHIP";
export const REMOVED_USER_MEMBERSHIP = "REMOVED_USER_MEMBERSHIP";
export const LOAD_PROPERTY_USERS = "LOAD_PROPERTY_USERS";
export const LOADED_PROPERTY_USERS = "LOADED_PROPERTY_USERS";
export const JOB_STATUS_RUNNING = "RUNNING";
export const JOB_STATUS_SUCCEEDED = "SUCCEEDED";
export const JOB_STATUS_FAILED = "FAILED";
export const LANDKIND_ADMIN_CONSOLE_TEXT = "Landkind Admin Console";
export const SET_ABORT_CONTROLLER = "SET_ABORT_CONTROLLER";
export const STYLETYPE_TOGGLE_SWITCH = "STYLETYPE_TOGGLE_SWITCH";
export const STYLETYPE_TOGGLE_SWITCH_CONTAINER =
  "STYLETYPE_TOGGLE_SWITCH_CONTAINER";
export const LABEL_2D = "2D";
export const LABEL_3D = "3D";
export const REMOVE_PROPERTY_GROUP = "REMOVE_PROPERTY_GROUP";
export const REMOVED_PROPERTY_GROUP = "REMOVED_PROPERTY_GROUP";
export const ADDRESS_CANDIDATES_URL =
  "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?";
export const ADDRESS_SUGGESTIONS_URL =
  "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?";
export const ESRI_GEOLOCATOR_API_KEY =
  process.env.REACT_APP_ESRI_GEOLOCATOR_API_KEY;
export const WORKFLOW_DETAILS_TABLES_STYLETYPE =
  "WORKFLOW_DETAILS_TABLES_STYLETYPE";
export const SET_ORG_PROPERTIES_PAGINATION = "SET_ORG_PROPERTIES_PAGINATION";
export const SET_ORG_ARCHIVED_PROPS_PAGINATION =
  "SET_ORG_ARCHIVED_PROPS_PAGINATION";
export const SET_GROUP_USERS_PAGINATION = "SET_GROUP_USERS_PAGINATION";
export const CHECK_EMAIL_STYLETYPE = "CHECK_EMAIL_STYLETYPE";

export const SEARCH_TIMEOUT = 800;
export const MINIMUM_SEARCH_STRING_LENGTH = 2;

export const ADD_NEW_ORGANISATION = "Add New Organisation";
export const INVITE_USER_TO_ORGANISATION = "Invite user to organisation";

export const PAGINATION_STYLING = "PAGINATION_STYLING";
export const DATA_TABLE_CONTAINER_STYLING = "DATA_TABLE_CONTAINER_STYLING";
export const DATA_TABLE_SECTION_STYLING = "DATA_TABLE_SECTION_STYLING";
export const DATA_TABLE_CHECKBOX_STYLING = "DATA_TABLE_CHECKBOX_STYLING";
export const DATA_TABLE_TITLE_CONTAINER_STYLING =
  "DATA_TABLE_TITLE_CONTAINER_STYLING";
export const DATA_TABLE_FILTER_INPUT_STYLING =
  "DATA_TABLE_FILTER_INPUT_STYLING";
export const DATA_TABLE_PAGINATION_STYLING = "DATA_TABLE_PAGINATION_STYLING";
export const DATA_TABLE_BUTTON_TITLE_STYLING =
  "DATA_TABLE_BUTTON_TITLE_STYLING";
export const DATA_TABLE_HEADING_STYLING = "DATA_TABLE_HEADING_STYLING";
export const ORG_PROPERTIES_LIMIT = 10000;

export const ACCORDION_HEADER_STYLING = "ACCORDION_HEADER_STYLING";
export const ACCORDION_ROW_STYLING = "ACCORDION_ROW_STYLING";
export const ACCORDION_CONTENT_STYLING = "ACCORDION_CONTENT_STYLING";
export const SECTION_HEADER_STYLING = "SECTION_HEADER_STYLING";

export const CLEAR_AUTHENTICATION = "CLEAR_AUTHENTICATION";
export const AUTH_VIEW = "AUTH_VIEW";
export const AUTH_TEXT = "AUTH_TEXT";
export const LOGIN_BUTTON = "LOGIN_BUTTON";
export const LOGIN_VIEW = "LOGIN_VIEW";
export const RESET_LOGGING_OUT = "RESET_LOGGING_OUT";
export const UPLOAD_ORG_LOGO = "UPLOAD_ORG_LOGO";
export const LOAD_ORGANISATION_INVITED_USERS =
  "LOAD_ORGANISATION_INVITED_USERS";
export const LOADED_ORGANISATION_INVITED_USERS =
  "LOADED_ORGANISATION_INVITED_USERS";
export const LOAD_ORGANISATION_ACCEPTED_USERS =
  "LOAD_ORGANISATION_ACCEPTED_USERS";
export const LOADED_ORGANISATION_ACCEPTED_USERS =
  "LOADED_ORGANISATION_ACCEPTED_USERS";
export const LOAD_ACCEPTED_USER = "LOAD_ACCEPTED_USER";
export const LOADED_ACCEPTED_USER = "LOADED_ACCEPTED_USER";
export const LOAD_INVITED_USER = "LOAD_INVITED_USER";
export const LOADED_INVITED_USER = "LOADED_INVITED_USER";
export const INVITES_TEXT = "invites";
export const REMOVE_INVITE = "REMOVE_INVITE";
export const REMOVED_INVITE = "REMOVED_INVITE";
export const PAGINATION_V2_STYLING = "PAGINATION_V2_STYLING";

export const ALWAYS = "Always";
export const NEVER = "Never";
export const OPTIONAL = "Optional";
export const V2_PARAM = "v2=true";
export const PROPERTY_SELECTOR_BY_TITLE = "${this.title}";
export const PROPERTY_SELECTOR_BY_OFFICIALNAME = "${this.officalName}";

export const ARCHIVE_PROPERTY = "ARCHIVE_PROPERTY";
export const ARCHIVED_PROPERTY = "ARCHIVED_PROPERTY";
export const RESTORE_PROPERTY = "RESTORE_PROPERTY";
export const RESTORED_PROPERTY = "RESTORED_PROPERTY";
export const LOAD_ARCHIVED_PROPERTY = "LOAD_ARCHIVED_PROPERTY";
export const LOADED_ARCHIVED_PROPERTY = "LOADED_ARCHIVED_PROPERTY";
export const LOAD_ORG_ARCHIVED_PROPERTIES = "LOAD_ORG_ARCHIVED_PROPERTIES";
export const LOADED_ORG_ARCHIVED_PROPERTIES = "LOADED_ORG_ARCHIVED_PROPERTIES";

export const SET_PROPERTY_ERROR = "SET_PROPERTY_ERROR";

export const STYLETYPE_STATUS = "STYLETYPE_STATUS";
export const PROPERTY_TEXT = "property";
export const PROPERTY_GROUP_TEXT = "propertyGroup";
export const ORG_ID_SLUG = "organisation/:orgId";
export const GROUP_ID_SLUG = ":propertyGroupId";
export const PROP_ID_SLUG = ":propertyId";
export const ARCHIVED_SLUG = "archived";
export const ROLE_ID_SLUG = ":roleId";
export const WORKFLOW_ID_SLUG = ":workflowId";
export const USER_ID_SLUG = ":userId";
export const INVITE_ID_SLUG = ":inviteId";
export const DATASET_ID_SLUG = ":datasetId";
export const ADD_PERMISSION = "add";
export const CREATE_PERMISSION = "create";
export const READ_PERMISSION = "read";
export const READ_DETAILS_PERMISSION = "read-details";
export const UPDATE_PERMISSION = "update";
export const SUPER_HUMAN_PERMISSION = "superHuman";
export const ARCHIVE_PERMISSION = "archive";
export const DELETE_PERMISSION = "delete";
export const ORGANISATIONS_SECTION = "organisations";
export const ROLES_SECTION = "roles";
export const INVITES_SECTION = "invites";
export const USERS_SECTION = "users";
export const PROPERTY_GROUPS_SECTION = "propertyGroups";
export const PROPERTIES_SECTION = "properties";
export const WORKFLOWS_SECTION = "workflows";
export const RENDERERS_SECTION = "renderers";
export const DATASETS_SECTION = "dataSets";
export const RENDERER_ID_SLUG = ":rendererId";
export const UPDATE_RENDERER = "UPDATE_RENDERER";
export const UPDATED_RENDERER = "UPDATED_RENDERER";

export const LANDKIND_LOGO_SVG = `${REACT_APP_ASSET_PROXY_URL_ENV}/agbox-assets.s3-us-west-2.amazonaws.com/logos/landkind.svg`;

export const LANDKIND_LOGO_ALT_TEXT = "Landkind logo";
export const LANDKIND_LOGO_AUTH_WIDTH = "240px";
export const STYLETYPE_AUTH_HEADING = "STYLETYPE_AUTH_HEADING";
export const PAGINATION_OPTIONS = [5, 10, 50];
export const PAGINATION_KEY = "pagination";
export const REMOVE_ROLE = "REMOVE_ROLE";
export const REMOVED_ROLE = "REMOVED_ROLE";

export const LOAD_DATASET_TEMPLATES = "LOAD_DATASET_TEMPLATES";
export const LOADED_DATASET_TEMPLATES = "LOADED_DATASET_TEMPLATES";
export const LOAD_LAYER_RENDERER_TEMPLATES = "LOAD_LAYER_RENDERER_TEMPLATES";
export const LOADED_LAYER_RENDERER_TEMPLATES =
  "LOADED_LAYER_RENDERER_TEMPLATES";
export const LOAD_LABEL_RENDERER_TEMPLATES = "LOAD_LABEL_RENDERER_TEMPLATES";
export const LOADED_LABEL_RENDERER_TEMPLATES =
  "LOADED_LABEL_RENDERER_TEMPLATES";
export const LOAD_WORKFLOW_TEMPLATES = "LOAD_WORKFLOW_TEMPLATES";
export const LOADED_WORKFLOW_TEMPLATES = "LOADED_WORKFLOW_TEMPLATES";

export const LABEL_RENDERER_TYPE_TEXT = "labelRenderer";
export const LAYER_RENDERER_TYPE_TEXT = "layerRenderer";
export const DATA_SET_TYPE_TEXT = "dataset";
export const WORKFLOW_TYPE_TEXT = "workflow";

export const ROW_NUMBERS_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Land_Features/FeatureServer/0";

export const ENTRANCES_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Land_Features/FeatureServer/1";

export const BASE_POINTS_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Land_Features/FeatureServer/2";

export const ACCESSWAYS_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Land_Features/FeatureServer/3";

export const BASE_POLYLINES_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Land_Features/FeatureServer/4";

export const BASE_POLYGONS_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Land_Features/FeatureServer/5";

export const HAZARD_POINTS_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Hazards/FeatureServer/0";

export const HAZARD_POLYLINES_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Hazards/FeatureServer/1";

export const HAZARD_POLYGONS_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Hazards/FeatureServer/2";
export const KIWIFRUIT_BLOCKS_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Kiwifruit_Blocks/FeatureServer/0";

export const PROP_BOUNDARIES_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Boundaries/FeatureServer/2";

export const KPIN_POINTS_LAYER =
  "https://services1.arcgis.com/YAnRDYVL1tmpajaA/arcgis/rest/services/Kiwifruit/FeatureServer/0";
export const CREATE_PROPERTY_MODE_WIZARD = "wizard";
export const CREATE_PROPERTY_MODE_RAW = "raw";
export const CREATE_PROPERTY_MODE_FORM = "form";
export const ESRI_TOKEN_KEY = "esriToken";
export const CREATE_PROPERTY_MODE_KEY = "createPropertyMode";

export const IMPORT_DATA_OPTIONS = [
  {
    value: "blocks",
    checked: false,
    agolLayer: KIWIFRUIT_BLOCKS_LAYER,
    datasetName: "Crops",
    layerTitle: "cropPoly",
    fields: {
      variety: "varietyedi",
      title: "name",
      coverStatus: "coverstatus",
      notchVariety: "notchvarietyedi"
    },
    fixedValues: {
      category: "Kiwifruit"
    },
    customDataFields: {
      surveyDate: {
        field: "surveydate",
        type: "date"
      },
      blockLayout: {
        field: "blocklayout",
        type: "string"
      },
      blockId: {
        field: "blockid",
        type: "string"
      },
      coverDate: {
        field: "coverdate",
        type: "date"
      },
      graftDate: {
        field: "graftdate",
        type: "date"
      },
      graftType: {
        field: "grafttype",
        type: "string"
      },
      growMethod: {
        field: "growmethod",
        type: "string"
      },
      notchDate: {
        field: "notchVarietyGraftDate",
        type: "date"
      },
      numberOfRows: {
        field: "nonumberofrowstchvarietyedi",
        type: "integer"
      },
      organicStatus: {
        field: "organicstatus",
        type: "string"
      },
      structure: {
        field: "structuretype",
        type: "string"
      },
      supplyArea: {
        field: "supplyarea",
        type: "string"
      },
      supplyRegion: {
        field: "supplyregion",
        type: "string"
      },
      productionStatus: {
        field: "productionstatus",
        type: "string"
      }
    }
  },
  {
    value: "hazards",
    checked: false,
    expanded: false,
    datasetName: "Hazards",
    fields: {
      category: "HazardType"
    },
    customDataFields: {
      description: {
        type: "string"
      },
      showLabel: {
        type: "string",
        value: "displayLabel"
      }
    },
    layers: [
      {
        value: "hazardPoly",
        checked: false,
        agolLayer: HAZARD_POLYGONS_LAYER,
        layerTitle: "hazardPoly"
      },
      {
        value: "hazardLine",
        checked: false,
        agolLayer: HAZARD_POLYLINES_LAYER,
        layerTitle: "hazardLine"
      },
      {
        value: "hazardPoint",
        checked: false,
        agolLayer: HAZARD_POINTS_LAYER,
        layerTitle: "hazardPoint"
      }
    ]
  },
  {
    value: "baseData",
    checked: false,
    expanded: false,
    datasetName: "BaseData",
    customDataFields: {
      showLabel: {
        type: "string",
        value: "dontShowLabel"
      }
    },
    layers: [
      {
        value: "basePoly",
        checked: false,
        agolLayer: BASE_POLYGONS_LAYER,
        layerTitle: "basePoly",
        fields: {
          title: "description",
          category: "FeatureType"
        }
      },
      {
        value: "baseLine",
        checked: false,
        agolLayer: BASE_POLYLINES_LAYER,
        layerTitle: "baseLine",
        fields: {
          title: "label",
          category: "FeatureType"
        }
      },
      {
        value: "basePoint",
        checked: false,
        agolLayer: BASE_POINTS_LAYER,
        layerTitle: "basePoint",
        fields: {
          title: "label",
          category: "FeatureType"
        }
      }
    ]
  },
  {
    value: "accessways",
    checked: false,
    agolLayer: ACCESSWAYS_LAYER,
    datasetName: "BaseData",
    layerTitle: "baseLine",
    fixedValues: {
      title: "Accessway",
      category: "Accessway"
    },
    customDataFields: {
      showLabel: {
        type: "string",
        value: "dontShowLabel"
      }
    }
  },
  {
    value: "entrances",
    checked: false,
    agolLayer: ENTRANCES_LAYER,
    datasetName: "BaseData",
    layerTitle: "basePoint",
    fixedValues: {
      category: "${this.category} Entrance"
    },
    fields: {
      title: "roadno",
      roadNumber: "roadno"
    },
    customDataFields: {
      roadNumber: {
        field: "roadno",
        type: "string"
      },
      showLabel: {
        type: "string",
        value: "dontShowLabel"
      }
    }
  },
  {
    value: "rowNumbers",
    checked: false,
    agolLayer: ROW_NUMBERS_LAYER,
    datasetName: "BaseData",
    layerTitle: "basePoint",
    fields: {
      title: "rownumber"
    },
    fixedValues: {
      category: "Row Number"
    },
    customDataFields: {
      showLabel: {
        type: "string",
        value: "displayLabel"
      }
    }
  }
];
export const VALUES_DICTIONARY = {
  Pergola: "PG",
  "T-Bar": "TB",
  "Overhead Cable": "PG",
  "Posts Only": "PO",
  MIXED: "MX",
  Proposed: "PR",
  Trees: "TR",
  Vines: "VI",
  "Hail netting": "Covered",
  Uncovered: "Uncovered",
  Waterproof: "Covered",
  "Local Market Supply Only": "Producing",
  "Local Market Supply Only/Male Vines": "Producing",
  "Male Vines": "Non Producing",
  "Newly Grafted/Immature Vines": "Non Producing",
  "Non-producing": "Non Producing",
  "Non Producing": "Non Producing",
  NP: "Non Producing",
  prod: "Producing",
  producing: "Producing",
  Producing: "Producing",
  "Removed Vines (Incl Roots) - Non Psa": "Non Producing",
  "Rootstock Only": "Non Producing",
  Unknown: "None",
  Irrigation: "Pond",
  "Effluent Pond": "Effluent Pond",
  "Dam/Pond": "Pond",
  "Loading Area": "Accessway",
  Road: "Accessway",
  RU: "ZFP",
  ZFP_RS: "ZFP",
  RO: "ZFP",
  RG: "ZFP",
  RE: "ZFP",
  none: null,
  None: null
};

export const STYLE_TYPE_FORM_HEADING = "STYLE_TYPE_FORM_HEADING";

export const DISTANCE_UNIT_TEXT = "DistanceUnit";
export const AREA_UNIT_TEXT = "AreaUnit";

export const DISTANCE_UNIT_OPTIONS = [
  {
    shortname: "m",
    unit: "meters"
  },
  {
    shortname: "km",
    unit: "kilometers"
  }
];

export const AREA_UNIT_OPTIONS = [
  {
    shortname: "sqm",
    unit: "square-meters"
  },
  {
    shortname: "sqkm",
    unit: "square-kilometers"
  },
  {
    shortname: "ha",
    unit: "hectares"
  }
];

export const BASE_MAP_OPTIONS = [
  {
    id: "arcgis-imagery-standard",
    title: "ESRI World Imagery",
    attribution:
      "Sources: Esri, DigitalGlobe, GeoEye, i-cubed, USDA FSA, USGS, AEX, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community"
  }
];
export const STYLE_TYPE_BUTTON_CELL = "STYLE_TYPE_BUTTON_CELL";
export const STYLE_TYPE_DATA_TABLE_ROW = "STYLE_TYPE_DATA_TABLE_ROW";
export const STYLE_TYPE_TABLE_HEADER_BUTTON = "STYLE_TYPE_TABLE_HEADER_BUTTON";
export const LOAD_ORGANISATION_DATASETS = "LOAD_ORGANISATION_DATASETS";
export const LOADED_ORGANISATION_DATASETS = "LOADED_ORGANISATION_DATASETS";
export const LOAD_ORGANISATION_RENDERERS = "LOAD_ORGANISATION_RENDERERS";
export const LOADED_ORGANISATION_RENDERERS = "LOADED_ORGANISATION_RENDERERS";
export const LOAD_ORGANISATION_RENDERER = "LOAD_ORGANISATION_RENDERER";
export const LOADED_ORGANISATION_RENDERER = "LOADED_ORGANISATION_RENDERER";
export const CREATE_RENDERER = "CREATE_RENDERER";
export const CREATED_RENDERER = "CREATED_RENDERER";
export const ADD_PROPERTIES_TO_GROUPS = "ADD_PROPERTIES_TO_GROUPS";
export const IMPORT_DATA_FOR_PROPERTIES = "IMPORT_DATA_FOR_PROPERTIES";
export const IMPORTED_DATA_FOR_PROPERTIES = "IMPORTED_DATA_FOR_PROPERTIES";
export const STYLE_TYPE_CUSTOM_MAP = "STYLE_TYPE_CUSTOM_MAP";
export const STYLE_TYPE_CUSTOM_MAP_LAYER = "STYLE_TYPE_CUSTOM_MAP_LAYER";
export const CUSTOM_MAP_LAYER_TYPE_OPTIONS = ["Community", "Organisational"];
export const STYLE_TYPE_FORM_HALF_WIDTH = "STYLE_TYPE_FORM_HALF_WIDTH";
export const ESRI_GENERATE_TOKEN_URL =
  "https://www.arcgis.com/sharing/rest/generateToken";
export const STYLE_TYPE_DATA_TABLE_SORT_HEADER_BUTTON =
  "STYLE_TYPE_DATA_TABLE_HEADER_BUTTON";
export const STYLE_TYPE_TABLE_BUTTON = "STYLE_TYPE_TABLE_BUTTON";
export const LABEL_TEXT = "label";
export const LAYER_TEXT = "layer";
export const ASSETS_PROXY_SUBSTRING = "assets.landkind.com";
export const HEIGHT_AUTO_TEXT = "auto";
export const STYLE_TYPE_MODAL_INPUT_CONTAINER =
  "STYLE_TYPE_MODAL_INPUT_CONTAINER";
export const LANGUAGE_PREFERENCES_OPTIONS = ["English"];
export const RENDERER_KEYS = ["Point", "Line", "Poly"];
