import styled, { css } from "styled-components";
import { ImageElement } from "../../core/Image/style";
import { STYLETYPE_AUTH_HEADING } from "@base/constants";

export const Heading1Element = styled.h1`
  font-family: ${(props) => props.theme.baseFont};
  font-weight: ${(props) =>
    props.styleType === STYLETYPE_AUTH_HEADING
      ? props.theme.baseFontWeightHeavy
      : props.theme.baseFontWeight};
  line-height: ${(props) => props.theme.lineHeightBase};
  font-size: ${(props) =>
    props.styleType === STYLETYPE_AUTH_HEADING
      ? props.theme.fontSizeOne
      : props.theme.fontSizeThree};
  color: ${(props) =>
    props.styleType === STYLETYPE_AUTH_HEADING
      ? props.theme.agGreen
      : props.theme.agCommonBlack};
  text-align: ${(props) =>
    props.styleType === STYLETYPE_AUTH_HEADING ? "center" : "left"};
  margin: ${(props) =>
    props.styleType === STYLETYPE_AUTH_HEADING ? "1.2rem 0" : "0 0 1.2rem 0"};
  ${ImageElement} + & {
    margin-left: ${(props) =>
      props.styleType === STYLETYPE_AUTH_HEADING ? "0" : "2.8rem"};
  }

  ${(props) =>
    props.styleType === "headerText" &&
    css`
      flex-grow: 100;
      text-transform: uppercase;
      color: ${(props) => props.theme.agMischkaGray};
      font-size: ${(props) => props.theme.fontSizeFour};
      font-weight: ${(props) => props.theme.baseFontWeightHeavy};
      letter-spacing: 0rem;
      padding: 0rem 1.5rem;
      align-self: end;
      line-height: ${(props) => props.theme.lineHeightSmallest};
      margin: 0 0 1.1rem 0;
      ${ImageElement} + & {
        margin-left: 0;
      }
    `}
`;
Heading1Element.displayName = "Heading1Element";
