import { connect } from "react-redux";
import SuperHumanUsersDetail from "./SuperHumanUsersDetail";
import * as actions from "../../../actions";
import {
  getLabels,
  getPageTitle,
  getAbortController,
  getHasPermission,
  getLandkindUserOrganisationsLoading,
  getLandkindUserOrganisations,
  getLandkindUserInvitesLoading,
  getLandkindUserInvites,
  getSelectedUser,
  getSelectedUserStatus,
  getLandkindUserStatus
} from "../../../selectors";

export default connect(
  (state) => ({
    propState: state,
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    abortController: getAbortController(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType),
    landkindUserOrganisationsLoading:
      getLandkindUserOrganisationsLoading(state),
    landkindUserOrganisations: getLandkindUserOrganisations(state),
    landkindUserInvitesLoading: getLandkindUserInvitesLoading(state),
    landkindUserInvites: getLandkindUserInvites(state),
    selectedUser: getSelectedUser(state),
    selectedUserStatus: getSelectedUserStatus(state),
    landkindUserStatus: getLandkindUserStatus(state)
  }),
  actions
)(SuperHumanUsersDetail);
