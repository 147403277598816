import { connect } from "react-redux";
import AddOrganisation from "./AddOrganisation";
import * as actions from "../../../actions";
import {
  getLabels,
  getUserLoginId,
  getOrgsStatus,
  getAbortController,
  getPageTitle,
  getAvailableOrgs,
  getDatasetTemplates,
  getDatasetTemplatesStatus,
  getLayerRendererTemplates,
  getLayerRendererStatus,
  getLabelRendererTemplates,
  getLabelRendererStatus
} from "../../../selectors";
import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    adminId: getUserLoginId(state),
    createOrgStatus: getOrgsStatus(state),
    abortController: getAbortController(state),
    organisations: getAvailableOrgs(state),
    datasetTemplates: getDatasetTemplates(state),
    datasetTemplatesLoading:
      getDatasetTemplatesStatus(state) === STATUS_LOADING,
    layerRendererTemplates: getLayerRendererTemplates(state),
    layerRendererTemplatesLoading:
      getLayerRendererStatus(state) === STATUS_LOADING,
    labelRendererTemplates: getLabelRendererTemplates(state),
    labelRendererTemplatesLoading:
      getLabelRendererStatus(state) === STATUS_LOADING,
    rendererTemplatesLoading:
      getLayerRendererStatus(state) === STATUS_LOADING ||
      getLabelRendererStatus(state) === STATUS_LOADING
  }),
  actions
)(AddOrganisation);
