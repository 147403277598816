import styled, { css } from "styled-components";

export const LegendElement = styled.legend`
  font-family: ${(props) => props.theme.baseFont};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  line-height: ${(props) => props.theme.lineHeightBase};
  font-size: ${(props) => props.theme.fontSizeOne};
  color: ${(props) => props.theme.agCommonBlack};
  letter-spacing: 0.01em;
  margin-bottom: 0.8rem;
  padding: 0;

  ${(props) =>
    props.styleType === "labels" &&
    css`
      color: ${(props) => props.theme.adminPrimary};
      font-size: ${(props) => props.theme.fontSizeSmall};
      font-weight: ${(props) => props.theme.baseFontWeightHeavy};
    `}

  ${(props) =>
    props.styleType === "checkbox-header" &&
    css`
      margin-bottom: 0;
      color: ${(props) => props.theme.agWhite};
      background-color: ${(props) => props.theme.agMischkaGray};
      height: 2.188rem;
      padding: 0.625rem 0 0 0.938rem;
      width: calc(100% - 0.938rem);
    `}
`;
LegendElement.displayName = "LegendElement";
