import React from "react";
import PropTypes from "prop-types";
import { Heading3Element } from "./style";

/** Renders Heading3 Element  NOTE text is WHITE! - Used for the header component, always use on an element with a background color*/
const Heading3 = (props) => {
  return (
    <Heading3Element styleType={props.styleType}>
      {props.children}
    </Heading3Element>
  );
};

Heading3.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.string
};

export default Heading3;
