import styled, { css } from "styled-components";
import {
  APPLICATION_HEADER_ID,
  LISTCONTENT_ITEM_LIST_ID,
  LOADER_SIZE_REGULAR,
  LOADER_ID,
  MODAL_ID,
  MODAL_BOTTOM_BUTTONS_ID,
  MODAL_BODY_ID,
  MODAL_HEADER_ID,
  CONTEXT_MENU_ID,
  CONTEXT_HEADER_ID,
  FILTER_INPUT_ID,
  STYLETYPE_CHECKBOX,
  BACK_BUTTON,
  STYLETYPE_RADIO_TABLE,
  CONTENT_VIEW,
  STYLETYPE_CHECKBOX_CONTAINER,
  CONTEXT_MENU_ID_LIST,
  CONTEXT_HEADER_CONTAINER,
  DROPDOWN_ID,
  DROPDOWN_LIST_ID,
  CONTENT_LIST_TABLE_HEADER,
  CONTENT_LIST_TABLE,
  STYLETYPE_TOGGLE_SWITCH,
  STYLETYPE_TOGGLE_SWITCH_CONTAINER,
  STYLETYPE_FORM_FIELD_FLEX,
  PAGINATION_STYLING,
  DATA_TABLE_CONTAINER_STYLING,
  DATA_TABLE_PAGINATION_STYLING,
  DATA_TABLE_FILTER_INPUT_STYLING,
  DATA_TABLE_TITLE_CONTAINER_STYLING,
  DATA_TABLE_CHECKBOX_STYLING,
  ACCORDION_HEADER_STYLING,
  ACCORDION_ROW_STYLING,
  ACCORDION_CONTENT_STYLING,
  AUTH_VIEW,
  LOGIN_VIEW,
  STYLETYPE_STATUS,
  STYLE_TYPE_CUSTOM_MAP,
  STYLE_TYPE_CUSTOM_MAP_LAYER,
  STYLE_TYPE_MODAL_INPUT_CONTAINER
} from "@base/constants";
import { IconBackground, IconSymbol } from "../Icon/style";
import { ButtonElement, ButtonLabel } from "../Button/style";
import { FormLabelElement } from "../FormLabel/style";
import { ParagraphElement } from "../../typography/Paragraph/style";

// @ts-ignore

export const ContainerElement = styled.div`
  height: 100%;
  max-height: 100%;
  background: ${(props) => props.theme.agLighterShadeGray};
  display: flex;
  flex-direction: ${(props) =>
    props.direction === "column" ? "column" : "row"};
  justify-content: flex-start;

  & + & {
    margin-top: 2rem;
  }

  ${(props) =>
    props.styleType === PAGINATION_STYLING &&
    css`
      margin-left: 1.25rem;
      align-items: center;
    `}

  ${(props) =>
    props.styleType === APPLICATION_HEADER_ID &&
    css`
      flex-direction: row;
      background: ${props.theme.agWhite};
      justify-content: space-between;
      align-items: center;
    `}

  ${(props) =>
    props.styleType === CONTENT_VIEW &&
    css`
      height: 100%;
      background-color: ${(props) => props.theme.agLighterShadeGray};
    `}
    
  ${(props) =>
    props.styleType === LISTCONTENT_ITEM_LIST_ID &&
    css`
      background: none;
      flex-direction: row;
      justify-content: flex-end;
      flex-grow: 100;
    `}

  ${(props) =>
    props.styleType === LOADER_ID &&
    css`
      padding: ${(props) => {
        if (props.size === LOADER_SIZE_REGULAR) return "1.5rem";
      }};
      & ${IconBackground} {
        box-shadow: 0rem 0.125rem 0.313rem -0.063rem rgba(0, 0, 0, 0.46);
      }
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      flex-direction: ${(props) =>
        props.size === LOADER_SIZE_REGULAR ? "column" : "row"};
    `}

  ${(props) =>
    props.styleType === MODAL_ID &&
    css`
      background: ${(props) => props.theme.agWhite};
      min-width: 25rem;
      max-width: 62.5rem;
      width: 40%;
      min-height: 18.75rem;
      max-height: 62.5rem;
      height: auto;
      border-radius: 0.063rem;
      z-index: 10001;
      position: relative;
      display: flex;
      justify-content: flex-start;
    `}

  ${(props) =>
    props.styleType === MODAL_BOTTOM_BUTTONS_ID &&
    css`
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0 2.5rem 2.5rem 2.5rem;
      flex-grow: 1;
      margin-bottom: 0;
      background: ${(props) => props.theme.agWhite};

      ${ButtonElement} {
        margin: 0 0.25rem;
      }
    `}

  ${(props) =>
    props.styleType === MODAL_BODY_ID &&
    css`
      position: relative;
      flex-grow: 100;
      padding: 0 2.5rem;
      background: ${(props) => props.theme.agWhite};
    `}

  ${(props) =>
    props.styleType === MODAL_HEADER_ID &&
    css`
      height: auto;
      position: relative;
      padding: 0rem 2.5rem 0 2.5rem;
      margin-top: 2.5rem;
      flex-grow: 1;
      background: ${(props) => props.theme.agWhite};
      & + & {
        margin: 0 0 3rem 0;
      }
    `}

  ${(props) =>
    props.styleType === CONTEXT_MENU_ID &&
    css`
      height: 0;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
    `}

  ${(props) =>
    props.styleType === CONTEXT_MENU_ID_LIST &&
    css`
      height: 0;
      min-width: 12.5rem;
      position: absolute;
      margin-top: 2.4rem;
    `}
    
  ${(props) =>
    props.styleType === CONTEXT_HEADER_CONTAINER &&
    css`
      margin-bottom: 1.5rem;
      border-bottom: 8px solid ${(props) => props.theme.agWhite};
    `}



  ${(props) =>
    props.styleType === FILTER_INPUT_ID &&
    css`
      position: relative;
      min-width: 18.75rem;
      width: auto;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1.7rem;
      background-color: ${(props) => props.theme.agLighterShadeGray};
    `}

  ${(props) =>
    props.styleType === "mainTable" &&
    css`
      display: flex;
      height: 2.813rem;
      justify-content: space-between;
      padding: 0.4rem;

      background: ${props.theme.agSmokeWhite};
      &:nth-child(even) {
        background: ${props.theme.agWhite};
      }

      & + & {
        margin-top: 0rem;
      }

      & + &:last-child {
        margin-bottom: 0rem;
      }
    `}

  ${(props) =>
    props.styleType === "radio-heading" &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 2.813rem;
      background-color: ${(props) => props.theme.agMischkaGray};

      & + & {
        margin-top: 0rem;
      }
    `}

  ${(props) =>
    props.styleType === "radio-heading-label" &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: ${(props) => props.theme.agMischkaGray};

      & + & {
        margin-top: 0rem;
      }
    `}

  ${(props) =>
    props.styleType === "radio-options-labels" &&
    css`
      display: flex;
      width: 50%;
      justify-content: space-around;
      background-color: ${(props) => props.theme.agMischkaGray};

      & + & {
        margin-top: 0rem;
      }
      & + &:last-child {
        margin-bottom: 0rem;
      }
    `}

  ${(props) =>
    props.styleType === STYLETYPE_CHECKBOX &&
    css`
      height: 2.813rem;
      justify-content: space-between;
      background: ${props.theme.agSmokeWhite};
      &:nth-child(even) {
        background: ${props.theme.agWhite};
      }
      & + & {
        margin-top: 0rem;
      }
    `}

  ${(props) =>
    props.styleType === DATA_TABLE_CHECKBOX_STYLING &&
    css`
      height: auto;
      justify-content: space-between;
      background: none;

      & + & {
        margin-top: 0rem;
      }
    `}
          
  ${(props) =>
    props.styleType === BACK_BUTTON &&
    css`
      justify-content: space-between;
    `}

  ${(props) =>
    props.styleType === STYLETYPE_RADIO_TABLE &&
    css`
      margin-bottom: 2rem;
    `}

  ${(props) =>
    props.styleType === STYLETYPE_CHECKBOX_CONTAINER &&
    css`
      border: 2px solid white;
      border-radius: 0.25rem;
      padding: 0.563rem;
    `}
      
  ${(props) =>
    props.styleType === DROPDOWN_ID &&
    css`
      background: ${(props) => props.theme.agWhite};
      border: 1px solid ${(props) => props.theme.agMischkaGray};
    `}

${(props) =>
    props.styleType === DROPDOWN_LIST_ID &&
    css`
      background: ${(props) => props.theme.agWhite};
    `}

${(props) =>
    props.styleType === CONTENT_LIST_TABLE &&
    css`
      padding: 0.5rem 0rem;
    `}
${(props) =>
    props.styleType === CONTENT_LIST_TABLE_HEADER &&
    css`
      justify-content: space-between;
      align-items: baseline;
      padding: 0.8rem 0.4rem;

      ${FormLabelElement} {
        width: 15%;
        min-width: 2em;
        border-right: 2px;
        padding: 0.5rem;
      }

      & :nth-child(3) {
        padding-right: 5rem;
        width: 25%;
      }
    `}

${(props) =>
    props.styleType === STYLETYPE_TOGGLE_SWITCH &&
    css`
      height: 3.125rem;
      width: 15.625rem;
      align-items: center;
      flex-direction: row;

      > p {
        margin-left: 0.5rem;
      }
    `}

  ${(props) =>
    props.styleType === STYLETYPE_TOGGLE_SWITCH_CONTAINER &&
    css`
      align-items: center;
      margin-bottom: 0.5rem;
    `}
  ${(props) =>
    props.styleType === STYLETYPE_FORM_FIELD_FLEX &&
    css`
      display: flex;
      justify-content: space-between;
      > div {
        width: 48%;
        margin: 0 !important;
      }
    `}
    

  ${(props) =>
    props.styleType === DATA_TABLE_TITLE_CONTAINER_STYLING &&
    css`
      margin-bottom: 0rem;
      & + & {
        margin-top: 0rem;
      }

      & + &:last-child {
        margin-bottom: 0rem;
      }
      ${ParagraphElement} {
        width: auto;
        margin: 0.2rem 0.5rem;
      }

      ${(props) =>
        props.innerStyleType === "inner-accordion" &&
        css`
          margin: 0;
        `}
    `}
      


  ${(props) =>
    props.styleType === DATA_TABLE_CONTAINER_STYLING &&
    css`
      padding: 0.3rem;
      border: 1px solid #ffffff;
      border-radius: 0.188rem;
      overflow-x: auto;
      max-height: 100%;

      ${(props) =>
        props.innerStyleType === "inner-accordion" &&
        css`
          max-height: 20.625rem;
        `}
    `}

  ${(props) =>
    props.styleType === DATA_TABLE_FILTER_INPUT_STYLING &&
    css`
      margin-left: auto;
    `}

  ${(props) =>
    props.styleType === DATA_TABLE_PAGINATION_STYLING &&
    css`
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
    `}   
      
    
  ${(props) =>
    props.styleType === ACCORDION_HEADER_STYLING &&
    css`
      justify-content: center;
    `}   

    ${(props) =>
    props.styleType === ACCORDION_ROW_STYLING &&
    css`
      justify-content: center;
      background: none;
    `}   
      
    ${(props) =>
    props.styleType === ACCORDION_CONTENT_STYLING &&
    css`
        display: ${props.expanded === true ? "block" : "none"}
        width: 100%;
        background:none;
        width: auto;
        padding: 0.4rem 3.1rem; 
      `}
    ${(props) =>
    props.styleType === AUTH_VIEW &&
    css`
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => props.theme.agBrightGreen};
    `}

    ${(props) =>
    props.styleType === LOGIN_VIEW &&
    css`
      z-index: 1;
      width: 100%;
      height: 100vh;
      position: fixed;
    `}
  

  ${(props) =>
    props.styleType === STYLETYPE_STATUS &&
    css`
      gap: 0.5em;
      margin: ${props.dataTableStatus === true ? "" : "0 0 -2em 0"};
      align-items: center;
      background: none;
      ${ParagraphElement} {
        line-height: 0.5em;
        font-size: calc(1rem + 0.3vw);
      }
    `}

    
  ${(props) =>
    props.styleType === STYLE_TYPE_CUSTOM_MAP &&
    css`
      background: none;
      align-items: center;
      justify-content: space-between;

      & ${ButtonElement} {
        background: none;
      }
      & ${ButtonLabel} {
        color: ${(props) => props.theme.agBrightGreen};
      }
      & ${IconBackground} {
        background: none;
      }
    `}

       
  ${(props) =>
    props.styleType === STYLE_TYPE_CUSTOM_MAP_LAYER &&
    css`
      background: none;
    `}

    ${(props) =>
    props.styleType === STYLE_TYPE_MODAL_INPUT_CONTAINER &&
    css`
      background: none;
      ${FormLabelElement} {
        margin-top: 1em;
      }
    `}
`;
ContainerElement.displayName = "ContainerElement";
