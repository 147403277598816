import { createActions } from "redux-actions";
import {
  AUTHENTICATE_USER,
  SUCCESSFUL_AUTHENTICATION,
  ERROR_AUTHENTICATION,
  INVALIDATE_AUTHENTICATION,
  CLEAR_ERRORS,
  CHECK_AUTHZERO_AUTHENTICATION,
  LOAD_USER_LOGIN,
  LOADED_USER_LOGIN,
  SET_AUTHZERO_CLIENT,
  CLEAR_AUTHENTICATION,
  RESET_LOGGING_OUT
} from "@base/constants";

export const {
  authenticateUser,
  successfulAuthentication,
  errorAuthentication,
  invalidateAuthentication,
  clearErrors,
  checkAuthzeroAuthentication,
  loadUserLogin,
  loadedUserLogin,
  setAuthzeroClient,
  clearAuthentication,
  resetLoggingOut
} = createActions({
  [AUTHENTICATE_USER]: () => { },
  [SUCCESSFUL_AUTHENTICATION]: (user) => user,
  [ERROR_AUTHENTICATION]: (error) => error,
  [INVALIDATE_AUTHENTICATION]: () => { },
  [CLEAR_ERRORS]: () => { },
  [CHECK_AUTHZERO_AUTHENTICATION]: () => { },
  [LOAD_USER_LOGIN]: () => { },
  [LOADED_USER_LOGIN]: (user) => user,
  [SET_AUTHZERO_CLIENT]: (client) => client,
  [CLEAR_AUTHENTICATION]: () => { },
  [RESET_LOGGING_OUT]: () => { }
});
