import styled from "styled-components";
import { ParagraphElement } from "../Paragraph/style";

export const Heading2Element = styled.h2`
  font-family: ${(props) => props.theme.baseFont};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  line-height: ${(props) => props.theme.lineHeightLarger};
  font-size: ${(props) => props.theme.fontSizeTwo};
  color: ${(props) => props.theme.agCommonBlack};
  margin-bottom: 0.8em;

  ${ParagraphElement} + & {
    margin-top: 2.4rem;
  }
`;
Heading2Element.displayName = "Heading2Element";
