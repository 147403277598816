import { createActions } from "redux-actions";
import {
  LOADED_MAP,
  LOAD_WEB_MAP,
  LOADED_WEB_MAP,
  ERROR_LOADING_WEB_MAP,
  LOAD_LABELS,
  LOADED_LABELS,
  SET_LOADING_TEXT,
  SET_APP_LOADING,
  SET_PAGE_TITLE,
  SHOW_MODAL,
  HIDE_MODAL,
  SET_MODAL_CONTENT,
  SET_ERROR,
  SET_ABORT_CONTROLLER,
  LOAD_DATASET_TEMPLATES,
  LOADED_DATASET_TEMPLATES,
  LOAD_LAYER_RENDERER_TEMPLATES,
  LOADED_LAYER_RENDERER_TEMPLATES,
  LOAD_LABEL_RENDERER_TEMPLATES,
  LOADED_LABEL_RENDERER_TEMPLATES,
  LOAD_WORKFLOW_TEMPLATES,
  LOADED_WORKFLOW_TEMPLATES,
  LOAD_LANDKIND_USERS,
  LOADED_LANDKIND_USERS,
  LOAD_LANDKIND_USER_ORGANISATIONS,
  LOADED_LANDKIND_USER_ORGANISATIONS,
  LOAD_LANDKIND_USER_INVITES,
  LOADED_LANDKIND_USER_INVITES,
  REMOVE_LANDKIND_USER,
  REMOVED_LANDKIND_USER,
  UPDATE_LANDKIND_USER,
  UPDATED_LANDKIND_USER
} from "@base/constants";

export const {
  loadedMap,
  loadWebMap,
  loadedWebMap,
  errorLoadingWebMap,
  loadLabels,
  loadedLabels,
  setLoadingText,
  loadLanguage,
  setAppLoading,
  setPageTitle,
  showModal,
  hideModal,
  setModalContent,
  setError,
  setAbortController,
  loadDatasetTemplates,
  loadedDatasetTemplates,
  loadLayerRendererTemplates,
  loadedLayerRendererTemplates,
  loadLabelRendererTemplates,
  loadedLabelRendererTemplates,
  loadWorkflowTemplates,
  loadedWorkflowTemplates,
  loadLandkindUsers,
  loadedLandkindUsers,
  loadLandkindUserOrganisations,
  loadedLandkindUserOrganisations,
  loadLandkindUserInvites,
  loadedLandkindUserInvites,
  removeLandkindUser,
  removedLandkindUser,
  updateLandkindUser,
  updatedLandkindUser
} = createActions({
  [LOADED_MAP]: (mapView) => mapView,
  [LOAD_WEB_MAP]: (webMapId) => webMapId,
  [LOADED_WEB_MAP]: (webMap) => webMap,
  [ERROR_LOADING_WEB_MAP]: (error) => error,
  [LOAD_LABELS]: () => {},
  [LOADED_LABELS]: (labels) => labels,
  [SET_LOADING_TEXT]: (text) => text,
  [SET_APP_LOADING]: (loading) => loading,
  [SET_PAGE_TITLE]: (title) => title,
  [SHOW_MODAL]: () => {},
  [HIDE_MODAL]: () => {},
  [SET_MODAL_CONTENT]: (content) => content,
  [SET_ERROR]: (error) => error,
  [SET_ABORT_CONTROLLER]: (controller) => controller,
  [LOAD_DATASET_TEMPLATES]: () => {},
  [LOADED_DATASET_TEMPLATES]: (templates) => templates,
  [LOAD_LAYER_RENDERER_TEMPLATES]: () => {},
  [LOADED_LAYER_RENDERER_TEMPLATES]: (templates) => templates,
  [LOAD_LABEL_RENDERER_TEMPLATES]: () => {},
  [LOADED_LABEL_RENDERER_TEMPLATES]: (templates) => templates,
  [LOAD_WORKFLOW_TEMPLATES]: () => {},
  [LOADED_WORKFLOW_TEMPLATES]: (templates) => templates,
  [LOAD_LANDKIND_USERS]: () => {},
  [LOADED_LANDKIND_USERS]: (users) => users,
  [LOAD_LANDKIND_USER_ORGANISATIONS]: (userId) => userId,
  [LOADED_LANDKIND_USER_ORGANISATIONS]: (organisations) => organisations,
  [LOAD_LANDKIND_USER_INVITES]: (userId) => userId,
  [LOADED_LANDKIND_USER_INVITES]: (invites) => invites,
  [REMOVE_LANDKIND_USER]: (userId) => userId,
  [REMOVED_LANDKIND_USER]: () => {},
  [UPDATE_LANDKIND_USER]: (userId, data) => ({ userId, data }),
  [UPDATED_LANDKIND_USER]: () => {}
});
