export const getEsriModules = (state) => state.app.esriModules;
export const getMapView = (state) => state.app.mapView;
export const getWebMapError = (state) => state.app.webMapError;
export const getWebMap = (state) => state.app.webMap;
export const getMapContainer = (state) => state.app.mapContainer;
export const getAbortSignal = (state) =>
  state.app.abortController ? state.app.abortController.signal : null;
export const getAbortController = (state) =>
  state.app.abortController ? state.app.abortController : null;
export const getLoadingText = (state) => state.app.loadingText;
export const getAppLoading = (state) => state.app.appLoading;
export const getPageTitle = (state) => state.app.pageTitle;
export const getModalVisibility = (state) => state.app.modalVisibility;
export const getLabels = (state, labelObject) => {
  const labels = state.app.labels;
  if (!labelObject || !labelObject.stringConstant) return labels;
  const { stringConstant, data } = labelObject;
  const templateString = labels[stringConstant];
  const computedLabel = function (templateString, data) {
    return new Function("return `" + templateString + "`;").call(data);
  };
  if (data) {
    return computedLabel(templateString, data);
  }
  return templateString;
};
export const getModalHeader = (state) => state.app.modalContent.header;
export const getModalBody = (state) => state.app.modalContent.body;
export const getModalButtons = (state) => state.app.modalContent.buttons;
export const getModalshowClose = (state) =>
  state.app.modalContent.showCloseButton;
export const getError = (state) => !!state.app.error.error;
export const getErrorMessage = (state) => state.app.error.message;
export const getDatasetTemplates = (state) => {
  const templates = state.app.datasetTemplates.available;
  return templates ? templates : [];
};
export const getDatasetTemplatesStatus = (state) =>
  state.app.datasetTemplates.status;

export const getLayerRendererTemplates = (state) => {
  const templates = state.app.layerRendererTemplates.available;
  return templates ? templates : [];
};
export const getLayerRendererStatus = (state) =>
  state.app.layerRendererTemplates.status;

export const getLabelRendererTemplates = (state) => {
  const templates = state.app.labelRendererTemplates.available;
  return templates ? templates : [];
};
export const getLabelRendererStatus = (state) =>
  state.app.labelRendererTemplates.status;

export const getLandkindUsersLoading = (state) => {
  return state.app.landkindUsers.isLoading;
};

export const getLandkindUsers = (state) => {
  return state.app.landkindUsers.available
    ? state.app.landkindUsers.available.items
    : false;
};

export const getLandkindUserOrganisationsLoading = (state) => {
  return state.app.landkindUserOrganisations.isLoading;
};

export const getLandkindUserOrganisations = (state) => {
  return state.app.landkindUserOrganisations.available
    ? state.app.landkindUserOrganisations.available.items
    : false;
};

export const getLandkindUserInvitesLoading = (state) => {
  return state.app.landkindUserInvites.isLoading;
};

export const getLandkindUserInvites = (state) => {
  return state.app.landkindUserInvites.available
    ? state.app.landkindUserInvites.available.items
    : false;
};

export const getLandkindUserStatus = (state) => {
  return state.app.landkindUser.status;
};

export const getWorkflowTemplates = (state) => {
  const templates = state.app.workflowTemplates.available;
  return templates ? templates : [];
};

export const getWorkflowTemplatesStatus = (state) =>
  state.app.workflowTemplates.status