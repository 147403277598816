import React, { Component } from "react";

import { ListContent, ContextHeader, Loader } from "@UIKit";
import { Heading3, P } from "@typography";
import {
  Image,
  Section,
  Container,
  Wrapper,
  FormLabel,
  FormInput
} from "@core";
import { navigate } from "@reach/router";

import {
  PROPERTY_GROUPS_URL,
  PROPERTIES_URL,
  ROLES_URL,
  USERS_URL,
  WORKFLOWS_URL,
  RENDERERS_URL,
  HOME_URL,
  DATASETS_URL,
  CONTENT_FADE_TIMEOUT,
  UPDATE_ORG_URL,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  LABEL_2D,
  LABEL_3D,
  STATUS_REMOVING,
  STATUS_READY,
  LINK_BUTTON,
  READ_PERMISSION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
  ORGANISATIONS_SECTION,
  PROPERTY_GROUPS_SECTION,
  USERS_SECTION,
  WORKFLOWS_SECTION,
  PROPERTIES_SECTION,
  ROLES_SECTION,
  RENDERERS_SECTION,
  DATASETS_SECTION,
  ASSETS_PROXY_SUBSTRING,
  SECTION_HEADER_STYLING,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL,
  STYLE_TYPE_MODAL_INPUT_CONTAINER,
  REACT_APP_ASSET_PROXY_URL_ENV
} from "@base/constants";
import { CSSTransition } from "react-transition-group";

class OrganisationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      deleteOrganisationConfirmation: ""
    };
  }

  componentDidMount() {
    this.loadOrganisationDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps) {
    const {
      orgId,
      pageTitle,
      setPageTitle,
      selectedOrganisation,
      organisationsStatus,
      selectedOrganisationLoading
    } = this.props;
    if (prevProps.orgId !== orgId) {
      this.loadOrganisationDetails();
    }
    if (selectedOrganisation && pageTitle !== selectedOrganisation.title) {
      setPageTitle(selectedOrganisation.title);
    }
    if (
      prevProps.organisationsStatus === STATUS_REMOVING &&
      organisationsStatus === STATUS_READY
    ) {
      this.showDeleteSuccess();
    }

    if (prevProps.selectedOrganisationLoading && !selectedOrganisationLoading) {
      this.setState({
        loading: false
      });
    }
  }

  loadOrganisationDetails = () => {
    const { orgId, loadOrganisation } = this.props;
    loadOrganisation(orgId, true);
  };
  getOrganisationTitle = () => {
    const { selectedOrganisation } = this.props;
    if (!selectedOrganisation) return false;
    const { title } = selectedOrganisation;
    return title ? title : false;
  };

  getOrganisationLogo = () => {
    const { selectedOrganisation } = this.props;
    if (
      !selectedOrganisation ||
      !selectedOrganisation.preferences ||
      !selectedOrganisation.preferences.logo
    )
      return false;

    const {
      preferences: { logo }
    } = selectedOrganisation;

    let logoFix = logo.split("https://" || "http://");
    logoFix[logoFix.length - 1] = logoFix[logoFix.length - 1].replace(
      "//",
      "/"
    );
    logoFix = logoFix.join("https://" || "http://");

    if (logoFix.includes(ASSETS_PROXY_SUBSTRING)) return logoFix;
    const altAssetsSubstring = REACT_APP_ASSET_PROXY_URL_ENV.replace(
      "landkind.com",
      "agbox.io"
    );

    return `${REACT_APP_ASSET_PROXY_URL_ENV}/${logo.replace(
      new RegExp(
        `(https://|http://|${REACT_APP_ASSET_PROXY_URL_ENV}/|${altAssetsSubstring}/)`
      ),
      ""
    )}`;
  };

  getOrgMapview = () => {
    const { getLabel, selectedOrganisation } = this.props;
    if (!selectedOrganisation) return false;
    const { preferences } = selectedOrganisation;
    if (!preferences) return false;
    const { enable3D } = preferences;
    const enable3DText = enable3D === false ? LABEL_2D : LABEL_3D;
    return enable3DText ? (
      <P>{`${getLabel("MAP_VIEW_LABEL")}: ${enable3DText}`}</P>
    ) : (
      false
    );
  };

  getOrgSpatialReference = () => {
    const { getLabel, selectedOrganisation } = this.props;
    if (!selectedOrganisation) return false;
    const { preferences } = selectedOrganisation;
    if (!preferences) return false;
    const { defaultSpatialReference } = preferences;
    return defaultSpatialReference ? (
      <P>
        {`${getLabel("SPATIAL_REFERENCE_LABEL")}: ${defaultSpatialReference}`}
      </P>
    ) : (
      false
    );
  };

  getOrgDescription = () => {
    const { getLabel, selectedOrganisation } = this.props;
    if (!selectedOrganisation) return false;
    const { description } = selectedOrganisation;
    return description ? (
      <P styleType={"descriptionText"}>
        {`${getLabel("FORM_LABEL_DESCRIPTION")}: ${description}`}
      </P>
    ) : (
      false
    );
  };

  getCount = (dataType) => {
    const { selectedOrganisation } = this.props;
    if (
      !dataType ||
      !selectedOrganisation ||
      !selectedOrganisation.counts ||
      !selectedOrganisation.counts[dataType] ||
      !selectedOrganisation.counts[dataType].count
    )
      return 0;
    return selectedOrganisation.counts[dataType].count;
  };

  getOrganisationListItem = ({ dataType, labelString, url }) => {
    if (!dataType) return false;

    const { getLabel, getHasPermission, orgId } = this.props;
    const hasPermission = getHasPermission(orgId, dataType, READ_PERMISSION);
    const heading = getLabel(labelString, {
      count: this.getCount(dataType)
    });

    return {
      buttonType: LINK_BUTTON,
      headerLabel: heading,
      url: hasPermission ? url : null
    };
  };

  getOrganisationsDetails = () => {
    const { selectedOrganisation } = this.props;
    if (this.isLoadingOrganisationDetails() || !selectedOrganisation)
      return null;

    const sections = [
      {
        dataType: PROPERTY_GROUPS_SECTION,
        labelString: "ORGANISATION_PROPERTY_GROUPS_LIST_COUNT",
        url: `${PROPERTY_GROUPS_URL}`
      },
      {
        dataType: USERS_SECTION,
        labelString: "ORGANISATION_USERS_LIST_COUNT",
        url: `${USERS_URL}`
      },
      {
        dataType: WORKFLOWS_SECTION,
        labelString: "ORGANISATION_WORKFLOWS_LIST_COUNT",
        url: `${WORKFLOWS_URL}`
      },
      {
        dataType: PROPERTIES_SECTION,
        labelString: "ORGANISATION_PROPERTIES_LIST_COUNT",
        url: `${PROPERTIES_URL}`
      },
      {
        dataType: ROLES_SECTION,
        labelString: "ORGANISATION_ROLES_LIST_COUNT",
        url: `${ROLES_URL}`
      },
      {
        dataType: RENDERERS_SECTION,
        labelString: "ORGANISATION_RENDERERS_LIST_COUNT",
        url: `${RENDERERS_URL}`
      },
      {
        dataType: DATASETS_SECTION,
        labelString: "ORGANISATION_DATASETS_LIST_COUNT",
        url: `${DATASETS_URL}`
      }
    ];

    return sections.reduce((result, section) => {
      const listItem = this.getOrganisationListItem(section);
      return listItem ? [...result, listItem] : result;
    }, []);
  };

  isLoadingOrganisationDetails = () => {
    const { loading } = this.state;
    return loading;
  };

  updateOrganisationAction = () => {
    const { hideModal, orgId } = this.props;

    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${UPDATE_ORG_URL}`);
  };

  deleteAction = async () => {
    const { removeOrganisation, orgId } = this.props;
    await removeOrganisation(orgId);
    this.showLoading();
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("DELETE_ACTION_LABEL"),
        item: getLabel("ORGANISATION_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
  };

  showDeleteSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_DELETED_LABEL", {
        item: getLabel("ORGANISATION_TEXT")
      }),
      body: getLabel("ITEM_DELETED_SUCCESS", {
        item: getLabel("ORGANISATION_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("ORGANISATION_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  returnToOverviewAction = () => {
    const { hideModal } = this.props;

    hideModal();
    navigate(HOME_URL);
  };

  setDeleteOrgConfirmation = (deleteOrganisationConfirmation) => {
    this.setState(
      {
        deleteOrganisationConfirmation
      },
      () =>
        this.setDeleteConfirmationContent(
          deleteOrganisationConfirmation === this.getOrganisationTitle()
        )
    );
  };

  cancelDeleteOrganisation = () => {
    const { hideModal } = this.props;
    this.setState({
      deleteOrganisationConfirmation: ""
    });
    hideModal();
  };

  setDeleteConfirmationContent = (buttonEnabled) => {
    const { setModalContent, getLabel } = this.props;
    const { deleteOrganisationConfirmation } = this.state;
    setModalContent({
      header: getLabel("CONFIRM_DELETE_HEADER", {
        item: getLabel("ORGANISATION_TEXT")
      }),
      body: (
        <Container styleType={STYLE_TYPE_MODAL_INPUT_CONTAINER}>
          <P>
            {getLabel("CONFIRM_DELETE_BODY", {
              item: getLabel("ORGANISATION_TEXT")
            })}
          </P>
          <FormLabel htmlFor={"delete-org-confirm"}>
            {getLabel("DELETE_ORG_CONFIRMATION_LABEL")}
          </FormLabel>
          <FormInput
            value={deleteOrganisationConfirmation}
            type={"text"}
            id={"delete-org-confirm"}
            onChange={this.setDeleteOrgConfirmation}
          />
        </Container>
      ),
      buttons: [
        {
          buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
            item: getLabel("ORGANISATION_TEXT")
          }),
          onClick: this.deleteAction,
          isDisabled: !buttonEnabled
        },
        {
          buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
          onClick: this.cancelDeleteOrganisation
        }
      ]
    });
  };

  showDeleteConfirmation = () => {
    const { showModal } = this.props;

    this.setDeleteConfirmationContent(false);
    showModal();
  };

  getContextMenu = () => {
    const { getLabel, getHasPermission, orgId } = this.props;
    const childLinks = [];
    if (getHasPermission(orgId, ORGANISATIONS_SECTION, UPDATE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.updateOrganisationAction,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("ORGANISATION_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      });
    }
    if (getHasPermission(orgId, ORGANISATIONS_SECTION, DELETE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.showDeleteConfirmation,
        buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
          item: getLabel("ORGANISATION_TEXT")
        })
      });
    }
    return {
      childLinks,
      isDisabled: false,
      visible: childLinks.length ? true : false
    };
  };

  render() {
    const { getLabel } = this.props;
    const { DEFAULT_ORGANISATION_LOGO_ALT_TEXT, OVERVIEW_HEADING_TEXT } =
      getLabel();
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoadingOrganisationDetails() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <CSSTransition
              in={!this.isLoadingOrganisationDetails()}
              timeout={CONTENT_FADE_TIMEOUT}
              classNames="css-transition"
              unmountOnExit
            >
              <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
                {this.getOrganisationLogo() && (
                  <Image
                    height={"50px"}
                    source={this.getOrganisationLogo()}
                    altText={
                      this.getOrganisationTitle()
                        ? this.getOrganisationTitle()
                        : DEFAULT_ORGANISATION_LOGO_ALT_TEXT
                    }
                    data-name={"organisationLogo"}
                    styleType={"org-logo-image"}
                  />
                )}
                {this.getOrganisationTitle() ? (
                  <ContextHeader
                    headerTitle={this.getOrganisationTitle()}
                    contextMenu={this.getContextMenu()}
                  />
                ) : null}
              </Container>
            </CSSTransition>
            <Section>
              <Heading3 styleType={SECTION_HEADER_STYLING}>
                {getLabel("DETAILS_LABEL", {
                  item: getLabel("ORGANISATION_TEXT")
                })}
              </Heading3>
              {this.getOrgMapview()}
              {this.getOrgSpatialReference()}
              {this.getOrgDescription()}
              <Heading3 styleType={SECTION_HEADER_STYLING}>
                {OVERVIEW_HEADING_TEXT}
              </Heading3>
              <ListContent rows={this.getOrganisationsDetails()} />
            </Section>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default OrganisationDetail;
