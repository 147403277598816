import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { defaultTheme } from "@UIKit";
import { Icon } from "@core";
import { DropzoneWrapper, DropzoneContainer } from "../style";
import { STYLETYPE_ERROR } from "@base/constants";

const FileInput = (props) => (
  <Dropzone
    onDrop={props.handleSelectFile}
    multiple={false}
    accept={props.accept}
    onDropRejected={props.onDropRejected}
    onDropAccepted={props.onDropAccepted}
  >
    {({ getRootProps, getInputProps }) => (
      <DropzoneWrapper
        data-name={"DropzoneWrapper"}
        styleType={props.isInvalid ? STYLETYPE_ERROR : ""}
      >
        <DropzoneContainer data-name={"DropzoneContainer"} {...getRootProps()}>
          <input {...getInputProps()} id={"file-input"} />
          <Icon
            type={"upload"}
            iconColor={defaultTheme.agDarkestGray}
            bgShape="round"
            iconWidth="30px"
            iconHeight="30px"
          />

          <p>{props.dropLabel}</p>
          <span>
            <em>
              {props.fileAcceptLabel} {props.accept}
            </em>
          </span>
        </DropzoneContainer>
      </DropzoneWrapper>
    )}
  </Dropzone>
);
FileInput.propTypes = {
  /** Label displayed on dropzone */
  dropLabel: PropTypes.string,
  /** Called on drop/select of a file */
  handleSelectFile: PropTypes.func,
  /** The file types accepted (in string format) */
  accept: PropTypes.string,
  /** Called when a file drop is rejected (e.g. if file type is not accepted)*/
  onDropRejected: PropTypes.func,
  /** Called when a file drop is accepted */
  onDropAccepted: PropTypes.func,
  /** Displayed on the dropzone to describe accepted file types (is automatically followed by the accept prop) */
  fileAcceptLabel: PropTypes.string,
  /** If the file input is invalid */
  isInvalid: PropTypes.bool
};
export default FileInput;
