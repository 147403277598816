import React, { Component } from "react";
import {
  Container,
  Wrapper,
  Section,
  TextArea,
  FormLabel,
  FormInput,
  Fieldset
} from "@core";
import { P } from "@typography";
import {
  STYLETYPE_ERROR,
  WORKFLOWS_URL,
  STATUS_CREATING,
  STATUS_READY,
  CONTENT_FADE_TIMEOUT,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL,
  STYLETYPE_FORM_FIELD
} from "@base/constants";
import { Loader, ContextHeader, BottomButtonBar, RadioGroup } from "@UIKit";
import { navigate } from "@reach/router";
import { CSSTransition } from "react-transition-group";

class AddWorkflow extends Component {
  constructor(props) {
    super(props);
    const localRawModeValue = localStorage.getItem("workflowRawMode");
    const rawModeValue =
      localRawModeValue !== null ? localRawModeValue == "true" : false;

    this.state = {
      rawMode: rawModeValue,
      textInputValue: "",
      workflowTitle: "",
      workflowTemplateOption: ""
    };
  }

  componentDidMount() {
    this.loadDetails();
  }

  loadDetails = () => {
    const { loadWorkflowTemplates } = this.props;
    loadWorkflowTemplates();
  };

  isDetailsLoading = () => {
    const { workflowTemplatesLoading } = this.props;
    return workflowTemplatesLoading;
  };

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps, prevState) {
    const { pageTitle, setPageTitle, getLabel, selectedWorkflowStatus } =
      this.props;
    if (
      pageTitle !==
      getLabel("ADD_NEW_LABEL", { item: getLabel("WORKFLOW_TEXT") })
    ) {
      setPageTitle(
        getLabel("ADD_NEW_LABEL", { item: getLabel("WORKFLOW_TEXT") })
      );
    }
    if (
      prevProps.selectedWorkflowStatus === STATUS_CREATING &&
      selectedWorkflowStatus === STATUS_READY
    ) {
      this.showSuccess();
    }
    const workflowTemplates = this.getWorkflowTemplates();
    if (
      prevProps.workflowTemplates !== workflowTemplates &&
      workflowTemplates.length > 0 &&
      !this.state.workflowTemplateOption
    ) {
      this.setValue(workflowTemplates[0].templateId, "workflowTemplateOption");
    }
  }

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("ADD_NEW_LABEL", { item: getLabel("WORKFLOW_TEXT") });
  };

  setTextInputValue = (value) => {
    this.setState({
      textInputValue: value
    });
  };

  getTextInputValue = () => {
    return this.state.textInputValue ? this.state.textInputValue : "";
  };

  valueIsEmpty = (field) => {
    return (
      !this.getValue(field) ||
      (this.getValue(field) && this.getValue(field).length === 0) ||
      this.getValue(field) === ""
    );
  };

  isInvalid = () => {
    return (
      this.valueIsEmpty("textInputValue") && this.valueIsEmpty("workflowTitle")
    );
  };

  getTemplateRequestData = () => {
    return JSON.stringify({
      title: this.getValue("workflowTitle"),
      template: {
        templateId: this.getValue("workflowTemplateOption")
      }
    });
  };

  callAction = async () => {
    try {
      const data = this.getValue("rawMode")
        ? this.getTextInputValue()
        : this.getTemplateRequestData();
      const { createWorkflow, showModal, orgId } = this.props;
      this.showLoading();
      showModal();

      await createWorkflow(orgId, data);
    } catch (e) {
      throw new Error(e);
    }
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("CREATING_LABEL"),
        item: getLabel("WORKFLOWS_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_CREATED_LABEL", {
        item: getLabel("WORKFLOWS_TEXT")
      }),
      body: getLabel("ITEM_CREATED_LABEL", {
        item: getLabel("WORKFLOWS_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("ADD_ANOTHER_LABEL", {
            item: getLabel("WORKFLOW_TEXT")
          }),
          onClick: this.addAnother
        },
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("WORKFLOWS_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  addAnother = () => {
    const { hideModal } = this.props;
    this.setTextInputValue("");
    hideModal();
  };

  returnToOverviewAction = () => {
    const { hideModal, orgId } = this.props;

    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${WORKFLOWS_URL}/`);
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: "primary",
        isDisabled: this.isInvalid(),
        onClick: this.callAction,
        buttonLabel: getLabel("ADD_NEW_LABEL", {
          item: getLabel("WORKFLOW_TEXT")
        })
      }
    ];
  };

  switchModes = () => {
    this.setState(
      {
        rawMode: !this.state.rawMode,
        textInputValue: "",
        workflowTitle: ""
      },
      () => {
        localStorage.setItem("workflowRawMode", this.state.rawMode);
      }
    );
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    const { rawMode } = this.state;
    return {
      childLinks: [
        {
          isDisabled: false,
          onClick: this.switchModes,
          buttonLabel: rawMode
            ? getLabel("FORM_MODE_LABEL")
            : getLabel("RAW_MODE_LABEL")
        }
      ],
      isDisabled: false,
      visible: true
    };
  };

  getWorkflowTemplates = () => {
    const { workflowTemplates } = this.props;
    return workflowTemplates || [];
  };

  createRadioButtons = (workflowTemplates) => {
    return workflowTemplates.map(({ templateId, title, description }) => ({
      label: `${title} - ${description}`,
      value: templateId,
      id: templateId
    }));
  };

  getTemplateOptionsRadioGroup = () => {
    const { getLabel } = this.props;
    const workflowTemplates = this.getWorkflowTemplates();

    return !workflowTemplates?.length ? null : (
      <RadioGroup
        legend={getLabel("WORKFLOW_TEMPLATE_LABEL")}
        value={this.getValue("workflowTemplateOption")}
        isDisabled={false}
        name={"workflowTemplateOptionForm"}
        radioButtons={this.createRadioButtons(workflowTemplates)}
        onClick={(event) => {
          this.setValue(event, "workflowTemplateOption");
        }}
      />
    );
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
          <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
            <ContextHeader
              headerTitle={this.createHeaderText()}
              contextMenu={this.getContextMenu()}
            />
          </Container>
          <Container>
            <CSSTransition
              in={this.getValue("rawMode")}
              timeout={CONTENT_FADE_TIMEOUT}
              classNames="css-transition"
              unmountOnExit
            >
              <Section>
                <FormLabel
                  htmlFor={"workflow-json-textarea"}
                  styleType={
                    this.valueIsEmpty("textInputValue") ? STYLETYPE_ERROR : ""
                  }
                >
                  {getLabel("ADD_JSON", {
                    item: getLabel("WORKFLOW_TEXT")
                  })}
                </FormLabel>
                <TextArea
                  id={"workflow-json-textarea"}
                  value={this.getTextInputValue()}
                  onChange={(value) => {
                    this.setTextInputValue(value);
                  }}
                  styleType={
                    this.valueIsEmpty("textInputValue") ? STYLETYPE_ERROR : ""
                  }
                  maxLength={30000}
                ></TextArea>
                {this.isInvalid() ? (
                  <P styleType={STYLETYPE_ERROR}>
                    {getLabel("FORM_FIELD_EMPTY_LABEL")}
                  </P>
                ) : null}
              </Section>
            </CSSTransition>
            <CSSTransition
              in={!this.getValue("rawMode")}
              timeout={CONTENT_FADE_TIMEOUT}
              classNames="css-transition"
              unmountOnExit
            >
              <Section>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <FormLabel
                    htmlFor="role-name"
                    styleType={this.isInvalid() ? STYLETYPE_ERROR : ""}
                  >
                    {getLabel("FORM_TITLE_LABEL")}
                  </FormLabel>
                  <FormInput
                    value={this.getValue("workflowTitle")}
                    onChange={(value) => {
                      this.setValue(value, "workflowTitle");
                    }}
                    styleType={this.isInvalid() ? STYLETYPE_ERROR : ""}
                    id="workflow-name"
                    required={true}
                    ariaDescribedBy={
                      this.isInvalid() ? "workflow-name-error" : undefined
                    }
                  />
                  {this.isInvalid() ? (
                    <P styleType={STYLETYPE_ERROR}>
                      {getLabel("FORM_FIELD_EMPTY_LABEL")}
                    </P>
                  ) : null}
                </Container>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  {this.isDetailsLoading() ? (
                    <Loader fullPage={false} />
                  ) : (
                    <Fieldset>{this.getTemplateOptionsRadioGroup()}</Fieldset>
                  )}
                </Container>
              </Section>
            </CSSTransition>
            <BottomButtonBar buttons={this.getButtons()} />
          </Container>
        </Wrapper>
      </Container>
    );
  }
}

export default AddWorkflow;
