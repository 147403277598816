import React, { Component } from "react";
import PropTypes from "prop-types";
import { RadioGroup } from "@UIKit";
import { Container, FormLabel } from "@core";
import { RadioGroupTableElement } from "./style";
/**
 * RadioGroupTable component creates new Table
 */
class RadioGroupTable extends Component {
  static propTypes = {
    /** tableItems returns array of items */
    tableItems: PropTypes.array,
    /** setValue function to call on onClick*/
    setValue: PropTypes.func
  };

  /**
   * function take event and item and call props.setValue
   * @public
   */
  setValue = (event, item) => {
    const { setValue } = this.props;
    setValue(event, item);
  };

  /**
   * function renders label and RadioGroup options
   * @public
   */
  renderRadioGroupTable = () => {
    const { tableItems } = this.props;
    if (!tableItems) return [];
    let listOfTableItems = tableItems.map((item) => {
      return (
        <Container
          styleType={"mainTable"}
          direction={"row"}
          key={`${item.name}-${item.title}`}
        >
          <FormLabel htmlFor={"radiogroup-table"} styleType={"labelText"}>
            {item.title}
          </FormLabel>
          <RadioGroup
            styleType={"radioGroupTable"}
            direction={"horizontal"}
            value={item.value}
            isDisabled={false}
            name={item.name}
            radioButtons={item.options}
            onClick={(event) => this.setValue(event, item)}
          ></RadioGroup>
        </Container>
      );
    });
    return listOfTableItems;
  };

  render() {
    return (
      <RadioGroupTableElement>
        {this.renderRadioGroupTable()}
      </RadioGroupTableElement>
    );
  }
}

export default RadioGroupTable;
