import { createLogic } from "redux-logic";
import {
  LOAD_LABELS,
  SET_PAGE_TITLE,
  LANDKIND_ADMIN_CONSOLE_TEXT,
  LOAD_DATASET_TEMPLATES,
  LOAD_LAYER_RENDERER_TEMPLATES,
  LOAD_LABEL_RENDERER_TEMPLATES,
  LABEL_RENDERER_TYPE_TEXT,
  LAYER_RENDERER_TYPE_TEXT,
  DATA_SET_TYPE_TEXT,
  ABORT_ERROR_NAME,
  LOAD_LANDKIND_USERS,
  LOAD_LANDKIND_USER_ORGANISATIONS,
  LOAD_LANDKIND_USER_INVITES,
  REMOVE_LANDKIND_USER,
  UPDATE_LANDKIND_USER,
  SHOW_MODAL,
  HIDE_MODAL,
  REACT_APP_ASSET_PROXY_URL_ENV,
  LOAD_WORKFLOW_TEMPLATES,
  WORKFLOW_TYPE_TEXT
} from "@base/constants";

const loadLabelsLogic = createLogic({
  type: LOAD_LABELS,
  async process({ action, globalActions }, dispatch, done) {
    const { loadedLabels } = globalActions;
    const languageUrl = action.payload.languageFile;
    const language = action.payload.language;
    try {
      const languageJson = await fetch(
        `${REACT_APP_ASSET_PROXY_URL_ENV}/${languageUrl
          .replace("http://", "")
          .replace("https://", "")}`
      );
      const result = await languageJson.json();
      if (result.error) throw result.error;
      dispatch(loadedLabels(language, result));
      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const loadDatasetTemplateLogic = createLogic({
  type: LOAD_DATASET_TEMPLATES,
  async process(
    { globalActions, agBoxApiRequests, selectors, getState },
    dispatch,
    done
  ) {
    try {
      const { loadedDatasetTemplates } = globalActions;
      const { getToken, getAbortSignal } = selectors;
      const token = getToken(getState());
      const signal = getAbortSignal(getState());
      const templates = await agBoxApiRequests.requestTemplatesByType(
        token,
        signal,
        DATA_SET_TYPE_TEXT
      );
      dispatch(loadedDatasetTemplates(templates));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadLayerRendererTemplateLogic = createLogic({
  type: LOAD_LAYER_RENDERER_TEMPLATES,
  async process(
    { globalActions, agBoxApiRequests, selectors, getState },
    dispatch,
    done
  ) {
    try {
      const { loadedLayerRendererTemplates } = globalActions;
      const { getToken, getAbortSignal } = selectors;
      const token = getToken(getState());
      const signal = getAbortSignal(getState());
      const templates = await agBoxApiRequests.requestTemplatesByType(
        token,
        signal,
        LAYER_RENDERER_TYPE_TEXT
      );
      dispatch(loadedLayerRendererTemplates(templates));
      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadLabelRendererTemplateLogic = createLogic({
  type: LOAD_LABEL_RENDERER_TEMPLATES,
  async process(
    { globalActions, agBoxApiRequests, selectors, getState },
    dispatch,
    done
  ) {
    try {
      const { loadedLabelRendererTemplates } = globalActions;
      const { getToken, getAbortSignal } = selectors;
      const token = getToken(getState());
      const signal = getAbortSignal(getState());
      const templates = await agBoxApiRequests.requestTemplatesByType(
        token,
        signal,
        LABEL_RENDERER_TYPE_TEXT
      );
      dispatch(loadedLabelRendererTemplates(templates));
      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const setPageTitleLogic = createLogic({
  type: SET_PAGE_TITLE,
  async process({ action }, dispatch, done) {
    try {
      document.title = action.payload
        ? action.payload
        : LANDKIND_ADMIN_CONSOLE_TEXT;
      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const loadLandkindUsersLogic = createLogic({
  type: LOAD_LANDKIND_USERS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedLandkindUsers } = globalActions;
      const { FETCHING_ORGANISATIONS_TEXT } = getLabels(getState());
      dispatch(setLoadingText(FETCHING_ORGANISATIONS_TEXT));

      const token = getToken(state);
      const { requestLandkindUsers } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const users = await requestLandkindUsers(token, signal);
      dispatch(loadedLandkindUsers(users));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadLandkindUserOrganisationsLogic = createLogic({
  type: LOAD_LANDKIND_USER_ORGANISATIONS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedLandkindUserOrganisations } = globalActions;
      const { FETCHING_ORGANISATIONS_TEXT } = getLabels(getState());
      dispatch(setLoadingText(FETCHING_ORGANISATIONS_TEXT));

      const userId = action.payload;
      const token = getToken(state);
      const { requestLandkindUserOrganisations } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const organisations = await requestLandkindUserOrganisations(
        userId,
        token,
        signal
      );
      dispatch(loadedLandkindUserOrganisations(organisations));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadLandkindUserInvitesLogic = createLogic({
  type: LOAD_LANDKIND_USER_INVITES,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedLandkindUserInvites } = globalActions;
      const { FETCHING_ORGANISATIONS_TEXT } = getLabels(getState());
      dispatch(setLoadingText(FETCHING_ORGANISATIONS_TEXT));

      const userId = action.payload;
      const token = getToken(state);
      const { requestLandkindUserInvites } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const invites = await requestLandkindUserInvites(userId, token, signal);
      dispatch(loadedLandkindUserInvites(invites));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const removeLandkindUserLogic = createLogic({
  type: REMOVE_LANDKIND_USER,
  async process(
    { globalActions, agBoxApiRemoves, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, removedLandkindUser } = globalActions;
      const { FETCHING_ORGANISATIONS_TEXT } = getLabels(getState());
      dispatch(setLoadingText(FETCHING_ORGANISATIONS_TEXT));

      const userId = action.payload;
      const token = getToken(state);
      const { removeLandkindUser } = agBoxApiRemoves;
      const signal = getAbortSignal(getState());
      await removeLandkindUser(userId, token, signal);
      dispatch(removedLandkindUser());

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const updateLandkindUserLogic = createLogic({
  type: UPDATE_LANDKIND_USER,
  async process(
    { globalActions, agBoxApiUpdates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getAbortSignal, getLabels, getToken } = selectors;
      const { setLoadingText, updatedLandkindUser } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));
      const token = getToken(state);
      const { userId, data } = action.payload;

      if (userId && data) {
        const { partiallyUpdateUser } = agBoxApiUpdates;
        const signal = getAbortSignal(getState());
        await partiallyUpdateUser(userId, data, token, signal);
        dispatch(updatedLandkindUser());
      }
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadWorkflowTemplateLogic = createLogic({
  type: LOAD_WORKFLOW_TEMPLATES,
  async process(
    { globalActions, agBoxApiRequests, selectors, getState },
    dispatch,
    done
  ) {
    try {
      const { loadedWorkflowTemplates } = globalActions;
      const { getToken, getAbortSignal } = selectors;
      const token = getToken(getState());
      const signal = getAbortSignal(getState());
      const templates = await agBoxApiRequests.requestTemplatesByType(
        token,
        signal,
        WORKFLOW_TYPE_TEXT
      );
      dispatch(loadedWorkflowTemplates(templates));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const showModalLogic = createLogic({
  type: SHOW_MODAL,
  process({ action }, dispatch, done) {
    const body = document.querySelector("body");
    body.classList.add("modal-open");
    done();
  }
});

const hideModalLogic = createLogic({
  type: HIDE_MODAL,
  process({ action }, dispatch, done) {
    const body = document.querySelector("body");
    body.classList.remove("modal-open");
    done();
  }
});

export default [
  loadLabelsLogic,
  setPageTitleLogic,
  loadDatasetTemplateLogic,
  loadLayerRendererTemplateLogic,
  loadLabelRendererTemplateLogic,
  loadLandkindUsersLogic,
  loadLandkindUserOrganisationsLogic,
  loadLandkindUserInvitesLogic,
  removeLandkindUserLogic,
  updateLandkindUserLogic,
  showModalLogic,
  hideModalLogic,
  loadWorkflowTemplateLogic
];
