import React, { Component } from "react";
import { Icon, Image } from "../";
import {
  BUTTON_TYPE_PRIMARY,
  ORGANISATION_URL,
  USERS_URL
} from "@base/constants";
import PropTypes from "prop-types";
import { ButtonElement, ButtonImageWrapper, ButtonLabel } from "./style";

/**
 * LinkButton component that creates a new button from style components and optionally includes an Icon
 */
class LinkButton extends Component {
  static propTypes = {
    /**Whether the button is disabled or not */
    isDisabled: PropTypes.bool,
    /**The function that is called when a button is clicked */
    onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /** url that will navigate user to */
    url: PropTypes.string,
    /**The Style to apply to the button e.g. Primary */
    buttonStyleType: PropTypes.string,
    /**The Label that displays on the button */
    buttonLabel: PropTypes.string,
    /**Whether to display a logo within the button */
    icon: PropTypes.shape({
      type: PropTypes.string,
      bgColor: PropTypes.string,
      bgShape: PropTypes.string,
      bgWidth: PropTypes.string,
      bgHeight: PropTypes.string,
      iconHeight: PropTypes.string,
      iconWidth: PropTypes.string,
      iconColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      spinning: PropTypes.bool,
      path: PropTypes.string,
      rotation: PropTypes.number
    }),
    /** display image within button if passed */
    image: PropTypes.shape({
      source: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string
    })
  };

  /**
   * Returns if the button is set to disabled, defaults to false
   * @public
   */
  isDisabled = () => {
    return this.props.isDisabled ? this.props.isDisabled : false;
  };

  /**
   * Calls the onClick function that has been passed in, defaults to null
   * @public
   */
  onClickHandler = () => {
    return this.props.onClick ? this.props.onClick : null;
  };
  /**
   * Returns the passed in url
   * @public
   */
  getUrl = () => {
    const { url } = this.props;
    if (!url) return null;
    const uri = url.split("/").filter((item) => item)[0];
    if (uri === USERS_URL) return url;
    return url && url.includes("/") ? `/${ORGANISATION_URL}${url}` : url;
  };

  /**
   * Returns type of button to render, defaults to BUTTON_TYPE_PRIMARY
   * @public
   */
  getButtonStyleType = () => {
    return this.props.buttonStyleType
      ? this.props.buttonStyleType
      : BUTTON_TYPE_PRIMARY;
  };

  /**
   * Returns the passed in buttonLabel
   * @public
   */
  getButtonLabel = () => {
    return this.props.buttonLabel ? this.props.buttonLabel : "";
  };

  /**
   * Returns the icon passed in, returns if no icon set
   * @public
   */
  getButtonIcon = () => {
    const { icon } = this.props;
    if (!icon || icon === "") return;

    return (
      <Icon
        data-name={"Icon"}
        iconColor={icon.iconColor}
        type={icon.type}
        bgColor={icon.bgColor}
        bgHeight={icon.bgHeight}
        bgWidth={icon.bgWidth}
        iconHeight={icon.iconHeight}
        iconWidth={icon.iconWidth}
        bgShape={icon.bgShape}
        rotation={icon.rotation}
        path={icon.path}
        spinning={icon.spinning}
      />
    );
  };

  /**
   * Returns the image passed in, returns if no image set
   * @public
   */
  getButtonImage = () => {
    const { image } = this.props;
    if (!image) return;
    const { width, height } = image;
    return (
      <ButtonImageWrapper width={width} height={height}>
        <Image {...image} />
      </ButtonImageWrapper>
    );
  };

  render() {
    return (
      <ButtonElement
        to={this.getUrl()}
        disabled={this.isDisabled()}
        styleType={this.getButtonStyleType()}
      >
        {this.getButtonImage()}
        {this.getButtonIcon()}
        <ButtonLabel styleType={this.getButtonStyleType()}>
          {this.getButtonLabel()}
        </ButtonLabel>
      </ButtonElement>
    );
  }
}

export default LinkButton;
