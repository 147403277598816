import { handleActions } from "redux-actions";
import english from "../english";

import {
  AUTHENTICATE_USER,
  LOADED_MAP,
  LOAD_WEB_MAP,
  LOADED_WEB_MAP,
  ERROR_LOADING_WEB_MAP,
  LOADED_LABELS,
  SET_LOADING_TEXT,
  SET_APP_LOADING,
  SET_PAGE_TITLE,
  SHOW_MODAL,
  HIDE_MODAL,
  SET_MODAL_CONTENT,
  SET_ERROR,
  SET_ABORT_CONTROLLER,
  STATUS_EMPTY,
  STATUS_LOADING,
  LOAD_DATASET_TEMPLATES,
  STATUS_READY,
  LOADED_DATASET_TEMPLATES,
  LOAD_LAYER_RENDERER_TEMPLATES,
  LOADED_LAYER_RENDERER_TEMPLATES,
  LOAD_LABEL_RENDERER_TEMPLATES,
  LOADED_LABEL_RENDERER_TEMPLATES,
  LOAD_LANDKIND_USERS,
  LOADED_LANDKIND_USERS,
  LOAD_LANDKIND_USER_ORGANISATIONS,
  LOADED_LANDKIND_USER_ORGANISATIONS,
  LOAD_LANDKIND_USER_INVITES,
  LOADED_LANDKIND_USER_INVITES,
  REMOVE_LANDKIND_USER,
  REMOVED_LANDKIND_USER,
  STATUS_REMOVING,
  STATUS_UPDATING,
  UPDATE_LANDKIND_USER,
  UPDATED_LANDKIND_USER,
  LOAD_WORKFLOW_TEMPLATES,
  LOADED_WORKFLOW_TEMPLATES
} from "@base/constants";

export const defaultState = {
  labels: english,
  abortController: null,
  loadingText: english.LOADING_LABEL,
  appLoading: true,
  pageTitle: "",
  modalVisibility: false,
  modalContent: {
    header: "",
    body: "",
    buttons: [{ isDisabled: false }]
  },
  error: {
    error: false,
    message: ""
  },
  datasetTemplates: {
    status: STATUS_EMPTY,
    available: []
  },
  layerRendererTemplates: {
    status: STATUS_EMPTY,
    available: []
  },
  labelRendererTemplates: {
    status: STATUS_EMPTY,
    available: []
  },
  landkindUser: {
    status: STATUS_READY
  },
  landkindUsers: {
    isLoading: false,
    available: false
  },
  landkindUserOrganisations: {
    isLoading: false,
    available: false
  },
  landkindUserInvites: {
    isLoading: false,
    available: false
  },
  workflowTemplates: {
    status: STATUS_EMPTY,
    available: []
  }
};

export default handleActions(
  {
    [AUTHENTICATE_USER]: (state, { payload }) => ({
      ...state,
      esriModules: payload
    }),
    [LOADED_MAP]: (state, { payload }) => ({
      ...state,
      mapView: payload
    }),
    [LOAD_WEB_MAP]: (state, { payload }) => ({
      ...state,
      webMapId: payload,
      webMapError: false
    }),
    [LOADED_WEB_MAP]: (state, { payload }) => ({
      ...state,
      webMap: payload
    }),
    [ERROR_LOADING_WEB_MAP]: (state, { payload }) => ({
      ...state,
      webMapError: payload
    }),
    [LOADED_LABELS]: (state, { payload }) => ({
      ...state,
      labels: payload
    }),
    [SET_LOADING_TEXT]: (state, { payload }) => ({
      ...state,
      loadingText: payload
    }),
    [SET_APP_LOADING]: (state, { payload }) => ({
      ...state,
      appLoading: payload
    }),
    [SET_PAGE_TITLE]: (state, { payload }) => ({
      ...state,
      pageTitle: payload
    }),
    [SHOW_MODAL]: (state) => ({
      ...state,
      modalVisibility: true
    }),
    [HIDE_MODAL]: (state) => ({
      ...state,
      modalVisibility: false
    }),
    [SET_MODAL_CONTENT]: (state, { payload }) => ({
      ...state,
      modalContent: payload
    }),
    [SET_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [SET_ABORT_CONTROLLER]: (state, { payload }) => ({
      ...state,
      abortController: payload
    }),
    [LOAD_DATASET_TEMPLATES]: (state) => ({
      ...state,
      datasetTemplates: {
        status: STATUS_LOADING,
        available: []
      }
    }),
    [LOADED_DATASET_TEMPLATES]: (state, { payload }) => ({
      ...state,
      datasetTemplates: {
        status: STATUS_READY,
        available: payload
      }
    }),
    [LOAD_LAYER_RENDERER_TEMPLATES]: (state) => ({
      ...state,
      layerRendererTemplates: {
        status: STATUS_LOADING,
        available: []
      }
    }),
    [LOADED_LAYER_RENDERER_TEMPLATES]: (state, { payload }) => ({
      ...state,
      layerRendererTemplates: {
        status: STATUS_READY,
        available: payload
      }
    }),
    [LOAD_LABEL_RENDERER_TEMPLATES]: (state) => ({
      ...state,
      labelRendererTemplates: {
        status: STATUS_LOADING,
        available: []
      }
    }),
    [LOADED_LABEL_RENDERER_TEMPLATES]: (state, { payload }) => ({
      ...state,
      labelRendererTemplates: {
        status: STATUS_READY,
        available: payload
      }
    }),
    [LOAD_LANDKIND_USERS]: (state) => ({
      ...state,
      landkindUsers: {
        ...state.landkindUsers,
        isLoading: true
      }
    }),
    [LOADED_LANDKIND_USERS]: (state, { payload }) => ({
      ...state,
      landkindUsers: {
        isLoading: false,
        available: payload
      }
    }),

    [LOAD_LANDKIND_USER_ORGANISATIONS]: (state) => ({
      ...state,
      landkindUserOrganisations: {
        ...state.landkindUserOrganisations,
        isLoading: true
      }
    }),
    [LOADED_LANDKIND_USER_ORGANISATIONS]: (state, { payload }) => ({
      ...state,
      landkindUserOrganisations: {
        isLoading: false,
        available: payload
      }
    }),
    [LOAD_LANDKIND_USER_INVITES]: (state) => ({
      ...state,
      landkindUserInvites: {
        ...state.landkindUserInvites,
        isLoading: true
      }
    }),
    [LOADED_LANDKIND_USER_INVITES]: (state, { payload }) => ({
      ...state,
      landkindUserInvites: {
        isLoading: false,
        available: payload
      }
    }),
    [REMOVE_LANDKIND_USER]: (state) => ({
      ...state,
      landkindUser: {
        status: STATUS_REMOVING
      }
    }),
    [REMOVED_LANDKIND_USER]: (state) => ({
      ...state,
      landkindUser: {
        status: STATUS_READY
      }
    }),
    [UPDATE_LANDKIND_USER]: (state) => ({
      ...state,
      landkindUser: {
        status: STATUS_UPDATING
      }
    }),
    [UPDATED_LANDKIND_USER]: (state) => ({
      ...state,
      landkindUser: {
        status: STATUS_READY
      }
    }),
    [LOAD_WORKFLOW_TEMPLATES]: (state) => ({
      ...state,
      workflowTemplates: {
        status: STATUS_LOADING,
        available: []
      }
    }),
    [LOADED_WORKFLOW_TEMPLATES]: (state, { payload }) => ({
      ...state,
      workflowTemplates: {
        status: STATUS_READY,
        available: payload
      }
    })
  },
  defaultState
);
