import { connect } from "react-redux";
import AddRenderer from "./AddRenderer";
import * as actions from "../../../actions";
import {
  getLabels,
  getSelectedOrgCreateRendererStatus
} from "../../../selectors";

export default connect(
  (state) => ({
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedOrgCreateRendererStatus: getSelectedOrgCreateRendererStatus(state)
  }),
  actions
)(AddRenderer);
