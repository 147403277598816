import React from "react";
import { Heading4Element } from "./style";
import PropTypes from "prop-types";

/** Renders Heading4 Element  NOTE text is WHITE! - Used for the header component, always use on an element with a background color*/
const Heading4 = (props) => {
  return (
    <Heading4Element styleType={props.styleType}>
      {props.children}
    </Heading4Element>
  );
};

Heading4.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.string
};
export default Heading4;
