import React from "react";
import PropTypes from "prop-types";
import { ListContentElement } from "./style";
import ListContentItem from "./subComponents/ListContentItem";
import { CSSTransition } from "react-transition-group";

import { CONTENT_FADE_TIMEOUT } from "../../../constants";

/**
 * Returns a array of ListContentItem components
 * @public
 */
export const renderChildren = (rows) => {
  if (!rows) return null;
  return rows.map((row, index) => {
    return <ListContentItem key={`${row.headerLabel}_${index}`} {...row} />;
  });
};

/**
 * Renders a ListContent Item, which can comprise of an Image, a header button, and series of child buttons.
 */
const ListContent = (props) => {
  return (
    <CSSTransition
      in={props.visible} // skipcq JS-0463
      timeout={CONTENT_FADE_TIMEOUT}
      classNames="css-transition"
      unmountOnExit
    >
      <ListContentElement data-name="ListContentElement">
        {renderChildren(props.rows)}
      </ListContentElement>
    </CSSTransition>
  );
};

ListContent.propTypes = {
  /** Array describing each row of content in the list  */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      /** Label for the main button (header) */
      headerLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /** Link that will navigate user to */
      headerOnClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      /** url that will navigate user to */
      url: PropTypes.string,
      /** src for the image to display (optional) if empty no Image component will be rendered */
      imageSource: PropTypes.string,
      /** the Alt text that will be listed on the image */
      altText: PropTypes.string,
      /** the child links to render in the list item */
      childLinks: PropTypes.arrayOf(
        PropTypes.shape({
          /** The label for the childs button  */
          buttonLabel: PropTypes.string,
          /** The link for the childs button (optional) if not supplied, then button will be disabled and render just a text  */
          onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
          /** The icon for the childs button (optional) if ommitted, link will be rendered without an icon */
          iconType: PropTypes.string
        })
      ),
      /** Whether list is visible or not */
      visible: PropTypes.bool,
      /** buttonType is linkButton renders Link otherwise Button */
      buttonType: PropTypes.string
    })
  )
};

ListContent.defaultProps = {
  visible: true
};

export default ListContent;
