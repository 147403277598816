import {
  STATUS_LOADING,
  READ_DETAILS_PERMISSION,
  WORKFLOWS_SECTION
} from "@base/constants";

export const getOrgDataLoading = (state) => {
  return (
    state.organisations.status === STATUS_LOADING ||
    state.organisations.selectedOrganisationUsers.isLoading ||
    state.organisations.selectedOrganisationPropertyGroups.isLoading ||
    state.organisations.selectedOrganisationProperties.isLoading ||
    state.organisations.selectedOrgArchivedProperties.isLoading ||
    state.organisations.selectedOrganisationRoles.isLoading ||
    state.organisations.selectedOrganisationWorkflows.isLoading
  );
};
export const getAvailableOrgs = (state) => state.organisations.available;
export const getSelectedOrg = (state) =>
  state.organisations.selectedOrganisation;
export const getSelectedOrgId = (state) =>
  state.organisations.selectedOrganisation.orgId;
export const getOrgsStatus = (state) => state.organisations.status;

export const getSelectedOrgLoading = (state) => state.organisations.isSelecting;

export const getSelectedOrgUsers = (state) => {
  return state.organisations.selectedOrganisationUsers.available
    ? state.organisations.selectedOrganisationUsers.available.items
    : [];
};
export const getSelectedOrgUsersTotal = (state) => {
  return state.organisations.selectedOrganisationUsers.available &&
    state.organisations.selectedOrganisationUsers.available.meta &&
    state.organisations.selectedOrganisationUsers.available.meta.total
    ? state.organisations.selectedOrganisationUsers.available.meta.total
    : false;
};
export const getSelectedOrgUsersLoading = (state) => {
  return state.organisations.selectedOrganisationUsers.isLoading;
};

export const getSelectedOrgPropertyGroups = (state) => {
  return state.organisations.selectedOrganisationPropertyGroups.available
    ? state.organisations.selectedOrganisationPropertyGroups.available.items
    : false;
};

export const getSelectedOrgPropertyGroupsTotal = (state) => {
  return state.organisations.selectedOrganisationPropertyGroups.available &&
    state.organisations.selectedOrganisationPropertyGroups.available.meta &&
    state.organisations.selectedOrganisationPropertyGroups.available.meta.total
    ? state.organisations.selectedOrganisationPropertyGroups.available.meta
        .total
    : false;
};

export const getSelectedOrgPropertyGroupsLoading = (state) => {
  return state.organisations.selectedOrganisationPropertyGroups.isLoading;
};

export const getSelectedOrgProperties = (state) => {
  return state.organisations.selectedOrganisationProperties.available
    ? state.organisations.selectedOrganisationProperties.available.items
    : false;
};

export const getSelectedOrgPropertiesTotal = (state) => {
  return state.organisations.selectedOrganisationProperties.available &&
    state.organisations.selectedOrganisationProperties.available.meta &&
    state.organisations.selectedOrganisationProperties.available.meta.total
    ? state.organisations.selectedOrganisationProperties.available.meta.total
    : false;
};

export const getSelectedOrgPropertiesLoading = (state) => {
  return state.organisations.selectedOrganisationProperties.isLoading;
};

export const getOrgArchivedProperties = (state) => {
  return state.organisations.selectedOrgArchivedProperties.available
    ? state.organisations.selectedOrgArchivedProperties.available.items
    : false;
};

export const getOrgArchivedPropertiesTotal = (state) => {
  return state.organisations.selectedOrgArchivedProperties.available &&
    state.organisations.selectedOrgArchivedProperties.available.meta &&
    state.organisations.selectedOrgArchivedProperties.available.meta.total
    ? state.organisations.selectedOrgArchivedProperties.available.meta.total
    : false;
};

export const getOrgArchivedPropertiesLoading = (state) => {
  return state.organisations.selectedOrgArchivedProperties.isLoading;
};

export const getSelectedOrgRoles = (state) => {
  return state.organisations.selectedOrganisationRoles.available
    ? state.organisations.selectedOrganisationRoles.available.items
    : false;
};

export const getSelectedOrgRolesTotal = (state) => {
  return state.organisations.selectedOrganisationRoles.available &&
    state.organisations.selectedOrganisationRoles.available.meta &&
    state.organisations.selectedOrganisationRoles.available.meta.total
    ? state.organisations.selectedOrganisationRoles.available.meta.total
    : false;
};

export const getSelectedOrgRolesLoading = (state) => {
  return state.organisations.selectedOrganisationRoles.isLoading;
};

export const getSelectedOrgWorkflows = (state) => {
  return state.organisations.selectedOrganisationWorkflows.available
    ? state.organisations.selectedOrganisationWorkflows.available.items
    : false;
};

export const getSelectedOrgWorkflowsTotal = (state) => {
  return state.organisations.selectedOrganisationWorkflows.available &&
    state.organisations.selectedOrganisationWorkflows.available.meta &&
    state.organisations.selectedOrganisationWorkflows.available.meta.count
    ? state.organisations.selectedOrganisationWorkflows.available.meta.count
    : false;
};

export const getSelectedOrgWorkflowsLoading = (state) => {
  return state.organisations.selectedOrganisationWorkflows.isLoading;
};

export const getOrgPropertiesPageNumber = (state) => {
  return state.organisations.page ? state.organisations.page : 1;
};

export const getOrgPropertiesOffset = (state) => {
  return state.organisations.offset ? state.organisations.offset : 0;
};
export const getOrgPropertiesSearch = (state) => {
  return state.organisations.search ? state.organisations.search : "";
};

export const getOrgArchivedPropsPageNumber = (state) => {
  return state.organisations.selectedOrgArchivedPagination.page
    ? state.organisations.selectedOrgArchivedPagination.page
    : 1;
};

export const getOrgArchivedPropsOffset = (state) => {
  return state.organisations.selectedOrgArchivedPagination.offset
    ? state.organisations.selectedOrgArchivedPagination.offset
    : 0;
};
export const getOrgArchivedPropsSearch = (state) => {
  return state.organisations.selectedOrgArchivedPagination.search
    ? state.organisations.selectedOrgArchivedPagination.search
    : "";
};

export const getOrgLogoUrl = (state) =>
  state.organisations.selectedOrganisation.logo;

export const getSelectedOrgInvitedUsers = (state) =>
  state.organisations.selectedOrganisationInvitedUsers.available
    ? state.organisations.selectedOrganisationInvitedUsers.available.items
    : [];

export const getSelectedOrgAcceptedUsers = (state) =>
  state.organisations.selectedOrganisationAcceptedUsers.available
    ? state.organisations.selectedOrganisationAcceptedUsers.available.items
    : [];
export const getHasPermission = (state, orgId, section, permissionType) => {
  if (state.login.user.superHuman) return true;
  const organisation =
    state.organisations && state.organisations.available
      ? state.organisations.available.find((org) => org.orgId === orgId)
      : null;
  if (
    !organisation ||
    !organisation.adminConsoleAccess ||
    !organisation.adminConsoleAccess.adminAccess ||
    (section === WORKFLOWS_SECTION &&
      permissionType === READ_DETAILS_PERMISSION)
  )
    return false;
  return organisation.adminConsoleAccess[section] &&
    organisation.adminConsoleAccess[section][permissionType]
    ? true
    : false;
};
export const getSelectedOrgDatasets = (state) => {
  return state.organisations.selectedOrganisationDatasets.available;
};

export const getSelectedOrgDatasetsLoading = (state) => {
  return state.organisations.selectedOrganisationDatasets.isLoading;
};

export const getSelectedOrgRenderersLoading = (state) => {
  return state.organisations.selectedOrganisationRenderers.isLoading;
};

export const getSelectedOrgRenderers = (state) => {
  return state.organisations.selectedOrganisationRenderers.available;
};

export const getSelectedOrgRendererLoading = (state) => {
  return state.organisations.selectedOrganisationRenderer.isLoading;
};

export const getSelectedOrgRenderer = (state) => {
  return state.organisations.selectedOrganisationRenderer.available;
};

export const getSelectedOrgCreateRendererStatus = (state) => {
  return state.organisations.createRendererStatus;
};

export const getSelectedOrgCreateDatasetStatus = (state) => {
  return state.organisations.createDatasetStatus;
};
