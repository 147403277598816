import { combineReducers } from "redux";

import app from "./app";
import organisations from "./organisations";
import user from "./user";
import login from "./login";
import propertyGroup from "./propertyGroup";
import role from "./role";
import workflow from "./workflow";
import property from "./property";

export default combineReducers({
  app,
  organisations,
  user,
  login,
  propertyGroup,
  role,
  workflow,
  property
});
