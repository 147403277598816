import React from "react";
import { WrapperElement } from "./style";
import PropTypes from "prop-types";

/** Renders a Wrapper to hold a Container */
const Wrapper = (props) => {
  return (
    <WrapperElement
      data-name={"Wrapper"}
      {...props}
      styleType={props.styleType ? props.styleType : ""}
    >
      {props.children}
    </WrapperElement>
  );
};

Wrapper.propTypes = {
  /** The variant of wrapper styled component needed, such as ApplicationHeader (optional) */
  styleType: PropTypes.string,
  /** children */
  children: PropTypes.node
};

export default Wrapper;
