import React from "react";
import styled, { css } from "styled-components";
import { Link } from "@reach/router";

export const BreadCrumbList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin: 1.5rem 0rem;
  white-space: nowrap;
`;
BreadCrumbList.displayName = "BreadCrumbList";

export const BreadCrumbListElement = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  &:first-child {
    padding-left: 0;
  }
`;
BreadCrumbListElement.displayName = "BreadCrumbListElement";

export const BreadCrumbLinkElement = styled((props) => <Link {...props} />)`
  padding: 0.2rem 0.4rem;
  position: relative;
  border: none;
  color: ${(props) => props.theme.agCommonBlack};
  font-weight: ${(props) => props.theme.baseFontWeightRegular};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 1;
    `}

  ${(props) =>
    props.disabled &&
    props.children !== "Home" &&
    css`
      opacity: 1;
      pointer-events: none;
      color: ${(props) => props.theme.agCommonBlack};
      font-weight: ${(props) => props.theme.baseFontWeightHeavy};
    `}
  
  ${(props) =>
    props.menuOpen &&
    css`
      color: ${(props) => props.theme.agCommonBlack};
      font-weight: ${(props) => props.theme.baseFontWeightHeavy};
    `}

    ${(props) =>
    props.siblingMenu &&
    css`
      padding-right: 0.3rem;
    `}

      :focus {
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.agCommonBlack};
    outline: none !important;
  }
`;
BreadCrumbLinkElement.displayName = "BreadCrumbLinkElement";

export const BreadCrumbSiblingMenuList = styled.ul`
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.agWhite};
  display: ${(props) => (props.open === true ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  top: 1.5rem;
  text-align: right;
  font-size: ${(props) => props.theme.fontSizeSmaller};
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  background: ${(props) => props.theme.agWhite};

  ${(props) =>
    props.open &&
    css`
      opacity: 1;
      pointer-events: inherit;
      z-index: 99999;
      box-shadow: 0 0 0.625rem ${(props) => props.theme.agLightestGray};
    `}
`;

BreadCrumbSiblingMenuList.displayName = "BreadCrumbSiblingMenuList";

export const BreadCrumbSiblingMenuListElement = styled.li`
  display: inline-block;
  border-bottom: 1px solid ${(props) => props.theme.agLightestGray};
  background: ${(props) => props.theme.agWhite};

  &:last-child {
    margin-bottom: 0;
    border: none;
  }

  &:hover {
    background: ${(props) => props.theme.agLightShadeGray};
  }
`;
BreadCrumbSiblingMenuListElement.displayName =
  "BreadCrumbSiblingMenuListElement";

export const BreadCrumbSiblingMenuLinkElement = styled((props) => (
  <Link {...props} />
))`
  padding: 0.6rem 0.7rem;
  text-decoration: none;
  display: block;
  color: ${(props) => props.theme.agCommonBlack};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
`;
BreadCrumbSiblingMenuLinkElement.displayName =
  "BreadCrumbSiblingMenuLinkElement";

export const BreadCrumbNavigationWrapper = styled.nav`
  height: 100%;
`;
BreadCrumbNavigationWrapper.displayName = "BreadCrumbNavigationWrapper";

export const BreadCrumbSiblingMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
BreadCrumbSiblingMenuWrapper.displayName = "BreadCrumbSiblingMenuWrapper";
