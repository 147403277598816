import React, { Component } from "react";
import {
  Container,
  Wrapper,
  Section,
  FormInput,
  FormLabel,
  Checkbox,
  TextArea
} from "@core";
import {
  ContextHeader,
  Loader,
  BottomButtonBar,
  PaginationV2,
  FilterInput,
  Dropdown
} from "@UIKit";
import { getSession, storeSession } from "@base/common";
import {
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  STYLETYPE_FORM_FIELD,
  STYLETYPE_ERROR,
  STYLETYPE_CHECKBOX_CONTAINER,
  STATUS_UPDATING,
  STATUS_READY,
  BUTTON_TYPE_PRIMARY,
  BUTTON_TYPE_SECONDARY,
  STATUS_LOADING,
  BUTTON_TYPE_PRIMARY_FULLWIDTH,
  BUTTON_TYPE_SECONDARY_FULLWIDTH,
  MINIMUM_SEARCH_STRING_LENGTH,
  SEARCH_TIMEOUT,
  FIELD_SIZE_SMALL,
  STYLETYPE_TEXT,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  DATA_TABLE_PAGINATION_STYLING,
  DATA_TABLE_FILTER_INPUT_STYLING,
  HEIGHT_AUTO_TEXT
} from "@base/constants";
import { navigate } from "@reach/router";

export default class UpdatePropertyGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      filterText: "",
      properties: [],
      originalState: {
        properties: [],
        title: "",
        description: ""
      },
      searchTimeout: null
    };
  }

  componentDidMount() {
    this.loadDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps) {
    const { selectedPropertyGroupStatus } = this.props;
    if (
      prevProps.selectedPropertyGroupStatus === STATUS_UPDATING &&
      selectedPropertyGroupStatus === STATUS_READY
    ) {
      this.showSuccess();
    }
    if (
      prevProps.selectedPropertyGroupStatus === STATUS_LOADING &&
      this.props.selectedPropertyGroupStatus !== STATUS_LOADING &&
      this.props.selectedPropertyGroup
    ) {
      const { title, description, properties } =
        this.props.selectedPropertyGroup;
      this.setState({
        title,
        description,
        properties: properties.map(({ propId }) => propId),
        originalState: {
          title,
          description,
          properties: properties.map(({ propId }) => propId)
        }
      });
    }
  }

  loadDetails = () => {
    const {
      setOrgPropertiesPagination,
      loadOrganisationProperties,
      loadPropertyGroup,
      propertyGroupId,
      orgId
    } = this.props;
    loadPropertyGroup(orgId, propertyGroupId, true);
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(0, 10, 0, "");
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      item: getLabel("PROPERTY_GROUP_TEXT"),
      action: getLabel("UPDATE_ACTION_LABEL")
    });
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return "";
    return this.state[field];
  };

  valueIsEmpty = (field) => {
    return (
      !this.getValue(field) ||
      (this.getValue(field) && this.getValue(field).length === 0) ||
      this.getValue(field) === ""
    );
  };

  isLoading = () => {
    const {
      selectedPropertyGroup,
      selectedPropertyGroupStatus,
      selectedOrganisationProperties
    } = this.props;
    return (
      selectedPropertyGroup ||
      selectedOrganisationProperties ||
      selectedPropertyGroupStatus === STATUS_LOADING
    );
  };

  getPropertyOptions = () => {
    const { properties } = this.state;
    const { selectedOrganisationProperties } = this.props;
    if (!selectedOrganisationProperties) return [];

    return selectedOrganisationProperties.map((property) => {
      return {
        ...property,
        title: `${property.officialName} - ${property.title}`,
        value: property.propId,
        checked: properties.indexOf(property.propId) !== -1
      };
    });
  };

  resetPropertyGroup = () => {
    const { originalState } = this.state;
    this.setState({
      title: originalState.title,
      description: originalState.description,
      properties: originalState.properties
    });
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("UPDATE_ACTION_LABEL"),
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  savePropertyGroup = async () => {
    try {
      const { title, properties, description } = this.state;
      const {
        orgId,
        propertyGroupId,
        updatePropertyGroup,
        selectedPropertyGroup,
        showModal
      } = this.props;

      this.showLoading();
      showModal();

      const currentProperties = selectedPropertyGroup.properties;

      const addProperties = properties.filter(
        (propId) =>
          !currentProperties.find((property) => property.propId === propId)
      );

      const removeProperties = currentProperties.reduce(
        (result, property) =>
          properties.indexOf(property.propId) === -1
            ? [...result, property.propId]
            : result,
        []
      );
      const data = JSON.stringify({
        title,
        description,
        addProperties,
        removeProperties
      });

      await updatePropertyGroup(orgId, propertyGroupId, data);
    } catch (e) {
      throw new Error(e);
    }
  };

  returnToOverview = () => {
    const { hideModal } = this.props;
    hideModal();
    navigate(`../`);
  };

  returnToDetails = () => {
    const { hideModal } = this.props;
    hideModal();
    navigate(`./`);
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_UPDATED_LABEL", {
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      body: getLabel("ITEM_UPDATED_SUCCESS", {
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("PROPERTY_GROUP_TEXT")
          }),
          onClick: this.returnToOverview
        },
        {
          buttonLabel: getLabel("RETURN_TO_DETAILS", {
            item: getLabel("PROPERTY_GROUP_TEXT")
          }),
          onClick: this.returnToDetails
        }
      ]
    });
    return null;
  };

  showMessage = () => {
    const { setModalContent, showModal, getLabel } = this.props;
    showModal();
    setModalContent({
      showCloseButton: false,
      header: getLabel("UPDATE_ACTION_HEADER_LABEL", {
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      body: getLabel("BACK_TO_DETAILS_MESSAGE"),
      buttons: [
        {
          buttonStyleType: BUTTON_TYPE_PRIMARY_FULLWIDTH,
          buttonLabel: getLabel("YES_LABEL"),
          onClick: this.savePropertyGroup
        },
        {
          buttonStyleType: BUTTON_TYPE_SECONDARY_FULLWIDTH,
          buttonLabel: getLabel("NO_LABEL"),
          onClick: this.returnToDetails
        }
      ]
    });
    return null;
  };

  getStateChanged = () => {
    const { title, properties, description, originalState } = this.state;
    if (
      title !== originalState.title ||
      description !== originalState.description ||
      properties.length !== originalState.properties.length
    ) {
      return true;
    }
    return false;
  };

  getButtons = () => {
    const { getLabel } = this.props;
    const stateChanged = this.getStateChanged();
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        isDisabled: this.valueIsEmpty("title"),
        onClick: this.savePropertyGroup,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("PROPERTY_GROUP_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      },
      {
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        onClick: this.resetPropertyGroup,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("PROPERTY_GROUP_TEXT"),
          action: getLabel("RESET_LABEL")
        })
      },
      {
        onClick: stateChanged ? this.showMessage : this.returnToDetails,
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        buttonLabel: getLabel("RETURN_TO_DETAILS", {
          item: getLabel("PROPERTY_GROUP_TEXT")
        })
      }
    ];
  };

  getPropertiesTotal = () => {
    const { selectedOrgPropertiesTotal } = this.props;
    if (!selectedOrgPropertiesTotal) return [];
    return selectedOrgPropertiesTotal;
  };

  getPageLimit = () => {
    return getSession(`properties-${PAGINATION_KEY}`) || PAGINATION_OPTIONS[0];
  };

  showPagination = () => {
    return this.getPropertiesTotal() > this.getPageLimit();
  };

  getCurrentPageNumber = () => {
    const { selectedOrgPropertiesPageNumber } = this.props;
    return selectedOrgPropertiesPageNumber;
  };

  getFilterText = () => {
    const { filterText } = this.state;
    return filterText;
  };

  isPropertiesLoading = () => {
    const { selectedOrganisationPropertiesLoading } = this.props;
    return selectedOrganisationPropertiesLoading;
  };

  setPaginationContent = (value) => {
    const { orgId, setOrgPropertiesPagination, loadOrganisationProperties } =
      this.props;
    const searchValue = this.getFilterText();
    const result = this.getPageLimit();
    let offset = (value - 1) * result;
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(value, 10, offset, searchValue);
  };

  getPropertiesFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("PROPERTIES_TEXT")
    });
  };

  setFilterText = (value) => {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearInterval(searchTimeout);
    }

    this.setState(
      {
        filterText: value
      },
      () => {
        if (value.length >= MINIMUM_SEARCH_STRING_LENGTH || value === "") {
          const searchTimeout = setTimeout(() => {
            this.setPaginationContent(1);
          }, SEARCH_TIMEOUT);
          this.setState({ searchTimeout });
        }
      }
    );
  };

  dropDownOnSelectFunction = (dropdown) => {
    this.setState({ currentPageNumber: 1 });
    const { orgId, loadOrganisationProperties, setOrgPropertiesPagination } =
      this.props;
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(0, dropdown.value, 0, "");
    storeSession(`properties-${PAGINATION_KEY}`, dropdown.value);
  };

  showDropdown = () => {
    return this.getPropertiesTotal() > this.getDropDownOptions()[0].value;
  };

  getDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit()
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoading() === true ? (
          <Loader
            fullpage={false}
            loadingText={getLabel("GENERIC_ACTION_MESSAGE", {
              item: getLabel("PROPERTY_GROUP_TEXT"),
              action: getLabel("LOADING_LABEL")
            })}
          />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
              />
            </Container>
            <Container></Container>

            <Container>
              <Section>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <FormLabel
                    htmlFor={"title"}
                    styleType={
                      this.valueIsEmpty("title") ? STYLETYPE_ERROR : ""
                    }
                  >
                    {getLabel("FORM_TITLE_LABEL")}
                  </FormLabel>
                  <FormInput
                    id={"title"}
                    value={this.getValue("title")}
                    onChange={(value) => {
                      this.setValue(value, "title");
                    }}
                    styleType={
                      this.valueIsEmpty("title") ? STYLETYPE_ERROR : ""
                    }
                  />
                </Container>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <FormLabel
                    htmlFor="property-group-description"
                    id="property-group-description-label"
                  >
                    {getLabel("FORM_LABEL_DESCRIPTION")}
                  </FormLabel>
                  <TextArea
                    value={this.getValue("description")}
                    onChange={(value) => {
                      this.setValue(value, "description");
                    }}
                    id="property-group-description"
                    size={FIELD_SIZE_SMALL}
                    styleType={STYLETYPE_TEXT}
                  />
                </Container>
                <FilterInput
                  inputId={`${this.getPropertiesFilterLabel()}-input`}
                  label={this.getPropertiesFilterLabel()}
                  liveFilter={true}
                  placeholder={"e.g: Orchard 1"}
                  filterFunction={(event) => {
                    this.setFilterText(event);
                  }}
                  styleType={DATA_TABLE_FILTER_INPUT_STYLING}
                />
                {this.isPropertiesLoading() ? (
                  <Loader fullPage={false} />
                ) : (
                  <Container styleType={STYLETYPE_CHECKBOX_CONTAINER}>
                    <Checkbox
                      handleUpdateValue={(properties) =>
                        this.setValue(properties, "properties")
                      }
                      options={this.getPropertyOptions()}
                      value={this.getValue("properties")}
                      name={"propIds"}
                      legend={getLabel("SELECT_HEADER", {
                        item: getLabel("PROPERTIES_TEXT")
                      })}
                    />
                  </Container>
                )}
                <Container
                  direction={"row"}
                  styleType={DATA_TABLE_PAGINATION_STYLING}
                >
                  {this.showDropdown() && (
                    <Dropdown
                      id={"update-property-group"}
                      placeholder={"Select option"}
                      onClick={this.dropDownOnSelectFunction}
                      label={this.getDropDownLabel()}
                      value={this.getPageLimit()}
                      options={this.getDropDownOptions()}
                    />
                  )}
                  {this.showPagination() && (
                    <PaginationV2
                      currentPageNumber={this.getCurrentPageNumber()}
                      listPerPageNum={this.getPageLimit()}
                      totalItems={this.getPropertiesTotal()}
                      setPaginationContent={this.setPaginationContent}
                      showPaginationInput={true}
                    />
                  )}
                </Container>
                <BottomButtonBar buttons={this.getButtons()} />
              </Section>
            </Container>
          </Wrapper>
        )}
      </Container>
    );
  }
}
