import styled from "styled-components";

// @ts-ignore
export const ListElement = styled.ul`
  margin: 0;
  padding: 0;
`;

ListElement.displayName = "ListElement";

export const ListItemElement = styled.li`
  margin: 0;
  padding: 0;
`;

ListItemElement.displayName = "ListItemElement";
