import { createActions } from "redux-actions";
import {
  LOAD_USER,
  LOADED_USER,
  CREATE_INVITE,
  LOAD_USER_PROPERTIES_MEMBERSHIP,
  LOADED_USER_PROPERTIES_MEMBERSHIP,
  LOAD_USER_PROPERTY_GROUPS,
  LOADED_USER_PROPERTY_GROUPS,
  UPDATE_USER_MEMBERSHIP,
  UPDATED_USER_MEMBERSHIP,
  UPDATE_USER_MEMBERSHIP_FAILED,
  REMOVE_USER_MEMBERSHIP,
  REMOVED_USER_MEMBERSHIP,
  LOAD_USER_MEMBERSHIP,
  LOADED_USER_MEMBERSHIP,
  CREATED_INVITE,
  CREATE_INVITE_ERROR,
  RESEND_INVITE,
  RESENDED_INVITE,
  LOAD_INVITED_USER,
  LOADED_INVITED_USER,
  REMOVE_INVITE,
  REMOVED_INVITE
} from "@base/constants";

export const {
  loadUser,
  loadedUser,
  createInvite,
  createdInvite,
  createInviteError,
  loadUserPropertiesMembership,
  loadedUserPropertiesMembership,
  loadUserPropertyGroups,
  loadedUserPropertyGroups,
  updateUserMembership,
  updatedUserMembership,
  updateUserMembershipFailed,
  removeUserMembership,
  removedUserMembership,
  loadUserMembership,
  loadedUserMembership,
  resendInvite,
  resendedInvite,
  loadInvitedUser,
  loadedInvitedUser,
  removeInvite,
  removedInvite
} = createActions({
  [LOAD_USER]: (userId) => userId,
  [LOADED_USER]: (user) => user,
  [CREATE_INVITE]: (orgId, data, sendEmail) => ({ orgId, data, sendEmail }),
  [CREATED_INVITE]: (inviteId) => inviteId,
  [CREATE_INVITE_ERROR]: (errorMessage) => errorMessage,
  [LOAD_USER_PROPERTIES_MEMBERSHIP]: (orgId, userId) => ({
    orgId,
    userId
  }),
  [LOADED_USER_PROPERTIES_MEMBERSHIP]: (user) => user,
  [LOAD_USER_PROPERTY_GROUPS]: (orgId, userId) => ({ orgId, userId }),
  [LOADED_USER_PROPERTY_GROUPS]: (user) => user,
  [UPDATE_USER_MEMBERSHIP]: (orgId, userId, data) => ({
    orgId,
    userId,
    data
  }),
  [UPDATED_USER_MEMBERSHIP]: () => {},
  [UPDATE_USER_MEMBERSHIP_FAILED]: () => {},
  [REMOVE_USER_MEMBERSHIP]: (orgId, userId) => ({ orgId, userId }),
  [REMOVED_USER_MEMBERSHIP]: () => {},
  [LOAD_USER_MEMBERSHIP]: (orgId, userId) => ({ orgId, userId }),
  [LOADED_USER_MEMBERSHIP]: (user) => user,
  [RESEND_INVITE]: (orgId, inviteId, sendEmail, data) => ({
    orgId,
    inviteId,
    sendEmail,
    data
  }),
  [RESENDED_INVITE]: () => {},
  [LOAD_INVITED_USER]: (orgId, inviteId) => ({
    orgId,
    inviteId
  }),
  [LOADED_INVITED_USER]: (invitedUserDetails) => invitedUserDetails,
  [REMOVE_INVITE]: (orgId, inviteId) => ({ orgId, inviteId }),
  [REMOVED_INVITE]: () => {}
});
