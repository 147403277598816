import React, { Component } from "react";
import { Loader, ContextHeader, DataTable } from "@UIKit";
import { Heading3, P } from "@typography";
import { Section, Container, Wrapper, LinkButton, Icon } from "@core";
import {
  STATUS_REMOVING,
  STATUS_LOADING,
  STATUS_READY,
  USERS_URL,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  SECTION_HEADER_STYLING,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  ASSETS_PROXY_SUBSTRING,
  UPDATE_USER_URL,
  HEIGHT_AUTO_TEXT,
  STYLETYPE_STATUS,
  REACT_APP_ASSET_PROXY_URL_ENV
} from "@base/constants";
import {
  filterList,
  getStatusColorByKey,
  getStatusLabelConstByKey,
  getUserStatusKey
} from "@base/common";
import { navigate } from "@reach/router";

class SuperHumanUsersDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { filterText: "" };
  }

  componentDidMount() {
    this.setupUserDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps) {
    const { setPageTitle, pageTitle, selectedUser, landkindUserStatus } =
      this.props;
    if (selectedUser) {
      const { firstName, lastName } = selectedUser;
      const userName = `${firstName} ${lastName}`;
      if (pageTitle !== userName) {
        setPageTitle(userName);
      }
    }

    if (
      landkindUserStatus === STATUS_READY &&
      prevProps.landkindUserStatus === STATUS_REMOVING
    ) {
      this.showRemoveSuccess();
    }
  }

  setupUserDetails = () => {
    const {
      userId,
      loadUser,
      loadLandkindUserOrganisations,
      loadLandkindUserInvites
    } = this.props;
    loadUser(userId);
    loadLandkindUserInvites(userId);
    loadLandkindUserOrganisations(userId);
  };

  getSelectedUser = () => {
    const { selectedUser } = this.props;
    return selectedUser ? selectedUser : false;
  };

  getLandkindUserOrganisations = () => {
    const { landkindUserOrganisations } = this.props;
    return landkindUserOrganisations ? landkindUserOrganisations : false;
  };

  getLandkindUserInvites = () => {
    const { landkindUserInvites } = this.props;
    return landkindUserInvites ? landkindUserInvites : false;
  };

  isUserDetailLoading = () => {
    const {
      selectedUserStatus,
      landkindUserInvitesLoading,
      landkindUserOrganisationsLoading
    } = this.props;
    return (
      landkindUserInvitesLoading ||
      landkindUserOrganisationsLoading ||
      selectedUserStatus === STATUS_LOADING
    );
  };

  createHeaderText = () => {
    const selectedUser = this.getSelectedUser();
    const { firstName, lastName } = selectedUser;
    return `${firstName} ${lastName}`;
  };

  getUserEmail = () => {
    const selectedUser = this.getSelectedUser();
    const { emailAddress } = selectedUser;
    return emailAddress ? emailAddress : "";
  };

  getUserDetails = () => {
    const { getLabel } = this.props;
    const selectedUser = this.getSelectedUser();
    const { preferences } = selectedUser;
    if (!preferences) return false;
    const { language } = preferences;

    if (language) {
      return (
        <P key={language}>
          {getLabel("LANGUAGE_LABEL")}:
          {` ${language[0].toUpperCase()}` + language.substring(1)}
        </P>
      );
    }
    return null;
  };

  setFilterText = (value) => {
    this.setState({
      filterText: value
    });
  };

  getFilterText = () => this.state.filterText;

  getNoResultsText = () => {
    const { getLabel } = this.props;
    const filterText = this.getFilterText();
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("ORGANISATIONS_TEXT")
    });
  };

  getOrgLogo = (org) => {
    if (!org.logo && !org.orgLogo) return "";
    const logoUrl = org.logo || org.orgLogo;
    if (logoUrl.includes(ASSETS_PROXY_SUBSTRING)) return logoUrl;
    const altAssetsSubstring = REACT_APP_ASSET_PROXY_URL_ENV.replace(
      "landkind.com",
      "agbox.io"
    );

    return `${REACT_APP_ASSET_PROXY_URL_ENV}/${logoUrl.replace(
      new RegExp(
        `(https://|http://|${REACT_APP_ASSET_PROXY_URL_ENV}/|${altAssetsSubstring}/)`
      ),
      ""
    )}`;
  };

  getUserStatusInfo = (item) => {
    const { getLabel } = this.props;
    const statusKey = getUserStatusKey(item);
    const labelConst = getStatusLabelConstByKey(statusKey);
    const color = getStatusColorByKey(statusKey);
    return (
      <Container direction={"row"} styleType={STYLETYPE_STATUS} dataTableStatus>
        <Icon
          type={"circle"}
          iconColor={color}
          bgShape="round"
          iconWidth="20px"
          iconHeight="20px"
        />
        <P styleType={STYLETYPE_STATUS}>{getLabel(labelConst)}</P>
      </Container>
    );
  };

  getTableData = () => {
    const { userId, getLabel } = this.props;
    const orgs = this.getLandkindUserOrganisations();
    if (!orgs) return [];

    const orgsList = filterList(orgs, this.getFilterText());
    return orgsList.map((org) => {
      const title = (
        <LinkButton
          url={`/${org.orgId}/${USERS_URL}/${userId}`}
          buttonLabel={org.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
          image={{
            source: this.getOrgLogo(org),
            altText: `${org.title}-logo`,
            width: "5em",
            height: "2.2em",
            onError: (e) => {
              e.target.onerror = null;
              e.target.style.display = "none";
              e.target.setAttribute("aria-hidden", true);
            }
          }}
        />
      );

      return {
        cells: [
          title,
          org.roleTitle || getLabel("NO_ROLE_ASSIGNED_LABEL"),
          this.getUserStatusInfo(org)
        ]
      };
    });
  };

  getTableTitle = () => {
    const selectedUser = this.getSelectedUser();
    return `${selectedUser.firstName}'s organisations`;
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    return {
      visible: true,
      childLinks: [
        {
          isDisabled: false,
          onClick: this.updateUserAction,
          buttonLabel: getLabel("UPDATE_USER_DETAILS_LABEL")
        },
        {
          isDisabled: false,
          onClick: this.showRemoveConfirmation,
          buttonLabel: getLabel("PERMANENTLY_DELETE_USER_LABEL")
        }
      ]
    };
  };

  removeLandkindUser = () => {
    const { userId, getLabel, removeLandkindUser } = this.props;

    removeLandkindUser(userId);
    this.showLoading(getLabel("REMOVING_LABEL"));
  };

  showLoading = (label) => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: label,
        item: getLabel("USER_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
  };

  showRemoveConfirmation = () => {
    const { showModal, setModalContent, getLabel, hideModal } = this.props;

    setModalContent({
      header: getLabel("PERMANENTLY_DELETE_USER_LABEL"),
      body: getLabel("PERMANENTLY_DELETE_USER_BODY"),
      buttons: [
        {
          buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
            item: getLabel("USER_TEXT")
          }),
          onClick: this.removeLandkindUser
        },
        {
          buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
          onClick: hideModal
        }
      ]
    });
    showModal();
  };

  showRemoveSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_REMOVED_LABEL", {
        item: getLabel("USER_TEXT")
      }),
      body: getLabel("USER_REMOVED_SUCCESS_LABEL"),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("USER_TEXT").toLowerCase()
          }),
          onClick: this.returnToOverview
        }
      ]
    });
  };

  returnToOverview = () => {
    const { hideModal } = this.props;
    hideModal();
    navigate("../");
  };

  updateUserAction = () => {
    const { hideModal, userId } = this.props;
    hideModal();
    navigate(`/${USERS_URL}/${userId}/${UPDATE_USER_URL}`);
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isUserDetailLoading() === true ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={this.getContextMenu()}
              />
            </Container>
            <Section>
              <Heading3 styleType={SECTION_HEADER_STYLING}>
                {this.getUserEmail()}
              </Heading3>
              {this.getUserDetails()}
            </Section>

            <Section styleType={DATA_TABLE_SECTION_STYLING}>
              <DataTable
                noResultsText={this.getNoResultsText()}
                title={this.getTableTitle()}
                tableFilter={{
                  filterLabel: this.getFilterLabel(),
                  showFilter: true,
                  filterFunction: this.setFilterText,
                  placeholderText: getLabel(
                    "ORGANISATION_FILTER_PLACEHOLDER_LABEL"
                  ),
                  inputId: this.getFilterLabel()
                }}
                header={{
                  cells: [
                    {
                      content: getLabel("FORM_LABEL_ORG_NAME")
                    },
                    {
                      content: getLabel("ROLE_TEXT")
                    },
                    {
                      content: getLabel("FORM_STATUS_LABEL")
                    }
                  ]
                }}
                rows={this.getTableData()}
              />
            </Section>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default SuperHumanUsersDetail;
