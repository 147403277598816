import React, { Component } from "react";
import {
  Container,
  Wrapper,
  Section,
  FormLabel,
  FormInput,
  CheckboxV2,
  Fieldset
} from "@core";
import { getSession, storeSession } from "@base/common";
import { P, Heading4, Legend } from "@typography";
import {
  AGBOX_API_URL,
  AGBOX_API_KEY,
  STYLETYPE_ERROR,
  ROLES_URL,
  STATUS_READY,
  STATUS_CREATING,
  CONTENT_VIEW,
  BUTTON_TYPE_PRIMARY,
  CONTEXT_HEADER_CONTAINER,
  STYLETYPE_FORM_FIELD_FLEX,
  STYLETYPE_FORM_FIELD,
  PROPERTY_GROUP_TEXT,
  PROPERTY_TEXT,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  BUTTON_TYPE_SECONDARY,
  HEIGHT_AUTO_TEXT,
  DEVICE_MOBILE,
  ORGANISATION_URL
} from "@base/constants";
import {
  Loader,
  RadioGroup,
  ContextHeader,
  defaultTheme,
  BottomButtonBar,
  DataTable,
  ToggleSwitch
} from "@UIKit";
import { navigate } from "@reach/router";
import AgBoxApiRequests from "../../../apis/agbox/requests";
const { requestUserMembership, requestRole } = AgBoxApiRequests(
  AGBOX_API_URL,
  AGBOX_API_KEY
);

class AddRole extends Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      roleName: "",
      propertyGroupWorkflows: [],
      propertyWorkflows: [],
      propertyGroupWorkflowsToggleAll: false,
      propertyWorkflowsToggleAll: false,
      propertyWorkflowsHeader: false,
      propertyGroupWorkflowsHeader: false,
      propertyPageNumber: 1,
      propertyGroupPageNumber: 1,
      propertyGroupDefaultWorkflow: "None",
      propertyDefaultWorkflow: "None",
      currentUserAdminAccess: false,
      adminConsoleAccessEnabled: false
    };
  }

  componentDidMount() {
    this.loadDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  componentDidUpdate(prevProps) {
    const {
      pageTitle,
      setPageTitle,
      getLabel,
      selectedRoleStatus,
      selectedOrgWorkflowsLoading
    } = this.props;

    if (
      pageTitle !== getLabel("ADD_NEW_LABEL", { item: getLabel("ROLE_TEXT") })
    ) {
      setPageTitle(getLabel("ADD_NEW_LABEL", { item: getLabel("ROLE_TEXT") }));
    }

    if (prevProps.selectedOrgWorkflowsLoading && !selectedOrgWorkflowsLoading) {
      this.updateWorkflowsState();
    }

    if (
      prevProps.selectedRoleStatus === STATUS_CREATING &&
      selectedRoleStatus === STATUS_READY
    ) {
      this.confirmCreateSuccess();
    }
  }

  updateWorkflowsState = () => {
    const { selectedOrgWorkflows } = this.props;
    this.setState({
      propertyGroupWorkflows: selectedOrgWorkflows
        .filter((item) => item.type === PROPERTY_GROUP_TEXT)
        .map((workflow) => {
          return {
            ...workflow,
            expanded: false,
            checked: false,
            isDefault: false,
            allPermissionsChecked: false,
            permissions: workflow.permissions.map((permission) => {
              return {
                permissionTitle: permission,
                checked: false
              };
            })
          };
        }),

      propertyWorkflows: selectedOrgWorkflows
        .filter((item) => item.type === PROPERTY_TEXT)
        .map((workflow) => {
          return {
            ...workflow,
            expanded: false,
            checked: false,
            isDefault: false,
            allPermissionsChecked: false,
            permissions: workflow.permissions.map((permission) => {
              return {
                permissionTitle: permission,
                checked: false
              };
            })
          };
        })
    });
  };

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  loadDetails = () => {
    const { orgId, loadOrganisationRoles, loadOrganisationWorkflows } =
      this.props;
    loadOrganisationRoles(orgId);
    loadOrganisationWorkflows(orgId);
    this.userHasAdminCreatePermission();
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("ADD_NEW_LABEL", { item: getLabel("ROLE_TEXT") });
  };

  isInvalid = () => {
    return this.valueIsEmpty("roleName") || this.getOrgRoleNameExists();
  };

  addAnother = () => {
    const { hideModal } = this.props;
    this.scrollToTop();
    this.resetState();
    hideModal();
  };

  returnToOverviewAction = () => {
    const { hideModal, orgId } = this.props;

    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${ROLES_URL}/`);
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("CREATE_ACTION_LABEL"),
        item: getLabel("ROLE_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  getSelectedWorkflowsData = (workflow, defaultWorkflow) => {
    return workflow
      .filter((workflow) => workflow.checked)
      .reduce((result, attr) => {
        const { workflowId, permissions } = attr;
        const isDefault = defaultWorkflow === workflowId ? true : false;
        const checkedPermissions = permissions
          .filter((permission) => permission.checked)
          .map((permission) => {
            return {
              [permission.permissionTitle]: true
            };
          });
        const workflowPermissions = Object.assign({}, ...checkedPermissions);
        if (isDefault && Object.keys(workflowPermissions).length !== 0) {
          return [...result, { workflowId, isDefault, workflowPermissions }];
        } else if (Object.keys(workflowPermissions).length !== 0) {
          return [...result, { workflowId, workflowPermissions }];
        } else if (isDefault) {
          return [...result, { workflowId, isDefault }];
        } else {
          return [...result, { workflowId }];
        }
      }, []);
  };

  getRoleData = () => {
    const {
      propertyWorkflows,
      propertyGroupWorkflows,
      propertyGroupDefaultWorkflow,
      propertyDefaultWorkflow
    } = this.state;
    const selectedPropertyGroupWorkflows = this.getSelectedWorkflowsData(
      propertyGroupWorkflows,
      propertyGroupDefaultWorkflow
    );
    const selectedPropertyWorkflows = this.getSelectedWorkflowsData(
      propertyWorkflows,
      propertyDefaultWorkflow
    );
    return [...selectedPropertyGroupWorkflows, ...selectedPropertyWorkflows];
  };

  confirmCreateSuccess = async () => {
    await this.callUpdateRoleAdmin();
    this.showCreateSuccess();
  };

  callUpdateRoleAdmin = async () => {
    const { currentUserAdminAccess, adminConsoleAccessEnabled } = this.state;
    const { orgId, selectedRole, updateRoleAdmin } = this.props;
    if (currentUserAdminAccess && adminConsoleAccessEnabled) {
      const roleId = selectedRole.roleId;
      try {
        const data = JSON.stringify({
          adminConsoleAccess: {
            adminAccess: true,
            adminRole: {
              create: true,
              update: true,
              read: true,
              delete: false
            }
          }
        });
        updateRoleAdmin(orgId, roleId, data);
      } catch (e) {
        throw new Error(e);
      }
    }
    return;
  };

  callCreateRole = async () => {
    try {
      const { roleName } = this.state;
      const { createRole, showModal, orgId } = this.props;
      const workflowsData = this.getRoleData();
      const data = {
        title: roleName,
        permissions: {
          create: true,
          read: true,
          update: true
        },
        workflows: workflowsData
      };
      this.showLoading();
      showModal();
      createRole(orgId, data);
    } catch (e) {
      throw new Error(e);
    }
  };

  showCreatingModal = () => {
    const { showModal, setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("CREATING_LABEL"),
        item: getLabel("ROLE_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    showModal();
  };

  showCreateSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_CREATED_LABEL", {
        item: getLabel("ROLE_TEXT")
      }),
      body: getLabel("ITEM_CREATED_SUCCESS", {
        item: getLabel("ROLE_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("ADD_ANOTHER_LABEL", {
            item: getLabel("ROLE_TEXT")
          }),
          onClick: this.addAnother
        },
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("ROLE_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  returnToRoleOverview = () => {
    const { orgId } = this.props;
    navigate(`/${ORGANISATION_URL}/${orgId}/${ROLES_URL}`);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        isDisabled: this.isInvalid() || this.getRoleData().length === 0,
        onClick: this.callCreateRole,
        buttonLabel: getLabel("ADD_NEW_LABEL", { item: getLabel("ROLE_TEXT") }),
        icon: {
          type: "plus",
          bgWidth: "25px",
          bgHeight: "15px",
          iconHeight: "16px",
          iconWidth: "15px",
          iconColor: defaultTheme.agWhite
        }
      },
      {
        onClick: this.returnToRoleOverview,
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
          item: getLabel("ROLE_TEXT")
        })
      }
    ];
  };

  valueIsEmpty = (field) => {
    const value = this.getValue(field);
    return (
      value === null ||
      value === undefined ||
      value === "" ||
      (value && !value.length)
    );
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  getOrganisationWorkflows = () => {
    const { selectedOrgWorkflows } = this.props;
    return selectedOrgWorkflows ? selectedOrgWorkflows : false;
  };

  getWorkflowsByType = (type) => {
    const workflows = this.getOrganisationWorkflows();
    if (workflows) {
      return workflows.filter((item) => item.type === type);
    }
  };

  isDetailsLoading = () => {
    const {
      selectedOrgWorkflows,
      selectedOrgWorkflowsLoading,
      selectedOrganisationRolesLoading
    } = this.props;
    return (
      !selectedOrgWorkflows ||
      selectedOrgWorkflowsLoading ||
      selectedOrganisationRolesLoading
    );
  };

  getWorkflowsList = (type) => {
    const pageNumberState = this.state[`${type}PageNumber`];
    const workflowsState = this.state[`${type}Workflows`];
    const pageLimit = this.getPageLimit(type);
    if (!workflowsState) return [];
    const lastProperty = pageNumberState * pageLimit;
    const firstProperty = lastProperty - pageLimit;
    const currentPropertyList = workflowsState.slice(
      firstProperty,
      lastProperty
    );
    return currentPropertyList;
  };

  getItemLabel = (type) => {
    const { getLabel } = this.props;
    return getLabel(
      type === PROPERTY_TEXT ? "PROPERTY_TEXT" : "PROPERTY_GROUP_TEXT"
    );
  };

  getWorkflowsHeader = (type) => {
    const { getLabel } = this.props;
    return {
      toggleOpen: this.getWorkflowsToggleOpen(type),
      setToggleOpen: () => this.setWorkflowsToggleOpen(type),
      showToggle: true,
      cells: [
        { content: this.getWorkflowsHeaderCheckbox(type) },
        { content: getLabel("DEVICE_LABEL") }
      ]
    };
  };

  getAllWorkflowsEnabled = (type) => {
    return this.state[`${type}WorkflowsHeader`];
  };

  getWorkflowsHeaderCheckbox = (type) => {
    const { getLabel } = this.props;
    const value = this.getAllWorkflowsEnabled(type);
    return (
      <CheckboxV2
        handleUpdate={() => this.handleCheckWorkflowsHeader(type)}
        name={`${type}-workflows-header`}
        rowId={`${type}-workflows-header`}
        value={value}
        checked={value}
        title={getLabel("ENABLE_ALL_WORKFLOWS_LABEL", {
          item: this.getItemLabel(type)
        })}
      />
    );
  };

  handleCheckWorkflowsHeader = (type) => {
    const allWorkflowsEnabled = this.getAllWorkflowsEnabled(type);
    const headerValue = `${type}WorkflowsHeader`;
    const workflowType = `${type}Workflows`;

    this.setState({
      [headerValue]: !allWorkflowsEnabled,
      [workflowType]: this.state[`${type}Workflows`].map((item) => ({
        ...item,
        checked: !allWorkflowsEnabled,
        allPermissionsChecked: !allWorkflowsEnabled,
        permissions: item.permissions.map((permission) => ({
          ...permission,
          checked: !allWorkflowsEnabled
        }))
      }))
    });
  };

  getWorkflowsToggleOpen = (type) => {
    return this.state[`${type}WorkflowsToggleAll`];
  };

  setWorkflowsToggleOpen = (type) => {
    const toggleAllWorkflows = `${type}WorkflowsToggleAll`;
    const workflowType = `${type}Workflows`;
    const workflowTypeState = this.state[`${type}Workflows`];

    this.setState(
      { [toggleAllWorkflows]: !this.getWorkflowsToggleOpen(type) },
      () => {
        const newState = workflowTypeState.map((item) => ({
          ...item,
          expanded: this.getWorkflowsToggleOpen(type)
        }));
        this.setValue(newState, workflowType);
      }
    );
  };

  getWorkflowsData = (type) => {
    const workflows = this.getWorkflowsList(type);
    return workflows.map((workflow) => {
      return {
        id: workflow.workflowId,
        cells: [this.getWorkflowsCheckbox(workflow, type), workflow.device],
        expanded: this.getWorkflowsExpanded(workflow, type),
        handleUpdateRowExpanded: () =>
          this.updateWorkflowsRowExpanded(workflow.workflowId, type),
        content: this.renderWorkflowsOptions(workflow, type)
      };
    });
  };

  getWorkflowsCheckbox = (workflow, type) => {
    return (
      <CheckboxV2
        handleUpdate={() => this.handleCheckWorkflows(workflow, type)}
        name={`${type}-workflows`}
        rowId={workflow.workflowId}
        checked={workflow.checked}
        title={workflow.title}
      />
    );
  };

  handleCheckWorkflows = (workflow, type) => {
    const workflowsType = `${type}Workflows`;
    const workflowsTypeHeader = `${type}WorkflowsHeader`;
    const workflowsState = this.state[`${type}Workflows`];

    const newState = workflowsState.map((item) => {
      const permissions = item.permissions.map((permission) => ({
        ...permission,
        checked: !item.checked
      }));
      if (item.workflowId === workflow.workflowId) {
        return {
          ...item,
          checked: !item.checked,
          allPermissionsChecked: !item.checked,
          permissions
        };
      }
      return item;
    });
    this.setState({
      [workflowsType]: newState,
      [workflowsTypeHeader]: newState.every((item) => item.checked)
    });
  };

  getWorkflowsExpanded = (workflow) => {
    if (workflow.permissions.length === 0) return false;
    return workflow.expanded;
  };

  updateWorkflowsRowExpanded = (id, type) => {
    const workflowsType = `${type}Workflows`;
    const workflowsState = this.state[`${type}Workflows`];
    const toggleAllWorkflows = `${type}WorkflowsToggleAll`;

    const updatedWorkflows = workflowsState.map((workflow) => {
      if (workflow.workflowId !== id) return workflow;
      return {
        ...workflow,
        expanded: !workflow.expanded
      };
    });

    this.setState(
      {
        [workflowsType]: updatedWorkflows
      },
      () => {
        const propsWorkflowsExpanded = this.getWorkflowsList(type)
          .filter((item) => item.permissions.length !== 0)
          .every((item) => item.expanded);
        this.setValue(propsWorkflowsExpanded, toggleAllWorkflows);
      }
    );
  };

  renderWorkflowsOptions = (workflow, type) => {
    const { getLabel } = this.props;
    if (!workflow.permissions || !workflow.permissions.length) return null;
    return (
      <Fieldset>
        <DataTable
          title={getLabel("ENABLE_WORKFLOW_PERMISSIONS_LABEL", {
            item: workflow.title
          })}
          TitleComponent={Legend}
          header={this.getWorkflowsPermissionsHeader(workflow, type)}
          rows={this.getWorkflowsPermissionsData(workflow, type)}
          pagination={{ showPagination: false }}
          pageDropDown={{ showDropdown: false }}
          innerStyleType={"inner-accordion"}
          id={`${workflow.workflowId}-permissions`}
        />
      </Fieldset>
    );
  };

  getWorkflowsPermissionsHeader = (workflow, type) => {
    const { getLabel } = this.props;
    return {
      cells: [
        {
          content: (
            <CheckboxV2
              handleUpdate={() =>
                this.handleUpdateWorkfowsPermissions(workflow, type)
              }
              name={`${workflow.workflowId}-permissions`}
              rowId={`${workflow.workflowId}-permissions`}
              value={workflow.allPermissionsChecked}
              checked={workflow.allPermissionsChecked}
              title={getLabel("ENABLE_FULL_PERMISSIONS_LABEL")}
            />
          )
        }
      ],
      showToggle: false,
      innerStyleType: "inner-accordion"
    };
  };

  handleUpdateWorkfowsPermissions = (workflow, type) => {
    const workflowsType = `${type}Workflows`;
    const workflowsState = this.state[`${type}Workflows`];
    const newState = workflowsState.map((item) => {
      if (item.workflowId === workflow.workflowId) {
        return {
          ...item,
          allPermissionsChecked: !item.allPermissionsChecked,
          permissions: item.permissions.map((permission) => ({
            ...permission,
            checked: !item.allPermissionsChecked
          }))
        };
      }
      return item;
    });
    this.setState({
      [workflowsType]: newState
    });
  };

  getWorkflowsPermissionsData = (workflow, type) => {
    if (workflow.permissions.length === 0) return [];
    return workflow.permissions.map((permission) => {
      return {
        cells: [
          this.getWorkflowsPermissionsCheckbox(workflow, permission, type)
        ],
        innerStyleType: "inner-accordion"
      };
    });
  };

  getWorkflowsPermissionsCheckbox = (workflow, permission, type) => {
    return (
      <CheckboxV2
        handleUpdate={() =>
          this.handleCheckWorkflowsPermissions(workflow, permission, type)
        }
        name={`${workflow.workflowId}-permissions`}
        rowId={`${workflow.workflowId}-permissions`}
        value={this.getValue("")}
        checked={permission.checked}
        title={permission.permissionTitle}
      />
    );
  };

  handleCheckWorkflowsPermissions = (workflow, permission, type) => {
    const workflowsType = `${type}Workflows`;
    const workflowsState = this.state[`${type}Workflows`];
    const newState = workflowsState.map((item) => {
      if (item.workflowId === workflow.workflowId) {
        const permissions = item.permissions.map((singlePermission) => {
          if (singlePermission.permissionTitle === permission.permissionTitle) {
            return { ...permission, checked: !singlePermission.checked };
          }
          return singlePermission;
        });

        return {
          ...item,
          permissions,
          allPermissionsChecked: permissions.every((item) => item.checked)
            ? true
            : false
        };
      }
      return item;
    });
    this.setState({
      [workflowsType]: newState
    });
  };

  getWorkflowsPagination = (type) => {
    return {
      setPaginationContent: (e) => this.setPaginationContent(e, type),
      activeNumber: this.getCurrentPageNumber(type),
      numberOfButtons: this.getPageLimit(type),
      totalResults: this.getWorkflowsByType(type).length,
      showPagination: this.showPagination(type),
      paginationInput: true
    };
  };

  showPagination = (type) => {
    return this.getWorkflowsByType(type).length > this.getPageLimit(type);
  };

  setPaginationContent = (value, type) => {
    this.updateWorkflowsExpandedState(type);
    this.setState({ [`${type}PageNumber`]: value });
  };

  getPageLimit = (type) => {
    const result = getSession(`role-${type}-workflows-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getCurrentPageNumber = (type) => this.state[`${type}PageNumber`];

  updateWorkflowsExpandedState = (type) => {
    const workflowsType = `${type}Workflows`;
    const workflowsState = this.state[`${type}Workflows`];
    const toggleAllWorkflows = `${type}WorkflowsToggleAll`;

    const updatedWorkflows = workflowsState.map((workflow) => {
      return {
        ...workflow,
        expanded: false
      };
    });
    this.setValue(updatedWorkflows, workflowsType);
    this.setValue(false, toggleAllWorkflows);
  };

  showDropdown = (type) => {
    return (
      this.getWorkflowsByType(type).length > this.getDropDownOptions()[0].value
    );
  };

  dropDownOnSelectFunction = (value, type) => {
    this.updateWorkflowsExpandedState(type);
    this.setState({ [`${type}PageNumber`]: 1 });
    storeSession(`role-${type}-workflows-${PAGINATION_KEY}`, value);
  };

  getDropDownLabel = (type) => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit(type)
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getPageDropDown = (type) => {
    return {
      showDropdown: this.showDropdown(type),
      dropDownValue: this.getPageLimit(type),
      dropDownLabel: this.getDropDownLabel(type),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: (e) => this.dropDownOnSelectFunction(e, type)
    };
  };

  getSelectedWorkflowsRadioButtonOptions = (type) => {
    const workflowsState = this.state[`${type}Workflows`];
    return workflowsState
      .filter((workflow) => workflow.device !== DEVICE_MOBILE)
      .filter((workflow) => workflow.checked)
      .map((workflow) => ({
        label: workflow.title,
        value: workflow.workflowId
      }));
  };

  getWorkflowsRadioButtons = (type) => {
    const { getLabel } = this.props;
    const defaultWorkflow = `${type}DefaultWorkflow`;
    const defaultWorkflowState = this.state[defaultWorkflow];

    const selectedWorkflowsByType =
      this.getSelectedWorkflowsRadioButtonOptions(type);
    const isWorkflowSelected = selectedWorkflowsByType.some(
      (el) => el.value === defaultWorkflowState
    );

    if (defaultWorkflowState !== "None" && !isWorkflowSelected) {
      this.setValue("None", defaultWorkflow);
    }

    if (
      selectedWorkflowsByType.length === 0 &&
      defaultWorkflowState !== "None"
    ) {
      this.setValue("None", defaultWorkflow);
      return [
        {
          label: getLabel("NONE_LABEL"),
          value: getLabel("NONE_LABEL")
        }
      ];
    }
    return [
      {
        label: getLabel("NONE_LABEL"),
        value: getLabel("NONE_LABEL")
      },
      ...selectedWorkflowsByType
    ];
  };

  handleDefaultWorkflow = (event, type) => {
    const defaultWorkflow = `${type}DefaultWorkflow`;
    this.setValue(event, defaultWorkflow);
  };

  renderWorkflowsRadioOptions = (type) => {
    const { getLabel } = this.props;
    const defaultWorkflow = `${type}DefaultWorkflow`;
    const radioButtonOptions = this.getWorkflowsRadioButtons(type);
    return (
      <RadioGroup
        legend={getLabel("DEFAULT_WORKFLOW_LABEL", {
          item: getLabel(
            type === PROPERTY_TEXT ? "PROPERTY_TEXT" : "PROPERTY_GROUP_TEXT"
          )
        })}
        value={this.getValue(defaultWorkflow)}
        isDisabled={false}
        name={defaultWorkflow}
        radioButtons={radioButtonOptions}
        onClick={(event) => this.handleDefaultWorkflow(event, type)}
      />
    );
  };

  getOrgRoleTitles = () => {
    const { selectedOrganisationRoles } = this.props;
    if (!selectedOrganisationRoles) return false;
    return selectedOrganisationRoles.map((item) => item.title.toLowerCase());
  };

  getOrgRoleNameExists = () => {
    const roleTitles = this.getOrgRoleTitles();
    if (!roleTitles) return false;
    const title = this.getValue("roleName");
    return roleTitles.includes(title.toLowerCase());
  };

  getValidationError = () => {
    const { getLabel } = this.props;
    return this.valueIsEmpty("roleName")
      ? getLabel("ROLE_NAME_HELPTEXT")
      : this.getOrgRoleNameExists()
      ? getLabel("ROLE_EXISTS_LABEL")
      : null;
  };

  scrollToTop = () => window.scrollTo({ top: 0 });

  resetState = () => {
    this.updateWorkflowsState();
    this.setState({
      roleName: "",
      propertyPageNumber: 1,
      propertyGroupPageNumber: 1,
      propertyGroupDefaultWorkflow: "None",
      propertyDefaultWorkflow: "None"
    });
  };

  setAdminConsoleAccess = () => {
    this.setValue(
      !this.getValue("adminConsoleAccessEnabled"),
      "adminConsoleAccessEnabled"
    );
  };

  renderAdminConsoleAccessOptions = () => {
    const { adminConsoleAccessEnabled } = this.state;
    const { getLabel } = this.props;
    const adminAccessLabel = getLabel("ADMIN_CONSOLE_ACCESS_TOGGLE_LABEL", {
      status: adminConsoleAccessEnabled
        ? getLabel("ENABLED_LABEL")
        : getLabel("DISABLED_LABEL")
    });

    return (
      <ToggleSwitch
        id={"admin-widget-label"}
        label={adminAccessLabel}
        isToggled={this.getValue("adminConsoleAccessEnabled")}
        toggleActiveStatus={this.setAdminConsoleAccess}
      />
    );
  };

  userHasAdminCreatePermission = async () => {
    try {
      const { user, token, orgId } = this.props;
      const userId = user.userId;
      const userMembership = await requestUserMembership(
        orgId,
        userId,
        token,
        this.controller.signal
      );
      const { roleId } = userMembership;
      const role = await requestRole(
        orgId,
        roleId,
        token,
        this.controller.signal
      );
      const hasAdminCreatePermission = role.adminConsoleAccess.adminRole.create;
      if (hasAdminCreatePermission)
        this.setState({ currentUserAdminAccess: hasAdminCreatePermission });
    } catch (error) {
      throw new Error(error);
    }
  };

  getCurrentUserAdminAccess = () => {
    const { currentUserAdminAccess } = this.state;
    return currentUserAdminAccess;
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isDetailsLoading() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
              />
            </Container>

            <Container>
              <Container
                styleType={STYLETYPE_FORM_FIELD_FLEX}
                direction={"row"}
              >
                <Container>
                  <Heading4>
                    {getLabel("DETAILS_LABEL", {
                      item: getLabel("ROLE_TEXT")
                    })}
                  </Heading4>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      htmlFor="role-name"
                      styleType={this.isInvalid() ? STYLETYPE_ERROR : ""}
                    >
                      {getLabel("FORM_LABEL_NAME_OF_ROLE")}
                    </FormLabel>
                    <FormInput
                      value={this.getValue("roleName")}
                      onChange={(value) => {
                        this.setValue(value, "roleName");
                      }}
                      styleType={this.isInvalid() ? STYLETYPE_ERROR : ""}
                      id="role-name"
                      required={true}
                      ariaDescribedBy={
                        this.getValidationError() && "role-name-error"
                      }
                    />
                    {this.getValidationError() && (
                      <P styleType={STYLETYPE_ERROR} id="role-name-error">
                        {this.getValidationError()}
                      </P>
                    )}
                  </Container>
                </Container>
              </Container>

              {this.getCurrentUserAdminAccess() && (
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <Fieldset>
                    <Legend>{getLabel("ADMIN_CONSOLE_ACCESS_LABEL")}</Legend>
                    {this.renderAdminConsoleAccessOptions()}
                  </Fieldset>
                </Container>
              )}

              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Fieldset>
                  <DataTable
                    title={getLabel("ENABLE_WORKFLOWS_LABEL", {
                      item: this.getItemLabel(PROPERTY_GROUP_TEXT)
                    })}
                    TitleComponent={Legend}
                    header={this.getWorkflowsHeader(PROPERTY_GROUP_TEXT)}
                    rows={this.getWorkflowsData(PROPERTY_GROUP_TEXT)}
                    pagination={this.getWorkflowsPagination(
                      PROPERTY_GROUP_TEXT
                    )}
                    pageDropDown={this.getPageDropDown(PROPERTY_GROUP_TEXT)}
                    styleType={DATA_TABLE_BUTTON_TITLE_STYLING}
                    id={"property-group-workflows"}
                  />
                </Fieldset>
              </Section>

              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Fieldset>
                  <DataTable
                    title={getLabel("ENABLE_WORKFLOWS_LABEL", {
                      item: this.getItemLabel(PROPERTY_TEXT)
                    })}
                    TitleComponent={Legend}
                    header={this.getWorkflowsHeader(PROPERTY_TEXT)}
                    rows={this.getWorkflowsData(PROPERTY_TEXT)}
                    pagination={this.getWorkflowsPagination(PROPERTY_TEXT)}
                    pageDropDown={this.getPageDropDown(PROPERTY_TEXT)}
                    styleType={DATA_TABLE_BUTTON_TITLE_STYLING}
                    id={"property-workflows"}
                  />
                </Fieldset>
              </Section>

              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <Fieldset>
                    {this.renderWorkflowsRadioOptions(PROPERTY_GROUP_TEXT)}
                  </Fieldset>
                </Container>
              </Section>

              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <Fieldset>
                    {this.renderWorkflowsRadioOptions(PROPERTY_TEXT)}
                  </Fieldset>
                </Container>
              </Section>

              {this.getRoleData().length === 0 && (
                <P styleType={STYLETYPE_ERROR} id="workflow-length-error">
                  {getLabel("ADD_ROLE_WORKFLOWS_ERROR_LABEL")}
                </P>
              )}
              <BottomButtonBar buttons={this.getButtons()} />
            </Container>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default AddRole;
