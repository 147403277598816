import styled from "styled-components";
import { STYLETYPE_ERROR } from "@base/constants";

export const AttachmentContainer = styled.div``;
AttachmentContainer.displayName = "AttachmentContainer";

export const AttachmentListWrapper = styled.ul`
  list-style-type: none;
  padding-left: 0;
  button {
    border: none;
  }
`;
AttachmentListWrapper.displayName = "AttachmentListWrapper";

export const AttachmentListItem = styled.li`
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;
AttachmentListItem.displayName = "AttachmentListItem";

export const AttachmentListItemLink = styled.a``;
AttachmentListItemLink.displayName = "AttachmentListItemLink";

export const DropzoneWrapper = styled.div`
  width: 100%;
  height: auto;
  border: ${(props) =>
    props.styleType === STYLETYPE_ERROR
      ? `2px solid ${props.theme.agRed}`
      : `1px solid ${props.theme.agLighterGray}`};
`;
DropzoneWrapper.displayName = "DropzoneWrapper";

export const DropzoneContainer = styled.div`
  padding: 4rem 3rem;
  background-color: ${(props) => props.theme.agOffWhite};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & p {
    text-align: center;
    text-shadow: 0.0625rem 0.0625rem 0rem #fff;
    font-weight: 400;
    color: ${(props) => props.theme.agDarkestGray};
    font-size: 1rem;
    margin-top: 0.3rem;
  }
  & span {
    font-size: 0.9rem;
  }
`;
DropzoneContainer.displayName = "DropzoneContainer";
