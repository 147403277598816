export const defaultTheme = {
  agWhite: "#ffffff",
  agSmokeWhite: "#F5F2F2",
  agTransparentWhite: "rgba(255, 255, 255, 0.8)",
  agOffWhite: "#f8f8f8",
  agMischkaGray: "#A7A9AC",
  agDimGray: "#707070",
  agLightShadeGray: "#F6F6F6",
  agLightestGray: "#efefef",
  agLighterGray: "#ccc",
  agLighterShadeGray: "#e7e7e8",
  agLightGray: "#a9a9a9",
  agGray: "#959595",
  agDarkGray: "#828282",
  agDarkerGray: "#6e6e6e",
  agDarkestGray: "#595959",
  agOffBlack: "#4c4c4c",
  agTransparentBlack: "rgba(0, 0,0 , 0.75)",
  agTransparentLightBlack: "rgba(41, 43, 53, 0.6)",
  agBlack: "#323232",
  agTrueBlack: "#000000",

  agLightestBlue: "#d2e9f9",
  agLighterBlue: "#bcdaed",
  agLightBlue: "#56a5d8",
  agTransparentLightBlue: "rgba(0, 0, 200, 0.12)",
  agBlue: "#0079c1",
  agDarkBlue: "#005e95",
  agBrightGreen: "#00ab7a",
  agLightGreen: "#9bc19c",
  agLighterGreen: "#73C69A",
  agLightShadeGreen: "#6bcdb2",
  agGreen: "#00ab7a",
  agDarkerGreen: "#00ab70",
  agDarkGreen: "#338033",
  agLightRed: "#e4a793",
  agLighterRed: "#F04923",
  agOffRed: "#f3ded7",
  agErrorRed: "#c86a4a",
  agRed: "#de2900",
  agDarkRed: "#ab3c16",
  agLightOrange: "#e8912e",
  agOrange: "#d17e21",
  agDarkOrange: "#ad6511",
  agLightYellow: "#efe397",
  agYellow: "#e4d154",
  agDarkYellow: "#d9bf2b",
  agLightPurple: "#c4bcdc",
  agPurple: "#9081bc",
  agDarkPurple: "#7461a8",
  agLightBrown: "#d2b38f",
  agBrown: "#a57946",
  agDarkBrown: "#8c5e2a",
  agLink: "#4f57a6",
  agCommonBlack: "#626466",

  adminPrimary: "#33E54B",
  adminSecondary: "#33C7E5",
  adminTeritary: "#917FDB",

  fontSizeSmallest: "0.8125rem",
  fontSizeSmaller: "0.875rem",
  fontSizeSmall: " 0.9375rem",
  fontSizeBase: "16px",
  fontSizeOne: "1.125rem",
  fontSizeTwo: "1.8rem",
  fontSizeThree: "2.9375rem",
  fontSizeFour: "1.7rem",

  lineHeightSmallest: "1em",
  lineHeightSmaller: "1.2em",
  lineHeightBase: "1.5em",
  lineHeightLarger: "1.8em",
  lineHeightLargest: "2.5em",

  baseBorderRadiusStandard: "0.3rem",

  baseFontWeightExtaLight: "200",
  baseFontWeight: "300",
  baseFontWeightRegular: "400",
  baseFontWeightHeavy: "600",
  baseFont: '"Poppins", sans-serif',
  baseFontAlternative: '"Poppins", sans-serif',

  basePadding: 5,

  iconWidth: "40px",
  iconHeight: "40px",
  imgWidth: "60px",
  imgPadding: "8px"
};
