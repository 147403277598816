import styled, { css } from "styled-components";
import {
  STYLETYPE_ERROR,
  DROPDOWN_ID,
  PAGINATION_STYLING
} from "@base/constants";

// @ts-ignore
export const FormInputElement = styled.input`
  width:calc(100% - 1.2rem);
  font-size: ${(props) => props.theme.fontSizeSmall}
  min-height:1.875rem;
  height:auto;
  border: 1px solid ${(props) => props.theme.agLighterGray};
  padding: 0.2rem 0.6rem;

  ${(props) =>
    props.styleType === STYLETYPE_ERROR &&
    css`
      border: 2px solid ${props.theme.agRed};
    `}

  ${(props) =>
    props.styleType === PAGINATION_STYLING &&
    css`
      min-width: 2.5rem;
      width: ${props.width}rem;
      height: 2.5rem;
      padding: 0 0.3rem;
      font-size: 1.5rem;
    `}
`;
FormInputElement.displayName = "FormInputElement";
