import React, { Component } from "react";
import { ContainerElement } from "./style";
import PropTypes from "prop-types";
import { defaultTheme } from "../../defaultTheme";

/** Renders a container to hold content, by default it's set as flex, in column. Should always be wrapper by a Wrapper component */
class Container extends Component {
  static propTypes = {
    /** The css color (rgb, hex, color name) for the background of the container */
    bgColor: PropTypes.string,
    direction: PropTypes.oneOf(["column", "row"]),
    /** The variant of Container styled component needed, such as ApplicationHeader (optional) */
    styleType: PropTypes.string
  };
  static defaultProps = {
    bgColor: defaultTheme.agWhite,
    direction: "column"
  };
  render() {
    const { styleType } = this.props;

    return (
      <ContainerElement
        {...this.props}
        data-name={"Container"}
        styleType={styleType ? styleType : ""}
      />
    );
  }
}

export default Container;
