import React from "react";
import { Heading2Element } from "./style";
import PropTypes from "prop-types";

/** Renders Heading2 Element - Use for subheadings*/
const Heading2 = (props) => {
  return <Heading2Element>{props.children}</Heading2Element>;
};

Heading2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default Heading2;
