export const getIsAuthenticated = (state) => state.login.isAuthenticated;
export const getUserLoginId = (state) => state.login.userId;
export const getToken = (state) => state.login.token;
export const getLoginError = (state) => state.login.error;
export const getOAuthInfoModule = (state) => state.app.esriModules.OAuthInfo;

export const getUserLogin = (state) => state.login.user;
export const getUserNameLogin = (state) => state.login.user.name;
export const getUserLoadingLogin = (state) => state.login.user.isLoading;
export const getAuthzeroClient = (state) => state.login.authClient;
export const getUserSuperHuman = (state) => state.login.user.superHuman;
export const getLoggingOut = (state) => state.login.loggingOut;
