export const getSelectedProperty = (state) => {
  return state.property && state.property.selectedProperty
    ? state.property.selectedProperty
    : false;
};

export const getSelectedPropertyStatus = (state) => {
  return state.property && state.property.status
    ? state.property.status
    : false;
};

export const getSelectedPropertyWorkflows = (state) => {
  return state.property &&
    state.property.selectedPropertyWorkflows &&
    state.property.selectedPropertyWorkflows.available
    ? state.property.selectedPropertyWorkflows.available
    : false;
};

export const getSelectedPropertyWorkflowsLoading = (state) => {
  return state.property && state.property.selectedPropertyWorkflows
    ? state.property.selectedPropertyWorkflows.isLoading
    : false;
};

export const getSelectedPropertyPropertyGroups = (state) => {
  return state.property &&
    state.property.selectedPropertyPropertyGroups &&
    state.property.selectedPropertyPropertyGroups.available
    ? state.property.selectedPropertyPropertyGroups.available
    : false;
};

export const getSelectedPropertyPropertyGroupsLoading = (state) => {
  return state.property && state.property.selectedPropertyPropertyGroups
    ? state.property.selectedPropertyPropertyGroups.isLoading
    : false;
};

export const getSelectedPropertyUsersLoading = (state) => {
  return state.property && state.property.selectedPropertyUsers
    ? state.property.selectedPropertyUsers.isLoading
    : false;
};

export const getSelectedPropertyUsers = (state) => {
  return state.property &&
    state.property.selectedPropertyPropertyGroups &&
    state.property.selectedPropertyUsers.available
    ? state.property.selectedPropertyUsers.available
    : false;
};

export const getSelectedPropertyUsersCount = (state) => {
  return state.property &&
    state.property.selectedPropertyUsers &&
    state.property.selectedPropertyUsers.count
    ? state.property.selectedPropertyUsers.count
    : false;
};

export const getSelectedPropertyError = (state) => {
  return state.property && state.property.propertyError
    ? state.property.propertyError
    : false;
};

export const getCreatedProperties = (state) => {
  return state.property.createdProperties;
};
