import React, { Component } from "react";
import {
  Container,
  Wrapper,
  FormLabel,
  FormInput,
  Section,
  Fieldset
} from "@core";
import {
  Loader,
  ContextHeader,
  RadioGroup,
  BottomButtonBar,
  Dropdown
} from "@UIKit";
import {
  CONTENT_VIEW,
  STYLETYPE_ERROR,
  STYLETYPE_FORM_FIELD,
  STATUS_READY,
  STATUS_UPDATING,
  CONTEXT_HEADER_CONTAINER,
  BUTTON_TYPE_PRIMARY,
  BUTTON_TYPE_SECONDARY,
  STATUS_LOADING,
  HEIGHT_AUTO_TEXT,
  STYLETYPE_FORM_FIELD_FLEX,
  LANGUAGE_PREFERENCES_OPTIONS,
  BUTTON_TYPE_PRIMARY_FULLWIDTH,
  BUTTON_TYPE_SECONDARY_FULLWIDTH
} from "@base/constants";
import { navigate } from "@reach/router";

class UpdateSuperHumanUsersDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      language: "",
      superHuman: false
    };
  }

  componentDidMount() {
    this.loadUserDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  loadUserDetails = () => {
    const { userId, loadUser } = this.props;
    loadUser(userId);
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedUserStatus, landkindUserStatus } = this.props;
    if (
      prevProps.selectedUserStatus === STATUS_LOADING &&
      selectedUserStatus === STATUS_READY
    ) {
      this.setupUserDetails();
    }
    if (
      prevProps.landkindUserStatus === STATUS_UPDATING &&
      landkindUserStatus === STATUS_READY
    ) {
      this.showSuccess();
    }
    if (
      prevState.superHuman === false &&
      this.state.superHuman &&
      !this.isUserSuperHuman()
    ) {
      this.showSuperHumanChangeModal();
    }
  }

  setupUserDetails = () => {
    const { selectedUser } = this.props;
    const { firstName, lastName } = selectedUser;
    const superHuman = selectedUser && selectedUser.superHuman ? true : false;
    const preferences = selectedUser.preferences || {};
    const language = selectedUser.language || preferences.language || "English";
    this.setState({
      firstName,
      lastName,
      language,
      superHuman
    });
    return;
  };

  isUserSuperHuman = () => {
    const { selectedUser } = this.props;
    return selectedUser && selectedUser.superHuman
      ? selectedUser.superHuman
      : false;
  };

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  showSuperHumanChangeModal = () => {
    const { showModal, setModalContent, getLabel } = this.props;
    showModal();
    setModalContent({
      showCloseButton: false,
      header: getLabel("ENABLE_SUPERHUMAN_ACCESS_LABEL"),
      body: getLabel("ENABLE_SUPERHUMAN_ACCESS_BODY"),
      buttons: [
        {
          buttonLabel: getLabel("ENABLE_SUPERHUMAN_LABEL"),
          onClick: () => this.updateSuperHumanAction(true),
          buttonStyleType: BUTTON_TYPE_PRIMARY_FULLWIDTH
        },
        {
          buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
          onClick: () => this.updateSuperHumanAction(false),
          buttonStyleType: BUTTON_TYPE_SECONDARY_FULLWIDTH
        }
      ]
    });
    return null;
  };

  updateSuperHumanAction = (value) => {
    const { hideModal } = this.props;
    this.setValue("superHuman", value);
    hideModal();
  };

  setValue = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  isUserDetailLoading = () => {
    const { selectedUserStatus } = this.props;
    return selectedUserStatus === STATUS_LOADING;
  };

  valueIsEmpty = (field) => {
    return !this.getValue(field);
  };

  handleLanguageSelection = (value) => {
    const language = LANGUAGE_PREFERENCES_OPTIONS.filter(
      (item) => item === value
    )[0];
    this.setState({ language });
  };

  getLanguageDropDownOptions = () => {
    return LANGUAGE_PREFERENCES_OPTIONS.map((value) => ({
      value: value,
      label: value
    }));
  };

  renderLanguageDropdown = () => {
    const { getLabel } = this.props;
    return (
      <Container>
        <FormLabel htmlFor="language-preference">
          {getLabel("LANGUAGE_PREFERENCE_LABEL")}
        </FormLabel>
        <Dropdown
          id={"language-preference"}
          placeholder={"Select option"}
          onClick={(e) => this.handleLanguageSelection(e.value)}
          label={this.getValue("language")}
          value={this.getValue("language")}
          options={this.getLanguageDropDownOptions()}
        />
      </Container>
    );
  };

  getRadioButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        label: getLabel("STANDARD_LABEL"),
        value: false
      },
      {
        label: getLabel("SUPERHUMAN_LABEL"),
        value: true
      }
    ];
  };

  returnToUserDetailAction = () => {
    const { hideModal } = this.props;
    hideModal();
    navigate(`./`);
  };

  callAction = async () => {
    const { userId, showModal, updateLandkindUser } = this.props;
    const { firstName, lastName, language, superHuman } = this.state;
    const data = {
      firstName,
      lastName,
      language,
      superHuman,
      preferences: {
        language
      }
    };
    this.showLoading();
    showModal();
    updateLandkindUser(userId, JSON.stringify(data));
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("UPDATING_LABEL"),
        item: getLabel("USER_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_UPDATED_LABEL", {
        item: getLabel("USER_TEXT")
      }),
      body: getLabel("ITEM_UPDATED_SUCCESS", {
        item: getLabel("USER_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_DETAILS", {
            item: getLabel("USER_TEXT")
          }),
          onClick: this.returnToUserDetailAction
        }
      ]
    });
    return null;
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        onClick: this.callAction,
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("USER_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        }),
        isDisabled: false
      },
      {
        onClick: this.returnToUserDetailAction,
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        buttonLabel: getLabel("RETURN_TO_DETAILS", {
          item: getLabel("USER_TEXT")
        })
      }
    ];
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isUserDetailLoading() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={getLabel("UPDATE_ACTION_HEADER_LABEL", {
                  item: getLabel("USER_TEXT")
                })}
                contextMenu={{ visible: false }}
              />
            </Container>
            <Container styleType={STYLETYPE_FORM_FIELD_FLEX} direction={"row"}>
              <Container>
                <Section>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      htmlFor={"firstName"}
                      styleType={
                        this.valueIsEmpty("firstName") ? STYLETYPE_ERROR : ""
                      }
                    >
                      {getLabel("FORM_FIRST_NAME_LABEL")}
                    </FormLabel>
                    <FormInput
                      id={"firstName"}
                      value={this.getValue("firstName")}
                      onChange={(value) => {
                        this.setValue("firstName", value);
                      }}
                      styleType={
                        this.valueIsEmpty("firstName") ? STYLETYPE_ERROR : ""
                      }
                    />
                  </Container>

                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      htmlFor={"lastName"}
                      styleType={
                        this.valueIsEmpty("lastName") ? STYLETYPE_ERROR : ""
                      }
                    >
                      {getLabel("FORM_LAST_NAME_LABEL")}
                    </FormLabel>
                    <FormInput
                      id={"lastName"}
                      value={this.getValue("lastName")}
                      onChange={(value) => {
                        this.setValue("lastName", value);
                      }}
                      styleType={
                        this.valueIsEmpty("lastName") ? STYLETYPE_ERROR : ""
                      }
                    />
                  </Container>

                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <Fieldset>{this.renderLanguageDropdown()}</Fieldset>
                  </Container>

                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <RadioGroup
                      legend={getLabel("ENABLE_SUPER_HUMAN_MODE_LABEL")}
                      direction={"vertical"}
                      value={this.getValue("superHuman")}
                      isDisabled={false}
                      name={"typeFilterRadioGroup"}
                      radioButtons={this.getRadioButtons()}
                      onClick={(event) => {
                        event = event == "true";
                        this.setValue("superHuman", event);
                      }}
                    ></RadioGroup>
                  </Container>
                </Section>
              </Container>
            </Container>
            <BottomButtonBar buttons={this.getButtons()} />
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default UpdateSuperHumanUsersDetail;
