import { connect } from "react-redux";
import PropertyGroupDetail from "./PropertyGroupDetail";
import * as actions from "../../../actions";
import {
  getLabels,
  getAvailableOrgs,
  getOrgsStatus,
  getSelectedOrgLoading,
  getSelectedOrg,
  getSelectedOrgPropertyGroups,
  getSelectedOrgPropertyGroupsLoading,
  getSelectedOrgPropertyGroupsTotal,
  getSelectedPropertyGroup,
  getPageTitle,
  getSelectedOrgUsers,
  getSelectedOrgUsersTotal,
  getSelectedOrgUsersLoading,
  getSelectedPropertyGroupUsers,
  getSelectedPropertyGroupUsersTotal,
  getSelectedPropertyGroupUsersIsLoading,
  getSelectedPropertyGroupProperties,
  getSelectedPropertyGroupPropertiesIsLoading,
  getPropertyGroupStatus,
  getAbortController,
  getSelectedPropertyGroupPagination,
  getSelectedPropertyGroupPropertiesTotal,
  getSelectedGroupUsersPageNumber,
  getSelectedGroupUsersSearch,
  getHasPermission
} from "../../../selectors";

import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    availableOrganisations: getAvailableOrgs(state),
    organisationsStatus: getOrgsStatus(state),
    organisationsLoading: getOrgsStatus(state) === STATUS_LOADING,
    selectedOrganisationLoading: getSelectedOrgLoading(state),
    selectedOrganisation: getSelectedOrg(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedOrganisationPropertyGroups: getSelectedOrgPropertyGroups(state),
    selectedOrganisationPropertyGroupsLoading:
      getSelectedOrgPropertyGroupsLoading(state),
    selectedOrganisationPropertyGroupsTotal:
      getSelectedOrgPropertyGroupsTotal(state),
    selectedPropertyGroup: getSelectedPropertyGroup(state),
    selectedPropertyGroupStatus: getPropertyGroupStatus(state),
    pageTitle: getPageTitle(state),
    selectedOrganisationUsers: getSelectedOrgUsers(state),
    selectedOrganisationUsersTotal: getSelectedOrgUsersTotal(state),
    selectedOrganisationUsersLoading: getSelectedOrgUsersLoading(state),
    selectedPropertyGroupUsers: getSelectedPropertyGroupUsers(state),
    selectedPropertyGroupUsersIsLoading:
      getSelectedPropertyGroupUsersIsLoading(state),
    properties: getSelectedPropertyGroupProperties(state),
    propertiesTotal: getSelectedPropertyGroupPropertiesTotal(state),
    propertiesLoading: getSelectedPropertyGroupPropertiesIsLoading(state),
    propertiesPagination: getSelectedPropertyGroupPagination(state),
    selectedPropertyGroupUsersTotal: getSelectedPropertyGroupUsersTotal(state),
    selectedPropertyGroupProperties: getSelectedPropertyGroupProperties(state),
    selectedPropertyGroupPropertiesIsLoading:
      getSelectedPropertyGroupPropertiesIsLoading(state),
    abortController: getAbortController(state),
    selectedGroupUsersPageNumber: getSelectedGroupUsersPageNumber(state),
    selectedGroupUsersSearch: getSelectedGroupUsersSearch(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType)
  }),
  actions
)(PropertyGroupDetail);
