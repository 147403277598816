import { createLogic } from "redux-logic";
import {
  LOAD_PROPERTY_GROUP,
  LOAD_PROPERTY_GROUP_USERS,
  LOAD_PROPERTY_GROUP_WORKFLOWS,
  CREATE_PROPERTY_GROUP,
  UPDATE_PROPERTY_GROUP,
  ABORT_ERROR_NAME,
  REMOVE_PROPERTY_GROUP,
  LOAD_PROPERTY_GROUP_PROPERTIES,
  PAGINATION_KEY,
  PAGINATION_OPTIONS,
  ADD_PROPERTIES_TO_GROUPS
} from "@base/constants";
import { pollJob, getSession } from "../common";

const loadPropertyGroupLogic = createLogic({
  type: LOAD_PROPERTY_GROUP,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;

      if (!action.payload) {
        const { PAYLOAD_IS_NULL_ERROR } = getLabels(state);
        throw PAYLOAD_IS_NULL_ERROR;
      }

      const { setLoadingText, loadedPropertyGroup, setPageTitle } =
        globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));
      dispatch(setPageTitle(""));

      const token = getToken(state);
      const { orgId, groupId, returnProperties } = action.payload;

      const { requestPropertyGroup } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const propertyGroup = await requestPropertyGroup(
        orgId,
        groupId,
        token,
        signal,
        returnProperties
      );
      dispatch(loadedPropertyGroup(propertyGroup));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadPropertyGroupUsersLogic = createLogic({
  type: LOAD_PROPERTY_GROUP_USERS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const {
        getToken,
        getAbortSignal,
        getLabels,
        getSelectedGroupUsersOffset,
        getSelectedGroupUsersSearch
      } = selectors;

      if (!action.payload) {
        const { PAYLOAD_IS_NULL_ERROR } = getLabels(state);
        throw PAYLOAD_IS_NULL_ERROR;
      }

      const { setLoadingText, loadedPropertyGroupUsers, setPageTitle } =
        globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));
      dispatch(setPageTitle(""));
      const token = getToken(state);
      const { orgId, groupId } = action.payload;
      const offset = getSelectedGroupUsersOffset(state)
        ? getSelectedGroupUsersOffset(state)
        : 0;
      const limit =
        getSession(`property-group-users-${PAGINATION_KEY}`) ||
        PAGINATION_OPTIONS[0];
      const search = getSelectedGroupUsersSearch(state)
        ? getSelectedGroupUsersSearch(state)
        : "";
      const { requestPropertyGroupUsers } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const propertyGroupUsers = await requestPropertyGroupUsers(
        orgId,
        groupId,
        token,
        signal,
        offset,
        limit,
        search
      );
      dispatch(loadedPropertyGroupUsers(propertyGroupUsers));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadPropertyGroupsWorkflowsLogic = createLogic({
  type: LOAD_PROPERTY_GROUP_WORKFLOWS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;

      if (!action.payload) {
        const { PAYLOAD_IS_NULL_ERROR } = getLabels(state);
        throw PAYLOAD_IS_NULL_ERROR;
      }

      const { setLoadingText, loadedPropertyGroupWorkflows } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);

      const { orgId, groupId } = action.payload;
      const { requestPropertyGroupWorkflows } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const propertyGroupWorkflows = await requestPropertyGroupWorkflows(
        orgId,
        groupId,
        token,
        signal
      );
      dispatch(loadedPropertyGroupWorkflows(groupId, propertyGroupWorkflows));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const createPropertyGroupLogic = createLogic({
  type: CREATE_PROPERTY_GROUP,
  async process(
    { globalActions, agBoxApiCreates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;

      if (!action.payload) {
        const { PAYLOAD_IS_NULL_ERROR } = getLabels(state);
        throw PAYLOAD_IS_NULL_ERROR;
      }

      const { setLoadingText, addPropertiesToGroups } = globalActions;
      const { CREATING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(CREATING_LABEL));

      const token = getToken(state);
      const { title, orgId, description, properties } = action.payload;
      const { createPropertyGroup } = agBoxApiCreates;
      const signal = getAbortSignal(getState());
      const { groupId } = await createPropertyGroup(
        orgId,
        JSON.stringify({ title, description }),
        token,
        signal
      );
      await dispatch(addPropertiesToGroups(orgId, [groupId], properties));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});
const updatePropertyGroupLogic = createLogic({
  type: UPDATE_PROPERTY_GROUP,
  async process(
    { globalActions, agBoxApiUpdates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;

      if (!action.payload) {
        const { PAYLOAD_IS_NULL_ERROR } = getLabels(state);
        throw PAYLOAD_IS_NULL_ERROR;
      }

      const { updatedPropertyGroup } = globalActions;
      const token = getToken(state);
      const { groupId, orgId, updates } = action.payload;
      const { updatePropertyGroup } = agBoxApiUpdates;
      const signal = getAbortSignal(getState());
      await updatePropertyGroup(updates, groupId, orgId, token, signal);
      dispatch(updatedPropertyGroup());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const removePropertyGroupLogic = createLogic({
  type: REMOVE_PROPERTY_GROUP,
  async process(
    { globalActions, agBoxApiRemoves, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;

      if (!action.payload) {
        const { PAYLOAD_IS_NULL_ERROR } = getLabels(getState());
        throw PAYLOAD_IS_NULL_ERROR;
      }

      const { removedPropertyGroup } = globalActions;
      const token = getToken(state);
      const { groupId, orgId } = action.payload;
      const { removePropertyGroup, requestRemovePropertyGroupStatus } =
        agBoxApiRemoves;
      const signal = getAbortSignal(getState());
      const { jobId } = await removePropertyGroup(
        orgId,
        groupId,
        token,
        signal
      );
      await pollJob(
        requestRemovePropertyGroupStatus,
        orgId,
        groupId,
        jobId,
        token,
        signal
      );
      dispatch(removedPropertyGroup());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadPropertyGroupPropertiesLogic = createLogic({
  type: LOAD_PROPERTY_GROUP_PROPERTIES,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;

      if (!action.payload) {
        const { PAYLOAD_IS_NULL_ERROR } = getLabels(state);
        throw PAYLOAD_IS_NULL_ERROR;
      }
      const { loadedPropertyGroupProperties } = globalActions;
      const token = getToken(state);
      const { orgId, groupId, limit, offset, search } = action.payload;

      const { requestPropertyGroupProperties } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const propertyResults = await requestPropertyGroupProperties(
        orgId,
        groupId,
        offset,
        limit,
        search,
        token,
        signal
      );
      dispatch(loadedPropertyGroupProperties(propertyResults));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const addPropertiesToGroupsLogic = createLogic({
  type: ADD_PROPERTIES_TO_GROUPS,
  async process(
    { globalActions, agBoxApiUpdates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { updatedPropertyGroup } = globalActions;
      const { updatePropertyGroup } = agBoxApiUpdates;
      if (!action.payload) {
        const { PAYLOAD_IS_NULL_ERROR } = getLabels(state);
        throw PAYLOAD_IS_NULL_ERROR;
      }
      const token = getToken(state);
      const signal = getAbortSignal(getState());
      const { orgId, propIds, groupIds } = action.payload;
      if (!groupIds.length) return done();
      const updates = JSON.stringify({
        addProperties: propIds
      });
      await Promise.all(
        groupIds.map((groupId) =>
          updatePropertyGroup(updates, groupId, orgId, token, signal)
        )
      );
      dispatch(updatedPropertyGroup());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

export default [
  loadPropertyGroupLogic,
  loadPropertyGroupUsersLogic,
  loadPropertyGroupsWorkflowsLogic,
  createPropertyGroupLogic,
  updatePropertyGroupLogic,
  removePropertyGroupLogic,
  loadPropertyGroupPropertiesLogic,
  addPropertiesToGroupsLogic
];
