import { connect } from "react-redux";
import UpdatePropertyGroup from "./UpdatePropertyGroup";
import * as actions from "../../../actions";
import {
  getLabels,
  getSelectedPropertyGroup,
  getSelectedOrgProperties,
  getSelectedOrgPropertiesTotal,
  getPropertyGroupStatus,
  getSelectedOrgPropertiesLoading,
  getOrgPropertiesPageNumber,
  getOrgPropertiesOffset,
  getAbortController
} from "../../../selectors";

export default connect(
  (state) => ({
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedPropertyGroup: getSelectedPropertyGroup(state),
    selectedOrganisationProperties: getSelectedOrgProperties(state),
    selectedOrgPropertiesTotal: getSelectedOrgPropertiesTotal(state),
    selectedPropertyGroupStatus: getPropertyGroupStatus(state),
    selectedOrganisationPropertiesLoading:
      getSelectedOrgPropertiesLoading(state),
    selectedOrgPropertiesPageNumber: getOrgPropertiesPageNumber(state),
    selectedOrgPropertiesOffset: getOrgPropertiesOffset(state),
    abortController: getAbortController(state)
  }),
  actions
)(UpdatePropertyGroup);
