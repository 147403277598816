import { createLogic } from "redux-logic";
import {
  LOAD_PROPERTY,
  LOAD_PROPERTY_PROPERTY_GROUPS,
  LOAD_PROPERTY_WORKFLOWS,
  REMOVE_PROPERTY,
  LOAD_PROPERTY_USERS,
  ABORT_ERROR_NAME,
  UPDATE_PROPERTY,
  ARCHIVE_PROPERTY,
  RESTORE_PROPERTY,
  LOAD_ARCHIVED_PROPERTY,
  IMPORT_DATA_FOR_PROPERTIES,
  CREATE_PROPERTIES,
  JOB_STATUS_SUCCEEDED,
  UPDATE_PROPERTY_DATA
} from "@base/constants";
import { pollJob } from "../common";

const removePropertyLogic = createLogic({
  type: REMOVE_PROPERTY,
  async process(
    { globalActions, agBoxApiRemoves, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, removedProperty } = globalActions;
      const { DELETING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(DELETING_LABEL));

      const token = getToken(state);
      const { orgId, propertyId } = action.payload;
      const { removeProperty } = agBoxApiRemoves;
      const signal = getAbortSignal(getState());
      const { jobId } = await removeProperty(orgId, propertyId, token, signal);
      await pollJob(
        agBoxApiRemoves.requestRemovePropertyStatus,
        orgId,
        propertyId,
        jobId,
        token,
        signal
      );
      dispatch(removedProperty());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadPropertyLogic = createLogic({
  type: LOAD_PROPERTY,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedProperty, setPageTitle } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      const { orgId, propertyId } = action.payload;
      const token = getToken(state);
      dispatch(setLoadingText(LOADING_LABEL));
      dispatch(setPageTitle(""));

      const { requestProperty } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const property = await requestProperty(orgId, propertyId, token, signal);

      dispatch(loadedProperty(property));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setPropertyError } = globalActions;
      dispatch(setPropertyError(e));
      done();
    }
  }
});

const loadPropertyPropertyGroupsLogic = createLogic({
  type: LOAD_PROPERTY_PROPERTY_GROUPS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedPropertyPropertyGroups } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);

      const { orgId, propertyId } = action.payload;
      const { requestPropertyPropertyGroups } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const propertyPropertyGroups = await requestPropertyPropertyGroups(
        orgId,
        propertyId,
        token,
        signal
      );
      dispatch(loadedPropertyPropertyGroups(propertyPropertyGroups));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadPropertyWorkflowsLogic = createLogic({
  type: LOAD_PROPERTY_WORKFLOWS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedPropertyWorkflows } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);

      const { orgId, propertyId } = action.payload;
      const { requestPropertyWorkflows } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const propertyWorkflows = await requestPropertyWorkflows(
        orgId,
        propertyId,
        token,
        signal
      );
      dispatch(loadedPropertyWorkflows(propertyWorkflows));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadPropertyUsersLogic = createLogic({
  type: LOAD_PROPERTY_USERS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedPropertyUsers } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);

      const { orgId, propId } = action.payload;
      const { requestPropertyUsers } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const propertyUsers = await requestPropertyUsers(
        orgId,
        propId,
        token,
        signal
      );
      dispatch(loadedPropertyUsers(propertyUsers));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const updatePropertyLogic = createLogic({
  type: UPDATE_PROPERTY,
  async process(
    { globalActions, agBoxApiUpdates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getAbortSignal, getLabels, getToken } = selectors;
      const { setLoadingText, updatedProperty } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));
      const token = getToken(state);
      const signal = getAbortSignal(getState());
      const { updatePropertyDataSetsLayer } = agBoxApiUpdates;

      const { orgId, propId, roleId, data } = action.payload;

      if (orgId && propId && roleId && data) {
        const updates = new URLSearchParams();
        updates.append(
          "updates",
          JSON.stringify([
            {
              attributes: data
            }
          ])
        );

        await updatePropertyDataSetsLayer(
          orgId,
          propId,
          roleId,
          updates,
          token,
          signal
        );
        dispatch(updatedProperty());
      }
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const archivePropertyLogic = createLogic({
  type: ARCHIVE_PROPERTY,
  async process(
    { globalActions, agBoxApiRemoves, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, archivedProperty } = globalActions;
      const { ARCHIVING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(ARCHIVING_LABEL));

      const token = getToken(state);
      const { orgId, propId } = action.payload;
      const { archiveProperty } = agBoxApiRemoves;
      const signal = getAbortSignal(getState());
      await archiveProperty(orgId, propId, token, signal);
      dispatch(archivedProperty());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const restorePropertyLogic = createLogic({
  type: RESTORE_PROPERTY,
  async process(
    {
      globalActions,
      agBoxApiUpdates,
      agBoxApiRequests,
      getState,
      selectors,
      action
    },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, restoredProperty } = globalActions;
      const { RESTORING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(RESTORING_LABEL));

      const token = getToken(state);
      const { orgId, propId, data } = action.payload;
      const { restoreProperty } = agBoxApiUpdates;
      const signal = getAbortSignal(getState());
      const { jobId } = await restoreProperty(
        orgId,
        propId,
        data,
        token,
        signal
      );
      if (jobId) {
        await pollJob(
          agBoxApiRequests.requestRestorePropertyStatus,
          orgId,
          propId,
          jobId,
          token,
          signal
        );
      }
      dispatch(restoredProperty());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadArchivedPropertyLogic = createLogic({
  type: LOAD_ARCHIVED_PROPERTY,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedArchivedProperty, setPageTitle } =
        globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      const { orgId, propertyId } = action.payload;
      const token = getToken(state);
      dispatch(setLoadingText(LOADING_LABEL));
      dispatch(setPageTitle(""));

      const { requestArchivedProperty } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const property = await requestArchivedProperty(
        orgId,
        propertyId,
        token,
        signal
      );

      dispatch(loadedArchivedProperty(property));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setPropertyError } = globalActions;
      dispatch(setPropertyError(e.message));
      done();
    }
  }
});

const importDataForPropertiesLogic = createLogic({
  type: IMPORT_DATA_FOR_PROPERTIES,
  async process(
    { globalActions, agBoxApiCreates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getAbortSignal, getToken } = selectors;
      const { addFeaturesToLayer } = agBoxApiCreates;
      const { importedDataForProperties } = globalActions;
      const token = getToken(state);
      const signal = getAbortSignal(getState());
      const { orgId, data } = action.payload;

      await Promise.all(
        data.map((item) => {
          const { features, datasetName, layerId } = item;
          const body = new URLSearchParams();
          body.append("adds", JSON.stringify(features));
          return addFeaturesToLayer(
            orgId,
            datasetName,
            layerId,
            body,
            token,
            signal
          );
        })
      )
        .then((results) => {
          const addResults = [].concat(
            ...results.map((result) => result.addResults)
          );
          const errors = addResults.filter((result) => !result.success);
          if (errors.length)
            throw new Error(errors.map((error) => error.message).join("\n"));
        })
        .catch((e) => {
          if (e.name === ABORT_ERROR_NAME) return;
          throw new Error(e.message);
        });

      dispatch(importedDataForProperties());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const createPropertiesLogic = createLogic({
  type: CREATE_PROPERTIES,
  async process(
    {
      globalActions,
      agBoxApiCreates,
      agBoxApiRequests,
      getState,
      selectors,
      action
    },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, createdProperties } = globalActions;
      const { CREATING_LABEL } = getLabels(getState());
      const { requestPropertiesDetails, requestOrganisationPropertyGroups } =
        agBoxApiRequests;
      dispatch(setLoadingText(CREATING_LABEL));

      const token = getToken(state);
      const { data, orgId } = action.payload;
      const { createProperties } = agBoxApiCreates;
      const signal = getAbortSignal(getState());

      const createPropertiesData = {
        properties: Array.isArray(data) ? data : [data]
      };

      const { jobId } = await createProperties(
        orgId,
        JSON.stringify(createPropertiesData),
        token,
        signal
      );
      const jobResults = await pollJob(
        agBoxApiCreates.requestCreatePropertiesStatus,
        orgId,
        jobId,
        token,
        signal
      );

      if (jobResults === JOB_STATUS_SUCCEEDED) {
        const { result } = await agBoxApiCreates.requestCreatePropertiesStatus(
          orgId,
          jobId,
          token,
          signal
        );

        const propertyGroups = await requestOrganisationPropertyGroups(
          orgId,
          token,
          signal
        );

        const { groupId } = propertyGroups.items.find(
          (group) => group.title === "All"
        );

        const propertiesDetails = await requestPropertiesDetails(
          orgId,
          groupId,
          `propId IN (${result.map((propId) => propId).join(",")})`,
          token,
          signal
        );
        const properties = propertiesDetails.map((property) => {
          return {
            propId: property.attributes.propId,
            kpin: property.attributes.officialName.split(":")[1]
          };
        });

        dispatch(createdProperties(properties));
      }
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const updatePropertyDataLogic = createLogic({
  type: UPDATE_PROPERTY_DATA,
  async process(
    { globalActions, agBoxApiUpdates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getAbortSignal, getLabels, getToken } = selectors;
      const { setLoadingText, updatedPropertyData } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));
      const token = getToken(state);
      const signal = getAbortSignal(state);
      const { updatePropertyData } = agBoxApiUpdates;
      const { orgId, propId, data } = action.payload;

      await updatePropertyData(orgId, propId, data, token, signal);
      dispatch(updatedPropertyData());

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

export default [
  removePropertyLogic,
  loadPropertyLogic,
  loadPropertyPropertyGroupsLogic,
  loadPropertyWorkflowsLogic,
  loadPropertyUsersLogic,
  updatePropertyLogic,
  archivePropertyLogic,
  restorePropertyLogic,
  loadArchivedPropertyLogic,
  importDataForPropertiesLogic,
  createPropertiesLogic,
  updatePropertyDataLogic
];
