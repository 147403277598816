import {
  ADDRESS_CANDIDATES_URL,
  ADDRESS_SUGGESTIONS_URL,
  ESRI_GEOLOCATOR_API_KEY,
  V2_PARAM
} from "@base/constants";
import { PROP_BOUNDARIES_LAYER } from "../../constants";

export default (API_URL, API_KEY) => ({
  requestUser: (userId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/users/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestLandkindUsers: (token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/users/getUsers`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestLandkindUserOrganisations: (userId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/users/${userId}/organisations`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestLandkindUserInvites: (userId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/invites/users/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestRemoveOrganisationStatus: (jobId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/jobs/${jobId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestUserMembership: (orgId, userId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/organisations/${orgId}/users/${userId}?returnGroups=true&returnProperties=true`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestUserMembershipPropertyGroup: (orgId, userId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/users/${userId}/organisations/${orgId}/propertyGroups?${V2_PARAM}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestUserMembershipProperties: (orgId, userId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/users/${userId}/organisations/${orgId}/properties?limit=1000&offset=0&${V2_PARAM}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisations: (token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/users/organisations/admin?returnCounts=true`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message)
            reject(
              new Error(result.message, {
                cause: result.code
              })
            );
          return resolve(result.items);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationStatus: (orgId, jobId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/${orgId}/jobs/${jobId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisation: (orgId, token, signal, returnCounts = false) => {
    return new Promise((resolve, reject) => {
      let url = `${API_URL}/organisations/${orgId}`;
      if (returnCounts) {
        url = `${url}?returnCounts=true`;
      }
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationUsers: (orgId, isSuperHuman, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/organisations/${orgId}/users${
          isSuperHuman ? "?returnSuperHumans=true" : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationPropertyGroups: (orgId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/${orgId}/propertyGroups`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationWorkflows: (orgId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/organisations/${orgId}/workflows?returnPermissions=true`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationRoles: (orgId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/${orgId}/roles?returnCounts=true`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationRenderers: (orgId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/${orgId}/renderers`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationRenderer: (orgId, rendererId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/${orgId}/renderers/${rendererId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationDatasets: (orgId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/${orgId}/dataSets`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationProperties: (
    orgId,
    token,
    signal,
    limit,
    offset,
    search
  ) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/organisations/${orgId}/properties?limit=${limit}&offset=${offset}&search=${search}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrgArchivedProperties: (
    orgId,
    token,
    signal,
    limit,
    offset,
    search
  ) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/organisations/${orgId}/properties?limit=${limit}&offset=${offset}&search=${search}&archived=true`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestPropertyGroup: (
    orgId,
    groupId,
    token,
    signal,
    returnProperties = true
  ) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/propertyGroups/${groupId}/organisations/${orgId}?returnProperties=${returnProperties}&returnGeometry=false`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestPropertyGroupUsers: (
    orgId,
    groupId,
    token,
    signal,
    offset,
    limit,
    search
  ) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/propertyGroups/${groupId}/organisations/${orgId}/getUsers?offset=${offset}&limit=${limit}&search=${search}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestPropertyGroupWorkflows: (orgId, groupId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/propertyGroups/${groupId}/organisations/${orgId}/workflows`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestPropertyGroupProperties: (
    orgId,
    groupId,
    offset,
    limit,
    search,
    token,
    signal
  ) => {
    return new Promise((resolve, reject) => {
      let url = `${API_URL}/propertyGroups/${groupId}/organisations/${orgId}?returnGeometry=false&offset=${offset}&limit=${limit}&${V2_PARAM}`;
      if (search) {
        url = `${url}&search=${search}`;
      }
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestRole: (orgId, roleId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/roles/${roleId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestRoleWorkflows: (orgId, roleId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/roles/${roleId}/organisations/${orgId}/workflows`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestRoleUsers: (orgId, roleId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/roles/${roleId}/organisations/${orgId}/users`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestWorkflow: (orgId, workflowId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/workflows/${workflowId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestWorkflowPropertyGroups: (orgId, workflowId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/workflows/${workflowId}/organisations/${orgId}/propertyGroups`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestWorkflowProperties: (orgId, workflowId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/workflows/${workflowId}/organisations/${orgId}/properties`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestProperty: (orgId, propertyId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/properties/${propertyId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestPropertyPropertyGroups: (orgId, propertyId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/properties/${propertyId}/organisations/${orgId}/propertyGroups`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestPropertyWorkflows: (orgId, propertyId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/properties/${propertyId}/organisations/${orgId}/workflows`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestPropertyUsers: (orgId, propertyId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/properties/${propertyId}/organisations/${orgId}/users`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestAddressCandidates: (suggestion, magicKey, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${ADDRESS_CANDIDATES_URL}SingleLine=${suggestion}&outFields=Postal,City,Country,ShortLabel,Region&magicKey=${magicKey}&f=pjson&token=${ESRI_GEOLOCATOR_API_KEY}`,
        {
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestAddressSuggestions: (searchQuery, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${ADDRESS_SUGGESTIONS_URL}text=${searchQuery}&category=Address&f=pjson`,
        {
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestLogoUploadPresignedUrl: (orgId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/${orgId}/logo`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "PATCH",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationInvitedUsers: (orgId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/invites/organisations/${orgId}?status=invited`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationInvitedUser: (orgId, inviteId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/invites/${inviteId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestOrganisationAcceptedUsers: (orgId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/invites/organisations/${orgId}?status=accepted`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestArchivedProperty: (orgId, propertyId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/properties/${propertyId}/organisations/${orgId}/archive`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },

  requestTemplatesByType: (token, signal, type) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/templates/templateType/${type}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message)
            reject(
              new Error(result.message, {
                cause: result.code
              })
            );
          return resolve(result.items);
        })
        .catch((e) => reject(e));
    });
  },
  requestPropertiesDetails: (orgId, groupId, search, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/dataSets/Property/organisations/${orgId}/propertyGroups/${groupId}/FeatureServer/0/query?returnGeometry=false&v2=true&f=json&where=${search}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.error)
            reject(
              new Error(result.message, {
                cause: result.code
              })
            );
          return resolve(result.features);
        })
        .catch((e) => reject(e));
    });
  },
  requestPropertyBoundaries: (
    search,
    outFields,
    returnGeometry,
    limit,
    token,
    signal
  ) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${PROP_BOUNDARIES_LAYER}/query?where=${search}&outSR=4326&outfields=${outFields}&token=${token}&f=pjson&resultRecordCount=${limit}&returnGeometry=${returnGeometry}`,
        {
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.error)
            reject(
              new Error(result.message, {
                cause: result.code
              })
            );
          return resolve(result.features);
        })
        .catch((e) => reject(e));
    });
  },
  requestKPINFeatures: (
    layer,
    search,
    outFields,
    returnGeometry,
    limit,
    token,
    signal
  ) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${layer}/query?where=${search}&outSR=4326&outfields=${outFields}&token=${token}&f=pjson&resultRecordCount=${limit}&returnGeometry=${returnGeometry}`,
        {
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.error)
            reject(
              new Error(result.message, {
                cause: result.code
              })
            );
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  requestDataset: (orgId, dataSet, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/dataSets/${dataSet}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  requestCreateRoleStatus: (orgId, roleId, jobId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/roles/${roleId}/organisations/${orgId}/jobs/${jobId}/create`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  requestRestorePropertyStatus: (orgId, propId, jobId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/properties/${propId}/organisations/${orgId}/jobs/${jobId}/restore`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  requestCreateDatasetStatus: (orgId, dataSet, jobId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/dataSets/${dataSet}/organisations/${orgId}/jobs/${jobId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  }
});
