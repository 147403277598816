import styled, { css } from "styled-components";
import { defaultTheme } from "../../defaultTheme";
import { Container, Button } from "@core";

export const ModalElement = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 10000;

  ${(props) =>
    props.forStyleguide === 'true' &&
    css`
      width: 100%;
      height: 100%;
      position: relative !important;
    `}
`;
ModalElement.displayName = "ModalElement";

export const ModalShade = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);

  ${(props) =>
    props.forStyleguide === 'true' &&
    css`
      width: 100%;
      height: 100%;
      position: absolute !important;
    `}
`;
ModalShade.displayName = "ModalShade";
