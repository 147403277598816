import styled, { css } from "styled-components";
import {
  BUTTON_TYPE_PRIMARY,
  BUTTON_TYPE_SIGN_OUT,
  BUTTON_TYPE_USER,
  BUTTON_TYPE_SIBLING_MENU,
  BUTTON_TYPE_LIST_CONTENT,
  BUTTON_TYPE_LIST_CONTENT_CHILD,
  MODAL_ID,
  CONTEXT_MENU_ID,
  CONTEXT_MENU_ID_LIST_ITEM,
  FILTER_INPUT_ID,
  BACK_BUTTON,
  DROPDOWN_ID,
  DROPDOWN_LIST_ITEM_ID,
  BUTTON_TYPE_PRIMARY_FULLWIDTH,
  BUTTON_TYPE_PRIMARY_BIG,
  BUTTON_TYPE_PRIMARY_BIG_FULLWIDTH,
  BUTTON_TYPE_SECONDARY,
  BUTTON_TYPE_SECONDARY_FULLWIDTH,
  BUTTON_TYPE_SECONDARY_BIG,
  BUTTON_TYPE_SECONDARY_BIG_FULLWIDTH,
  PAGINATION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  STYLE_TYPE_DATA_TABLE_ROW,
  STYLE_TYPE_TABLE_HEADER_BUTTON,
  STYLE_TYPE_DATA_TABLE_SORT_HEADER_BUTTON,
  STYLE_TYPE_TABLE_BUTTON
} from "@base/constants";
import { ImageElement } from "../Image/style";
import { IconBackground, IconSymbol } from "../Icon/style";

export const ButtonLabel = styled.span`
  color: ${(props) => props.theme.adminTeritary};
  text-align: center;
  font-weight: ${(props) => props.theme.baseFontWeight};
  padding: 0.3rem;
  width: ${(props) => {
    if (
      props.styleType === BUTTON_TYPE_PRIMARY ||
      props.styleType === BUTTON_TYPE_PRIMARY_BIG ||
      props.styleType === BUTTON_TYPE_PRIMARY_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_PRIMARY_BIG_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_SECONDARY ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG ||
      props.styleType === BUTTON_TYPE_SECONDARY_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG_FULLWIDTH
    )
      return "auto";
    return "100%";
  }};
`;
ButtonLabel.displayName = "ButtonLabel";

export const ButtonElement = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.2rem 0.6rem;
  color: ${(props) => props.theme.adminTeritary};
  pointer-events: ${(props) => (props.disabled ? "none" : "inherit")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  border: ${(props) => {
    if (
      props.styleType === BUTTON_TYPE_PRIMARY ||
      props.styleType === BUTTON_TYPE_PRIMARY_BIG ||
      props.styleType === BUTTON_TYPE_PRIMARY_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_PRIMARY_BIG_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_USER ||
      props.styleType === BUTTON_TYPE_SIGN_OUT ||
      props.styleType === BUTTON_TYPE_SIBLING_MENU ||
      props.styleType === BUTTON_TYPE_LIST_CONTENT_CHILD ||
      props.styleType === BUTTON_TYPE_LIST_CONTENT ||
      props.styleType === MODAL_ID ||
      props.styleType === DROPDOWN_ID ||
      props.styleType === STYLE_TYPE_DATA_TABLE_SORT_HEADER_BUTTON ||
      props.styleType === DATA_TABLE_BUTTON_TITLE_STYLING ||
      props.styleType === STYLE_TYPE_TABLE_BUTTON
    )
      return "none";
    if (
      props.styleType === BUTTON_TYPE_SECONDARY ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG ||
      props.styleType === BUTTON_TYPE_SECONDARY_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG_FULLWIDTH ||
      props.styleType === CONTEXT_MENU_ID_LIST_ITEM ||
      props.styleType === FILTER_INPUT_ID
    )
      return `1px solid ${props.theme.agMischkaGray};`;

    if (props.styleType === DROPDOWN_LIST_ITEM_ID)
      return `1px solid ${(props) => props.theme.agLighterGray}`;

    if (props.disabled) return `1px solid  ${props.theme.agLighterGray}`;

    return `1px solid  ${props.theme.adminTeritary}`;
  }};
  background: ${(props) => {
    if (
      props.styleType === BUTTON_TYPE_PRIMARY ||
      props.styleType === BUTTON_TYPE_PRIMARY_BIG ||
      props.styleType === BUTTON_TYPE_PRIMARY_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_PRIMARY_BIG_FULLWIDTH
    )
      return props.theme.agGreen;
    if (
      props.styleType === BUTTON_TYPE_SECONDARY ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG ||
      props.styleType === BUTTON_TYPE_SECONDARY_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG_FULLWIDTH ||
      props.styleType === FILTER_INPUT_ID ||
      props.styleType === MODAL_ID ||
      props.styleType === BUTTON_TYPE_USER ||
      props.styleType === BUTTON_TYPE_SIGN_OUT ||
      props.styleType === CONTEXT_MENU_ID ||
      props.styleType === PAGINATION_STYLING
    )
      return props.theme.agWhite;
    if (
      props.styleType === BUTTON_TYPE_LIST_CONTENT_CHILD ||
      props.styleType === BUTTON_TYPE_LIST_CONTENT
    )
      return "none";
    if (props.disabled) return `1px solid  ${props.theme.agOffWhite}`;
    return `1px solid  ${props.theme.agWhite}`;
  }};

  width: ${(props) => {
    if (
      props.styleType === BUTTON_TYPE_PRIMARY_BIG ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG
    )
      return "50%";
    if (
      props.styleType === BUTTON_TYPE_PRIMARY_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_PRIMARY_BIG_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_SECONDARY_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG_FULLWIDTH
    )
      return "100%";
    return "auto";
  }};

  padding: ${(props) => {
    if (
      props.styleType === BUTTON_TYPE_PRIMARY_BIG ||
      props.styleType === BUTTON_TYPE_PRIMARY_BIG_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG_FULLWIDTH
    )
      return "0.8rem";
  }};
  justify-content: ${(props) => {
    if (
      props.styleType === BUTTON_TYPE_PRIMARY ||
      props.styleType === BUTTON_TYPE_PRIMARY_BIG ||
      props.styleType === BUTTON_TYPE_PRIMARY_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_PRIMARY_BIG_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_SECONDARY ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG ||
      props.styleType === BUTTON_TYPE_SECONDARY_FULLWIDTH ||
      props.styleType === BUTTON_TYPE_SECONDARY_BIG_FULLWIDTH
    )
      return "center";
    return "space-between";
  }};

  ${ButtonLabel} {
    color: ${(props) => {
      if (
        props.styleType === BUTTON_TYPE_PRIMARY ||
        props.styleType === BUTTON_TYPE_PRIMARY_BIG ||
        props.styleType === BUTTON_TYPE_PRIMARY_FULLWIDTH ||
        props.styleType === BUTTON_TYPE_PRIMARY_BIG_FULLWIDTH
      )
        return props.theme.agWhite;
      if (
        props.styleType === BUTTON_TYPE_SECONDARY ||
        props.styleType === BUTTON_TYPE_SECONDARY_BIG ||
        props.styleType === BUTTON_TYPE_SECONDARY_FULLWIDTH ||
        props.styleType === BUTTON_TYPE_SECONDARY_BIG_FULLWIDTH ||
        props.styleType === DATA_TABLE_BUTTON_TITLE_STYLING
      )
        return props.theme.agCommonBlack;
      if (
        props.styleType === BUTTON_TYPE_SIGN_OUT ||
        props.styleType === BUTTON_TYPE_USER
      )
        return props.theme.agMischkaGray;
      if (
        props.styleType === BUTTON_TYPE_LIST_CONTENT ||
        props.styleType === BUTTON_TYPE_LIST_CONTENT_CHILD
      )
        return props.theme.agDimGray;
      if (
        props.styleType === CONTEXT_MENU_ID_LIST_ITEM ||
        props.styleType === FILTER_INPUT_ID ||
        props.styleType === DROPDOWN_LIST_ITEM_ID
      )
        return props.theme.agCommonBlack;
      if (
        props.styleType === PAGINATION_STYLING ||
        props.styleType === STYLE_TYPE_TABLE_BUTTON
      )
        return props.theme.agBrightGreen;

      if (props.disabled) return props.theme.agBlack;
      return props.theme.adminTeritary;
    }};
    font-weight: ${(props) => {
      if (
        props.styleType === BUTTON_TYPE_PRIMARY ||
        props.styleType === BUTTON_TYPE_PRIMARY_BIG ||
        props.styleType === BUTTON_TYPE_PRIMARY_FULLWIDTH ||
        props.styleType === BUTTON_TYPE_PRIMARY_BIG_FULLWIDTH ||
        props.styleType === BUTTON_TYPE_SECONDARY ||
        props.styleType === BUTTON_TYPE_SECONDARY_BIG ||
        props.styleType === BUTTON_TYPE_SECONDARY_FULLWIDTH ||
        props.styleType === BUTTON_TYPE_SECONDARY_BIG_FULLWIDTH ||
        props.styleType === BUTTON_TYPE_USER ||
        props.styleType === FILTER_INPUT_ID
      )
        return props.theme.baseFontWeightHeavy;
      if (
        props.styleType === DROPDOWN_LIST_ITEM_ID ||
        props.styleType === CONTEXT_MENU_ID_LIST_ITEM
      )
        return props.theme.baseFontWeightRegular;
    }};
    text-align: ${(props) => {
      if (
        props.styleType === DROPDOWN_LIST_ITEM_ID ||
        props.styleType === CONTEXT_MENU_ID_LIST_ITEM
      )
        return "start";
    }};
    font-size: ${(props) => {
      if (props.styleType === BUTTON_TYPE_USER) return props.theme.fontSizeOne;
      if (props.styleType === BUTTON_TYPE_SIGN_OUT)
        return props.theme.fontSizeSmaller;
      if (
        props.styleType === PAGINATION_STYLING ||
        props.styleType === DATA_TABLE_BUTTON_TITLE_STYLING
      )
        return "1.5rem";
    }};
  }

  ${(props) =>
    props.styleType === BUTTON_TYPE_USER &&
    css`
      opacity: 1;
    `}

  ${(props) =>
    props.styleType === BUTTON_TYPE_SIGN_OUT &&
    css`
      padding: 0rem;
    `}

  ${(props) =>
    props.styleType === BUTTON_TYPE_SIBLING_MENU &&
    css`
      padding-right: 0;

      & ${ButtonLabel} {
        padding: 0.1rem;
      }
    `}

  ${(props) =>
    props.styleType === BUTTON_TYPE_LIST_CONTENT &&
    css`
      font-size: ${(props) => props.theme.fontSizeTwo};
    `}

  ${(props) =>
    props.styleType === CONTEXT_MENU_ID &&
    css`
      position: relative;
      width: 2.5rem;
      padding: 0.2rem 0.3rem;
      border: ${(props) =>
        props.disabled
          ? `1px solid  ${props.theme.agLightestGray}`
          : `1px solid  ${props.theme.agMischkaGray}`};
      :focus {
        border: 1px solid ${(props) => props.theme.agMischkaGray} !important;
      }
    `}

  ${(props) =>
    props.styleType === CONTEXT_MENU_ID_LIST_ITEM &&
    css`
      border-bottom: none;
      background: ${(props) =>
        props.disabled ? props.theme.agLightestGray : props.theme.agWhite};

      :last-child {
        border: 1px solid ${(props) => props.theme.agMischkaGray};
      }
    `}

  ${(props) =>
    props.styleType === FILTER_INPUT_ID &&
    css`
      padding: 0.7rem 2.6rem;
      margin-left: 0.125rem;

      :focus {
        border: 1px solid ${props.theme.agMischkaGray};
      }
    `}    

    /* Modal button styles */

  ${(props) =>
    props.styleType === MODAL_ID &&
    css`
      position: absolute;
      right: 0.313rem;
      top: 0.625rem;
      z-index: 10000;
    `}

  ${(props) =>
    props.styleType === BACK_BUTTON &&
    css`
      height: 3.125rem;
      margin-top: 0.625rem;
    `}

  ${(props) =>
    props.styleType === "paginationButtons" &&
    css`
      width: 2.438rem;
      height: 2.438rem;
      margin: 0.5rem;
      color: ${(props) => props.theme.agCommonBlack};
      box-shadow: 0rem 0.188rem 0.375rem #00000029;
      border: 1px solid ${(props) => props.theme.agDimGray};
      flex-direction: column;
      justify-content: center;
      padding: 0rem;

      ${(props) =>
        props.disabled &&
        css`
          border: none;
        `}

      ${ButtonLabel} {
        padding: 0rem;
      }
    `}

  ${(props) =>
    props.styleType === DROPDOWN_ID &&
    css`
      padding: 0;
      :focus {
        border: none;
      }
    `}

  ${(props) =>
    props.styleType === DROPDOWN_LIST_ITEM_ID &&
    css`
      :focus {
        border: 1px solid ${(props) => props.theme.agLighterGray};
      }
    `}


  ${(props) =>
    props.styleType === PAGINATION_STYLING &&
    css`
      margin-left: 15px;
      border: none;
      padding: 0 0.8rem;
      box-shadow: 0px 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
      background: ${(props) =>
        `${props.theme.agWhite} 0% 0% no-repeat padding-box`};

      &:hover {
        background: ${(props) =>
          `${props.theme.agBrightGreen} 0% 0% no-repeat padding-box`};

        ${ButtonLabel} {
          color: ${props.theme.agWhite};
        }
      }
    `}

    ${(props) =>
    props.styleType === STYLE_TYPE_DATA_TABLE_ROW &&
    css`
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      ${ButtonLabel} {
        padding: 0;
      }
      ${IconSymbol} {
        background: ${(props) => props.theme.agBrightGreen};
      }
      ${IconBackground} {
        background: none;
      }
      &:hover,
      &:focus {
        outline: none;
        background: ${props.theme.agLighterGreen};
        ${IconBackground} {
          background-color: ${props.theme.agLighterGreen};
        }
        ${IconSymbol} {
          background-color: ${props.theme.agWhite};
        }
      }
    `}


    ${(props) =>
    props.styleType === STYLE_TYPE_TABLE_HEADER_BUTTON &&
    css`
      opacity: 1;
      background-color: ${props.theme.agWhite};
      border: none;
      ${ButtonLabel} {
        padding: 0;
      }
      ${IconSymbol} {
        background-color: ${props.theme.agBrightGreen};
      }
      ${IconBackground} {
        background-color: transparent;
      }
      &:hover,
      &:focus {
        outline: none;
        background-color: ${props.theme.agLighterGreen};
        ${IconSymbol} {
          background-color: ${props.theme.agWhite};
        }
      }
    `}

    ${(props) =>
    props.styleType === DATA_TABLE_BUTTON_TITLE_STYLING &&
    css`
      padding: 0;
      height: 1.25rem;

      :focus {
        outline: none;
      }

      ${ButtonLabel} {
        font-size: 1.38rem;
        padding: 0rem 0.3rem;
      }

      ${ImageElement} {
        min-width: 5rem;
      }
    `}
`;
ButtonElement.displayName = "ButtonElement";
