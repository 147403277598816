import React, { Component } from "react";
import { navigate } from "@reach/router";
import AgBoxApiRequests from "../../../apis/agbox/requests";
import { CSSTransition } from "react-transition-group";
import { geodesicBuffer } from "@arcgis/core/geometry/geometryEngine";
import { P, Heading3, Legend } from "@typography";
import { getSession, storeSession, clearSession } from "@base/common";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import {
  Container,
  Wrapper,
  Section,
  FormInput,
  FormLabel,
  Form,
  CheckboxV2,
  Fieldset
} from "@core";
import { Loader, ContextHeader, BottomButtonBar, DataTable } from "@UIKit";
import {
  IMPORT_DATA_OPTIONS,
  STYLETYPE_ERROR,
  PROPERTIES_URL,
  CONTENT_FADE_TIMEOUT,
  STYLETYPE_FORM_FIELD,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  AGBOX_API_URL,
  AGBOX_API_KEY,
  STATUS_READY,
  ESRI_TOKEN_KEY,
  KPIN_POINTS_LAYER,
  VALUES_DICTIONARY,
  STYLE_TYPE_FORM_HEADING,
  STATUS_UPDATING,
  STYLE_TYPE_FORM_HALF_WIDTH,
  ESRI_GENERATE_TOKEN_URL,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL,
  ABORT_ERROR_NAME
} from "@base/constants";
const {
  requestOrganisationProperties,
  requestOrgArchivedProperties,
  requestPropertyBoundaries,
  requestKPINFeatures,
  requestOrganisationDatasets
} = AgBoxApiRequests(AGBOX_API_URL, AGBOX_API_KEY);

class ImportDatasets extends Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      officialName: "",
      kpin: "",
      propId: "",
      wizardInfo: {
        kpinSearch: "",
        propertyData: null,
        customData: "",
        spatialReference: "",
        importData: IMPORT_DATA_OPTIONS,
        propertyGroups: [],
        kpins: [],
        kpinResults: []
      },
      username: "",
      password: "",
      showLogin: false,
      isSearching: false
    };
  }

  componentDidMount() {
    const {
      selectedProperty: { officialName, propId }
    } = this.props;
    const kpin = officialName.split(":")[1];
    this.setState({ officialName, kpin, propId }, () => this.setupAgolAuth());
  }

  componentDidUpdate(prevProps) {
    const { pageTitle, setPageTitle, getLabel, status } = this.props;
    if (
      pageTitle !==
      getLabel("IMPORT_LABEL", { item: getLabel("DATASETS_TEXT") })
    ) {
      setPageTitle(
        getLabel("IMPORT_LABEL", { item: getLabel("DATASETS_TEXT") })
      );
    }
    if (prevProps.status === STATUS_UPDATING && status === STATUS_READY)
      this.showCreateSuccess();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  scrollToTop = () => window.scrollTo({ top: 0 });

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  setWizardInfo = (key, value) => {
    this.setState({
      wizardInfo: {
        ...this.state.wizardInfo,
        [key]: value
      }
    });
  };

  valueIsEmpty = (field) => {
    return (
      !this.getValue(field) ||
      (this.getValue(field) && this.getValue(field).length === 0) ||
      this.getValue(field) === ""
    );
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("IMPORT_LABEL", { item: getLabel("DATASETS_TEXT") });
  };

  render() {
    return <div></div>;
  }

  getContextMenu = () => {
    return {
      isDisabled: true,
      visible: false
    };
  };

  returnToOverviewAction = () => {
    const { hideModal, orgId } = this.props;
    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${PROPERTIES_URL}/`);
  };

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  decodeComputedString = (templateString, data) => {
    const computedLabel = function (templateString, data) {
      return new Function("return `" + templateString + "`;").call(data);
    };
    if (data) {
      return computedLabel(templateString, data);
    }
    return templateString;
  };

  showLoginModal = () => {
    const { setModalContent, getLabel, showModal } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("SIGNING_IN_LABEL"),
      body: <Loader height={HEIGHT_AUTO_TEXT} />
    });
    showModal();
  };

  showLoginError = (errorMessage) => {
    const { setModalContent, getLabel, hideModal } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("AGOL_LOGIN_ERROR_HEADING"),
      body: <P>{errorMessage}</P>,
      buttons: [
        {
          buttonLabel: getLabel("CLOSE_MODAL_BUTTON_LABEL"),
          onClick: hideModal
        }
      ]
    });
  };

  setCreatingModalContent = (loadingText = "") => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("CREATING_LABEL"),
        item: getLabel("DATASETS_TEXT")
      }),
      body: (
        <Loader
          fullPage={false}
          loadingText={loadingText}
          height={HEIGHT_AUTO_TEXT}
        />
      ),
      buttons: []
    });
  };

  showCreatingModal = () => {
    const { showModal, getLabel } = this.props;
    this.setCreatingModalContent(getLabel("IMPORTING_DATA_LABEL"));
    showModal();
  };

  setWizardErrorModalContent = (errorMessage) => {
    const { setModalContent, getLabel } = this.props;
    this.scrollToTop();
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ERROR_HEADING"),
      body: <P>{errorMessage}</P>,
      buttons: [
        {
          buttonLabel: getLabel("PROPERTY_ERROR_RETRY_LABEL"),
          onClick: this.hideErrorModal
        }
      ]
    });
  };

  showWizardErrorModal = (e) => {
    const { showModal } = this.props;
    this.setWizardErrorModalContent(e.message);
    showModal();
  };

  setWizardNoDataErrorModalContent = (errorMessage) => {
    const { setModalContent, getLabel } = this.props;
    this.scrollToTop();
    setModalContent({
      showCloseButton: false,
      header: getLabel("PROPERTY_ERROR_NO_MATCHING_DATA"),
      body: <P>{errorMessage}</P>,
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("PROPERTY_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
  };

  showWizardNoDataErrorModal = () => {
    const { showModal, getLabel } = this.props;
    const kpinValue = this.getValue("kpin");
    this.setWizardNoDataErrorModalContent(
      getLabel("PROPERTY_CANNOT_BE_FOUND_LABEL", { kpin: kpinValue })
    );
    showModal();
  };

  hideErrorModal = () => {
    const { hideModal } = this.props;
    if (this.getEsriTokenExpired())
      this.setState({
        showLogin: true
      });
    hideModal();
  };

  showCreateSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    this.scrollToTop();
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_CREATED_LABEL", {
        item: getLabel("DATASETS_TEXT")
      }),
      body: getLabel("ITEM_CREATED_SUCCESS", {
        item: getLabel("DATASET_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("PROPERTY_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  loadPropertyGroups = () => {
    const { loadOrganisationPropertyGroups, orgId } = this.props;
    loadOrganisationPropertyGroups(orgId);
  };

  loadDatasets = async () => {
    const { loadOrganisationDatasets, orgId, token } = this.props;

    let availableDatasets = await requestOrganisationDatasets(
      orgId,
      token,
      this.controller.signal
    );

    availableDatasets = availableDatasets.items.map((item) => item.name);

    const uniqueDatasetNames = [
      ...new Set(
        IMPORT_DATA_OPTIONS.map((option) => option.datasetName).filter((item) =>
          availableDatasets.some((dataset) => dataset === item)
        )
      )
    ];
    loadOrganisationDatasets(orgId, uniqueDatasetNames);
  };

  getEsriToken = () => {
    const savedTokenInfo = getSession(ESRI_TOKEN_KEY);
    if (!savedTokenInfo) return null;
    const { token } = JSON.parse(savedTokenInfo);
    return token;
  };

  getEsriTokenExpired = () => {
    const savedTokenInfo = getSession(ESRI_TOKEN_KEY);
    if (!savedTokenInfo) return true;
    const { expires } = JSON.parse(savedTokenInfo);
    return expires ? new Date(expires) < new Date() : false;
  };

  checkAgolAuthentication = () => {
    const showLogin = this.getEsriTokenExpired();
    this.setValue(showLogin, "showLogin");
    if (!showLogin) {
      this.loadPropertyGroups();
      this.loadDatasets();
      this.startKpinSearch(this.getValue("kpin"));
    }
  };

  setupAgolAuth = () => {
    const info = new OAuthInfo({
      appId: process.env.REACT_APP_AGOL_CLIENT_ID,
      portalUrl: process.env.REACT_APP_AGOL_PORTAL
    });
    IdentityManager.registerOAuthInfos([info]);
    this.checkAgolAuthentication();
  };

  authenticateAgol = async () => {
    try {
      this.showLoginModal();
      clearSession(ESRI_TOKEN_KEY);
      const { hideModal } = this.props;
      const result = await IdentityManager.generateToken(
        {
          tokenServiceUrl: ESRI_GENERATE_TOKEN_URL
        },
        {
          username: this.getValue("username"),
          password: this.getValue("password")
        }
      );
      storeSession(ESRI_TOKEN_KEY, JSON.stringify(result));
      hideModal();
      this.setState({
        showLogin: false
      });
      this.loadPropertyGroups();
      this.loadDatasets();
      this.startKpinSearch(this.getValue("kpin"));
    } catch (e) {
      let errorMessage = e.message;
      if (e.details && e.details.messages && e.details.messages.length)
        errorMessage = e.details.messages.join("\n");
      this.showLoginError(errorMessage);
    }
  };

  getLoginForm = () => {
    const { getLabel } = this.props;
    return (
      <Form styleType={STYLE_TYPE_FORM_HALF_WIDTH}>
        <Heading3 styleType={STYLE_TYPE_FORM_HEADING}>
          {getLabel("AGOL_LOGIN_HEADING_LABEL")}
        </Heading3>
        <Container styleType={STYLETYPE_FORM_FIELD}>
          <FormLabel
            htmlFor={"username"}
            styleType={this.valueIsEmpty("password") ? STYLETYPE_ERROR : ""}
          >
            {getLabel("USERNAME_LABEL")}
          </FormLabel>
          <FormInput
            value={this.getValue("username")}
            onChange={(value) => {
              this.setValue(value, "username");
            }}
            styleType={this.valueIsEmpty("username") ? STYLETYPE_ERROR : ""}
            id={"username"}
          />
        </Container>
        <Container styleType={STYLETYPE_FORM_FIELD}>
          <FormLabel
            htmlFor={"password"}
            styleType={this.valueIsEmpty("password") ? STYLETYPE_ERROR : ""}
          >
            {getLabel("FORM_PASSWORD_LABEL")}
          </FormLabel>
          <FormInput
            value={this.getValue("password")}
            type="password"
            onChange={(value) => {
              this.setValue(value, "password");
            }}
            styleType={this.valueIsEmpty("password") ? STYLETYPE_ERROR : ""}
            id={"password"}
          />
        </Container>
      </Form>
    );
  };

  getAddDatasetContent = () => {
    return this.getValue("showLogin")
      ? this.getLoginForm()
      : this.getAddDatasetWizardContent();
  };

  getKpinInputBox = () => {
    return (
      <>
        <FormInput
          id={"wizard-search"}
          value={this.getValue("kpin")}
          disabled={true}
        />
      </>
    );
  };

  getWizardInfo = (key) => {
    const { wizardInfo } = this.state;
    return wizardInfo[key];
  };

  getPropertyData = () => {
    const { wizardInfo } = this.state;
    return wizardInfo.propertyData;
  };

  getImportAllData = () => {
    const importData = this.getWizardInfo("importData");
    return importData.every((option) => option.checked);
  };

  setImportAllData = (e) => {
    const { checked } = e.currentTarget;
    this.setWizardInfo(
      "importData",
      this.getWizardInfo("importData").map((option) => ({
        ...option,
        checked,
        layers: option.layers
          ? option.layers.map((layer) => ({
              ...layer,
              checked
            }))
          : null
      }))
    );
  };

  getAllRowsExpanded = () => {
    const importData = this.getWizardInfo("importData");
    return importData.every((option) =>
      option.layers ? option.expanded : true
    );
  };

  getDatasetByName = (datasetName) => {
    const { organisationDatasets } = this.props;
    return organisationDatasets
      ? organisationDatasets.find((dataset) => dataset.name === datasetName) ||
          null
      : null;
  };

  getDatasetOptions = () => {
    return this.getWizardInfo("importData").reduce((result, option) => {
      const { layers, datasetName, layerTitle } = option;
      const datasetInfo = this.getDatasetByName(datasetName);
      if (!datasetInfo) return result;
      if (!layers) {
        const layerId = datasetInfo.layers.findIndex(
          (layer) => layer.title === layerTitle
        );
        return layerId > -1
          ? [
              ...result,
              {
                ...option,
                layerId
              }
            ]
          : result;
      }
      const layerOptions = layers.reduce((layerResult, layerOption) => {
        const layerId = datasetInfo.layers.findIndex(
          (layer) => layer.title === layerOption.layerTitle
        );
        if (layerId === -1) return layerResult;
        return [
          ...layerResult,
          {
            ...layerOption,
            layerId
          }
        ];
      }, []);
      if (!layerOptions.length) return result;
      else if (layerOptions.length === 1)
        return [
          ...result,
          {
            ...option,
            ...layerOptions[0],
            layers: null
          }
        ];
      return [
        ...result,
        {
          ...option,
          layers: layerOptions
        }
      ];
    }, []);
  };

  getLayerSubtable = (option) => {
    if (!option.layers) return null;
    const { getLabel } = this.props;
    return (
      <Fieldset>
        <DataTable
          id={"import-data-table"}
          header={{
            cells: [
              {
                content: (
                  <CheckboxV2
                    handleUpdate={this.setImportDataset}
                    name={`${option.value}-import-all`}
                    rowId={`${option.value}-import-all`}
                    value={option.value}
                    checked={option.layers.every((layer) => layer.checked)}
                    title={getLabel("IMPORT_ALL_LAYERS_LABEL")}
                  />
                )
              }
            ]
          }}
          rows={option.layers.map((layer) => {
            const titleText = layer.value.replace(/([A-Z])/g, " $1");
            const title = `${titleText.charAt(0).toUpperCase()}${titleText
              .slice(1)
              .toLowerCase()}`;
            return {
              cells: [
                <CheckboxV2
                  handleUpdate={(e) =>
                    this.setImportLayer(
                      option.value,
                      layer.value,
                      e.target.checked
                    )
                  }
                  name={option.value}
                  rowId={layer.value}
                  value={layer.value}
                  checked={layer.checked}
                  title={title}
                />
              ]
            };
          })}
          pagination={{ showPagination: false }}
          pageDropDown={{ showDropdown: false }}
          innerStyleType={"inner-accordion"}
        />
      </Fieldset>
    );
  };

  setDatasetExpanded = (value) => {
    this.setWizardInfo(
      "importData",
      this.getWizardInfo("importData").map((option) => {
        if (option.value !== value) return option;
        return {
          ...option,
          expanded: !option.expanded
        };
      })
    );
  };

  getDatasetRows = () => {
    const { getLabel } = this.props;
    return this.getDatasetOptions().map((option) => {
      return {
        cells: [
          <CheckboxV2
            handleUpdate={this.setImportDataset}
            name={"dataset"}
            id={option.value}
            rowId={option.value}
            value={option.value}
            checked={option.checked}
            title={
              option.value
                ? getLabel(
                    `${option.value
                      .replace(/([A-Z])/g, "_$1")
                      .toUpperCase()}_LABEL`
                  )
                : null
            }
          />
        ],
        expanded: option.expanded === true,
        id: option.value,
        content: option.layers ? this.getLayerSubtable(option) : null,
        handleUpdateRowExpanded: this.setDatasetExpanded
      };
    });
  };

  setExpandAllDatasets = () => {
    const allRowsExpanded = this.getAllRowsExpanded();
    this.setWizardInfo(
      "importData",
      this.getWizardInfo("importData").map((option) => ({
        ...option,
        expanded: option.layers ? !allRowsExpanded : false
      }))
    );
  };

  getFieldValue = (value, agolFieldName, fields = [], fieldType) => {
    if (value === undefined || value === null) return null;
    if (Object.prototype.hasOwnProperty.call(VALUES_DICTIONARY, value))
      return VALUES_DICTIONARY[value];

    const fieldInfo = fields.find(
      (fieldInfo) => fieldInfo.name === agolFieldName
    );

    if (fieldInfo && fieldInfo.domain && fieldInfo.domain.codedValues) {
      const codedValue = fieldInfo.domain.codedValues.find(
        (item) => item.code === value
      );
      if (codedValue) return codedValue.name;
    }
    if (fieldType === "date") return new Date(value).toISOString();
    return value;
  };

  generateFeatureAttributes = (feature, datasetInfo, layerFields = []) => {
    const { fields, fixedValues, customDataFields } = datasetInfo;
    const attributes = {};
    if (fields) {
      Object.keys(fields).forEach((fieldName) => {
        const agolField = fields[fieldName];
        attributes[fieldName] = this.getFieldValue(
          feature.attributes[agolField],
          agolField,
          layerFields
        );
      });
    }
    if (fixedValues) {
      Object.keys(fixedValues).forEach((fieldName) => {
        attributes[fieldName] = this.decodeComputedString(
          fixedValues[fieldName],
          feature.attributes
        );
      });
    }
    if (customDataFields) {
      const customData = {};
      Object.keys(customDataFields).forEach((fieldName) => {
        const { field = fieldName, type, value } = customDataFields[fieldName];
        customData[fieldName] = {
          type,
          value:
            value ||
            this.getFieldValue(feature.attributes[field], field, [], type)
        };
      });
      attributes.customData = JSON.stringify(customData);
    }
    return {
      ...attributes,
      title: attributes.title || attributes.category
    };
  };

  generateImportDataForLayer = async (item) => {
    const { abortController } = this.props;
    const { agolLayer, datasetName, layerId } = item;
    const esriToken = this.getEsriToken();

    const kpin = this.getValue("kpin");
    const propId = this.getValue("propId");

    const results = await requestKPINFeatures(
      agolLayer,
      `kpin = '${kpin}'`,
      "*",
      true,
      2000,
      esriToken,
      abortController.signal
    );

    if (!results || !results.features || !results.features.length) return null;
    const { features, fields } = results;

    const newFeatures = features.reduce((result, feature) => {
      if (feature.attributes.kpin === kpin) {
        result.push({
          attributes: {
            propId: propId,
            ...this.generateFeatureAttributes(feature, item, fields)
          },
          geometry: {
            ...feature.geometry,
            spatialReference: {
              wkid: 4326
            }
          }
        });
      }
      return result;
    }, []);

    return {
      features: newFeatures,
      datasetName,
      layerId
    };
  };

  setImportLayer = (dataset, layerTitle, checked) => {
    this.setWizardInfo(
      "importData",
      this.getWizardInfo("importData").map((option) => {
        if (option.value !== dataset || !option.layers) return option;
        const updatedLayers = option.layers.map((layer) => {
          if (layer.value !== layerTitle) return layer;
          return {
            ...layer,
            checked
          };
        });
        return {
          ...option,
          layers: updatedLayers,
          checked: updatedLayers.some((layer) => layer.checked)
        };
      })
    );
  };

  setImportDataset = (e) => {
    const { value, checked } = e.currentTarget;
    this.setWizardInfo(
      "importData",
      this.getWizardInfo("importData").map((option) => {
        if (option.value !== value) return option;
        return {
          ...option,
          checked,
          layers: option.layers
            ? option.layers.map((layer) => ({ ...layer, checked }))
            : null
        };
      })
    );
  };

  handleStartImportData = async () => {
    const { getLabel, importDataForProperties, orgId } = this.props;
    const selectedImportData = this.getDatasetOptions().reduce(
      (result, dataset) => {
        const { layers, checked } = dataset;
        if (!checked) return result;
        if (!layers) return [...result, dataset];
        return [
          ...result,
          ...layers
            .filter((layer) => layer.checked)
            .map((layer) => ({
              ...dataset,
              ...layer
            }))
        ];
      },
      []
    );
    if (!selectedImportData.length) return;

    this.setCreatingModalContent(getLabel("IMPORTING_DATA_LABEL"));

    const importData = await Promise.all(
      selectedImportData.map((item) => this.generateImportDataForLayer(item))
    ).then((result) => result.filter((item) => item));

    if (!importData.length) return;

    importDataForProperties(orgId, importData);
  };

  getPropertyGeometry = (boundary, point) => {
    if (boundary)
      return {
        ...boundary.geometry,
        spatialReference: {
          wkid: 4326
        }
      };
    else
      return geodesicBuffer(
        {
          ...point.geometry,
          spatialReference: {
            wkid: 4326
          }
        },
        50,
        "meters"
      );
  };

  getPropertyAttributes = (point) => {
    const customData = this.getWizardInfo("customData");
    let officialName = "";
    if (point) {
      officialName = `KPIN:${point.attributes.kpin}`;
      const {
        propertyname,
        roadnumber,
        road,
        town,
        postcode,
        region,
        country
      } = point.attributes;
      const address = road && roadnumber ? `${roadnumber} ${road}` : road;

      return {
        title: propertyname,
        officialName,
        address,
        city: town,
        postcode,
        state: region,
        country,
        active: 1,
        customData
      };
    }
    return {
      officialName,
      title: officialName,
      active: 1,
      customData
    };
  };

  formatPropertyFeature = (boundary, point) => {
    const attributes = this.getPropertyAttributes(point);
    const geometry = this.getPropertyGeometry(boundary, point);
    return {
      title: attributes.title,
      spatialReference: this.getWizardInfo("spatialReference"),
      attributes,
      geometry
    };
  };

  loadPropertyInfo = async (kpins) => {
    const { abortController, getLabel } = this.props;
    this.setCreatingModalContent(getLabel("LOADING_PROPERTY_FEATURE_LABEL"));
    const token = this.getEsriToken();
    const boundaries = await requestPropertyBoundaries(
      kpins.map((kpin) => `propertyref = '${kpin}'`).join(" OR "),
      "*",
      true,
      kpins.length,
      token,
      abortController.signal
    );
    const { features } = await requestKPINFeatures(
      KPIN_POINTS_LAYER,
      kpins.map((kpin) => `kpin = '${kpin}'`).join(" OR "),
      "*",
      true,
      kpins.length,
      token,
      abortController.signal
    );
    return kpins.map((kpin) => {
      const boundary = boundaries.find(
        (feature) => feature.attributes.propertyref === String(kpin)
      );
      const point = features.find(
        (feature) => feature.attributes.kpin === String(kpin)
      );
      return this.formatPropertyFeature(boundary, point);
    });
  };

  getListItems = () => {
    const listItems = this.getWizardInfo("kpinResults");
    const selectedItems = this.getWizardInfo("kpins");
    return listItems.map((item) => {
      const selected = selectedItems.find(
        (selectedItem) => selectedItem.value === item.value
      )
        ? true
        : false;
      return {
        ...item,
        selected,
        disabled: item.exists || selected
      };
    });
  };

  handleCheckSelectableItems = () => {
    if (!this.getListItems().some((item) => !item.selected && !item.disabled)) {
      this.setWizardInfo("kpinSearch", "");
    }
  };

  isInvalid = () => {
    const kpins = this.getWizardInfo("kpins") || [];
    const importData = this.getWizardInfo("importData") || [];
    const allUnchecked = importData.every((item) => item.checked === false);
    return !kpins.length || allUnchecked;
  };

  checkForExistingProperty = async (kpin) => {
    const { orgId, token } = this.props;
    const totalExistingProperties = await Promise.all(
      [requestOrgArchivedProperties, requestOrganisationProperties].map(
        (request) => request(orgId, token, this.controller.signal, 1, 0, kpin)
      )
    ).then((results) => {
      const kpinCount = results.reduce((count, result) => {
        return (
          count + result.items &&
          result.items.filter((item) => item.officialName.includes("KPIN"))
            .length
        );
      }, 0);
      return kpinCount;
    });
    return totalExistingProperties > 0;
  };

  searchForAGOLKpins = async (search) => {
    const token = this.getEsriToken();
    let results = await requestPropertyBoundaries(
      encodeURI(`propertyref LIKE '%${search}%'`),
      "propertyref",
      false,
      10,
      token,
      this.controller.signal
    );
    if (results.length < 10) {
      const { features = [] } = await requestKPINFeatures(
        KPIN_POINTS_LAYER,
        encodeURI(`kpin LIKE '%${search}%'`),
        "kpin",
        false,
        10,
        token,
        this.controller.signal
      );
      const uniqueKPINs = features
        .filter(
          (feature) =>
            !results.find(
              (result) =>
                result.attributes.propertyref === feature.attributes.kpin
            )
        )
        .slice(0, 10 - results.length);
      results.push(...uniqueKPINs);
    }
    return results;
  };

  onSelectKpin = (kpin) => {
    const { wizardInfo } = this.state;
    const kpins = this.getWizardInfo("kpins") || [];
    this.setState(
      {
        wizardInfo: {
          ...wizardInfo,
          kpins: [...kpins, kpin]
        }
      },
      () => {
        this.handleCheckSelectableItems();
      }
    );
  };

  startKpinSearch = async (kpinSearch) => {
    try {
      const { getLabel } = this.props;
      if (!kpinSearch) return;
      this.setState({
        isSearching: true
      });
      const results = await this.searchForAGOLKpins(kpinSearch);
      const listItems = await Promise.all(
        results.map(async (result) => {
          const kpin = result.attributes.propertyref || result.attributes.kpin;
          const exists = await this.checkForExistingProperty(kpin);
          return {
            exists,
            label: `KPIN:${kpin}`,
            value: kpin,
            subtitle: exists ? getLabel("PROPERTY_EXISTS_LABEL") : ""
          };
        })
      );
      this.setWizardInfo("kpinResults", listItems);

      const item = listItems.find((item) => item.value === kpinSearch);
      if (item) this.onSelectKpin(item);
      else this.showWizardNoDataErrorModal();

      this.setState({
        isSearching: false
      });
    } catch (e) {
      if (e.cause === 498) this.checkAgolAuthentication();
      else {
        if (e.name !== ABORT_ERROR_NAME) {
          this.showWizardErrorModal(e);
        }
      }

      this.setState({
        isSearching: false
      });
    }
  };

  importDatasetsFromWizard = async () => {
    try {
      this.showCreatingModal();
      const kpins = this.getWizardInfo("kpins").map(({ value }) => value);
      const propertyData = await this.loadPropertyInfo(kpins);
      this.setState(
        {
          wizardInfo: {
            ...this.state.wizardInfo,
            propertyData
          }
        },
        () => this.handleStartImportData()
      );
    } catch (e) {
      const { getLabel } = this.props;
      this.setWizardErrorModalContent(
        this.getEsriTokenExpired()
          ? getLabel("AGOL_SESSION_EXPIRED_LABEL")
          : e.message
      );
    }
  };

  getAddDatasetWizardContent = () => {
    const { getLabel, organisationDatasetsLoading } = this.props;
    return (
      <Section>
        <Form>
          <Container styleType={STYLETYPE_FORM_FIELD}>
            {this.getKpinInputBox()}
          </Container>
          <Container styleType={STYLETYPE_FORM_FIELD}>
            <Fieldset>
              <DataTable
                id={"datasets-table"}
                title={getLabel("IMPORT_DATA_LABEL")}
                TitleComponent={Legend}
                loading={organisationDatasetsLoading}
                header={{
                  cells: [
                    {
                      content: (
                        <CheckboxV2
                          handleUpdate={this.setImportAllData}
                          name={"importData"}
                          rowId={"importData"}
                          value={this.getImportAllData()}
                          checked={this.getImportAllData()}
                          title={getLabel("IMPORT_ALL_LABEL")}
                        />
                      )
                    }
                  ],
                  showToggle: true,
                  setToggleOpen: this.setExpandAllDatasets,
                  toggleOpen: this.getAllRowsExpanded()
                }}
                rows={this.getDatasetRows()}
                noResultsText={getLabel("NO_DATASETS_LABEL")}
              />
            </Fieldset>
          </Container>
        </Form>
      </Section>
    );
  };

  getButtons = () => {
    const { getLabel } = this.props;
    let primaryButton = {
      buttonStyleType: "primary",
      isDisabled: !this.getValue("username") || !this.getValue("password"),
      onClick: this.authenticateAgol,
      buttonLabel: getLabel("SIGN_IN_LABEL")
    };
    if (!this.getValue("showLogin"))
      primaryButton = {
        buttonStyleType: "primary",
        isDisabled: this.isInvalid(),
        onClick: this.importDatasetsFromWizard,
        buttonLabel: getLabel("IMPORT_LABEL", {
          item: getLabel("DATASETS_TEXT")
        })
      };
    return [
      primaryButton,
      {
        buttonStyleType: "secondary",
        isDisabled: false,
        onClick: this.returnToOverviewAction,
        buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
          item: getLabel("PROPERTY_TEXT")
        })
      }
    ];
  };

  render() {
    return (
      <Container styleType={CONTENT_VIEW}>
        <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
          <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
            <ContextHeader
              headerTitle={this.createHeaderText()}
              contextMenu={this.getContextMenu()}
            />
          </Container>
          <Container>
            {this.getAddDatasetContent() && (
              <CSSTransition
                in={true}
                timeout={CONTENT_FADE_TIMEOUT}
                classNames="css-transition"
                unmountOnExit
              >
                {this.getAddDatasetContent()}
              </CSSTransition>
            )}
          </Container>
          <BottomButtonBar buttons={this.getButtons()} />
        </Wrapper>
      </Container>
    );
  }
}

export default ImportDatasets;
