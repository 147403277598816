import React, { Component } from "react";
import { Container } from "@core";
import { CSSTransition } from "react-transition-group";
import { CONTENT_FADE_TIMEOUT, DROPDOWN_ID } from "@base/constants";
import PropTypes from "prop-types";
import DropdownInput from "./subComponents/DropdownInput";
import DropdownList from "./subComponents/DropdownList";

/**
 * Dropdown component. Made up of an input, a dropdown button, and a list of buttons.
 */
class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false
    };
  }
  static propTypes = {
    /** Whether the context menu is visible or not */
    visible: PropTypes.bool,
    /** Whether the context menu is disabled or not */
    isDisabled: PropTypes.bool,
    /** An array of children to render as buttons */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        /** Whether the child button is disabled or not */
        isDisabled: PropTypes.bool,
        /** The label for the childs button  */
        label: PropTypes.string,
        /** The value for the childs button  */
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    /** What to display in the dropdown entry field */
    label: PropTypes.string,
    /** The underlying value stored against the dropdown (can be different from the label) */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** id for DropdownInput */
    id: PropTypes.string,
    /** An aria described by attribute */
    ariaDescribedBy: PropTypes.string,
    /** An aria label attribute */
    ariaLabel: PropTypes.string
  };

  static defaultProps = {
    visible: true,
    isDisabled: false
  };

  componentDidMount() {
    document.addEventListener("keydown", this.closeDropdown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.closeDropdown, false);
  }

  closeDropdown = (event) => {
    const { openMenu } = this.state;
    if (openMenu && (!event.key || event.key == "Escape")) {
      this.toggleOpen();
    }
  };

  /**
   * Returns the value to display in the text area
   * @public
   */
  getValue = () => {
    const { value } = this.props;
    return value;
  };

  /**
   * Returns the label to display in the text area
   * @public
   */
  getLabel = () => {
    const { label } = this.props;
    return label;
  };

  /**
   * Returns the dropdown children property
   * @public
   */
  getOptions = () => {
    const { options } = this.props;
    return options && options.length ? options : false;
  };

  /**
   * Toggles the state of the open button
   * @private
   */
  toggleOpen = (openState) => {
    const { openMenu } = this.state;
    this.setState({
      openMenu: openState ? openState : !openMenu
    });
  };

  /**
   * Returns the open state of the menu
   * @private
   */
  getOpen = () => {
    const { openMenu } = this.state;
    return openMenu;
  };

  /**
   * Returns the disabled state of the menu
   * @private
   */
  getDisabled = () => {
    const { isDisabled } = this.props;
    return !!isDisabled;
  };

  /**
   * Returns the placeholder state of the menu
   * @private
   */
  getPlaceholder = () => {
    const { placeholder } = this.props;
    return placeholder;
  };

  getOnClick = (event) => {
    const { onClick, value } = this.props;
    if (event && event.value && value && onClick) {
      return JSON.stringify(event.value) !== value ? onClick(event) : null;
    }
    if (event && onClick) {
      return onClick(event);
    }
    return null;
  };

  getDropDownId = () => {
    const { id } = this.props;
    return id ? id : "";
  };

  render() {
    return (
      <Container styleType={DROPDOWN_ID}>
        <DropdownInput
          id={this.getDropDownId()}
          placeholder={this.getPlaceholder()}
          label={this.getLabel()}
          value={this.getValue()}
          onClick={this.toggleOpen}
          onFocus={() => {
            this.toggleOpen(true);
          }}
          onBlur={this.closeDropdown}
          onChange={(event) => {
            this.props.onChange(event.target.value);
          }}
          ariaDescribedBy={this.props.ariaDescribedBy}
          ariaLabel={this.props.ariaLabel}
        />
        <CSSTransition
          in={this.getOpen()}
          timeout={CONTENT_FADE_TIMEOUT}
          classNames="css-transition"
          unmountOnExit
        >
          <DropdownList
            options={this.getOptions()}
            onClick={(event) => {
              this.getOnClick(event);
            }}
          ></DropdownList>
        </CSSTransition>
      </Container>
    );
  }
}

export default Dropdown;
