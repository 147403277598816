import React, { Component } from "react";

import { Loader, DataTable, ContextHeader } from "@UIKit";
import { Container, Wrapper, Section, LinkButton } from "@core";
import { navigate } from "@reach/router";
import { defaultTheme, BottomButtonBar } from "@UIKit";
import { filterList } from "@base/common";

import {
  ROLES_URL,
  NEW_URL,
  CONTENT_VIEW,
  BUTTON_TYPE_PRIMARY,
  CONTEXT_HEADER_CONTAINER,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  ROLES_SECTION,
  CREATE_PERMISSION,
  ORGANISATION_URL
} from "@base/constants";

class RoleOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: ""
    };
  }

  componentDidMount() {
    this.loadDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  loadDetails = () => {
    const { loadOrganisationRoles, orgId } = this.props;
    loadOrganisationRoles(orgId);
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("ROLES_TEXT");
  };

  getSecondHeaderText = () => {
    const { orgId, getLabel, availableOrganisations } = this.props;
    if (!availableOrganisations || !availableOrganisations.length) return "";
    const org = availableOrganisations.find((org) => org.orgId === orgId);
    if (!org) return "";
    return getLabel("ROLES_FOR_ORG_TEXT", {
      item: org.title
    });
  };

  getOrganisationRoles = () => {
    const { selectedOrganisationRoles } = this.props;
    return selectedOrganisationRoles
      ? filterList(selectedOrganisationRoles, this.getFilterText())
      : false;
  };

  createNavigationLink = (link) => {
    return () => {
      navigate(link);
    };
  };
  createNewRoleLink = () => {
    const { orgId } = this.props;
    return this.createNavigationLink(
      `/${ORGANISATION_URL}/${orgId}/${ROLES_URL}/${NEW_URL}`
    );
  };

  hasCreatePermission = () => {
    const { getHasPermission, orgId } = this.props;
    return getHasPermission(orgId, ROLES_SECTION, CREATE_PERMISSION);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        isDisabled: false,
        buttonLabel: getLabel("ADD_NEW_LABEL", { item: getLabel("ROLE_TEXT") }),
        onClick: this.createNewRoleLink(),
        icon: {
          type: "plus",
          bgWidth: "25px",
          bgHeight: "15px",
          iconHeight: "16px",
          iconWidth: "15px",
          iconColor: defaultTheme.agWhite
        }
      }
    ];
  };

  getContextHeaderButtons = () => {
    const { getLabel } = this.props;
    return this.hasCreatePermission()
      ? [
          {
            buttonStyleType: BUTTON_TYPE_PRIMARY,
            isDisabled: false,
            buttonLabel: getLabel("ADD_NEW_LABEL", {
              item: getLabel("ROLE_TEXT")
            }),
            onClick: this.createNewRoleLink()
          }
        ]
      : [];
  };

  getOrgRolesLoading = () => {
    return this.props.selectedOrganisationRolesStatus;
  };

  setFilterText = (value) => {
    this.setState({
      filterText: value
    });
  };

  getFilterText = () => {
    const { filterText } = this.state;
    return filterText;
  };

  getNoResultsText = () => {
    const { getLabel } = this.props;
    const filterText = this.getFilterText();
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getFilterPlaceholderText = () => {
    const { getLabel } = this.props;
    return getLabel("ROLES_FILTER_PLACEHOLDER_LABEL");
  };

  getFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("ROLES_TEXT")
    });
  };

  getTableHeader = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        { content: getLabel("FORM_LABEL_ROLE_NAME") },
        { content: getLabel("WORKFLOWS_TEXT") },
        { content: getLabel("ROLES_USERS_TEXT") }
      ]
    };
  };

  getTableData = () => {
    const { orgId } = this.props;
    const roles = this.getOrganisationRoles();
    if (!roles) return;
    return roles.map((role) => {
      const title = (
        <LinkButton
          url={`/${orgId}/${ROLES_URL}/${role.roleId}`}
          buttonLabel={role.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      );

      return {
        cells: [title, role.counts.workflows, role.counts.users]
      };
    });
  };

  render() {
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.getOrgRolesLoading() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper
            data-name={"Wrapper"}
            subStyle="App"
            styleType={CONTENT_VIEW}
          >
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
                buttons={this.getContextHeaderButtons()}
              />
            </Container>

            <Section styleType={DATA_TABLE_SECTION_STYLING}>
              <DataTable
                noResultsText={this.getNoResultsText()}
                title={this.getSecondHeaderText()}
                tableFilter={{
                  filterLabel: this.getFilterLabel(),
                  showFilter: true,
                  filterFunction: this.setFilterText,
                  placeholderText: this.getFilterPlaceholderText(),
                  inputId: this.getFilterLabel()
                }}
                header={this.getTableHeader()}
                rows={this.getTableData()}
              />
              {this.hasCreatePermission() && (
                <BottomButtonBar buttons={this.getButtons()} />
              )}
            </Section>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default RoleOverview;
