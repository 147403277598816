import React, { Component } from "react";
import {
  Container,
  Wrapper,
  FormLabel,
  FormInput,
  Section,
  Checkbox,
  TextArea
} from "@core";
import { getSession, storeSession } from "@base/common";
import {
  STYLETYPE_ERROR,
  CONTENT_VIEW,
  STYLETYPE_FORM_FIELD,
  STATUS_READY,
  STATUS_CREATING,
  PROPERTY_GROUPS_URL,
  STYLETYPE_CHECKBOX_CONTAINER,
  CONTEXT_HEADER_CONTAINER,
  STATUS_UPDATING,
  FIELD_SIZE_SMALL,
  STYLETYPE_TEXT,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  DATA_TABLE_PAGINATION_STYLING,
  STATUS_LOADING,
  HEIGHT_AUTO_TEXT,
  MINIMUM_SEARCH_STRING_LENGTH,
  ORGANISATION_URL,
  SEARCH_TIMEOUT,
  DATA_TABLE_FILTER_INPUT_STYLING
} from "@base/constants";
import { P, Legend } from "@typography";
import {
  Loader,
  ContextHeader,
  BottomButtonBar,
  PaginationV2,
  Dropdown,
  FilterInput
} from "@UIKit";
import { navigate } from "@reach/router";

export default class AddPropertyGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      filterText: "",
      properties: [],
      propertiesFilterText: "",
      searchTimeout: null
    };
  }

  componentDidMount() {
    this.loadPropertyGroupDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps) {
    const { pageTitle, setPageTitle, getLabel, status } = this.props;
    if (
      pageTitle !==
      getLabel("ADD_NEW_LABEL", { item: getLabel("PROPERTY_GROUP_TEXT") })
    ) {
      setPageTitle(
        getLabel("ADD_NEW_LABEL", { item: getLabel("PROPERTY_GROUP_TEXT") })
      );
    }
    if (
      (prevProps.status === STATUS_CREATING ||
        prevProps.status === STATUS_UPDATING) &&
      status === STATUS_READY
    ) {
      this.showCreateSuccess();
    }
  }

  loadPropertyGroupDetails = () => {
    const { orgId, loadOrganisationProperties, setOrgPropertiesPagination } =
      this.props;
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(0, 10, 0, "");
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("ADD_NEW_LABEL", { item: getLabel("PROPERTY_GROUP_TEXT") });
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  valueIsEmpty = (field) => {
    return (
      !this.getValue(field) ||
      (this.getValue(field) && this.getValue(field).length === 0) ||
      this.getValue(field) === ""
    );
  };

  setPropertyValues = (properties) => {
    this.setState({ properties });
  };

  getPropertyOptions = () => {
    const { properties } = this.state;
    const { selectedOrganisationProperties } = this.props;
    if (!selectedOrganisationProperties) return [];
    const propertyIdOptions = selectedOrganisationProperties.map((property) => {
      return {
        ...property,
        title: `${property.officialName} - ${property.title}`,
        description: property.description,
        value: property.propId,
        checked: properties.indexOf(property.propId) !== -1
      };
    });
    return propertyIdOptions;
  };

  getPropertyValues = () => {
    const { properties } = this.state;
    return properties;
  };

  isInvalid = () => {
    return this.valueIsEmpty("title") || this.valueIsEmpty("properties");
  };

  callCreatePropertyGroup = async () => {
    try {
      const { orgId } = this.props;
      const title = this.getValue("title");
      const description = this.getValue("description");
      const properties = this.getValue("properties");
      const { createPropertyGroup } = this.props;
      this.showCreatingModal();
      await createPropertyGroup(orgId, title, description, properties);
    } catch (e) {
      throw new Error(e);
    }
  };

  showCreatingModal = () => {
    const { showModal, setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("CREATING_LABEL"),
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    showModal();
  };

  showCreateSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_CREATED_LABEL", {
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      body: getLabel("ITEM_CREATED_SUCCESS", {
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("ADD_ANOTHER_LABEL", {
            item: getLabel("PROPERTY_GROUP_TEXT")
          }),
          onClick: this.addAnother
        },
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("PROPERTY_GROUP_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  addAnother = () => {
    const { hideModal } = this.props;
    this.setValue("", "title");
    this.setValue("", "description");
    this.setValue([], "properties");
    hideModal();
  };

  returnToOverviewAction = () => {
    const { hideModal, orgId } = this.props;

    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${PROPERTY_GROUPS_URL}/`);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: "primary",
        isDisabled: this.isInvalid(),
        onClick: this.callCreatePropertyGroup,
        buttonLabel: getLabel("ADD_NEW_LABEL", {
          item: getLabel("PROPERTY_GROUP_TEXT")
        })
      }
    ];
  };

  isLoading = () => {
    const {
      selectedPropertyGroup,
      selectedPropertyGroupStatus,
      selectedOrganisationProperties
    } = this.props;
    return (
      selectedPropertyGroup ||
      selectedOrganisationProperties ||
      selectedPropertyGroupStatus === STATUS_LOADING
    );
  };

  isPropertiesLoading = () => {
    const { selectedOrganisationPropertiesLoading } = this.props;
    return selectedOrganisationPropertiesLoading;
  };

  getOrgPropertiesTotal = () => {
    const { selectedOrgPropertiesTotal } = this.props;
    return selectedOrgPropertiesTotal;
  };

  showPagination = () => {
    return this.getOrgPropertiesTotal() > this.getPageLimit();
  };

  getCurrentPageNumber = () => {
    const { selectedOrgPropertiesPageNumber } = this.props;
    return selectedOrgPropertiesPageNumber;
  };

  getFilterText = () => {
    const { propertiesFilterText } = this.state;
    return propertiesFilterText;
  };

  setPaginationContent = (value) => {
    const { orgId, setOrgPropertiesPagination, loadOrganisationProperties } =
      this.props;
    const searchValue = this.getFilterText();
    const result = this.getPageLimit();
    let offset = (value - 1) * result;
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(value, 10, offset, searchValue);
  };

  getPageLimit = () => {
    return getSession(`properties-${PAGINATION_KEY}`) || PAGINATION_OPTIONS[0];
  };

  dropDownOnSelectFunction = (dropdown) => {
    this.setState({ currentPageNumber: 1 });
    const { orgId, loadOrganisationProperties, setOrgPropertiesPagination } =
      this.props;
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(0, dropdown.value, 0, "");
    storeSession(`properties-${PAGINATION_KEY}`, dropdown.value);
  };

  showDropdown = () => {
    return this.getOrgPropertiesTotal() > this.getDropDownOptions()[0].value;
  };

  getDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit()
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getPropertiesFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("PROPERTIES_TEXT")
    });
  };

  setFilterText = (value) => {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearInterval(searchTimeout);
    }
    this.setState(
      {
        propertiesFilterText: value
      },
      () => {
        if (value.length >= MINIMUM_SEARCH_STRING_LENGTH || value === "") {
          const searchTimeout = setTimeout(() => {
            this.setPaginationContent(1);
          }, SEARCH_TIMEOUT);
          this.setState({ searchTimeout });
        }
      }
    );
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoading() === true ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
              />
            </Container>

            <Container>
              <Section>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <FormLabel
                    styleType={
                      this.valueIsEmpty("title") ? STYLETYPE_ERROR : ""
                    }
                    htmlFor="property-group-title"
                  >
                    {getLabel("FORM_TITLE_LABEL")}
                  </FormLabel>
                  <FormInput
                    value={this.getValue("title")}
                    onChange={(value) => {
                      this.setValue(value, "title");
                    }}
                    styleType={
                      this.valueIsEmpty("title") ? STYLETYPE_ERROR : ""
                    }
                    id="property-group-title"
                    required={true}
                    ariaDescribedBy={
                      this.valueIsEmpty("title")
                        ? "property-group-title-error"
                        : null
                    }
                  />
                  {this.valueIsEmpty("title") && (
                    <P
                      styleType={STYLETYPE_ERROR}
                      id="property-group-title-error"
                    >
                      {getLabel("FORM_FIELD_EMPTY_LABEL")}
                    </P>
                  )}
                </Container>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <FormLabel
                    htmlFor="property-group-description"
                    id="property-group-description-label"
                  >
                    {getLabel("FORM_LABEL_DESCRIPTION")}
                  </FormLabel>
                  <TextArea
                    value={this.getValue("description")}
                    onChange={(value) => {
                      this.setValue(value, "description");
                    }}
                    id="property-group-description"
                    size={FIELD_SIZE_SMALL}
                    styleType={STYLETYPE_TEXT}
                  />
                </Container>
                <FilterInput
                  inputId={`${this.getPropertiesFilterLabel()}-input`}
                  label={this.getPropertiesFilterLabel()}
                  liveFilter={true}
                  placeholder={"e.g: Orchard 1"}
                  filterFunction={(event) => {
                    this.setFilterText(event);
                  }}
                  styleType={DATA_TABLE_FILTER_INPUT_STYLING}
                />
                {this.isPropertiesLoading() ? (
                  <Loader fullPage={false} />
                ) : (
                  <Container styleType={STYLETYPE_CHECKBOX_CONTAINER}>
                    <Checkbox
                      handleUpdateValue={this.setPropertyValues}
                      options={this.getPropertyOptions()}
                      value={this.getPropertyValues()}
                      name={"propIds"}
                      legend={getLabel("SELECT_HEADER", {
                        item: getLabel("PROPERTIES_TEXT")
                      })}
                    />
                  </Container>
                )}
                <Container
                  direction={"row"}
                  styleType={DATA_TABLE_PAGINATION_STYLING}
                >
                  {this.showDropdown() && (
                    <Dropdown
                      id={"add-property-group"}
                      placeholder={"Select option"}
                      onClick={this.dropDownOnSelectFunction}
                      label={this.getDropDownLabel()}
                      value={this.getPageLimit()}
                      options={this.getDropDownOptions()}
                    />
                  )}
                  {this.showPagination() && (
                    <PaginationV2
                      currentPageNumber={this.getCurrentPageNumber()}
                      listPerPageNum={this.getPageLimit()}
                      totalItems={this.getOrgPropertiesTotal()}
                      setPaginationContent={this.setPaginationContent}
                      showPaginationInput={true}
                    />
                  )}
                </Container>
                <BottomButtonBar buttons={this.getButtons()} />
              </Section>
            </Container>
          </Wrapper>
        )}
      </Container>
    );
  }
}
