import React, { Component } from "react";
import {
  Loader,
  ContextHeader,
  BottomButtonBar,
  Attachments,
  ToggleSwitch,
  RadioGroup,
  Dropdown,
  DataTable,
  defaultTheme
} from "@UIKit";
import {
  CONTENT_VIEW,
  STYLETYPE_ERROR,
  STYLETYPE_FORM_FIELD,
  CONTEXT_HEADER_CONTAINER,
  STYLE_TYPE_NUMBER,
  STATUS_CREATING,
  STATUS_READY,
  STATUS_UPDATING,
  HOME_URL,
  STYLETYPE_HELPER,
  FIELD_SIZE_SMALL,
  STYLETYPE_TEXT,
  STYLETYPE_FORM_FIELD_FLEX,
  STYLETYPE_HELPTEXT,
  ALWAYS,
  NEVER,
  OPTIONAL,
  PROPERTY_SELECTOR_BY_TITLE,
  PROPERTY_SELECTOR_BY_OFFICIALNAME,
  DISTANCE_UNIT_OPTIONS,
  AREA_UNIT_OPTIONS,
  DISTANCE_UNIT_TEXT,
  AREA_UNIT_TEXT,
  BASE_MAP_OPTIONS,
  STYLE_TYPE_TABLE_BUTTON,
  DATA_TABLE_SECTION_STYLING,
  STYLE_TYPE_CUSTOM_MAP,
  STYLE_TYPE_CUSTOM_MAP_LAYER,
  CUSTOM_MAP_LAYER_TYPE_OPTIONS,
  LAYER_TEXT,
  LABEL_TEXT,
  SECTION_HEADER_STYLING,
  HEIGHT_AUTO_TEXT
} from "@base/constants";
import { P, Legend, Heading3 } from "@typography";
import {
  Container,
  Wrapper,
  FormLabel,
  FormInput,
  TextArea,
  Section,
  Checkbox,
  Fieldset,
  Button,
  CheckboxV2
} from "@core";
import { navigate } from "@reach/router";

class AddOrganisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgName: "",
      orgDescription: "",
      enable3D: false,
      spatialRef: 2193,
      logo: "",
      sendEmailOption: OPTIONAL,
      propertySeletorOption: PROPERTY_SELECTOR_BY_TITLE,
      propertySeletorCustomLabel: "${this.}",
      layerRendererSelectorOption: "",
      layerRendererJSON: "{}",
      labelRendererSelectorOption: "",
      labelRendererJSON: "{}",
      widgets: [],
      measureWidget: true,
      layerWidget: true,
      dataSets: [],
      preferredDistanceUnit: "meters",
      preferredAreaUnit: "hectares",
      searchByAddressEnabled: false,
      addressSearchRadius: null,
      baseMaps: BASE_MAP_OPTIONS[0],
      propertyFavourites: true,
      toggleSideBar: true,
      enablePreferences: true,
      enableMobileImageryDownload: true,
      customLayers: [
        {
          title: "",
          url: "",
          type: "",
          showLabels: false,
          showLayer: false
        }
      ]
    };
  }

  componentDidMount() {
    this.loadOrganisations();
  }

  loadOrganisations = () => {
    const {
      loadOrganisations,
      organisationsLoading,
      loadDatasetTemplates,
      loadLayerRendererTemplates,
      loadLabelRendererTemplates
    } = this.props;
    if (!organisationsLoading) loadOrganisations();
    loadDatasetTemplates();
    loadLayerRendererTemplates();
    loadLabelRendererTemplates();
  };

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      pageTitle,
      setPageTitle,
      getLabel,
      createOrgStatus,
      layerRendererTemplates,
      labelRendererTemplates,
      rendererTemplatesLoading
    } = this.props;
    if (
      pageTitle !==
      getLabel("ADD_NEW_LABEL", { item: getLabel("ORGANISATION_TEXT") })
    ) {
      setPageTitle(
        getLabel("ADD_NEW_LABEL", { item: getLabel("ORGANISATION_TEXT") })
      );
    }
    if (
      (prevProps.createOrgStatus === STATUS_CREATING ||
        prevProps.createOrgStatus === STATUS_UPDATING) &&
      createOrgStatus === STATUS_READY
    ) {
      this.showSuccess();
    }
    if (
      prevProps.rendererTemplatesLoading !== rendererTemplatesLoading &&
      layerRendererTemplates.length !== 0 &&
      labelRendererTemplates.length !== 0
    ) {
      this.updateState();
    }
    if (this.valueIsEmpty("dataSets")) {
      this.initialisePropertyDataset();
    }
  }

  updateState = () => {
    const { layerRendererTemplates, labelRendererTemplates } = this.props;
    this.setState({
      layerRendererSelectorOption: layerRendererTemplates[0].templateId,
      labelRendererSelectorOption: labelRendererTemplates[0].templateId
    });
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_CREATED_LABEL", {
        item: getLabel("ORGANISATION_TEXT")
      }),
      body: getLabel("ITEM_CREATED_LABEL", {
        item: getLabel("ORGANISATION_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("ORGANISATIONS_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  returnToOverviewAction = () => {
    const { hideModal } = this.props;

    hideModal();
    navigate(HOME_URL);
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("CREATE_NEW_LABEL", {
      item: getLabel("ORGANISATION_TEXT")
    });
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  getOrganisationTitles = () => {
    const { organisations } = this.props;
    if (!organisations) return false;
    return organisations.map((item) => item.title.toLowerCase());
  };

  organisationTitleExists = () => {
    const orgTitles = this.getOrganisationTitles();
    if (!orgTitles) return;
    const title = this.getValue("orgName");
    return orgTitles.includes(title.toLowerCase());
  };

  valueIsEmpty = (field) => {
    return (
      !this.getValue(field) ||
      (this.getValue(field) && this.getValue(field).length === 0) ||
      this.getValue(field) === ""
    );
  };

  isInvalid = () => {
    return this.valueIsEmpty("orgName") || this.organisationTitleExists();
  };

  getValidationError = () => {
    const { getLabel } = this.props;
    return this.valueIsEmpty("orgName")
      ? getLabel("FORM_FIELD_EMPTY_LABEL")
      : this.organisationTitleExists()
      ? getLabel("ORGANISATION_UNIQUE_TITLE_ERROR")
      : null;
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("CREATING_LABEL"),
        item: getLabel("ORGANISATION_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  getOrgLayerRenderer = () => {
    const { layerRendererJSON, layerRendererSelectorOption } = this.state;
    const layerRendererTemplates = this.getOrgLayerRendererTemplates();

    const renderer = layerRendererTemplates.find(
      (item) => item.templateId === layerRendererSelectorOption
    );
    return renderer
      ? { template: { templateId: renderer.templateId } }
      : { type: "layerRenderersJSON", renderer: JSON.parse(layerRendererJSON) };
  };

  getOrgLabelRenderer = () => {
    const { labelRendererJSON, labelRendererSelectorOption } = this.state;
    const labelRendererTemplates = this.getOrgLabelRendererTemplates();

    const renderer = labelRendererTemplates.find(
      (item) => item.templateId === labelRendererSelectorOption
    );
    return renderer
      ? { template: { templateId: renderer.templateId } }
      : { type: "layerLabelsJSON", renderer: JSON.parse(labelRendererJSON) };
  };

  getOrgUnits = () => {
    const { preferredAreaUnit, preferredDistanceUnit } = this.state;
    return {
      areaUnits: AREA_UNIT_OPTIONS,
      distanceUnits: DISTANCE_UNIT_OPTIONS,
      preferredAreaUnit,
      preferredDistanceUnit
    };
  };

  getAddressSearchSettings = () => {
    const { searchByAddressEnabled, addressSearchRadius } = this.state;
    const searchRadius = addressSearchRadius ? Number(addressSearchRadius) : 5;
    return searchByAddressEnabled
      ? { searchByAddressEnabled, addressSearchRadius: searchRadius }
      : { searchByAddressEnabled };
  };

  getCustomLayers = () => {
    const { customLayers } = this.state;
    return customLayers.filter((el) => {
      return el.title !== "" && el.url !== "" && el.type !== "";
    });
  };

  callAction = () => {
    const { layerWidget, measureWidget, dataSets, baseMaps } = this.state;
    const { adminId, createOrganisation, showModal } = this.props;
    const widgets = [
      ...(layerWidget ? ["layers"] : []),
      ...(measureWidget ? ["measure"] : [])
    ];
    const units = this.getOrgUnits();
    const addressSearchSettings = this.getAddressSearchSettings();
    const customLayers = this.getCustomLayers();
    const layersList = [{ type: "baseMap", ...baseMaps }, ...customLayers];

    this.showLoading();
    showModal();
    createOrganisation({
      orgDetails: {
        adminId,
        dataSets: dataSets.map((dataset) => dataset.templateId),
        title: this.getValue("orgName"),
        description: this.getValue("orgDescription"),
        preferences: {
          logo: this.getValue("logo"),
          enable3D: this.getValue("enable3D"),
          defaultSpatialReference: this.getValue("spatialRef"),
          sendVerificationEmails: this.getValue("sendEmailOption"),
          propertySelectorLabel: this.getValue("propertySeletorOption"),
          layerRendererJSON: this.getOrgLayerRenderer(),
          labelRendererJSON: this.getOrgLabelRenderer(),

          propertyFavoritesEnabled: this.getValue("propertyFavourites"),
          toggleSidebar: this.getValue("toggleSideBar"),
          enablePreferences: this.getValue("enablePreferences"),
          mobile: {
            disableImageryDownload: !this.getValue(
              "enableMobileImageryDownload"
            )
          },
          widgets,
          units,
          layers: layersList,
          ...addressSearchSettings
        }
      }
    });
  };

  hasInvalidRow = () => {
    const { customLayers } = this.state;
    return customLayers
      .filter((el) => el.title === "" || el.url === "" || el.type === "")
      .some((el) => el.title !== "" || el.url !== "" || el.type !== "");
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: "primary",
        isDisabled: this.isInvalid() || this.hasInvalidRow(),
        onClick: this.callAction,
        buttonLabel: getLabel("ADD_NEW_LABEL", {
          item: getLabel("ORGANISATION_TEXT")
        })
      },
      {
        buttonStyleType: "secondary",
        isDisabled: false,
        onClick: this.returnToOverviewAction,
        buttonLabel: getLabel("BACK_TO_OVERVIEW_BUTTON", { item: "" })
      }
    ];
  };

  setIsToggled = () => {
    this.setValue(!this.getValue("enable3D"), "enable3D");
  };

  renderEnable3Doptions = () => {
    const { enable3D } = this.state;
    const { getLabel } = this.props;

    const mapsSetTo = enable3D
      ? getLabel("3D_ENABLED_LABEL")
      : getLabel("3D_DISABLED_LABEL");

    return (
      <ToggleSwitch
        id={"map-label"}
        label={mapsSetTo}
        isToggled={this.getValue("enable3D")}
        toggleActiveStatus={this.setIsToggled}
      />
    );
  };

  getImportFiles = () => {
    const { logo } = this.state;
    return logo ? [logo] : [];
  };

  handleClearLogo = () => {
    this.setState({
      logo: null
    });
  };

  handleSelectLogo = (files) => {
    this.setState({
      logo: files[0]
    });
  };

  getOrgDescriptionValidationError = () => {
    const { getLabel } = this.props;
    const description = this.getValue("orgDescription");
    if (description && description.length > 500)
      return getLabel("RULE_MAX_CHAR_LENGTH_LABEL", {
        item: 500
      });
    return "";
  };

  renderSendEmailOptions = () => {
    const { getLabel } = this.props;
    return (
      <RadioGroup
        legend={getLabel("SEND_EMAIL_LABEL")}
        value={this.getValue("sendEmailOption")}
        isDisabled={false}
        name={"sendEmailOptionForm"}
        radioButtons={[
          {
            label: getLabel("SEND_EMAIL_OPTIONS_LABEL", {
              item: ALWAYS
            }),
            value: ALWAYS,
            id: "always-send-email"
          },
          {
            label: getLabel("SEND_EMAIL_OPTIONS_LABEL", {
              item: NEVER
            }),
            value: NEVER,
            id: "never-send-email"
          },
          {
            label: getLabel("SEND_EMAIL_OPTIONAL_LABEL"),
            value: OPTIONAL,
            id: "optional-send-email"
          }
        ]}
        onClick={(event) => {
          this.setValue(event, "sendEmailOption");
        }}
      />
    );
  };

  toggleFieldValue = (field) => {
    this.setValue(!this.getValue(field), field);
  };

  renderPropertyFavouritesOptions = () => {
    const { getLabel } = this.props;
    const propertyFavouriteLabel = this.getValue("propertyFavourites")
      ? getLabel("ENABLED_LABEL")
      : getLabel("DISABLED_LABEL");

    return (
      <Container styleType={STYLETYPE_FORM_FIELD}>
        <Fieldset>
          <FormLabel htmlFor="property-favourites-label">
            {getLabel("PROPERY_FAVOURITES_LABEL")}
          </FormLabel>
          <ToggleSwitch
            id={"property-favourites-toggle-label"}
            label={propertyFavouriteLabel}
            isToggled={this.getValue("propertyFavourites")}
            toggleActiveStatus={() => {
              this.toggleFieldValue("propertyFavourites");
            }}
          />
        </Fieldset>
      </Container>
    );
  };

  renderToggleSideBarOptions = () => {
    const { getLabel } = this.props;
    const propertyFavouriteLabel = this.getValue("toggleSideBar")
      ? getLabel("ENABLED_LABEL")
      : getLabel("DISABLED_LABEL");

    return (
      <Container styleType={STYLETYPE_FORM_FIELD}>
        <Fieldset>
          <FormLabel htmlFor="toggle-sidebar-label">
            {getLabel("TOGGLE_SIDEBAR_LABEL")}
          </FormLabel>
          <ToggleSwitch
            id={"toggle-sidebar-toggle-label"}
            label={propertyFavouriteLabel}
            isToggled={this.getValue("toggleSideBar")}
            toggleActiveStatus={() => {
              this.toggleFieldValue("toggleSideBar");
            }}
          />
        </Fieldset>
      </Container>
    );
  };

  renderEnablePreferencesOptions = () => {
    const { getLabel } = this.props;
    const propertyFavouriteLabel = this.getValue("enablePreferences")
      ? getLabel("ENABLED_LABEL")
      : getLabel("DISABLED_LABEL");

    return (
      <Container styleType={STYLETYPE_FORM_FIELD}>
        <Fieldset>
          <FormLabel htmlFor="enable-preferences-label">
            {getLabel("ENABLE_PREFERENCE_LABEL")}
          </FormLabel>
          <ToggleSwitch
            id={"enable-preferences-toggle-label"}
            label={propertyFavouriteLabel}
            isToggled={this.getValue("enablePreferences")}
            toggleActiveStatus={() => {
              this.toggleFieldValue("enablePreferences");
            }}
          />
        </Fieldset>
      </Container>
    );
  };

  renderEnableMobileImageryDownloadOptions = () => {
    const { getLabel } = this.props;
    const propertyFavouriteLabel = this.getValue("enableMobileImageryDownload")
      ? getLabel("ENABLED_LABEL")
      : getLabel("DISABLED_LABEL");

    return (
      <Container styleType={STYLETYPE_FORM_FIELD}>
        <Fieldset>
          <FormLabel htmlFor="enable-mobile-imagery-label">
            {getLabel("ENABLE_MOBILE_IMAGERY_LABEL")}
          </FormLabel>
          <ToggleSwitch
            id={"enable-mobile-imagery-toggle-label"}
            label={propertyFavouriteLabel}
            isToggled={this.getValue("enableMobileImageryDownload")}
            toggleActiveStatus={() => {
              this.toggleFieldValue("enableMobileImageryDownload");
            }}
          />
        </Fieldset>
      </Container>
    );
  };

  renderPropertySelectorOptions = () => {
    const { getLabel } = this.props;
    return (
      <RadioGroup
        legend={getLabel("PROPERTY_SELECTOR_FORM_LABEL")}
        value={this.getValue("propertySeletorOption")}
        isDisabled={false}
        name={"propertySeletorOptionForm"}
        radioButtons={[
          {
            label: getLabel("SELECT_BY_ITEM_LABEL", {
              item: getLabel("FORM_TITLE_LABEL")
            }),
            value: PROPERTY_SELECTOR_BY_TITLE,
            id: "property-selector-by-title"
          },
          {
            label: getLabel("SELECT_BY_ITEM_LABEL", {
              item: getLabel("OFFICIAL_NAME_LABEL")
            }),
            value: PROPERTY_SELECTOR_BY_OFFICIALNAME,
            id: "property-selector-by-official-name"
          },
          {
            label: getLabel("CUSTOM_LABEL_TEXT"),
            value: this.getValue("propertySeletorCustomLabel"),
            id: "property-selector-by-custom-label"
          }
        ]}
        onClick={(event) => {
          this.setValue(event, "propertySeletorOption");
        }}
      />
    );
  };

  renderCustomLabelForm = () => {
    const { getLabel } = this.props;
    const { propertySeletorOption } = this.state;
    if (
      propertySeletorOption === PROPERTY_SELECTOR_BY_TITLE ||
      propertySeletorOption === PROPERTY_SELECTOR_BY_OFFICIALNAME
    )
      return false;

    return (
      <Container styleType={STYLETYPE_FORM_FIELD}>
        <FormLabel htmlFor="property-selector-custom-label">
          {getLabel("CUSTOM_LABEL_FOR_PROPERTIES_LABEL")}
        </FormLabel>
        <FormInput
          value={this.getValue("propertySeletorOption")}
          onChange={(value) => {
            this.setValue(value, "propertySeletorOption");
          }}
          placeholder={getLabel("CUSTOM_LABEL_PLACEHOLDER")}
          id="property-selector-custom-label"
        />
        <P styleType={STYLETYPE_HELPTEXT} id="cutomLabel-helper">
          {getLabel("PROPERTY_SELECTOR_HELPTEXT_LABEL")}
        </P>
      </Container>
    );
  };

  getMeasureWidgetHeading = () => {
    const { getLabel } = this.props;
    return getLabel("WIDGET_TYPE_LABEL", {
      item: getLabel("MEASURE_LABEL")
    });
  };

  setMeasureWidget = () => {
    this.setValue(!this.getValue("measureWidget"), "measureWidget");
  };

  renderMeasureWidgetOptions = () => {
    const { measureWidget } = this.state;
    const { getLabel } = this.props;
    const measureLabel = getLabel("WIDGET_DISPLAY_LABEL", {
      item: getLabel("MEASURE_LABEL"),
      status: measureWidget
        ? getLabel("ENABLED_LABEL")
        : getLabel("DISABLED_LABEL")
    });

    return (
      <ToggleSwitch
        id={"measure-widget-label"}
        label={measureLabel}
        isToggled={this.getValue("measureWidget")}
        toggleActiveStatus={this.setMeasureWidget}
      />
    );
  };

  getLayerWidgetHeading = () => {
    const { getLabel } = this.props;
    return getLabel("WIDGET_TYPE_LABEL", {
      item: getLabel("LAYER_LABEL")
    });
  };

  setLayerWidget = () => {
    this.setValue(!this.getValue("layerWidget"), "layerWidget");
  };

  renderLayerWidgetOptions = () => {
    const { layerWidget } = this.state;
    const { getLabel } = this.props;
    const layerLabel = getLabel("WIDGET_DISPLAY_LABEL", {
      item: getLabel("LAYER_LABEL"),
      status: layerWidget
        ? getLabel("ENABLED_LABEL")
        : getLabel("DISABLED_LABEL")
    });

    return (
      <ToggleSwitch
        id={"layer-widget-label"}
        label={layerLabel}
        isToggled={this.getValue("layerWidget")}
        toggleActiveStatus={this.setLayerWidget}
      />
    );
  };

  getOrgLayerRendererTemplates = () => {
    const { layerRendererTemplates } = this.props;
    return layerRendererTemplates && layerRendererTemplates.length !== 0
      ? layerRendererTemplates
      : [];
  };

  getOrgLabelRendererTemplates = () => {
    const { labelRendererTemplates } = this.props;
    return labelRendererTemplates && labelRendererTemplates.length !== 0
      ? labelRendererTemplates
      : [];
  };

  getOrgRendererLabelByType = (type) => {
    const { getLabel } = this.props;
    return getLabel("RENDERERS_FOR_ORGANISATION_LABEL", {
      item: getLabel(type === LAYER_TEXT ? "LAYER_LABEL" : "LABEL_TEXT")
    });
  };

  getOrgRendererButtonOptionsByType = (type) => {
    const { getLabel } = this.props;
    const rendererTemplates =
      type === LAYER_TEXT
        ? this.getOrgLayerRendererTemplates()
        : this.getOrgLabelRendererTemplates();
    return rendererTemplates.map((item) => ({
      label: getLabel("RENDERERS_USE_TEMPLATE_LABEL", {
        item: item.title
      }),
      value: item.templateId,
      id: `${item.title.toLowerCase()}-renderer`
    }));
  };

  renderRadioGroupByRendererType = (type) => {
    const { getLabel } = this.props;
    return (
      <RadioGroup
        legend={this.getOrgRendererLabelByType(type)}
        value={this.getValue(`${type}RendererSelectorOption`)}
        isDisabled={false}
        name={`${type}RendererSelectorOptionForm`}
        radioButtons={[
          ...this.getOrgRendererButtonOptionsByType(type),
          {
            label: getLabel("RENDERERS_CUSTOM_LABEL", {
              item: getLabel(type === LAYER_TEXT ? "LAYER_LABEL" : "LABEL_TEXT")
            }),
            value: "customRenderer",
            id: `custom-${type}-renderer`
          }
        ]}
        onClick={(event) => {
          this.setValue(event, `${type}RendererSelectorOption`);
        }}
      />
    );
  };

  renderCustomFormByRendererType = (type) => {
    const { getLabel } = this.props;
    const rendererSelectorOption = this.state[`${type}RendererSelectorOption`];
    const rendererTemplates =
      type === LAYER_TEXT
        ? this.getOrgLayerRendererTemplates()
        : this.getOrgLabelRendererTemplates();
    if (
      rendererTemplates.some(
        (item) => item.templateId === rendererSelectorOption
      )
    )
      return false;
    return (
      <Container styleType={STYLETYPE_FORM_FIELD}>
        <FormLabel htmlFor={`org-custom-${type}-renderer`}>
          {getLabel("CUSTOM_RENDERER_FOR_ORG_LABEL", {
            item: getLabel(type === LAYER_TEXT ? "LAYER_LABEL" : "LABEL_TEXT")
          })}
        </FormLabel>
        <TextArea
          value={this.getValue(`${type}RendererJSON`)}
          onChange={(value) => {
            this.setValue(value, `${type}RendererJSON`);
          }}
          id={`org-custom-${type}-renderer`}
          size={FIELD_SIZE_SMALL}
          styleType={STYLETYPE_TEXT}
          labelledBy={`org-custom-${type}-renderer`}
        />
      </Container>
    );
  };

  isDetailsLoading = () => {
    const { datasetTemplatesLoading, rendererTemplatesLoading } = this.props;
    return datasetTemplatesLoading || rendererTemplatesLoading;
  };

  getDropdownValue = (type) => {
    const preferredUnit = `preferred${type}`;
    return this.state[preferredUnit];
  };

  dropDownOnSelectFunctionByType = (type, value) => {
    const preferredUnit = `preferred${type}`;
    this.setValue(value, preferredUnit);
  };

  getDropDownLabel = (type) => {
    const preferredUnit = `preferred${type}`;
    return this.state[preferredUnit];
  };

  getDropDownOptionsByType = (type) => {
    const options =
      type === DISTANCE_UNIT_TEXT ? DISTANCE_UNIT_OPTIONS : AREA_UNIT_OPTIONS;
    return options.map((value) => ({
      value: value.unit,
      label: value.unit
    }));
  };

  setSearchByAddress = () => {
    this.setValue(
      !this.getValue("searchByAddressEnabled"),
      "searchByAddressEnabled"
    );
  };

  renderSearchByRadiusForm = () => {
    const { getLabel } = this.props;
    return (
      <Container styleType={STYLETYPE_FORM_FIELD}>
        <FormLabel htmlFor="organisation-search-radius">
          {getLabel("SEARCH_BY_ADDRESS_RADIUS_LABEL")}
        </FormLabel>
        <FormInput
          type={STYLE_TYPE_NUMBER}
          value={this.getValue("addressSearchRadius")}
          onChange={(value) => {
            this.setValue(value, "addressSearchRadius");
          }}
          placeholder={"e.g. 5"}
          id="organisation-search-radius"
        />
        <P styleType={STYLETYPE_HELPTEXT} id="org-searchRadius-helper">
          {getLabel("SEARCH_BY_ADDRESS_HELPTEXT_LABEL")}
        </P>
      </Container>
    );
  };

  renderSearchByAddressOption = () => {
    const { searchByAddressEnabled } = this.state;
    const { getLabel } = this.props;

    const label = getLabel("SEARCH_BY_ADDRESS_STATE", {
      item: searchByAddressEnabled
        ? getLabel("ENABLED_LABEL")
        : getLabel("DISABLED_LABEL")
    });

    return (
      <Container styleType={STYLETYPE_FORM_FIELD}>
        <FormLabel htmlFor={"search-by-address-label"}>
          {getLabel("SEARCH_BY_ADDRESS")}
        </FormLabel>
        <ToggleSwitch
          label={label}
          id={"search-by-address-label"}
          isToggled={searchByAddressEnabled}
          toggleActiveStatus={this.setSearchByAddress}
        />
        {searchByAddressEnabled && this.renderSearchByRadiusForm()}
      </Container>
    );
  };

  dropDownOnSelectFunction = (value, key) => {
    this.setValue(value, key);
  };

  getBaseMaps = () => this.state.baseMaps;

  getBaseMapsDropDownOptions = () => {
    return BASE_MAP_OPTIONS.map((value) => ({
      value: value.id,
      label: value.title
    }));
  };

  handleBaseMapsSelection = (value) => {
    const baseMaps = BASE_MAP_OPTIONS.filter((item) => item.id === value)[0];
    this.setState({ baseMaps });
  };

  renderBaseMapsDropdown = () => {
    const { getLabel } = this.props;
    return (
      <Container>
        <FormLabel htmlFor="organisation-basemap">
          {getLabel("BASE_MAP_LABEL")}
        </FormLabel>
        <Dropdown
          id={"organisation-basemap"}
          placeholder={"Select option"}
          onClick={(e) => this.handleBaseMapsSelection(e.value)}
          label={this.getBaseMaps().title}
          value={this.getBaseMaps().id}
          options={this.getBaseMapsDropDownOptions()}
        />
      </Container>
    );
  };

  getDropDownOptions = (options) => {
    return options.map((value) => ({
      value: value,
      label: value
    }));
  };

  getTableHeader = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        {
          content: getLabel("LAYER_NAME_LABEL")
        },
        {
          content: getLabel("LAYER_URL_LABEL")
        },
        {
          content: getLabel("LAYER_TYPE_LABEL")
        },
        {
          content: getLabel("SHOW_LABELS_LABEL")
        },
        {
          content: getLabel("SHOW_LAYER_LABEL")
        }
      ]
    };
  };

  removeLayerFromTable = (i) => {
    const { customLayers } = this.state;
    this.setState({
      customLayers: customLayers.filter((layer, index) => i !== index)
    });
  };

  getShowLayerAndRemoveOption = (showLayer = false, i) => {
    const { getLabel } = this.props;
    const editable = i === this.getValue("customLayers").length - 1;
    const fieldInput = editable
      ? this.renderShowLayerCheckbox(showLayer, i)
      : showLayer.toString();
    return (
      <Container direction={"row"} styleType={STYLE_TYPE_CUSTOM_MAP}>
        {fieldInput}
        {editable ? (
          <Button
            buttonLabel={getLabel("ADD_ANOTHER_LABEL", {
              item: getLabel("LAYER_LABEL")
            })}
            isDisabled={this.getLayerAddButtonDisabled(i)}
            onClick={this.addLayerToTable}
            buttonStyleType={STYLE_TYPE_TABLE_BUTTON}
            icon={{
              type: "plus",
              bgWidth: "1.4em",
              bgHeight: "1.4em",
              iconHeight: "0.8em",
              iconWidth: "0.8em",
              iconColor: defaultTheme.agBrightGreen
            }}
          />
        ) : (
          <Button
            buttonLabel={getLabel("REMOVE_ACTION_LABEL")}
            isDisabled={false}
            onClick={() => this.removeLayerFromTable(i)}
            buttonStyleType={STYLE_TYPE_TABLE_BUTTON}
            icon={{
              type: "close",
              bgWidth: "1.4em",
              bgHeight: "1.4em",
              iconHeight: "0.8em",
              iconWidth: "0.8em",
              iconColor: defaultTheme.agBrightGreen
            }}
          />
        )}
      </Container>
    );
  };

  getTableData = () => {
    const { customLayers } = this.state;
    return customLayers.map((data, i) => {
      const { title, url, type, showLabels, showLayer } = data;
      return {
        cells: [
          this.renderLayerNameForm(title, i),
          this.renderLayerURLForm(url, i),
          this.renderLayerTypeDropdown(type, i),
          this.renderShowLabelsCheckbox(showLabels, i),
          this.getShowLayerAndRemoveOption(showLayer, i)
        ]
      };
    });
  };

  getLayerAddButtonDisabled = (i) => {
    const { customLayers } = this.state;
    const layerItem = customLayers[i];
    return !layerItem || !layerItem.title || !layerItem.url || !layerItem.type;
  };

  setLayerField = (field, value, index) => {
    const { customLayers } = this.state;
    this.setState({
      customLayers: customLayers.map((layer, i) => {
        if (i !== index) return layer;
        return {
          ...layer,
          [field]: value
        };
      })
    });
  };

  renderLayerNameForm = (layerName, i) => {
    const { getLabel } = this.props;
    if (i !== this.getValue("customLayers").length - 1) return layerName;
    return (
      <Container styleType={STYLE_TYPE_CUSTOM_MAP_LAYER}>
        <FormInput
          aria-label={getLabel("LAYER_NAME_LABEL")}
          value={layerName}
          onChange={(value) => {
            this.setLayerField("title", value, i);
          }}
          styleType={!layerName ? STYLETYPE_ERROR : ""}
          id={`custom-map-layer-name-${i}`}
          required={true}
          ariaDescribedBy={!layerName ? `custom-map-layer-name-error-${i}` : ""}
        />
        {!layerName && (
          <FormLabel
            styleType={STYLETYPE_ERROR}
            htmlFor={`custom-map-layer-name-${i}`}
            id={`custom-map-layer-name-error-${i}`}
          >
            {getLabel("FIELD_REQUIRED_LABEL")}
          </FormLabel>
        )}
      </Container>
    );
  };

  renderLayerURLForm = (layerURL, i) => {
    const { getLabel } = this.props;
    if (i !== this.getValue("customLayers").length - 1) return layerURL;
    return (
      <Container styleType={STYLE_TYPE_CUSTOM_MAP_LAYER}>
        <FormInput
          aria-label={getLabel("LAYER_URL_LABEL")}
          value={layerURL}
          onChange={(value) => {
            this.setLayerField("url", value, i);
          }}
          styleType={!layerURL ? STYLETYPE_ERROR : ""}
          id={`custom-map-layer-url-${i}`}
          required={true}
          ariaDescribedBy={!layerURL ? `custom-map-layer-url-error-${i}` : ""}
        />
        {!layerURL && (
          <FormLabel
            styleType={STYLETYPE_ERROR}
            htmlFor={`custom-map-layer-url-${i}`}
            id={`custom-map-layer-url-error-${i}`}
          >
            {getLabel("FIELD_REQUIRED_LABEL")}
          </FormLabel>
        )}
      </Container>
    );
  };

  renderLayerTypeDropdown = (layerType, i) => {
    const { getLabel } = this.props;
    if (i !== this.getValue("customLayers").length - 1) return layerType;
    return (
      <Container styleType={STYLE_TYPE_CUSTOM_MAP_LAYER}>
        <Dropdown
          ariaLabel={getLabel("LAYER_TYPE_LABEL")}
          id={`layer-type-${i}`}
          placeholder={getLabel("SELECT_OPTION_LABEL")}
          onClick={(e) => this.setLayerField("type", e.value, i)}
          label={layerType}
          value={layerType}
          options={this.getDropDownOptions(CUSTOM_MAP_LAYER_TYPE_OPTIONS)}
          ariaDescribedBy={!layerType ? `custom-map-layer-type-error-${i}` : ""}
        />
        {!layerType && (
          <FormLabel
            styleType={STYLETYPE_ERROR}
            htmlFor={`layer-type-${i}`}
            id={`custom-map-layer-type-error-${i}`}
          >
            {getLabel("FIELD_REQUIRED_LABEL")}
          </FormLabel>
        )}
      </Container>
    );
  };

  renderShowLabelsCheckbox = (showLabels = false, i) => {
    const { getLabel } = this.props;
    if (i !== this.getValue("customLayers").length - 1)
      return showLabels.toString();
    return (
      <CheckboxV2
        handleUpdate={() => this.setLayerField("showLabels", !showLabels, i)}
        name={`showLabels-${i}`}
        value={showLabels}
        checked={showLabels}
        title={getLabel("SHOW_LABELS_LABEL")}
      />
    );
  };

  addLayerToTable = () => {
    const { customLayers } = this.state;
    this.setState({
      customLayers: [
        ...customLayers,
        {
          title: "",
          url: "",
          type: "",
          showLabels: false,
          showLayer: false
        }
      ]
    });
  };

  renderShowLayerCheckbox = (showLayer = false, i) => {
    const { getLabel } = this.props;
    return (
      <CheckboxV2
        handleUpdate={() => this.setLayerField("showLayer", !showLayer, i)}
        name={`showLayer-${i}`}
        value={showLayer}
        checked={showLayer}
        title={getLabel("SHOW_LAYER_LABEL")}
      />
    );
  };

  getDataSetsTemplates = () => {
    const { datasetTemplates } = this.props;
    return datasetTemplates || [];
  };

  setSelectAllDataSets = (e) => {
    this.setValue(
      e.currentTarget.checked
        ? this.getDataSetsTemplates().map((template) => ({
            title: template.title,
            templateId: template.templateId
          }))
        : this.getPropertyDataset(),
      "dataSets"
    );
  };

  getSelectAllDataSets = () => {
    const selectedDataSetsTemplates = this.getSelectedDataSetsTemplates();
    return this.getDataSetsTemplates().every(
      (template) =>
        template.title === "Property" ||
        selectedDataSetsTemplates.some(
          (selected) => selected.templateId === template.templateId
        )
    );
  };

  getSelectedDataSetsTemplates = () => {
    const { dataSets } = this.state;
    if (!dataSets) return [];
    return dataSets.filter((dataset) => dataset.title !== "Property");
  };

  getDataSetsTemplatesRows = () => {
    const selectedDataSetsTemplates = this.getSelectedDataSetsTemplates();
    return this.getDataSetsTemplates().map((template) => {
      const isChecked =
        selectedDataSetsTemplates.some(
          (selected) => selected.templateId === template.templateId
        ) || template.title === "Property";
      return {
        cells: [
          <CheckboxV2
            handleUpdate={this.setSelectedDataSets}
            name={"dataset"}
            id={template.templateId}
            rowId={template.templateId}
            value={template.templateId}
            checked={isChecked}
            title={`${template.title} - ${template.description}`}
            disabled={template.title === "Property"}
          />
        ],
        id: template.templateId
      };
    });
  };

  setSelectedDataSets = (e) => {
    const { value, checked } = e.currentTarget;
    const selectedDataSets = [
      ...this.getPropertyDataset(),
      ...this.getSelectedDataSetsTemplates()
    ];
    const template = this.getDataSetsTemplates().find(
      (template) => template.templateId === value
    );
    if (!template) return;
    const { title, templateId } = template;
    const newDataset = { title, templateId };
    this.setValue(
      checked
        ? [...selectedDataSets, newDataset]
        : selectedDataSets.filter((dataset) => dataset.templateId !== value),
      "dataSets"
    );
  };

  getPropertyDataset = () => {
    const datasetTemplates = this.getDataSetsTemplates();
    const propertyTemplate = datasetTemplates.find(
      (template) => template.title === "Property"
    );
    return propertyTemplate
      ? [{ title: "Property", templateId: propertyTemplate.templateId }]
      : [];
  };

  initialisePropertyDataset = () => {
    const propertyDataset = this.getPropertyDataset();
    if (propertyDataset.length) {
      this.setValue(propertyDataset, "dataSets");
    }
  };

  render() {
    const { getLabel, datasetTemplatesLoading } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isDetailsLoading() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
              />
            </Container>

            <Container>
              <Container
                styleType={STYLETYPE_FORM_FIELD_FLEX}
                direction={"row"}
              >
                <Container>
                  <Section>
                    <Heading3 styleType={SECTION_HEADER_STYLING}>
                      {getLabel("DETAILS_LABEL", {
                        item: getLabel("ORGANISATION_TEXT")
                      })}
                    </Heading3>
                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <FormLabel
                        styleType={this.isInvalid() ? STYLETYPE_ERROR : ""}
                        htmlFor="organisation-name"
                      >
                        {getLabel("FORM_LABEL_ORG_NAME")}
                      </FormLabel>
                      <FormInput
                        value={this.getValue("orgName")}
                        onChange={(value) => {
                          this.setValue(value, "orgName");
                        }}
                        styleType={this.isInvalid() ? STYLETYPE_ERROR : ""}
                        id="organisation-name"
                        required={true}
                        ariaDescribedBy={
                          this.getValidationError() && "organisation-name-error"
                        }
                      />
                      {this.getValidationError() && (
                        <P
                          styleType={STYLETYPE_ERROR}
                          id="organisation-name-error"
                        >
                          {this.getValidationError()}
                        </P>
                      )}
                    </Container>
                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <FormLabel
                        htmlFor="organisation-description"
                        id="org-description-label"
                      >
                        {getLabel("FORM_LABEL_ORG_DESCRIPTION")}
                      </FormLabel>
                      <TextArea
                        value={this.getValue("orgDescription")}
                        onChange={(value) => {
                          this.setValue(value, "orgDescription");
                        }}
                        id="organisation-description"
                        maxLength={500}
                        size={FIELD_SIZE_SMALL}
                        styleType={STYLETYPE_TEXT}
                        labelledBy={
                          "org-description-label org-description-helper"
                        }
                      />
                      <P
                        styleType={STYLETYPE_HELPER}
                        id="org-description-helper"
                      >
                        {getLabel("ORG_DESCRIPTION_HELPTEXT")}
                      </P>
                    </Container>
                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <Attachments
                        isEditable={true}
                        labels={{
                          add: getLabel("UPLOAD_ORG_LOGO_LABEL"),
                          drop: getLabel("FILE_DROPZONE_LABEL"),
                          invalid: getLabel("INVALID_FILE_TYPE_LABEL"),
                          fileAccept: getLabel("ACCEPTS_FILE_TYPES_LABEL")
                        }}
                        importFiles={this.getImportFiles()}
                        handleClearFile={this.handleClearLogo}
                        handleSelectFile={this.handleSelectLogo}
                      />
                    </Container>
                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <Fieldset>
                        <Legend>
                          {getLabel("FORM_LABEL_ORG_PREFERENCES")}
                        </Legend>
                        <Legend>{getLabel("WIDGETS_LABEL")}</Legend>
                        <FormLabel htmlFor="measure-widget-label">
                          {this.getMeasureWidgetHeading()}
                        </FormLabel>
                        {this.renderMeasureWidgetOptions()}
                        <FormLabel htmlFor="layer-widget-label">
                          {this.getLayerWidgetHeading()}
                        </FormLabel>
                        {this.renderLayerWidgetOptions()}
                      </Fieldset>
                    </Container>
                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <FormLabel htmlFor="organisation-spatialref">
                        {getLabel("ORG_SPATIAL_REFERENCE_LABEL")}
                      </FormLabel>
                      <FormInput
                        type={STYLE_TYPE_NUMBER}
                        value={this.getValue("spatialRef")}
                        onChange={(value) => {
                          this.setValue(value, "spatialRef");
                        }}
                        placeholder={getLabel(
                          "ORG_SPATIAL_REFERENCE_PLACEHOLDER"
                        )}
                        id="organisation-spatialref"
                        ariaDescribedBy={"org-description-helper"}
                      />
                      <P
                        styleType={STYLETYPE_HELPTEXT}
                        id="org-description-helper"
                      >
                        {getLabel("ORG_SPATIAL_REFERENCE_HELPTEXT")}
                      </P>
                    </Container>

                    <Container>
                      <FormLabel htmlFor="organisation-distance-unit">
                        {getLabel("DEFAULT_UNIT_LABEL", {
                          item: getLabel("DISTANCE_LABEL")
                        })}
                      </FormLabel>
                      <Dropdown
                        id={"organisation-distance-unit"}
                        placeholder={"Select option"}
                        onClick={(e) =>
                          this.dropDownOnSelectFunctionByType(
                            DISTANCE_UNIT_TEXT,
                            e.value
                          )
                        }
                        label={this.getDropDownLabel(DISTANCE_UNIT_TEXT)}
                        value={this.getDropdownValue(DISTANCE_UNIT_TEXT)}
                        options={this.getDropDownOptionsByType(
                          DISTANCE_UNIT_TEXT
                        )}
                      />
                    </Container>

                    <Container>
                      <FormLabel htmlFor="organisation-area-unit">
                        {getLabel("DEFAULT_UNIT_LABEL", {
                          item: getLabel("AREA_LABEL")
                        })}
                      </FormLabel>
                      <Dropdown
                        id={"organisation-area-unit"}
                        placeholder={"Select option"}
                        onClick={(e) =>
                          this.dropDownOnSelectFunctionByType(
                            AREA_UNIT_TEXT,
                            e.value
                          )
                        }
                        label={this.getDropDownLabel(AREA_UNIT_TEXT)}
                        value={this.getDropdownValue(AREA_UNIT_TEXT)}
                        options={this.getDropDownOptionsByType(AREA_UNIT_TEXT)}
                      />
                    </Container>

                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <Fieldset>{this.renderSendEmailOptions()}</Fieldset>
                    </Container>
                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <Fieldset>
                        {this.renderPropertySelectorOptions()}
                      </Fieldset>
                      {this.renderCustomLabelForm()}
                    </Container>

                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <Fieldset>
                        {this.renderRadioGroupByRendererType(LAYER_TEXT)}
                      </Fieldset>
                      {this.renderCustomFormByRendererType(LAYER_TEXT)}
                    </Container>

                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <Fieldset>
                        {this.renderRadioGroupByRendererType(LABEL_TEXT)}
                      </Fieldset>
                      {this.renderCustomFormByRendererType(LABEL_TEXT)}
                    </Container>

                    {this.renderSearchByAddressOption()}
                    {this.renderPropertyFavouritesOptions()}
                    {this.renderToggleSideBarOptions()}
                    {this.renderEnablePreferencesOptions()}
                    {this.renderEnableMobileImageryDownloadOptions()}

                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <Fieldset>{this.renderBaseMapsDropdown()}</Fieldset>
                    </Container>
                  </Section>
                </Container>
              </Container>
              <Container styleType={STYLETYPE_FORM_FIELD}>
                <Fieldset>
                  <DataTable
                    title={getLabel("FORM_LABEL_ORG_CREATE_DATASETS")}
                    id={"datasets-table"}
                    TitleComponent={Legend}
                    header={{
                      cells: [
                        {
                          content: (
                            <CheckboxV2
                              handleUpdate={this.setSelectAllDataSets}
                              name={"dataSets"}
                              rowId={"dataSets"}
                              value={this.getSelectAllDataSets()}
                              checked={this.getSelectAllDataSets()}
                              title={getLabel("ENABLE_ALL_DATASETS_LABEL")}
                            />
                          )
                        }
                      ],
                      showToggle: false
                    }}
                    rows={this.getDataSetsTemplatesRows()}
                    loading={datasetTemplatesLoading}
                    noResultsText={getLabel("NO_DATASETS_LABEL")}
                  />
                </Fieldset>
              </Container>
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <DataTable
                  title={getLabel("CUSTOM_MAP_LAYERS_LABEL")}
                  header={this.getTableHeader()}
                  rows={this.getTableData()}
                />
              </Section>
              <BottomButtonBar buttons={this.getButtons()} />
            </Container>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default AddOrganisation;
