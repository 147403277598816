import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tr, Th } from "../style";
import { Button, Container } from "@core";
import { defaultTheme } from "@UIKit";
import {
  ACCORDION_HEADER_STYLING,
  STYLE_TYPE_TABLE_HEADER_BUTTON,
  STYLE_TYPE_DATA_TABLE_SORT_HEADER_BUTTON
} from "@base/constants";

class DataTableHeader extends Component {
  static propTypes = {
    /** an array of cells */
    cells: PropTypes.arrayOf(
      PropTypes.shape({
        /**header cell content */
        content: PropTypes.any,
        /** whether to enable sorting for that column header */
        sort: PropTypes.shape({
          /** accessible label text for the button */
          label: PropTypes.string,
          /** the sort button direction */
          direction: PropTypes.string,
          /** Called on click of sort button */
          onClick: PropTypes.func
        })
      })
    ),
    /** setToggleOpen function toggles button */
    setToggleOpen: PropTypes.func,
    /** toggleOpen return true or false */
    toggleOpen: PropTypes.bool,
    /** showToggle return true or false */
    showToggle: PropTypes.bool,
    /** innerStyleType return string */
    innerStyleType: PropTypes.string,
    /** A unique id for the table */
    id: PropTypes.string
  };

  setToggleOpen = () => {
    const { setToggleOpen } = this.props;
    return setToggleOpen ? setToggleOpen() : null;
  };

  getToggleOpen = () => {
    const { toggleOpen } = this.props;
    return toggleOpen ? toggleOpen : false;
  };

  getShowToggle = () => {
    const { showToggle } = this.props;
    return showToggle ? showToggle : false;
  };

  getAccordionHeaderCells = () => {
    const { cells } = this.props;
    return cells || [];
  };

  render() {
    return (
      <Tr>
        {this.getShowToggle() && (
          <Th styleType={ACCORDION_HEADER_STYLING}>
            <span className={"sr-only"} id="sr-heading">
              {this.getToggleOpen() ? "Collapse all items" : "Expand all items"}
            </span>
            <Container direction={"row"} styleType={ACCORDION_HEADER_STYLING}>
              <Button
                ariaLabelledBy={"sr-heading"}
                buttonLabel={""}
                isDisabled={false}
                onClick={this.setToggleOpen}
                buttonStyleType={STYLE_TYPE_TABLE_HEADER_BUTTON}
                icon={{
                  type: "double-down-arrow",
                  bgWidth: "23px",
                  bgHeight: "23px",
                  iconHeight: "23px",
                  iconWidth: "23px",
                  bgColor: defaultTheme.agWhite,
                  iconColor: defaultTheme.agBrightGreen,
                  rotation: this.getToggleOpen() ? 360 : 270
                }}
              />
            </Container>
          </Th>
        )}
        {this.getAccordionHeaderCells().map((header, i) => (
          <Th
            key={`header-${this.props.id}-${i}`}
            innerStyleType={this.props.innerStyleType}
            hasSort={header.sort ? true : false}
          >
            {header.content}
            {header.sort && (
              <Button
                ariaLabel={header.sort.label}
                isDisabled={false}
                onClick={header.sort.onClick}
                buttonStyleType={STYLE_TYPE_DATA_TABLE_SORT_HEADER_BUTTON}
                icon={{
                  type: "down-triangle",
                  bgWidth: "1.2em",
                  bgHeight: "1.2em",
                  iconHeight: "0.8em",
                  iconWidth: "0.8em",
                  bgColor: defaultTheme.agBrightGreen,
                  iconColor: defaultTheme.agWhite,
                  rotation: header.sort.direction === "asc" ? 180 : 0
                }}
              />
            )}
          </Th>
        ))}
      </Tr>
    );
  }
}

export default DataTableHeader;
