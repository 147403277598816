import React from "react";
import PropTypes from "prop-types";
import { FormLabel, Container, Wrapper, Fieldset } from "@core";
import { STYLETYPE_CHECKBOX } from "@base/constants";
import { Legend } from "@typography";
import { Input, InputWrapper } from "./style";

const Checkbox = (props) => {
  return (
    <Fieldset>
      <Legend styleType={"checkbox-header"}>{props.legend}</Legend>
      {props.options.map((option, i) => (
        <InputWrapper key={`${option.title}-${option.value}-${i}`}>
          {option.title && option.value && (
            <Input
              styleType={props.styleType}
              id={`${option.title}-${option.value}-${i}`}
              name={props.name}
              key={`${option.title}-${option.value}-${i}`}
              type={"checkbox"}
              value={option.value}
              checked={option.checked}
              disabled={option.disabled}
              onChange={(
                e // skipcq JS-0417
              ) =>
                props.handleUpdateValue(
                  e.target.checked
                    ? [...props.value, option.value]
                    : props.value.filter((value) => value !== option.value)
                )
              }
            />
          )}
          <FormLabel
            htmlFor={`${option.title}-${option.value}-${i}`}
            styleType={props.styleType}
          >
            {option.title}
          </FormLabel>
        </InputWrapper>
      ))}
    </Fieldset>
  );
};

Checkbox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
      checked: PropTypes.bool
    })
  ),
  name: PropTypes.string,
  /** styleType for styling checkbox */
  styleType: PropTypes.string,
  /** value array*/
  value: PropTypes.array,
  /** handleUpdateValue function */
  handleUpdateValue: PropTypes.func,
  /** legend for radiogroup */
  legend: PropTypes.string
};

Checkbox.defaultProps = {
  styleType: STYLETYPE_CHECKBOX
};

export default Checkbox;
