import React from "react";
import PropTypes from "prop-types";
import { Button, Container } from "@core";
import { MODAL_BOTTOM_BUTTONS_ID } from "@base/constants";

/**
 * Returns a array of Button components
 * @public
 */
const renderChildren = (childLinks) => {
  if (!childLinks) return;
  return childLinks
    .filter((item) => {
      return item.buttonLabel && item.buttonLabel !== "";
    })
    .map((item, index) => {
      let iconElement;
      if (item.iconType && item.iconType !== "") {
        iconElement = {
          type: item.iconType,
          bgWidth: "30px",
          bgHeight: "30px",
          iconHeight: "20px",
          iconWidth: "20px",
          iconColor: item.iconColor
        };
      }
      return (
        <Button
          data-name={"ModalBottomButtonsElementButton"}
          buttonStyleType={item.buttonStyleType}
          isDisabled={typeof item.onClick !== "function" || item.isDisabled}
          onClick={item.onClick}
          buttonLabel={item.buttonLabel}
          icon={iconElement ? iconElement : null}
          key={`${item.buttonLabel} + button ${index}`}
        />
      );
    });
};

/**
 * Renders a list of buttons on the bottom of a Modal
 */

const ModalBottomButtons = (props) => {
  return (
    <Container
      data-name={"ModalBottomButtonsElement"}
      styleType={MODAL_BOTTOM_BUTTONS_ID}
    >
      {renderChildren(props.buttons)}
    </Container>
  );
};

ModalBottomButtons.propTypes = {
  /** An array of children to render as buttons */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      /** The label for the childs button  */
      buttonLabel: PropTypes.string,
      /** The link for the childs button (optional) if not supplied, then button will be disabled and render just a text  */
      onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      /** The icon for the childs button (optional) if ommitted, link will be rendered without an icon */
      iconType: PropTypes.string,
      /** The iconColor for the childs button (optional) if ommitted, link will be rendered with default iconColor black */
      iconColor: PropTypes.string,
      /** The style for the childs button (optional) if ommitted, link will be rendered as primary */
      buttonStyleType: PropTypes.string
    })
  )
};

export default ModalBottomButtons;
