import styled, { css } from "styled-components";
import {
  CHECKBOX_ICON_STRING,
  DATA_TABLE_CHECKBOX_STYLING
} from "@base/constants";

export const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.813rem;
  padding: 0.4em;
  margin-bottom: 0.3em;
  background: ${(props) => props.theme.agSmokeWhite};
  &:nth-child(even) {
    background: ${(props) => props.theme.agWhite};
  }
  & + & {
    margin-top: 0rem;
  }
`;
InputWrapper.displayName = "InputWrapper";

export const Input = styled.input`
  appearance: none;
  width: calc(100% - 30rem);
  font-size: 0.9375rem;
  min-height: 1.875rem;
  height: auto;
  padding: 0.6rem;
  align-self: center;
  margin-right: 0.9rem;
  border-radius: 0.063rem;
  border: 0.063em solid ${(props) => props.theme.agDarkerGreen};
  width: 2.125rem;
  height: 2.125rem;
  box-shadow: 0rem 0.188rem 0.188rem rgba(0, 0, 0, 0.16);
  background: ${(props) => props.theme.agWhite};

  :checked {
    border-radius: 0.063rem;
    border: none;
    background: ${(props) => props.theme.agDarkerGreen};
    padding: 0.125rem 0.125rem;
  }

  :checked::before {
    background-image: ${CHECKBOX_ICON_STRING};
    background-size: 1.5rem;
    background-repeat: no-repeat;
    background-position: center;
    border: 0.125rem solid white;
    border-radius: 0.063rem;
    display: block;
    width: 1.625rem;
    height: 1.625rem;
    content: "";
  }

  :disabled {
    border-radius: 0.063rem;
    fill: ${(props) => props.theme.agWhite};
    box-shadow: 0rem 0.063rem 0.188rem rgba(0, 0, 0, 0.16);
    opacity: 0.5;
  }

  :hover {
    &:not(:disabled) {
      border: 0.063rem solid ${(props) => props.theme.agLighterGreen};
      border-radius: 0.063rem;
      fill: ${(props) => props.theme.agWhite};
      box-shadow: 0rem 0.188rem 0.188rem rgba(0, 0, 0, 0.16);
      &:checked:before {
        background-size: 1.375rem;
        border: 0.125rem solid white;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  ${(props) =>
    props.styleType === DATA_TABLE_CHECKBOX_STYLING &&
    css`
      border-radius: 0.188rem;
      background: ${props.theme.agWhite};
      border: 1px solid ${props.theme.agLighterGreen};
    `}
`;
Input.displayName = "InputElement";
