import { handleActions } from "redux-actions";
import {
  CREATE_INVITE,
  CREATED_INVITE,
  CREATE_INVITE_ERROR,
  STATUS_ERROR,
  STATUS_UPDATING,
  STATUS_LOADING,
  STATUS_READY,
  STATUS_CREATING,
  STATUS_RESENDING,
  LOAD_USER_PROPERTIES_MEMBERSHIP,
  LOADED_USER_PROPERTIES_MEMBERSHIP,
  LOAD_USER_PROPERTY_GROUPS,
  LOADED_USER_PROPERTY_GROUPS,
  UPDATE_USER_MEMBERSHIP,
  UPDATED_USER_MEMBERSHIP,
  UPDATE_USER_MEMBERSHIP_FAILED,
  REMOVED_USER_MEMBERSHIP,
  REMOVE_USER_MEMBERSHIP,
  STATUS_REMOVING,
  LOAD_USER_MEMBERSHIP,
  LOADED_USER_MEMBERSHIP,
  RESEND_INVITE,
  RESENDED_INVITE,
  LOAD_INVITED_USER,
  LOADED_INVITED_USER,
  REMOVE_INVITE,
  REMOVED_INVITE,
  STATUS_EMPTY,
  LOAD_USER,
  LOADED_USER
} from "@base/constants";

export const defaultState = {
  status: STATUS_EMPTY,
  selectedUser: null,
  selectedInvitedUser: {
    isLoading: false,
    available: false
  },
  selectedUserProperties: {
    isLoading: false,
    available: []
  },
  selectedUserPropertyGroups: {
    isLoading: false,
    available: []
  }
};
// skipcq JS-0128
export default handleActions(
  {
    [LOAD_USER]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      selectedUser: null
    }),
    [LOADED_USER]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      selectedUser: payload
    }),
    [LOAD_USER_MEMBERSHIP]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      selectedUser: null
    }),
    [LOADED_USER_MEMBERSHIP]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      selectedUser: {
        ...payload,
        name: `${payload.firstName} ${payload.lastName}`
      }
    }),
    [CREATE_INVITE]: (state) => ({
      ...state,
      status: STATUS_CREATING
    }),
    [CREATED_INVITE]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      inviteId: payload
    }),
    [CREATE_INVITE_ERROR]: (state, { payload }) => ({
      ...state,
      status: STATUS_ERROR,
      errorMessage: payload
    }),
    [LOAD_USER_PROPERTIES_MEMBERSHIP]: (state) => ({
      ...state,
      selectedUserProperties: {
        ...state.properties,
        isLoading: true
      }
    }),
    [LOADED_USER_PROPERTIES_MEMBERSHIP]: (state, { payload }) => ({
      ...state,
      selectedUserProperties: {
        isLoading: false,
        available: payload.items
      }
    }),
    [LOAD_USER_PROPERTY_GROUPS]: (state) => ({
      ...state,
      selectedUserPropertyGroups: {
        ...state.propertyGroups,
        isLoading: true
      }
    }),
    [LOADED_USER_PROPERTY_GROUPS]: (state, { payload }) => ({
      ...state,
      selectedUserPropertyGroups: {
        isLoading: false,
        available: payload.items
      }
    }),
    [UPDATE_USER_MEMBERSHIP]: (state) => ({
      ...state,
      status: STATUS_UPDATING
    }),
    [UPDATED_USER_MEMBERSHIP]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [UPDATE_USER_MEMBERSHIP_FAILED]: (state) => ({
      ...state,
      status: STATUS_ERROR
    }),
    [REMOVED_USER_MEMBERSHIP]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [REMOVE_USER_MEMBERSHIP]: (state) => ({
      ...state,
      status: STATUS_REMOVING
    }),
    [RESEND_INVITE]: (state) => ({
      ...state,
      status: STATUS_RESENDING
    }),
    [RESENDED_INVITE]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [LOAD_INVITED_USER]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      selectedInvitedUser: {
        isLoading: true
      }
    }),
    [LOADED_INVITED_USER]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      selectedInvitedUser: {
        isLoading: false,
        available: payload
      }
    }),
    [REMOVE_INVITE]: (state) => ({
      ...state,
      status: STATUS_REMOVING
    }),
    [REMOVED_INVITE]: (state) => ({
      ...state,
      status: STATUS_READY
    })
  },
  defaultState
);
