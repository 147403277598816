export const sortMethod = (array, sortKey, sortDirection) => {
  return array.sort((a, b) => {
    if (a[sortKey] !== null) {
      if (typeof a[sortKey] === "number") {
        if (sortDirection === "asc") return a[sortKey] - b[sortKey];
        if (sortDirection === "desc") return b[sortKey] - a[sortKey];
      }
      const isReserved =
        sortDirection === "asc" || sortDirection === "default" ? 1 : -1;
      return isReserved * a[sortKey].localeCompare(b[sortKey]);
    }
  });
};
