import { createStore, applyMiddleware } from "redux";
import { createLogicMiddleware } from "redux-logic";

import globalLogic from "./logic";

import * as globalActions from "./actions";
import rootReducer from "./reducers";
import * as selectors from "./selectors";

import { AGBOX_API_URL, AGBOX_API_KEY } from "./constants";
import AgBoxApiRequests from "./apis/agbox/requests";
import AgBoxApiCreates from "./apis/agbox/creates";
import AgBoxApiRemoves from "./apis/agbox/removes";
import AgBoxApiUpdates from "./apis/agbox/updates";

const agBoxApiRequests = AgBoxApiRequests(AGBOX_API_URL, AGBOX_API_KEY);
const agBoxApiCreates = AgBoxApiCreates(AGBOX_API_URL, AGBOX_API_KEY);
const agBoxApiRemoves = AgBoxApiRemoves(AGBOX_API_URL, AGBOX_API_KEY);
const agBoxApiUpdates = AgBoxApiUpdates(AGBOX_API_URL, AGBOX_API_KEY);

const rootLogicDependencies = {
  agBoxApiRequests,
  agBoxApiCreates,
  agBoxApiRemoves,
  agBoxApiUpdates,
  globalActions,
  selectors
};

// Create the logic as middleware
const logicMiddleware = createLogicMiddleware(
  globalLogic,
  rootLogicDependencies
);

export default function configureStore() {
  const store = createStore(rootReducer, applyMiddleware(logicMiddleware));
  return store;
}
