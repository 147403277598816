import React, { Component } from "react";
import {
  Container,
  Wrapper,
  Section,
  FormInput,
  TextArea,
  FormLabel
} from "@core";
import { P } from "@typography";
import {
  STYLETYPE_ERROR,
  WORKFLOWS_URL,
  STATUS_READY,
  STATUS_UPDATING,
  STATUS_LOADING,
  CONTENT_FADE_TIMEOUT,
  FIELD_SIZE_MEDIUM,
  STYLETYPE_FORM_FIELD,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  BUTTON_TYPE_PRIMARY,
  BUTTON_TYPE_SECONDARY,
  BUTTON_TYPE_PRIMARY_FULLWIDTH,
  BUTTON_TYPE_SECONDARY_FULLWIDTH,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL
} from "@base/constants";
import { Loader, RadioGroup, ContextHeader, BottomButtonBar } from "@UIKit";
import { navigate } from "@reach/router";
import { CSSTransition } from "react-transition-group";

class UpdateWorkflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rawMode: false,
      workflowJSON: "",
      jsonTextInputValue: "",
      textTitle: "",
      textIcon: "",
      textState: "",
      textPlugins: "",
      textFeatureServices: "",
      originalState: {}
    };
  }

  componentDidMount() {
    this.setupWorkflowDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  static getDerivedStateFromProps(props, state) {
    const { selectedWorkflow } = props;
    if (
      selectedWorkflow &&
      !state.textTitle &&
      !state.textIcon &&
      !state.textState &&
      !state.textPlugins &&
      !state.textFeatureServices &&
      !state.workflowJSON &&
      !state.jsonTextInputValue
    ) {
      const dataForRawJson = {
        title: selectedWorkflow["title"],
        icon: selectedWorkflow["icon"],
        plugins: selectedWorkflow["plugins"],
        state: selectedWorkflow["state"],
        featureServices: selectedWorkflow["featureServices"]
      };
      return {
        workflowJSON: dataForRawJson,
        jsonTextInputValue: JSON.stringify(dataForRawJson, "", 2),
        textTitle: selectedWorkflow["title"],
        textIcon: selectedWorkflow["icon"],
        textPlugins: JSON.stringify(selectedWorkflow["plugins"], "", 2),
        textState: JSON.stringify(selectedWorkflow["state"], "", 2),
        textFeatureServices: JSON.stringify(
          selectedWorkflow["featureServices"],
          "",
          2
        ),
        originalState: {
          textTitle: selectedWorkflow["title"],
          textIcon: selectedWorkflow["icon"],
          textPlugins: JSON.stringify(selectedWorkflow["plugins"], "", 2),
          textState: JSON.stringify(selectedWorkflow["state"], "", 2),
          textFeatureServices: JSON.stringify(
            selectedWorkflow["featureServices"],
            "",
            2
          )
        }
      };
    }
    return state;
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedWorkflowStatus } = this.props;
    if (
      prevProps.selectedWorkflowStatus === STATUS_UPDATING &&
      selectedWorkflowStatus === STATUS_READY
    ) {
      this.showSuccess();
    }
  }

  setupWorkflowDetails = () => {
    const { orgId, workflowId, loadWorkflow } = this.props;
    loadWorkflow(orgId, workflowId);
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      item: getLabel("WORKFLOW_TEXT"),
      action: getLabel("UPDATE_ACTION_LABEL")
    });
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  valueIsEmpty = (field) => {
    return (
      !this.getValue(field) ||
      (this.getValue(field) && this.getValue(field).length === 0) ||
      this.getValue(field) === ""
    );
  };

  isInvalid = () => {
    const { rawMode } = this.state;
    if (rawMode) {
      return this.valueIsEmpty("jsonTextInputValue");
    } else {
      return (
        this.valueIsEmpty("textTitle") ||
        this.valueIsEmpty("textIcon") ||
        this.valueIsEmpty("textPlugins") ||
        this.valueIsEmpty("textFeatureServices") ||
        this.valueIsEmpty("textState")
      );
    }
  };

  getFormValues = () => {
    return {
      title: this.getValue("textTitle"),
      icon: this.getValue("textIcon"),
      state: JSON.parse(this.getValue("textState")),
      plugins: JSON.parse(this.getValue("textPlugins")),
      textFeatureServices: JSON.parse(this.getValue("textFeatureServices"))
    };
  };

  callAction = async () => {
    try {
      const { rawMode } = this.state;
      const rawJsonInput = this.getValue("jsonTextInputValue");
      const formInputValues = JSON.stringify(this.getFormValues());
      const data = rawMode ? rawJsonInput : formInputValues;
      const { orgId, workflowId, updateWorkflow, showModal } = this.props;
      this.showLoading();
      showModal();

      const results = await updateWorkflow(orgId, workflowId, data);
    } catch (e) {
      throw new Error(e);
    }
  };

  showLoading = () => {
    const { showModal, setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("UPDATE_ACTION_LABEL"),
        item: getLabel("WORKFLOW_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_UPDATED_LABEL", {
        item: getLabel("WORKFLOW_TEXT")
      }),
      body: getLabel("ITEM_UPDATED_SUCCESS", {
        item: getLabel("WORKFLOW_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("WORKFLOW_TEXT")
          }),
          onClick: this.returnToWorflowAction
        }
      ]
    });
    return null;
  };

  returnToWorflowAction = () => {
    const { hideModal, orgId, selectedWorkflow } = this.props;
    const { workflowId } = selectedWorkflow;
    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${WORKFLOWS_URL}/${workflowId}`);
  };

  isLoading = () => {
    const {
      organisationsLoading,
      selectedOrganisationLoading,
      selectedWorkflowStatus
    } = this.props;
    return (
      organisationsLoading ||
      selectedOrganisationLoading ||
      selectedWorkflowStatus === STATUS_LOADING
    );
  };

  isUpdating = () => {
    const { selectedWorkflowStatus } = this.props;
    return selectedWorkflowStatus === STATUS_UPDATING;
  };

  resetWorkflowJSON = () => {
    const { workflowJSON } = this.state;
    this.setValue(JSON.stringify(workflowJSON, "", 2), "jsonTextInputValue");

    this.setValue(JSON.stringify(workflowJSON["title"], "", 2), "textTitle");

    this.setValue(JSON.stringify(workflowJSON["icon"], "", 2), "textIcon");

    this.setValue(
      JSON.stringify(workflowJSON["plugins"], "", 2),
      "textPlugins"
    );

    this.setValue(
      JSON.stringify(workflowJSON["featureServices"], "", 2),
      "textFeatureServices"
    );

    this.setValue(JSON.stringify(workflowJSON["state"], "", 2), "textState");
  };

  getRadioButtons = () => {
    const { getLabel } = this.props;

    return [
      {
        label: getLabel("RAW_JSON_INPUT_LABEL"),
        value: true
      },
      {
        label: getLabel("FORM_INPUT_LABEL"),
        value: false
      }
    ];
  };

  showMessage = () => {
    const { setModalContent, showModal, getLabel } = this.props;
    showModal();
    setModalContent({
      showCloseButton: false,
      header: getLabel("UPDATE_ACTION_HEADER_LABEL", {
        item: getLabel("WORKFLOW_TEXT")
      }),
      body: getLabel("BACK_TO_DETAILS_MESSAGE"),
      buttons: [
        {
          buttonStyleType: BUTTON_TYPE_PRIMARY_FULLWIDTH,
          isDisabled: this.isInvalid() || this.isLoading(),
          buttonLabel: getLabel("YES_LABEL"),
          onClick: this.callAction
        },
        {
          buttonStyleType: BUTTON_TYPE_SECONDARY_FULLWIDTH,
          buttonLabel: getLabel("NO_LABEL"),
          onClick: this.returnToWorflowAction
        }
      ]
    });
    return null;
  };

  getStateChanged = () => {
    const {
      rawMode,
      textTitle,
      textIcon,
      textState,
      textPlugins,
      textFeatureServices,
      originalState,
      workflowJSON,
      jsonTextInputValue
    } = this.state;
    if (rawMode && jsonTextInputValue !== workflowJSON) {
      return true;
    }

    if (
      !rawMode &&
      (textTitle !== originalState.textTitle ||
        textIcon !== originalState.textIcon ||
        textState !== originalState.textState ||
        textPlugins !== originalState.textPlugins ||
        textFeatureServices !== originalState.textFeatureServices)
    ) {
      return true;
    }
    return false;
  };

  getButtons = () => {
    const { getLabel } = this.props;
    const stateChanged = this.getStateChanged();
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        isDisabled: this.isInvalid() || this.isLoading(),
        onClick: this.callAction,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("WORKFLOW_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      },
      {
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        isDisabled: this.isInvalid() || this.isLoading(),
        onClick: this.resetWorkflowJSON,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("WORKFLOW_TEXT"),
          action: getLabel("RESET_LABEL")
        })
      },
      {
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        onClick: stateChanged ? this.showMessage : this.returnToWorflowAction,
        buttonLabel: getLabel("RETURN_TO_DETAILS", {
          item: getLabel("WORKFLOW_TEXT")
        })
      }
    ];
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoading() ? (
          <Loader
            fullpage={false}
            loadingText={getLabel("GENERIC_ACTION_MESSAGE", {
              item: getLabel("WORKFLOW_TEXT"),
              action: getLabel("LOADING_LABEL")
            })}
          />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
              />
            </Container>
            <Container>
              <Container styleType={STYLETYPE_FORM_FIELD}>
                <RadioGroup
                  direction={"vertical"}
                  value={this.getValue("rawMode")}
                  isDisabled={false}
                  name={"typeFilterRadioGroup"}
                  radioButtons={this.getRadioButtons()}
                  onClick={(event) => {
                    event = event == "true";
                    this.setValue(event, "rawMode");
                  }}
                ></RadioGroup>
              </Container>
              <CSSTransition
                in={this.getValue("rawMode")}
                timeout={CONTENT_FADE_TIMEOUT}
                classNames="css-transition"
                unmountOnExit
              >
                <Section>
                  <FormLabel
                    htmlFor={"jsontextinputvalue"}
                    styleType={
                      this.valueIsEmpty("jsonTextInputValue")
                        ? STYLETYPE_ERROR
                        : ""
                    }
                  >
                    {getLabel("ADD_JSON", {
                      item: getLabel("WORKFLOW_TEXT")
                    })}
                  </FormLabel>
                  <TextArea
                    id={"jsontextinputvalue"}
                    maxLength={30000}
                    value={this.getValue("jsonTextInputValue")}
                    onChange={(value) => {
                      this.setValue(value, "jsonTextInputValue");
                    }}
                    styleType={
                      this.valueIsEmpty("jsonTextInputValue")
                        ? STYLETYPE_ERROR
                        : ""
                    }
                  ></TextArea>
                </Section>
              </CSSTransition>
              <CSSTransition
                in={!this.getValue("rawMode")}
                timeout={CONTENT_FADE_TIMEOUT}
                classNames="css-transition"
                unmountOnExit
              >
                <Section>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      htmlFor={"text-title"}
                      styleType={
                        this.valueIsEmpty("textTitle") ? STYLETYPE_ERROR : ""
                      }
                    >
                      {getLabel("FORM_TITLE_LABEL")}
                    </FormLabel>
                    <FormInput
                      id={"text-title"}
                      value={this.getValue("textTitle")}
                      onChange={(value) => {
                        this.setValue(value, "textTitle");
                      }}
                      styleType={
                        this.valueIsEmpty("textTitle") ? STYLETYPE_ERROR : ""
                      }
                    ></FormInput>
                  </Container>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      htmlFor={"text-icon"}
                      styleType={
                        this.valueIsEmpty("textIcon") ? STYLETYPE_ERROR : ""
                      }
                    >
                      {getLabel("FORM_ICON_LABEL")}
                    </FormLabel>
                    <FormInput
                      id={"text-icon"}
                      value={this.getValue("textIcon")}
                      onChange={(value) => {
                        this.setValue(value, "textIcon");
                      }}
                      styleType={
                        this.valueIsEmpty("textIcon") ? STYLETYPE_ERROR : ""
                      }
                    ></FormInput>
                  </Container>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      htmlFor={"text-plugins"}
                      styleType={
                        this.valueIsEmpty("textPlugins") ? STYLETYPE_ERROR : ""
                      }
                    >
                      {getLabel("FORM_PLUGINS_LABEL")}
                    </FormLabel>
                    <TextArea
                      id={"text-plugins"}
                      size={FIELD_SIZE_MEDIUM}
                      value={this.getValue("textPlugins")}
                      onChange={(value) => {
                        this.setValue(value, "textPlugins");
                      }}
                      styleType={
                        this.valueIsEmpty("textPlugins") ? STYLETYPE_ERROR : ""
                      }
                    ></TextArea>
                  </Container>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      htmlFor={"text-feature-services"}
                      styleType={
                        this.valueIsEmpty("textFeatureServices")
                          ? STYLETYPE_ERROR
                          : ""
                      }
                    >
                      {getLabel("WORKFLOW_DETAILS_FEATURE_SERVICES_SUBHEADING")}
                    </FormLabel>
                    <TextArea
                      id={"text-feature-services"}
                      size={FIELD_SIZE_MEDIUM}
                      value={this.getValue("textFeatureServices")}
                      onChange={(value) => {
                        this.setValue(value, "textFeatureServices");
                      }}
                      styleType={
                        this.valueIsEmpty("textFeatureServices")
                          ? STYLETYPE_ERROR
                          : ""
                      }
                    ></TextArea>
                  </Container>

                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      htmlFor={"text-state"}
                      styleType={
                        this.valueIsEmpty("textState") ? STYLETYPE_ERROR : ""
                      }
                    >
                      {getLabel("FORM_STATE_LABEL")}
                    </FormLabel>
                    <TextArea
                      id={"text-state"}
                      maxLength={20000}
                      value={this.getValue("textState")}
                      onChange={(value) => {
                        this.setValue(value, "textState");
                      }}
                      styleType={
                        this.valueIsEmpty("textState") ? STYLETYPE_ERROR : ""
                      }
                    ></TextArea>
                  </Container>
                </Section>
              </CSSTransition>
              {this.isInvalid() ? (
                <P styleType={STYLETYPE_ERROR}>
                  {getLabel("PLEASE_FILL_FORM")}
                </P>
              ) : null}
              <BottomButtonBar buttons={this.getButtons()} />
            </Container>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default UpdateWorkflow;
