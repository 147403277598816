import styled from "styled-components";

// @ts-ignore
export const IconSymbol = styled.span`
  background-color: ${(props) => props.iconColor || props.theme.agBlack};
  mask-image: ${(props) => {
    try {
      const icon = `${props.type.replace(/ /g, "-")}-icon.svg`;
      const url = props.path
        ? `${props.path}${icon}`
        : `${require(`../../icons/${icon}`)}`;
      return `url("${url}")`;
    } catch (e) {
      return `url("${require(`../../icons/error-generic-icon.svg`)}")`;
    }
  }};
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  width: ${(props) => props.width || props.theme.iconWidth};
  height: ${(props) => props.height || props.theme.iconHeight};
  animation-name: ${(props) => {
    if (props.spinning) return `ckw`;
    return "none";
  }};
  animation-duration: ${(props) => {
    if (props.spinning) return `3s`;
    return "none";
  }};
  animation-iteration-count: infinite;
  transform-origin: ${(props) => {
    if (props.spinning) return `50% 50%`;
    return "none";
  }};
  display: ${(props) => {
    if (props.spinning) return `inline-block`;
    return "block";
  }};
  transform: ${(props) => {
    if (props.rotation !== null) return `rotate(${props.rotation}deg)`;
  }};
  transition: ${(props) => {
    if (props.rotation !== null) return "transform 0.2s ease-in-out";
  }};
`;
IconSymbol.displayName = "IconSymbol";

export const IconBackground = styled.span`
  background: ${(props) => props.backgroundColor || "none"};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => (props.shape === "round" ? "50%" : "0")};
  width: ${(props) =>
    props.invalid ? "0" : props.width || props.theme.iconWidth};
  height: ${(props) => props.height || props.theme.iconHeight};
  flex-shrink: 0;
`;
IconBackground.displayName = "IconBackground";
