import { handleActions } from "redux-actions";
import {
  CREATE_ROLE,
  STATUS_CREATING,
  STATUS_READY,
  STATUS_LOADING,
  STATUS_UPDATING,
  STATUS_REMOVING,
  CREATED_ROLE,
  LOAD_ROLE,
  LOADED_ROLE,
  LOAD_ROLE_WORKFLOWS,
  LOADED_ROLE_WORKFLOWS,
  LOAD_ROLE_WORKFLOWS_ERROR,
  LOAD_ROLE_USERS,
  LOADED_ROLE_USERS,
  UPDATE_ROLE,
  UPDATE_ROLE_ADMIN,
  UPDATED_ROLE,
  REMOVE_ROLE,
  REMOVED_ROLE
} from "@base/constants";
import { STATUS_ERROR } from "../constants";

export const defaultState = {
  status: STATUS_READY,
  available: null,
  selectedRoleWorkflows: {},
  selectedRoleUsers: {}
};
// skipcq JS-0128
export default handleActions(
  {
    [LOAD_ROLE]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      available: null
    }),

    [LOADED_ROLE]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      available: payload
    }),

    [LOAD_ROLE_WORKFLOWS]: (state) => ({
      ...state,
      status: STATUS_READY,
      selectedRoleWorkflows: {
        status: STATUS_LOADING
      }
    }),
    [LOADED_ROLE_WORKFLOWS]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      selectedRoleWorkflows: {
        status: STATUS_READY,
        ...payload.roleWorkflows
      }
    }),
    [LOAD_ROLE_WORKFLOWS_ERROR]: (state) => ({
      ...state,
      status: STATUS_READY,
      available: {
        ...state.available,
        workflows: {
          status: STATUS_ERROR
        }
      }
    }),
    [LOAD_ROLE_USERS]: (state) => ({
      ...state,
      status: STATUS_READY,
      selectedRoleUsers: {
        status: STATUS_LOADING
      }
    }),
    [LOADED_ROLE_USERS]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      selectedRoleUsers: {
        status: STATUS_READY,
        ...payload.roleUsers
      }
    }),

    [CREATE_ROLE]: (state) => ({
      ...state,
      status: STATUS_CREATING
    }),

    [CREATED_ROLE]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      available: payload
    }),

    [UPDATE_ROLE]: (state) => ({
      ...state,
      status: STATUS_UPDATING
    }),
    [UPDATE_ROLE_ADMIN]: (state) => ({
      ...state,
      status: STATUS_UPDATING
    }),
    [UPDATED_ROLE]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      available: payload
    }),
    [REMOVE_ROLE]: (state) => ({
      ...state,
      status: STATUS_REMOVING
    }),
    [REMOVED_ROLE]: (state) => ({
      ...state,
      status: STATUS_READY
    })
  },
  defaultState
);
