import React, { Component } from "react";
import {
  PaginationContainer,
  PaginationList,
  PaginationButton
} from "../style";
import { Button } from "@core";

class PaginationComponent extends Component {
  isActivePage = (page) => {
    const { isActivePage } = this.props;
    return isActivePage ? isActivePage(page) : false;
  };

  nextPage = () => {
    return this.props.nextPage;
  };

  previousPage = () => {
    return this.props.previousPage;
  };

  lastPageDisabled = () => {
    return this.props.lastPageDisabled === true;
  };

  firstPageDisabled = () => {
    return this.props.firstPageDisabled === true;
  };

  firstPage = () => {
    return this.props.firstPage;
  };

  lastPage = () => {
    return this.props.lastPage;
  };

  render() {
    const { pages, paginate } = this.props;

    return (
      <PaginationContainer data-name={"PaginationContainer"}>
        <PaginationList>
          <Button
            buttonStyleType={"paginationButtons"}
            isDisabled={this.firstPageDisabled()}
            onClick={this.firstPage()}
            buttonLabel={""}
            icon={{
              type: "beginning",
              bgWidth: "10px",
              bgHeight: "10px",
              iconHeight: "10px",
              iconWidth: "10px",
              iconColor: "#626466"
            }}
          />
          <Button
            buttonStyleType={"paginationButtons"}
            isDisabled={this.firstPageDisabled()}
            onClick={this.previousPage()}
            buttonLabel={""}
            icon={{
              type: "left-arrow",
              bgWidth: "10px",
              bgHeight: "10px",
              iconHeight: "10px",
              iconWidth: "10px",
              iconColor: "#626466"
            }}
          />

          {pages.map((num) => (
            <PaginationButton key={num} active={this.isActivePage(num)}>
              <Button
                buttonStyleType={"paginationButtons"}
                isDisabled={false}
                onClick={() => paginate(num)}
                buttonLabel={num}
              />
            </PaginationButton>
          ))}

          <Button
            buttonStyleType={"paginationButtons"}
            isDisabled={this.lastPageDisabled()}
            onClick={this.nextPage()}
            buttonLabel={""}
            icon={{
              type: "right-arrow",
              bgWidth: "10px",
              bgHeight: "10px",
              iconHeight: "10px",
              iconWidth: "10px",
              iconColor: "#626466"
            }}
          />
          <Button
            buttonStyleType={"paginationButtons"}
            isDisabled={this.lastPageDisabled()}
            onClick={this.lastPage()}
            buttonLabel={""}
            icon={{
              type: "end",
              bgWidth: "10px",
              bgHeight: "10px",
              iconHeight: "10px",
              iconWidth: "10px",
              iconColor: "#626466"
            }}
          />
        </PaginationList>
      </PaginationContainer>
    );
  }
}

export default PaginationComponent;
