import styled, { css } from "styled-components";
import { ButtonLabel, ButtonElement } from "../../core/Button/style";

export const PaginationWrapper = styled.div`
  margin: 2rem;
`;

PaginationWrapper.displayName = "PaginationWrapper";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`;
PaginationContainer.displayName = "PaginationContainer";

export const PaginationList = styled.ul`
  display: flex;
  flex-flow: wrap;
`;
PaginationList.displayName = "PaginationList";

export const PaginationButton = styled.li`
  & > ${ButtonElement} {
    height: 2.438rem;
    width: 2.438rem;
    border-radius: 0.063rem;
    box-shadow: 0rem 0.188rem 0.375rem #00000029;
    border: 1px solid ${(props) => props.theme.agDimGray};
    background: ${(props) =>
      props.active
        ? `${props.theme.agWhite} 0% 0% no-repeat padding-box`
        : `${props.theme.agLighterShadeGray} 0% 0% no-repeat padding-box`};

    > ${ButtonLabel} {
      font-size: 1.5em;
      color: ${(props) => props.theme.agCommonBlack};
    }
  }
`;

PaginationButton.displayName = "PaginationButton";
