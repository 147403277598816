import React, { Component } from "react";
import { Icon, Button, Container } from "@core";
import { STYLETYPE_STATUS } from "@base/constants";
import PropTypes from "prop-types";
import { ContextMenu } from "@UIKit";
import { P, Heading2 } from "@typography";
import { ContextHeaderElement } from "./style";

/**
 * The context header is an helperful component that combines both a page header and a context Menu
 */
class ContextHeader extends Component {
  static propTypes = {
    /** String to display in the heading */
    headerTitle: PropTypes.string,
    contextMenu: PropTypes.shape({
      /** Whether the context menu is visible or not */
      visible: PropTypes.bool,
      /** Whether the context menu is disabled or not */
      isDisabled: PropTypes.bool,
      /** An array of children to render as buttons */
      childLinks: PropTypes.arrayOf(
        PropTypes.shape({
          /** Whether the child button is disabled or not */
          isDisabled: PropTypes.bool,
          /** The label for the childs button  */
          buttonLabel: PropTypes.string,
          /** The link for the childs button (optional) if not supplied, then button will be disabled and render just a text  */
          onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
          /** The icon for the childs button (optional) if ommitted, link will be rendered without an icon */
          iconType: PropTypes.string
        })
      )
    }),
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        /** Whether the button is disabled or not */
        isDisabled: PropTypes.bool,
        /** The label for the button  */
        buttonLabel: PropTypes.string,
        /** The link for the button*/
        onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        /** The buttonStyleType for the button */
        buttonStyleType: PropTypes.string
      })
    ),
    status: PropTypes.shape({
      /** The label for the status  */
      label: PropTypes.string,
      /** The iconColor for the status */
      iconColor: PropTypes.string
    })
  };

  static defaultProps = {
    contextMenu: {
      visible: true,
      isDisabled: false,
      childLinks: []
    }
  };

  /**
   * Returns the header string return '' if empty
   * @public
   */
  getHeaderTitle = () => {
    const { headerTitle } = this.props;
    return headerTitle ? headerTitle : "";
  };

  /**
   * Returns the context menu data for ContextMenu
   * @public
   */
  getContextMenu = () => {
    const { contextMenu } = this.props;
    return contextMenu
      ? contextMenu
      : {
          visible: true,
          isDisabled: false,
          childLinks: []
        };
  };

  /**
   * Returns a Header
   * @public
   */
  getHeader = (text) => {
    return <Heading2>{text}</Heading2>;
  };

  getVisible = () => {
    const contextMenuVisible = this.getContextMenu().visible;
    return contextMenuVisible;
  };

  renderButtons = () => {
    const { buttons } = this.props;
    if (!buttons) return;
    return buttons.map((item, index) => {
      return (
        <Button
          key={`${item.buttonLabel}-${index}`}
          buttonStyleType={item.buttonStyleType}
          isDisabled={item.isDisabled}
          onClick={item.onClick}
          buttonLabel={item.buttonLabel}
        />
      );
    });
  };

  renderStatusLabel = () => {
    const { status } = this.props;
    if (!status) return;
    return (
      <Container direction={"row"} styleType={STYLETYPE_STATUS}>
        <Icon
          type={"circle"}
          iconColor={status.iconColor}
          bgShape="round"
          iconWidth="20px"
          iconHeight="20px"
        />
        <P styleType={STYLETYPE_STATUS}>{status.label}</P>
      </Container>
    );
  };

  render() {
    return (
      <ContextHeaderElement>
        {this.getHeader(this.getHeaderTitle())}
        {this.renderStatusLabel()}
        {this.renderButtons()}
        {this.getVisible() && <ContextMenu {...this.getContextMenu()} />}
      </ContextHeaderElement>
    );
  }
}

export default ContextHeader;
