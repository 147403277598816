import styled, { css } from "styled-components";
import {
  STYLE_TYPE_FORM_HEADING,
  DATA_TABLE_HEADING_STYLING,
  SECTION_HEADER_STYLING
} from "../../../constants";

export const Heading3Element = styled.h3`
  font-family: ${(props) => props.theme.baseFont};
  font-weight: ${(props) => props.theme.baseFontWeight};
  line-height: ${(props) => props.theme.lineHeightBase};
  font-size: ${(props) => props.theme.fontSizeBase};
  color: ${(props) => props.theme.agCommonBlack};
  text-transform: uppercase;
  letter-spacing: 0.01em;

  ${(props) =>
    props.styleType === "tableHeading" &&
    css`
      align-self: center;
      color: ${(props) => props.theme.agCommonBlack};
      font-weight: ${(props) => props.theme.baseFontWeightHeavy};
    `}

  ${(props) =>
    props.styleType === STYLE_TYPE_FORM_HEADING &&
    css`
      text-transform: initial;
      font-weight: ${(props) => props.theme.baseFontWeightHeavy};
      margin-bottom: 0.5em;
    `}


    ${(props) =>
    props.styleType === DATA_TABLE_HEADING_STYLING &&
    css`
      margin-bottom: 0.8rem;
      text-transform: none;
      font-size: ${(props) => props.theme.fontSizeOne};
      font-weight: ${(props) => props.theme.baseFontWeightHeavy};
    `}

    
    ${(props) =>
    props.styleType === SECTION_HEADER_STYLING &&
    css`
      font-weight: ${(props) => props.theme.baseFontWeightHeavy};
      font-size: ${(props) => props.theme.fontSizeTwo};
      line-height: ${(props) => props.theme.lineHeightLarger};
      text-transform: none;
      margin-bottom: 0.8rem;
    `}
`;
Heading3Element.displayName = "Heading3Element";
