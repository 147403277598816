import React, { Component } from "react";
import {
  defaultTheme,
  ContextHeader,
  BottomButtonBar,
  Loader,
  DataTable
} from "@UIKit";
import { Heading3, Heading4, P } from "@typography";
import { Section, Container, Wrapper, LinkButton } from "@core";
import { navigate } from "@reach/router";
import {
  PROPERTY_GROUPS_URL,
  PROPERTIES_URL,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  STATUS_LOADING,
  STATUS_ARCHIVING,
  STATUS_READY,
  STATUS_REMOVING,
  UPDATE_URL,
  PROPERTIES_SECTION,
  ARCHIVE_PERMISSION,
  UPDATE_PERMISSION,
  PROPERTY_GROUPS_SECTION,
  READ_PERMISSION,
  PAGINATION_KEY,
  PAGINATION_OPTIONS,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  SECTION_HEADER_STYLING,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL,
  DELETE_PERMISSION,
  IMPORT_DATASETS_URL
} from "@base/constants";
import { getSession, storeSession, filterList } from "@base/common";

class PropertyDetail extends Component {
  constructor(props) {
    super(props);
    this.searchTimeout = null;
    this.state = {
      propertyGroupsSearchText: "",
      propertyGroupsPageNumber: 1
    };
  }

  componentDidMount() {
    this.loadPropertyDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  loadPropertyDetails = () => {
    const { orgId, propertyId, loadProperty } = this.props;
    loadProperty(orgId, propertyId);
    this.loadPropertyPropertyGroups();
  };

  loadPropertyPropertyGroups = () => {
    const { loadPropertyPropertyGroups, propertyId, orgId } = this.props;
    if (!this.getHasPermission(PROPERTY_GROUPS_SECTION, READ_PERMISSION))
      return;
    loadPropertyPropertyGroups(orgId, propertyId);
  };

  componentDidUpdate(prevProps) {
    const {
      selectedProperty,
      setPageTitle,
      pageTitle,
      selectedPropertyStatus,
      propertyError,
      propertyId
    } = this.props;

    if (selectedProperty !== prevProps.selectedProperty) {
      const property = this.getCurrentProperty();
      if (property && property.title && pageTitle !== property.title) {
        setPageTitle(property.title);
      }
    }
    if (
      prevProps.selectedPropertyStatus === STATUS_ARCHIVING &&
      selectedPropertyStatus === STATUS_READY
    ) {
      this.showArchiveSuccess();
    }
    if (
      prevProps.selectedPropertyStatus === STATUS_REMOVING &&
      selectedPropertyStatus === STATUS_READY
    ) {
      this.showDeleteSuccess();
    }
    if (
      propertyId &&
      propertyError &&
      propertyError !== prevProps.propertyError
    ) {
      this.showErrorModal(propertyId);
    }
  }

  showErrorModal = (propertyId) => {
    const { getLabel, setModalContent, showModal } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("GENERIC_ERROR_HEADING"),
      body: getLabel("PROPERTY_ERROR_MESSAGE", {
        propertyId
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("PROPERTY_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    showModal();
    return null;
  };

  getCurrentProperty = () => {
    const { selectedProperty } = this.props;
    return selectedProperty ? selectedProperty : false;
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return this.getCurrentProperty() && this.getCurrentProperty().title
      ? getLabel("PROPERTY_HEADING_TEXT", {
          title: this.getCurrentProperty().title
        })
      : getLabel("PROPERTY_TEXT");
  };

  createSecondaryText = () => {
    const { getLabel } = this.props;
    return this.getCurrentProperty() && this.getCurrentProperty().officialName
      ? getLabel("PROPERTY_HEADING_TEXT", {
          title: this.getCurrentProperty().officialName
        })
      : getLabel("DETAILS_LABEL", { item: getLabel("PROPERTY_TEXT") });
  };

  propertyIsLoading = () => {
    const { selectedPropertyStatus, selectedPropertyPropertyGroupsLoading } =
      this.props;
    return (
      selectedPropertyStatus === STATUS_LOADING ||
      selectedPropertyPropertyGroupsLoading
    );
  };

  getDescription = () => {
    const { getLabel } = this.props;
    if (this.propertyIsLoading()) return getLabel("LOADING_LABEL");
    if (!this.getCurrentProperty()) return getLabel("GENERAL_NO_DETAILS_FOUND");
    let details = ["address", "city", "country", "postcode"];

    return details
      .map((item) => {
        return (
          <P styleType={"descriptionText"} key={item}>
            {this.getCurrentProperty()[item]}
          </P>
        );
      })
      .filter((item) => item);
  };

  getDescription = () => {
    const { getLabel } = this.props;
    if (this.propertyIsLoading()) return getLabel("LOADING_LABEL");
    if (!this.getCurrentProperty()) return getLabel("GENERAL_NO_DETAILS_FOUND");
    let details = ["address", "city", "country", "postcode"];

    return (
      <>
        {details
          .map((item) => {
            return (
              <P styleType={"descriptionText"} key={item}>
                {this.getCurrentProperty()[item]}
              </P>
            );
          })
          .filter((item) => item)}
        {this.getSpatialReference() && (
          <>
            <Heading4 styleType={SECTION_HEADER_STYLING}>
              {getLabel("SPATIAL_REFERENCE_LABEL")}
            </Heading4>
            <P styleType={"descriptionText"}>{this.getSpatialReference()}</P>
          </>
        )}
      </>
    );
  };

  getHasPermission = (section, type) => {
    const { orgId, getHasPermission } = this.props;
    return getHasPermission(orgId, section, type);
  };

  updatePropertyAction = () => {
    const { hideModal, orgId, propertyId } = this.props;

    hideModal();
    navigate(
      `/${ORGANISATION_URL}/${orgId}/${PROPERTIES_URL}/${propertyId}/${UPDATE_URL}`
    );
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    const childLinks = [];
    if (this.getHasPermission(PROPERTIES_SECTION, UPDATE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.updatePropertyAction,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("PROPERTY_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      });
    }
    if (this.getHasPermission(PROPERTIES_SECTION, UPDATE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.importDatasetsAction,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("DATASETS_TEXT"),
          action: getLabel("IMPORT_ACTION_LABEL")
        })
      });
    }
    if (this.getHasPermission(PROPERTIES_SECTION, ARCHIVE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.showArchiveConfirmation,
        buttonLabel: getLabel("PROPERTY_ACTION_LABEL", {
          item: getLabel("ARCHIVE_LABEL")
        })
      });
    }
    if (this.getHasPermission(PROPERTIES_SECTION, DELETE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.showDeleteConfirmation,
        buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
          item: getLabel("PROPERTY_TEXT")
        })
      });
    }
    return {
      childLinks,
      isDisabled: false,
      visible: childLinks.length ? true : false
    };
  };

  archiveProperty = () => {
    const { archiveProperty, orgId, propertyId } = this.props;
    archiveProperty(orgId, propertyId);
    this.showLoading("ARCHIVING_LABEL");
  };

  showArchiveConfirmation = () => {
    const { showModal, setModalContent, getLabel, hideModal } = this.props;
    setModalContent({
      header: getLabel("PROPERTY_ACTION_LABEL", {
        item: getLabel("ARCHIVE_LABEL")
      }),
      body: getLabel("ARCHIVING_PROPERTY_TEXT"),
      buttons: [
        {
          buttonLabel: getLabel("PROPERTY_ACTION_LABEL", {
            item: getLabel("ARCHIVE_LABEL")
          }),
          onClick: this.archiveProperty
        },
        {
          buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
          onClick: hideModal
        }
      ]
    });
    showModal();
  };

  deleteProperty = () => {
    const { removeProperty, orgId, propertyId } = this.props;
    removeProperty(orgId, propertyId);
    this.showLoading("DELETING_LABEL");
  };

  showDeleteConfirmation = () => {
    const { showModal, setModalContent, getLabel, hideModal } = this.props;
    setModalContent({
      header: getLabel("CONFIRM_DELETE_BUTTON", {
        item: getLabel("PROPERTY_TEXT")
      }),
      body: getLabel("CONFIRM_DELETE_BODY", {
        item: getLabel("PROPERTY_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
            item: getLabel("PROPERTY_TEXT")
          }),
          onClick: this.deleteProperty
        },
        {
          buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
          onClick: hideModal
        }
      ]
    });
    showModal();
  };

  getStatus = () => {
    const { getLabel } = this.props;
    return {
      label: getLabel("ACTIVE_LABEL"),
      iconColor: defaultTheme.agGreen
    };
  };

  showLoading = (action = "ARCHIVING_LABEL") => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel(action),
        item: getLabel("PROPERTY_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
  };

  showArchiveSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_ARCHIVED_LABEL", {
        item: getLabel("PROPERTY_TEXT")
      }),
      body: getLabel("ITEM_ARCHIVED_SUCCESS", {
        item: getLabel("PROPERTY_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("PROPERTY_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  showDeleteSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_DELETED_LABEL", {
        item: getLabel("PROPERTY_TEXT")
      }),
      body: getLabel("ITEM_DELETED_SUCCESS", {
        item: getLabel("PROPERTY_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("PROPERTY_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  returnToOverviewAction = () => {
    const { hideModal, orgId, propertyError, setPropertyError } = this.props;

    hideModal();
    if (propertyError) setPropertyError(false);
    navigate(`/${ORGANISATION_URL}/${orgId}/${PROPERTIES_URL}/`);
  };

  importDatasetsAction = () => {
    const { hideModal, orgId, propertyId } = this.props;

    hideModal();
    navigate(
      `/${ORGANISATION_URL}/${orgId}/${PROPERTIES_URL}/${propertyId}/${IMPORT_DATASETS_URL}`
    );
  };

  getDropDownLabel = (limit) => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: limit
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getSelectedPropertyPropertyGroups = () => {
    const { selectedPropertyPropertyGroups } = this.props;
    return selectedPropertyPropertyGroups
      ? filterList(
          selectedPropertyPropertyGroups,
          this.getPropertyGroupsSearchText()
        )
      : false;
  };

  getSelectedPropertyPropertyGroupsTotal = () => {
    if (!this.getSelectedPropertyPropertyGroups()) return [];
    return this.getSelectedPropertyPropertyGroups().length;
  };

  getPropertyGroupsResultsSubtext = () => {
    const { getLabel } = this.props;
    const pageLimit = this.getPropertyGroupsPageLimit();
    const pageNumber = this.getPropertyGroupsPageNumber();
    const total = this.getSelectedPropertyPropertyGroupsTotal();
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      pageNumber === 1 ? pageNumber : (pageNumber - 1) * pageLimit + 1;

    const limitEndNumber = pageNumber * pageLimit;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;
    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  getFilterLabel = (item) => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item
    });
  };

  setPropertyGroupsSearchText = (value) => {
    this.setState({
      propertyGroupsSearchText: value,
      propertyGroupsPageNumber: 1
    });
  };

  getPropertyGroupsSearchText = () => {
    return this.state.propertyGroupsSearchText;
  };

  getNoResultsText = (filterText) => {
    const { getLabel } = this.props;
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getPropertyPropertyGroupsList = () => {
    const { propertyGroupsPageNumber } = this.state;
    const pageLimit = this.getPropertyGroupsPageLimit();
    const propertyGroupsList = this.getSelectedPropertyPropertyGroups();
    if (!propertyGroupsList) return;
    const lastPropertyGroup = propertyGroupsPageNumber * pageLimit;
    const firstPropertyGroup = lastPropertyGroup - pageLimit;
    const currentPropertyGroupsList = propertyGroupsList.slice(
      firstPropertyGroup,
      lastPropertyGroup
    );
    return currentPropertyGroupsList;
  };

  getPropertyGroupsTableData = () => {
    const { orgId } = this.props;
    const propertyGroupsList = this.getPropertyPropertyGroupsList();
    if (!propertyGroupsList) return;
    return propertyGroupsList.map((group) => {
      const title = this.getHasPermission(
        PROPERTY_GROUPS_SECTION,
        READ_PERMISSION
      ) ? (
        <LinkButton
          url={`/${orgId}/${PROPERTY_GROUPS_URL}/${group.groupId}`}
          buttonLabel={group.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      ) : (
        group.title
      );

      return {
        cells: [title]
      };
    });
  };

  getPropertyGroupsLoading = () => {
    const { selectedPropertyPropertyGroupsLoading } = this.props;
    return selectedPropertyPropertyGroupsLoading
      ? selectedPropertyPropertyGroupsLoading
      : false;
  };

  getPropertyGroupsPageLimit = () => {
    const result = getSession(`properties-property-groups-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  showPropertyGroupsPagination = () => {
    return (
      this.getSelectedPropertyPropertyGroupsTotal() >
      this.getPropertyGroupsPageLimit()
    );
  };

  setPropertyGroupsPagination = (value) => {
    this.setState({ propertyGroupsPageNumber: value });
  };

  getPropertyGroupsPageNumber = () => {
    return this.state.propertyGroupsPageNumber;
  };

  getPropertyGroupsPaginationContent = () => {
    return {
      setPaginationContent: this.setPropertyGroupsPagination,
      activeNumber: this.getPropertyGroupsPageNumber(),
      numberOfButtons: this.getPropertyGroupsPageLimit(),
      totalResults: this.getSelectedPropertyPropertyGroupsTotal(),
      showPagination: this.showPropertyGroupsPagination(),
      paginationInput: true
    };
  };

  showPropertyGroupsDropdown = () => {
    return (
      this.getSelectedPropertyPropertyGroupsTotal() >
      this.getDropDownOptions()[0].value
    );
  };

  updatePropertyGroupsPerPage = (value) => {
    this.setState({ propertyGroupsPageNumber: 1 });
    storeSession(`properties-property-groups-${PAGINATION_KEY}`, value);
  };

  getPropertyGroupsPageDropDownInfo = () => {
    return {
      showDropdown: this.showPropertyGroupsDropdown(),
      dropDownValue: this.getPropertyGroupsPageLimit(),
      dropDownLabel: this.getDropDownLabel(this.getPropertyGroupsPageLimit()),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: this.updatePropertyGroupsPerPage
    };
  };

  getSpatialReference = () => {
    return this.getCurrentProperty()?.spatialReference ?? null;
  };

  render() {
    const { getLabel } = this.props;
    const { propertyGroupsSearchText } = this.state;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.propertyIsLoading() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={this.getContextMenu()}
                status={this.getStatus()}
              />
            </Container>
            <Section>
              <Heading3 styleType={SECTION_HEADER_STYLING}>
                {this.createSecondaryText()}
              </Heading3>
              {this.getDescription()}
            </Section>
            {this.getHasPermission(
              PROPERTY_GROUPS_SECTION,
              READ_PERMISSION
            ) && (
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <DataTable
                  title={getLabel("PROPERTY_GROUPS_TEXT")}
                  loading={this.getPropertyGroupsLoading()}
                  noResultsText={this.getNoResultsText(
                    propertyGroupsSearchText
                  )}
                  showResultsText={true}
                  resultsSubtext={this.getPropertyGroupsResultsSubtext()}
                  tableFilter={{
                    filterLabel: this.getFilterLabel(
                      getLabel("PROPERTY_GROUPS_TEXT")
                    ),
                    showFilter: true,
                    filterFunction: this.setPropertyGroupsSearchText,
                    isDisabled: false,
                    placeholderText: getLabel(
                      "PROPERTY_GROUP_FILTER_PLACEHOLDER_LABEL"
                    ),
                    inputId: this.getFilterLabel(
                      getLabel("PROPERTY_GROUPS_TEXT")
                    )
                  }}
                  header={{
                    cells: [{ content: getLabel("PROPERTY_GROUPS_TEXT") }]
                  }}
                  rows={this.getPropertyGroupsTableData()}
                  pagination={this.getPropertyGroupsPaginationContent()}
                  pageDropDown={this.getPropertyGroupsPageDropDownInfo()}
                />
              </Section>
            )}
            <BottomButtonBar />
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default PropertyDetail;
