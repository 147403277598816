import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextAreaElement } from "./style";
import { FIELD_SIZE_LARGE } from "@base/constants";

/**
 * Button component that creates a new button from style components and optionally includes an Icon
 */
class TextArea extends Component {
  static propTypes = {
    /**Whether the text area is disabled or not */
    isDisabled: PropTypes.bool,
    /**The max length of characters in the text area */
    maxLength: PropTypes.number,
    /**The optional placeholder */
    placeholder: PropTypes.string,
    /**Whether the text area is read only */
    readOnly: PropTypes.bool,
    /**What to display in the textArea */
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.bool,
      PropTypes.object
    ]),
    /**What to display in the textArea */
    styleType: PropTypes.string,
    /**What size for the textArea */
    size: PropTypes.string,
    /**A unique ID for the element */
    id: PropTypes.string,
    /**Function called on update of text area (object type is for testing purposes) */
    onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
      .isRequired,
    /** Used to populate the aria-labelledby property on the TextArea element */
    labelledBy: PropTypes.string
  };

  /**
   * Returns if the text area is set to disabled, defaults to false
   * @public
   */
  isDisabled = () => {
    return this.props.isDisabled === true;
  };

  /**
   * Returns maximum character count of the text area, defaults to 10000
   * @public
   */
  getMaxLength = () => {
    return this.props.maxLength === null || this.props.maxLength === undefined
      ? 10000
      : this.props.maxLength;
  };

  /**
   * Returns the placeholder to display in the text area, defaults to empty string
   * @public
   */
  getPlaceholder = () => {
    return this.props.placeholder ? this.props.placeholder : "";
  };

  /**
   * Returns if the text area is set to read only, defaults to false
   * @public
   */
  isReadOnly = () => {
    return this.props.readOnly === true;
  };

  /**
   * Returns the value to display in the text area
   * @public
   */
  getValue = () => {
    return this.props.value;
  };

  /**
   * Function to set a particular style onto the textArea
   * @public
   */
  getStyleType = () => {
    return this.props.styleType ? this.props.styleType : "";
  };

  /**
   * Function to set a particular style onto the textArea
   * @public
   */
  getSize = () => {
    return this.props.size ? this.props.size : FIELD_SIZE_LARGE;
  };

  render() {
    return (
      <TextAreaElement
        size={this.getSize()}
        styleType={this.getStyleType()}
        data-name={"TextArea"}
        maxLength={this.getMaxLength()}
        placeholder={this.getPlaceholder()}
        readonly={this.isReadOnly()}
        disabled={this.isDisabled()}
        value={this.getValue()}
        onChange={(event) => {
          this.props.onChange(event.target.value);
        }}
        id={this.props.id}
        aria-labelledby={this.props.labelledBy}
      />
    );
  }
}

export default TextArea;
