import React, { Component } from "react";
import { getURLPathParameter, sortMethod } from "@base/common";
import { P, Heading3, Legend } from "@typography";
import { Container, Wrapper, Section, Table, Icon } from "@core";
import { Loader, ContextHeader } from "@UIKit";

import {
  ORGANISATION_URL,
  DATASETS_URL,
  DESCRIPTION_TEXT_PARAGRAPH,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  SECTION_HEADER_STYLING,
  READ_PERMISSION,
  UPDATE_PERMISSION,
  ADD_PERMISSION,
  DELETE_PERMISSION,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_HEADING_STYLING
} from "@base/constants";

class DatasetDetail extends Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      datasetId: null
    };
  }

  componentDidMount() {
    this.loadDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  componentDidUpdate(prevProps) {
    const { selectedOrgDatasets, setPageTitle, pageTitle } = this.props;
    if (selectedOrgDatasets !== prevProps.selectedOrgDatasets) {
      const dataset = this.getOrgDataset()?.[0];
      if (dataset && dataset.name && pageTitle !== dataset.name) {
        setPageTitle(dataset.name);
      }
    }
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  loadDetails = () => {
    const { loadOrganisationDatasets } = this.props;
    const orgId = getURLPathParameter(ORGANISATION_URL);
    const datasetId = getURLPathParameter(DATASETS_URL);
    this.setValue(datasetId, "datasetId");
    loadOrganisationDatasets(orgId, [datasetId]);
  };

  getOrgDataset = () => {
    const { selectedOrgDatasets } = this.props;
    return selectedOrgDatasets ? selectedOrgDatasets : false;
  };

  getOrgDatasetLoading = () => {
    const { selectedOrgDatasetsLoading } = this.props;
    return selectedOrgDatasetsLoading;
  };

  createHeaderText = () => {
    return this.getValue("datasetId");
  };

  getContextMenu = () => {
    return {
      isDisabled: true,
      visible: false
    };
  };

  createSecondHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("DETAILS_LABEL", { item: getLabel("DATASET_TEXT") });
  };

  getDatabaseId = () => {
    const { getLabel } = this.props;
    const database = this.getOrgDataset()?.[0]?.database;
    if (!database) {
      return null;
    }
    return (
      <P styleType={DESCRIPTION_TEXT_PARAGRAPH}>
        {getLabel("DATABASE_LABEL")}: <strong>{database}</strong>
      </P>
    );
  };

  getLastUpdatedDate = () => {
    const { getLabel } = this.props;
    const updatedAt = this.getOrgDataset()?.[0]?.updatedAt;
    if (!updatedAt) {
      return null;
    }
    const formattedDate = new Date(updatedAt).toLocaleDateString("en-GB");
    return (
      <P styleType={DESCRIPTION_TEXT_PARAGRAPH}>
        {getLabel("LAST_UPDATED_DATE_LABEL")}: <strong>{formattedDate}</strong>
      </P>
    );
  };

  getCreatedDate = () => {
    const { getLabel } = this.props;
    const createdAt = this.getOrgDataset()?.[0]?.createdAt;
    if (!createdAt) {
      return null;
    }
    const formattedDate = new Date(createdAt).toLocaleDateString("en-GB");
    return (
      <P styleType={DESCRIPTION_TEXT_PARAGRAPH}>
        {getLabel("CREATED_DATE_LABEL")}: <strong>{formattedDate}</strong>
      </P>
    );
  };

  getRoleCapabilitiesTableValues = (roleCapabilities) => {
    if (!roleCapabilities || Object.keys(roleCapabilities).length === 0) {
      return { uniqueRoles: [], rows: [], permissionTypes: [] };
    }
    let permissionTypes = Object.keys(roleCapabilities);
    const allRoles = Object.values(roleCapabilities).flat().filter(Boolean);

    const desiredOrder = [
      READ_PERMISSION,
      UPDATE_PERMISSION,
      ADD_PERMISSION,
      DELETE_PERMISSION
    ];
    const orderedPermissionTypes = desiredOrder.every((perm) =>
      permissionTypes.includes(perm)
    )
      ? desiredOrder
      : permissionTypes;

    const uniqueRoles = Array.from(
      new Map(allRoles.map((role) => [role.roleId, role])).values()
    );
    const rows = uniqueRoles.map((role) => {
      const permissions = orderedPermissionTypes.map((perm) => {
        const hasPermission = roleCapabilities[perm]?.some(
          (r) => r?.roleId === role.roleId
        );
        return {
          icon: hasPermission ? "tick" : "close",
          color: hasPermission ? "#00ab7a" : "#d17e21"
        };
      });
      return { roleId: role.roleId, permissions };
    });
    return { uniqueRoles, rows, permissionTypes: orderedPermissionTypes };
  };

  getRoleCapabilitiesTable = () => {
    const { getLabel } = this.props;
    const roleCapabilities = this.getOrgDataset()?.[0]?.roleCapabilities;
    if (!roleCapabilities || Object.keys(roleCapabilities).length === 0) {
      return null;
    }
    const { rows, permissionTypes } =
      this.getRoleCapabilitiesTableValues(roleCapabilities);
    if (rows.length === 0) return null;

    const renderRows = rows.map((row, index) => (
      <tr key={`key-${row.roleId}-${index}`}>
        <td>{row.roleId}</td>
        {row.permissions.map((permission, permIndex) => (
          <td
            key={`key-${row.roleId}-${permIndex}`}
            aria-label={
              permission.icon === "tick" ? "Has permission" : "No permission"
            }
          >
            <Icon
              type={permission.icon}
              bgWidth="30px"
              bgHeight="30px"
              iconHeight="20px"
              iconWidth="20px"
              iconColor={permission.color}
            />
          </td>
        ))}
      </tr>
    ));
    return (
      <Table evenSpace={permissionTypes.length + 1}>
        <thead>
          <tr>
            <th>{getLabel("ROLE_TEXT")}</th>
            {permissionTypes.map((perm) => (
              <th key={perm}>{perm.charAt(0).toUpperCase() + perm.slice(1)}</th>
            ))}
          </tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </Table>
    );
  };

  getDefaultValuesTable = () => {
    const { getLabel } = this.props;
    let defaultValues = this.getOrgDataset()?.[0]?.defaultValues;
    if (!defaultValues || Object.keys(defaultValues).length === 0) {
      return null;
    }

    const defaultValuesArray = Object.keys(defaultValues).flatMap((key) => ({
      key,
      value:
        typeof defaultValues[key] === "boolean"
          ? String(defaultValues[key])
          : defaultValues[key]
    }));
    const sortedDefaultValues = sortMethod(defaultValuesArray, "key", "asc");
    const renderRows = sortedDefaultValues.map(({ key, value }) => (
      <tr key={key}>
        <td>{key}</td>
        <td>{value}</td>
      </tr>
    ));
    return (
      <Table>
        <thead>
          <tr>
            <th>{getLabel("FORM_FIELD_LABEL")}</th>
            <th>{getLabel("FORM_VALUE_LABEL")}</th>
          </tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </Table>
    );
  };

  getDomainValuesTable = () => {
    const { getLabel } = this.props;
    const domainValues = this.getOrgDataset()?.[0]?.domainValues;
    if (!domainValues || Object.keys(domainValues).length === 0) {
      return null;
    }

    const renderRows = Object.keys(domainValues)
      .flatMap((field) => {
        if (!Array.isArray(domainValues[field])) {
          return null;
        }

        let isFirstRow = true;
        return domainValues[field]
          .map((item, index) => {
            if (
              !(item.title && item.value) &&
              !(item.varietyName && item.varietyCode)
            ) {
              return null;
            }
            const row = (
              <tr key={`${field}-${index}`}>
                {isFirstRow ? (
                  <td rowSpan={domainValues[field].length}>{field}</td>
                ) : null}
                <td>{item.title || item.varietyName}</td>
                <td>{item.value || item.varietyCode}</td>
              </tr>
            );
            isFirstRow = false;
            return row;
          })
          .filter((row) => row !== null);
      })
      .filter((row) => row !== null);

    if (renderRows.length === 0) {
      return null;
    }

    return (
      <Table evenSpace={3}>
        <thead>
          <tr>
            <th>{getLabel("FORM_FIELD_LABEL")}</th>
            <th>{getLabel("FORM_TITLE_NAME_LABEL")}</th>
            <th>{getLabel("FORM_VALUE_LABEL")}</th>
          </tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </Table>
    );
  };

  getLayerTables = () => {
    const { getLabel } = this.props;
    const layers = this.getOrgDataset()?.[0]?.layers;
    if (!layers || layers.length === 0) {
      return null;
    }

    return layers.map((layer, index) => (
      <Section key={`layer-${index}`} styleType={DATA_TABLE_SECTION_STYLING}>
        <Heading3 styleType={SECTION_HEADER_STYLING}>{layer.title}</Heading3>
        <Heading3 styleType={DATA_TABLE_HEADING_STYLING}>
          {`${layer.title} ${getLabel("FORM_DETAILS_LABEL")}`}
        </Heading3>
        <Table evenSpace={2}>
          <thead>
            <tr>
              <th>{`${getLabel("FORM_TABLE_LABEL")} ${getLabel(
                "FORM_ID_LABEL"
              )}`}</th>
              <th>{`${getLabel("FORM_GEOMETRY_LABEL")} ${getLabel(
                "FORM_TYPE_LABEL"
              )}`}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{layer.table}</td>
              <td>{layer.geometryType}</td>
            </tr>
          </tbody>
        </Table>
        <br />
        <Heading3 styleType={DATA_TABLE_HEADING_STYLING}>
          {`${layer.title} Fields`}
        </Heading3>
        <Table evenSpace={2}>
          <thead>
            <tr>
              <th>{getLabel("FORM_FIELD_LABEL")}</th>
              <th>{getLabel("FORM_TYPE_LABEL")}</th>
            </tr>
          </thead>
          <tbody>
            {layer.fields.map((field, fieldIndex) => (
              <tr key={`field-${fieldIndex}`}>
                <td>{field.title}</td>
                <td>{field.dataType}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Section>
    ));
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.getOrgDatasetLoading() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={this.getContextMenu()}
              />
            </Container>
            <Section>
              <Heading3 styleType={SECTION_HEADER_STYLING}>
                {this.createSecondHeaderText()}
              </Heading3>
              {this.getDatabaseId()}
              {this.getLastUpdatedDate()}
              {this.getCreatedDate()}
            </Section>
            {this.getRoleCapabilitiesTable() && (
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Heading3 styleType={DATA_TABLE_HEADING_STYLING}>
                  {getLabel("DATASET_DETAILS_ROLE_CAPABILITIES_TABLE_HEADING")}
                </Heading3>
                {this.getRoleCapabilitiesTable()}
              </Section>
            )}
            {this.getDefaultValuesTable() && (
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Heading3 styleType={DATA_TABLE_HEADING_STYLING}>
                  {getLabel("DATASET_DETAILS_DEFAULT_VALUES_TABLE_HEADING")}
                </Heading3>
                {this.getDefaultValuesTable()}
              </Section>
            )}
            {this.getDomainValuesTable() && (
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Heading3 styleType={DATA_TABLE_HEADING_STYLING}>
                  {getLabel("DATASET_DETAILS_DOMAIN_VALUES_TABLE_HEADING")}
                </Heading3>
                {this.getDomainValuesTable()}
              </Section>
            )}
            {this.getLayerTables()}
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default DatasetDetail;
