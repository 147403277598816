import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Button, FormInput, FormLabel } from "@core";
import { FILTER_INPUT_ID, CONTENT_FADE_TIMEOUT } from "@base/constants";
import { CSSTransition } from "react-transition-group";
import { defaultTheme } from "@UIKit";
import { FilterInputElement } from "./style";

/**
 * FilterInput component. Made up of an label, input, a filter and clear button.
 */
class FilterInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: ""
    };
  }
  static propTypes = {
    /** Whether the filter Input is disabled or not */
    isDisabled: PropTypes.bool,
    /** What to display in the filter Input entry field */
    label: PropTypes.string.isRequired,
    /** What to display in the placeholder for the filter Input entry field */
    placeholder: PropTypes.string.isRequired,
    /** The label to display on the button for filtering */
    buttonLabel: PropTypes.string,
    /** The filter Function to run, if liveFilter is true, the happens onChange, if false then happens on button click */
    filterFunction: PropTypes.func,
    /** Whether to filter as user types */
    liveFilter: PropTypes.bool,
    /** styleType for styling */
    styleType: PropTypes.string,
    /** inputId for FormLabel htlmFor and FormInput id attributes */
    inputId: PropTypes.string
  };

  static defaultProps = {
    label: "Filter",
    buttonLabel: "Filter",
    placeholder: "e.g: Joe Bloggs",
    liveFilter: false,
    styleType: FILTER_INPUT_ID
  };

  /**
   * Returns the value to display in the text area
   * @private
   */
  getFilterValue = () => {
    const { filterValue } = this.state;
    return filterValue;
  };

  /**
   * sets the value to display in the text area
   * @private
   */
  setFilterValue = (value) => {
    if (value === null || value === undefined) return;
    this.setState({
      filterValue: value
    });
  };

  /**
   * Returns whether the text input field is blank
   * @private
   */
  textInputIsBlank = () => {
    return !!(this.getFilterValue() && this.getFilterValue() !== "");
  };

  /**
   * empties the filter text value
   * @private
   */
  clearFilterValue = async () => {
    await this.setFilterValue("");
    if (this.getLiveFilter() === true) {
      this.getFilterFunction(this.getFilterValue());
    }
  };

  /**
   * Returns the button Label  to display in the filter button
   * @public
   */
  getButtonLabel = () => {
    const { buttonLabel } = this.props;
    return buttonLabel;
  };

  /**
   * Returns the function that runs for filtering, if liveFilter is true, then happens onChange, else onClick of button.
   * @public
   */
  getFilterFunction = (value) => {
    const { filterFunction } = this.props;
    return filterFunction ? filterFunction(value) : null;
  };

  /**
   * Returns the placeholder Label  to display in the text input
   * @public
   */
  getPlaceholder = () => {
    const { placeholder } = this.props;
    return placeholder;
  };

  /**
   * Returns the placeholder Label  to display in the text input
   * @public
   */
  getLabel = () => {
    const { label } = this.props;
    return label;
  };

  /**
   * Returns whether liveFilter is set
   * @public
   */
  getLiveFilter = () => {
    const { liveFilter } = this.props;
    return liveFilter === true;
  };

  /**
   * Returns if the text area is set to disabled, defaults to false
   * @public
   */
  isDisabled = () => {
    const { isDisabled } = this.props;
    return isDisabled ? isDisabled : false;
  };

  getInputId = () => this.props.inputId;

  render() {
    return (
      <Container styleType={this.props.styleType}>
        <FilterInputElement
          data-name={"FilterInputElement"}
          styleType={this.props.styleType}
          isDisabled={this.isDisabled()}
        >
          <FormLabel htmlFor={this.getInputId()}>{this.getLabel()}</FormLabel>
          <FormInput
            id={this.getInputId()}
            isDisabled={this.isDisabled()}
            placeholder={this.getPlaceholder()}
            value={this.getFilterValue()}
            onChange={async (event) => {
              await this.setFilterValue(event);
              if (this.getLiveFilter() === true) {
                this.getFilterFunction(this.getFilterValue());
              }
            }}
          />

          <CSSTransition
            in={this.textInputIsBlank()}
            timeout={CONTENT_FADE_TIMEOUT}
            classNames="css-transition"
            unmountOnExit
          >
            <Button
              buttonStyleType={"close"}
              isDisabled={false}
              onClick={this.clearFilterValue}
              buttonLabel={""}
              ariaLabel={"clear"}
              icon={{
                type: "close",
                bgWidth: "30px",
                bgHeight: "30px",
                iconHeight: "20px",
                iconWidth: "20px",
                iconColor: defaultTheme.agDarkRed
              }}
            />
          </CSSTransition>
        </FilterInputElement>
        {this.getLiveFilter() === false && (
          <Button
            data-name={"filterInputButton"}
            buttonStyleType={FILTER_INPUT_ID}
            isDisabled={this.isDisabled()}
            onClick={() => {
              this.getFilterFunction(this.getFilterValue());
            }}
            buttonLabel={this.getButtonLabel()}
          />
        )}
      </Container>
    );
  }
}

export default FilterInput;
