import { connect } from "react-redux";
import AddWorkflow from "./AddWorkflow";
import * as actions from "../../../actions";
import {
  getLabels,
  getSelectedWorkflowStatus,
  getAbortController,
  getPageTitle,
  getWorkflowTemplates,
  getWorkflowTemplatesStatus
} from "../../../selectors";
import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    selectedWorkflowStatus: getSelectedWorkflowStatus(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    abortController: getAbortController(state),
    workflowTemplates: getWorkflowTemplates(state),
    workflowTemplatesLoading:
      getWorkflowTemplatesStatus(state) === STATUS_LOADING
  }),
  actions
)(AddWorkflow);
