import { connect } from "react-redux";
import WorkflowDetail from "./WorkflowDetail";
import * as actions from "../../../actions";

import {
  getLabels,
  getOrgsStatus,
  getSelectedOrgLoading,
  getSelectedOrg,
  getAvailableOrgs,
  getSelectedWorkflow,
  getSelectedWorkflowStatus,
  getSelectedWorkflowPropertyGroups,
  getSelectedWorkflowPropertyGroupsLoading,
  getSelectedWorkflowProperties,
  getSelectedWorkflowPropertiesLoading,
  getPageTitle,
  getAbortController,
  getHasPermission
} from "../../../selectors";

import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    availableOrganisations: getAvailableOrgs(state),
    organisationsStatus: getOrgsStatus(state),
    organisationsLoading: getOrgsStatus(state) === STATUS_LOADING,
    selectedOrganisationLoading: getSelectedOrgLoading(state),
    selectedOrganisation: getSelectedOrg(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedWorkflow: getSelectedWorkflow(state),
    selectedWorkflowStatus: getSelectedWorkflowStatus(state),
    selectedWorkflowPropertyGroups: getSelectedWorkflowPropertyGroups(state),
    selectedWorkflowPropertyGroupsLoading:
      getSelectedWorkflowPropertyGroupsLoading(state),
    selectedWorkflowProperties: getSelectedWorkflowProperties(state),
    selectedWorkflowPropertiesLoading:
      getSelectedWorkflowPropertiesLoading(state),
    abortController: getAbortController(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType)
  }),
  actions
)(WorkflowDetail);
