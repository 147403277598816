import React, { Component } from "react";
import { PaginationWrapper } from "./style";
import PaginationV2Component from "./subComponents/PaginationV2Component";
import PaginationV2InputGroup from "./subComponents/PaginationV2InputGroup";

class PaginationV2 extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (window.innerWidth > 750) {
      this.setState({ width: window.innerWidth });
    }
  }

  currentPageNumber = () => {
    const { currentPageNumber } = this.props;
    return currentPageNumber ? currentPageNumber : 1;
  };

  handlePreviousPage = () => {
    const { setPaginationContent } = this.props;
    const currentPageNumber = this.currentPageNumber();
    setPaginationContent(currentPageNumber - 1);
  };

  handleNextPage = () => {
    const { setPaginationContent } = this.props;
    const currentPageNumber = this.currentPageNumber();
    setPaginationContent(currentPageNumber + 1);
  };

  handleFirstPage = () => {
    const { setPaginationContent } = this.props;
    setPaginationContent(1);
  };

  handleLastPage = () => {
    const { setPaginationContent } = this.props;
    setPaginationContent(this.pageCount());
  };

  firstButtonDisabled = () => {
    const { currentPageNumber } = this.props;
    return currentPageNumber === 1;
  };

  lastButtonDisabled = () => {
    const currentPageNumber = this.currentPageNumber();
    return currentPageNumber === this.pageCount();
  };

  pageCount = () => {
    const { listPerPageNum, totalItems } = this.props;
    if (!totalItems || !listPerPageNum) return 0;
    return Math.ceil(totalItems / listPerPageNum);
  };

  handleSelectPage = (pageNumber) => {
    const { setPaginationContent } = this.props;
    setPaginationContent(pageNumber);
  };

  getUpdatedPageNumbers = (currentButton, pageNumbers) => {
    const { width } = this.state;
    let updatedPageNumbers = [];
    let dots = "...";

    const workableWidth = (width - 690) / 56;
    const theNumber = Math.round(workableWidth / 2) * 2;
    const dividedNumber = theNumber / 2;
    const numberToLeft = dividedNumber;
    const numberToRight = dividedNumber - 1;

    if (
      (pageNumbers.length > 3 && pageNumbers.length <= 10 && width > 1150) ||
      pageNumbers.length <= 3
    ) {
      updatedPageNumbers = pageNumbers;
    } else if (currentButton <= theNumber) {
      const sliced = pageNumbers.slice(0, theNumber);
      updatedPageNumbers = [...sliced, dots, pageNumbers.length];
    } else if (currentButton < pageNumbers.length - dividedNumber) {
      const sliced1 = pageNumbers.slice(
        currentButton - numberToLeft,
        currentButton
      );
      const sliced2 = pageNumbers.slice(
        currentButton,
        currentButton + numberToRight
      );
      updatedPageNumbers = [
        1,
        dots,
        ...sliced1,
        ...sliced2,
        dots,
        pageNumbers.length
      ];
    } else if (currentButton > pageNumbers.length - theNumber) {
      const sliced = pageNumbers.slice(pageNumbers.length - theNumber);
      updatedPageNumbers = [1, dots, ...sliced];
    }
    return updatedPageNumbers;
  };

  pages = () => {
    const currentButton = this.currentPageNumber();
    let pageNumbers = [];
    const pageCount = this.pageCount();
    for (let i = 1; i <= pageCount; i++) {
      pageNumbers.push(i);
    }

    const updatedPageNumbers = this.getUpdatedPageNumbers(
      currentButton,
      pageNumbers
    );

    return updatedPageNumbers;
  };

  isActivePage = (pageNumber) => {
    return pageNumber === this.currentPageNumber();
  };

  getShowPaginationInput = () => {
    const { showPaginationInput } = this.props;
    return showPaginationInput;
  };

  render() {
    return (
      <PaginationWrapper data-name={"PaginationWrapper"}>
        <PaginationV2Component
          isActivePage={this.isActivePage}
          handleNextPage={this.handleNextPage}
          handlePreviousPage={this.handlePreviousPage}
          firstButtonDisabled={this.firstButtonDisabled()}
          lastButtonDisabled={this.lastButtonDisabled()}
          pages={this.pages()}
          handleSelectPage={this.handleSelectPage}
          handleFirstPage={this.handleFirstPage}
          handleLastPage={this.handleLastPage}
        />
        {this.getShowPaginationInput() && (
          <PaginationV2InputGroup
            currentPageNumber={this.currentPageNumber()}
            handleSelectPage={this.handleSelectPage}
            pageCount={this.pageCount()}
            ariaLabel={"Page number"}
          />
        )}
      </PaginationWrapper>
    );
  }
}

export default PaginationV2;
