export const getSelectedWorkflow = (state) => {
  return state.workflow && state.workflow.available
    ? state.workflow.available
    : false;
};

export const getSelectedWorkflowStatus = (state) => {
  return state.workflow.status;
};

export const getSelectedWorkflowPropertyGroups = (state) => {
  return state.workflow &&
    state.workflow.propertyGroups &&
    state.workflow.propertyGroups.available
    ? state.workflow.propertyGroups.available
    : false;
};

export const getSelectedWorkflowPropertyGroupsLoading = (state) => {
  return (
    state.workflow &&
    state.workflow.propertyGroups &&
    state.workflow.propertyGroups.isLoading
  );
};

export const getSelectedWorkflowProperties = (state) => {
  return state.workflow &&
    state.workflow.properties &&
    state.workflow.properties.available
    ? state.workflow.properties.available
    : false;
};

export const getSelectedWorkflowPropertiesLoading = (state) => {
  return (
    state.workflow &&
    state.workflow.properties &&
    state.workflow.properties.isLoading
  );
};
