import { connect } from "react-redux";
import UpdateRole from "./UpdateRole";
import * as actions from "../../../actions";
import {
  getLabels,
  getUserLogin,
  getPageTitle,
  getSelectedRole,
  getAbortController,
  getSelectedRoleStatus,
  getSelectedOrgWorkflows,
  getSelectedOrgWorkflowsTotal,
  getSelectedOrgWorkflowsLoading,
  getSelectedOrgRoles,
  getSelectedOrgRolesLoading,
  getToken
} from "../../../selectors";

export default connect(
  (state) => ({
    user: getUserLogin(state),
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant, data }),
    abortController: getAbortController(state),
    selectedRole: getSelectedRole(state),
    selectedRoleStatus: getSelectedRoleStatus(state),
    selectedOrgWorkflows: getSelectedOrgWorkflows(state),
    selectedOrgWorkflowsTotal: getSelectedOrgWorkflowsTotal(state),
    selectedOrgWorkflowsLoading: getSelectedOrgWorkflowsLoading(state),
    selectedOrganisationRoles: getSelectedOrgRoles(state),
    selectedOrganisationRolesLoading: getSelectedOrgRolesLoading(state),
    token: getToken(state)
  }),
  actions
)(UpdateRole);
