import React, { Component } from "react";
import { Container, Wrapper, Image } from "@core";
import {
  AUTH_VIEW,
  LANDKIND_LOGO_SVG,
  LANDKIND_LOGO_ALT_TEXT,
  LANDKIND_LOGO_AUTH_WIDTH,
  STYLETYPE_AUTH_HEADING,
  AUTH_TEXT
} from "@base/constants";
import { Heading1, P } from "@typography";

export default class AccessDenied extends Component {
  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={AUTH_VIEW}>
        <Wrapper styleType={AUTH_VIEW}>
          <Image
            altText={LANDKIND_LOGO_ALT_TEXT}
            source={LANDKIND_LOGO_SVG}
            width={LANDKIND_LOGO_AUTH_WIDTH}
          />
          <Heading1 styleType={STYLETYPE_AUTH_HEADING}>
            {getLabel("ADMIN_ACCESS_DENIED_HEADING_LABEL")}
          </Heading1>
          <P styleType={AUTH_TEXT}>
            {getLabel("ADMIN_ACCESS_DENIED_BODY_LABEL")}
          </P>
        </Wrapper>
      </Container>
    );
  }
}
