import React, { Component } from "react";
import { Container, Wrapper } from "@core";
import { ModalElement, ModalShade } from "./style";
import { CSSTransition } from "react-transition-group";
import { CONTENT_FADE_TIMEOUT, MODAL_ID } from "@base/constants";
import ModalHeader from "./subComponents/ModalHeader";
import ModalCloseButton from "./subComponents/ModalCloseButton";
import ModalBody from "./subComponents/ModalBody";
import ModalBottomButtons from "./subComponents/ModalBottomButtons";
import PropTypes from "prop-types";
import FocusTrap from "focus-trap-react";
/**
 * The Modal sits ontop of all pages and a single focussed action to present to the user, it comes as a single Element.
 The element is made up of the shade, and the content.
 The content itself is broken down into 3 core elements: header, content, actions.
 */
class Modal extends Component {
  static propTypes = {
    /** Whether the Modal is visible or not */
    visible: PropTypes.bool,
    /** The content to display in the header component */
    headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /** The content to display in the body component */
    bodyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /** Whether to show the close button in top right, will hide Modal */
    showCloseButton: PropTypes.bool,
    /** The function to run when the user clicks the close button in the top right */
    onCloseClick: PropTypes.func,
    /** An array of children to render as buttons */
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        /** The label for the childs button  */
        buttonLabel: PropTypes.string,
        /** The link for the childs button (optional) if not supplied, then button will be disabled and render just a text  */
        onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        /** The icon for the childs button (optional) if ommitted, link will be rendered without an icon */
        iconType: PropTypes.string,
        /** The iconColor for the childs button (optional) if ommitted, link will be rendered with default iconColor black */
        iconColor: PropTypes.string,
        /** The style for the childs button (optional) if ommitted, link will be rendered as primary */
        buttonStyleType: PropTypes.string,
        /** The style for isDisabled (optional) if ommitted, will be true else false */
        isDisabled: PropTypes.bool
      })
    )
  };

  static defaultProps = {
    visible: false,
    headerContent: "",
    showCloseButton: true,
    onCloseClick: null
  };

  /**
   * Internal method for setting styles for displaying correctly in styleguide.
   Defaults to false;
   * @private
   */
  getForStyleguide = () => {
    return this.props.forStyleguide ? this.props.forStyleguide : "false";
  };

  /**
   * Returns whether to show the close button in the top right;
   * @public
   */
  showCloseButton = () => {
    if (
      this.props.showCloseButton === true ||
      this.props.showCloseButton === false
    ) {
      return this.props.showCloseButton;
    }
    return true;
  };

  getVisible = () => {
    return this.props.visible ? this.props.visible : false;
  };

  render() {
    return (
      <Wrapper>
        <CSSTransition
          in={this.getVisible()}
          timeout={CONTENT_FADE_TIMEOUT}
          classNames="css-transition"
          unmountOnExit
        >
          <FocusTrap
            focusTrapOptions={{
              fallbackFocus: "#modal-container"
            }}
          >
            <ModalElement
              role="dialog"
              aria-modal="true"
              data-name={"modal-element"}
              forStyleguide={this.getForStyleguide()}
            >
              <Container styleType={MODAL_ID} id={"modal-container"}>
                {this.showCloseButton() && (
                  <ModalCloseButton onCloseClick={this.props.onCloseClick} />
                )}
                <ModalHeader headerContent={this.props.headerContent} />
                <ModalBody bodyContent={this.props.bodyContent} />
                <ModalBottomButtons buttons={this.props.buttons} />
              </Container>
              <ModalShade forStyleguide={this.getForStyleguide()} />
            </ModalElement>
          </FocusTrap>
        </CSSTransition>
      </Wrapper>
    );
  }
}

export default Modal;
