import styled, { css } from "styled-components";
import {
  STYLETYPE_ERROR,
  STYLETYPE_CHECKBOX,
  STYLETYPE_RADIOBUTTON,
  DATA_TABLE_CHECKBOX_STYLING
} from "@base/constants";

export const FormLabelElement = styled.label`
  font-family: ${(props) => props.theme.baseFont};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  line-height: ${(props) => props.theme.lineHeightBase};
  font-size: ${(props) => props.theme.fontSizeBase};
  color: ${(props) => props.theme.agCommonBlack};
  letter-spacing: 0.01em;
  margin: ${(props) =>
    props.styleType === "labelText" ? "0.5rem 0 0 0.5rem" : "0rem"};

  ${(props) =>
    props.styleType === STYLETYPE_RADIOBUTTON &&
    css`
      align-self: left;
      color: ${(props) => props.theme.agBlack};
      font-size: ${(props) => props.theme.fontSizeOne};
      font-weight: ${(props) => props.theme.baseFontWeightRegular};
    `}

  ${(props) =>
    props.styleType === STYLETYPE_CHECKBOX &&
    css`
      align-self: center;
      color: ${(props) => props.theme.agBlack};
      font-size: ${(props) => props.theme.fontSizeOne};
      font-weight: ${(props) => props.theme.baseFontWeightRegular};
    `}

  ${(props) =>
    props.styleType === DATA_TABLE_CHECKBOX_STYLING &&
    css`
      align-self: center;
      color: ${(props) => props.theme.agBlack};
      font-size: ${(props) => props.theme.fontSizeOne};
      font-weight: ${(props) => props.theme.baseFontWeightRegular};
    `}
    
  ${(props) =>
    props.styleType === STYLETYPE_ERROR &&
    css`
      color: ${(props) => props.theme.agRed};
      font-weight: 800;
    `}
    
  ${(props) =>
    props.styleType === "superHumanRadioGroup" &&
    css`
      margin-bottom: 1rem;
    `}

  & + & {
    margin-top: ${(props) => {
      return props.direction === "horizontal" ? "0rem" : "0.5rem";
    }};
  }
`;
FormLabelElement.displayName = "FormLabelElement";
