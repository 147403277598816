import styled from "styled-components";
import {
  STYLETYPE_ERROR,
  FIELD_SIZE_SMALL,
  FIELD_SIZE_MEDIUM,
  FIELD_SIZE_LARGE,
  STYLETYPE_TEXT
} from "@base/constants";

// @ts-ignore
export const TextAreaElement = styled.textarea`
  width:100%;
  font-family: ${(props) =>
    props.styleType === STYLETYPE_TEXT ? props.theme.baseFont : "inherit"};
  font-size: ${(props) => props.theme.fontSizeSmall}
  min-height: ${(props) => {
    if (props.size === FIELD_SIZE_SMALL) return "7.5rem";
    if (props.size === FIELD_SIZE_MEDIUM) return "15.625rem";
    if (props.size === FIELD_SIZE_LARGE) return "31.25rem";
    return "auto";
  }} ;
  height:auto;
  border: ${(props) =>
    props.styleType === STYLETYPE_ERROR
      ? `border: 2px solid ${props.theme.agRed}`
      : "1px solid #ccc"};

  & + button {
    margin-top:2rem;
  }
`;
TextAreaElement.displayName = "TextAreaElement";
