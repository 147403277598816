import React, { Component } from "react";
import { Button, FormInput } from "@core";
import { DROPDOWN_ID } from "@base/constants";
import PropTypes from "prop-types";
import { defaultTheme } from "@UIKit";
import { DropdownInputElement } from "../style";
//import DropdownButtonList from "./subComponents/DropdownButtonList";

/**
 * The context menu provides a way to provide a list of links in a dropdown.
 The element is made up of a button that reveals or hides a list of buttons.
 */
class DropdownInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false
    };
  }
  static propTypes = {
    /**What to display in the dropdown entry field */
    label: PropTypes.string.isRequired,
    /** Whether the context menu is visible or not */
    visible: PropTypes.bool,
    /** Whether the context menu is disabled or not */
    isDisabled: PropTypes.bool,
    /** An array of children to render as buttons */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        /** Whether the child button is disabled or not */
        isDisabled: PropTypes.bool,
        /** The label for the childs button  */
        label: PropTypes.string,
        /** The value for the childs button  */
        value: PropTypes.string
      })
    ),
    /** The function for the dropdown (optional) if not supplied, then button will be disabled and render just a text  */
    onSelect: PropTypes.func,
    id: PropTypes.string,
    /** An aria described by attribute */
    ariaDescribedBy: PropTypes.string
  };

  static defaultProps = {
    visible: true,
    isDisabled: false
  };

  /**
   * Returns the label to display in the text area
   * @public
   */
  getLabel = () => {
    return this.props.label;
  };

  /**
   * Returns the dropdown children property
   * @public
   */
  getOptions = () => {
    const { options } = this.props;
    return options && options.length ? options : false;
  };

  /**
   * Toggles the state of the open button
   * @private
   */
  toggleOpen = () => {
    const { openMenu } = this.state;
    this.setState({
      openMenu: !openMenu
    });
  };

  /**
   * Returns the open state of the menu
   * @private
   */
  getOpen = () => {
    const { openMenu } = this.state;
    return openMenu;
  };

  /**
   * Returns the disabled state of the menu
   * @private
   */
  getDisabled = () => {
    const { isDisabled } = this.props;
    return !!isDisabled;
  };

  /**
   * Returns the onClick function applied to the button
   * @private
   */
  getOnClickFunction = () => {
    const { onClick } = this.props;
    return onClick ? onClick : null;
  };

  getOnBlurFunction = () => {
    const { onBlur } = this.props;
    return onBlur ? onBlur : null;
  };

  /**
   * Returns the onFocus function applied to the input
   * @private
   */
  getOnFocusFunction = () => {
    const { onFocus } = this.props;
    return onFocus ? onFocus : null;
  };

  /**
   * Returns the onChcnage function applied to the input
   * @private
   */
  getOnChangeFunction = (event) => {
    const { onChange } = this.props;
    return onChange && event ? onChange(event.target.value) : null;
  };

  /**
   * Returns the placeholder to display in the text area
   * @public
   */
  getPlaceholder = () => {
    return this.props.placeholder;
  };

  getInputId = () => {
    const { id } = this.props;
    return id ? id : "";
  };

  render() {
    return (
      <DropdownInputElement onBlur={this.getOnBlurFunction()}>
        <FormInput
          readOnly={true}
          styleType={DROPDOWN_ID}
          onFocus={this.getOnFocusFunction()}
          value={this.getLabel()}
          placeholder={this.getPlaceholder()}
          id={this.getInputId()}
          onChange={(event) => {
            this.getOnChange(event);
          }}
          ariaDescribedBy={this.props.ariaDescribedBy}
          ariaLabel={this.props.ariaLabel}
        />
        <Button
          buttonStyleType={DROPDOWN_ID}
          isDisabled={this.getDisabled()}
          onClick={this.getOnClickFunction()}
          buttonLabel={""}
          icon={{
            type: "down-triangle",
            bgWidth: "30px",
            bgHeight: "30px",
            iconHeight: "14px",
            iconWidth: "14px",
            iconColor: defaultTheme.agCommonBlack
          }}
        />
      </DropdownInputElement>
    );
  }
}

export default DropdownInput;
