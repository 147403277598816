import React, { Component } from "react";
import { Container, Wrapper, Section, FormInput, FormLabel } from "@core";
import {
  CONTENT_VIEW,
  STATUS_LOADING,
  CONTEXT_HEADER_CONTAINER,
  STYLETYPE_FORM_FIELD,
  STYLETYPE_ERROR,
  STYLE_TYPE_EMAIL,
  STYLE_TYPE_NAME,
  BUTTON_TYPE_PRIMARY,
  BUTTON_TYPE_SECONDARY,
  STATUS_UPDATING,
  STATUS_READY,
  AGBOX_API_URL,
  AGBOX_API_KEY,
  STYLETYPE_FORM_FIELD_FLEX,
  BUTTON_TYPE_PRIMARY_FULLWIDTH,
  BUTTON_TYPE_SECONDARY_FULLWIDTH,
  HEIGHT_AUTO_TEXT
} from "@base/constants";
import { ContextHeader, Loader, BottomButtonBar, Combobox } from "@UIKit";
import { P } from "@typography";
import { validateEmail } from "../../../common";
import { navigate } from "@reach/router";
import AgBoxApiRequests from "../../../apis/agbox/requests";
const { requestAddressCandidates, requestAddressSuggestions } =
  AgBoxApiRequests(AGBOX_API_URL, AGBOX_API_KEY);

class UpdateProperty extends Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();

    this.state = {
      title: "",
      address: "",
      officialName: "",
      primaryContactName: "",
      primaryContactEmail: "",
      addressSuggestions: [],
      addressCandidate: null,
      addressSuggestion: null,
      city: "",
      state: "",
      postcode: "",
      spatialReference: "",
      originalState: {},
      active: null
    };
  }

  componentDidMount() {
    this.loadPropertyDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  componentDidUpdate(prevProps) {
    const {
      pageTitle,
      setPageTitle,
      selectedProperty,
      selectedPropertyStatus
    } = this.props;
    if (pageTitle !== selectedProperty.title) {
      setPageTitle(selectedProperty.title);
    }
    if (
      prevProps.selectedPropertyStatus === STATUS_UPDATING &&
      selectedPropertyStatus === STATUS_READY
    ) {
      this.showSuccess();
    }
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  loadPropertyDetails = () => {
    const {
      orgId,
      propertyId,
      loadUserMembership,
      loadProperty,
      propState: {
        login: { userId }
      }
    } = this.props;
    loadProperty(orgId, propertyId);
    loadUserMembership(orgId, userId);
  };

  static getDerivedStateFromProps(props, state) {
    const { selectedProperty } = props;
    if (
      selectedProperty &&
      !state.city &&
      !state.title &&
      !state.state &&
      !state.active &&
      !state.postcode &&
      !state.address &&
      !state.officialName &&
      !state.primaryContactName &&
      !state.primaryContactEmail &&
      !state.spatialReference
    ) {
      const {
        city,
        title,
        state,
        active,
        postcode,
        address,
        officialName,
        primaryContactName,
        primaryContactEmail,
        spatialReference
      } = selectedProperty;

      return {
        city,
        title,
        state,
        active: active === null ? "1" : String(active),
        postcode,
        address,
        officialName,
        primaryContactName,
        primaryContactEmail,
        spatialReference: spatialReference || "",
        originalState: {
          city,
          title,
          state,
          postcode,
          address,
          officialName,
          primaryContactName,
          primaryContactEmail,
          spatialReference: spatialReference || ""
        }
      };
    }
    return state;
  }

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      item: getLabel("PROPERTY_TEXT"),
      action: getLabel("UPDATE_ACTION_LABEL")
    });
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_UPDATED_LABEL", {
        item: getLabel("PROPERTY_TEXT")
      }),
      body: getLabel("ITEM_UPDATED_SUCCESS", {
        item: getLabel("PROPERTY_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("PROPERTY_TEXT")
          }),
          onClick: this.returnToOverview
        },
        {
          buttonLabel: getLabel("RETURN_TO_DETAILS", {
            item: getLabel("PROPERTY_TEXT")
          }),
          onClick: this.returnToDetails
        }
      ]
    });
    return null;
  };

  returnToOverview = () => {
    const { hideModal } = this.props;
    hideModal();
    navigate(`../`);
  };

  returnToDetails = () => {
    const { hideModal } = this.props;
    hideModal();
    navigate(`./`);
  };

  isLoadingPropertyDetails = () => {
    const { selectedPropertyStatus, selectedUserMembershipLoading } =
      this.props;
    return (
      selectedUserMembershipLoading || selectedPropertyStatus === STATUS_LOADING
    );
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return "";
    return this.state[field];
  };

  valueIsEmpty = (field) => {
    return !this.getValue(field);
  };

  getEmailErrorMessage = () => {
    const { getLabel } = this.props;
    const emailValue = this.getValue("primaryContactEmail");
    if (!emailValue) return null;
    if (!validateEmail(emailValue))
      return getLabel("EMAIL_ADDRESS_INVALID_LABEL");
    return null;
  };

  isInvalid = () => {
    return (
      this.getEmailErrorMessage() !== null ||
      this.valueIsEmpty("title") ||
      this.valueIsEmpty("address")
    );
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("UPDATE_ACTION_LABEL"),
        item: getLabel("PROPERTY_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  getPropertyData = () => {
    const {
      selectedProperty: { objectId }
    } = this.props;
    const {
      city,
      title,
      state,
      active,
      postcode,
      address,
      officialName,
      primaryContactName,
      primaryContactEmail
    } = this.state;
    return {
      city,
      title,
      state,
      active,
      postcode,
      address,
      objectId,
      officialName,
      primaryContactName,
      primaryContactEmail
    };
  };

  updatePropertyAction = async () => {
    try {
      const {
        updateProperty,
        updatePropertyData,
        orgId,
        propertyId,
        showModal,
        selectedUserMembership: { roleId }
      } = this.props;

      this.showLoading();
      showModal();

      const data = this.getPropertyData();
      await updateProperty(orgId, propertyId, roleId, data);

      const { spatialReference, originalState } = this.state;
      if (spatialReference !== originalState.spatialReference) {
        const spatialReferenceData = JSON.stringify({
          spatialReference: spatialReference
        });
        await updatePropertyData(orgId, propertyId, spatialReferenceData);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  resetProperty = () => {
    const { selectedProperty } = this.props;
    if (!selectedProperty) return;
    const {
      city,
      title,
      state,
      postcode,
      address,
      officialName,
      primaryContactName,
      primaryContactEmail,
      spatialReference
    } = selectedProperty;
    this.setState({
      city,
      title,
      state,
      postcode,
      address,
      officialName,
      primaryContactName,
      primaryContactEmail,
      spatialReference
    });
  };

  showMessage = () => {
    const { setModalContent, showModal, getLabel } = this.props;
    showModal();
    setModalContent({
      showCloseButton: false,
      header: getLabel("UPDATE_ACTION_HEADER_LABEL", {
        item: getLabel("PROPERTY_TEXT")
      }),
      body: getLabel("BACK_TO_DETAILS_MESSAGE"),
      buttons: [
        {
          buttonStyleType: BUTTON_TYPE_PRIMARY_FULLWIDTH,
          buttonLabel: getLabel("YES_LABEL"),
          onClick: this.updatePropertyAction,
          isDisabled: this.isInvalid()
        },
        {
          buttonStyleType: BUTTON_TYPE_SECONDARY_FULLWIDTH,
          buttonLabel: getLabel("NO_LABEL"),
          onClick: this.returnToDetails
        }
      ]
    });
    return null;
  };

  getStateChanged = () => {
    const {
      city,
      title,
      state,
      postcode,
      address,
      officialName,
      primaryContactName,
      primaryContactEmail,
      spatialReference,
      originalState
    } = this.state;
    if (
      city !== originalState.city ||
      title !== originalState.title ||
      state !== originalState.state ||
      postcode !== originalState.postcode ||
      address !== originalState.address ||
      officialName !== originalState.officialName ||
      primaryContactName !== originalState.primaryContactName ||
      primaryContactEmail !== originalState.primaryContactEmail ||
      spatialReference !== originalState.spatialReference
    ) {
      return true;
    }
    return false;
  };

  getButtons = () => {
    const { getLabel } = this.props;
    const stateChanged = this.getStateChanged();
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        isDisabled: this.isInvalid(),
        onClick: this.updatePropertyAction,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("PROPERTY_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      },
      {
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        onClick: this.resetProperty,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("PROPERTY_TEXT"),
          action: getLabel("RESET_LABEL")
        })
      },
      {
        onClick: stateChanged ? this.showMessage : this.returnToDetails,
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        buttonLabel: getLabel("RETURN_TO_DETAILS", {
          item: getLabel("PROPERTY_TEXT")
        })
      }
    ];
  };

  updateAddressValue = (address) => {
    this.setState({
      address,
      addressSuggestions: [],
      addressSuggestion: null
    });
    this.getAddressSuggestions(address);
  };

  getAddressSuggestions = async (address) => {
    try {
      if (!address) return;
      const { suggestions } = await requestAddressSuggestions(address);
      this.setState({
        addressSuggestions: suggestions.map((suggestion) => ({
          ...suggestion,
          label: suggestion.text,
          value: suggestion.text
        })),
        addressCandidate: null,
        addressSuggestion: null
      });
    } catch (e) {
      const { setError } = this.props;
      setError(e);
    }
  };

  setAddressSuggestion = (suggestion) => {
    this.setState({
      addressSuggestion: suggestion
    });
    this.getAddressCandidate(suggestion);
  };

  getAddressCandidate = async (suggestion) => {
    try {
      if (!suggestion) return;
      const { text, magicKey } = suggestion;
      const { candidates } = await requestAddressCandidates(
        text,
        magicKey,
        this.controller.signal
      );
      this.setState({
        addressCandidate: candidates[0]
      });
      this.setCandidateFields(candidates[0]);
    } catch (e) {
      const { setError } = this.props;
      setError(e);
    }
  };

  setCandidateFields = (candidate) => {
    if (!candidate) return;
    const { City, Region, Postal, ShortLabel } = candidate.attributes;
    this.setState({
      city: City ? City : "",
      state: Region ? Region : "",
      postcode: Postal ? Postal : "",
      address: ShortLabel ? ShortLabel : ""
    });
  };

  addressCandidateEmpty = () => {
    const { addressCandidate } = this.state;
    return addressCandidate === null;
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoadingPropertyDetails() ? (
          <Loader fullpage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
              />
            </Container>

            <Container>
              <Section>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <FormLabel
                    htmlFor={"title"}
                    styleType={
                      this.valueIsEmpty("title") ? STYLETYPE_ERROR : ""
                    }
                  >
                    {getLabel("FORM_TITLE_LABEL")}
                  </FormLabel>
                  <FormInput
                    id={"title"}
                    value={this.getValue("title")}
                    onChange={(value) => {
                      this.setValue(value, "title");
                    }}
                    styleType={
                      this.valueIsEmpty("title") ? STYLETYPE_ERROR : ""
                    }
                  />
                </Container>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <FormLabel htmlFor={"official-name"}>
                    {getLabel("OFFICIAL_NAME_LABEL")}
                  </FormLabel>
                  <FormInput
                    id={"official-name"}
                    value={this.getValue("officialName") || ""}
                    onChange={(value) => {
                      this.setValue(value, "officialName");
                    }}
                  />
                </Container>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <FormLabel htmlFor="primary-contact-name">
                    {getLabel("PRIMARY_CONTACT_NAME_LABEL")}
                  </FormLabel>
                  <FormInput
                    value={this.getValue("primaryContactName") || ""}
                    onChange={(value) => {
                      this.setValue(value, "primaryContactName");
                    }}
                    id="primary-contact-name"
                    type={STYLE_TYPE_NAME}
                  />
                </Container>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <FormLabel htmlFor="primary-contact-email">
                    {getLabel("PRIMARY_CONTACT_EMAIL_LABEL")}
                  </FormLabel>
                  <FormInput
                    value={this.getValue("primaryContactEmail") || ""}
                    onChange={(value) => {
                      this.setValue(value, "primaryContactEmail");
                    }}
                    styleType={
                      this.getEmailErrorMessage() ? STYLETYPE_ERROR : ""
                    }
                    ariaDescribedBy={
                      this.getEmailErrorMessage()
                        ? "primaryContactEmail-error"
                        : null
                    }
                    id="primary-contact-email"
                    type={STYLE_TYPE_EMAIL}
                    aria-invalid={this.getEmailErrorMessage()}
                  />
                  {this.getEmailErrorMessage() && (
                    <P styleType={"error"} id="primaryContactEmail-error">
                      {this.getEmailErrorMessage()}
                    </P>
                  )}
                </Container>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <Combobox
                    id={"update-property-address"}
                    inputValue={this.getValue("address")}
                    inputLabel={getLabel("ADDRESS_LABEL")}
                    onChangeInputValue={this.updateAddressValue}
                    isInvalid={this.valueIsEmpty("address")}
                    listItems={this.getValue("addressSuggestions")}
                    onSelectListItem={this.setAddressSuggestion}
                    listValue={this.getValue("address")}
                    showList={this.addressCandidateEmpty()}
                  />
                  <Container
                    styleType={STYLETYPE_FORM_FIELD_FLEX}
                    direction={"row"}
                  >
                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <FormLabel htmlFor={"city"}>
                        {getLabel("CITY_LABEL")}
                      </FormLabel>
                      <FormInput
                        value={this.getValue("city")}
                        onChange={(value) => {
                          this.setValue(value, "city");
                        }}
                        id={"city"}
                      ></FormInput>
                    </Container>
                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <FormLabel htmlFor={"postcode"}>
                        {getLabel("POSTCODE_LABEL")}
                      </FormLabel>
                      <FormInput
                        value={this.getValue("postcode") || ""}
                        onChange={(value) => {
                          this.setValue(value, "postcode");
                        }}
                        id={"postcode"}
                      ></FormInput>
                    </Container>
                  </Container>
                  <Container
                    styleType={STYLETYPE_FORM_FIELD_FLEX}
                    direction={"row"}
                  >
                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <FormLabel htmlFor={"state"}>
                        {getLabel("STATE_LABEL")}
                      </FormLabel>
                      <FormInput
                        value={this.getValue("state")}
                        onChange={(value) => {
                          this.setValue(value, "state");
                        }}
                        id={"state"}
                      ></FormInput>
                    </Container>
                  </Container>
                </Container>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <FormLabel htmlFor="spatial-reference">
                    {getLabel("SPATIAL_REFERENCE_LABEL")}
                  </FormLabel>
                  <FormInput
                    value={this.getValue("spatialReference") || ""}
                    onChange={(value) => {
                      this.setValue(value, "spatialReference");
                    }}
                    id="spatial-reference"
                  />
                </Container>
              </Section>
              <BottomButtonBar buttons={this.getButtons()} />
            </Container>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default UpdateProperty;
