import { connect } from "react-redux";
import UpdateOrganisation from "./UpdateOrganisation";
import * as actions from "../../../actions";
import {
  getLabels,
  getOrgsStatus,
  getSelectedOrg,
  getPageTitle,
  getSelectedOrgLoading,
  getAbortController
} from "../../../selectors";
import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant, data }),
    selectedOrganisation: getSelectedOrg(state),
    selectedOrganisationLoading: getSelectedOrgLoading(state),
    organisationsStatus: getOrgsStatus(state),
    organisationsLoading: getOrgsStatus(state) === STATUS_LOADING,
    abortController: getAbortController(state)
  }),
  actions
)(UpdateOrganisation);
