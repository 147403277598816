import { createLogic } from "redux-logic";
import {
  LOAD_ORGANISATIONS,
  LOAD_ORGANISATION,
  LOAD_ORGANISATION_USERS,
  LOAD_ORGANISATION_PROPERTY_GROUPS,
  LOAD_ORGANISATION_WORKFLOWS,
  LOAD_ORGANISATION_ROLES,
  LOAD_ORGANISATION_PROPERTIES,
  LOAD_ORG_ARCHIVED_PROPERTIES,
  LOAD_ORGANISATION_PROPERTY_WORKFLOWS,
  LOAD_ORGANISATION_PROPERTY_PROPERTY_GROUPS,
  UPDATE_ORGANISATION,
  LOAD_ORGANISATION_USERS_ROLES,
  CREATE_ORGANISATION,
  ABORT_ERROR_NAME,
  REMOVE_ORGANISATION,
  LOAD_ORGANISATION_ACCEPTED_USERS,
  PAGINATION_KEY,
  PAGINATION_OPTIONS,
  LOAD_ORGANISATION_DATASETS,
  LOAD_ORGANISATION_RENDERERS,
  LOAD_ORGANISATION_RENDERER,
  CREATE_RENDERER,
  UPDATE_RENDERER,
  UPDATED_RENDERER,
  CREATE_DATASET,
  JOB_STATUS_FAILED,
  JOB_STATUS_SUCCEEDED
} from "@base/constants";
import { pollJob, getSession } from "../common";
import { LOAD_ORGANISATION_INVITED_USERS, UPLOAD_ORG_LOGO } from "../constants";

const loadOrganisationPropertyWorkflowsLogic = createLogic({
  type: LOAD_ORGANISATION_PROPERTY_WORKFLOWS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedOrganisationPropertyWorkflows } =
        globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);

      const { orgId, propId, roleId } = action.payload;

      const signal = getAbortSignal(getState());
      const { requestPropertyWorkflows } = agBoxApiRequests;

      const propertyWorkflows = await requestPropertyWorkflows(
        orgId,
        propId,
        roleId,
        token,
        signal
      );

      dispatch(
        loadedOrganisationPropertyWorkflows(
          propertyWorkflows.meta.total,
          propId
        )
      );
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationPropertyPropertyGroupsLogic = createLogic({
  type: LOAD_ORGANISATION_PROPERTY_PROPERTY_GROUPS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedOrganisationPropertyPropertyGroups } =
        globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);

      const { orgId, propId } = action.payload;
      const { requestPropertyPropertyGroups } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const propertyPropertyGroups = await requestPropertyPropertyGroups(
        orgId,
        propId,
        token,
        signal
      );

      dispatch(
        loadedOrganisationPropertyPropertyGroups(propertyPropertyGroups, propId)
      );
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationsLogic = createLogic({
  type: LOAD_ORGANISATIONS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getLabels } = selectors;
      const { setLoadingText, loadedOrganisations, setAppLoading } =
        globalActions;
      const { FETCHING_ORGANISATIONS_TEXT } = getLabels(getState());
      dispatch(setLoadingText(FETCHING_ORGANISATIONS_TEXT));
      const token = getToken(state);
      const { requestOrganisations } = agBoxApiRequests;
      const organisations = await requestOrganisations(token);

      dispatch(loadedOrganisations(organisations));
      dispatch(setAppLoading(false));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError, loadedOrganisations, setAppLoading } = globalActions;
      dispatch(setError(e));
      dispatch(setAppLoading(false));
      if (e.cause && e.cause === "ERROR_AUTH_USER_UNAUTHORISED") {
        dispatch(loadedOrganisations([]));
      }

      done();
    }
  }
});

const loadOrganisationLogic = createLogic({
  type: LOAD_ORGANISATION,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedOrganisation } = globalActions;
      const { FETCHING_ORGANISATIONS_TEXT } = getLabels(getState());
      dispatch(setLoadingText(FETCHING_ORGANISATIONS_TEXT));

      const token = getToken(state);
      const { orgId, returnCounts } = action.payload;

      const { requestOrganisation } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const organisation = await requestOrganisation(
        orgId,
        token,
        signal,
        returnCounts
      );
      dispatch(loadedOrganisation(organisation));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationUsersLogic = createLogic({
  type: LOAD_ORGANISATION_USERS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels, getUserSuperHuman } =
        selectors;
      const { setLoadingText, loadedOrganisationUsers } = globalActions;
      const { FETCHING_ORGANISATIONS_TEXT } = getLabels(getState());
      dispatch(setLoadingText(FETCHING_ORGANISATIONS_TEXT));

      const token = getToken(state);
      const orgId = action.payload;
      const isSuperHuman = getUserSuperHuman(getState());
      const { requestOrganisationUsers } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const users = await requestOrganisationUsers(
        orgId,
        isSuperHuman,
        token,
        signal
      );
      dispatch(loadedOrganisationUsers(users));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationPropertyGroupsLogic = createLogic({
  type: LOAD_ORGANISATION_PROPERTY_GROUPS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedOrganisationPropertyGroups } =
        globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);
      const orgId = action.payload;
      const { requestOrganisationPropertyGroups } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const propertyGroups = await requestOrganisationPropertyGroups(
        orgId,
        token,
        signal
      );
      dispatch(loadedOrganisationPropertyGroups(propertyGroups));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationWorkflowsLogic = createLogic({
  type: LOAD_ORGANISATION_WORKFLOWS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedOrganisationWorkflows } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);
      const orgId = action.payload;

      const { requestOrganisationWorkflows } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const workflows = await requestOrganisationWorkflows(
        orgId,
        token,
        signal
      );
      dispatch(loadedOrganisationWorkflows(workflows));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationRolesLogic = createLogic({
  type: LOAD_ORGANISATION_ROLES,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedOrganisationRoles } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);
      const orgId = action.payload;

      const { requestOrganisationRoles } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const roles = await requestOrganisationRoles(orgId, token, signal);
      dispatch(loadedOrganisationRoles(roles));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationPropertiesLogic = createLogic({
  type: LOAD_ORGANISATION_PROPERTIES,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const {
        getToken,
        getAbortSignal,
        getLabels,
        getOrgPropertiesOffset,
        getOrgPropertiesSearch
      } = selectors;
      const { setLoadingText, loadedOrganisationProperties } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);
      const orgId = action.payload;
      const limit =
        getSession(`properties-${PAGINATION_KEY}`) || PAGINATION_OPTIONS[0];
      const offset = getOrgPropertiesOffset(state)
        ? getOrgPropertiesOffset(state)
        : 0;
      const search = getOrgPropertiesSearch(state)
        ? getOrgPropertiesSearch(state)
        : "";

      const { requestOrganisationProperties } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const roles = await requestOrganisationProperties(
        orgId,
        token,
        signal,
        limit,
        offset,
        search
      );
      dispatch(loadedOrganisationProperties(roles));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrgArchivedPropertiesLogic = createLogic({
  type: LOAD_ORG_ARCHIVED_PROPERTIES,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const {
        getToken,
        getAbortSignal,
        getLabels,
        getOrgArchivedPropsOffset,
        getOrgArchivedPropsSearch
      } = selectors;
      const { setLoadingText, loadedOrgArchivedProperties } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);
      const orgId = action.payload;

      const limit =
        getSession(`archived-properties-${PAGINATION_KEY}`) ||
        PAGINATION_OPTIONS[0];
      const offset = getOrgArchivedPropsOffset(state)
        ? getOrgArchivedPropsOffset(state)
        : 0;
      const search = getOrgArchivedPropsSearch(state)
        ? getOrgArchivedPropsSearch(state)
        : "";

      const { requestOrgArchivedProperties } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const properties = await requestOrgArchivedProperties(
        orgId,
        token,
        signal,
        limit,
        offset,
        search
      );
      dispatch(loadedOrgArchivedProperties(properties));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

let updateTries = 0;
const updateOrganisationLogic = createLogic({
  type: UPDATE_ORGANISATION,
  async process(
    { globalActions, agBoxApiUpdates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, updatedOrganisation } = globalActions;
      const { UPDATING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(UPDATING_LABEL));

      const token = getToken(state);
      const { orgId, data } = action.payload;
      const { partiallyUpdateOrganisation } = agBoxApiUpdates;
      const signal = getAbortSignal(getState());
      await partiallyUpdateOrganisation(orgId, data, token, signal);
      dispatch(updatedOrganisation(orgId));
      updateTries = 0;
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      updateTries++;
      if (updateTries === 3) {
        const { setError } = globalActions;
        dispatch(setError(e));
        updateTries = 0;
      } else if (updateTries < 3) {
        const { updateOrganisation } = globalActions;
        const { orgId, data } = action.payload;
        dispatch(updateOrganisation(orgId, data));
      }
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const loadOrganisationUsersRolesLogic = createLogic({
  type: LOAD_ORGANISATION_USERS_ROLES,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedOrganisationUsersRoles } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);

      const { orgId, roleId } = action.payload;
      if (orgId && roleId) {
        const { requestRole, requestRoleUsers } = agBoxApiRequests;
        const signal = getAbortSignal(getState());

        const roleUsers = await requestRoleUsers(orgId, roleId, token, signal);
        const role = await requestRole(orgId, roleId, token, signal);
        const { title } = role;
        dispatch(loadedOrganisationUsersRoles(title, roleUsers, roleId));
      }
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const createOrganisationLogic = createLogic({
  type: CREATE_ORGANISATION,
  async process(
    {
      globalActions,
      getState,
      agBoxApiCreates,
      agBoxApiRequests,
      agBoxApiUpdates,
      selectors,
      action
    },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, createdOrganisation } = globalActions;
      const { CREATING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(CREATING_LABEL));

      const token = getToken(state);
      const signal = getAbortSignal(getState());
      const { orgDetails } = action.payload;

      if (!orgDetails) return done();
      const { layerRendererJSON, labelRendererJSON } = orgDetails.preferences;
      delete orgDetails.preferences.layerRendererJSON;
      delete orgDetails.preferences.labelRendererJSON;
      const { preferences } = orgDetails;
      const { orgId, jobId, logo } = await agBoxApiCreates.createOrganisation(
        JSON.stringify({
          ...orgDetails,
          logo: preferences.logo ? true : false
        }),
        token,
        signal
      );

      if (orgId && jobId) {
        await pollJob(
          agBoxApiRequests.requestOrganisationStatus,
          orgId,
          jobId,
          token,
          signal
        );
      }
      if (logo && logo.url && logo.fields) {
        const { url, fields } = logo;
        const formData = new FormData();
        Object.keys(fields).forEach((key) => {
          const field = fields[key];
          formData.append(key, field);
        });

        formData.append("Content-Type", preferences.logo.type);
        formData.append("file", preferences.logo, preferences.logo.name);
        await agBoxApiUpdates.uploadLogoToOrganisation(url, formData, signal);
      }
      if (orgId && layerRendererJSON) {
        await agBoxApiCreates.createRenderer(
          orgId,
          layerRendererJSON,
          token,
          signal
        );
      }
      if (orgId && labelRendererJSON) {
        await agBoxApiCreates.createRenderer(
          orgId,
          labelRendererJSON,
          token,
          signal
        );
      }
      dispatch(createdOrganisation());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});
let uploadTries = 0;
const uploadOrgLogoLogic = createLogic({
  type: UPLOAD_ORG_LOGO,
  async process(
    {
      globalActions,
      getState,
      agBoxApiUpdates,
      agBoxApiRequests,
      selectors,
      action
    },
    dispatch,
    done
  ) {
    try {
      const { getAbortSignal, getToken } = selectors;
      const { updateOrganisation } = globalActions;
      const { orgId, logo } = action.payload;
      const token = getToken(getState());
      const signal = getAbortSignal(getState());
      const { fields, url } =
        await agBoxApiRequests.requestLogoUploadPresignedUrl(
          orgId,
          token,
          signal
        );
      if (url && fields) {
        const formData = new FormData();
        Object.keys(fields).forEach((key) => {
          const field = fields[key];
          formData.append(key, field);
        });

        formData.append("Content-Type", logo.type);
        formData.append("file", logo, logo.name);

        await agBoxApiUpdates.uploadLogoToOrganisation(url, formData, signal);
        dispatch(
          updateOrganisation(
            orgId,
            JSON.stringify({
              preferences: {
                logo: `https://${fields.bucket}.s3.amazonaws.com/${fields.key}`
              }
            })
          )
        );
      }
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);

      uploadTries++;
      if (uploadTries === 3) {
        const { setError } = globalActions;
        dispatch(setError(e));
        uploadTries = 0;
      } else if (updateTries < 3) {
        const { uploadOrgLogo } = globalActions;
        const { orgId, logo } = action.payload;
        dispatch(uploadOrgLogo(orgId, logo));
      }
      done();
    }
  }
});

const removeOrganisationLogic = createLogic({
  type: REMOVE_ORGANISATION,
  async process(
    {
      globalActions,
      agBoxApiRemoves,
      agBoxApiRequests,
      getState,
      selectors,
      action
    },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, removedOrganisation } = globalActions;
      const { DELETING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(DELETING_LABEL));

      const token = getToken(state);
      const orgId = action.payload;
      const { removeOrganisation } = agBoxApiRemoves;
      const signal = getAbortSignal(getState());
      const { jobId } = await removeOrganisation(orgId, token, signal);
      if (jobId && orgId) {
        await pollJob(
          agBoxApiRequests.requestRemoveOrganisationStatus,
          jobId,
          token,
          signal
        );
        dispatch(removedOrganisation());
        done();
      }
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationInvitedUsersLogic = createLogic({
  type: LOAD_ORGANISATION_INVITED_USERS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedOrganisationInvitedUsers } = globalActions;
      const { FETCHING_ORGANISATIONS_TEXT } = getLabels(getState());
      dispatch(setLoadingText(FETCHING_ORGANISATIONS_TEXT));

      const token = getToken(state);
      const orgId = action.payload;

      const { requestOrganisationInvitedUsers } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const users = await requestOrganisationInvitedUsers(orgId, token, signal);
      dispatch(loadedOrganisationInvitedUsers(users));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationAcceptedUsersLogic = createLogic({
  type: LOAD_ORGANISATION_ACCEPTED_USERS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedOrganisationAcceptedUsers } = globalActions;
      const { FETCHING_ORGANISATIONS_TEXT } = getLabels(getState());
      dispatch(setLoadingText(FETCHING_ORGANISATIONS_TEXT));

      const token = getToken(state);
      const orgId = action.payload;

      const { requestOrganisationAcceptedUsers } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const users = await requestOrganisationAcceptedUsers(
        orgId,
        token,
        signal
      );
      dispatch(loadedOrganisationAcceptedUsers(users));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationDatasetsLogic = createLogic({
  type: LOAD_ORGANISATION_DATASETS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal } = selectors;
      const { loadedOrganisationDatasets } = globalActions;
      const token = getToken(state);
      const { orgId, datasets } = action.payload;

      const { requestDataset } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const orgDatasets = await Promise.all(
        datasets.map((dataset) => requestDataset(orgId, dataset, token, signal))
      ).catch((e) => {
        throw e;
      });
      dispatch(loadedOrganisationDatasets(orgDatasets));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationRenderersLogic = createLogic({
  type: LOAD_ORGANISATION_RENDERERS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal } = selectors;
      const { loadedOrganisationRenderers } = globalActions;
      const token = getToken(state);
      const orgId = action.payload;

      const { requestOrganisationRenderers } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const orgRenderers = await requestOrganisationRenderers(
        orgId,
        token,
        signal
      );
      dispatch(loadedOrganisationRenderers(orgRenderers));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadOrganisationRendererLogic = createLogic({
  type: LOAD_ORGANISATION_RENDERER,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal } = selectors;
      const { loadedOrganisationRenderer } = globalActions;
      const token = getToken(state);
      const { orgId, rendererId } = action.payload;

      const { requestOrganisationRenderer } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const orgRenderer = await requestOrganisationRenderer(
        orgId,
        rendererId,
        token,
        signal
      );
      dispatch(loadedOrganisationRenderer(orgRenderer));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const createRendererLogic = createLogic({
  type: CREATE_RENDERER,
  async process(
    { globalActions, getState, agBoxApiCreates, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, createdRenderer } = globalActions;
      const { CREATING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(CREATING_LABEL));

      const token = getToken(state);
      const { orgId, data } = action.payload;
      const { createRenderer } = agBoxApiCreates;
      const signal = getAbortSignal(getState());
      await createRenderer(orgId, data, token, signal);
      dispatch(createdRenderer());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const updateRendererLogic = createLogic({
  type: UPDATE_RENDERER,
  async process(
    { globalActions, agBoxApiUpdates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, updatedRenderer } = globalActions;
      const { UPDATING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(UPDATING_LABEL));

      const token = getToken(state);
      const { orgId, rendererId, data } = action.payload;
      const { partiallyUpdateRenderer } = agBoxApiUpdates;
      const signal = getAbortSignal(getState());
      await partiallyUpdateRenderer(orgId, rendererId, data, token, signal);
      dispatch(updatedRenderer(orgId, rendererId));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const updatedRendererLogic = createLogic({
  type: UPDATED_RENDERER,
  async process(
    { globalActions, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const { getLabels } = selectors;
      const { setLoadingText, loadOrganisationRenderer } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const { orgId, rendererId } = action.payload;

      dispatch(loadOrganisationRenderer(orgId, rendererId));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const createDatasetLogic = createLogic({
  type: CREATE_DATASET,
  async process(
    {
      globalActions,
      getState,
      agBoxApiCreates,
      agBoxApiRequests,
      selectors,
      action
    },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, createdDataset } = globalActions;
      const { CREATING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(CREATING_LABEL));

      const token = getToken(state);
      const { orgId, data, isFromTemplate } = action.payload;
      const { createDataset } = agBoxApiCreates;
      const { requestCreateDatasetStatus } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      let responses = [];

      if (isFromTemplate) {
        responses = await data.reduce(async (accPromise, d) => {
          const acc = await accPromise;

          const templateData = JSON.stringify({
            name: d.title,
            dataSetTemplateId: d.templateId
          });

          const response = await createDataset(
            orgId,
            templateData,
            token,
            signal
          );
          const { jobId, dataSets } = response;

          let jobStatus = await pollJob(
            requestCreateDatasetStatus,
            orgId,
            dataSets[0].name,
            jobId,
            token,
            signal
          );
          acc.push({ jobStatus, dataSetName: dataSets[0].name, response });
          return acc;
        }, Promise.resolve([]));

        const failedJobs = responses.filter(
          (r) => r.jobStatus !== JOB_STATUS_SUCCEEDED
        );
        const successfulJobs = responses.filter(
          (r) => r.jobStatus === JOB_STATUS_SUCCEEDED
        );

        if (failedJobs.length) {
          const errorMessage = [
            `${JOB_STATUS_FAILED}:\n${failedJobs
              .map((j) => j.dataSetName)
              .join("\n")}`,
            successfulJobs.length &&
              `${JOB_STATUS_SUCCEEDED}:\n${successfulJobs
                .map((j) => j.dataSetName)
                .join("\n")}`
          ]
            .filter(Boolean)
            .join("\n\n");

          throw new Error(errorMessage);
        }

        successfulJobs.forEach((job) => dispatch(createdDataset(job.response)));
      } else {
        const response = await createDataset(orgId, data, token, signal);
        dispatch(createdDataset(response));
      }

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

export default [
  loadOrganisationsLogic,
  loadOrganisationLogic,
  loadOrganisationUsersLogic,
  loadOrganisationPropertyGroupsLogic,
  loadOrganisationWorkflowsLogic,
  loadOrganisationRolesLogic,
  loadOrganisationPropertiesLogic,
  loadOrgArchivedPropertiesLogic,
  loadOrganisationPropertyWorkflowsLogic,
  loadOrganisationPropertyPropertyGroupsLogic,
  updateOrganisationLogic,
  loadOrganisationUsersRolesLogic,
  createOrganisationLogic,
  uploadOrgLogoLogic,
  removeOrganisationLogic,
  loadOrganisationInvitedUsersLogic,
  loadOrganisationAcceptedUsersLogic,
  loadOrganisationDatasetsLogic,
  loadOrganisationRenderersLogic,
  loadOrganisationRendererLogic,
  createRendererLogic,
  updateRendererLogic,
  updatedRendererLogic,
  createDatasetLogic
];
