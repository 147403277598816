import React, { Component } from "react";
import {
  Container,
  Wrapper,
  FormInput,
  FormLabel,
  Checkbox,
  CheckboxV2,
  Fieldset
} from "@core";
import { P } from "@typography";
import {
  USERS_URL,
  STYLE_TYPE_EMAIL,
  STYLETYPE_ERROR,
  STYLETYPE_FORM_FIELD,
  CONTENT_VIEW,
  STYLETYPE_DATE,
  STYLETYPE_CHECKBOX_CONTAINER,
  CONTEXT_HEADER_CONTAINER,
  ALWAYS,
  NEVER,
  OPTIONAL,
  INVITES_TEXT,
  STATUS_READY,
  SEARCH_TIMEOUT,
  STATUS_RESENDING,
  STYLETYPE_FORM_FIELD_FLEX,
  MINIMUM_SEARCH_STRING_LENGTH,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  DATA_TABLE_PAGINATION_STYLING,
  DATA_TABLE_FILTER_INPUT_STYLING,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL
} from "@base/constants";
import {
  Loader,
  RadioGroup,
  ContextHeader,
  FilterInput,
  PaginationV2,
  BottomButtonBar,
  Dropdown
} from "@UIKit";
import { navigate } from "@reach/router";
import moment from "moment";
import { getSession, storeSession } from "@base/common";

class UpdateUserInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      expiryDate: "",
      roleId: "",
      sendEmailInvite: null,
      inviteDisabledValue: null,
      propertyIds: null,
      groupIds: null,
      propertiesFilterText: "",
      propGroupsFilterText: "",
      searchTimeout: null,
      groupPropIds: []
    };

    this.emailTimer = null;
    this.controller = new AbortController();
  }

  componentDidMount() {
    this.loadUserInviteDetails();
  }

  loadUserInviteDetails = () => {
    const {
      orgId,
      inviteId,
      loadInvitedUser,
      loadOrganisation,
      loadOrganisationRoles,
      setOrgPropertiesPagination,
      loadOrganisationProperties,
      loadOrganisationPropertyGroups
    } = this.props;
    const pageLimit = this.getPageLimit();
    loadInvitedUser(orgId, inviteId);
    loadOrganisation(orgId);
    loadOrganisationRoles(orgId);
    setOrgPropertiesPagination(1, pageLimit, 0, "");
    loadOrganisationProperties(orgId);
    loadOrganisationPropertyGroups(orgId);
  };

  componentWillUnmount() {
    this.abortRequests();
  }

  componentDidUpdate(prevProps) {
    const {
      userInviteDetailsLoaded,
      selectedInvitedUser,
      selectedInvitedUserStatus
    } = this.props;

    if (
      prevProps.userInviteDetailsLoaded !== userInviteDetailsLoaded &&
      selectedInvitedUser
    ) {
      this.setInitialDetailsValues();
    }

    if (
      prevProps.selectedInvitedUserStatus === STATUS_RESENDING &&
      selectedInvitedUserStatus === STATUS_READY
    ) {
      this.showResendInviteSuccess();
    }
  }

  showResendInviteSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("INVITE_SENT_LABEL", {
        item: getLabel("INVITE_LABEL")
      }),
      body: getLabel("INVITE_SENT_LABEL", {
        item: getLabel("INVITE_LABEL")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_DETAILS", {
            item: getLabel("INVITED_USER_LABEL", { item: "" })
          }),
          onClick: this.returnToUserDetailAction
        }
      ]
    });
    return null;
  };

  setInitialDetailsValues = () => {
    let sendEmailInvite, inviteDisabledValue;
    const sendEmailOptions = [ALWAYS, NEVER, OPTIONAL];
    const { selectedOrganisation, selectedInvitedUser } = this.props;
    const { sendVerificationEmails } = selectedOrganisation.preferences;
    const { email, firstName, lastName, membershipData } = selectedInvitedUser;
    const { roleId, expiryDate, properties, propertyGroups } = membershipData;

    const propertyIds = properties.map((item) => item.propId);
    const groupIds = propertyGroups.map((item) => item.groupId);

    if (sendVerificationEmails === ALWAYS) {
      sendEmailInvite = true;
      inviteDisabledValue = true;
    } else if (sendVerificationEmails === NEVER) {
      sendEmailInvite = false;
      inviteDisabledValue = true;
    } else if (
      sendVerificationEmails === OPTIONAL ||
      sendVerificationEmails === undefined ||
      !sendEmailOptions.includes(sendVerificationEmails)
    ) {
      sendEmailInvite = true;
      inviteDisabledValue = false;
    }
    const formattedDate =
      expiryDate && expiryDate !== ""
        ? moment(expiryDate).format("YYYY-MM-DD")
        : "";

    this.setState({
      email,
      roleId,
      firstName,
      lastName,
      propertyIds,
      groupIds,
      expiryDate: formattedDate,
      sendEmailInvite,
      inviteDisabledValue
    });
  };

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  valueIsEmpty = (field) => {
    const value = this.getValue(field);
    return (
      value === null ||
      value === undefined ||
      value === "" ||
      (value && !value.length)
    );
  };

  setSendEmailInvite = () => {
    this.setState({
      sendEmailInvite: !this.state.sendEmailInvite
    });
  };

  renderSendInviteCheckbox = () => {
    const { getLabel } = this.props;
    const { sendEmailInvite, inviteDisabledValue } = this.state;
    return (
      <CheckboxV2
        handleUpdate={this.setSendEmailInvite}
        name={"sendEmailInvite"}
        value={sendEmailInvite}
        checked={sendEmailInvite}
        disabled={inviteDisabledValue}
        title={getLabel("SEND_EMAIL_INVITE_LABEL")}
      />
    );
  };

  greaterExpiryDate = () => {
    const expiryDate = this.getValue("expiryDate");
    const todaysDate = new Date().toISOString().slice(0, 10);
    return expiryDate !== todaysDate && expiryDate > todaysDate;
  };

  getSelectedOrgRoles = () => {
    const { selectedOrganisationRoles } = this.props;
    return selectedOrganisationRoles && selectedOrganisationRoles.length > 0
      ? selectedOrganisationRoles
      : false;
  };

  renderRolesRadios = () => {
    const { getLabel } = this.props;
    const availableRoles = this.getSelectedOrgRoles();
    if (availableRoles) {
      const currentRoleId = this.getValue("roleId");
      const roles = availableRoles.map(({ roleId, title }) => {
        return {
          label: title,
          value: roleId,
          checked: roleId === currentRoleId
        };
      });

      return (
        <RadioGroup
          legend={getLabel("SELECT_HEADER", {
            item: getLabel("ROLE_TEXT")
          })}
          styleType={this.valueIsEmpty("roleId") ? STYLETYPE_ERROR : ""}
          value={currentRoleId}
          isDisabled={false}
          name={"addUserRoleForm"}
          radioButtons={roles}
          onClick={(event) => {
            this.setValue(event, "roleId");
          }}
          validationMessage={getLabel("VALIDATION_ERROR_PLEASE_SELECT_ROLE")}
          required={true}
        />
      );
    }
  };

  handleProperties = (property) => {
    const { groupIds } = this.state;
    const { selectedOrgPropertyGroups } = this.props;
    const currentSelectedGroups = selectedOrgPropertyGroups.filter((group) =>
      groupIds.includes(group.groupId)
    );
    const propertiesInUserGroup =
      currentSelectedGroups &&
      currentSelectedGroups.map((group) => {
        return group.properties;
      });

    const flat = [].concat(...propertiesInUserGroup);
    if (flat.includes(property.propId)) return true;
  };

  getPropertyIdOptions = () => {
    const { propertyIds } = this.state;
    const { getLabel, selectedOrganisationProperties } = this.props;
    if (!selectedOrganisationProperties || propertyIds === null) return [];

    const propertyIdOptions = selectedOrganisationProperties.map((property) => {
      return {
        ...property,
        title: `${property.officialName} - ${property.title}`,
        value: property.propId,
        checked: this.handleProperties(property)
          ? this.handleProperties(property)
          : propertyIds.indexOf(property.propId) !== -1,
        disabled: this.handleProperties(property)
      };
    });
    if (propertyIdOptions.length === 0) {
      return [
        {
          title: getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
            filterText: this.getPropertiesFilterText()
          })
        }
      ];
    }
    return propertyIdOptions;
  };

  setPropertyIdValues = (propertyIds) => {
    this.setState({ propertyIds });
  };

  getPropertyIdValues = () => {
    const { propertyIds } = this.state;
    return propertyIds;
  };

  setPropertyGroupIdValues = (groupIds) => {
    this.setState({ groupIds }, () => {
      this.handleGroup(groupIds);
    });
  };

  handleGroup = (groupIds) => {
    const { selectedOrgPropertyGroups } = this.props;

    const selectedGroup = selectedOrgPropertyGroups
      .filter((r) => groupIds.includes(r.groupId))
      .map((r) => r.properties);

    const groupPropIds = [...new Set([].concat(...selectedGroup))];
    this.setState({ groupPropIds });
  };

  getPropertyGroupIdOptions = () => {
    const { groupIds } = this.state;
    const { getLabel, selectedOrgPropertyGroups } = this.props;
    if (!selectedOrgPropertyGroups || groupIds === null) return [];
    const filterText = this.getPropGroupsFilterText().toLowerCase();
    const filteredPropertyGroups = this.filterList(
      selectedOrgPropertyGroups,
      filterText
    );
    const groupIdOptions = filteredPropertyGroups.map((group) => {
      return {
        ...group,
        title: group.title,
        value: group.groupId,
        checked: groupIds.indexOf(group.groupId) !== -1
      };
    });
    if (groupIdOptions.length === 0) {
      return [
        {
          title: getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
            filterText: this.getPropGroupsFilterText()
          })
        }
      ];
    }
    return groupIdOptions;
  };

  getPropertyGroupIdValues = () => {
    const { groupIds } = this.state;
    return groupIds;
  };

  getPropertiesFilterText = () => {
    const { propertiesFilterText } = this.state;
    return propertiesFilterText;
  };

  getPropGroupsFilterText = () => {
    const { propGroupsFilterText } = this.state;
    return propGroupsFilterText;
  };

  getPropertiesFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("PROPERTIES_TEXT")
    });
  };

  getPropertyGroupsFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("PROPERTY_GROUPS_TEXT")
    });
  };

  filterList = (list, filterText) => {
    return list.filter((listItem) => {
      return (
        listItem.title && listItem.title.toLowerCase().indexOf(filterText) > -1
      );
    });
  };

  getOrgPropertiesTotal = () => {
    const { selectedOrgPropertiesTotal } = this.props;
    return selectedOrgPropertiesTotal;
  };

  showPagination = () => {
    return this.getOrgPropertiesTotal() > this.getPageLimit();
  };

  getCurrentPageNumber = () => {
    const { selectedOrgPropertiesPageNumber } = this.props;
    return selectedOrgPropertiesPageNumber;
  };

  setPaginationContent = (value) => {
    const { orgId, setOrgPropertiesPagination, loadOrganisationProperties } =
      this.props;
    const searchValue = this.getFilterText();
    const pageLimit = this.getPageLimit();
    let offset = (value - 1) * pageLimit;
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(value, 10, offset, searchValue);
  };

  setFilterText = (value) => {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearInterval(searchTimeout);
    }

    this.setState(
      {
        propertiesFilterText: value
      },
      () => {
        if (value.length >= MINIMUM_SEARCH_STRING_LENGTH || value === "") {
          const searchTimeout = setTimeout(() => {
            this.setPaginationContent(1);
          }, SEARCH_TIMEOUT);
          this.setState({ searchTimeout });
        }
      }
    );
  };

  getFilterText = () => {
    const { propertiesFilterText } = this.state;
    return propertiesFilterText;
  };

  isPropertiesLoading = () => {
    const { selectedOrganisationPropertiesLoading } = this.props;
    return selectedOrganisationPropertiesLoading;
  };

  isUserInviteLoading = () => {
    return !this.props.userInviteDetailsLoaded;
  };

  isInvalid = () => {
    return (
      this.valueIsEmpty("firstName") ||
      this.valueIsEmpty("lastName") ||
      (!this.valueIsEmpty("expiryDate") && !this.greaterExpiryDate())
    );
  };

  returnToUserDetailAction = () => {
    const { orgId, inviteId, hideModal } = this.props;
    hideModal();
    navigate(
      `/${ORGANISATION_URL}/${orgId}/${USERS_URL}/${INVITES_TEXT}/${inviteId}`
    );
  };

  getUserMembershipInfo = () => {
    const {
      roleId,
      expiryDate,
      firstName,
      lastName,
      groupIds,
      groupPropIds,
      propertyIds
    } = this.state;
    const {
      selectedInvitedUser: {
        membershipData: { properties, propertyGroups }
      }
    } = this.props;
    const addPropertyGroups = groupIds.map((item) => {
      return { groupId: item, roleId: roleId };
    });
    const removePropertyGroups = propertyGroups.reduce(
      (result, propertyGroup) =>
        groupIds.indexOf(propertyGroup.groupId) === -1
          ? [...result, propertyGroup.groupId]
          : result,
      []
    );

    const propertiesToAdd = propertyIds.filter(
      (el) => !groupPropIds.includes(el)
    );

    const addProperties = propertiesToAdd.map((item) => {
      return { propId: item, roleId: roleId };
    });

    const removeGroupProperties = propertyIds.filter((el) =>
      groupPropIds.includes(el)
    );

    const propertiesToRemove = properties.reduce(
      (result, properties) =>
        propertyIds.indexOf(properties.propId) === -1
          ? [...result, properties.propId]
          : result,
      []
    );

    const removeProperties = [...removeGroupProperties, ...propertiesToRemove];

    return {
      roleId,
      expiryDate,
      firstName,
      lastName,
      addPropertyGroups:
        addPropertyGroups.length !== 0 ? addPropertyGroups : "",
      removePropertyGroups:
        removePropertyGroups.length !== 0 ? removePropertyGroups : "",
      addProperties: addProperties.length !== 0 ? addProperties : "",
      removeProperties: removeProperties.length !== 0 ? removeProperties : ""
    };
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("RESENDING_LABEL"),
        item: getLabel("INVITE_LABEL")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  callAction = async () => {
    try {
      const { orgId, inviteId, showModal, resendInvite } = this.props;
      const { sendEmailInvite } = this.state;
      const data = this.getUserMembershipInfo();
      this.showLoading();
      showModal();

      await resendInvite(orgId, inviteId, sendEmailInvite, data);
    } catch (e) {
      throw new Error(e);
    }
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: "primary",
        isDisabled: this.isInvalid(),
        onClick: this.callAction,
        buttonLabel: getLabel("UPDATE_AND_RESEND_INVITE_LABEL")
      },
      {
        buttonStyleType: "secondary",
        buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
        onClick: this.returnToUserDetailAction
      }
    ];
  };

  getPageLimit = () => {
    return getSession(`properties-${PAGINATION_KEY}`) || PAGINATION_OPTIONS[0];
  };

  dropDownOnSelectFunction = (dropdown) => {
    this.setState({ currentPageNumber: 1 });
    const { orgId, loadOrganisationProperties, setOrgPropertiesPagination } =
      this.props;
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(0, dropdown.value, 0, "");
    storeSession(`properties-${PAGINATION_KEY}`, dropdown.value);
  };

  showDropdown = () => {
    return this.getOrgPropertiesTotal() > this.getDropDownOptions()[0].value;
  };

  getDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit()
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  render() {
    const { getLabel } = this.props;
    const { expiryDate } = this.state;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isUserInviteLoading() ? (
          <Loader
            fullpage={false}
            loadingText={getLabel("GENERIC_ACTION_MESSAGE", {
              item: getLabel("USER_TEXT"),
              action: getLabel("LOADING_LABEL")
            })}
          />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={getLabel("UPDATE_USER_INVITE_LABEL")}
                contextMenu={{ visible: false }}
              />
            </Container>

            <Container>
              <Container
                styleType={STYLETYPE_FORM_FIELD_FLEX}
                direction={"row"}
              >
                <Container>
                  <Container>{this.renderSendInviteCheckbox()}</Container>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel htmlFor="email">
                      {getLabel("FORM_EMAIL_LABEL")}
                    </FormLabel>
                    <FormInput
                      readOnly
                      value={this.getValue("email")}
                      id="email"
                      required={true}
                      type={STYLE_TYPE_EMAIL}
                    />
                  </Container>

                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      styleType={
                        this.valueIsEmpty("firstName") ? STYLETYPE_ERROR : ""
                      }
                      htmlFor="first-name"
                    >
                      {getLabel("FORM_FIRST_NAME_LABEL")}
                    </FormLabel>
                    <FormInput
                      value={this.getValue("firstName")}
                      onChange={(value) => {
                        this.setValue(value, "firstName");
                      }}
                      styleType={
                        this.valueIsEmpty("firstName") ? STYLETYPE_ERROR : ""
                      }
                      required={true}
                      ariaDescribedBy={
                        this.valueIsEmpty("firstName")
                          ? "firstname-error"
                          : null
                      }
                      id="first-name"
                      aria-invalid={this.valueIsEmpty("firstName")}
                    />
                    {this.valueIsEmpty("firstName") && (
                      <P styleType={"error"} id="firstname-error">
                        {getLabel("FORM_FIELD_EMPTY_LABEL")}
                      </P>
                    )}
                  </Container>

                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      styleType={
                        this.valueIsEmpty("lastName") ? STYLETYPE_ERROR : ""
                      }
                      htmlFor="last-name"
                    >
                      {getLabel("FORM_LAST_NAME_LABEL")}
                    </FormLabel>
                    <FormInput
                      value={this.getValue("lastName")}
                      onChange={(value) => {
                        this.setValue(value, "lastName");
                      }}
                      styleType={
                        this.valueIsEmpty("lastName") ? STYLETYPE_ERROR : ""
                      }
                      required={true}
                      ariaDescribedBy={
                        this.valueIsEmpty("lastName") ? "lastname-error" : null
                      }
                      id="last-name"
                      aria-invalid={this.valueIsEmpty("lastName")}
                    />
                    {this.valueIsEmpty("lastName") && (
                      <P styleType={"error"} id="lastname-error">
                        {getLabel("FORM_FIELD_EMPTY_LABEL")}
                      </P>
                    )}
                  </Container>

                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      styleType={
                        expiryDate !== "" && !this.greaterExpiryDate()
                          ? STYLETYPE_ERROR
                          : ""
                      }
                      htmlFor="expiry-date"
                    >
                      {getLabel("EXPIRY_DATE_LABEL")}
                    </FormLabel>
                    <FormInput
                      value={this.getValue("expiryDate")}
                      onChange={(value) => {
                        this.setValue(value, "expiryDate");
                      }}
                      styleType={
                        !this.valueIsEmpty("expiryDate") &&
                        !this.greaterExpiryDate()
                          ? STYLETYPE_ERROR
                          : ""
                      }
                      type={STYLETYPE_DATE}
                      id="expiry-date"
                      aria-invalid={
                        !this.valueIsEmpty("expiryDate") &&
                        !this.greaterExpiryDate()
                      }
                      ariaDescribedBy={
                        !this.valueIsEmpty("expiryDate") &&
                        !this.greaterExpiryDate() &&
                        "expiryDate-error"
                      }
                    />
                    {!this.valueIsEmpty("expiryDate") &&
                      !this.greaterExpiryDate() && (
                        <P styleType={"error"} id="expiryDate-error">
                          {getLabel("EXPIRY_DATE_ERROR_LABEL")}
                        </P>
                      )}
                  </Container>

                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <Fieldset>{this.renderRolesRadios()}</Fieldset>
                  </Container>
                </Container>
              </Container>
              <Container>
                <FilterInput
                  inputId={`${this.getPropertyGroupsFilterLabel()}-input`}
                  label={this.getPropertyGroupsFilterLabel()}
                  liveFilter={true}
                  placeholder={"e.g: Group 1"}
                  filterFunction={(event) => {
                    this.setValue(event, "propGroupsFilterText");
                  }}
                  styleType={DATA_TABLE_FILTER_INPUT_STYLING}
                />
                <Container styleType={STYLETYPE_CHECKBOX_CONTAINER}>
                  <Checkbox
                    handleUpdateValue={this.setPropertyGroupIdValues}
                    options={this.getPropertyGroupIdOptions()}
                    value={this.getPropertyGroupIdValues()}
                    name={"groupIds"}
                    legend={getLabel("SELECT_HEADER", {
                      item: getLabel("PROPERTY_GROUPS_TEXT")
                    })}
                  />
                </Container>
                <FilterInput
                  inputId={`${this.getPropertiesFilterLabel()}-input`}
                  label={this.getPropertiesFilterLabel()}
                  liveFilter={true}
                  placeholder={"e.g: Orchard 1"}
                  filterFunction={(event) => {
                    this.setFilterText(event);
                  }}
                  styleType={DATA_TABLE_FILTER_INPUT_STYLING}
                />
                {this.isPropertiesLoading() ? (
                  <Loader fullPage={false} />
                ) : (
                  <Container styleType={STYLETYPE_CHECKBOX_CONTAINER}>
                    <Checkbox
                      handleUpdateValue={this.setPropertyIdValues}
                      options={this.getPropertyIdOptions()}
                      value={this.getPropertyIdValues()}
                      name={"propertyIds"}
                      legend={getLabel("SELECT_HEADER", {
                        item: getLabel("PROPERTIES_TEXT")
                      })}
                    />
                  </Container>
                )}
                <Container
                  direction={"row"}
                  styleType={DATA_TABLE_PAGINATION_STYLING}
                >
                  {this.showDropdown() && (
                    <Dropdown
                      id={"update-user-invite"}
                      placeholder={"Select option"}
                      onClick={this.dropDownOnSelectFunction}
                      label={this.getDropDownLabel()}
                      value={this.getPageLimit()}
                      options={this.getDropDownOptions()}
                    />
                  )}
                  {this.showPagination() && (
                    <PaginationV2
                      currentPageNumber={this.getCurrentPageNumber()}
                      listPerPageNum={this.getPageLimit()}
                      totalItems={this.getOrgPropertiesTotal()}
                      setPaginationContent={this.setPaginationContent}
                      showPaginationInput={true}
                    />
                  )}
                </Container>
              </Container>
              <BottomButtonBar buttons={this.getButtons()} />
            </Container>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default UpdateUserInvite;
