import React from "react";
import styled, { css } from "styled-components";
import { Link } from "@reach/router";
import {
  BACK_BUTTON,
  BUTTON_TYPE_PRIMARY,
  LOGIN_BUTTON,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  BUTTON_TYPE_LIST_CONTENT_CHILD
} from "@base/constants";
import { ImageElement } from "../Image/style";

export const ButtonLabel = styled.span`
  width: 100%;
  color: ${(props) => props.theme.agCommonBlack};
  text-align: center;
  font-size: ${(props) => props.theme.fontSizeTwo};
  font-weight: ${(props) => props.theme.baseFontWeight};
  padding: 0.6rem;
  ${(props) =>
    props.styleType === LOGIN_BUTTON &&
    css`
      text-transform: uppercase;
      color: ${(props) => props.theme.agWhite};
      font-size: ${(props) => props.theme.fontSizeBase};
      font-weight: ${props.theme.baseFontWeightHeavy};
    `}
  ${(props) =>
    props.styleType === DATA_TABLE_BUTTON_TITLE_STYLING &&
    css`
      text-align: left;
    `}
    
  ${(props) =>
    props.styleType === BUTTON_TYPE_LIST_CONTENT_CHILD &&
    css`
      font-size: 0.8rem;
    `}
`;
ButtonLabel.displayName = "ButtonLabel";

export const ButtonElement = styled((props) => <Link {...props} />)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0.8rem;
  color: ${(props) => props.theme.agCommonBlack};
  background: ${(props) => `1px solid  ${props.theme.agWhite}`};
  border: none;
  pointer-events: ${(props) => (props.disabled ? "none" : "inherit")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  ${(props) =>
    props.styleType === BACK_BUTTON &&
    css`
      height: 3rem;
      border: 1px solid ${props.theme.adminTeritary};
    `}
  ${(props) =>
    props.styleType === BUTTON_TYPE_PRIMARY &&
    css`
      background: ${props.theme.agWhite};
      border: 1px solid ${props.theme.adminTeritary};
    `}
  ${(props) =>
    props.styleType === LOGIN_BUTTON &&
    css`
      background-color: ${(props) => props.theme.agBlue};
      border-radius: 0.375rem;
      width: 100%;
      box-sizing: border-box;
    `}

    ${(props) =>
    props.styleType === DATA_TABLE_BUTTON_TITLE_STYLING &&
    css`
      padding: 0;

      :focus {
        outline: none;
      }

      ${ButtonLabel} {
        font-size: calc(1rem + 0.3vw);
        padding: 0;
      }
    `}
`;
ButtonElement.displayName = "ButtonElement";

export const ButtonImageWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  justify-content: center;
  margin-right: 1em;

  ${ImageElement} {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;
ButtonImageWrapper.displayName = "ButtonImageWrapper";
