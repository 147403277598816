import React from "react";
import PropTypes from "prop-types";
import { Button } from "@core";
import { MODAL_ID } from "@base/constants";

const ModalCloseButton = (props) => {
  return (
    <Button
      buttonStyleType={MODAL_ID}
      isDisabled={false}
      onClick={props.onCloseClick}
      icon={{
        type: "close",
        bgWidth: "30px",
        bgHeight: "30px",
        iconHeight: "20px",
        iconWidth: "20px",
        iconColor: "black"
      }}
    />
  );
};

ModalCloseButton.propTypes = {
  /** The function to run when the user clicks the close button in the top right */
  onCloseClick: PropTypes.func
};

export default ModalCloseButton;
