import React from "react";
import { defaultTheme } from "../../../defaultTheme";
import { ComboboxMultiItemWrapper, ComboboxMultiItemText } from "../style";
import { Button } from "@core";
import PropTypes from "prop-types";

const ComboboxMultiValue = ({ value, label, onClear }) => {
  return (
    <ComboboxMultiItemWrapper>
      <ComboboxMultiItemText>{label}</ComboboxMultiItemText>
      <Button
        buttonStyleType={"close"}
        isDisabled={false}
        onClick={() => onClear(value)}
        ariaLabel={`Remove ${label}`}
        icon={{
          type: "close",
          bgWidth: "1.2em",
          bgHeight: "1.2em",
          iconHeight: "1em",
          iconWidth: "1em",
          iconColor: defaultTheme.agBlack
        }}
      />
    </ComboboxMultiItemWrapper>
  );
};

ComboboxMultiValue.propTypes = {
  /** The value of the selected item */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  /** The display label for the selected item */
  label: PropTypes.string,
  /** Called on click of the clear button for the item */
  onClear: PropTypes.func
};

export default ComboboxMultiValue;
