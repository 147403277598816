import React, { Component } from "react";
import { Loader } from "@UIKit";
import { Container, Wrapper } from "@core";
import {
  LOGIN_VIEW,
  LANDKIND_LOGO_ALT_TEXT,
  LANDKIND_LOGO_SVG
} from "@base/constants";

class Login extends Component {
  componentDidMount() {
    const { authenticateUser } = this.props;
    authenticateUser();
  }

  getLoadingText = () => {
    const { loadingText, getLabel } = this.props;
    const { LOADING_LABEL } = getLabel();
    return loadingText ? loadingText : LOADING_LABEL;
  };

  render() {
    return (
      <Container styleType={LOGIN_VIEW}>
        <Wrapper styleType={LOGIN_VIEW}>
          <Loader
            fullPage={true}
            loadingText={this.getLoadingText()}
            image={{
              altText: LANDKIND_LOGO_ALT_TEXT,
              source: LANDKIND_LOGO_SVG,
              styleType: "Loading-screen-loader"
            }}
          />
        </Wrapper>
      </Container>
    );
  }
}

export default Login;
