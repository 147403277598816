import { handleActions } from "redux-actions";
import {
  LOAD_PROPERTY_GROUP,
  LOADED_PROPERTY_GROUP,
  LOAD_PROPERTY_GROUP_USERS,
  LOADED_PROPERTY_GROUP_USERS,
  LOAD_PROPERTY_GROUP_WORKFLOWS,
  LOADED_PROPERTY_GROUP_WORKFLOWS,
  LOAD_PROPERTY_GROUP_PROPERTIES,
  LOADED_PROPERTY_GROUP_PROPERTIES,
  UPDATED_PROPERTY_GROUP,
  STATUS_READY,
  STATUS_CREATING,
  CREATE_PROPERTY_GROUP,
  UPDATE_PROPERTY_GROUP,
  STATUS_UPDATING,
  STATUS_LOADING,
  STATUS_REMOVING,
  REMOVE_PROPERTY_GROUP,
  REMOVED_PROPERTY_GROUP,
  SET_GROUP_USERS_PAGINATION,
  ADD_PROPERTIES_TO_GROUPS
} from "@base/constants";

export const defaultState = {
  status: STATUS_READY,
  selectedPropertyGroup: null,
  selectedPropertyGroupProperties: {
    available: [],
    count: 0,
    isLoading: false,
    pagination: {
      page: 1,
      limit: 10,
      offset: 0
    }
  },
  selectedPropertyGroupWorkflows: {
    available: [],
    count: 0,
    isLoading: false
  },
  selectedPropertyGroupUsers: {
    available: [],
    count: 0,
    isLoading: false
  },
  selectedPropertyGroupUsersPagination: {
    page: 1,
    limit: 10,
    offset: 0,
    search: ""
  }
};
// skipcq JS-0128
export default handleActions(
  {
    [LOAD_PROPERTY_GROUP]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      selectedPropertyGroup: null
    }),
    [LOADED_PROPERTY_GROUP]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      selectedPropertyGroup: payload
    }),
    [LOAD_PROPERTY_GROUP_PROPERTIES]: (state, { payload }) => ({
      ...state,
      selectedPropertyGroupProperties: {
        available: [],
        count: 0,
        isLoading: true,
        pagination: {
          page: payload.page,
          limit: payload.limit,
          offset: payload.offset
        }
      }
    }),
    [LOADED_PROPERTY_GROUP_PROPERTIES]: (state, { payload }) => ({
      ...state,
      selectedPropertyGroupProperties: {
        ...state.selectedPropertyGroupProperties,
        available: payload.properties,
        count: payload.meta.count,
        isLoading: false
      }
    }),
    [LOAD_PROPERTY_GROUP_USERS]: (state) => ({
      ...state,
      selectedPropertyGroupUsers: {
        available: [],
        count: 0,
        isLoading: true
      }
    }),
    [LOADED_PROPERTY_GROUP_USERS]: (state, { payload }) => ({
      ...state,
      selectedPropertyGroupUsers: {
        available: payload.items,
        count: payload.meta.total,
        isLoading: false
      }
    }),
    [SET_GROUP_USERS_PAGINATION]: (state, { payload }) => ({
      ...state,
      selectedPropertyGroupUsersPagination: {
        page: payload.page,
        limit: payload.limit,
        offset: payload.offset,
        search: payload.search
      }
    }),

    [LOAD_PROPERTY_GROUP_WORKFLOWS]: (state) => ({
      ...state,
      selectedPropertyGroupWorkflows: {
        available: [],
        count: 0,
        isLoading: true
      }
    }),
    [LOADED_PROPERTY_GROUP_WORKFLOWS]: (state, { payload }) => ({
      ...state,
      selectedPropertyGroupWorkflows: {
        available: payload.propertyGroupWorkflows.items,
        count: payload.propertyGroupWorkflows.meta.total,
        isLoading: false
      }
    }),
    [CREATE_PROPERTY_GROUP]: (state) => ({
      ...state,
      status: STATUS_CREATING
    }),
    [UPDATE_PROPERTY_GROUP]: (state) => ({
      ...state,
      status: STATUS_UPDATING
    }),
    [UPDATED_PROPERTY_GROUP]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [REMOVE_PROPERTY_GROUP]: (state) => ({
      ...state,
      status: STATUS_REMOVING
    }),
    [REMOVED_PROPERTY_GROUP]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [ADD_PROPERTIES_TO_GROUPS]: (state) => ({
      ...state,
      status: STATUS_UPDATING
    })
  },
  defaultState
);
