export default (API_URL, API_KEY) => ({
  partiallyUpdateWorkflow: (orgId, workflowId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/workflows/${workflowId}/organisations/${orgId}/admin?useCache=true`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          method: "PATCH",
          body: data,
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  partiallyUpdateMembership: (orgId, userId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/users/${userId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "PATCH",
        body: data,
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  partiallyUpdateUser: (userId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/users/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "PATCH",
        body: data,
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  partiallyUpdateOrganisation: (orgId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "PATCH",
        body: data,
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  updateRole: (orgId, roleId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/roles/${roleId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "PATCH",
        body: data,
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  updateRoleAdmin: (orgId, roleId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/roles/${roleId}/organisations/${orgId}/admin`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "PATCH",
        body: data,
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  updatePropertyGroup: (data, groupId, orgId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/propertyGroups/${groupId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "PATCH",
        body: data,
        redirect: "follow",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  updatePropertyDataSetsLayer: (orgId, propId, roleId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/dataSets/Property/organisations/${orgId}/properties/${propId}/roles/${roleId}/FeatureServer/0/applyEdits`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          method: "POST",
          body: data,
          redirect: "follow",
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  uploadLogoToOrganisation: (url, data, signal) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        headers: {
          "x-amz-server-side-encryption": `AES256`
        },
        method: "POST",
        body: data,
        redirect: "follow",
        signal
      })
        .then((result) => {
          if (!result.ok) reject(new Error(result.statusText));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  restoreProperty: (orgId, propId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/properties/${propId}/organisations/${orgId}/restore`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        body: data,
        method: "PATCH",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  partiallyUpdateRenderer: (orgId, rendererId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/${orgId}/renderers/${rendererId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "PATCH",
        body: data,
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  updatePropertyData: (orgId, propId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/properties/${propId}/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "PATCH",
        body: data,
        redirect: "follow",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  }
});
