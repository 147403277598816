import { createLogic } from "redux-logic";
import createAuth0Client from "@auth0/auth0-spa-js";

import {
  AUTHENTICATE_USER,
  CHECK_AUTHZERO_AUTHENTICATION,
  INVALIDATE_AUTHENTICATION,
  AUTHZERO_USERID_CLAIM,
  LOAD_USER_LOGIN,
  LOADED_USER_LOGIN,
  DEFAULT_LANGUAGE,
  ABORT_ERROR_NAME
} from "@base/constants";
import { navigate } from "@reach/router";

const authenticateUserLogic = createLogic({
  type: AUTHENTICATE_USER,
  process({ globalActions, selectors, getState }, dispatch, done) {
    const { checkAuthzeroAuthentication, setLoadingText } = globalActions;
    const { getLabels } = selectors;
    const { CHECKING_LOGIN_STATUS_TEXT } = getLabels(getState());
    dispatch(setLoadingText(CHECKING_LOGIN_STATUS_TEXT));
    dispatch(checkAuthzeroAuthentication());
    done();
  }
});

const checkAuthZeroAuthenticationLogic = createLogic({
  type: CHECK_AUTHZERO_AUTHENTICATION,
  async process({ globalActions, selectors, getState }, dispatch, done) {
    const sessionPathKey = "windowPath";
    if (!window.location.search.includes("code=")) {
      sessionStorage.setItem(sessionPathKey, window.location.pathname);
    }
    try {
      let auth0ClientParams = {
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        redirect_uri: window.location.origin
      };

      let auth0Client = await createAuth0Client(auth0ClientParams);

      const { setAuthzeroClient } = globalActions;
      dispatch(setAuthzeroClient(auth0Client));

      const storedUrl = sessionStorage.getItem(sessionPathKey);

      if (window.location.search.includes("code=")) {
        const redirectPath = storedUrl ? storedUrl : window.location.pathname;
        await auth0Client.handleRedirectCallback();
        window.history.replaceState({}, document.title, redirectPath);

        navigate(redirectPath);
      }

      const isAuthenticated = await auth0Client.isAuthenticated();
      if (!isAuthenticated) auth0Client.loginWithRedirect({});

      const user = await auth0Client.getUser();
      const userId = user[AUTHZERO_USERID_CLAIM];
      const claims = await auth0Client.getIdTokenClaims();
      const token = claims.__raw;

      const { getLabels } = selectors;
      const { SUCCESSFUL_LOGIN_TEXT } = getLabels(getState());
      const { successfulAuthentication, setLoadingText, loadUserLogin } =
        globalActions;
      dispatch(setLoadingText(SUCCESSFUL_LOGIN_TEXT));
      dispatch(
        successfulAuthentication({
          userId,
          token
        })
      );
      dispatch(loadUserLogin(userId));
      sessionStorage.removeItem(sessionPathKey);
      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      const { errorAuthentication } = globalActions;
      dispatch(errorAuthentication(e));
      done();
    }
  }
});

const invalidateAuthenticationLogic = createLogic({
  type: INVALIDATE_AUTHENTICATION,
  async process({ globalActions, selectors, getState }, dispatch, done) {
    const state = getState();

    try {
      const { getAuthzeroClient } = selectors;
      const { unloadOrganisations, clearAuthentication } = globalActions;
      dispatch(unloadOrganisations());
      const authClient = getAuthzeroClient(state);
      const logoutOptions = {
        returnTo: `${window.location.origin}/logout`
      };
      await authClient.logout(logoutOptions);
      dispatch(clearAuthentication());
      done();
    } catch (e) {
      console.log(e);
      done();
    }
  }
});

const loadUserLoginLogic = createLogic({
  type: LOAD_USER_LOGIN,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getUserLoginId, getAbortSignal, getLabels } = selectors;
      const { setLoadingText } = globalActions;
      const { LOADING_USER_TEXT } = getLabels(getState());
      dispatch(setLoadingText(LOADING_USER_TEXT));
      const token = getToken(state);
      const userId = getUserLoginId(state);

      const { requestUser } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const user = await requestUser(userId, token, signal);
      const { loadedUserLogin, loadLanguage } = globalActions;
      const { language, languageFileAdmin } = user;
      if (language && language !== DEFAULT_LANGUAGE && languageFileAdmin) {
        dispatch(loadLanguage(language, languageFileAdmin));
      }
      dispatch(loadedUserLogin(user));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadedUserLoginLogic = createLogic({
  type: LOADED_USER_LOGIN,
  async process({ globalActions }, dispatch, done) {
    try {
      const { loadOrganisations } = globalActions;
      dispatch(loadOrganisations());
      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

export default [
  authenticateUserLogic,
  checkAuthZeroAuthenticationLogic,
  invalidateAuthenticationLogic,
  loadUserLoginLogic,
  loadedUserLoginLogic
];
