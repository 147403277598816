import React, { Component } from "react";
import { defaultTheme, ContextHeader, Loader, DataTable } from "@UIKit";
import { Container, Wrapper, Section, LinkButton } from "@core";
import { navigate } from "@reach/router";
import { Heading3 } from "@typography";
import {
  PROPERTIES_URL,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  STATUS_LOADING,
  STATUS_RESTORING,
  STATUS_READY,
  ARCHIVE_PERMISSION,
  PROPERTIES_SECTION,
  PROPERTY_GROUPS_SECTION,
  READ_PERMISSION,
  SECTION_HEADER_STYLING,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL
} from "@base/constants";
import { getSession, storeSession, filterList } from "@base/common";

class ArchivedPropertyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      pageNumber: 1
    };
    this.controller = new AbortController();
  }

  componentDidMount() {
    this.loadPropertyDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps) {
    const {
      pageTitle,
      setPageTitle,
      propertyError,
      selectedProperty,
      selectedPropertyStatus
    } = this.props;
    if (selectedProperty !== prevProps.selectedProperty) {
      const property = this.getSelectedProperty();
      if (property && property.title && pageTitle !== property.title) {
        setPageTitle(property.title);
      }
    }
    if (
      prevProps.selectedPropertyStatus === STATUS_RESTORING &&
      selectedPropertyStatus === STATUS_READY
    ) {
      this.showRestoreSuccess();
    }

    if (propertyError && propertyError !== prevProps.propertyError) {
      this.showErrorModal();
    }
  }

  loadPropertyDetails = () => {
    const { orgId, propertyId, loadArchivedProperty } = this.props;
    loadArchivedProperty(orgId, propertyId);
  };

  showErrorModal = () => {
    const { getLabel, setModalContent, showModal, propertyError } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("GENERIC_ERROR_HEADING"),
      body: propertyError,
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("PROPERTY_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    showModal();
    return null;
  };

  getSelectedProperty = () => {
    const { selectedProperty } = this.props;
    return selectedProperty;
  };

  isPropertyLoading = () => {
    const { selectedPropertyStatus } = this.props;
    return selectedPropertyStatus === STATUS_LOADING;
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return this.getSelectedProperty() && this.getSelectedProperty().title
      ? getLabel("PROPERTY_HEADING_TEXT", {
          title: this.getSelectedProperty().title
        })
      : getLabel("PROPERTY_TEXT");
  };

  getStatus = () => {
    const { getLabel } = this.props;
    return {
      label: getLabel("ARCHIVED_LABEL"),
      iconColor: defaultTheme.agRed
    };
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    const hasArchivePermission = this.getHasPermission(
      PROPERTIES_SECTION,
      ARCHIVE_PERMISSION
    );
    return {
      childLinks: hasArchivePermission
        ? [
            {
              isDisabled: false,
              onClick: this.showUnarchiveConfirmation,
              buttonLabel: getLabel("PROPERTY_ACTION_LABEL", {
                item: getLabel("UNARCHIVE_LABEL")
              })
            }
          ]
        : [],
      isDisabled: false,
      visible: hasArchivePermission
    };
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("RESTORING_LABEL"),
        item: getLabel("PROPERTY_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
  };

  unarchiveProperty = () => {
    const {
      restoreProperty,
      orgId,
      propertyId,
      selectedProperty: { propertyGroups, userMemberships }
    } = this.props;
    const data = {
      groupIds: propertyGroups.map((group) => group.groupId),
      userMemberships
    };
    restoreProperty(orgId, propertyId, JSON.stringify(data));
    this.showLoading();
  };

  showUnarchiveConfirmation = () => {
    const { showModal, setModalContent, getLabel, hideModal } = this.props;
    setModalContent({
      header: getLabel("PROPERTY_ACTION_LABEL", {
        item: getLabel("UNARCHIVE_LABEL")
      }),
      body: getLabel("UNARCHIVING_PROPERTY_TEXT"),
      buttons: [
        {
          buttonLabel: getLabel("PROPERTY_ACTION_LABEL", {
            item: getLabel("UNARCHIVE_LABEL")
          }),
          onClick: this.unarchiveProperty
        },
        {
          buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
          onClick: hideModal
        }
      ]
    });
    showModal();
  };

  returnToOverviewAction = () => {
    const { hideModal, orgId, propertyError, setPropertyError } = this.props;

    hideModal();
    if (propertyError) setPropertyError(false);
    navigate(`/${ORGANISATION_URL}/${orgId}/${PROPERTIES_URL}/`);
  };

  showRestoreSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_RESTORED_LABEL", {
        item: getLabel("PROPERTY_TEXT")
      }),
      body: getLabel("ITEM_RESTORED_SUCCESS", {
        item: getLabel("PROPERTY_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("PROPERTY_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  getSecondHeaderText = () => {
    const officialName = this.getSelectedProperty().officialName;
    return officialName ? officialName : "";
  };

  getHasPermission = (section, type) => {
    const { orgId, getHasPermission } = this.props;
    return getHasPermission(orgId, section, type);
  };

  getPropertyGroups = () => {
    const { selectedProperty } = this.props;
    if (selectedProperty && selectedProperty.propertyGroups) {
      const { propertyGroups } = selectedProperty;
      return propertyGroups
        ? filterList(propertyGroups, this.getSearchText())
        : [];
    }
    return [];
  };

  getPropertyGroupsTotal = () => {
    return this.getPropertyGroups().length;
  };

  getPropertyGroupsResultsSubtext = () => {
    const { getLabel } = this.props;
    const pageLimit = this.getPageLimit();
    const pageNumber = this.getPageNumber();
    const total = this.getPropertyGroupsTotal();
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      pageNumber === 1 ? pageNumber : (pageNumber - 1) * pageLimit + 1;

    const limitEndNumber = pageNumber * pageLimit;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;
    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  getFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("PROPERTY_GROUPS_TEXT")
    });
  };

  setSearchText = (value) => {
    this.setState({
      searchText: value,
      pageNumber: 1
    });
  };

  getSearchText = () => {
    return this.state.searchText;
  };

  getNoResultsText = () => {
    const { getLabel } = this.props;
    const filterText = this.getSearchText();
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getPropertyGroupsList = () => {
    const { pageNumber } = this.state;
    const pageLimit = this.getPageLimit();
    const propertyGroupsList = this.getPropertyGroups();
    if (propertyGroupsList.length === 0) return [];
    const lastPropertyGroup = pageNumber * pageLimit;
    const firstPropertyGroup = lastPropertyGroup - pageLimit;
    const currentPropertyGroupsList = propertyGroupsList.slice(
      firstPropertyGroup,
      lastPropertyGroup
    );
    return currentPropertyGroupsList;
  };

  getPropertyGroupsTableData = () => {
    const propertyGroupsList = this.getPropertyGroupsList();
    if (propertyGroupsList.length === 0) return [];
    return propertyGroupsList.map((group) => {
      const title = (
        <LinkButton
          url={`/`}
          isDisabled
          buttonLabel={group.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      );

      return {
        cells: [title]
      };
    });
  };

  getPageLimit = () => {
    const result = getSession(
      `archived-property-property-groups-${PAGINATION_KEY}`
    );
    return result || this.getDropDownOptions()[0].value;
  };

  showPagination = () => {
    return this.getPropertyGroupsTotal() > this.getPageLimit();
  };

  setPaginationContent = (value) => {
    this.setState({ pageNumber: value });
  };

  getPageNumber = () => {
    return this.state.pageNumber;
  };

  getPaginationContent = () => {
    return {
      setPaginationContent: this.setPaginationContent,
      activeNumber: this.getPageNumber(),
      numberOfButtons: this.getPageLimit(),
      totalResults: this.getPropertyGroupsTotal(),
      showPagination: this.showPagination(),
      paginationInput: true
    };
  };

  getDropDownLabel = (limit) => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: limit
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  showDropdown = () => {
    return this.getPropertyGroupsTotal() > this.getDropDownOptions()[0].value;
  };

  updatePropertyGroupsPerPage = (value) => {
    this.setState({ pageNumber: 1 });
    storeSession(`archived-property-property-groups-${PAGINATION_KEY}`, value);
  };

  getPageDropDownInfo = () => {
    return {
      showDropdown: this.showDropdown(),
      dropDownValue: this.getPageLimit(),
      dropDownLabel: this.getDropDownLabel(this.getPageLimit()),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: this.updatePropertyGroupsPerPage
    };
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isPropertyLoading() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={this.getContextMenu()}
                status={this.getStatus()}
              />
            </Container>
            <Section>
              <Heading3 styleType={SECTION_HEADER_STYLING}>
                {this.getSecondHeaderText()}
              </Heading3>
            </Section>
            {this.getHasPermission(
              PROPERTY_GROUPS_SECTION,
              READ_PERMISSION
            ) && (
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <DataTable
                  title={getLabel("PROPERTY_GROUPS_TEXT")}
                  noResultsText={this.getNoResultsText()}
                  showResultsText={true}
                  resultsSubtext={this.getPropertyGroupsResultsSubtext()}
                  tableFilter={{
                    filterLabel: this.getFilterLabel(),
                    showFilter: true,
                    filterFunction: this.setSearchText,
                    isDisabled: false,
                    placeholderText: getLabel(
                      "PROPERTY_GROUP_FILTER_PLACEHOLDER_LABEL"
                    ),
                    inputId: this.getFilterLabel()
                  }}
                  header={{
                    cells: [{ content: getLabel("PROPERTY_GROUPS_TEXT") }]
                  }}
                  rows={this.getPropertyGroupsTableData()}
                  pagination={this.getPaginationContent()}
                  pageDropDown={this.getPageDropDownInfo()}
                />
              </Section>
            )}
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default ArchivedPropertyDetail;
