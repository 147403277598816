import React, { Component } from "react";
import PropTypes from "prop-types";
import { ListElement, ListItemElement } from "./style";

/**
 * List component that creates a unorder list that renders children.
 */
class List extends Component {
  static propTypes = {
    /**What to display in the textArea */
    styleType: PropTypes.string,
    /**What to render in the list */
    children: PropTypes.array
  };

  /**
   * Function to set a particular style
   * @public
   */
  getStyleType = () => {
    return this.props.styleType ? this.props.styleType : "";
  };

  /**
   * Returns children to render a list with
   * @public
   */
  getChildren = () => {
    return this.props.children ? this.props.children : false;
  };

  /**
   * Renders children
   * @private
   */
  renderChildren = (children) => {
    if (!children) return null;
    return children.map((child, index) => {
      return (
        <ListItemElement key={`key-${index}-child-${child}`}>
          {child}
        </ListItemElement>
      );
    });
  };

  render() {
    return (
      <ListElement styleType={this.getStyleType()}>
        {this.renderChildren(this.getChildren())}
      </ListElement>
    );
  }
}

export default List;
