import React from "react";
import PropTypes from "prop-types";
import { Container } from "@core";
import { P } from "@typography";
import { MODAL_BODY_ID } from "@base/constants";

/**
  * Returns the bodyContent, if bodyContent is a string, cast it as a paragraph, if it isn't return it as a child component.
  Otherwise returns empty string if blank.
  * @public
  */
const getBodyContent = (bodyContent) => {
  if (bodyContent) {
    return typeof bodyContent === "string" ? (
      <P styleType={MODAL_BODY_ID}>{bodyContent}</P>
    ) : (
      bodyContent
    );
  }
  return "";
};

const ModalBody = (props) => {
  return (
    <Container data-name={"ModalBody"} styleType={MODAL_BODY_ID}>
      {getBodyContent(props.bodyContent)}
    </Container>
  );
};

ModalBody.propTypes = {
  /** The content to display in the body component */
  bodyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default ModalBody;
