import React, { Component } from "react";
import {
  Container,
  Wrapper,
  FormLabel,
  FormInput,
  Section,
  Fieldset,
  Checkbox
} from "@core";
import {
  Loader,
  ContextHeader,
  RadioGroup,
  BottomButtonBar,
  FilterInput,
  PaginationV2,
  Dropdown
} from "@UIKit";
import {
  CONTENT_VIEW,
  STYLETYPE_DATE,
  STYLETYPE_ERROR,
  STYLETYPE_FORM_FIELD,
  STATUS_READY,
  STATUS_UPDATING,
  USERS_URL,
  CONTEXT_HEADER_CONTAINER,
  STYLETYPE_CHECKBOX_CONTAINER,
  BUTTON_TYPE_PRIMARY,
  BUTTON_TYPE_SECONDARY,
  BUTTON_TYPE_PRIMARY_FULLWIDTH,
  BUTTON_TYPE_SECONDARY_FULLWIDTH,
  MINIMUM_SEARCH_STRING_LENGTH,
  SEARCH_TIMEOUT,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  DATA_TABLE_PAGINATION_STYLING,
  STATUS_LOADING,
  DATA_TABLE_FILTER_INPUT_STYLING,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL
} from "@base/constants";
import { navigate } from "@reach/router";
import moment from "moment";
import { getSession, storeSession } from "@base/common";

class UpdateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expiryDate: "",
      roleId: "",
      groupIds: null,
      groupPropIds: [],
      propertyIds: null,
      originalState: {
        expiryDate: "",
        roleId: "",
        propertyIds: null,
        groupIds: null
      },
      propertiesFilterText: "",
      searchTimeout: null
    };
  }

  componentDidMount() {
    this.setupUserDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps) {
    const {
      selectedUserStatus,
      selectedUserMembership,
      setPageTitle,
      pageTitle
    } = this.props;
    if (
      prevProps.selectedUserStatus === STATUS_UPDATING &&
      selectedUserStatus === STATUS_READY
    ) {
      this.showSuccess();
    }
    if (
      prevProps.selectedUserStatus === STATUS_LOADING &&
      selectedUserStatus === STATUS_READY &&
      selectedUserMembership
    ) {
      if (pageTitle !== selectedUserMembership.name)
        setPageTitle(selectedUserMembership.name);
      this.setUserInfo();
    }

    if (
      selectedUserMembership &&
      !this.state.propertyIds &&
      !this.state.groupIds
    ) {
      this.setUserMembership();
    }

    const { groupIds, propertyIds, roleId } = this.state.originalState;
    if (selectedUserMembership && !roleId && !propertyIds && !groupIds) {
      this.setOriginalState();
    }
  }

  setOriginalState = () => {
    const { selectedUserMembership } = this.props;
    const { roleId, expiryDate, properties, propertyGroups } =
      selectedUserMembership;
    this.setState({
      originalState: {
        roleId,
        expiryDate: expiryDate ? moment(expiryDate).format("YYYY-MM-DD") : "",
        propertyIds: properties.map((item) => item.propId),
        groupIds: propertyGroups.map((item) => item.groupId)
      }
    });
  };

  setUserInfo = () => {
    const { roleId, expiryDate } = this.props.selectedUserMembership;
    this.setState({
      roleId,
      expiryDate: expiryDate ? moment(expiryDate).format("YYYY-MM-DD") : ""
    });
  };

  setUserMembership = () => {
    const { selectedUserMembership } = this.props;
    const { properties, propertyGroups } = selectedUserMembership;
    this.setState({
      propertyIds: properties.map((item) => item.propId),
      groupIds: propertyGroups.map((item) => item.groupId)
    });
  };

  setupUserDetails = () => {
    const {
      orgId,
      userId,
      loadUserMembership,
      loadOrganisationRoles,
      loadOrganisationProperties,
      loadOrganisationPropertyGroups,
      setOrgPropertiesPagination
    } = this.props;
    setOrgPropertiesPagination(1, 10, 0, "");
    loadUserMembership(orgId, userId);
    loadOrganisationRoles(orgId);
    loadOrganisationProperties(orgId);
    loadOrganisationPropertyGroups(orgId);
  };

  getHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      item: getLabel("USER_TEXT"),
      action: getLabel("UPDATE_ACTION_LABEL")
    });
  };

  setValue = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  getUserMembershipInfo = () => {
    const { roleId, expiryDate, groupIds, groupPropIds, propertyIds } =
      this.state;
    const { selectedUserMembership } = this.props;
    const { properties, propertyGroups } = selectedUserMembership;
    const addPropertyGroups = groupIds.map((item) => {
      return { groupId: item, roleId: roleId };
    });
    const removePropertyGroups = propertyGroups.reduce(
      (result, propertyGroup) =>
        groupIds.indexOf(propertyGroup.groupId) === -1
          ? [...result, { groupId: propertyGroup.groupId }]
          : result,
      []
    );

    const propertiesToAdd = propertyIds.filter(
      (el) => !groupPropIds.includes(el)
    );

    const addProperties = propertiesToAdd.map((item) => {
      return { propId: item, roleId: roleId };
    });

    const propertiesToRemove = propertyIds
      .filter((el) => groupPropIds.includes(el))
      .map((el) => {
        return { propId: el };
      });

    const removeMembershipProps = properties.reduce(
      (result, properties) =>
        propertyIds.indexOf(properties.propId) === -1
          ? [...result, { propId: properties.propId }]
          : result,
      []
    );
    const removeProperties = [...propertiesToRemove, ...removeMembershipProps];

    return JSON.stringify({
      expiryDate,
      roleId,
      addPropertyGroups:
        addPropertyGroups.length !== 0 ? addPropertyGroups : "",
      removePropertyGroups:
        removePropertyGroups.length !== 0 ? removePropertyGroups : "",
      addProperties: addProperties.length !== 0 ? addProperties : "",
      removeProperties: removeProperties.length !== 0 ? removeProperties : ""
    });
  };

  callAction = async () => {
    try {
      const { orgId, userId, updateUserMembership, showModal } = this.props;
      this.showLoading();
      showModal();

      await updateUserMembership(orgId, userId, this.getUserMembershipInfo());
    } catch (e) {
      throw new Error(e);
    }
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("UPDATING_LABEL"),
        item: getLabel("USER_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  returnToUserDetailAction = () => {
    const { hideModal, orgId, userId } = this.props;
    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${USERS_URL}/${userId}`);
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_UPDATED_LABEL", {
        item: getLabel("USER_TEXT")
      }),
      body: getLabel("ITEM_UPDATED_SUCCESS", {
        item: getLabel("USER_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_DETAILS", {
            item: getLabel("USER_TEXT")
          }),
          onClick: this.returnToUserDetailAction
        }
      ]
    });
    return null;
  };

  valueIsEmpty = (field) => {
    return (
      !this.getValue(field) ||
      (this.getValue(field) && this.getValue(field).length === 0) ||
      this.getValue(field) === ""
    );
  };

  getCurrentRoles = () => {
    const { selectedOrganisationRoles } = this.props;
    return selectedOrganisationRoles && selectedOrganisationRoles.length > 0
      ? selectedOrganisationRoles
      : false;
  };

  renderRolesRadios = () => {
    const { getLabel } = this.props;
    const availableRoles = this.getCurrentRoles();
    if (availableRoles) {
      const selectedRole = this.getValue("roleId");
      const roles = availableRoles.map(({ roleId, title }) => {
        return {
          label: title,
          value: roleId,
          checked: selectedRole === roleId
        };
      });

      return (
        <RadioGroup
          legend={getLabel("SELECT_HEADER", {
            item: getLabel("ROLE_TEXT")
          })}
          styleType={this.valueIsEmpty("roleId") ? STYLETYPE_ERROR : ""}
          value={selectedRole}
          isDisabled={false}
          name={"addUserRoleForm"}
          radioButtons={roles}
          onClick={(event) => {
            this.setValue("roleId", event);
          }}
          validationMessage={getLabel("VALIDATION_ERROR_PLEASE_SELECT_ROLE")}
          required={true}
        />
      );
    }
  };

  setPropertyGroupIdValues = (groupIds) => {
    this.setState({ groupIds }, () => {
      this.handleGroup(groupIds);
    });
  };

  handleGroup = (groupIds) => {
    const { selectedOrgPropertyGroups } = this.props;

    const selectedGroup = selectedOrgPropertyGroups
      .filter((r) => groupIds.includes(r.groupId))
      .map((r) => r.properties);

    const groupPropIds = [...new Set([].concat(...selectedGroup))];
    this.setState({ groupPropIds });
  };

  getPropertyGroupIdOptions = () => {
    const { groupIds } = this.state;
    const { selectedOrgPropertyGroups } = this.props;
    if (!selectedOrgPropertyGroups || groupIds === null) return [];
    if (selectedOrgPropertyGroups) {
      const groupIdOptions = selectedOrgPropertyGroups.map((group) => {
        return {
          ...group,
          title: group.title,
          value: group.groupId,
          checked: groupIds.indexOf(group.groupId) !== -1
        };
      });
      return groupIdOptions;
    }
  };

  getPropertyGroupIdValues = () => {
    const { groupIds } = this.state;
    return groupIds;
  };

  setPropertyIdValues = (propertyIds) => {
    this.setState({ propertyIds });
  };

  handleProperties = (property) => {
    const { groupIds } = this.state;
    const { selectedOrgPropertyGroups } = this.props;

    const currentSelectedGroups = selectedOrgPropertyGroups.filter((group) =>
      groupIds.includes(group.groupId)
    );

    const propertiesInUserGroup =
      currentSelectedGroups &&
      currentSelectedGroups.map((group) => {
        return group.properties;
      });

    const flat = [].concat(...propertiesInUserGroup);
    if (flat.includes(property.propId)) return true;
  };

  getPropertyIdOptions = () => {
    const { propertyIds } = this.state;
    const { selectedOrganisationProperties } = this.props;
    if (!selectedOrganisationProperties || propertyIds === null) return [];
    if (selectedOrganisationProperties) {
      const propertyIdOptions = selectedOrganisationProperties.map(
        (property) => {
          return {
            ...property,
            title: `${property.officialName} - ${property.title}`,
            value: property.propId,
            checked: this.handleProperties(property)
              ? this.handleProperties(property)
              : propertyIds.indexOf(property.propId) !== -1,
            disabled: this.handleProperties(property)
          };
        }
      );
      return propertyIdOptions;
    }
  };

  getPropertyIdValues = () => {
    const { propertyIds } = this.state;
    return propertyIds;
  };

  showMessage = () => {
    const { setModalContent, showModal, getLabel } = this.props;
    showModal();
    setModalContent({
      showCloseButton: false,
      header: getLabel("UPDATE_ACTION_HEADER_LABEL", {
        item: getLabel("USER_TEXT")
      }),
      body: getLabel("BACK_TO_DETAILS_MESSAGE"),
      buttons: [
        {
          buttonStyleType: BUTTON_TYPE_PRIMARY_FULLWIDTH,
          buttonLabel: getLabel("YES_LABEL"),
          onClick: this.callAction
        },
        {
          buttonStyleType: BUTTON_TYPE_SECONDARY_FULLWIDTH,
          buttonLabel: getLabel("NO_LABEL"),
          onClick: this.returnToUserDetailAction
        }
      ]
    });
    return null;
  };

  getStateChanged = () => {
    const { expiryDate, roleId, groupIds, propertyIds, originalState } =
      this.state;
    if (
      expiryDate !== originalState.expiryDate ||
      roleId !== originalState.roleId ||
      (groupIds &&
        originalState.groupIds &&
        groupIds.length !== originalState.groupIds.length) ||
      (propertyIds &&
        originalState.propertyIds &&
        propertyIds.length !== originalState.propertyIds.length) ||
      (groupIds &&
        originalState.groupIds &&
        groupIds.length === 1 &&
        originalState.groupIds.length === 1 &&
        groupIds[0] !== originalState.groupIds[0])
    ) {
      return true;
    }
    return false;
  };

  getButtons = () => {
    const { getLabel } = this.props;
    const stateChanged = this.getStateChanged();
    return [
      {
        onClick: this.callAction,
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("USER_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        }),
        isDisabled: !stateChanged
      },
      {
        onClick: stateChanged
          ? this.showMessage
          : this.returnToUserDetailAction,
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        buttonLabel: getLabel("RETURN_TO_DETAILS", {
          item: getLabel("USER_TEXT")
        })
      }
    ];
  };

  isLoadingOptions = () => {
    const {
      selectedUserStatus,
      selectedOrganisationRolesLoading,
      selectedOrgPropertyGroupsLoading
    } = this.props;
    return (
      selectedUserStatus === STATUS_LOADING ||
      selectedOrganisationRolesLoading ||
      selectedOrgPropertyGroupsLoading
    );
  };

  getPropertiesFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("PROPERTIES_TEXT")
    });
  };

  getOrgPropertiesTotal = () => {
    const { selectedOrgPropertiesTotal } = this.props;
    return selectedOrgPropertiesTotal;
  };

  showPagination = () => {
    return this.getOrgPropertiesTotal() > this.getPageLimit();
  };

  getCurrentPageNumber = () => {
    const { selectedOrgPropertiesPageNumber } = this.props;
    return selectedOrgPropertiesPageNumber;
  };

  setPaginationContent = (value) => {
    const { orgId, setOrgPropertiesPagination, loadOrganisationProperties } =
      this.props;
    const searchValue = this.getFilterText();
    let offset = (value - 1) * this.getPageLimit();
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(value, 10, offset, searchValue);
  };

  setFilterText = (value) => {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearInterval(searchTimeout);
    }

    this.setState(
      {
        propertiesFilterText: value
      },
      () => {
        if (value.length >= MINIMUM_SEARCH_STRING_LENGTH || value === "") {
          const searchTimeout = setTimeout(() => {
            this.setPaginationContent(1);
          }, SEARCH_TIMEOUT);
          this.setState({ searchTimeout });
        }
      }
    );
  };

  getFilterText = () => {
    const { propertiesFilterText } = this.state;
    return propertiesFilterText;
  };

  isPropertiesLoading = () => {
    const { selectedOrganisationPropertiesLoading } = this.props;
    return selectedOrganisationPropertiesLoading;
  };

  getPageLimit = () => {
    return getSession(`properties-${PAGINATION_KEY}`) || PAGINATION_OPTIONS[0];
  };

  dropDownOnSelectFunction = (dropdown) => {
    this.setState({ currentPageNumber: 1 });
    const { orgId, loadOrganisationProperties, setOrgPropertiesPagination } =
      this.props;
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(0, dropdown.value, 0, "");
    storeSession(`properties-${PAGINATION_KEY}`, dropdown.value);
  };

  showDropdown = () => {
    return this.getOrgPropertiesTotal() > this.getDropDownOptions()[0].value;
  };

  getDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit()
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoadingOptions() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.getHeaderText()}
                contextMenu={{ visible: false }}
              />
            </Container>
            <Section>
              <Container styleType={STYLETYPE_FORM_FIELD}>
                <FormLabel htmlFor="expiry-date">
                  {getLabel("EXPIRY_DATE_LABEL")}
                </FormLabel>
                <FormInput
                  id="expiry-date"
                  value={this.getValue("expiryDate")}
                  onChange={(value) => {
                    this.setValue("expiryDate", value);
                  }}
                  styleType={""}
                  type={STYLETYPE_DATE}
                ></FormInput>
              </Container>

              <Container styleType={STYLETYPE_FORM_FIELD}>
                <Fieldset>{this.renderRolesRadios()}</Fieldset>
              </Container>
              <Container styleType={STYLETYPE_CHECKBOX_CONTAINER}>
                <Checkbox
                  handleUpdateValue={this.setPropertyGroupIdValues}
                  options={this.getPropertyGroupIdOptions()}
                  value={this.getPropertyGroupIdValues()}
                  name={"groupIds"}
                  legend={getLabel("SELECT_HEADER", {
                    item: getLabel("PROPERTY_GROUPS_TEXT")
                  })}
                />
              </Container>
              <FilterInput
                inputId={`${this.getPropertiesFilterLabel()}-input`}
                label={this.getPropertiesFilterLabel()}
                liveFilter={true}
                placeholder={"e.g: Orchard 1"}
                filterFunction={(event) => {
                  this.setFilterText(event);
                }}
                styleType={DATA_TABLE_FILTER_INPUT_STYLING}
              />
              {this.isPropertiesLoading() ? (
                <Loader fullPage={false} />
              ) : (
                <Container styleType={STYLETYPE_CHECKBOX_CONTAINER}>
                  <Checkbox
                    handleUpdateValue={this.setPropertyIdValues}
                    options={this.getPropertyIdOptions()}
                    value={this.getPropertyIdValues()}
                    name={"propIds"}
                    legend={getLabel("SELECT_HEADER", {
                      item: getLabel("PROPERTIES_TEXT")
                    })}
                  />
                </Container>
              )}
              <Container
                direction={"row"}
                styleType={DATA_TABLE_PAGINATION_STYLING}
              >
                {this.showDropdown() && (
                  <Dropdown
                    id={"update-user"}
                    placeholder={"Select option"}
                    onClick={this.dropDownOnSelectFunction}
                    label={this.getDropDownLabel()}
                    value={this.getPageLimit()}
                    options={this.getDropDownOptions()}
                  />
                )}
                {this.showPagination() && (
                  <PaginationV2
                    currentPageNumber={this.getCurrentPageNumber()}
                    listPerPageNum={this.getPageLimit()}
                    totalItems={this.getOrgPropertiesTotal()}
                    setPaginationContent={this.setPaginationContent}
                    showPaginationInput={true}
                  />
                )}
              </Container>

              <BottomButtonBar buttons={this.getButtons()} />
            </Section>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default UpdateUser;
