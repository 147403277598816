import React, { Component } from "react";
import {
  Container,
  Wrapper,
  Section,
  TextArea,
  FormInput,
  FormLabel,
  Checkbox,
  CheckboxV2,
  Fieldset,
  Form
} from "@core";
import { Heading3, P } from "@typography";
import { CSSTransition } from "react-transition-group";
import { getSession, storeSession } from "@base/common";
import {
  USERS_URL,
  STYLE_TYPE_EMAIL,
  STATUS_READY,
  STATUS_ERROR,
  STATUS_CREATING,
  STYLETYPE_CODE,
  STYLETYPE_ERROR,
  STYLETYPE_FORM_FIELD,
  CONTENT_FADE_TIMEOUT,
  CONTENT_VIEW,
  STYLETYPE_DATE,
  STYLETYPE_CHECKBOX_CONTAINER,
  CONTEXT_HEADER_CONTAINER,
  MINIMUM_SEARCH_STRING_LENGTH,
  SEARCH_TIMEOUT,
  STATUS_RESENDING,
  ALWAYS,
  NEVER,
  OPTIONAL,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  DATA_TABLE_PAGINATION_STYLING,
  SECTION_HEADER_STYLING,
  DATA_TABLE_FILTER_INPUT_STYLING,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL
} from "@base/constants";
import {
  Loader,
  RadioGroup,
  ContextHeader,
  BottomButtonBar,
  FilterInput,
  PaginationV2,
  Dropdown
} from "@UIKit";
import { navigate } from "@reach/router";
import { validateEmail } from "../../../common";

class InviteUserToOrganisation extends Component {
  constructor(props) {
    super(props);
    const localRawModeValue = localStorage.getItem("userRawMode");
    const rawModeValue =
      localRawModeValue !== null ? localRawModeValue === "true" : false;

    this.state = {
      rawMode: rawModeValue,
      textFirstName: "",
      textLastName: "",
      textEmail: "",
      textInputValue: "",
      selectedRole: "",
      expiryDate: "",
      propertyIds: [],
      propertyGroupIds: [],
      emailExists: false,
      checkingEmail: false,
      propertiesFilterText: "",
      propGroupsFilterText: "",
      searchTimeout: null,
      sendEmailInvite: null,
      inviteDisabledValue: null,
      groupPropIds: []
    };

    this.emailTimer = null;
    this.controller = new AbortController();
  }

  componentDidMount() {
    this.loadDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps) {
    const { pageTitle, setPageTitle, getLabel, selectedUserStatus } =
      this.props;
    if (pageTitle !== getLabel("INVITE_USER_TO_ORGANISATION")) {
      setPageTitle(getLabel("INVITE_USER_TO_ORGANISATION"));
    }
    if (
      prevProps.selectedUserStatus === STATUS_CREATING &&
      selectedUserStatus === STATUS_READY
    ) {
      this.showSuccess();
    }

    if (
      prevProps.selectedUserStatus === STATUS_RESENDING &&
      selectedUserStatus === STATUS_READY
    ) {
      this.showResendInviteSuccess();
    }

    if (
      prevProps.selectedUserStatus === STATUS_CREATING &&
      selectedUserStatus === STATUS_ERROR
    ) {
      this.showInviteErrorModal();
    }
  }

  loadDetails = () => {
    const {
      orgId,
      loadOrganisationProperties,
      loadOrganisationPropertyGroups,
      loadOrganisationRoles,
      loadOrganisationUsers,
      setOrgPropertiesPagination,
      loadOrganisation,
      loadOrganisationInvitedUsers,
      loadOrganisationAcceptedUsers
    } = this.props;
    loadOrganisation(orgId);
    setOrgPropertiesPagination(1, 10, 0, "");
    loadOrganisationProperties(orgId);
    loadOrganisationPropertyGroups(orgId);
    loadOrganisationRoles(orgId);
    loadOrganisationUsers(orgId);
    loadOrganisationInvitedUsers(orgId);
    loadOrganisationAcceptedUsers(orgId);
  };

  static getDerivedStateFromProps(props, state) {
    const { selectedOrganisation } = props;
    const sendEmailOptions = [ALWAYS, NEVER, OPTIONAL];
    if (
      selectedOrganisation &&
      selectedOrganisation.preferences &&
      state.sendEmailInvite === null &&
      state.inviteDisabledValue === null
    ) {
      const { sendVerificationEmails } = selectedOrganisation.preferences;
      if (sendVerificationEmails === ALWAYS) {
        return { sendEmailInvite: true, inviteDisabledValue: true };
      } else if (sendVerificationEmails === NEVER) {
        return { sendEmailInvite: false, inviteDisabledValue: true };
      } else if (
        sendVerificationEmails === OPTIONAL ||
        sendVerificationEmails === undefined ||
        !sendEmailOptions.includes(sendVerificationEmails)
      ) {
        return { sendEmailInvite: true, inviteDisabledValue: false };
      }
    }
    return state;
  }

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  valueIsEmpty = (field) => {
    return (
      !this.getValue(field) ||
      (this.getValue(field) && this.getValue(field).length === 0) ||
      this.getValue(field) === ""
    );
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("INVITE_USER_TO_ORGANISATION");
  };

  isInvalid = () => {
    const { rawMode } = this.state;

    if (rawMode) {
      return this.valueIsEmpty("textInputValue");
    } else if (this.checkCurrentOrgUser()) {
      return (
        this.checkCurrentOrgUser() !== false ||
        this.valueIsEmpty("selectedRole") ||
        (!this.valueIsEmpty("expiryDate") && !this.greaterExpiryDate())
      );
    } else {
      return (
        this.getEmailErrorMessage() !== null ||
        this.valueIsEmpty("textFirstName") ||
        this.valueIsEmpty("textLastName") ||
        this.valueIsEmpty("selectedRole") ||
        (!this.valueIsEmpty("expiryDate") && !this.greaterExpiryDate())
      );
    }
  };

  membershipData = () => {
    const roleId = this.getValue("selectedRole");
    const propId = this.getValue("propertyIds");
    const groupId = this.getValue("propertyGroupIds");

    const properties = propId.map((item) => {
      return { propId: item, roleId: roleId };
    });
    const propertyGroups = groupId.map((item) => {
      return { groupId: item, roleId: roleId };
    });

    const updatedObj =
      properties.length !== 0 && propertyGroups.length !== 0
        ? {
            properties,
            propertyGroups
          }
        : properties.length !== 0
        ? {
            properties
          }
        : propertyGroups.length !== 0
        ? {
            propertyGroups
          }
        : {};
    return updatedObj;
  };

  resendUserInvite = async () => {
    try {
      const { sendEmailInvite } = this.state;
      const { orgId, inviteId } = this.props;
      let data = {};
      if (this.getValue("rawMode") === false) {
        data = {
          email: this.getValue("textEmail"),
          firstName: this.getValue("textFirstName"),
          lastName: this.getValue("textLastName"),
          roleId: this.getValue("selectedRole"),
          expiryDate: this.getValue("expiryDate"),
          active: true,
          superHuman: false,
          ...this.membershipData()
        };
      } else {
        data = JSON.parse(this.getValue("textInputValue"));
      }

      const { resendInvite, showModal } = this.props;
      this.showLoading("RESENDING_LABEL", "INVITE_LABEL");
      showModal();

      await resendInvite(orgId, inviteId, sendEmailInvite, data);
    } catch (e) {
      throw new Error(e);
    }
  };

  showResendInviteSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("INVITE_SENT_LABEL", {
        item: getLabel("INVITE_LABEL")
      }),
      body: getLabel("INVITE_SENT_LABEL", {
        item: getLabel("INVITE_LABEL")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("USERS_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  callAction = async () => {
    try {
      const { orgId } = this.props;
      let data = {};
      if (this.getValue("rawMode") === false) {
        data = {
          email: this.getValue("textEmail"),
          firstName: this.getValue("textFirstName"),
          lastName: this.getValue("textLastName"),
          roleId: this.getValue("selectedRole"),
          expiryDate: this.getValue("expiryDate"),
          active: true,
          superHuman: false,
          ...this.membershipData()
        };
      } else {
        data = JSON.parse(this.getValue("textInputValue"));
      }

      const { sendEmailInvite } = this.state;
      const { createInvite, showModal } = this.props;
      this.showLoading("INVITING_LABEL", "USER_TEXT");
      showModal();

      await createInvite(orgId, data, sendEmailInvite);
    } catch (e) {
      throw new Error(e);
    }
  };

  showLoading = (action, item) => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel(action),
        item: getLabel(item)
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    const firstName = this.getValue("textFirstName");
    const lastName = this.getValue("textLastName");

    setModalContent({
      showCloseButton: false,
      header: getLabel("USER_INVITED_LABEL"),
      body: getLabel("INVITED_USER_MESSAGE", {
        firstName,
        lastName
      }),
      buttons: [
        {
          buttonLabel: getLabel("RESEND_INVITE"),
          onClick: this.resendUserInvite
        },
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("USERS_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  resendExistingInvite = async () => {
    try {
      const { textEmail, sendEmailInvite } = this.state;
      const { selectedOrganisationInvitedUsers } = this.props;
      const invitedUser = selectedOrganisationInvitedUsers.find(
        (user) => user.email === textEmail
      );

      const { orgId, inviteId } = invitedUser;
      const { resendInvite, showModal } = this.props;

      this.showLoading("RESENDING_LABEL", "INVITE_LABEL");
      showModal();

      await resendInvite(orgId, inviteId, sendEmailInvite, {});
    } catch (e) {
      throw new Error(e);
    }
  };

  closeModal = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  showInviteErrorModal = () => {
    const { setModalContent, getLabel, inviteUserErrorMessage } = this.props;

    setModalContent({
      showCloseButton: false,
      body: `${inviteUserErrorMessage}`,
      buttons: [
        {
          buttonLabel: getLabel("RESEND_INVITE"),
          onClick: this.resendExistingInvite
        },
        {
          buttonLabel: getLabel("CLOSE_MODAL_BUTTON_LABEL"),
          onClick: this.closeModal
        }
      ]
    });
    return null;
  };

  addAnother = () => {
    const { hideModal } = this.props;
    this.setValue("", "textInputValue");
    hideModal();
  };

  returnToOverviewAction = () => {
    const { hideModal, orgId } = this.props;
    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${USERS_URL}/`);
  };

  getCurrentRoles = () => {
    const { selectedOrganisationRoles } = this.props;
    return selectedOrganisationRoles && selectedOrganisationRoles.length > 0
      ? selectedOrganisationRoles
      : false;
  };

  displayCurrentRoles = () => {
    const { selectedOrganisationRolesLoading } = this.props;
    if (selectedOrganisationRolesLoading) return <Loader fullPage={false} />;
    const availableRoles = this.getCurrentRoles();
    if (availableRoles)
      return availableRoles.map((role) => {
        return (
          <P key={`key-${role.title}-${role.roleId}`}>
            {`${role.title}: ${role.roleId}`}
          </P>
        );
      });
  };

  renderRolesRadios = () => {
    const { getLabel } = this.props;
    const availableRoles = this.getCurrentRoles();
    if (availableRoles) {
      const selectedRole = this.getValue("selectedRole");
      const roles = availableRoles.map(({ roleId, title }) => {
        return {
          label: title,
          value: roleId,
          checked: selectedRole === roleId
        };
      });

      return (
        <RadioGroup
          legend={getLabel("SELECT_HEADER", {
            item: getLabel("ROLE_TEXT")
          })}
          styleType={this.valueIsEmpty("selectedRole") ? STYLETYPE_ERROR : ""}
          value={selectedRole}
          isDisabled={false}
          name={"addUserRoleForm"}
          radioButtons={roles}
          onClick={(event) => {
            this.setValue(event, "selectedRole");
          }}
          validationMessage={getLabel("VALIDATION_ERROR_PLEASE_SELECT_ROLE")}
          required={true}
        />
      );
    }
  };

  setPropertyIdValues = (propertyIds) => {
    this.setState({ propertyIds });
  };

  handleProperties = (property) => {
    const { propertyGroupIds } = this.state;
    const { selectedOrgPropertyGroups = [] } = this.props;

    const currentSelectedGroups = selectedOrgPropertyGroups.filter((group) =>
      propertyGroupIds.includes(group.groupId)
    );

    const propertiesInUserGroup =
      currentSelectedGroups &&
      currentSelectedGroups.map((group) => {
        return group.properties;
      });

    const flat = [].concat(...propertiesInUserGroup);
    if (flat.includes(property.propId)) return true;
  };

  getPropertyIdOptions = () => {
    const { propertyIds } = this.state;
    const { getLabel, selectedOrganisationProperties } = this.props;
    if (!selectedOrganisationProperties) return [];

    const propertyIdOptions = selectedOrganisationProperties.map((property) => {
      return {
        ...property,
        title: `${property.officialName} - ${property.title}`,
        value: property.propId,
        checked: this.handleProperties(property)
          ? this.handleProperties(property)
          : propertyIds.indexOf(property.propId) !== -1,
        disabled: this.handleProperties(property)
      };
    });
    if (propertyIdOptions.length === 0) {
      return [
        {
          title: getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
            filterText: this.getPropertiesFilterText()
          })
        }
      ];
    }
    return propertyIdOptions;
  };

  getPropertyIdValues = () => {
    const { propertyIds } = this.state;
    return propertyIds;
  };

  setPropertyGroupIdValues = (propertyGroupIds) => {
    this.setState({ propertyGroupIds }, () => {
      this.handleGroup(propertyGroupIds);
    });
  };

  handleGroup = (propertyGroupIds) => {
    const { selectedOrgPropertyGroups } = this.props;

    const selectedGroup = selectedOrgPropertyGroups
      .filter((r) => propertyGroupIds.includes(r.groupId))
      .map((r) => r.properties);

    const groupPropIds = [...new Set([].concat(...selectedGroup))];
    this.setState({ groupPropIds });
  };

  getPropertyGroupIdOptions = () => {
    const { propertyGroupIds } = this.state;
    const { getLabel, selectedOrgPropertyGroups } = this.props;
    if (!selectedOrgPropertyGroups) return [];
    const filterText = this.getPropGroupsFilterText().toLowerCase();
    const filteredPropertyGroups = this.filterList(
      selectedOrgPropertyGroups,
      filterText
    );
    const groupIdOptions = filteredPropertyGroups.map((group) => {
      return {
        ...group,
        title: group.title,
        value: group.groupId,
        checked: propertyGroupIds.indexOf(group.groupId) !== -1
      };
    });
    if (groupIdOptions.length === 0) {
      return [
        {
          title: getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
            filterText: this.getPropGroupsFilterText()
          })
        }
      ];
    }
    return groupIdOptions;
  };

  getPropertyGroupIdValues = () => {
    const { propertyGroupIds } = this.state;
    return propertyGroupIds;
  };

  addUserJsonExample = () => {
    return `{
      firstName: "Test",
      lastName: "Test",
      emailAddress: "test@gmail.com",
      superHuman: true,
      roleId: "theRoleId"
    }`;
  };

  checkEmailExists = (email) => {
    const { selectedOrgUsers } = this.props;
    if (selectedOrgUsers) {
      const userExists = selectedOrgUsers.find(
        (user) => user.emailAddress === email
      );
      this.setState({
        emailExists: (userExists && userExists.emailAddress) || false,
        checkingEmail: false,
        textFirstName: "",
        textLastName: ""
      });
    }
  };

  greaterExpiryDate = () => {
    const expiryDate = this.getValue("expiryDate");
    const todaysDate = new Date().toISOString().slice(0, 10);
    return expiryDate !== todaysDate && expiryDate > todaysDate;
  };

  checkCurrentOrgUser = () => {
    const { emailExists } = this.state;
    return emailExists;
  };

  getCurrentUserInvite = () => {
    const { textEmail } = this.state;
    const { selectedOrganisationInvitedUsers } = this.props;
    const invitedUser = selectedOrganisationInvitedUsers.find(
      (user) => user.email === textEmail
    );
    return !invitedUser ? false : true;
  };

  getEmailErrorMessage = () => {
    const { getLabel } = this.props;
    const isEmpty = this.valueIsEmpty("textEmail");
    if (isEmpty) return getLabel("FORM_FIELD_EMPTY_LABEL");
    const currentOrgUser = this.checkCurrentOrgUser();
    if (currentOrgUser) return getLabel("CURRENT_ORGANISATION_USER");
    const currentUserInvite = this.getCurrentUserInvite();
    if (currentUserInvite) return getLabel("USER_INVITED_MESSAGE");
    const emailValue = this.getValue("textEmail");
    if (!validateEmail(emailValue))
      return getLabel("EMAIL_ADDRESS_INVALID_LABEL");
    return null;
  };

  setEmailAddress = (value) => {
    this.setValue(true, "checkingEmail");
    this.setValue(value, "textEmail");
    if (!value) this.setValue(false, "emailExists");
    const emailIsValid = validateEmail(value);
    if (value && emailIsValid) {
      this.checkEmailExists(value);
    }
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: "primary",
        isDisabled: this.isInvalid(),
        onClick: this.callAction,
        buttonLabel: getLabel("INVITE_USER_TO_ORGANISATION")
      }
    ];
  };

  isLoadingOptions = () => {
    const {
      organisationsLoading,
      selectedOrganisationRolesLoading,
      selectedOrgPropertyGroupsLoading
    } = this.props;

    return (
      organisationsLoading ||
      selectedOrganisationRolesLoading ||
      selectedOrgPropertyGroupsLoading
    );
  };

  emailExists = () => {
    const { emailExists } = this.state;
    return emailExists;
  };

  getPropertiesFilterText = () => {
    const { propertiesFilterText } = this.state;
    return propertiesFilterText;
  };

  getPropGroupsFilterText = () => {
    const { propGroupsFilterText } = this.state;
    return propGroupsFilterText;
  };

  getPropertiesFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("PROPERTIES_TEXT")
    });
  };

  getPropertyGroupsFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("PROPERTY_GROUPS_TEXT")
    });
  };

  filterList = (list, filterText) => {
    return list.filter((listItem) => {
      return (
        listItem.title && listItem.title.toLowerCase().indexOf(filterText) > -1
      );
    });
  };

  switchModes = () => {
    this.setState(
      {
        rawMode: !this.state.rawMode
      },
      () => {
        localStorage.setItem("userRawMode", this.state.rawMode);
      }
    );
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    const { rawMode } = this.state;
    return {
      childLinks: [
        {
          isDisabled: false,
          onClick: this.switchModes,
          buttonLabel: rawMode
            ? getLabel("FORM_MODE_LABEL")
            : getLabel("RAW_MODE_LABEL")
        }
      ],
      isDisabled: false,
      visible: true
    };
  };

  getOrgPropertiesTotal = () => {
    const { selectedOrgPropertiesTotal } = this.props;
    return selectedOrgPropertiesTotal;
  };

  getPageLimit = () => {
    return getSession(`properties-${PAGINATION_KEY}`) || PAGINATION_OPTIONS[0];
  };

  showPagination = () => {
    return this.getOrgPropertiesTotal() > this.getPageLimit();
  };

  getCurrentPageNumber = () => {
    const { selectedOrgPropertiesPageNumber } = this.props;
    return selectedOrgPropertiesPageNumber;
  };

  setPaginationContent = (value) => {
    const { orgId, setOrgPropertiesPagination, loadOrganisationProperties } =
      this.props;
    const searchValue = this.getFilterText();
    let offset = (value - 1) * this.getPageLimit();
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(value, 10, offset, searchValue);
  };

  setFilterText = (value) => {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearInterval(searchTimeout);
    }

    this.setState(
      {
        propertiesFilterText: value
      },
      () => {
        if (value.length >= MINIMUM_SEARCH_STRING_LENGTH || value === "") {
          const searchTimeout = setTimeout(() => {
            this.setPaginationContent(1);
          }, SEARCH_TIMEOUT);
          this.setState({ searchTimeout });
        }
      }
    );
  };

  getFilterText = () => {
    const { propertiesFilterText } = this.state;
    return propertiesFilterText;
  };

  isPropertiesLoading = () => {
    const { selectedOrganisationPropertiesLoading } = this.props;
    return selectedOrganisationPropertiesLoading;
  };

  setSendEmailInvite = () => {
    this.setState({
      sendEmailInvite: !this.state.sendEmailInvite
    });
  };

  renderSendInviteCheckbox = () => {
    const { getLabel } = this.props;
    const { sendEmailInvite, inviteDisabledValue } = this.state;
    return (
      <CheckboxV2
        handleUpdate={this.setSendEmailInvite}
        name={"sendEmailInvite"}
        value={sendEmailInvite}
        checked={sendEmailInvite}
        disabled={inviteDisabledValue}
        title={getLabel("SEND_EMAIL_INVITE_LABEL")}
      />
    );
  };

  dropDownOnSelectFunction = (dropdown) => {
    this.setState({ currentPageNumber: 1 });
    const { orgId, loadOrganisationProperties, setOrgPropertiesPagination } =
      this.props;
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(0, dropdown.value, 0, "");
    storeSession(`properties-${PAGINATION_KEY}`, dropdown.value);
  };

  showDropdown = () => {
    return this.getOrgPropertiesTotal() > this.getDropDownOptions()[0].value;
  };

  getDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit()
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  isSuperHuman = () => {
    return this.props.user.superHuman;
  };

  render() {
    const { getLabel } = this.props;
    const { expiryDate, checkingEmail } = this.state;

    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoadingOptions() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            {this.isSuperHuman() && (
              <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
                <ContextHeader
                  headerTitle={this.createHeaderText()}
                  contextMenu={this.getContextMenu()}
                />
              </Container>
            )}
            <Container>
              <Form>
                <CSSTransition
                  in={this.getValue("rawMode")}
                  timeout={CONTENT_FADE_TIMEOUT}
                  classNames="css-transition"
                  unmountOnExit
                >
                  <Section>
                    <Heading3 styleType={SECTION_HEADER_STYLING}>
                      {getLabel("USERS_JSON_EXAMPLE_LABEL")}
                    </Heading3>
                    <P styleType={STYLETYPE_CODE}>
                      {this.addUserJsonExample()}
                    </P>
                    <P>{getLabel("ADD_USER_EXAMPLE_JSON_NOTES")}</P>
                    <Heading3 styleType={SECTION_HEADER_STYLING}>
                      {getLabel("ROLES_AVAILABLE_LABEL")}
                    </Heading3>
                    {this.displayCurrentRoles()}
                    <FormLabel
                      styleType={
                        this.valueIsEmpty("textInputValue")
                          ? STYLETYPE_ERROR
                          : ""
                      }
                      htmlFor="rawjson"
                    >
                      {getLabel("ADD_JSON", {
                        item: getLabel("USER_TEXT")
                      })}
                    </FormLabel>
                    <TextArea
                      value={this.getValue("textInputValue")}
                      onChange={(value) => {
                        this.setValue(value, "textInputValue");
                      }}
                      styleType={
                        this.valueIsEmpty("textInputValue")
                          ? STYLETYPE_ERROR
                          : ""
                      }
                      aria-invalid={this.valueIsEmpty("textInputValue")}
                      id="rawjson"
                    />
                  </Section>
                </CSSTransition>
                <CSSTransition
                  in={!this.getValue("rawMode")}
                  timeout={CONTENT_FADE_TIMEOUT}
                  classNames="css-transition"
                  unmountOnExit
                >
                  <Container>
                    <Container>{this.renderSendInviteCheckbox()}</Container>
                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <FormLabel
                        styleType={
                          this.getEmailErrorMessage() ? STYLETYPE_ERROR : ""
                        }
                        htmlFor="text-email"
                      >
                        {getLabel("FORM_EMAIL_LABEL")}
                      </FormLabel>
                      <FormInput
                        value={this.getValue("textEmail")}
                        onChange={(value) => {
                          this.setEmailAddress(value);
                        }}
                        styleType={
                          this.getEmailErrorMessage() ? STYLETYPE_ERROR : ""
                        }
                        ariaDescribedBy={
                          this.getEmailErrorMessage() ? "email-error" : null
                        }
                        id="text-email"
                        required={true}
                        type={STYLE_TYPE_EMAIL}
                        aria-invalid={this.getEmailErrorMessage()}
                      />
                      {this.getEmailErrorMessage() && (
                        <P styleType={"error"} id="email-error">
                          {this.getEmailErrorMessage()}
                        </P>
                      )}
                    </Container>
                    {!this.emailExists() && (
                      <>
                        <Container styleType={STYLETYPE_FORM_FIELD}>
                          <FormLabel
                            styleType={
                              this.valueIsEmpty("textFirstName")
                                ? STYLETYPE_ERROR
                                : ""
                            }
                            htmlFor="text-first-name"
                          >
                            {getLabel("FORM_FIRST_NAME_LABEL")}
                          </FormLabel>
                          <FormInput
                            isDisabled={checkingEmail}
                            value={this.getValue("textFirstName")}
                            onChange={(value) => {
                              this.setValue(value, "textFirstName");
                            }}
                            styleType={
                              this.valueIsEmpty("textFirstName")
                                ? STYLETYPE_ERROR
                                : ""
                            }
                            required={true}
                            ariaDescribedBy={
                              this.valueIsEmpty("textFirstName")
                                ? "firstname-error"
                                : null
                            }
                            id="text-first-name"
                            aria-invalid={this.valueIsEmpty("textFirstName")}
                          />
                          {this.valueIsEmpty("textFirstName") && (
                            <P styleType={"error"} id="firstname-error">
                              {getLabel("FORM_FIELD_EMPTY_LABEL")}
                            </P>
                          )}
                        </Container>
                        <Container styleType={STYLETYPE_FORM_FIELD}>
                          <FormLabel
                            styleType={
                              this.valueIsEmpty("textLastName")
                                ? STYLETYPE_ERROR
                                : ""
                            }
                            htmlFor="text-last-name"
                          >
                            {getLabel("FORM_LAST_NAME_LABEL")}
                          </FormLabel>
                          <FormInput
                            isDisabled={checkingEmail}
                            value={this.getValue("textLastName")}
                            onChange={(value) => {
                              this.setValue(value, "textLastName");
                            }}
                            styleType={
                              this.valueIsEmpty("textLastName")
                                ? STYLETYPE_ERROR
                                : ""
                            }
                            required={true}
                            ariaDescribedBy={
                              this.valueIsEmpty("textLastName")
                                ? "lastname-error"
                                : null
                            }
                            id="text-last-name"
                            aria-invalid={this.valueIsEmpty("textLastName")}
                          />
                          {this.valueIsEmpty("textLastName") && (
                            <P styleType={"error"} id="lastname-error">
                              {getLabel("FORM_FIELD_EMPTY_LABEL")}
                            </P>
                          )}
                        </Container>
                      </>
                    )}

                    <Container styleType={STYLETYPE_FORM_FIELD}>
                      <FormLabel
                        styleType={
                          expiryDate !== "" && !this.greaterExpiryDate()
                            ? STYLETYPE_ERROR
                            : ""
                        }
                        htmlFor="expiry-date"
                      >
                        {getLabel("EXPIRY_DATE_LABEL")}
                      </FormLabel>
                      <FormInput
                        value={this.getValue("expiryDate")}
                        onChange={(value) => {
                          this.setValue(value, "expiryDate");
                        }}
                        styleType={
                          !this.valueIsEmpty("expiryDate") &&
                          !this.greaterExpiryDate()
                            ? STYLETYPE_ERROR
                            : ""
                        }
                        type={STYLETYPE_DATE}
                        id="expiry-date"
                        aria-invalid={
                          !this.valueIsEmpty("expiryDate") &&
                          !this.greaterExpiryDate()
                        }
                      />
                      {!this.valueIsEmpty("expiryDate") &&
                        !this.greaterExpiryDate() && (
                          <P styleType={"error"}>
                            {getLabel("EXPIRY_DATE_ERROR_LABEL")}
                          </P>
                        )}
                    </Container>
                    <>
                      <Container styleType={STYLETYPE_FORM_FIELD}>
                        <Fieldset>{this.renderRolesRadios()}</Fieldset>
                      </Container>
                      <FilterInput
                        inputId={`${this.getPropertyGroupsFilterLabel()}-input`}
                        label={this.getPropertyGroupsFilterLabel()}
                        liveFilter={true}
                        placeholder={"e.g: Group 1"}
                        filterFunction={(event) => {
                          this.setValue(event, "propGroupsFilterText");
                        }}
                        styleType={DATA_TABLE_FILTER_INPUT_STYLING}
                      />
                      <Container styleType={STYLETYPE_CHECKBOX_CONTAINER}>
                        <Checkbox
                          handleUpdateValue={this.setPropertyGroupIdValues}
                          options={this.getPropertyGroupIdOptions()}
                          value={this.getPropertyGroupIdValues()}
                          name={"groupIds"}
                          legend={getLabel("SELECT_HEADER", {
                            item: getLabel("PROPERTY_GROUPS_TEXT")
                          })}
                        />
                      </Container>
                      <FilterInput
                        inputId={`${this.getPropertiesFilterLabel()}-input`}
                        label={this.getPropertiesFilterLabel()}
                        liveFilter={true}
                        placeholder={"e.g: Orchard 1"}
                        filterFunction={(event) => {
                          this.setFilterText(event);
                        }}
                        styleType={DATA_TABLE_FILTER_INPUT_STYLING}
                      />
                      {this.isPropertiesLoading() ? (
                        <Loader fullPage={false} />
                      ) : (
                        <Container styleType={STYLETYPE_CHECKBOX_CONTAINER}>
                          <Checkbox
                            handleUpdateValue={this.setPropertyIdValues}
                            options={this.getPropertyIdOptions()}
                            value={this.getPropertyIdValues()}
                            name={"propIds"}
                            legend={getLabel("SELECT_HEADER", {
                              item: getLabel("PROPERTIES_TEXT")
                            })}
                          />
                        </Container>
                      )}
                      <Container
                        direction={"row"}
                        styleType={DATA_TABLE_PAGINATION_STYLING}
                      >
                        {this.showDropdown() && (
                          <Dropdown
                            id={"invite-user-to-organisation"}
                            placeholder={"Select option"}
                            onClick={this.dropDownOnSelectFunction}
                            label={this.getDropDownLabel()}
                            value={this.getPageLimit()}
                            options={this.getDropDownOptions()}
                          />
                        )}
                        {this.showPagination() && (
                          <PaginationV2
                            currentPageNumber={this.getCurrentPageNumber()}
                            listPerPageNum={this.getPageLimit()}
                            totalItems={this.getOrgPropertiesTotal()}
                            setPaginationContent={this.setPaginationContent}
                            showPaginationInput={true}
                          />
                        )}
                      </Container>

                      {this.isInvalid() ? (
                        <P styleType={STYLETYPE_ERROR}>
                          {getLabel("PLEASE_FILL_FORM")}
                        </P>
                      ) : null}
                      <BottomButtonBar buttons={this.getButtons()} />
                    </>
                  </Container>
                </CSSTransition>
              </Form>
            </Container>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default InviteUserToOrganisation;
