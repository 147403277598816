import React from "react";
import { PaginationButton } from "../style";
import { Button } from "@core";
import PropTypes from "prop-types";

const PaginationIconButton = (props) => {
  return (
    <PaginationButton key={props.iconType} active={props.active}>
      <Button
        buttonStyleType={"paginationButtons"}
        isDisabled={props.isDisabled}
        onClick={props.onClick}
        buttonLabel={""}
        icon={{
          type: props.iconType,
          bgWidth: "10px",
          bgHeight: "10px",
          iconHeight: "10px",
          iconWidth: "10px",
          iconColor: props.iconColor
        }}
      />
    </PaginationButton>
  );
};

PaginationIconButton.propTypes = {
  /** The color of the icon */
  iconColor: PropTypes.string,
  /** Whether the button is disabled */
  isDisabled: PropTypes.bool,
  /** Called on click of the button */
  onClick: PropTypes.func,
  /** Icon button active or not */
  active: PropTypes.bool,
  /** iconType for Icon */
  iconType: PropTypes.string
};

export default PaginationIconButton;
