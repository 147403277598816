import { createActions } from "redux-actions";
import {
  LOAD_WORKFLOW,
  LOADED_WORKFLOW,
  LOAD_WORKFLOW_PROPERTY_GROUPS,
  LOADED_WORKFLOW_PROPERTY_GROUPS,
  LOAD_WORKFLOW_PROPERTIES,
  LOADED_WORKFLOW_PROPERTIES,
  CREATE_WORKFLOW,
  CREATED_WORKFLOW,
  CREATE_WORKFLOW_FAILED,
  REMOVE_WORKFLOW,
  REMOVED_WORKFLOW,
  UPDATE_WORKFLOW,
  UPDATED_WORKFLOW
} from "@base/constants";

export const {
  loadWorkflow,
  loadedWorkflow,
  loadWorkflowPropertyGroups,
  loadedWorkflowPropertyGroups,
  loadWorkflowProperties,
  loadedWorkflowProperties,
  createWorkflow,
  createdWorkflow,
  createWorkflowFailed,
  removeWorkflow,
  removedWorkflow,
  updateWorkflow,
  updatedWorkflow
} = createActions({
  [LOAD_WORKFLOW]: (orgId, workflowId) => ({
    orgId,
    workflowId
  }),
  [LOADED_WORKFLOW]: (workflow) => workflow,
  [LOAD_WORKFLOW_PROPERTY_GROUPS]: (orgId, workflowId) => ({
    orgId,
    workflowId
  }),
  [LOADED_WORKFLOW_PROPERTY_GROUPS]: (propertyGroup) => propertyGroup,
  [LOAD_WORKFLOW_PROPERTIES]: (orgId, workflowId) => ({
    orgId,
    workflowId
  }),
  [LOADED_WORKFLOW_PROPERTIES]: (properties) => properties,
  [CREATE_WORKFLOW]: (orgId, data) => ({
    orgId,
    data
  }),
  [CREATED_WORKFLOW]: (workflow) => workflow,
  [CREATE_WORKFLOW_FAILED]: () => {},
  [REMOVE_WORKFLOW]: (orgId, workflowId) => ({
    orgId,
    workflowId
  }),
  [REMOVED_WORKFLOW]: () => {},
  [UPDATE_WORKFLOW]: (orgId, workflowId, data) => ({
    orgId,
    workflowId,
    data
  }),
  [UPDATED_WORKFLOW]: (orgId, workflowId) => ({
    orgId,
    workflowId
  })
});
