import React from "react";
import PropTypes from "prop-types";
import { FormLabel } from "@core";
import { InputWrapper, Input } from "./style";

const CheckboxV2 = (props) => {
  const { handleUpdate } = props;
  return (
    <InputWrapper>
      <Input
        type={"checkbox"}
        id={`${props.title}-input`}
        name={props.name}
        key={`${props.title}`}
        value={props.value}
        checked={props.checked} //skipcq JS-0463
        disabled={props.disabled}
        onChange={(e) => {
          handleUpdate(e);
        }}
      />
      <FormLabel htmlFor={`${props.title}-input`}>{props.title}</FormLabel>
    </InputWrapper>
  );
};

CheckboxV2.propTypes = {
  /** handleUpdate function for updating checkbox */
  handleUpdate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /** returns name */
  name: PropTypes.string,
  /** return rowId */
  rowId: PropTypes.string,
  /** returns title */
  title: PropTypes.string,
  /** returns disabled as true or false */
  disabled: PropTypes.bool
};

CheckboxV2.defaultProps = {};

export default CheckboxV2;
