import React, { Component } from "react";
import {
  defaultTheme,
  ContextHeader,
  BottomButtonBar,
  Loader,
  DataTable
} from "@UIKit";
import {
  NEW_URL,
  USERS_URL,
  CONTENT_VIEW,
  STATUS_LOADING,
  CONTEXT_HEADER_CONTAINER,
  BUTTON_TYPE_PRIMARY,
  INVITES_TEXT,
  STYLETYPE_STATUS,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  CREATE_PERMISSION,
  INVITES_SECTION,
  READ_PERMISSION,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  ORGANISATION_URL
} from "@base/constants";
import { P } from "@typography";
import { navigate } from "@reach/router";
import {
  filterByValue,
  storeSession,
  getSession,
  sortMethod,
  getStatusColorByKey,
  getStatusLabelConstByKey,
  getUserStatusKey
} from "@base/common";
import { Container, Wrapper, Section, LinkButton, Icon } from "@core";

class UserOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: "",
      currentPageNumber: 1,
      sortDirection: "asc"
    };
  }

  componentDidMount() {
    this.loadUserOverview();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps) {
    const { orgId, setPageTitle, pageTitle, availableOrganisations } =
      this.props;
    if (orgId && availableOrganisations) {
      const org = availableOrganisations.find((org) => org.orgId === orgId);
      const docTitle = `${org.title} users overview`;
      if (pageTitle !== docTitle) {
        setPageTitle(docTitle);
      }
    }
  }

  loadUserOverview = () => {
    const { orgId, loadOrganisationUsers, loadOrganisationInvitedUsers } =
      this.props;
    loadOrganisationUsers(orgId);
    loadOrganisationInvitedUsers(orgId);
  };

  getOrganisationUsers = () => {
    const {
      selectedOrganisationUsers,
      selectedOrganisationUsersLoading,
      selectedOrganisationInvitedUsers
    } = this.props;

    if (selectedOrganisationUsersLoading) return [];
    return [...selectedOrganisationUsers, ...selectedOrganisationInvitedUsers];
  };

  createHeaderText = () => {
    const { getLabel, availableOrganisations, orgId } = this.props;
    return availableOrganisations
      ? getLabel("USERS_HEADING_TEXT", {
          organisation: availableOrganisations.find(
            (org) => org.orgId === orgId
          ).title
        })
      : getLabel("USERS_TEXT");
  };

  getSecondHeaderText = () => {
    const { getLabel } = this.props;
    const { USERS_TEXT } = getLabel();
    return USERS_TEXT;
  };

  getUserStatusInfo = (statusKey) => {
    const { getLabel } = this.props;
    const labelConst = getStatusLabelConstByKey(statusKey);
    const color = getStatusColorByKey(statusKey);
    return { label: getLabel(labelConst), color };
  };

  createNavigationLink = (link) => {
    return () => {
      navigate(link);
    };
  };

  createNewUserLink = () => {
    const { orgId } = this.props;

    return this.createNavigationLink(
      `/${ORGANISATION_URL}/${orgId}/${USERS_URL}/${NEW_URL}`
    );
  };

  getNewUserLabel = () => {
    const { getLabel } = this.props;
    return getLabel("INVITE_USER_TO_ORGANISATION");
  };

  isSuperHuman = () => {
    const { superHumanUser } = this.props;
    return !!superHumanUser;
  };

  setFilterText = (value) => {
    this.setState({
      filterText: value,
      currentPageNumber: 1
    });
  };

  getFilterText = () => {
    const { filterText } = this.state;
    return filterText;
  };

  getFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("USERS_TEXT")
    });
  };

  hasCreatePermission = () => {
    const { getHasPermission, orgId } = this.props;
    return getHasPermission(orgId, INVITES_SECTION, CREATE_PERMISSION);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        isDisabled: false,
        onClick: this.createNewUserLink(),
        buttonLabel: getLabel("INVITE_USER_TO_ORGANISATION"),
        icon: {
          type: "plus",
          bgWidth: "25px",
          bgHeight: "15px",
          iconHeight: "16px",
          iconWidth: "15px",
          iconColor: defaultTheme.agWhite
        }
      }
    ];
  };

  getContextHeaderButtons = () => {
    const { getLabel } = this.props;
    return this.hasCreatePermission()
      ? [
          {
            buttonStyleType: BUTTON_TYPE_PRIMARY,
            isDisabled: false,
            onClick: this.createNewUserLink(),
            buttonLabel: getLabel("INVITE_USER_TO_ORGANISATION")
          }
        ]
      : [];
  };

  isUserOverviewLoading = () => {
    const { selectedOrganisationUsers, selectedOrganisationUsersLoading } =
      this.props;
    return !selectedOrganisationUsers || selectedOrganisationUsersLoading;
  };

  getFilteredUsers = () => {
    const users = this.getOrganisationUsers();
    if (!users) return [];
    const { filterText } = this.state;
    const updatedUserObj = users.map((user) => {
      return {
        ...user,
        title: user.firstName,
        status: getUserStatusKey(user)
      };
    });

    return sortMethod(
      filterByValue(updatedUserObj, filterText),
      "status",
      this.getSortDirection()
    );
  };

  getOrgUsersTotal = () => {
    return this.getFilteredUsers().length;
  };

  getResultsSubtext = () => {
    const { getLabel } = this.props;
    const { currentPageNumber } = this.state;
    const pageLimit = this.getPageLimit();
    const total = this.getOrgUsersTotal();
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      currentPageNumber === 1
        ? currentPageNumber
        : (currentPageNumber - 1) * pageLimit + 1;

    const limitEndNumber = currentPageNumber * pageLimit;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;

    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  getSortDirection = () => this.state.sortDirection;

  toggleUsers = () => {
    this.setState({
      sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc"
    });
  };

  getTableHeader = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        { content: getLabel("FORM_FIRST_NAME_LABEL") },
        { content: getLabel("FORM_LAST_NAME_LABEL") },
        { content: getLabel("FORM_EMAIL_LABEL") },
        { content: getLabel("ROLE_TEXT") },
        {
          content: getLabel("FORM_STATUS_LABEL"),
          sort: {
            direction: this.getSortDirection(),
            onClick: this.toggleUsers,
            label: getLabel("SORT_LABEL", {
              item: getLabel("FORM_STATUS_LABEL")
            })
          }
        }
      ]
    };
  };

  getOrgUsersList = () => {
    const { currentPageNumber } = this.state;
    const orgUsersList = this.getFilteredUsers();
    const pageLimit = this.getPageLimit();
    if (!orgUsersList) return;
    const lastUser = currentPageNumber * pageLimit;
    const firstUser = lastUser - pageLimit;
    return orgUsersList.slice(firstUser, lastUser);
  };

  renderStatusLabel = (label, color) => {
    return (
      <Container direction={"row"} styleType={STYLETYPE_STATUS} dataTableStatus>
        <Icon
          type={"circle"}
          iconColor={color}
          bgShape="round"
          iconWidth="20px"
          iconHeight="20px"
        />
        <P styleType={STYLETYPE_STATUS}>{label}</P>
      </Container>
    );
  };

  getURL = (user) => {
    const { orgId } = this.props;
    const { userId, inviteId } = user;
    return inviteId
      ? `/${orgId}/${USERS_URL}/${INVITES_TEXT}/${inviteId}`
      : userId
      ? `/${orgId}/${USERS_URL}/${userId}`
      : null;
  };

  getUserTitle = (user) => {
    const { orgId, getHasPermission } = this.props;
    const { title, inviteId } = user;
    if (inviteId && !getHasPermission(orgId, INVITES_SECTION, READ_PERMISSION))
      return title;
    return (
      <LinkButton
        url={this.getURL(user)}
        buttonLabel={title}
        buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
      />
    );
  };

  getTableData = () => {
    const { getLabel } = this.props;
    const orgUsersList = this.getOrgUsersList();
    if (!orgUsersList) return;
    return orgUsersList.map((user) => {
      const { lastName, email, emailAddress, roleTitle } = user;
      const emailText = emailAddress ? emailAddress : email;
      const role = roleTitle ? roleTitle : getLabel("NO_ROLE_ASSIGNED_LABEL");

      const title = this.getUserTitle(user);

      const { label, color } = this.getUserStatusInfo(user.status);
      return {
        cells: [
          title,
          lastName,
          emailText,
          role,
          this.renderStatusLabel(label, color)
        ]
      };
    });
  };

  setPaginationContent = (value) => {
    this.setState({ currentPageNumber: value });
  };

  getCurrentPageNumber = () => {
    const { currentPageNumber } = this.state;
    return currentPageNumber;
  };

  showDropdown = () => {
    return this.getOrgUsersTotal() > this.getDropDownOptions()[0].value;
  };

  showPagination = () => {
    return this.getOrgUsersTotal() > this.getPageLimit();
  };

  getPaginationContent = () => {
    return {
      setPaginationContent: this.setPaginationContent,
      activeNumber: this.getCurrentPageNumber(),
      numberOfButtons: this.getPageLimit(),
      totalResults: this.getOrgUsersTotal(),
      showPagination: this.showPagination(),
      paginationInput: true
    };
  };

  dropDownOnSelectFunction = (value) => {
    this.setState({ currentPageNumber: 1 });
    storeSession(`users-${PAGINATION_KEY}`, value);
  };

  getPageLimit = () => {
    const result = getSession(`users-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit()
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getPageDropDown = () => {
    return {
      showDropdown: this.showDropdown(),
      dropDownValue: this.getPageLimit(),
      dropDownLabel: this.getDropDownLabel(),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: this.dropDownOnSelectFunction
    };
  };

  getFilterPlaceholderText = () => {
    const { getLabel } = this.props;
    return getLabel("USERS_FILTER_PLACEHOLDER_LABEL");
  };

  getNoResultsText = () => {
    const { getLabel } = this.props;
    const filterText = this.getFilterText();
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  render() {
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isUserOverviewLoading() === true ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper
            data-name={"Wrapper"}
            subStyle="App"
            styleType={CONTENT_VIEW}
          >
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
                buttons={this.getContextHeaderButtons()}
              />
            </Container>

            <Container>
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <DataTable
                  noResultsText={this.getNoResultsText()}
                  title={this.getSecondHeaderText()}
                  showResultsText={true}
                  resultsSubtext={this.getResultsSubtext()}
                  tableFilter={{
                    filterLabel: this.getFilterLabel(),
                    showFilter: true,
                    filterFunction: this.setFilterText,
                    isDisabled: false,
                    placeholderText: this.getFilterPlaceholderText(),
                    inputId: this.getFilterLabel()
                  }}
                  header={this.getTableHeader()}
                  rows={this.getTableData()}
                  pagination={this.getPaginationContent()}
                  pageDropDown={this.getPageDropDown()}
                />
              </Section>
              {this.hasCreatePermission() && (
                <BottomButtonBar buttons={this.getButtons()} />
              )}
            </Container>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default UserOverview;
