import React from "react";
import PropTypes from "prop-types";
import { ListContentItemElement, ListContentItemSpan } from "../style";
import ListContentItemList from "./ListContentItemList";
import { LinkButton, Button, Image, Icon } from "@core";

import { LINK_BUTTON, BUTTON_TYPE_LIST_CONTENT } from "@base/constants";

/**
 * Renders a ListContent Item, which can comprise of an Image, a header button, and series of child buttons.
 */
const ListContentItem = (props) => {
  const getItem = () => {
    if (props.buttonType === LINK_BUTTON)
      return props.url ? (
        <LinkButton
          buttonStyleType={BUTTON_TYPE_LIST_CONTENT}
          isDisabled={false}
          url={`${props.url}`}
          buttonLabel={props.headerLabel}
        />
      ) : (
        <ListContentItemSpan>{props.headerLabel}</ListContentItemSpan>
      );
    return (
      <Button
        buttonLabel={props.headerLabel}
        buttonStyleType={BUTTON_TYPE_LIST_CONTENT}
        onClick={() => {
          if (props.headerOnClick) {
            props.headerOnClick();
          }
        }}
        isDisabled={!props.headerOnClick}
      />
    );
  };
  return (
    <ListContentItemElement data-name="ListContentItemElement">
      {props.icon && (
        <Icon
          data-name={"Icon"}
          type={props.icon.type}
          spinning={props.icon.spinning}
          iconWidth={props.icon.iconWidth}
          iconHeight={props.icon.iconHeight}
          bgColor={props.icon.bgColor}
          iconColor={props.icon.iconColor}
          bgShape={props.icon.bgShape}
        />
      )}
      {props.imageSource && props.imageSource !== "" && (
        <Image source={props.imageSource} altText={props.altText} />
      )}
      {getItem()}
      <ListContentItemList childLinks={props.childLinks} />
    </ListContentItemElement>
  );
};

ListContentItem.propTypes = {
  /** Label for the main button (header) */
  headerLabel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Link that will navigate user to */
  headerOnClick: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
    PropTypes.string
  ]),
  /** url that will navigate user to */
  url: PropTypes.string,
  /** src for the image to display (optional) if empty no Image component will be rendered */
  imageSource: PropTypes.string,
  /** the Icon to display */
  icon: PropTypes.shape({
    bgColor: PropTypes.string,
    bgWidth: PropTypes.string,
    bgHeight: PropTypes.string,
    iconHeight: PropTypes.string,
    iconWidth: PropTypes.string,
    iconColor: PropTypes.string,
    spinning: PropTypes.bool,
    type: PropTypes.string,
    bgShape: PropTypes.string
  }),
  /** the Alt text that will be listed on the image */
  altText: PropTypes.string,
  /** the child links to render in the list item */
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      /** The label for the childs button  */
      buttonLabel: PropTypes.string,
      /** The link for the childs button (optional) if not supplied, then button will be disabled and render just a text  */
      onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      /** The icon for the childs button (optional) if ommitted, link will be rendered without an icon */
      iconType: PropTypes.string
    })
  ),
  /** buttonType is linkButton renders Link otherwise Button */
  buttonType: PropTypes.string
};

export default ListContentItem;
