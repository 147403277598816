import { connect } from "react-redux";
import Login from "./Login";
import * as actions from "../../actions";
import { getLabels, getLoadingText } from "../../selectors";

export default connect(
  (state) => ({
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    loadingText: getLoadingText(state)
  }),
  actions
)(Login);
