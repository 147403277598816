import styled, { css } from "styled-components";
import { ButtonLabel, ButtonElement } from "../../core/Button/style";
import { IconSymbol, IconBackground } from "../../core/Icon/style";

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: nowrap;
  justify-content: center;
`;
PaginationWrapper.displayName = "PaginationWrapper";

export const PaginationContainer = styled.div`
  min-width: 16.25rem;
`;
PaginationContainer.displayName = "PaginationContainer";

export const PaginationPageList = styled.ul`
  display: flex;
  flex-flow: nowrap;
`;
PaginationPageList.displayName = "PaginationPageList";

export const PaginationButton = styled.li`
  & > ${ButtonElement} {
    height: 2.5rem;
    width: ${(props) =>
      props.page > 999 ? "4rem" : props.page > 99 ? "3.2rem" : "2.5rem"};
    border: none;
    margin-left: 0.3rem;
    box-shadow: 0rem 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
    background: ${(props) =>
      `${props.theme.agWhite} 0% 0% no-repeat padding-box`};

    > ${ButtonLabel} {
      font-size: 1.5rem;
      color: ${(props) => props.theme.agDarkerGreen};
    }

    ${(props) =>
      props.styleType === true &&
      css`
        border: none;
        background: none;
        box-shadow: none;
        margin: 0.5rem -0.5rem;
        > ${ButtonLabel} {
          color: ${(props) => props.theme.agMischkaGray};
        }
      `}

    ${(props) =>
      props.styleType !== true &&
      css`
        &:hover {
          border: 1px solid ${(props) => props.theme.agWhite};
          background: ${(props) =>
            `${props.theme.agLighterGreen} 0% 0% no-repeat padding-box`};

          ${IconSymbol} {
            background-color: ${(props) => props.theme.agWhite};
          }

          > ${ButtonLabel} {
            color: ${(props) => props.theme.agWhite};
          }
        }
      `}
 

    ${(props) =>
      props.active &&
      css`
        box-shadow: none;
        border: 1px solid ${props.theme.agMischkaGray};
        background: ${(props) =>
          `${props.theme.agSmokeWhite} 0% 0% no-repeat padding-box`};

        > ${ButtonLabel} {
          color: ${(props) => props.theme.agCommonBlack};
        }
      `}
  }
`;

PaginationButton.displayName = "PaginationButton";
