import styled, { css } from "styled-components";
import triangles from "../../icons/triangles-icon.svg";
import { ButtonElement } from "../../core/Button/style";

export const BottomButtonWrapper = styled.div`
  background: url(${triangles});
  background-repeat: no-repeat;
  background-position: 100% 20%;
  background-size: 9.375rem 9.375rem;
`;

BottomButtonWrapper.displayName = "BottomButtonWrapper";

export const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
  padding-top: 12rem;

  ${ButtonElement} {
    width: inherit;

    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

BottomButtonContainer.displayName = "BottomButtonContainer";
