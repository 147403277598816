export const filterList = (list, filterText, field = "title") => {
  try {
    if (!list) throw "No list provided";
    if (!filterText) return list;
    return list.filter((listItem) => {
      return (
        listItem[field] &&
        String(listItem[field])
          .toLowerCase()
          .indexOf(String(filterText).toLowerCase()) > -1
      );
    });
  } catch (e) {
    if (process.env.NODE_ENV === "development") console.log(e);
    throw new Error(e);
  }
};

export const filterByValue = (list, filterText) => {
  try {
    if (!list) throw "No list provided";
    if (!filterText) return list;
    const filedsToCheck = ["title", "firstName", "lastName", "emailAddress"];
    return list.filter((o) =>
      filedsToCheck.some((k) =>
        String(o[k]).toLowerCase().includes(filterText.toLowerCase())
      )
    );
  } catch (e) {
    if (process.env.NODE_ENV === "development") console.log(e);
    throw new Error(e);
  }
};
