import { connect } from "react-redux";
import ImportDatasets from "./ImportDatasets";
import * as actions from "../../../actions";
import {
  getLabels,
  getSelectedPropertyStatus,
  getSelectedProperty,
  getAbortController,
  getPageTitle,
  getToken,
  getSelectedOrgPropertyGroups,
  getSelectedOrgPropertyGroupsLoading,
  getSelectedOrgDatasets,
  getSelectedOrgDatasetsLoading
} from "../../../selectors";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    status: getSelectedPropertyStatus(state),
    selectedProperty: getSelectedProperty(state),
    abortController: getAbortController(state),
    token: getToken(state),
    organisationPropertyGroups: getSelectedOrgPropertyGroups(state),
    organisationPropertyGroupsLoading:
      getSelectedOrgPropertyGroupsLoading(state),
    organisationDatasets: getSelectedOrgDatasets(state),
    organisationDatasetsLoading: getSelectedOrgDatasetsLoading(state)
  }),
  actions
)(ImportDatasets);
