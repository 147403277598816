import { createLogic } from "redux-logic";
import {
  LOAD_USER,
  LOAD_USER_MEMBERSHIP,
  CREATE_INVITE,
  RESEND_INVITE,
  REMOVE_INVITE,
  LOAD_USER_PROPERTIES_MEMBERSHIP,
  LOAD_USER_PROPERTY_GROUPS,
  UPDATE_USER_MEMBERSHIP,
  REMOVE_USER_MEMBERSHIP,
  ABORT_ERROR_NAME,
  LOAD_INVITED_USER
} from "@base/constants";

const loadUserLogic = createLogic({
  type: LOAD_USER,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText } = globalActions;
      const { LOADING_USER_TEXT } = getLabels(getState());
      dispatch(setLoadingText(LOADING_USER_TEXT));
      const token = getToken(state);
      const userId = action.payload;

      const { requestUser } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const user = await requestUser(userId, token, signal);
      const { loadedUser } = globalActions;
      dispatch(loadedUser(user));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadUserMembershipLogic = createLogic({
  type: LOAD_USER_MEMBERSHIP,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedUserMembership } = globalActions;
      const { LOADING_USER_TEXT } = getLabels(getState());
      dispatch(setLoadingText(LOADING_USER_TEXT));
      const token = getToken(state);
      const { orgId, userId } = action.payload;
      if (orgId && userId) {
        const { requestUserMembership } = agBoxApiRequests;
        const signal = getAbortSignal(getState());
        const user = await requestUserMembership(orgId, userId, token, signal);
        dispatch(loadedUserMembership(user));
      }
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const createInviteLogic = createLogic({
  type: CREATE_INVITE,
  async process(
    { globalActions, agBoxApiCreates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, createdInvite } = globalActions;
      const { CREATING_LABEL } = getLabels(getState());
      const { createUserInvite } = agBoxApiCreates;
      const { data, orgId, sendEmail } = action.payload;
      const signal = getAbortSignal(getState());
      const token = getToken(state);
      dispatch(setLoadingText(CREATING_LABEL));
      const body = JSON.stringify(data);

      const { inviteId } = await createUserInvite(
        orgId,
        body,
        sendEmail,
        token,
        signal
      );

      dispatch(createdInvite(inviteId));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { createInviteError } = globalActions;
      dispatch(createInviteError(e));
      done();
    }
  }
});

const resendInviteLogic = createLogic({
  type: RESEND_INVITE,
  async process(
    { globalActions, agBoxApiCreates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, resendedInvite } = globalActions;
      const { CREATING_LABEL } = getLabels(getState());
      const { resendUserInvite } = agBoxApiCreates;
      const { data, orgId, inviteId, sendEmail } = action.payload;
      const signal = getAbortSignal(getState());
      const token = getToken(state);
      dispatch(setLoadingText(CREATING_LABEL));
      const body = JSON.stringify(data);

      await resendUserInvite(orgId, inviteId, sendEmail, body, token, signal);
      dispatch(resendedInvite());

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadUserPropertiesMembership = createLogic({
  type: LOAD_USER_PROPERTIES_MEMBERSHIP,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getAbortSignal, getLabels, getToken } = selectors;
      const { setLoadingText } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));
      const token = getToken(state);
      const { userId, orgId } = action.payload;
      const { requestUserMembershipProperties } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const userProperties = await requestUserMembershipProperties(
        orgId,
        userId,
        token,
        signal
      );

      const { loadedUserPropertiesMembership } = globalActions;

      dispatch(loadedUserPropertiesMembership(userProperties));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadUserPropertyGroups = createLogic({
  type: LOAD_USER_PROPERTY_GROUPS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getAbortSignal, getLabels, getToken } = selectors;
      const { setLoadingText } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));
      const token = getToken(state);
      const { userId, orgId } = action.payload;
      const { requestUserMembershipPropertyGroup } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const userPropertyGroups = await requestUserMembershipPropertyGroup(
        orgId,
        userId,
        token,
        signal
      );

      const { loadedUserPropertyGroups } = globalActions;

      dispatch(loadedUserPropertyGroups(userPropertyGroups));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const updateUserMembershipLogic = createLogic({
  type: UPDATE_USER_MEMBERSHIP,
  async process(
    { globalActions, agBoxApiUpdates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getAbortSignal, getLabels, getToken } = selectors;
      const {
        setLoadingText,
        updatedUserMembership,
        loadUserPropertiesMembership,
        loadOrganisationPropertyGroups
      } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));
      const token = getToken(state);
      const { userId, orgId, data } = action.payload;

      if (userId && orgId && data) {
        const { partiallyUpdateMembership } = agBoxApiUpdates;
        const signal = getAbortSignal(getState());
        await partiallyUpdateMembership(orgId, userId, data, token, signal);
        dispatch(updatedUserMembership());
        dispatch(loadUserPropertiesMembership(orgId, userId));
        dispatch(loadOrganisationPropertyGroups(orgId));
      }
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError, updateUserMembershipFailed } = globalActions;
      dispatch(setError(e));
      dispatch(updateUserMembershipFailed());
      done();
    }
  }
});

const removeUserMembershipLogic = createLogic({
  type: REMOVE_USER_MEMBERSHIP,
  async process(
    { globalActions, agBoxApiRemoves, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getAbortSignal, getLabels, getToken } = selectors;
      const { setLoadingText, removedUserMembership } = globalActions;
      const { LOADING_LABEL } = getLabels(state);

      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);

      const { userId, orgId } = action.payload;

      if (!userId || !orgId) return done();
      const { removeUserMembership } = agBoxApiRemoves;
      const signal = getAbortSignal(getState());
      await removeUserMembership(orgId, userId, token, signal);

      dispatch(removedUserMembership());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadInvitedUserLogic = createLogic({
  type: LOAD_INVITED_USER,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText } = globalActions;
      const { LOADING_USER_TEXT } = getLabels(getState());
      dispatch(setLoadingText(LOADING_USER_TEXT));
      const token = getToken(state);
      const { orgId, inviteId } = action.payload;

      const { requestOrganisationInvitedUser } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const invitedUserDetails = await requestOrganisationInvitedUser(
        orgId,
        inviteId,
        token,
        signal
      );
      const { loadedInvitedUser } = globalActions;
      dispatch(loadedInvitedUser(invitedUserDetails));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const removeInviteLogic = createLogic({
  type: REMOVE_INVITE,
  async process(
    { globalActions, agBoxApiRemoves, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getAbortSignal, getLabels, getToken } = selectors;
      const { setLoadingText, removedInvite } = globalActions;
      const { LOADING_LABEL } = getLabels(state);

      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);

      const { orgId, inviteId } = action.payload;

      if (!orgId || !inviteId) return done();
      const { removeInvite } = agBoxApiRemoves;
      const signal = getAbortSignal(getState());
      await removeInvite(orgId, inviteId, token, signal);

      dispatch(removedInvite());
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

export default [
  loadUserLogic,
  loadUserMembershipLogic,
  createInviteLogic,
  resendInviteLogic,
  loadUserPropertiesMembership,
  loadUserPropertyGroups,
  updateUserMembershipLogic,
  removeUserMembershipLogic,
  loadInvitedUserLogic,
  removeInviteLogic
];
