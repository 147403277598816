import React, { Component } from "react";

import {
  Loader,
  ContextHeader,
  BottomButtonBar,
  defaultTheme,
  DataTable
} from "@UIKit";
import { getSession, storeSession, filterList } from "@base/common";
import { Heading3, P } from "@typography";
import { Section, Container, Wrapper, LinkButton } from "@core";
import { navigate } from "@reach/router";
import {
  PROPERTIES_URL,
  USERS_URL,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  BUTTON_TYPE_SECONDARY,
  PROPERTY_GROUPS_URL,
  STATUS_LOADING,
  UPDATE_URL,
  STATUS_REMOVING,
  STATUS_READY,
  LINK_BUTTON,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  MINIMUM_SEARCH_STRING_LENGTH,
  SEARCH_TIMEOUT,
  PROPERTY_GROUPS_SECTION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
  READ_DETAILS_PERMISSION,
  PROPERTIES_SECTION,
  READ_PERMISSION,
  USERS_SECTION,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  SECTION_HEADER_STYLING,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL
} from "@base/constants";

class PropertyGroupDetail extends Component {
  constructor(props) {
    super(props);
    this.searchTimeout = null;
    this.state = {
      propertySearchText: "",
      searchTimeout: null
    };
  }

  componentDidMount() {
    this.loadGroup();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  loadGroup = () => {
    const { orgId, propertyGroupId, loadPropertyGroup } = this.props;
    loadPropertyGroup(orgId, propertyGroupId, false);
    this.loadGroupUsers();
    this.loadPropertyGroupProperties();
  };

  loadGroupUsers = (
    page = 1,
    offset = 0,
    limit = this.getSelectedGroupUsersLimit(),
    searchText = ""
  ) => {
    const {
      orgId,
      propertyGroupId,
      loadPropertyGroupUsers,
      setGroupUsersPagination
    } = this.props;
    if (!this.getHasPermission(USERS_SECTION, READ_PERMISSION)) return;
    loadPropertyGroupUsers(orgId, propertyGroupId);
    setGroupUsersPagination(page, limit, offset, searchText);
  };

  componentDidUpdate(prevProps) {
    const {
      setPageTitle,
      pageTitle,
      selectedPropertyGroup,
      selectedPropertyGroupStatus
    } = this.props;

    if (pageTitle !== selectedPropertyGroup.title) {
      setPageTitle(selectedPropertyGroup.title);
    }

    if (
      prevProps.selectedPropertyGroupStatus === STATUS_REMOVING &&
      selectedPropertyGroupStatus === STATUS_READY
    ) {
      this.showDeleteSuccess();
    }
  }

  getSelectedPropertyGroupUsers = () => {
    const { selectedPropertyGroupUsers } = this.props;
    return selectedPropertyGroupUsers ? selectedPropertyGroupUsers : [];
  };

  getProperties = () => {
    const { properties } = this.props;
    return properties ? properties : false;
  };

  getSelectedPropertyGroupUsersTotal = () => {
    const { selectedPropertyGroupUsersTotal } = this.props;
    return selectedPropertyGroupUsersTotal
      ? selectedPropertyGroupUsersTotal
      : false;
  };

  getSelectedPropertyGroupProperties = () => {
    const { selectedPropertyGroupProperties } = this.props;
    return selectedPropertyGroupProperties
      ? selectedPropertyGroupProperties
      : false;
  };

  getCurrentPropertyGroup = () => {
    const { selectedPropertyGroup } = this.props;
    return selectedPropertyGroup ? selectedPropertyGroup : false;
  };

  getCurrentPropertyGroupDescription = () => {
    return this.getCurrentPropertyGroup()
      ? this.getCurrentPropertyGroup().description
      : false;
  };

  getCurrentPropertyGroupUsers = () => {
    const { orgId, getLabel, selectedPropertyGroupUsersIsLoading } = this.props;

    if (selectedPropertyGroupUsersIsLoading) {
      return [
        {
          icon: {
            type: "loading",
            spinning: true,
            iconWidth: "25px",
            iconHeight: "25px",
            bgColor: defaultTheme.agBrightGreen,
            iconColor: defaultTheme.agWhite,
            bgShape: "round"
          },
          headerLabel: getLabel("LOADING_LABEL"),
          childLinks: []
        }
      ];
    }

    const propertyGroupUsers = this.getSelectedPropertyGroupUsers();
    if (propertyGroupUsers.length === 0)
      return [
        {
          headerLabel: getLabel("NO_DATA_FOUND_TEXT", {
            dataItem: getLabel("USERS_TEXT")
          }),
          childLinks: []
        }
      ];

    const listOfUsers = propertyGroupUsers.map((user) => {
      const { firstName, lastName } = user;
      return {
        buttonType: LINK_BUTTON,
        url: `/${orgId}/${USERS_URL}/${user.userId}`,
        headerLabel: `${firstName} ${lastName}`,
        childLinks: []
      };
    });
    return listOfUsers;
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return this.getCurrentPropertyGroup() &&
      this.getCurrentPropertyGroup().title
      ? getLabel("PROPERTY_GROUP_HEADING_TEXT", {
          title: this.getCurrentPropertyGroup().title
        })
      : getLabel("PROPERTY_GROUPS_TEXT");
  };

  isPropertyGroupDetailsLoading = () => {
    const { selectedPropertyGroup, selectedPropertyGroupStatus } = this.props;
    return (
      selectedPropertyGroup === false ||
      selectedPropertyGroupStatus === STATUS_LOADING
    );
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    const childLinks = [];
    if (this.getHasPermission(PROPERTY_GROUPS_SECTION, UPDATE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.updatePropertyGroupAction,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("PROPERTY_GROUP_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      });
    }
    if (this.getHasPermission(PROPERTY_GROUPS_SECTION, DELETE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.showDeleteConfirmation,
        buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
          item: getLabel("PROPERTY_GROUP_TEXT")
        })
      });
    }
    return {
      childLinks,
      isDisabled: false,
      visible: childLinks.length ? true : false
    };
  };

  showDeleteConfirmation = () => {
    const { showModal, setModalContent, getLabel, hideModal } = this.props;

    setModalContent({
      header: getLabel("CONFIRM_DELETE_HEADER", {
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      body: getLabel("CONFIRM_DELETE_BODY", {
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("CONFIRM_DELETE_BUTTON", {
            item: getLabel("PROPERTY_GROUP_TEXT")
          }),
          onClick: this.deletePropertyGroup
        },
        {
          buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
          onClick: hideModal
        }
      ]
    });
    showModal();
  };

  deletePropertyGroup = () => {
    const { removePropertyGroup, orgId, propertyGroupId } = this.props;
    removePropertyGroup(orgId, propertyGroupId);
    this.showLoading();
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("DELETE_ACTION_LABEL"),
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
  };

  updatePropertyGroupAction = () => {
    const { hideModal, orgId, propertyGroupId } = this.props;

    hideModal();
    navigate(
      `/${ORGANISATION_URL}/${orgId}/${PROPERTY_GROUPS_URL}/${propertyGroupId}/${UPDATE_URL}`
    );
  };

  returnToPropertyGroupOverview = () => {
    const { orgId } = this.props;
    navigate(`/${ORGANISATION_URL}/${orgId}/${PROPERTY_GROUPS_URL}`);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        isDisabled: false,
        onClick: this.returnToPropertyGroupOverview,
        buttonLabel: getLabel("BACK_TO_OVERVIEW_BUTTON", {
          item: getLabel("PROPERTY_GROUPS_TEXT")
        })
      }
    ];
  };

  showDeleteSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_DELETED_LABEL", {
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      body: getLabel("ITEM_DELETED_SUCCESS", {
        item: getLabel("PROPERTY_GROUP_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("PROPERTY_GROUP_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  returnToOverviewAction = () => {
    const { hideModal } = this.props;

    hideModal();
    navigate("./");
  };

  getPropertyTableData = () => {
    const { orgId } = this.props;
    const propertyList = this.getProperties();
    if (!propertyList) return [];
    return propertyList.map((property) => {
      return {
        cells: [
          <LinkButton
            key={property.propId}
            buttonLabel={property.title}
            url={`/${orgId}/${PROPERTIES_URL}/${property.propId}`}
            buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
          />,
          property.officialName
        ]
      };
    });
  };

  getPropertyResultsSubtext = () => {
    if (this.getPropertiesLoading()) return "";
    const { getLabel } = this.props;
    const pageLimit = this.getPropertyPageLimit();
    const currentPageNumber = this.getPropertyPageNumber();
    const total = this.getPropertiesTotal();
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      currentPageNumber === 1
        ? currentPageNumber
        : (currentPageNumber - 1) * pageLimit + 1;

    const limitEndNumber = currentPageNumber * pageLimit;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;

    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  getSelectedGroupUsersPageNumber = () => {
    const { selectedGroupUsersPageNumber } = this.props;
    return selectedGroupUsersPageNumber ? selectedGroupUsersPageNumber : false;
  };

  getSelectedGroupUsersLimit = () => {
    const result = getSession(`property-group-users-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getSelectedGroupUsersSearch = () => {
    const { selectedGroupUsersSearch } = this.props;
    return selectedGroupUsersSearch ? selectedGroupUsersSearch : false;
  };

  getUsersNoResultsText = () => {
    const { getLabel } = this.props;
    const filterText = this.getSelectedGroupUsersSearch();
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getUsersResultsSubtext = () => {
    const { getLabel } = this.props;
    const pageLimit = this.getSelectedGroupUsersLimit();
    const currentPageNumber = this.getSelectedGroupUsersPageNumber();
    const total = this.getSelectedPropertyGroupUsersTotal();
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      currentPageNumber === 1
        ? currentPageNumber
        : (currentPageNumber - 1) * pageLimit + 1;

    const limitEndNumber = currentPageNumber * pageLimit;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;
    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  getPropertyFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("PROPERTIES_TEXT")
    });
  };

  setPropertySearchText = (propertySearchText) => {
    this.setState({
      propertySearchText
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    if (
      propertySearchText.length >= MINIMUM_SEARCH_STRING_LENGTH ||
      propertySearchText === ""
    ) {
      this.searchTimeout = setTimeout(() => {
        this.loadPropertyGroupProperties(
          1,
          0,
          this.getPropertyPageLimit(),
          propertySearchText
        );
      }, SEARCH_TIMEOUT);
    }
  };

  setUsersPaginationContent = (value, searchValue) => {
    const pageLimit = this.getSelectedGroupUsersLimit();
    let offset = (value - 1) * pageLimit;
    this.loadGroupUsers(value, offset, pageLimit, searchValue);
  };

  setUsersFilterText = (value) => {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearInterval(searchTimeout);
    }

    if (value.length >= MINIMUM_SEARCH_STRING_LENGTH || value === "") {
      const searchTimeout = setTimeout(() => {
        this.setUsersPaginationContent(1, value);
      }, SEARCH_TIMEOUT);
      this.setState({ searchTimeout });
    }
  };

  getPropertiesLoading = () => {
    const { propertiesLoading } = this.props;
    return propertiesLoading;
  };

  getPropertyPageNumber = () => {
    const { propertiesPagination } = this.props;
    if (!propertiesPagination || !propertiesPagination.page) return 1;
    return propertiesPagination.page;
  };

  getPropertyPageLimit = () => {
    const result = getSession(`property-group-properties-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getPropertiesTotal = () => {
    const { propertiesTotal } = this.props;
    return propertiesTotal;
  };

  showPropertyPagination = () => {
    return this.getPropertiesTotal() > this.getPropertyPageLimit();
  };

  loadPropertyGroupProperties = (
    page = 1,
    offset = 0,
    limit = this.getPropertyPageLimit(),
    search = ""
  ) => {
    if (!this.getHasPermission(PROPERTIES_SECTION, READ_PERMISSION)) return;
    const { orgId, propertyGroupId, loadPropertyGroupProperties } = this.props;
    loadPropertyGroupProperties(
      orgId,
      propertyGroupId,
      page,
      offset,
      limit,
      search
    );
  };

  handleUpdatePropertyPage = (page, searchValue) => {
    const pageLimit = this.getPropertyPageLimit();
    let offset = (page - 1) * pageLimit;
    this.loadPropertyGroupProperties(page, offset, pageLimit, searchValue);
  };

  getPropertyPaginationContent = () => {
    return {
      setPaginationContent: this.handleUpdatePropertyPage,
      activeNumber: this.getPropertyPageNumber(),
      numberOfButtons: this.getPropertyPageLimit(),
      totalResults: this.getPropertiesTotal(),
      showPagination: this.showPropertyPagination()
    };
  };
  getHasPermission = (section, type) => {
    const { orgId, getHasPermission } = this.props;
    return getHasPermission(orgId, section, type);
  };

  getUserRows = () => {
    const { orgId } = this.props;
    const usersList = this.getSelectedPropertyGroupUsers();
    if (!usersList) return;
    return usersList.map((user) => {
      const userName = `${user.firstName || ""} ${user.lastName || ""}`;
      return {
        cells: [
          <LinkButton
            key={user.userId}
            buttonLabel={userName}
            url={`/${orgId}/${USERS_URL}/${user.userId}`}
            buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
          />
        ]
      };
    });
  };

  showUsersPagination = () => {
    return (
      this.getSelectedPropertyGroupUsersTotal() >
      this.getSelectedGroupUsersLimit()
    );
  };

  getUsersPaginationContent = () => {
    return {
      setPaginationContent: this.setUsersPaginationContent,
      activeNumber: this.getSelectedGroupUsersPageNumber(),
      numberOfButtons: this.getSelectedGroupUsersLimit(),
      totalResults: this.getSelectedPropertyGroupUsersTotal(),
      showPagination: this.showUsersPagination(),
      paginationInput: true
    };
  };

  showPropertyDropdown = () => {
    return this.getPropertiesTotal() > this.getDropDownOptions()[0].value;
  };

  getPropertyDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPropertyPageLimit()
    });
  };

  handleUpdatePropertyPageLimit = (limit) => {
    this.loadPropertyGroupProperties(1, 0, limit, this.getPropertySearchText());
    storeSession(`property-group-properties-${PAGINATION_KEY}`, limit);
  };

  getPropertySearchText = () => {
    const { propertySearchText } = this.state;
    return propertySearchText;
  };

  getPropertyPageDropDownInfo = () => {
    return {
      showDropdown: this.showPropertyDropdown(),
      dropDownValue: this.getPropertyPageLimit(),
      dropDownLabel: this.getPropertyDropDownLabel(),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: this.handleUpdatePropertyPageLimit
    };
  };

  getNoPropertyResultsText = () => {
    const { getLabel } = this.props;
    const filterText = this.getPropertySearchText();
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  showUsersDropdown = () => {
    return (
      this.getSelectedPropertyGroupUsersTotal() >
      this.getDropDownOptions()[0].value
    );
  };

  updateUsersPerPage = (value) => {
    this.loadGroupUsers(1, 0, value, "");
    storeSession(`property-group-users-${PAGINATION_KEY}`, value);
  };

  getDropDownLabel = (limit) => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: limit
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getUsersPageDropDown = () => {
    return {
      showDropdown: this.showUsersDropdown(),
      dropDownValue: this.getSelectedGroupUsersLimit(),
      dropDownLabel: this.getDropDownLabel(this.getSelectedGroupUsersLimit()),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: this.updateUsersPerPage
    };
  };

  getGroupUsersIsLoading = () => {
    const { selectedPropertyGroupUsersIsLoading } = this.props;
    return selectedPropertyGroupUsersIsLoading === true;
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isPropertyGroupDetailsLoading() === true ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={this.getContextMenu()}
              />
            </Container>
            <Section>
              <Heading3 styleType={SECTION_HEADER_STYLING}>
                {getLabel("PROPERTY_GROUP_DESCRIPTION_HEADING_TEXT")}
              </Heading3>
              <P>{this.getCurrentPropertyGroupDescription()}</P>
            </Section>

            {this.getHasPermission(USERS_SECTION, READ_PERMISSION) && (
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <DataTable
                  noResultsText={this.getUsersNoResultsText()}
                  filterPlaceholderText={getLabel(
                    "USERS_FILTER_PLACEHOLDER_LABEL"
                  )}
                  title={getLabel("USERS_TEXT")}
                  showResultsText={true}
                  resultsSubtext={this.getUsersResultsSubtext()}
                  tableFilter={{
                    filterLabel: getLabel("GENERIC_ACTION_MESSAGE", {
                      action: getLabel("FILTER_ACTION_LABEL"),
                      item: getLabel("USERS_TEXT")
                    }),
                    showFilter: false,
                    filterFunction: this.setUsersFilterText,
                    isDisabled: false,
                    searchText: this.getSelectedGroupUsersSearch()
                  }}
                  header={{
                    cells: [{ content: getLabel("USERS_TEXT") }]
                  }}
                  rows={this.getUserRows()}
                  pagination={this.getUsersPaginationContent()}
                  pageDropDown={this.getUsersPageDropDown()}
                  loading={this.getGroupUsersIsLoading()}
                />
              </Section>
            )}

            {this.getHasPermission(PROPERTIES_SECTION, READ_PERMISSION) && (
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <DataTable
                  showResultsText={true}
                  title={getLabel("PROPERTIES_TEXT")}
                  resultsSubtext={this.getPropertyResultsSubtext()}
                  tableFilter={{
                    filterLabel: this.getPropertyFilterLabel(),
                    showFilter: true,
                    filterFunction: this.setPropertySearchText,
                    isDisabled: false,
                    propertySearchText: this.getPropertySearchText(),
                    placeholderText: getLabel(
                      "PROPERTIES_FILTER_PLACEHOLDER_LABEL"
                    ),
                    inputId: this.getPropertyFilterLabel()
                  }}
                  noResultsText={this.getNoPropertyResultsText()}
                  header={{
                    cells: [
                      { content: getLabel("PROPERTY_TITLE_LABEL") },
                      { content: getLabel("OFFICIAL_NAME_LABEL") }
                    ]
                  }}
                  rows={this.getPropertyTableData()}
                  loading={this.getPropertiesLoading()}
                  pagination={this.getPropertyPaginationContent()}
                  pageDropDown={this.getPropertyPageDropDownInfo()}
                />
              </Section>
            )}
            <BottomButtonBar buttons={this.getButtons()} />
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default PropertyGroupDetail;
