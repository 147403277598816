import React from "react";
import PropTypes from "prop-types";
import { Button } from "@core";
import { DROPDOWN_LIST_ID, DROPDOWN_LIST_ITEM_ID } from "@base/constants";
import { Container } from "@core";

/**
 * Returns a array of Button components
 * @public
 */
const renderChildren = (childLinks, onClick) => {
  if (!childLinks) return;
  return childLinks
    .filter((item) => {
      return item.label && item.label !== "";
    })
    .map((item) => {
      return (
        <Button
          data-name={"DropdownListButton"}
          buttonStyleType={DROPDOWN_LIST_ITEM_ID}
          onClick={() => {
            // skipcq JS-0417
            onClick(item);
          }}
          buttonLabel={item.label}
          key={`key-${item.label}-${item.value}`}
        />
      );
    });
};

/**
 * Renders a list of buttons for context menu
 */

const DropdownList = (props) => {
  return (
    <Container data-name={"DropdownList"} styleType={DROPDOWN_LIST_ID}>
      {renderChildren(props.options, props.onClick)}
    </Container>
  );
};

DropdownList.propTypes = {
  /** Function to run when an option is clicked */
  onClick: PropTypes.func,
  /** An array of children to render as buttons */
  options: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        /** The label for the childs button  */
        label: PropTypes.string,
        /** The value for the childs button  */
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    )
  ])
};

export default DropdownList;
