import React from "react";
import { SectionElement } from "./style";
import PropTypes from "prop-types";

/** Renders a Section to hold content */
const Section = (props) => {
  return (
    <SectionElement
      data-name={"SectionElement"}
      styleType={props.styleType ? props.styleType : ""}
    >
      {props.children}
    </SectionElement>
  );
};

Section.propTypes = {
  styleType: PropTypes.string,
  /** children */
  children: PropTypes.node
};

export default Section;
