import { connect } from "react-redux";
import UserDetail from "./UserDetail";
import * as actions from "../../../actions";
import {
  getLabels,
  getPageTitle,
  getSelectedUserStatus,
  getSelectedUser,
  getAbortController,
  getHasPermission
} from "../../../selectors";

export default connect(
  (state) => ({
    propState: state,
    pageTitle: getPageTitle(state),
    selectedUserStatus: getSelectedUserStatus(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedUserMembership: getSelectedUser(state),
    abortController: getAbortController(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType)
  }),
  actions
)(UserDetail);
