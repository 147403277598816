export const getSelectedRole = (state) => {
  return state.role && state.role.available ? state.role.available : false;
};
export const getSelectedRoleStatus = (state) => {
  return state.role && state.role.status;
};

export const getSelectedRoleWorkflows = (state) => {
  return state.role &&
    state.role.selectedRoleWorkflows &&
    state.role.selectedRoleWorkflows.items
    ? state.role.selectedRoleWorkflows.items
    : false;
};

export const getSelectedRoleWorkflowsStatus = (state) => {
  return state.role &&
    state.role.selectedRoleWorkflows &&
    state.role.selectedRoleWorkflows.status
    ? state.role.selectedRoleWorkflows.status
    : false;
};

export const getSelectedRoleUsers = (state) => {
  return state.role &&
    state.role.selectedRoleUsers &&
    state.role.selectedRoleUsers.items
    ? state.role.selectedRoleUsers.items
    : false;
};

export const getSelectedRoleUsersStatus = (state) => {
  return state.role &&
    state.role.selectedRoleUsers &&
    state.role.selectedRoleUsers.status
    ? state.role.selectedRoleUsers.status
    : false;
};

export const getSelectedRoleUsersTotal = (state) => {
  return state.role &&
    state.role.selectedRoleUsers &&
    state.role.selectedRoleUsers.meta &&
    state.role.selectedRoleUsers.meta.total
    ? state.role.selectedRoleUsers.meta.total
    : false;
};
