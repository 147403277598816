import { connect } from "react-redux";
import PropertyOverview from "./PropertyOverview";
import * as actions from "../../../actions";
import {
  getLabels,
  getAvailableOrgs,
  getSelectedOrgProperties,
  getSelectedOrgPropertiesTotal,
  getSelectedOrgPropertiesLoading,
  getOrgArchivedProperties,
  getOrgArchivedPropertiesTotal,
  getOrgArchivedPropertiesLoading,
  getAbortController,
  getOrgPropertiesOffset,
  getOrgPropertiesSearch,
  getOrgPropertiesPageNumber,
  getOrgArchivedPropsPageNumber,
  getOrgArchivedPropsOffset,
  getOrgArchivedPropsSearch,
  getPageTitle,
  getHasPermission
} from "../../../selectors";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    availableOrganisations: getAvailableOrgs(state),
    selectedOrganisationProperties: getSelectedOrgProperties(state),
    selectedOrgPropertiesTotal: getSelectedOrgPropertiesTotal(state),
    selectedOrganisationPropertiesLoading:
      getSelectedOrgPropertiesLoading(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    abortController: getAbortController(state),
    selectedOrgPropertiesOffset: getOrgPropertiesOffset(state),
    selectedOrgPropertiesSearch: getOrgPropertiesSearch(state),
    selectedOrgPropertiesPageNumber: getOrgPropertiesPageNumber(state),
    selectedOrgArchivedPropsPage: getOrgArchivedPropsPageNumber(state),
    selectedOrgArchivedPropsOffset: getOrgArchivedPropsOffset(state),
    selectedOrgArchivedPropsSearch: getOrgArchivedPropsSearch(state),
    selectedOrgArchivedProperties: getOrgArchivedProperties(state),
    selectedOrgArchivedPropertiesTotal: getOrgArchivedPropertiesTotal(state),
    selectedOrgArchivedPropertiesLoading:
      getOrgArchivedPropertiesLoading(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType)
  }),
  actions
)(PropertyOverview);
