import styled, { css } from "styled-components";
import { FormLabel } from "@core";

// @ts-ignore
export const RadioButtonElement = styled.input`
  &[type="radio"] {
    appearance: none;
    width: 1.375rem;
    height: 1.375rem;
    background: #d9d9d9;
    box-shadow: inset 0 0 0 0.2em white, 0 0 0 0.1em;
    border-radius: 50%;
    transition: 0.1s;
    cursor: pointer;
    vertical-align: text-top;

    &:hover,
    &:checked {
      background: ${(props) => props.theme.agLightGray};
      box-shadow: inset 0 0 0 0.2em white, 0 0 0 0.1em;
    }

    &:checked {
      background: #56be8e;
      box-shadow: inset 0 0 0 0.2em #ffffff, 0 0 0 0.1em #00ab70;
    }

    &:focus {
      outline: 0;
    }
  }

  margin-right: 1em;

  margin-left: ${(props) => {
    return props.direction === "horizontal" ? "1rem" : "0rem";
  }};

  & & {
    margin-bottom: ${(props) => {
      return props.direction === "horizontal" ? "0rem" : "1rem";
    }};
  }
`;
RadioButtonElement.displayName = "RadioButtonElement";
