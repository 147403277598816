import { connect } from "react-redux";
import PropertyGroupOverview from "./PropertyGroupOverview";
import * as actions from "../../../actions";
import {
  getLabels,
  getOrgsStatus,
  getSelectedOrgLoading,
  getSelectedOrg,
  getAvailableOrgs,
  getSelectedOrgPropertyGroups,
  getSelectedOrgPropertyGroupsTotal,
  getSelectedOrgPropertyGroupsLoading,
  getSelectedOrgRoles,
  getSelectedOrgRolesLoading,
  getSelectedOrgWorkflows,
  getSelectedOrgWorkflowsLoading,
  getPageTitle,
  getAbortController,
  getHasPermission
} from "../../../selectors";

import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    availableOrganisations: getAvailableOrgs(state),
    organisationsStatus: getOrgsStatus(state),
    organisationsLoading: getOrgsStatus(state) === STATUS_LOADING,
    selectedOrganisationLoading: getSelectedOrgLoading(state),
    selectedOrganisation: getSelectedOrg(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedOrganisationPropertyGroups: getSelectedOrgPropertyGroups(state),
    selectedOrganisationPropertyGroupsTotal:
      getSelectedOrgPropertyGroupsTotal(state),
    selectedOrganisationPropertyGroupsLoading:
      getSelectedOrgPropertyGroupsLoading(state),
    selectedOrganisationRoles: getSelectedOrgRoles(state),
    selectedOrganisationWorkflows: getSelectedOrgWorkflows(state),
    selectedOrganisationRolesLoading: getSelectedOrgRolesLoading(state),
    selectedOrganisationWorkflowsLoading: getSelectedOrgWorkflowsLoading(state),
    abortController: getAbortController(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType)
  }),
  actions
)(PropertyGroupOverview);
