import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  BreadCrumbListElement,
  BreadCrumbLinkElement,
  BreadCrumbSiblingMenuWrapper
} from "../style";
import { Button, Icon } from "@core";
import BreadCrumbSiblingMenu from "./BreadCrumbSiblingMenu";
import { defaultTheme } from "../../../";
import { BUTTON_TYPE_SIBLING_MENU, ORGANISATION_URL } from "@base/constants";

/**
 * The BreadCrumbLink component renders a single breadcrumb, will render a caret if props.hasCaret is true, and a dropdown button next to the link if siblingMenu.length

 */

class BreadCrumbLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siblingMenuOpen: false
    };
    this.BreadCrumbMenu = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.closeMenu, false);
    document.addEventListener("mousedown", this.closeMenu, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.closeMenu, false);
    document.removeEventListener("mousedown", this.closeMenu, false);
  }

  closeMenu = (e) => {
    if (
      e.keyCode === 27 ||
      (this.BreadCrumbMenu.current &&
        !this.BreadCrumbMenu.current.contains(e.target))
    )
      this.setSiblingMenuOpen(false);
  };

  static propTypes = {
    /** Crumbs that describe the structure of the navigation */
    crumb: PropTypes.shape({
      /** The label that will be displayed for the breadcrum */
      label: PropTypes.string.isRequired,
      /** Link that will be navigated to when clicked.  */
      link: PropTypes.string,
      /** An Array indicating that this breadCrumb has children and a dropdown mentu should be renderered for it  */
      siblingMenu: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          link: PropTypes.string
        })
      ),
      /** Whether this link should be disabled (such as the 'home' link in the breadcrumb) */
      disabled: PropTypes.bool,
      hasCaret: PropTypes.bool
    })
  };

  /**
   * Checks if the prop of hasCaret has been defined, is true.
   * Returns false if any of the above is false, otherwise returns prop.hasCaret.
   * @public
   */

  getHasCaret = () => {
    const { hasCaret } = this.props;
    return hasCaret ? hasCaret : false;
  };

  /**
   * Checks if the prop of siblingMenu has been defined, is an array, and has a length.
   * Returns false if any of the above is false, otherwise returns prop.siblingMenu.
   * @public
   */

  hasSiblingMenu = () => {
    const { siblingMenu } = this.props;
    return !!(siblingMenu && siblingMenu.length);
  };

  /**
   * Checks if the prop of disabled has been defined
   * Returns false if any of the above is false, otherwise returns prop.disabled.
   * @public
   */

  getDisabled = () => {
    const { disabled } = this.props;
    return disabled ? disabled : false;
  };

  /**
   * Checks if the prop of label  has been defined
   * Returns false if any of the above is false, otherwise returns prop.label.
   * @public
   */

  getLabel = () => {
    const { label } = this.props;
    return label ? label : false;
  };

  /**
   * Checks if the prop of label  has been defined
   * Returns false if any of the above is false, otherwise returns prop.label.
   * @public
   */

  getSiblingMenu = () => {
    const { siblingMenu } = this.props;
    return siblingMenu;
  };

  getSiblingMenuOpen = () => {
    const { siblingMenuOpen } = this.state;
    return siblingMenuOpen;
  };

  setSiblingMenuOpen = (value) => {
    this.setState({
      siblingMenuOpen: value
    });
  };

  getOnClick = () => {
    const { link } = this.props;
    if (!link) return null;
    return link && link === "/" ? link : `/${ORGANISATION_URL}${link}`;
  };

  render() {
    return (
      <BreadCrumbListElement
        data-name="BreadCrumbListElement"
        ref={this.BreadCrumbMenu}
      >
        {this.getHasCaret() && (
          <Icon
            type="right"
            bgWidth="7px"
            bgHeight="7px"
            iconHeight="7px"
            iconWidth="7px"
            iconColor={defaultTheme.agCommonBlack}
          />
        )}
        <BreadCrumbLinkElement
          menuOpen={this.getSiblingMenuOpen()}
          to={this.getOnClick()}
          disabled={this.getDisabled()}
          data-name={"BreadCrumbLinkElement"}
        >
          {this.getLabel()}
        </BreadCrumbLinkElement>
        <BreadCrumbSiblingMenuWrapper>
          {this.hasSiblingMenu() && (
            <Button
              buttonStyleType={BUTTON_TYPE_SIBLING_MENU}
              isDisabled={false}
              onClick={() => {
                this.setSiblingMenuOpen(!this.getSiblingMenuOpen());
              }}
              buttonLabel={""}
              icon={{
                type: "down-triangle",
                bgWidth: "12px",
                bgHeight: "12px",
                iconHeight: "12px",
                iconWidth: "12px",
                iconColor: defaultTheme.agCommonBlack
              }}
            />
          )}
          {this.hasSiblingMenu() && (
            <BreadCrumbSiblingMenu
              siblingMenu={this.getSiblingMenu()}
              open={this.getSiblingMenuOpen()}
            />
          )}
        </BreadCrumbSiblingMenuWrapper>
      </BreadCrumbListElement>
    );
  }
}
export default BreadCrumbLink;
