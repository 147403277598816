import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  BreadCrumbSiblingMenuListElement,
  BreadCrumbSiblingMenuLinkElement
} from "../style";
import { ORGANISATION_URL } from "@base/constants";

/**
 * The BreadCrumbSiblingMenuLink component renders a link for the sibling menu

 */

class BreadCrumbSiblingMenuLink extends Component {
  static propTypes = {
    /** object containing a link and label*/
    label: PropTypes.string,
    link: PropTypes.string
  };

  /**
      * Checks if the label property of a props object exists and not empty string
      * Returns false if any of the above is false, otherwise returns the label

      * @public
      */

  getLabel = () => {
    const { label } = this.props;
    return label && label !== "" ? label : false;
  };

  /**
   * Checks if the link property of a props object exists and not empty string
   * Returns empty string if any of the above is false, otherwise returns the link
   * @public
   */

  getURL = () => {
    const { link } = this.props;
    return link && link !== "" ? `/${ORGANISATION_URL}${link}` : false;
  };

  render() {
    return (
      <BreadCrumbSiblingMenuListElement>
        <BreadCrumbSiblingMenuLinkElement to={this.getURL()}>
          {this.getLabel()}
        </BreadCrumbSiblingMenuLinkElement>
      </BreadCrumbSiblingMenuListElement>
    );
  }
}
export default BreadCrumbSiblingMenuLink;
