export const storeSession = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const getSession = (key) => {
  return sessionStorage.getItem(key) || null;
};

export const clearSession = (key) => {
  if (!key) return;
  const sessionStorage = window.sessionStorage;
  sessionStorage.removeItem(key);
};
