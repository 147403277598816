import { connect } from "react-redux";
import AddProperty from "./AddProperty";
import * as actions from "../../../actions";
import {
  getLabels,
  getSelectedPropertyStatus,
  getAbortController,
  getPageTitle,
  getToken,
  getCreatedProperties,
  getSelectedOrgPropertyGroups,
  getSelectedOrgPropertyGroupsLoading,
  getSelectedOrgDatasets,
  getSelectedOrgDatasetsLoading,
  getPropertyGroupStatus
} from "../../../selectors";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    status: getSelectedPropertyStatus(state),
    abortController: getAbortController(state),
    token: getToken(state),
    createdKpins: getCreatedProperties(state),
    organisationPropertyGroups: getSelectedOrgPropertyGroups(state),
    organisationPropertyGroupsLoading:
      getSelectedOrgPropertyGroupsLoading(state),
    organisationDatasets: getSelectedOrgDatasets(state),
    organisationDatasetsLoading: getSelectedOrgDatasetsLoading(state),
    propertyGroupStatus: getPropertyGroupStatus(state)
  }),
  actions
)(AddProperty);
