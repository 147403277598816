import React from "react";
import PropTypes from "prop-types";
import { Container } from "@core";
import { Heading2 } from "@typography";
import { MODAL_HEADER_ID } from "@base/constants";

/**
   * Returns the headerContent, if HeaderContent is a string, cast it as a heading, if it isn't return it as a child component.
   Otherwise returns empty string if blank.
   * @public
   */
const getHeaderContent = (headerContent) => {
  if (headerContent) {
    return typeof headerContent === "string" ? (
      <Heading2>{headerContent}</Heading2>
    ) : (
      headerContent
    );
  }
  return "";
};

const ModalHeader = (props) => {
  return (
    <Container data-name={"ModalHeader"} styleType={MODAL_HEADER_ID}>
      {getHeaderContent(props.headerContent)}
    </Container>
  );
};

ModalHeader.propTypes = {
  /** The content to display in the header component */
  headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default ModalHeader;
