import React, { Component } from "react";
import {
  defaultTheme,
  ContextHeader,
  BottomButtonBar,
  Loader,
  DataTable
} from "@UIKit";
import { Container, Wrapper, Section, LinkButton } from "@core";
import { navigate } from "@reach/router";
import { filterList, getSession, storeSession } from "@base/common";
import {
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  PROPERTY_GROUPS_URL,
  CONTENT_VIEW,
  NEW_URL,
  BUTTON_TYPE_PRIMARY,
  CONTEXT_HEADER_CONTAINER,
  CREATE_PERMISSION,
  PROPERTY_GROUPS_SECTION,
  ORGANISATION_URL
} from "@base/constants";

class PropertyGroupOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: "",
      currentPageNumber: 1
    };
  }

  componentDidMount() {
    this.loadPropertyGroups();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate() {
    const { orgId, pageTitle, setPageTitle, availableOrganisations } =
      this.props;

    if (orgId && availableOrganisations) {
      const org = availableOrganisations.find((org) => org.orgId === orgId);
      const docTitle = `${org.title} property groups overview`;
      if (pageTitle !== docTitle) {
        setPageTitle(docTitle);
      }
    }
  }

  loadPropertyGroups = () => {
    const { loadOrganisationPropertyGroups, orgId } = this.props;
    loadOrganisationPropertyGroups(orgId);
  };

  isLoadingPropertyGroupOverview = () => {
    const {
      selectedOrganisationPropertyGroups,
      selectedOrganisationPropertyGroupsLoading
    } = this.props;
    return (
      !selectedOrganisationPropertyGroups ||
      selectedOrganisationPropertyGroupsLoading
    );
  };

  createHeaderText = () => {
    const { getLabel, availableOrganisations, orgId } = this.props;
    if (!availableOrganisations) return "";
    return getLabel("PROPERTY_GROUPS_HEADING_TEXT", {
      organisation: availableOrganisations.find((org) => org.orgId === orgId)
        .title
    });
  };

  getSecondHeaderText = () => {
    const { getLabel } = this.props;
    const { PROPERTY_GROUPS_TEXT } = getLabel();
    return PROPERTY_GROUPS_TEXT;
  };

  getOrganisationPropertyGroups = () => {
    const { selectedOrganisationPropertyGroups } = this.props;
    return selectedOrganisationPropertyGroups
      ? filterList(selectedOrganisationPropertyGroups, this.getFilterText())
      : false;
  };

  createNavigationLink = (link) => {
    return () => {
      navigate(link);
    };
  };

  getFilterText = () => {
    const { filterText } = this.state;
    return filterText;
  };

  getFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("PROPERTY_GROUPS_TEXT")
    });
  };

  getNewPropertyGroupLabel = () => {
    const { getLabel } = this.props;
    return getLabel("ADD_NEW_LABEL", { item: getLabel("PROPERTY_GROUP_TEXT") });
  };

  createNewPropertyGroupLink = () => {
    const { orgId } = this.props;
    return this.createNavigationLink(
      `/${ORGANISATION_URL}/${orgId}/${PROPERTY_GROUPS_URL}/${NEW_URL}`
    );
  };

  hasCreatePermission = () => {
    const { getHasPermission, orgId } = this.props;
    return getHasPermission(orgId, PROPERTY_GROUPS_SECTION, CREATE_PERMISSION);
  };

  getButtons = () => {
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        isDisabled: false,
        onClick: this.createNewPropertyGroupLink(),
        buttonLabel: this.getNewPropertyGroupLabel(),
        icon: {
          type: "plus",
          bgWidth: "25px",
          bgHeight: "15px",
          iconHeight: "16px",
          iconWidth: "15px",
          iconColor: defaultTheme.agWhite
        }
      }
    ];
  };

  getContextHeaderButtons = () => {
    return this.hasCreatePermission()
      ? [
          {
            buttonStyleType: BUTTON_TYPE_PRIMARY,
            isDisabled: false,
            onClick: this.createNewPropertyGroupLink(),
            buttonLabel: this.getNewPropertyGroupLabel()
          }
        ]
      : [];
  };

  getNoResultsText = () => {
    const { getLabel } = this.props;
    const filterText = this.getFilterText();
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getResultsSubtext = () => {
    const { getLabel } = this.props;
    const { currentPageNumber } = this.state;
    const pageLimit = this.getPageLimit();
    const total = this.getOrgPropertyGroupsTotal();
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      currentPageNumber === 1
        ? currentPageNumber
        : (currentPageNumber - 1) * pageLimit + 1;

    const limitEndNumber = currentPageNumber * pageLimit;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;

    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  setFilterText = (value) => {
    this.setState({
      filterText: value,
      currentPageNumber: 1
    });
  };

  getFilterPlaceholderText = () => {
    const { getLabel } = this.props;
    return getLabel("PROPERTY_GROUP_FILTER_PLACEHOLDER_LABEL");
  };

  getTableHeader = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        {
          content: getLabel("PROPERTY_GROUPS_TEXT")
        }
      ]
    };
  };

  getOrgPropertyGroupsList = () => {
    const { currentPageNumber } = this.state;
    const pageLimit = this.getPageLimit();
    const propertyGroupsList = this.getOrganisationPropertyGroups();
    if (!propertyGroupsList) return;
    const lastPropertyGroup = currentPageNumber * pageLimit;
    const firstPropertyGroup = lastPropertyGroup - pageLimit;
    const propertyGroupList = propertyGroupsList.slice(
      firstPropertyGroup,
      lastPropertyGroup
    );
    return propertyGroupList;
  };

  getTableData = () => {
    const { orgId } = this.props;
    const propertyGroupList = this.getOrgPropertyGroupsList();
    if (!propertyGroupList) return;
    return propertyGroupList.map((group) => {
      const title = (
        <LinkButton
          url={`/${orgId}/${PROPERTY_GROUPS_URL}/${group.groupId}`}
          buttonLabel={group.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      );
      return {
        cells: [title]
      };
    });
  };

  setPaginationContent = (value) => {
    this.setState({ currentPageNumber: value });
  };

  getPageLimit = () => {
    const result = getSession(`org-property-groups-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getCurrentPageNumber = () => {
    const { currentPageNumber } = this.state;
    return currentPageNumber;
  };

  getOrgPropertyGroupsTotal = () => {
    if (!this.getOrganisationPropertyGroups()) return [];
    return this.getOrganisationPropertyGroups().length;
  };

  showDropdown = () => {
    return (
      this.getOrgPropertyGroupsTotal() > this.getDropDownOptions()[0].value
    );
  };

  showPagination = () => {
    return this.getOrgPropertyGroupsTotal() > this.getPageLimit();
  };

  getPaginationContent = () => {
    return {
      setPaginationContent: this.setPaginationContent,
      activeNumber: this.getCurrentPageNumber(),
      numberOfButtons: this.getPageLimit(),
      totalResults: this.getOrgPropertyGroupsTotal(),
      showPagination: this.showPagination(),
      paginationInput: true
    };
  };

  dropDownOnSelectFunction = (value) => {
    this.setState({ currentPageNumber: 1 });
    storeSession(`org-property-groups-${PAGINATION_KEY}`, value);
  };

  getDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit()
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getPageDropDown = () => {
    return {
      showDropdown: this.showDropdown(),
      dropDownValue: this.getPageLimit(),
      dropDownLabel: this.getDropDownLabel(),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: this.dropDownOnSelectFunction
    };
  };

  render() {
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoadingPropertyGroupOverview() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper
            data-name={"Wrapper"}
            subStyle="App"
            styleType={CONTENT_VIEW}
          >
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
                buttons={this.getContextHeaderButtons()}
              />
            </Container>

            <Section styleType={DATA_TABLE_SECTION_STYLING}>
              <DataTable
                loading={this.isLoadingPropertyGroupOverview()}
                noResultsText={this.getNoResultsText()}
                title={this.getSecondHeaderText()}
                showResultsText={true}
                resultsSubtext={this.getResultsSubtext()}
                tableFilter={{
                  filterLabel: this.getFilterLabel(),
                  showFilter: true,
                  filterFunction: this.setFilterText,
                  isDisabled: false,
                  placeholderText: this.getFilterPlaceholderText(),
                  inputId: this.getFilterLabel()
                }}
                header={this.getTableHeader()}
                rows={this.getTableData()}
                pagination={this.getPaginationContent()}
                pageDropDown={this.getPageDropDown()}
              />
              {this.hasCreatePermission() && (
                <BottomButtonBar buttons={this.getButtons()} />
              )}
            </Section>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default PropertyGroupOverview;
