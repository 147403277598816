import React, { Component } from "react";
import {
  Container,
  Wrapper,
  Section,
  FormLabel,
  FormInput,
  CheckboxV2,
  Fieldset
} from "@core";
import { getSession, storeSession } from "@base/common";
import { P, Legend, Heading3 } from "@typography";
import {
  STYLETYPE_ERROR,
  ROLES_URL,
  STATUS_READY,
  STATUS_LOADING,
  STATUS_UPDATING,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  STYLETYPE_FORM_FIELD_FLEX,
  STYLETYPE_FORM_FIELD,
  PROPERTY_GROUP_TEXT,
  PROPERTY_TEXT,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  BUTTON_TYPE_PRIMARY,
  BUTTON_TYPE_SECONDARY,
  SECTION_HEADER_STYLING,
  HEIGHT_AUTO_TEXT,
  DEVICE_MOBILE,
  ORGANISATION_URL,
  AGBOX_API_URL,
  AGBOX_API_KEY,
  CREATE_PERMISSION,
  READ_PERMISSION,
  UPDATE_PERMISSION,
  ARCHIVE_PERMISSION
} from "@base/constants";
import {
  Loader,
  RadioGroup,
  ContextHeader,
  BottomButtonBar,
  DataTable,
  ToggleSwitch
} from "@UIKit";
import { navigate } from "@reach/router";
import AgBoxApiRequests from "../../../apis/agbox/requests";
const { requestUserMembership, requestRole, requestOrganisationDatasets } =
  AgBoxApiRequests(AGBOX_API_URL, AGBOX_API_KEY);

class UpdateRole extends Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      roleName: "",
      propertyGroupWorkflows: [],
      propertyWorkflows: [],
      propertyGroupWorkflowsToggleAll: false,
      propertyWorkflowsToggleAll: false,
      propertyWorkflowsHeader: false,
      propertyGroupWorkflowsHeader: false,
      propertyPageNumber: 1,
      propertyGroupPageNumber: 1,
      propertyGroupDefaultWorkflow: "None",
      propertyDefaultWorkflow: "None",
      currentUserAdminAccess: false,
      adminConsoleAccessEnabled: false,
      initialAdminConsoleAccess: false,
      isLoading: false,
      datasetsToggleAll: false,
      datasets: [],
      datasetsHeader: false,
      datasetsPageNumber: 1
    };
  }

  componentDidMount() {
    this.setupRoleDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps) {
    const {
      setPageTitle,
      pageTitle,
      selectedRoleStatus,
      selectedOrgWorkflowsLoading,
      selectedRole
    } = this.props;
    const role = this.getSelectedRole();
    if (role && role.title && pageTitle !== role.title) {
      setPageTitle(role.title);
    }

    if (
      prevProps.selectedRoleStatus === STATUS_UPDATING &&
      selectedRoleStatus === STATUS_READY
    ) {
      this.showSuccess();
    }

    if (prevProps.selectedOrgWorkflowsLoading && !selectedOrgWorkflowsLoading) {
      this.updateWorkflowsState();
    }
    if (prevProps.selectedRole !== selectedRole && selectedRole) {
      this.loadDatasets();
    }
  }

  updateWorkflowsState = () => {
    const { selectedRole, selectedOrgWorkflows } = this.props;

    if (
      !selectedRole ||
      !selectedRole.workflows ||
      selectedRole.workflows.length === 0
    ) {
      this.setState({
        roleName: selectedRole.title,
        propertyGroupWorkflows: this.mapWorkflows(
          selectedOrgWorkflows,
          PROPERTY_GROUP_TEXT,
          []
        ),
        propertyWorkflows: this.mapWorkflows(
          selectedOrgWorkflows,
          PROPERTY_TEXT,
          []
        )
      });
      return;
    }

    const propertyGroupWorkflow = selectedRole.workflows
      .filter((item) => item.workflowType === PROPERTY_GROUP_TEXT)
      .find((item) => item.isDefault);
    const propertyWorkflow = selectedRole.workflows
      .filter((item) => item.workflowType === PROPERTY_TEXT)
      .find((item) => item.isDefault);

    this.setState({
      roleName: selectedRole.title,
      propertyGroupDefaultWorkflow: propertyGroupWorkflow
        ? propertyGroupWorkflow.workflowId
        : "None",
      propertyGroupWorkflows: this.mapWorkflows(
        selectedOrgWorkflows,
        PROPERTY_GROUP_TEXT,
        selectedRole.workflows
      ),
      propertyDefaultWorkflow: propertyWorkflow
        ? propertyWorkflow.workflowId
        : "None",
      propertyWorkflows: this.mapWorkflows(
        selectedOrgWorkflows,
        PROPERTY_TEXT,
        selectedRole.workflows
      )
    });
  };

  getWorkflowPermissions = (workflowId) => {
    const permissions = this.getWorkflowPermissionsForRole(workflowId);
    return permissions.map((permission) => ({
      permissionTitle: permission.permissionTitle,
      checked: permission.permissionValue || false
    }));
  };

  getWorkflowPermissionsForRole = (workflowId) => {
    const role = this.getSelectedRole();
    const existingWorkflowPermissions = role.workflows.find(
      (workflow) => workflow.workflowId === workflowId
    )?.workflowPermissions;

    let workflowPermissions =
      existingWorkflowPermissions?.map((permission) => ({
        ...permission,
        checked: permission.permissionValue
      })) || [];

    const defaultPermissions = [
      {
        permissionTitle: CREATE_PERMISSION
      },
      { permissionTitle: UPDATE_PERMISSION },
      { permissionTitle: ARCHIVE_PERMISSION },
      { permissionTitle: READ_PERMISSION }
    ];

    if (workflowPermissions.length > 0) {
      defaultPermissions.forEach((defaultPermission) => {
        if (
          !workflowPermissions.some(
            (permission) =>
              permission.permissionTitle === defaultPermission.permissionTitle
          )
        ) {
          workflowPermissions.push(defaultPermission);
        }
      });
    } else {
      workflowPermissions = defaultPermissions;
    }

    return workflowPermissions;
  };

  mapWorkflows = (workflows, type, selectedRoleWorkflows) => {
    return workflows
      .filter((item) => item.type === type)
      .map((workflow) => {
        const selectWorkflow = selectedRoleWorkflows.find(
          (item) => item.workflowId === workflow.workflowId
        );
        const permissions = this.getWorkflowPermissions(workflow.workflowId);
        return {
          ...workflow,
          expanded: false,
          checked: selectWorkflow ? true : false,
          isDefault: false,
          allPermissionsChecked: permissions.every(
            (permission) => permission.checked
          )
            ? true
            : false,
          permissions: permissions
        };
      });
  };

  getUpdatedWorkflowPermissions = (workflow) => {
    const type = workflow.type;
    const workflowsState = this.state[`${type}Workflows`];
    const selectedWorkflow = workflowsState.find(
      (selectedWorkflow) => selectedWorkflow.workflowId === workflow.workflowId
    );
    return selectedWorkflow.permissions;
  };

  setupRoleDetails = () => {
    const {
      orgId,
      roleId,
      loadRole,
      loadOrganisationRoles,
      loadOrganisationWorkflows
    } = this.props;
    loadRole(orgId, roleId);
    loadOrganisationRoles(orgId);
    loadOrganisationWorkflows(orgId);
    this.userHasAdminCreatePermission();
    this.setRoleAdminPermission();
  };

  setRoleAdminPermission = () => {
    const selectedRole = this.getSelectedRole();
    if (selectedRole?.adminConsoleAccess) {
      const { adminAccess, adminRole } = selectedRole.adminConsoleAccess;
      const hasPermission =
        adminAccess &&
        adminRole?.create &&
        adminRole?.read &&
        adminRole?.update;
      this.setState({
        adminConsoleAccessEnabled: hasPermission,
        initialAdminConsoleAccess: hasPermission
      });
    }
  };

  getHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      item: getLabel("ROLE_TEXT"),
      action: getLabel("UPDATE_ACTION_LABEL")
    });
  };

  getSelectedRole = () => {
    const { selectedRole } = this.props;
    return selectedRole ? selectedRole : false;
  };

  getOrgRoleTitles = () => {
    const { selectedOrganisationRoles } = this.props;
    if (!selectedOrganisationRoles) return false;
    return selectedOrganisationRoles.map(
      (item) => item.title?.toLowerCase() || ""
    );
  };

  getOrgRoleNameExists = () => {
    const roleTitles = this.getOrgRoleTitles();
    const selectedRoleTitle = this.getSelectedRole().title;
    if (!selectedRoleTitle || !roleTitles) return false;
    const titles = roleTitles.filter(
      (role) => role !== selectedRoleTitle.toLowerCase()
    );
    const title = this.getValue("roleName");
    return titles.includes(title.toLowerCase());
  };

  isInvalid = () => {
    return this.valueIsEmpty("roleName") || this.getOrgRoleNameExists();
  };

  valueIsEmpty = (field) => {
    const value = this.getValue(field);
    return (
      value === null ||
      value === undefined ||
      value === "" ||
      (value && !value.length)
    );
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  getSelectedWorkflowsData = (workflows, defaultWorkflow) => {
    return workflows
      .filter((workflow) => workflow.checked)
      .reduce((result, workflow) => {
        const { workflowId } = workflow;
        const permissions = this.getUpdatedWorkflowPermissions(workflow);
        const workflowPermissions = permissions.reduce(
          (acc, { permissionTitle, checked }) => {
            acc[permissionTitle] = checked;
            return acc;
          },
          {}
        );
        const isDefault = defaultWorkflow === workflowId ? true : false;
        if (isDefault && Object.keys(workflowPermissions).length !== 0) {
          return [...result, { workflowId, isDefault, workflowPermissions }];
        } else if (Object.keys(workflowPermissions).length !== 0) {
          return [...result, { workflowId, isDefault, workflowPermissions }];
        } else if (isDefault) {
          return [...result, { workflowId, isDefault }];
        } else {
          return [...result, { workflowId, isDefault }];
        }
      }, []);
  };

  getRoleData = () => {
    const {
      propertyWorkflows,
      propertyGroupWorkflows,
      propertyGroupDefaultWorkflow,
      propertyDefaultWorkflow
    } = this.state;
    const selectedPropertyGroupWorkflows = this.getSelectedWorkflowsData(
      propertyGroupWorkflows,
      propertyGroupDefaultWorkflow
    );
    const selectedPropertyWorkflows = this.getSelectedWorkflowsData(
      propertyWorkflows,
      propertyDefaultWorkflow
    );
    return [...selectedPropertyGroupWorkflows, ...selectedPropertyWorkflows];
  };

  getData = () => {
    const roleCapabilities = this.getRoleCapabilitiesData();
    const roleData = this.getRoleData();
    const roleWorkflowsState = roleData.map((item) => item.workflowId);
    const { roleName } = this.state;
    const selectedRole = this.getSelectedRole().workflows;

    const removeWorkflows = selectedRole.reduce(
      (result, workflows) =>
        roleWorkflowsState.indexOf(workflows.workflowId) === -1
          ? [...result, workflows.workflowId]
          : result,
      []
    );
    return JSON.stringify({
      title: roleName,
      removeWorkflows,
      addWorkflows: roleData,
      roleCapabilities: roleCapabilities
    });
  };

  callUpdateRoleAdmin = async (orgId, roleId) => {
    const { currentUserAdminAccess, adminConsoleAccessEnabled } = this.state;
    const { updateRoleAdmin } = this.props;

    let data = {
      adminConsoleAccess: {
        adminAccess: false,
        adminRole: {
          create: false,
          update: false,
          read: false,
          delete: false
        }
      }
    };

    if (currentUserAdminAccess && adminConsoleAccessEnabled) {
      data.adminConsoleAccess.adminAccess = true;
      data.adminConsoleAccess.adminRole.create = true;
      data.adminConsoleAccess.adminRole.update = true;
      data.adminConsoleAccess.adminRole.read = true;
    }
    try {
      const json = JSON.stringify(data);
      await updateRoleAdmin(orgId, roleId, json);
    } catch (e) {
      throw new Error(e);
    }
  };

  callAction = async () => {
    const { initialAdminConsoleAccess, adminConsoleAccessEnabled } = this.state;
    try {
      const { orgId, roleId, updateRole, showModal } = this.props;
      const data = this.getData();
      this.showLoading();
      showModal();
      await updateRole(orgId, roleId, data);
      if (adminConsoleAccessEnabled !== initialAdminConsoleAccess) {
        this.callUpdateRoleAdmin(orgId, roleId);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("UPDATING_LABEL"),
        item: getLabel("ROLE_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  returnToRoleDetailAction = () => {
    const { hideModal, orgId, roleId } = this.props;
    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${ROLES_URL}/${roleId}`);
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_UPDATED_LABEL", {
        item: getLabel("ROLE_TEXT")
      }),
      body: getLabel("ITEM_UPDATED_SUCCESS", {
        item: getLabel("ROLE_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_DETAILS", {
            item: getLabel("ROLE_TEXT")
          }),
          onClick: this.returnToRoleDetailAction
        }
      ]
    });
    return null;
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        onClick: this.callAction,
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("ROLE_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      },
      {
        onClick: this.returnToRoleDetailAction,
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        buttonLabel: getLabel("RETURN_TO_DETAILS", {
          item: getLabel("ROLE_TEXT")
        })
      }
    ];
  };

  getValidationError = () => {
    const { getLabel } = this.props;
    return this.valueIsEmpty("roleName")
      ? getLabel("ROLE_NAME_HELPTEXT")
      : this.getOrgRoleNameExists()
      ? getLabel("ROLE_EXISTS_LABEL")
      : null;
  };

  isDetailsLoading = () => {
    const {
      selectedRoleStatus,
      selectedOrgWorkflows,
      selectedOrgWorkflowsLoading,
      selectedOrganisationRolesLoading
    } = this.props;
    return (
      !selectedOrgWorkflows ||
      selectedOrgWorkflowsLoading ||
      selectedOrganisationRolesLoading ||
      selectedRoleStatus === STATUS_LOADING ||
      this.getValue("isLoading")
    );
  };

  getOrganisationWorkflows = () => {
    const { selectedOrgWorkflows } = this.props;
    return selectedOrgWorkflows ? selectedOrgWorkflows : false;
  };

  getWorkflowsByType = (type) => {
    const workflows = this.getOrganisationWorkflows();
    if (workflows) {
      return workflows.filter((item) => item.type === type);
    }
  };

  getWorkflowsList = (type) => {
    const pageNumberState = this.state[`${type}PageNumber`];
    const workflowsState = this.state[`${type}Workflows`];
    const pageLimit = this.getPageLimit(type);
    if (!workflowsState) return [];
    const lastProperty = pageNumberState * pageLimit;
    const firstProperty = lastProperty - pageLimit;
    const currentPropertyList = workflowsState.slice(
      firstProperty,
      lastProperty
    );
    return currentPropertyList;
  };

  getItemLabel = (type) => {
    const { getLabel } = this.props;
    return getLabel(
      type === PROPERTY_TEXT ? "PROPERTY_TEXT" : "PROPERTY_GROUP_TEXT"
    );
  };
  getWorkflowsHeader = (type) => {
    const { getLabel } = this.props;
    return {
      toggleOpen: this.getWorkflowsToggleOpen(type),
      setToggleOpen: () => this.setWorkflowsToggleOpen(type),
      showToggle: true,
      cells: [
        { content: this.getWorkflowsHeaderCheckbox(type) },
        { content: getLabel("DEVICE_LABEL") }
      ]
    };
  };

  getAllWorkflowsEnabled = (type) => {
    return this.state[`${type}WorkflowsHeader`];
  };

  getWorkflowsHeaderCheckbox = (type) => {
    const { getLabel } = this.props;
    const value = this.getAllWorkflowsEnabled(type);
    return (
      <CheckboxV2
        handleUpdate={() => this.handleCheckWorkflowsHeader(type)}
        name={`${type}-workflows-header`}
        rowId={`${type}-workflows-header`}
        value={value}
        checked={value}
        title={getLabel("ENABLE_ALL_WORKFLOWS_LABEL", {
          item: this.getItemLabel(type)
        })}
      />
    );
  };

  handleCheckWorkflowsHeader = (type) => {
    const allWorkflowsEnabled = this.getAllWorkflowsEnabled(type);
    const headerValue = `${type}WorkflowsHeader`;
    const workflowType = `${type}Workflows`;

    this.setState({
      [headerValue]: !allWorkflowsEnabled,
      [workflowType]: this.state[`${type}Workflows`].map((item) => ({
        ...item,
        checked: !allWorkflowsEnabled,
        allPermissionsChecked: !allWorkflowsEnabled,
        permissions: item.permissions.map((permission) => ({
          ...permission,
          checked: !allWorkflowsEnabled
        }))
      }))
    });
  };

  getWorkflowsToggleOpen = (type) => {
    return this.state[`${type}WorkflowsToggleAll`];
  };

  setWorkflowsToggleOpen = (type) => {
    const toggleAllWorkflows = `${type}WorkflowsToggleAll`;
    const workflowType = `${type}Workflows`;
    const workflowTypeState = this.state[`${type}Workflows`];

    this.setState(
      { [toggleAllWorkflows]: !this.getWorkflowsToggleOpen(type) },
      () => {
        const newState = workflowTypeState.map((item) => ({
          ...item,
          expanded: this.getWorkflowsToggleOpen(type)
        }));
        this.setValue(newState, workflowType);
      }
    );
  };

  getWorkflowsData = (type) => {
    const workflows = this.getWorkflowsList(type);
    return workflows.map((workflow) => {
      return {
        id: workflow.workflowId,
        cells: [this.getWorkflowsCheckbox(workflow, type), workflow.device],
        expanded: this.getWorkflowsExpanded(workflow, type),
        handleUpdateRowExpanded: () =>
          this.updateWorkflowsRowExpanded(workflow.workflowId, type),
        content: this.renderWorkflowsOptions(workflow, type)
      };
    });
  };

  getWorkflowsCheckbox = (workflow, type) => {
    return (
      <CheckboxV2
        handleUpdate={() => this.handleCheckWorkflows(workflow, type)}
        name={`${type}-workflows`}
        rowId={workflow.workflowId}
        checked={workflow.checked}
        title={workflow.title}
      />
    );
  };

  handleCheckWorkflows = (workflow, type) => {
    const workflowsType = `${type}Workflows`;
    const workflowsTypeHeader = `${type}WorkflowsHeader`;
    const workflowsState = this.state[`${type}Workflows`];

    const newState = workflowsState.map((item) => {
      const permissions = item.permissions.map((permission) => ({
        ...permission,
        checked: !item.checked
      }));
      if (item.workflowId === workflow.workflowId) {
        return {
          ...item,
          checked: !item.checked,
          allPermissionsChecked: !item.checked,
          permissions
        };
      }
      return item;
    });
    this.setState({
      [workflowsType]: newState,
      [workflowsTypeHeader]: newState.every((item) => item.checked)
    });
  };

  getWorkflowsExpanded = (workflow) => {
    if (workflow.permissions.length === 0) return false;
    return workflow.expanded;
  };

  updateWorkflowsRowExpanded = (id, type) => {
    const workflowsType = `${type}Workflows`;
    const workflowsState = this.state[`${type}Workflows`];
    const toggleAllWorkflows = `${type}WorkflowsToggleAll`;

    const updatedWorkflows = workflowsState.map((workflow) => {
      if (workflow.workflowId !== id) return workflow;
      return {
        ...workflow,
        expanded: !workflow.expanded
      };
    });

    this.setState(
      {
        [workflowsType]: updatedWorkflows
      },
      () => {
        const propsWorkflowsExpanded = this.getWorkflowsList(type)
          .filter((item) => item.permissions.length !== 0)
          .every((item) => item.expanded);
        this.setValue(propsWorkflowsExpanded, toggleAllWorkflows);
      }
    );
  };

  renderWorkflowsOptions = (workflow, type) => {
    const { getLabel } = this.props;
    return (
      <Fieldset>
        <DataTable
          title={getLabel("ENABLE_WORKFLOW_PERMISSIONS_LABEL", {
            item: workflow.title
          })}
          TitleComponent={Legend}
          header={this.getWorkflowsPermissionsHeader(workflow, type)}
          rows={this.getWorkflowsPermissionsData(workflow, type)}
          pagination={{ showPagination: false }}
          pageDropDown={{ showDropdown: false }}
          innerStyleType={"inner-accordion"}
          id={`${workflow.workflowId}-permissions`}
        />
      </Fieldset>
    );
  };

  getWorkflowsPermissionsHeader = (workflow, type) => {
    const { getLabel } = this.props;
    return {
      cells: [
        {
          content: (
            <CheckboxV2
              handleUpdate={() =>
                this.handleUpdateWorkfowsPermissions(workflow, type)
              }
              name={`${workflow.workflowId}-permissions`}
              rowId={`${workflow.workflowId}-permissions`}
              value={workflow.allPermissionsChecked}
              checked={workflow.allPermissionsChecked}
              title={getLabel("ENABLE_FULL_PERMISSIONS_LABEL")}
            />
          )
        }
      ],
      showToggle: false,
      innerStyleType: "inner-accordion"
    };
  };

  handleUpdateWorkfowsPermissions = (workflow, type) => {
    const permissions = this.getWorkflowPermissionsForRole(workflow.workflowId);
    const workflowsType = `${type}Workflows`;
    const workflowsState = this.state[`${type}Workflows`];
    const newState = workflowsState.map((item) => {
      if (item.workflowId === workflow.workflowId) {
        return {
          ...item,
          allPermissionsChecked: !item.allPermissionsChecked,
          permissions: permissions.map((permission) => ({
            ...permission,
            checked: !item.allPermissionsChecked
          }))
        };
      }
      return item;
    });
    this.setState({
      [workflowsType]: newState
    });
  };

  getWorkflowsPermissionsData = (workflow, type) => {
    const permissions = this.getUpdatedWorkflowPermissions(workflow);
    return permissions.map((permission) => {
      return {
        cells: [
          this.getWorkflowsPermissionsCheckbox(workflow, permission, type)
        ],
        innerStyleType: "inner-accordion"
      };
    });
  };

  getWorkflowsPermissionsCheckbox = (workflow, permission, type) => {
    return (
      <CheckboxV2
        handleUpdate={() =>
          this.handleCheckWorkflowsPermissions(workflow, permission, type)
        }
        name={"workflows"}
        rowId={"workflowsPermissions"}
        value={this.getValue("")}
        checked={permission.checked}
        title={permission.permissionTitle}
      />
    );
  };

  handleCheckWorkflowsPermissions = (workflow, permission, type) => {
    const workflowsType = `${type}Workflows`;
    const workflowsState = this.state[`${type}Workflows`];
    const newState = workflowsState.map((item) => {
      if (item.workflowId === workflow.workflowId) {
        const permissions = item.permissions.map((singlePermission) => {
          if (singlePermission.permissionTitle === permission.permissionTitle) {
            return { ...permission, checked: !singlePermission.checked };
          }
          return singlePermission;
        });

        return {
          ...item,
          permissions,
          allPermissionsChecked: permissions.every(
            (permission) => permission.checked
          )
            ? true
            : false
        };
      }
      return item;
    });
    this.setState({
      [workflowsType]: newState
    });
  };

  getWorkflowsPagination = (type) => {
    return {
      setPaginationContent: (e) => this.setPaginationContent(e, type),
      activeNumber: this.getCurrentPageNumber(type),
      numberOfButtons: this.getPageLimit(type),
      totalResults: this.getWorkflowsByType(type).length,
      showPagination: this.showPagination(type),
      paginationInput: true
    };
  };

  showPagination = (type) => {
    return this.getWorkflowsByType(type).length > this.getPageLimit(type);
  };

  setPaginationContent = (value, type) => {
    this.updateWorkflowsExpandedState(type);
    this.setState({ [`${type}PageNumber`]: value });
  };

  getPageLimit = (type) => {
    const result = getSession(`role-${type}-workflows-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getCurrentPageNumber = (type) => this.state[`${type}PageNumber`];

  updateWorkflowsExpandedState = (type) => {
    const workflowsType = `${type}Workflows`;
    const workflowsState = this.state[`${type}Workflows`];
    const toggleAllWorkflows = `${type}WorkflowsToggleAll`;

    const updatedWorkflows = workflowsState.map((workflow) => {
      return {
        ...workflow,
        expanded: false
      };
    });
    this.setValue(updatedWorkflows, workflowsType);
    this.setValue(false, toggleAllWorkflows);
  };

  showDropdown = (type) => {
    return (
      this.getWorkflowsByType(type).length > this.getDropDownOptions()[0].value
    );
  };

  dropDownOnSelectFunction = (value, type) => {
    this.updateWorkflowsExpandedState(type);
    this.setState({ [`${type}PageNumber`]: 1 });
    storeSession(`role-${type}-workflows-${PAGINATION_KEY}`, value);
  };

  getDropDownLabel = (type) => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit(type)
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getPageDropDown = (type) => {
    return {
      showDropdown: this.showDropdown(type),
      dropDownValue: this.getPageLimit(type),
      dropDownLabel: this.getDropDownLabel(type),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: (e) => this.dropDownOnSelectFunction(e, type)
    };
  };

  handleDefaultWorkflow = (event, type) => {
    const defaultWorkflow = `${type}DefaultWorkflow`;
    this.setValue(event, defaultWorkflow);
  };

  getSelectedWorkflowsRadioButtonOptions = (type) => {
    const workflowsState = this.state[`${type}Workflows`];
    return workflowsState
      .filter((workflow) => workflow.device !== DEVICE_MOBILE)
      .filter((workflow) => workflow.checked)
      .map((workflow) => ({
        label: workflow.title,
        value: workflow.workflowId
      }));
  };

  getWorkflowsRadioButtons = (type) => {
    const { getLabel } = this.props;
    const defaultWorkflow = `${type}DefaultWorkflow`;
    const defaultWorkflowState = this.state[defaultWorkflow];

    const selectedWorkflowsByType =
      this.getSelectedWorkflowsRadioButtonOptions(type);
    const isWorkflowSelected = selectedWorkflowsByType.some(
      (el) => el.value === defaultWorkflowState
    );

    if (defaultWorkflowState !== "None" && !isWorkflowSelected) {
      this.setValue("None", defaultWorkflow);
    }

    if (
      selectedWorkflowsByType.length === 0 &&
      defaultWorkflowState !== "None"
    ) {
      this.setValue("None", defaultWorkflow);
      return [
        {
          label: getLabel("NONE_LABEL"),
          value: getLabel("NONE_LABEL")
        }
      ];
    }
    return [
      {
        label: getLabel("NONE_LABEL"),
        value: getLabel("NONE_LABEL")
      },
      ...selectedWorkflowsByType
    ];
  };

  renderWorkflowsRadioOptions = (type) => {
    const { getLabel } = this.props;
    const defaultWorkflow = `${type}DefaultWorkflow`;
    const radioButtonOptions = this.getWorkflowsRadioButtons(type);
    return (
      <RadioGroup
        legend={getLabel("DEFAULT_WORKFLOW_LABEL", {
          item: getLabel(
            type === PROPERTY_TEXT ? "PROPERTY_TEXT" : "PROPERTY_GROUP_TEXT"
          )
        })}
        value={this.getValue(defaultWorkflow)}
        isDisabled={false}
        name={defaultWorkflow}
        radioButtons={radioButtonOptions}
        onClick={(event) => this.handleDefaultWorkflow(event, type)}
      />
    );
  };

  userHasAdminCreatePermission = async () => {
    try {
      const { user, token, orgId } = this.props;
      const userId = user.userId;
      const userMembership = await requestUserMembership(
        orgId,
        userId,
        token,
        this.controller.signal
      );
      const { roleId } = userMembership;
      const role = await requestRole(
        orgId,
        roleId,
        token,
        this.controller.signal
      );
      const hasAdminCreatePermission = role.adminConsoleAccess.adminRole.create;
      if (hasAdminCreatePermission)
        this.setState({ currentUserAdminAccess: hasAdminCreatePermission });
    } catch (error) {
      throw new Error(error);
    }
  };

  getCurrentUserAdminAccess = () => {
    const { currentUserAdminAccess } = this.state;
    return currentUserAdminAccess;
  };

  setAdminConsoleAccess = () => {
    this.setValue(
      !this.getValue("adminConsoleAccessEnabled"),
      "adminConsoleAccessEnabled"
    );
  };

  renderAdminConsoleAccessOptions = () => {
    const { adminConsoleAccessEnabled } = this.state;
    const { getLabel } = this.props;
    const adminAccessLabel = getLabel("ADMIN_CONSOLE_ACCESS_TOGGLE_LABEL", {
      status: adminConsoleAccessEnabled
        ? getLabel("ENABLED_LABEL")
        : getLabel("DISABLED_LABEL")
    });

    return (
      <ToggleSwitch
        id={"admin-widget-label"}
        label={adminAccessLabel}
        isToggled={this.getValue("adminConsoleAccessEnabled")}
        toggleActiveStatus={this.setAdminConsoleAccess}
      />
    );
  };

  getDatasetPermissions = (datasetName) => {
    const role = this.getSelectedRole();
    const roleCapabilities = role?.roleCapabilities || {};
    const datasetPermissions = roleCapabilities[datasetName] || {};
    return ["add", "update", "read", "delete"].map((permission) => ({
      permissionTitle: permission,
      checked: datasetPermissions[permission]?.includes("all") || false
    }));
  };

  showErrorModal = (error) => {
    const { showModal } = this.props;
    this.setErrorModalContent(error.message);
    showModal();
  };

  setErrorModalContent = (errorMessage) => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("GENERIC_ERROR_HEADING"),
      body: <P>{errorMessage}</P>,
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_DETAILS", {
            item: getLabel("ROLE_TEXT")
          }),
          onClick: this.returnToDetailsAction
        }
      ]
    });
  };

  returnToDetailsAction = () => {
    const { hideModal, orgId, roleId } = this.props;
    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${ROLES_URL}/${roleId}`);
  };

  loadDatasets = async () => {
    this.setValue(true, "isLoading");
    try {
      const { orgId, token } = this.props;
      const availableDatasets = await requestOrganisationDatasets(
        orgId,
        token,
        this.controller.signal
      );

      const datasets = availableDatasets.items.map((dataset) => {
        const permissions = this.getDatasetPermissions(dataset.name);
        return {
          title: dataset.name,
          permissions: permissions,
          expanded: false,
          checked: permissions.every((permission) => permission.checked),
          allPermissionsChecked: permissions.every(
            (permission) => permission.checked
          )
        };
      });

      this.setValue(datasets, "datasets");
      this.setValue(false, "isLoading");
    } catch (error) {
      this.setValue(false, "isLoading");
      this.showErrorModal(error);
    }
  };

  getRoleCapabilitiesData = () => {
    const datasets = this.getValue("datasets");
    const roleCapabilities = {};

    datasets.forEach((dataset) => {
      const allPermissionsUnchecked = dataset.permissions.every(
        (permission) => !permission.checked
      );
      if (allPermissionsUnchecked) {
        roleCapabilities[dataset.title] = null;
      } else {
        const permissions = dataset.permissions.reduce((acc, permission) => {
          if (permission.checked) {
            acc[permission.permissionTitle] = ["all"];
          }
          return acc;
        }, {});
        if (Object.keys(permissions).length > 0) {
          roleCapabilities[dataset.title] = permissions;
        }
      }
    });
    return roleCapabilities;
  };

  getAllDatasetsEnabled = () => {
    return this.getValue("datasetsHeader");
  };

  getDatasetsToggleOpen = () => {
    return this.getValue("datasetsToggleAll");
  };

  setDatasetsToggleOpen = () => {
    this.setState({ datasetsToggleAll: !this.getDatasetsToggleOpen() }, () => {
      const newState = this.getValue("datasets").map((item) => ({
        ...item,
        expanded: this.getDatasetsToggleOpen()
      }));
      this.setValue(newState, "datasets");
    });
  };

  handleCheckDatasetsHeader = () => {
    const allDatasetsEnabled = this.getAllDatasetsEnabled();
    const newDatasets = this.getValue("datasets").map((item) => ({
      ...item,
      checked: !allDatasetsEnabled,
      allPermissionsChecked: !allDatasetsEnabled,
      permissions: item.permissions.map((permission) => ({
        ...permission,
        checked: !allDatasetsEnabled
      }))
    }));

    this.setValue(!allDatasetsEnabled, "datasetsHeader");
    this.setValue(newDatasets, "datasets");
  };

  getDatasetsHeaderCheckbox = () => {
    const { getLabel } = this.props;
    const value = this.getAllDatasetsEnabled();
    return (
      <CheckboxV2
        handleUpdate={() => this.handleCheckDatasetsHeader()}
        name={"datasets-header"}
        rowId={"datasets-header"}
        value={value}
        checked={value}
        title={getLabel("ENABLE_ALL_DATASET_PERMISSIONS_LABEL")}
      />
    );
  };

  getDatasetsPageLimit = () => {
    const result = getSession(`role-datasets-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  showDatasetsPagination = () => {
    return this.getValue("datasets").length > this.getDatasetsPageLimit();
  };

  showDatasetsDropdown = () => {
    return (
      this.getValue("datasets").length > this.getDropDownOptions()[0].value
    );
  };

  getDatasetsDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getDatasetsPageLimit()
    });
  };

  updateDatasetsExpandedState = () => {
    const datasetsState = this.getValue("datasets");
    const updatedDatasets = datasetsState.map((dataset) => {
      return {
        ...dataset,
        expanded: false
      };
    });
    this.setValue(updatedDatasets, "datasets");
    this.setValue(false, "datasetsToggleAll");
  };

  setDatasetsPaginationContent = (value) => {
    this.updateDatasetsExpandedState();
    this.setValue(value, "datasetsPageNumber");
  };

  handleCheckDatasetsPermissions = (dataset, permission) => {
    const newState = this.getValue("datasets").map((item) => {
      if (item.title === dataset.title) {
        const permissions = item.permissions.map((singlePermission) => {
          if (singlePermission.permissionTitle === permission.permissionTitle) {
            return { ...permission, checked: !singlePermission.checked };
          }
          return singlePermission;
        });

        const allPermissionsChecked = permissions.every(
          (permission) => permission.checked
        );

        return {
          ...item,
          permissions,
          allPermissionsChecked,
          checked: allPermissionsChecked
        };
      }
      return item;
    });
    this.setValue(newState, "datasets");
  };

  getDatasetsPermissionsCheckbox = (dataset, permission) => {
    return (
      <CheckboxV2
        handleUpdate={() =>
          this.handleCheckDatasetsPermissions(dataset, permission)
        }
        name={"datasets"}
        rowId={"datasetsPermissions"}
        value={this.getValue("")}
        checked={permission.checked}
        title={permission.permissionTitle}
      />
    );
  };

  getUpdatedDatasetPermissions = (dataset) => {
    const datasets = this.getValue("datasets");
    const foundDataset = datasets.find((item) => item.title === dataset.title);
    return foundDataset ? foundDataset.permissions : [];
  };

  getDatasetsPermissionsData = (dataset) => {
    const permissions = this.getUpdatedDatasetPermissions(dataset);
    return permissions.map((permission) => {
      return {
        cells: [this.getDatasetsPermissionsCheckbox(dataset, permission)],
        innerStyleType: "inner-accordion"
      };
    });
  };

  handleUpdateDatasetsPermissions = (dataset) => {
    const newState = this.getValue("datasets").map((item) => {
      if (item.title === dataset.title) {
        const allPermissionsChecked = !item.allPermissionsChecked;
        const permissions = item.permissions.map((permission) => ({
          ...permission,
          checked: allPermissionsChecked
        }));

        return {
          ...item,
          allPermissionsChecked,
          permissions,
          checked: allPermissionsChecked
        };
      }
      return item;
    });

    this.setValue(newState, "datasets");
  };

  getDatasetsPermissionsHeader = (dataset) => {
    const { getLabel } = this.props;
    return {
      cells: [
        {
          content: (
            <CheckboxV2
              handleUpdate={() => this.handleUpdateDatasetsPermissions(dataset)}
              name={"dataset-permissions"}
              rowId={`${dataset.title}-permissions`}
              value={dataset.allPermissionsChecked}
              checked={dataset.allPermissionsChecked}
              title={getLabel("ENABLE_FULL_PERMISSIONS_LABEL")}
            />
          )
        }
      ],
      showToggle: false,
      innerStyleType: "inner-accordion"
    };
  };

  handleCheckDatasets = (dataset) => {
    const newState = this.getValue("datasets").map((item) => {
      const permissions = item.permissions.map((permission) => ({
        ...permission,
        checked: !item.checked
      }));
      if (item.title === dataset.title) {
        return {
          ...item,
          checked: !item.checked,
          allPermissionsChecked: !item.checked,
          permissions
        };
      }
      return item;
    });

    this.setValue(newState, "datasets");
    this.setValue(
      newState.every((item) => item.checked),
      "datasetsHeader"
    );
  };

  getDatasetsCheckbox = (dataset) => {
    return (
      <CheckboxV2
        handleUpdate={() => this.handleCheckDatasets(dataset)}
        name="datasets"
        rowId={dataset.title}
        checked={dataset.checked}
        title={dataset.title}
      />
    );
  };

  getDatasetsList = () => {
    const pageNumberState = this.getValue("datasetsPageNumber");
    const datasetsState = this.getValue("datasets");
    const pageLimit = this.getDatasetsPageLimit();
    if (!datasetsState) return [];
    const lastDataset = pageNumberState * pageLimit;
    const firstDataset = lastDataset - pageLimit;
    const currentDatasetList = datasetsState.slice(firstDataset, lastDataset);
    return currentDatasetList;
  };

  getDatasetsExpanded = (dataset) => {
    if (dataset.permissions.length === 0) return false;
    return dataset.expanded;
  };

  updateDatasetsRowExpanded = (datasetTitle) => {
    const updatedDatasets = this.getValue("datasets").map((dataset) => {
      if (dataset.title !== datasetTitle) return dataset;
      return {
        ...dataset,
        expanded: !dataset.expanded
      };
    });
    this.setValue(updatedDatasets, "datasets");

    const propsDatasetsExpanded = this.getDatasetsList()
      .filter((item) => item.permissions.length !== 0)
      .every((item) => item.expanded);
    this.setValue(propsDatasetsExpanded, "datasetsToggleAll");
  };

  renderDatasetsOptions = (dataset) => {
    const { getLabel } = this.props;
    return (
      <Fieldset>
        <DataTable
          title={getLabel("ENABLE_DATASET_PERMISSIONS_LABEL", {
            item: dataset.title
          })}
          TitleComponent={Legend}
          header={this.getDatasetsPermissionsHeader(dataset)}
          rows={this.getDatasetsPermissionsData(dataset)}
          pagination={{ showPagination: false }}
          pageDropDown={{ showDropdown: false }}
          innerStyleType={"inner-accordion"}
          id={`${dataset.title}-permissions`}
        />
      </Fieldset>
    );
  };

  dropDownOnSelectDatasetsFunction = (value) => {
    this.updateDatasetsExpandedState();
    this.setState({ ["datasetsPageNumber"]: 1 });
    storeSession(`role-datasets-${PAGINATION_KEY}`, value);
  };

  getDatasetsHeader = () => {
    return {
      toggleOpen: this.getDatasetsToggleOpen(),
      setToggleOpen: () => this.setDatasetsToggleOpen(),
      showToggle: true,
      cells: [{ content: this.getDatasetsHeaderCheckbox() }]
    };
  };

  getDatasetsPagination = () => {
    return {
      setPaginationContent: (e) => this.setDatasetsPaginationContent(e),
      activeNumber: this.getValue("datasetsPageNumber"),
      numberOfButtons: this.getDatasetsPageLimit(),
      totalResults: this.getValue("datasets").length,
      showPagination: this.showDatasetsPagination(),
      paginationInput: true
    };
  };

  getDatasetsPageDropDown = () => {
    return {
      showDropdown: this.showDatasetsDropdown(),
      dropDownValue: this.getDatasetsPageLimit(),
      dropDownLabel: this.getDatasetsDropDownLabel(),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: (e) => this.dropDownOnSelectDatasetsFunction(e)
    };
  };

  getDatasetsData = () => {
    const datasets = this.getDatasetsList();
    return datasets.map((dataset) => {
      return {
        id: dataset.title,
        cells: [this.getDatasetsCheckbox(dataset)],
        expanded: this.getDatasetsExpanded(dataset),
        handleUpdateRowExpanded: () =>
          this.updateDatasetsRowExpanded(dataset.title),
        content: this.renderDatasetsOptions(dataset)
      };
    });
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isDetailsLoading() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.getHeaderText()}
                contextMenu={{ visible: false }}
              />
            </Container>

            <Container>
              <Container
                styleType={STYLETYPE_FORM_FIELD_FLEX}
                direction={"row"}
              >
                <Container>
                  <Heading3 styleType={SECTION_HEADER_STYLING}>
                    {getLabel("DETAILS_LABEL", {
                      item: getLabel("ROLE_TEXT")
                    })}
                  </Heading3>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      htmlFor="role-name"
                      styleType={this.isInvalid() ? STYLETYPE_ERROR : ""}
                    >
                      {getLabel("FORM_LABEL_NAME_OF_ROLE")}
                    </FormLabel>
                    <FormInput
                      value={this.getValue("roleName")}
                      onChange={(value) => {
                        this.setValue(value, "roleName");
                      }}
                      styleType={this.isInvalid() ? STYLETYPE_ERROR : ""}
                      id="role-name"
                      required={true}
                      ariaDescribedBy={
                        this.getValidationError() && "role-name-error"
                      }
                    />
                    {this.getValidationError() && (
                      <P styleType={STYLETYPE_ERROR} id="role-name-error">
                        {this.getValidationError()}
                      </P>
                    )}
                  </Container>
                </Container>
              </Container>
              {this.getCurrentUserAdminAccess() && (
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <Fieldset>
                    <Legend>{getLabel("ADMIN_CONSOLE_ACCESS_LABEL")}</Legend>
                    {this.renderAdminConsoleAccessOptions()}
                  </Fieldset>
                </Container>
              )}
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Fieldset>
                  <DataTable
                    title={getLabel("ENABLE_WORKFLOWS_LABEL", {
                      item: this.getItemLabel(PROPERTY_GROUP_TEXT)
                    })}
                    TitleComponent={Legend}
                    header={this.getWorkflowsHeader(PROPERTY_GROUP_TEXT)}
                    rows={this.getWorkflowsData(PROPERTY_GROUP_TEXT)}
                    pagination={this.getWorkflowsPagination(
                      PROPERTY_GROUP_TEXT
                    )}
                    pageDropDown={this.getPageDropDown(PROPERTY_GROUP_TEXT)}
                    styleType={DATA_TABLE_BUTTON_TITLE_STYLING}
                    id={"property-group-workflows"}
                  />
                </Fieldset>
              </Section>
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Fieldset>
                  <DataTable
                    title={getLabel("ENABLE_WORKFLOWS_LABEL", {
                      item: this.getItemLabel(PROPERTY_TEXT)
                    })}
                    TitleComponent={Legend}
                    header={this.getWorkflowsHeader(PROPERTY_TEXT)}
                    rows={this.getWorkflowsData(PROPERTY_TEXT)}
                    pagination={this.getWorkflowsPagination(PROPERTY_TEXT)}
                    pageDropDown={this.getPageDropDown(PROPERTY_TEXT)}
                    styleType={DATA_TABLE_BUTTON_TITLE_STYLING}
                    id={"property-workflows"}
                  />
                </Fieldset>
              </Section>
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Fieldset>
                  <DataTable
                    title={`${getLabel("DATASET_TEXT")} ${getLabel(
                      "ROLE_PERMISSIONS"
                    ).toLowerCase()}`}
                    TitleComponent={Legend}
                    header={this.getDatasetsHeader()}
                    rows={this.getDatasetsData()}
                    pagination={this.getDatasetsPagination()}
                    pageDropDown={this.getDatasetsPageDropDown()}
                    styleType={DATA_TABLE_BUTTON_TITLE_STYLING}
                    id={"datasets"}
                  />
                </Fieldset>
              </Section>
              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <Fieldset>
                    {this.renderWorkflowsRadioOptions(PROPERTY_GROUP_TEXT)}
                  </Fieldset>
                </Container>
              </Section>

              <Section styleType={DATA_TABLE_SECTION_STYLING}>
                <Container styleType={STYLETYPE_FORM_FIELD}>
                  <Fieldset>
                    {this.renderWorkflowsRadioOptions(PROPERTY_TEXT)}
                  </Fieldset>
                </Container>
              </Section>
            </Container>

            <Section>
              <BottomButtonBar buttons={this.getButtons()} />
            </Section>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default UpdateRole;
