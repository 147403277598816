import React, { Component } from "react";
import { PaginationContainer, PaginationPageList } from "../style";
import PaginationPageButton from "./PaginationPageButton";
import PaginationIconButton from "./PaginationIconButton";
import { defaultTheme } from "@UIKit";
import PropTypes from "prop-types";

class PaginationV2Component extends Component {
  static propTypes = {
    /** handleNextPage function called when clicked on > arrow */
    handleNextPage: PropTypes.func,
    /** handlePreviousPage function called when clicked on < arrow */
    handlePreviousPage: PropTypes.func,
    /** firstButtonDisabled return true when on page number 1 else false */
    firstButtonDisabled: PropTypes.bool,
    /** lastButtonDisabled return true when on last page else false */
    lastButtonDisabled: PropTypes.bool,
    /** isActivePage return true when current page same as selected page number else false*/
    isActivePage: PropTypes.func,
    /** pages with array of page numbers*/
    pages: PropTypes.array,
    /** handleSelectPage func updates pages */
    handleSelectPage: PropTypes.func,
    /** handleFirstPage func set to first page number*/
    handleFirstPage: PropTypes.func,
    /** handleLastPage func set to last page number*/
    handleLastPage: PropTypes.func
  };

  handleNextPage = () => {
    const { handleNextPage } = this.props;
    if (handleNextPage) handleNextPage();
  };

  handlePreviousPage = () => {
    const { handlePreviousPage } = this.props;
    if (handlePreviousPage) handlePreviousPage();
  };

  firstButtonDisabled = () => {
    return this.props.firstButtonDisabled === true;
  };

  lastButtonDisabled = () => {
    return this.props.lastButtonDisabled === true;
  };

  isActivePage = (page) => {
    const { isActivePage } = this.props;
    return isActivePage ? isActivePage(page) : false;
  };

  handleSelectPage = (page) => {
    const { handleSelectPage } = this.props;
    if (typeof page === "string") return;
    if (handleSelectPage) handleSelectPage(page);
  };

  pages = () => {
    const { pages } = this.props;
    return pages ? pages : [];
  };

  render() {
    return (
      <PaginationContainer>
        <PaginationPageList>
          <PaginationIconButton
            iconType={"left-arrow"}
            active={this.firstButtonDisabled()}
            isDisabled={this.firstButtonDisabled()}
            onClick={this.handlePreviousPage}
            iconColor={
              this.firstButtonDisabled()
                ? defaultTheme.agMischkaGray
                : defaultTheme.agDarkerGreen
            }
          />
          {this.pages().map((page, index) => (
            <PaginationPageButton
              key={`${page}-${index}`}
              page={page}
              onClick={() => this.handleSelectPage(page)}
              active={this.isActivePage(page)}
              isDisabled={this.isActivePage(page)}
              styleType={typeof page === "string"}
            />
          ))}
          <PaginationIconButton
            iconType={"right-arrow"}
            active={this.lastButtonDisabled()}
            isDisabled={this.lastButtonDisabled()}
            onClick={this.handleNextPage}
            iconColor={
              this.lastButtonDisabled()
                ? defaultTheme.agMischkaGray
                : defaultTheme.agDarkerGreen
            }
          />
        </PaginationPageList>
      </PaginationContainer>
    );
  }
}

export default PaginationV2Component;
