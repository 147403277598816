import React, { Component } from "react";
import PropTypes from "prop-types";
import { RadioButtonElement } from "./style";
import { FORM_STYLE_TYPES, STYLETYPE_RADIOBUTTON } from "@base/constants";
import { FormLabel } from "@core";

/**
 * Button component that creates a new button from style components and optionally includes an Icon
 */
class RadioButton extends Component {
  static propTypes = {
    /**Whether the radio button is disabled or not */
    isDisabled: PropTypes.bool,
    /**Whether the radio button is vertical or horizontal */
    direction: PropTypes.oneOf(["horizontal", "vertical"]),
    /**What label to display in the radioButton */
    label: PropTypes.string,
    /**What to value to return if checked */
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool
    ]),
    /**What to display in the textArea */
    checked: PropTypes.bool,
    /**What to display in the textArea */
    styleType: PropTypes.string,
    /**What function to run on change */
    onChange: PropTypes.func,
    /**What function to run on click */
    onClick: PropTypes.func,
    /**What radioGroup this button belongs to */
    name: PropTypes.string
  };

  /**
   * Returns if the text area is set to disabled, defaults to false
   * @public
   */
  isDisabled = () => {
    return this.props.isDisabled ? this.props.isDisabled : false;
  };

  /**
   * Returns label for the radio button
   * @public
   */
  getLabel = () => {
    return this.props.label ? this.props.label : "";
  };

  /**
   * Returns the value stored against this radio Button
   * @public
   */
  getValue = () => {
    return this.props.value ? this.props.value : false;
  };

  /**
   * Returns the checked value from props
   * @public
   */
  getChecked = () => {
    return this.props.checked ? this.props.checked : false;
  };

  /**
   * Function that runs on change
   * @public
   */
  getOnChange = (event) => {
    const { onChange } = this.props;
    return onChange ? onChange(event) : null;
  };

  /**
   * Function that runs on click
   * @public
   */
  getOnClick = (event) => {
    const { onClick } = this.props;
    return onClick ? onClick(event) : null;
  };

  /**
   * Function to get the props.name
   * @public
   */
  getName = () => {
    return this.props.name ? this.props.name : "";
  };

  /**
   * Function to return props.direction
   * @public
   */
  getDirection = () => {
    return this.props.direction ? this.props.direction : "vertical";
  };

  render() {
    return (
      <FormLabel
        htmlFor={this.props.id}
        styleType={STYLETYPE_RADIOBUTTON}
        direction={this.getDirection()}
      >
        <RadioButtonElement
          id={this.props.id}
          type={"radio"}
          name={this.getName()}
          data-name={"radioButton"}
          disabled={this.isDisabled()}
          direction={this.getDirection()}
          checked={this.getChecked()}
          value={this.getValue()}
          onChange={(event) => {
            this.getOnChange(event.target.value);
          }}
          onClick={(event) => {
            this.getOnClick(event.target.value);
          }}
        ></RadioButtonElement>
        {this.getLabel()}
      </FormLabel>
    );
  }
}

export default RadioButton;
