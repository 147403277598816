import { connect } from "react-redux";
import ArchivedPropertyDetail from "./ArchivedPropertyDetail";
import * as actions from "../../../actions";
import {
  getLabels,
  getPageTitle,
  getAbortController,
  getSelectedProperty,
  getSelectedPropertyStatus,
  getSelectedPropertyError,
  getHasPermission
} from "../../../selectors";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    abortController: getAbortController(state),
    selectedProperty: getSelectedProperty(state),
    selectedPropertyStatus: getSelectedPropertyStatus(state),
    propertyError: getSelectedPropertyError(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType)
  }),
  actions
)(ArchivedPropertyDetail);
