import { connect } from "react-redux";
import RendererOverview from "./RendererOverview";
import * as actions from "../../../actions";
import {
  getLabels,
  getAvailableOrgs,
  getOrgsStatus,
  getSelectedOrgLoading,
  getSelectedOrg,
  getAbortController,
  getHasPermission,
  getSelectedOrgRenderersLoading,
  getSelectedOrgRenderers
} from "../../../selectors";

import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    availableOrganisations: getAvailableOrgs(state),
    organisationsStatus: getOrgsStatus(state),
    organisationsLoading: getOrgsStatus(state) === STATUS_LOADING,
    selectedOrganisationLoading: getSelectedOrgLoading(state),
    selectedOrganisation: getSelectedOrg(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    abortController: getAbortController(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType),
    selectedOrganisationRenderers: getSelectedOrgRenderers(state),
    selectedOrganisationRenderersLoading: getSelectedOrgRenderersLoading(state)
  }),
  actions
)(RendererOverview);
