import styled, { css } from "styled-components";

// @ts-ignore
export const FieldsetElement = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`;

FieldsetElement.displayName = "FieldsetElement";
