import styled, { css } from "styled-components";
import { STYLE_TYPE_FORM_HALF_WIDTH } from "@base/constants";

// @ts-ignore
export const FormElement = styled.form`
  border: none;
  padding: 0;
  margin: 0;
  @media all and (min-width: 600px) {
    width: ${(props) =>
      props.styleType === STYLE_TYPE_FORM_HALF_WIDTH ? "50%" : "100%"};
  }
`;

FormElement.displayName = "FormElement";
