import React, { Component } from "react";
import { FormInput, Button, FormLabel, Container } from "@core";
import {
  STYLETYPE_ERROR,
  STYLETYPE_FORM_FIELD,
  CONTENT_FADE_TIMEOUT
} from "@base/constants";
import ComboboxDropdown from "./subComponents/ComboboxDropdown";
import { defaultTheme } from "../../defaultTheme";
import { ComboboxInputContainer, ComboboxInputWrapper } from "./style";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import ComboboxMultiValue from "./subComponents/ComboboxMultiValue";
import Loader from "../Loader/Loader";
import { LOADER_SIZE_SMALL } from "../../../constants";

export default class Combobox extends Component {
  static propTypes = {
    /** A unique ID for the input */
    id: PropTypes.string,
    /** LIst items to show below the input */
    listItems: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
        selected: PropTypes.bool,
        disabled: PropTypes.bool
      })
    ),
    /** Called on click of a list item */
    onSelectListItem: PropTypes.func,
    /** The selected list item (if any) */
    listValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**  Value of the text input */
    inputValue: PropTypes.string,
    /** Called on change of text input value */
    onChangeInputValue: PropTypes.func,
    /** If the input is disabled */
    inputDisabled: PropTypes.bool,
    /** If the input value is invalid */
    isInvalid: PropTypes.bool,
    /** The label for the text input */
    inputLabel: PropTypes.string,
    /** If multi select is enabled */
    multi: PropTypes.bool,
    /** If is multi select, the selected values */
    multiValues: PropTypes.array,
    /** Called on clear of a selected value if is multi value */
    onClearMultiValue: PropTypes.func,
    /** If list is searching. Indicates whether to show a loader or not */
    isSearching: PropTypes.bool,
    /** Called on clear of input value. If not specified, will call onChangeInputValue with '' */
    onClearInputValue: PropTypes.func
  };
  constructor(props) {
    super(props);
    this.state = {
      showList: false
    };
  }
  componentDidMount() {
    this.setupClickOutsideListener();
  }
  componentWillUnmount() {
    this.removeClickOutsideListener();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.listItems) !==
        JSON.stringify(this.props.listItems) &&
      !this.getShowList()
    ) {
      this.showList();
    }
  }

  setupClickOutsideListener = () => {
    document.addEventListener("click", this.handleClickOutside, {
      capture: true
    });
  };

  removeClickOutsideListener = () => {
    document.removeEventListener("click", this.handleClickOutside, {
      capture: true
    });
  };
  listItems = () => {
    const { listItems } = this.props;
    return listItems
      ? listItems.map((listItem) => ({
          ...listItem,
          selected: this.valueIsSelected(listItem)
        }))
      : [];
  };

  onSelectListItem = (item) => {
    const { onSelectListItem } = this.props;
    if (onSelectListItem) onSelectListItem(item);
    if (!this.getIsMulti()) this.onToggleList();
  };

  listValue = () => {
    const { listValue } = this.props;
    return listValue ? listValue : "";
  };

  valueIsSelected = (option) => {
    if (Object.prototype.hasOwnProperty.call(option, "selected"))
      return option.selected;
    return this.listValue() === option.value;
  };

  inputValue = () => {
    const { inputValue } = this.props;
    return inputValue ? inputValue : "";
  };

  onChangeInputValue = (value) => {
    const { onChangeInputValue } = this.props;
    if (onChangeInputValue) onChangeInputValue(value);
  };

  inputDisabled = () => {
    const { inputDisabled } = this.props;
    return inputDisabled === true;
  };

  getShowList = () => {
    const { showList } = this.state;
    return showList === true;
  };
  isInvalid = () => {
    const { isInvalid } = this.props;
    return isInvalid === true;
  };
  inputLabel = () => {
    const { inputLabel } = this.props;
    return inputLabel;
  };

  clearInputValue = () => {
    const { onClearInputValue } = this.props;
    if (onClearInputValue) onClearInputValue();
    else this.onChangeInputValue("");
  };

  inputValueBlank = () => {
    return (
      !this.inputValue() && !(this.getIsMulti() && this.getMultiValues().length)
    );
  };

  getIsMulti = () => {
    const { multi } = this.props;
    return multi === true;
  };

  getMultiValues = () => {
    const { multiValues } = this.props;
    return multiValues || [];
  };

  onClearMultiValue = (value) => {
    const { onClearMultiValue } = this.props;
    if (!onClearMultiValue) return;
    onClearMultiValue(value);
  };

  getIsSearching = () => {
    const { isSearching } = this.props;
    return isSearching;
  };

  getShowDropdownControl = () => {
    return this.listItems().length ? true : false;
  };

  onToggleList = () => {
    this.setState({
      showList: !this.getShowList()
    });
  };

  handleClickOutside = (e) => {
    const container = document.getElementById(
      `combo-box-wrapper-${this.props.id}`
    );
    if (!container || container.contains(e.target) || !this.getShowList())
      return;
    this.onToggleList();
  };

  showList = () => {
    this.setState({
      showList: true
    });
  };
  render() {
    return (
      <Container
        styleType={STYLETYPE_FORM_FIELD}
        id={`combo-box-wrapper-${this.props.id}`}
      >
        {this.inputLabel() && (
          <FormLabel
            styleType={this.isInvalid() ? STYLETYPE_ERROR : ""}
            htmlFor={this.props.id}
          >
            {this.inputLabel()}
          </FormLabel>
        )}
        <ComboboxInputWrapper isInvalid={this.isInvalid()}>
          <ComboboxInputContainer>
            {this.getIsMulti() &&
              this.getMultiValues().map((item) => (
                <ComboboxMultiValue
                  {...item}
                  key={`selected-${item.value}`}
                  onClear={this.onClearMultiValue}
                />
              ))}
            <FormInput
              value={this.inputValue()}
              onChange={this.onChangeInputValue}
              styleType={this.isInvalid() ? STYLETYPE_ERROR : ""}
              id={this.props.id}
              type={"text"}
              aria-owns={
                this.getShowList() ? `${this.props.id}-autocomplete` : null
              }
              disabled={this.inputDisabled()}
              aria-autocomplete={this.getShowList() ? "list" : null}
              role="combobox"
              onFocus={this.showList}
            />
          </ComboboxInputContainer>

          <CSSTransition
            in={!this.inputValueBlank()}
            timeout={CONTENT_FADE_TIMEOUT}
            classNames="css-transition"
            unmountOnExit
          >
            <Button
              buttonStyleType={"close"}
              isDisabled={false}
              onClick={this.clearInputValue}
              buttonLabel={""}
              ariaLabel={"Clear"}
              icon={{
                type: "close",
                bgWidth: "2em",
                bgHeight: "2em",
                iconHeight: "1.4em",
                iconWidth: "1.4em",
                iconColor: defaultTheme.agDarkRed
              }}
            />
          </CSSTransition>
          {this.getShowDropdownControl() && (
            <Button
              buttonStyleType={"close"}
              isDisabled={false}
              onClick={this.onToggleList}
              ariaLabel={this.getShowList() ? "Close list" : "Open list"}
              icon={{
                type: "down-triangle",
                bgWidth: "2em",
                bgHeight: "2em",
                iconHeight: "1.4em",
                iconWidth: "1.4em",
                iconColor: defaultTheme.agDarkGray
              }}
            />
          )}
        </ComboboxInputWrapper>

        {this.getIsSearching() ? (
          <Loader size={LOADER_SIZE_SMALL} height={"4em"} />
        ) : (
          <ComboboxDropdown
            id={this.props.id}
            listItems={this.listItems()}
            onSelectListItem={this.onSelectListItem}
            showList={this.getShowList()}
          />
        )}
      </Container>
    );
  }
}
