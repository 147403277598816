import React, { Component } from "react";
import { PaginationWrapper } from "./style";
import PaginationComponent from "./subComponents/PaginationComponent";

class Pagination extends Component {
  currentPage = () => {
    const { currentPage } = this.props;
    return currentPage ? currentPage : 1;
  };

  firstPageDisabled = () => {
    const { currentPage } = this.props;
    return currentPage === 1;
  };

  lastPageDisabled = () => {
    const currentPage = this.currentPage();
    return currentPage === this.pageCount();
  };

  pageCount = () => {
    const { listPerPageNum, totalItems } = this.props;
    if (!totalItems || !listPerPageNum) return 0;
    return Math.ceil(totalItems / listPerPageNum);
  };

  paginate = (pageNum) => {
    const { setPaginationContent } = this.props;
    setPaginationContent(pageNum);
  };

  pages = () => {
    const { currentPage } = this.props;
    let pageNumbers = [];
    const pageLimit = 5;
    const pageCount = this.pageCount();
    let upperLimit, lowerLimit;
    lowerLimit = upperLimit = Math.min(currentPage, pageCount);

    for (let b = 1; b < pageLimit && b < pageCount; ) {
      if (lowerLimit > 1) {
        lowerLimit--;
        b++;
      }
      if (b < pageLimit && upperLimit < pageCount) {
        upperLimit++;
        b++;
      }
    }

    for (let i = lowerLimit; i <= upperLimit; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  isActivePage = (pageNumber) => {
    return pageNumber === this.currentPage();
  };

  nextPage = () => {
    const { setPaginationContent } = this.props;
    const currentPage = this.currentPage();
    setPaginationContent(currentPage + 1);
  };

  previousPage = () => {
    const { setPaginationContent } = this.props;
    const currentPage = this.currentPage();
    setPaginationContent(currentPage - 1);
  };

  firstPage = () => {
    const { setPaginationContent } = this.props;
    setPaginationContent(1);
  };

  lastPage = () => {
    const { setPaginationContent } = this.props;
    setPaginationContent(this.pageCount());
  };

  render() {
    return (
      <PaginationWrapper data-name={"PaginationWrapper"}>
        <PaginationComponent
          pages={this.pages()}
          paginate={this.paginate}
          firstPageDisabled={this.firstPageDisabled()}
          lastPageDisabled={this.lastPageDisabled()}
          isActivePage={this.isActivePage}
          nextPage={this.nextPage}
          previousPage={this.previousPage}
          firstPage={this.firstPage}
          lastPage={this.lastPage}
        />
      </PaginationWrapper>
    );
  }
}

export default Pagination;
