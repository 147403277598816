import React from "react";
import PropTypes from "prop-types";
import { Button } from "@core";
import {
  CONTEXT_MENU_ID_LIST,
  CONTEXT_MENU_ID_LIST_ITEM
} from "@base/constants";
import { defaultTheme } from "@UIKit";
import { Container } from "@core";

/**
 * Returns a array of Button components
 * @public
 */
export const renderChildren = (childLinks) => {
  if (!childLinks || childLinks.length === 0) return null;
  return childLinks
    .filter((item) => {
      return item.buttonLabel && item.buttonLabel !== "";
    })
    .map((item, index) => {
      let iconElement;
      if (item.iconType && item.iconType !== "") {
        iconElement = {
          type: item.iconType,
          bgWidth: "30px",
          bgHeight: "30px",
          iconHeight: "20px",
          iconWidth: "20px",
          iconColor: defaultTheme.agOffBlack
        };
      }

      return (
        <Button
          data-name={"ContextMenuButtonListItem"}
          buttonStyleType={CONTEXT_MENU_ID_LIST_ITEM}
          isDisabled={typeof item.onClick !== "function" || item.isDisabled}
          onClick={item.onClick}
          buttonLabel={item.buttonLabel}
          icon={iconElement ? iconElement : null}
          key={`${item.buttonLabel} + button ${index}`}
        />
      );
    });
};

/**
 * Renders a list of buttons for context menu
 */

const ContextMenuButtonList = (props) => {
  return (
    <Container
      data-name={"ContextMenuButtonList"}
      styleType={CONTEXT_MENU_ID_LIST}
    >
      {renderChildren(props.childLinks)}
    </Container>
  );
};

ContextMenuButtonList.propTypes = {
  /** An array of children to render as buttons */
  childLinks: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        /** The label for the childs button  */
        buttonLabel: PropTypes.string,
        /** The link for the childs button (optional) if not supplied, then button will be disabled and render just a text  */
        onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        /** The icon for the childs button (optional) if ommitted, link will be rendered without an icon */
        iconType: PropTypes.string,
        /** toggleOpen function to set openMenu */
        toggleOpen: PropTypes.func
      })
    )
  ])
};

export default ContextMenuButtonList;
