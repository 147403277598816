import styled, { css } from "styled-components";
import {
  APPLICATION_HEADER_ID,
  LOADER_ID,
  CONTENT_VIEW,
  AUTH_VIEW,
  LOGIN_VIEW
} from "@base/constants";

// @ts-ignore
export const WrapperElement = styled.div`
  height: 100%;

  ${(props) =>
    props.styleType === APPLICATION_HEADER_ID &&
    css`
      height: 4.375rem;
      margin: 0rem;
      width: 100%;
    `}

  ${(props) =>
    props.styleType === LOADER_ID &&
    css`
      margin: 0rem;
      min-height: 3.125rem;
      position: ${props.fullPage ? "relative" : "initial"};
      left: 0;
      top: 0;
      width: 100%;
      height: ${(props) =>
        props.loaderHeight ? props.loaderHeight : "calc(100vh - 15em)"};
      z-index: 1000;
    `}

  ${(props) =>
    props.styleType === CONTENT_VIEW &&
    css`
      margin: 1.875rem;
    `}
  ${(props) =>
    props.styleType === AUTH_VIEW &&
    css`
      width: 90%;
      max-width: 21.875rem;
      background-color: ${(props) => props.theme.agWhite};
      border-radius: 0.375rem;
      height: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 1.5rem;
      box-sizing: border-box;
    `}
    ${(props) =>
    props.styleType === LOGIN_VIEW &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `}
`;
WrapperElement.displayName = "WrapperElement";
