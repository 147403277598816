import React, { Component } from "react";
import {
  defaultTheme,
  ContextHeader,
  BottomButtonBar,
  DataTable
} from "@UIKit";
import { P } from "@typography";
import { Container, Wrapper, Section, LinkButton, Icon } from "@core";
import { navigate } from "@reach/router";
import { getSession, storeSession } from "@base/common";
import {
  NEW_URL,
  CONTENT_VIEW,
  PROPERTIES_URL,
  CONTEXT_HEADER_CONTAINER,
  SEARCH_TIMEOUT,
  MINIMUM_SEARCH_STRING_LENGTH,
  BUTTON_TYPE_PRIMARY,
  STYLETYPE_STATUS,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  PROPERTIES_SECTION,
  CREATE_PERMISSION,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  ORGANISATION_URL
} from "@base/constants";

class PropertyOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTimeout: null
    };
  }

  componentDidMount() {
    this.loadProperties();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate(prevProps) {
    const { orgId, pageTitle, setPageTitle, availableOrganisations } =
      this.props;

    if (orgId && availableOrganisations) {
      const org = availableOrganisations.find((org) => org.orgId === orgId);
      const docTitle = `${org.title} properties overview`;
      if (pageTitle !== docTitle) {
        setPageTitle(docTitle);
      }
    }
  }

  setActivePropertiesFilter = (value) => {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearInterval(searchTimeout);
    }

    if (value.length >= MINIMUM_SEARCH_STRING_LENGTH || value === "") {
      const searchTimeout = setTimeout(() => {
        this.setPaginationContent(1, value);
      }, SEARCH_TIMEOUT);
      this.setState({ searchTimeout });
    }
  };

  getFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("PROPERTIES_TEXT")
    });
  };

  loadProperties = () => {
    const {
      setOrgPropertiesPagination,
      setOrgArchivedPropsPagination,
      loadOrganisationProperties,
      loadOrgArchivedProperties,
      orgId
    } = this.props;
    const pageLimit = this.getPageLimit();
    const archivedPropsPageLimit = this.getArchivedPropsPageLimit();
    loadOrganisationProperties(orgId);
    loadOrgArchivedProperties(orgId);
    setOrgPropertiesPagination(0, pageLimit, 0, "");
    setOrgArchivedPropsPagination(0, archivedPropsPageLimit, 0, "");
  };

  createHeaderText = () => {
    const { getLabel, availableOrganisations, orgId } = this.props;
    return availableOrganisations
      ? getLabel("PROPERTIES_HEADING_TEXT", {
          organisation: availableOrganisations.find(
            (org) => org.orgId === orgId
          ).title
        })
      : getLabel("PROPERTIES_TEXT");
  };

  getSecondHeaderText = (label) => {
    const { getLabel } = this.props;
    return getLabel("PROPERTY_TYPE_LABEL", { item: getLabel(label) });
  };

  getOrganisationProperties = () => {
    const { selectedOrganisationProperties } = this.props;
    return selectedOrganisationProperties
      ? selectedOrganisationProperties
      : false;
  };

  createNavigationLink = (link) => {
    return () => {
      navigate(link);
    };
  };

  createNewPropertyLink = () => {
    const { orgId } = this.props;
    return this.createNavigationLink(
      `/${ORGANISATION_URL}/${orgId}/${PROPERTIES_URL}/${NEW_URL}`
    );
  };

  getNewPropertyLabel = () => {
    const { getLabel } = this.props;
    return getLabel("ADD_NEW_LABEL", { item: getLabel("PROPERTY_TEXT") });
  };

  isLoadingPropertyOverview = () => {
    const { selectedOrganisationPropertiesLoading } = this.props;
    return selectedOrganisationPropertiesLoading;
  };

  getPageLimit = () => {
    const result = getSession(`properties-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getPageOffset = () => {
    const { selectedOrgPropertiesOffset } = this.props;
    return selectedOrgPropertiesOffset;
  };

  getCurrentPageNumber = () => {
    const { selectedOrgPropertiesPageNumber } = this.props;
    return selectedOrgPropertiesPageNumber;
  };

  getActivePropertiesSearchText = () => {
    const { selectedOrgPropertiesSearch } = this.props;
    return selectedOrgPropertiesSearch;
  };

  getOrgPropertiesTotal = () => {
    const { selectedOrgPropertiesTotal } = this.props;
    return selectedOrgPropertiesTotal;
  };

  setPaginationContent = (value, searchValue) => {
    const { orgId, setOrgPropertiesPagination, loadOrganisationProperties } =
      this.props;
    const pageLimit = this.getPageLimit();
    let offset = (value - 1) * pageLimit;
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(value, pageLimit, offset, searchValue);
  };

  showPagination = () => {
    return this.getOrgPropertiesTotal() > this.getPageLimit();
  };

  hasCreatePermission = () => {
    const { getHasPermission, orgId } = this.props;
    return getHasPermission(orgId, PROPERTIES_SECTION, CREATE_PERMISSION);
  };

  getButtons = () => {
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        isDisabled: false,
        onClick: this.createNewPropertyLink(),
        buttonLabel: this.getNewPropertyLabel(),
        icon: {
          type: "plus",
          bgWidth: "25px",
          bgHeight: "15px",
          iconHeight: "16px",
          iconWidth: "15px",
          iconColor: defaultTheme.agWhite
        }
      }
    ];
  };

  getContextHeaderButtons = () => {
    return this.hasCreatePermission()
      ? [
          {
            buttonStyleType: BUTTON_TYPE_PRIMARY,
            isDisabled: false,
            onClick: this.createNewPropertyLink(),
            buttonLabel: this.getNewPropertyLabel()
          }
        ]
      : [];
  };

  getNoResultsText = () => {
    const { getLabel } = this.props;
    const filterText = this.getActivePropertiesSearchText();
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getFilterPlaceholderText = () => {
    const { getLabel } = this.props;
    return getLabel("PROPERTIES_FILTER_PLACEHOLDER_LABEL");
  };

  getResultsSubtext = () => {
    const { getLabel } = this.props;
    const listPerPageNum = this.getPageLimit();
    const currentPageNumber = this.getCurrentPageNumber();
    const total = this.getOrgPropertiesTotal();
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      currentPageNumber === 1
        ? currentPageNumber
        : (currentPageNumber - 1) * listPerPageNum + 1;

    const limitEndNumber = currentPageNumber * listPerPageNum;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;

    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  getTableHeader = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        { content: getLabel("PROPERTY_TITLE_LABEL") },
        { content: getLabel("OFFICIAL_NAME_LABEL") },
        { content: getLabel("FORM_STATUS_LABEL") }
      ]
    };
  };

  renderStatusLabel = (label, color) => {
    const { getLabel } = this.props;
    return (
      <Container direction={"row"} styleType={STYLETYPE_STATUS} dataTableStatus>
        <Icon
          type={"circle"}
          iconColor={color}
          bgShape="round"
          iconWidth="20px"
          iconHeight="20px"
        />
        <P styleType={STYLETYPE_STATUS}>{getLabel(label)}</P>
      </Container>
    );
  };

  getActivePropertyRows = () => {
    const { orgId } = this.props;
    const propertyList = this.getOrganisationProperties();
    if (!propertyList) return;
    return propertyList.map((property) => {
      const title = (
        <LinkButton
          buttonLabel={property.title}
          url={`/${orgId}/${PROPERTIES_URL}/${property.propId}`}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      );
      const officialName = (
        <LinkButton
          buttonLabel={property.officialName}
          url={`/${orgId}/${PROPERTIES_URL}/${property.propId}`}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      );
      return {
        cells: [
          title,
          officialName,
          this.renderStatusLabel("ACTIVE_LABEL", defaultTheme.agGreen)
        ]
      };
    });
  };

  getActivePropertiesPagination = () => {
    return {
      setPaginationContent: this.setPaginationContent,
      activeNumber: this.getCurrentPageNumber(),
      numberOfButtons: this.getPageLimit(),
      totalResults: this.getOrgPropertiesTotal(),
      showPagination: this.showPagination(),
      paginationInput: true
    };
  };

  showActivePropertiesDropdown = () => {
    return this.getOrgPropertiesTotal() > this.getDropDownOptions()[0].value;
  };

  dropDownOnSelectFunction = (value) => {
    const { orgId, loadOrganisationProperties, setOrgPropertiesPagination } =
      this.props;
    loadOrganisationProperties(orgId);
    setOrgPropertiesPagination(0, value, 0, "");
    storeSession(`properties-${PAGINATION_KEY}`, value);
  };

  getDropDownLabel = (limit) => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: limit
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getActivePropertiesPageDropdown = () => {
    return {
      showDropdown: this.showActivePropertiesDropdown(),
      dropDownValue: this.getPageLimit(),
      dropDownLabel: this.getDropDownLabel(this.getPageLimit()),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: this.dropDownOnSelectFunction
    };
  };

  getOrgArchivedProperties = () => {
    const { selectedOrgArchivedProperties } = this.props;
    return selectedOrgArchivedProperties;
  };

  getOrgArchivedPropertiesTotal = () => {
    const { selectedOrgArchivedPropertiesTotal } = this.props;
    return selectedOrgArchivedPropertiesTotal;
  };

  getArchivedPropsPageLimit = () => {
    const result = getSession(`archived-properties-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getArchivedPropsPageOffset = () => {
    const { selectedOrgArchivedPropsOffset } = this.props;
    return selectedOrgArchivedPropsOffset;
  };

  getArchivedPropsCurrentPageNumber = () => {
    const { selectedOrgArchivedPropsPage } = this.props;
    return selectedOrgArchivedPropsPage;
  };

  getArchivedPropsSearchText = () => {
    const { selectedOrgArchivedPropsSearch } = this.props;
    return selectedOrgArchivedPropsSearch;
  };

  getArchivedPropsResultsSubtext = () => {
    const { getLabel } = this.props;
    const listPerPageNum = this.getArchivedPropsPageLimit();
    const currentPageNumber = this.getArchivedPropsCurrentPageNumber();
    const total = this.getOrgArchivedPropertiesTotal();
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      currentPageNumber === 1
        ? currentPageNumber
        : (currentPageNumber - 1) * listPerPageNum + 1;

    const limitEndNumber = currentPageNumber * listPerPageNum;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;

    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  setArchivedFilterText = (value) => {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearInterval(searchTimeout);
    }

    if (value.length >= MINIMUM_SEARCH_STRING_LENGTH || value === "") {
      const searchTimeout = setTimeout(() => {
        this.setArchivedPropsPagination(1, value);
      }, SEARCH_TIMEOUT);
      this.setState({ searchTimeout });
    }
  };

  getArchivedPropertiesRows = () => {
    const { orgId } = this.props;
    const propertyList = this.getOrgArchivedProperties();
    if (!propertyList) return;
    return propertyList.map((property) => {
      const title = (
        <LinkButton
          buttonLabel={property.title}
          url={`/${orgId}/${PROPERTIES_URL}/${property.propId}/archived`}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      );
      return {
        cells: [
          title,
          property.officialName,
          this.renderStatusLabel("ARCHIVED_LABEL", defaultTheme.agRed)
        ]
      };
    });
  };

  showArchivedPropsPagination = () => {
    return (
      this.getOrgArchivedPropertiesTotal() > this.getArchivedPropsPageLimit()
    );
  };

  setArchivedPropsPagination = (value, searchValue) => {
    const archivedPropsPageLimit = this.getArchivedPropsPageLimit();
    const { orgId, setOrgArchivedPropsPagination, loadOrgArchivedProperties } =
      this.props;
    let offset = (value - 1) * archivedPropsPageLimit;
    loadOrgArchivedProperties(orgId);
    setOrgArchivedPropsPagination(
      value,
      archivedPropsPageLimit,
      offset,
      searchValue
    );
  };

  getArchivedPropsPagination = () => {
    return {
      setPaginationContent: this.setArchivedPropsPagination,
      activeNumber: this.getArchivedPropsCurrentPageNumber(),
      numberOfButtons: this.getArchivedPropsPageLimit(),
      totalResults: this.getOrgArchivedPropertiesTotal(),
      showPagination: this.showArchivedPropsPagination(),
      paginationInput: true
    };
  };

  showArchivedPropsDropdown = () => {
    return (
      this.getOrgArchivedPropertiesTotal() > this.getDropDownOptions()[0].value
    );
  };

  archivedDropDownOnSelectFn = (value) => {
    const { orgId, loadOrgArchivedProperties, setOrgArchivedPropsPagination } =
      this.props;
    loadOrgArchivedProperties(orgId);
    setOrgArchivedPropsPagination(0, value, 0, "");
    storeSession(`archived-properties-${PAGINATION_KEY}`, value);
  };

  getArchivedPropsPageDropDown = () => {
    return {
      showDropdown: this.showArchivedPropsDropdown(),
      dropDownValue: this.getArchivedPropsPageLimit(),
      dropDownLabel: this.getDropDownLabel(this.getArchivedPropsPageLimit()),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: this.archivedDropDownOnSelectFn
    };
  };

  getArchivedPropsDataLoading = () => {
    return this.props.selectedOrgArchivedPropertiesLoading === true;
  };

  getArchivedPropsNoResultsText = () => {
    const { getLabel } = this.props;
    const filterText = this.getArchivedPropsSearchText();
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  render() {
    return (
      <Container styleType={CONTENT_VIEW}>
        <Wrapper data-name={"Wrapper"} subStyle="App" styleType={CONTENT_VIEW}>
          <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
            <ContextHeader
              headerTitle={this.createHeaderText()}
              contextMenu={{ visible: false }}
              buttons={this.getContextHeaderButtons()}
            />
          </Container>
          <Container>
            <Section styleType={DATA_TABLE_SECTION_STYLING}>
              <DataTable
                title={this.getSecondHeaderText("ACTIVE_LABEL")}
                showResultsText={true}
                resultsSubtext={this.getResultsSubtext()}
                tableFilter={{
                  placeholderText: this.getFilterPlaceholderText(),
                  filterLabel: this.getFilterLabel(),
                  showFilter: true,
                  filterFunction: this.setActivePropertiesFilter,
                  isDisabled: false,
                  searchText: this.getActivePropertiesSearchText(),
                  inputId: this.getFilterLabel()
                }}
                noResultsText={this.getNoResultsText()}
                header={this.getTableHeader()}
                rows={this.getActivePropertyRows()}
                pagination={this.getActivePropertiesPagination()}
                pageDropDown={this.getActivePropertiesPageDropdown()}
                loading={this.isLoadingPropertyOverview()}
              />
            </Section>
          </Container>
          <Container>
            <Section styleType={DATA_TABLE_SECTION_STYLING}>
              <DataTable
                title={this.getSecondHeaderText("ARCHIVED_LABEL")}
                showResultsText={true}
                resultsSubtext={this.getArchivedPropsResultsSubtext()}
                tableFilter={{
                  filterLabel: this.getFilterLabel(),
                  showFilter: true,
                  filterFunction: this.setArchivedFilterText,
                  isDisabled: false,
                  searchText: this.getArchivedPropsSearchText(),
                  inputId: this.getSecondHeaderText("ARCHIVED_LABEL")
                }}
                noResultsText={this.getArchivedPropsNoResultsText()}
                header={this.getTableHeader()}
                rows={this.getArchivedPropertiesRows()}
                pagination={this.getArchivedPropsPagination()}
                pageDropDown={this.getArchivedPropsPageDropDown()}
                loading={this.getArchivedPropsDataLoading()}
              />
              {this.hasCreatePermission() && (
                <BottomButtonBar buttons={this.getButtons()} />
              )}
            </Section>
          </Container>
        </Wrapper>
      </Container>
    );
  }
}

export default PropertyOverview;
