import { createLogic } from "redux-logic";
import {
  LOAD_WORKFLOW,
  LOAD_WORKFLOW_PROPERTY_GROUPS,
  LOAD_WORKFLOW_PROPERTIES,
  CREATE_WORKFLOW,
  REMOVE_WORKFLOW,
  REMOVED_WORKFLOW,
  UPDATE_WORKFLOW,
  UPDATED_WORKFLOW,
  ABORT_ERROR_NAME
} from "@base/constants";

const updateWorkflowLogic = createLogic({
  type: UPDATE_WORKFLOW,
  async process(
    { globalActions, agBoxApiUpdates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, updatedWorkflow } = globalActions;
      const { DELETING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(DELETING_LABEL));

      const token = getToken(state);
      const { orgId, workflowId, data } = action.payload;
      const { partiallyUpdateWorkflow } = agBoxApiUpdates;
      const signal = getAbortSignal(getState());
      // skipcq JS-0128
      const results = await partiallyUpdateWorkflow(
        orgId,
        workflowId,
        data,
        token,
        signal
      );
      dispatch(updatedWorkflow(orgId, workflowId));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const updatedWorkflowLogic = createLogic({
  type: UPDATED_WORKFLOW,
  async process(
    { globalActions, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const { getLabels } = selectors;
      const { setLoadingText, loadWorkflow } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const { orgId, workflowId } = action.payload;

      dispatch(loadWorkflow(orgId, workflowId));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const removeWorkflowLogic = createLogic({
  type: REMOVE_WORKFLOW,
  async process(
    { globalActions, agBoxApiRemoves, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, removedWorkflow } = globalActions;
      const { DELETING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(DELETING_LABEL));

      const token = getToken(state);
      const { orgId, workflowId } = action.payload;
      const { removeWorkflow } = agBoxApiRemoves;
      const signal = getAbortSignal(getState());
      const results = await removeWorkflow(orgId, workflowId, token, signal); // skipcq JS-0128
      dispatch(removedWorkflow());

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const removedWorkflowLogic = createLogic({
  type: REMOVED_WORKFLOW,
  async process({ globalActions, getState, selectors }, dispatch, done) {
    try {
      const { getLabels } = selectors;
      const { setLoadingText, loadedOrganisationWorkflows } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      dispatch(loadedOrganisationWorkflows(false));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const createWorkflowLogic = createLogic({
  type: CREATE_WORKFLOW,
  async process(
    { globalActions, agBoxApiCreates, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, createdWorkflow } = globalActions;
      const { CREATING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(CREATING_LABEL));

      const token = getToken(state);
      const { orgId, data } = action.payload;
      const { createWorkflow } = agBoxApiCreates;
      const signal = getAbortSignal(getState());
      const workflow = await createWorkflow(orgId, data, token, signal);
      dispatch(createdWorkflow(workflow));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);

      const { setError, createWorkflowFailed } = globalActions;
      dispatch(setError(e));
      dispatch(createWorkflowFailed());
      done();
    }
  }
});

const loadWorkflowLogic = createLogic({
  type: LOAD_WORKFLOW,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedWorkflow, setPageTitle } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      const token = getToken(state);
      const { orgId, workflowId } = action.payload;
      dispatch(setLoadingText(LOADING_LABEL));
      dispatch(setPageTitle(""));

      const { requestWorkflow } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const role = await requestWorkflow(orgId, workflowId, token, signal);
      dispatch(loadedWorkflow(role));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadWorkflowPropertyGroupsLogic = createLogic({
  type: LOAD_WORKFLOW_PROPERTY_GROUPS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedWorkflowPropertyGroups } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);
      const { orgId, workflowId } = action.payload;

      const { requestWorkflowPropertyGroups } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const propertyGroup = await requestWorkflowPropertyGroups(
        orgId,
        workflowId,
        token,
        signal
      );
      dispatch(loadedWorkflowPropertyGroups(propertyGroup));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

const loadWorkflowPropertiesLogic = createLogic({
  type: LOAD_WORKFLOW_PROPERTIES,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors, action },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getLabels } = selectors;
      const { setLoadingText, loadedWorkflowProperties } = globalActions;
      const { LOADING_LABEL } = getLabels(getState());
      dispatch(setLoadingText(LOADING_LABEL));

      const token = getToken(state);
      const { orgId, workflowId } = action.payload;

      const { requestWorkflowProperties } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const properties = await requestWorkflowProperties(
        orgId,
        workflowId,
        token,
        signal
      );
      dispatch(loadedWorkflowProperties(properties));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { setError } = globalActions;
      dispatch(setError(e));
      done();
    }
  }
});

export default [
  updateWorkflowLogic,
  updatedWorkflowLogic,
  removeWorkflowLogic,
  removedWorkflowLogic,
  createWorkflowLogic,
  loadWorkflowLogic,
  loadWorkflowPropertyGroupsLogic,
  loadWorkflowPropertiesLogic
];
