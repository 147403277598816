import React from "react";
import { ParagraphElement } from "./style";
import PropTypes from "prop-types";

/** Renders Paragraph Element - Used for generic blocks of text */
const P = (props) => {
  return (
    <ParagraphElement
      id={props.id}
      styleType={props.styleType ? props.styleType : ""}
    >
      {props.children}
    </ParagraphElement>
  );
};

P.propTypes = {
  styleType: PropTypes.string,
  /** id for p element */
  id: PropTypes.string,
  /** children */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default P;
