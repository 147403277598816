import React, { Component } from "react";
import { ApplicationHeader, BreadCrumbNavigation, Loader } from "@UIKit";
import { navigate } from "@reach/router";
import * as constants from "@base/constants";
import Error from "../Error";
import {
  READ_PERMISSION,
  USERS_URL,
  UPDATE_USER_URL,
  UPDATE_URL
} from "../../constants";

class Main extends Component {
  getUserName = () => {
    const { userName, getLabel } = this.props;
    return userName
      ? userName
      : getLabel("NO_DATA_FOUND_TEXT", { dataItem: "Username" });
  };

  getHeadingTitle = () => {
    const { ADMIN_CONSOLE_HEADING } = this.props.getLabel();
    return ADMIN_CONSOLE_HEADING ? ADMIN_CONSOLE_HEADING : "";
  };

  isCurrentPath = (urlPathLink) => {
    if (!urlPathLink) return false;
    const { uri } = this.props;
    return uri === urlPathLink;
  };

  generateBreadCrumbs = () => {
    const { HOMEPAGE_LABEL, NEW_ORG_LABEL } = this.props.getLabel();
    const { HOME_URL, NEW_ORG_URL } = constants;
    let breadCrumbs = [
      {
        label: HOMEPAGE_LABEL,
        link: HOME_URL,
        disabled: this.isCurrentPath(HOME_URL)
      }
    ];

    const { uri } = this.props;

    if (uri === NEW_ORG_URL) {
      let newOrgBreadCrumbs = [
        ...breadCrumbs,
        {
          label: NEW_ORG_LABEL,
          link: "/new",
          disabled: true
        }
      ];
      return newOrgBreadCrumbs;
    }

    if (uri.split("/")[1] === USERS_URL) {
      return this.createSuperHumanUserBreadCrumb(breadCrumbs);
    }

    const { organisations } = this.props;
    if (organisations && organisations.available) {
      const breadCrumbFamily = uri.split("/").filter((item) => item);
      breadCrumbs =
        breadCrumbFamily && breadCrumbFamily.length
          ? this.createOrganisationsBreadCrumb(breadCrumbs, breadCrumbFamily[1])
          : breadCrumbs;
      if (breadCrumbFamily.includes(constants.UPDATE_ORG_URL)) {
        return breadCrumbs;
      }

      if (breadCrumbFamily.length > 2) {
        const grandChildPageTitle = uri.split("/")[3];
        breadCrumbs = this.createGrandChildBreadCrumb(
          breadCrumbs,
          grandChildPageTitle
        );
      }
      if (breadCrumbFamily.length > 3) {
        const { pageTitle } = this.props;
        const greatGrandChildPageLink = uri.split("/")[4];
        breadCrumbs = this.createGreatGrandChildBreadCrumb(
          breadCrumbs,
          pageTitle ? pageTitle : uri.split("/")[4],
          greatGrandChildPageLink
        );
      }
    }
    return breadCrumbs;
  };

  createSuperHumanUserBreadCrumb = (breadCrumbs) => {
    const { uri } = this.props;
    const { HOME_URL } = constants;
    const grandChild = uri.split("/")[2];
    const { pageTitle } = this.props;

    const greatGrandChild = uri.split("/").includes(UPDATE_USER_URL)
      ? [
          {
            label: UPDATE_URL,
            link: UPDATE_URL,
            disabled: true
          }
        ]
      : [];

    return [
      ...breadCrumbs,
      {
        label: "Users",
        link: HOME_URL,
        disabled: this.isCurrentPath(HOME_URL)
      },
      {
        label: pageTitle ? pageTitle : grandChild,
        link: grandChild,
        disabled: true
      },
      ...greatGrandChild
    ];
  };

  createOrganisationsBreadCrumb = (breadCrumbs, orgIdFromUrl) => {
    const { organisations } = this.props;
    const { HOME_URL } = constants;
    const { ORGANISATION_TEXT } = this.props.getLabel();

    if (
      !organisations ||
      !organisations.available ||
      organisations.available.orgId
    )
      return breadCrumbs;

    const currentOrg = organisations.available.find(
      (org) => org.orgId === orgIdFromUrl
    );
    if (!currentOrg) return breadCrumbs;
    const otherOrgs = organisations.available.filter(
      (org) => org.orgId !== orgIdFromUrl
    );
    return [
      ...breadCrumbs,
      {
        label: ORGANISATION_TEXT,
        link: HOME_URL,
        disabled: this.isCurrentPath(HOME_URL)
      },
      {
        label: currentOrg.title,
        link: `/${currentOrg.orgId}`,
        siblingMenu: otherOrgs
          ? otherOrgs.map((org) => {
              return {
                label: org.title,
                link: `/${org.orgId}`
              };
            })
          : [],
        disabled: false
      }
    ];
  };

  getOrgId = () => {
    const splitUrl = window.location.pathname.split("/");
    if (splitUrl.length > 2) return splitUrl[2];
    return "";
  };

  createGrandChildBreadCrumb = (breadCrumbs, grandChildPageTitle) => {
    const { organisations, uri, getLabel, getHasPermission } = this.props;
    if (
      !organisations ||
      !organisations.available ||
      organisations.available.orgId
    )
      return breadCrumbs;
    const currentOrg = organisations.available.find(
      (org) => org.orgId === uri.split("/").filter((item) => item)[1]
    );

    if (!currentOrg) return null;

    if (!grandChildPageTitle) return breadCrumbs;
    let grandChildPages = [
      constants.PROPERTY_GROUPS,
      constants.PROPERTIES,
      constants.ROLES,
      constants.USERS,
      constants.WORKFLOWS,
      constants.UPDATE_ORG,
      constants.RENDERERS,
      constants.DATASETS
    ].map((childPage) => {
      return {
        title: getLabel()[`${childPage}_TEXT`],
        link: `/${currentOrg.orgId}/${constants[`${childPage}_URL`]}`,
        page: constants[`${childPage}_URL`]
      };
    });
    const grandChildPage = grandChildPages.find(
      (item) => item.page === grandChildPageTitle
    );

    if (grandChildPage === undefined) return null;

    grandChildPages = grandChildPages.filter(
      (item) => item.page !== grandChildPageTitle
    );

    return [
      ...breadCrumbs,
      {
        label: grandChildPage.title,
        link: grandChildPage.link,
        siblingMenu: grandChildPages.reduce((result, grandChildPageSibling) => {
          if (
            !grandChildPageSibling.page.includes("update") &&
            !getHasPermission(
              this.getOrgId(),
              grandChildPageSibling.page,
              READ_PERMISSION
            )
          )
            return result;
          return [
            ...result,
            {
              label: grandChildPageSibling.title,
              link: grandChildPageSibling.link
            }
          ];
        }, []),
        disabled: false
      }
    ];
  };

  createGreatGrandChildBreadCrumb = (breadCrumbs, pageTitle, link) => {
    return !pageTitle
      ? breadCrumbs
      : [
          ...breadCrumbs,
          {
            label: pageTitle,
            disabled: true,
            link: link
          }
        ];
  };

  createNavigationLink = (link) => {
    return () => {
      navigate(`${link}`);
    };
  };

  getUserReady = () => {
    const { userAuthenticated, userLoading } = this.props;
    return userAuthenticated && !userLoading;
  };

  getLoadingText = () => {
    const { getLabel } = this.props;
    const { LOGGING_OUT_LABEL } = getLabel();
    return LOGGING_OUT_LABEL;
  };

  getSignOutAction = () => {
    const { invalidateAuthentication } = this.props;
    return invalidateAuthentication;
  };

  getLoggingOut = () => {
    const { loggingOut } = this.props;
    return loggingOut;
  };

  render() {
    return (
      <Error>
        <ApplicationHeader
          headingTitle={this.getHeadingTitle()}
          signOutLink={this.getSignOutAction()}
          userName={this.getUserName()}
        />
        {this.getUserReady() && (
          <BreadCrumbNavigation crumbs={this.generateBreadCrumbs()} />
        )}
        <Loader
          fullPage={true}
          visible={this.getLoggingOut()}
          loadingText={this.getLoadingText()}
        />
        {this.getUserReady() && this.props.children}
      </Error>
    );
  }
}

export default Main;
