import React, { Component } from "react";
import PropTypes from "prop-types";
import { BreadCrumbSiblingMenuList } from "../style";
import BreadCrumbSiblingMenuLink from "./BreadCrumbSiblingMenuLink";

/**
 * The BreadCrumbSiblingMenu component renders a list of links for the sibling menu

 */

class BreadCrumbSiblingMenu extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    /** Array of links that describe the submenu navigation */
    siblingMenu: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string
      })
    ),
    /** Boolean indicating if menu is open or not */
    open: PropTypes.bool.isRequired
  };

  /**
   * Checks if the prop of siblingMenu
   * Returns false if any of the above is false, otherwise returns prop.siblingMenu.
   * @public
   */

  getMenuLinks = () => {
    const { siblingMenu } = this.props;
    return siblingMenu ? siblingMenu : [];
  };

  /**
      * Checks if the label property of a menuLink object exists and not empty string
      * Returns false if any of the above is false, otherwise returns the label
      * @param menuLink - Object to check the property for, should contain both label and link.

      * @public
      */

  getMenuLabel = (menuLink) => {
    const { label } = menuLink;
    return label && label !== "" ? label : false;
  };

  /**
      * Checks if the link property of a menuLink object exists and not empty string
      * Returns empty string if any of the above is false, otherwise returns the link
      * @param menuLink - Object to check the property for, should contain both label and link.

      * @public
      */

  getMenuLink = (menuLink) => {
    const { link } = menuLink;
    return link && link !== "" ? link : false;
  };

  /**
   * Checks if the prop of open
   * Returns absolute true or false based on if open is populated
   * @public
   */
  isOpen = () => {
    const { open } = this.props;
    return !!open;
  };

  render() {
    const menuLinks = this.getMenuLinks();
    return (
      <BreadCrumbSiblingMenuList
        open={this.isOpen()}
        data-name={"BreadCrumbSiblingMenuList"}
      >
        {menuLinks.map((menuLink, index) => {
          const menuLinkLabel = this.getMenuLabel(menuLink);
          const menuLinkURL = this.getMenuLink(menuLink);

          return (
            menuLinkLabel &&
            menuLinkURL && (
              <BreadCrumbSiblingMenuLink
                key={`${menuLinkLabel}-siblingLink-${menuLinkURL}-${index}`}
                link={this.getMenuLink(menuLink)}
                label={this.getMenuLabel(menuLink)}
              />
            )
          );
        })}
      </BreadCrumbSiblingMenuList>
    );
  }
}
export default BreadCrumbSiblingMenu;
