import React from "react";
import PropTypes from "prop-types";
import { Container } from "@core";
import { SwitchWrapper, SwitchInput, ToggleLabel } from "./style";
import {
  STYLETYPE_TOGGLE_SWITCH,
  STYLETYPE_TOGGLE_SWITCH_CONTAINER
} from "@base/constants";
/**  Toggle switch component */
const ToggleSwitch = ({ isToggled, toggleActiveStatus, id, label }) => {
  return (
    <Container direction={"row"} styleType={STYLETYPE_TOGGLE_SWITCH_CONTAINER}>
      <Container styleType={STYLETYPE_TOGGLE_SWITCH}>
        <SwitchWrapper>
          <SwitchInput
            id={id}
            type="checkbox"
            checked={isToggled}
            onChange={toggleActiveStatus}
            aria-describedby={`${id}-description`}
          />
          <ToggleLabel id={`${id}-description`}>{label}</ToggleLabel>
        </SwitchWrapper>
      </Container>
    </Container>
  );
};

ToggleSwitch.propTypes = {
  /** isToggled is either true or false  */
  isToggled: PropTypes.bool,
  /** toggleActiveStatus, called onChange and update isToggled in parent component */
  toggleActiveStatus: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /** label is a string that can be read by screenreaders to make the component accessibilty & a11y compliant*/
  label: PropTypes.string
};

export default ToggleSwitch;
