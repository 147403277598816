import styled, { css } from "styled-components";
import { FormInputElement } from "../../core/FormInput/style";

export const ComboboxList = styled.ul`
  border: 1px solid ${(props) => props.theme.agLightestGray};
  max-height: 18.75rem;
  overflow-y: auto;
`;

ComboboxList.displayName = "ComboboxList";

export const ComboboxListItem = styled.li`
  padding: 0.4rem;
  cursor: pointer;
  background-color: ${(props) => props.theme.agWhite};
  &:nth-child(2n) {
    background-color: ${(props) => props.theme.agLightestGray};
  }
  &:hover,
  &[aria-selected="true"] {
    background-color: ${(props) => props.theme.agLighterGreen};
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.7;
  }
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`;
ComboboxListItem.displayName = "ComboboxListItem";

export const ComboboxInputWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;
  flex-grow: 0;
  flex-shrink: 100;
  padding: 0 0.1rem;
  min-width: 18.75rem;
  width: 100%;
  flex: 1 1 0%;
  box-sizing: border-box;
  min-height: 1.5em;
  background-color: ${(props) => props.theme.agWhite};
  padding: 0.2em 0.5em;
  border: ${(props) =>
    props.isInvalid ? `2px solid ${props.theme.agRed}` : "none"};
  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
  input {
    flex: 1;
    opacity: 1;
    border: none;
    padding: 0;
    min-width: 12.5rem;
    &:focus,
    &:focus-visible {
      border: none;
      outline: none;
    }
  }
  button {
    border: none;
    padding: 0;
  }
  * {
    box-sizing: border-box;
  }
`;

export const ComboboxInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const ComboboxMultiItemWrapper = styled.div`
  background-color: ${(props) => props.theme.agLighterGray};
  border-radius: 0.625rem;
  padding: 0.1em 0.3em;
  display: flex;
  margin: 0.1em;
  max-width: 30%;
  align-items: center;
  button {
    padding: 0;
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const ComboboxMultiItemText = styled.p`
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ComboboxListItemSubtitle = styled.span`
  font-size: 0.7em;
  float: right;
`;
