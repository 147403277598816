import { handleActions } from "redux-actions";
import {
  LOAD_PROPERTY,
  LOADED_PROPERTY,
  LOAD_PROPERTY_WORKFLOWS,
  LOADED_PROPERTY_WORKFLOWS,
  LOAD_PROPERTY_PROPERTY_GROUPS,
  LOADED_PROPERTY_PROPERTY_GROUPS,
  LOAD_PROPERTY_USERS,
  LOADED_PROPERTY_USERS,
  STATUS_READY,
  STATUS_LOADING,
  STATUS_CREATING,
  STATUS_REMOVING,
  STATUS_UPDATING,
  STATUS_ARCHIVING,
  STATUS_RESTORING,
  REMOVE_PROPERTY,
  REMOVED_PROPERTY,
  CREATED_PROPERTIES,
  CREATE_PROPERTIES,
  UPDATE_PROPERTY,
  UPDATED_PROPERTY,
  ARCHIVE_PROPERTY,
  ARCHIVED_PROPERTY,
  RESTORE_PROPERTY,
  RESTORED_PROPERTY,
  LOAD_ARCHIVED_PROPERTY,
  LOADED_ARCHIVED_PROPERTY,
  SET_PROPERTY_ERROR,
  IMPORT_DATA_FOR_PROPERTIES,
  IMPORTED_DATA_FOR_PROPERTIES,
  UPDATE_PROPERTY_DATA,
  UPDATED_PROPERTY_DATA
} from "@base/constants";

export const defaultState = {
  status: STATUS_READY,
  selectedProperty: null,
  selectedPropertyUsers: {
    isLoading: false,
    available: [],
    count: 0
  },
  selectedPropertyWorkflows: {
    isLoading: false,
    available: [],
    count: 0
  },
  selectedPropertyPropertyGroups: {
    isLoading: false,
    available: [],
    count: 0
  },
  propertyError: false,
  createdProperties: []
};
// skipcq JS-0128

export default handleActions(
  {
    [LOAD_PROPERTY]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      selectedProperty: null
    }),

    [LOADED_PROPERTY]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      selectedProperty: payload
    }),

    [LOAD_PROPERTY_WORKFLOWS]: (state) => ({
      ...state,
      selectedPropertyWorkflows: {
        isLoading: true,
        available: [],
        count: 0
      }
    }),
    [LOADED_PROPERTY_WORKFLOWS]: (state, { payload }) => ({
      ...state,
      selectedPropertyWorkflows: {
        isLoading: false,
        available: payload.items,
        count: payload.meta.total
      }
    }),
    [LOAD_PROPERTY_PROPERTY_GROUPS]: (state) => ({
      ...state,
      selectedPropertyPropertyGroups: {
        isLoading: true,
        available: [],
        count: 0
      }
    }),
    [LOADED_PROPERTY_PROPERTY_GROUPS]: (state, { payload }) => ({
      ...state,
      selectedPropertyPropertyGroups: {
        isLoading: false,
        available: payload.items,
        count: payload.meta.total
      }
    }),
    [LOAD_PROPERTY_USERS]: (state) => ({
      ...state,
      selectedPropertyUsers: {
        isLoading: true,
        available: [],
        count: 0
      }
    }),
    [LOADED_PROPERTY_USERS]: (state, { payload }) => ({
      ...state,
      selectedPropertyUsers: {
        isLoading: false,
        available: payload.items,
        count: payload.meta.total
      }
    }),
    [CREATE_PROPERTIES]: (state) => ({
      ...state,
      status: STATUS_CREATING,
      createdProperties: []
    }),
    [CREATED_PROPERTIES]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      createdProperties: payload.properties
    }),
    [REMOVE_PROPERTY]: (state) => ({
      ...state,
      status: STATUS_REMOVING
    }),
    [REMOVED_PROPERTY]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [UPDATE_PROPERTY]: (state) => ({
      ...state,
      status: STATUS_UPDATING
    }),
    [UPDATED_PROPERTY]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [ARCHIVE_PROPERTY]: (state) => ({
      ...state,
      status: STATUS_ARCHIVING
    }),
    [ARCHIVED_PROPERTY]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [RESTORE_PROPERTY]: (state) => ({
      ...state,
      status: STATUS_RESTORING
    }),
    [RESTORED_PROPERTY]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [LOAD_ARCHIVED_PROPERTY]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      selectedProperty: null
    }),
    [LOADED_ARCHIVED_PROPERTY]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      selectedProperty: payload
    }),
    [SET_PROPERTY_ERROR]: (state, { payload }) => ({
      ...state,
      propertyError: payload
    }),
    [IMPORT_DATA_FOR_PROPERTIES]: (state) => ({
      ...state,
      status: STATUS_UPDATING
    }),
    [IMPORTED_DATA_FOR_PROPERTIES]: (state) => ({
      ...state,
      status: STATUS_READY
    }),
    [UPDATE_PROPERTY_DATA]: (state) => ({
      ...state,
      status: STATUS_UPDATING
    }),
    [UPDATED_PROPERTY_DATA]: (state) => ({
      ...state,
      status: STATUS_READY
    })
  },
  defaultState
);
