import React, { Component } from "react";
import PropTypes from "prop-types";
import { BreadCrumbList, BreadCrumbNavigationWrapper } from "./style";
import BreadCrumbLink from "./subComponents/BreadCrumbLink";

/**
 * The BreadCrumbNavigation component renders a series of breadcrumbs based on an array passed in as props

 */
class BreadCrumbNavigation extends Component {
  static propTypes = {
    /** Crumbs that describe the structure of the navigation */
    crumbs: PropTypes.arrayOf(
      PropTypes.shape({
        /** The label that will be displayed for the breadcrumb */
        label: PropTypes.string.isRequired,
        /** Link that will be navigated to when clicked.  */
        link: PropTypes.string,
        /** An Array indicating that this breadCrumb has children and a dropdown mentu should be renderered for it  */
        siblingMenu: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.string,
            disabled: PropTypes.bool,
            hasCaret: PropTypes.bool
          })
        ),
        /** Whether this link should be disabled (such as the 'home' link in the breadcrumb) */
        disabled: PropTypes.bool,
        hasCaret: PropTypes.bool
      })
    )
  };

  /**
   * Checks if the prop of crumbs has been defined, is an array, and has a length.
   * Returns false if any of the above is false, otherwise returns prop.crumbs.
   * @public
   */

  getCrumbs = () => {
    const { crumbs } = this.props;
    return crumbs && crumbs.length ? crumbs : false;
  };

  /**
   * Checks if the current Index of this item, matches the length of crumbs,
   * Returns true or false
   * @param index - the current index of the item to check
   * @public
   */

  checkForCaret = (index) => {
    const crumbs = this.getCrumbs();
    if (!crumbs && !crumbs.length) return false;
    return index !== null && index !== undefined ? index !== 0 : false;
  };

  /**
   * Returns an array of BreadCrumbLink subcomponents for each item in props.crumbs.
   * @public
   */

  renderChildrenLinks = () => {
    const crumbs = this.getCrumbs();
    if (!crumbs) return null;
    return crumbs.map((crumb, index) => {
      crumb.hasCaret = this.checkForCaret(index);
      return (
        <BreadCrumbLink
          key={`breadcrumb-child ${crumb.label} ${index}`}
          props={crumb}
          {...crumb}
        />
      );
    });
  };

  render() {
    return (
      <BreadCrumbNavigationWrapper>
        <BreadCrumbList data-name={"BreadCrumbList"}>
          {this.renderChildrenLinks()}
        </BreadCrumbList>
      </BreadCrumbNavigationWrapper>
    );
  }
}

export default BreadCrumbNavigation;
