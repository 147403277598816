import moment from "moment";
import { defaultTheme } from "@UIKit/defaultTheme";

export const getUserStatusKey = (user) => {
  const { inviteId, active, expiryDate } = user;
  const isExpired =
    expiryDate && (active || inviteId)
      ? moment(moment(expiryDate).format("YYYY-MM-DD")).isSameOrBefore(
          moment().format("YYYY-MM-DD")
        )
      : false;
  if (inviteId) {
    return isExpired ? 4 : 3;
  } else if (!active) return 1;
  return isExpired ? 2 : 0;
};

export const getStatusColorByKey = (key) => {
  switch (key) {
    case 1: {
      return defaultTheme.agMischkaGray;
    }
    case 2: {
      return defaultTheme.agLighterRed;
    }
    case 3: {
      return defaultTheme.agLightGreen;
    }
    case 4: {
      return defaultTheme.agLightOrange;
    }
    default: {
      return defaultTheme.agLighterGreen;
    }
  }
};

export const getStatusLabelConstByKey = (key) => {
  switch (key) {
    case 1: {
      return "INACTIVE_LABEL";
    }
    case 2: {
      return "EXPIRED_LABEL";
    }
    case 3:
    case 4: {
      return "INVITED_LABEL";
    }
    default: {
      return "ACTIVE_LABEL";
    }
  }
};

export const getURLPathParameter = (param) => {
  if (!param) return;
  const url = window.location.pathname;
  if (!url.includes(param)) return;
  return url.split(`${param}/`)[1].split("/")[0];
};
