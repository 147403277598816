import styled, { css } from "styled-components";
import { STYLETYPE_ERROR } from "@base/constants";

// @ts-ignore
export const RadioGroupElement = styled.div`
  height: 100%;
  max-height: 100%;
  background: "none";
  display: flex;
  flex-direction: ${(props) => {
    return props.direction === "horizontal" ? "row" : "column";
  }};
  justify-content: flex-start;
  align-items: ${(props) => {
    return props.direction === "horizontal" ? "center" : "top";
  }};
  border-radius: 0.3rem;
  margin-bottom: 1.5rem;

  & + & {
    margin-bottom: 2rem;
  }

  & > p {
    margin-top: 0.5em;
  }

  ${(props) =>
    props.styleType === STYLETYPE_ERROR &&
    css`
      padding: 1rem;
      background: ${props.theme.agOffRed};
    `}

  ${(props) =>
    props.styleType === "radioGroupTable" &&
    css`
      display: flex;
      width: 50%;
      justify-content: space-around;
      & + & {
        margin-bottom: 0rem;
      }
    `}
`;
RadioGroupElement.displayName = "RadioGroupElement";
