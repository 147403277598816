import { connect } from "react-redux";
import Main from "./Main";
import * as actions from "../../actions";

import {
  getLabels,
  getUserLogin,
  getUserNameLogin,
  getAvailableOrgs,
  getSelectedOrg,
  getIsAuthenticated,
  getUserLoadingLogin,
  getOrgsStatus,
  getPageTitle,
  getHasPermission,
  getLoggingOut
} from "../../selectors";

import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    propState: state,
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    userDetails: getUserLogin(state),
    userName: getUserNameLogin(state),
    userAuthenticated: getIsAuthenticated(state),
    userLoading: getUserLoadingLogin(state),
    organisations: {
      available: getAvailableOrgs(state),
      selected: getSelectedOrg(state),
      isLoading: getOrgsStatus(state) === STATUS_LOADING
    },
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType),
    loggingOut: getLoggingOut(state)
  }),
  actions
)(Main);
