import styled, { css } from "styled-components";
import {
  DESCRIPTION_TEXT_PARAGRAPH,
  STYLETYPE_ERROR,
  STYLETYPE_CODE,
  STYLETYPE_HELPTEXT,
  STYLETYPE_HELPER,
  MODAL_BODY_ID,
  CHECK_EMAIL_STYLETYPE,
  AUTH_TEXT,
  STYLETYPE_STATUS
} from "@base/constants";

export const ParagraphElement = styled.p`
  color: ${(props) => props.theme.agDarkestGray};
  font-family: ${(props) => props.theme.baseFont};
  margin: 0;
  padding: 0;
  line-height: ${(props) => props.theme.lineHeightBase};
  font-size: ${(props) => props.theme.fontSizeBase};

  & + & {
    margin-top: 1rem;
  }

  ${(props) =>
    props.styleType === DESCRIPTION_TEXT_PARAGRAPH &&
    css`
      & + & {
        margin-top: 0rem;
      }
    `}

  ${(props) =>
    props.styleType === STYLETYPE_ERROR &&
    css`
      color: ${(props) => props.theme.agRed};
      font-weight: 800;
      font-size: 0.9em;
    `}

  ${(props) =>
    props.styleType === STYLETYPE_CODE &&
    css`
      font-family: monospace;
      white-space: pre;
      background: ${(props) => props.theme.agOffWhite};
      border: 1px solid ${(props) => props.theme.agLightestGray};
      padding: 1.5rem;
    `}

  ${(props) =>
    props.styleType === STYLETYPE_HELPER &&
    css`
      font-weight: 600;
      font-size: 0.7em;

      input + & {
        margin-top: -0.625rem;
      }
    `}

    ${(props) =>
    props.styleType === STYLETYPE_HELPTEXT &&
    css`
      font-weight: 600;
      font-size: 0.7em;
    `}

  ${(props) =>
    props.styleType === MODAL_BODY_ID &&
    css`
      font-size: 0.8em;
      white-space: break-spaces;
    `}

  ${(props) =>
    props.styleType === CHECK_EMAIL_STYLETYPE &&
    css`
      align-self: center;
      margin-left: 1rem;
    `}
  ${(props) =>
    props.styleType === AUTH_TEXT &&
    css`
      text-align: center;
      margin-top: 1.5rem;
      & + & {
        margin-top: 1.2rem;
      }
      & + a {
        margin-top: 1.5rem;
      }
    `}

    ${(props) =>
    props.styleType === STYLETYPE_STATUS &&
    css`
      font-size: calc(1rem + 0.3vw);
    `}
`;
ParagraphElement.displayName = "ParagraphElement";
