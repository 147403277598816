import styled, { css } from "styled-components";
import {
  PAGINATION_V2_STYLING,
  DATA_TABLE_SECTION_STYLING,
  WORKFLOW_DETAILS_TABLES_STYLETYPE
} from "@base/constants";

// @ts-ignore
export const SectionElement = styled.section`
  margin-bottom: ${(props) =>
    props.styleType === WORKFLOW_DETAILS_TABLES_STYLETYPE ? "2rem" : ""};

  margin-top: ${(props) =>
    props.styleType === DATA_TABLE_SECTION_STYLING ? "3rem" : ""};

  margin: ${(props) =>
    props.styleType === PAGINATION_V2_STYLING ? "3rem 0" : ""};
`;

SectionElement.displayName = "Section";
