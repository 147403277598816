import React, { Component } from "react";
import PropTypes from "prop-types";
import { RadioGroupElement } from "./style";
import { STYLETYPE_ERROR } from "@base/constants";
import { RadioButton } from "@core";
import { P, Legend } from "@typography";

/**
 * RadioGroup component that creates a new button from style components and optionally includes an Icon
 */
class RadioGroup extends Component {
  static propTypes = {
    /**Whether the entire RadioGroup is disabled or not */
    isDisabled: PropTypes.bool,
    /**Whether the entire RadioGroup is horizontal or vertical */
    direction: PropTypes.oneOf(["horizontal", "vertical"]),
    /**The radio buttons to render */
    radioButtons: PropTypes.arrayOf(
      PropTypes.shape({
        /** Whether the child button is disabled or not */
        isDisabled: PropTypes.bool,
        /**What label to display in the radioButton */
        label: PropTypes.string,
        /**What to value to return if checked */
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.bool
        ])
      })
    ),
    /**Currently selected radio button value */
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool
    ]),
    /**What function to run on change */
    onChange: PropTypes.func,
    /**What function to run on click */
    onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /**What radioGroup this button belongs to */
    name: PropTypes.string,
    /**What to style to set the radio group */
    styleType: PropTypes.string,
    /**What validation Error message to display */
    validationMessage: PropTypes.string,
    /** legend for radiogroup */
    legend: PropTypes.string
  };

  /**
   * Returns if the radio  is set to disabled, defaults to false
   * @public
   */
  isDisabled = () => {
    return this.props.isDisabled ? this.props.isDisabled : false;
  };

  /**
   * Returns the value stored against this radio Button
   * @public
   */
  getValue = () => {
    return this.props.value ? this.props.value : false;
  };

  /**
   * Function to get the props.name
   * @public
   */
  getName = () => {
    return this.props.name ? this.props.name : "";
  };

  /**
   * Function to return props.radioButtons
   * @public
   */
  getRadioButtons = () => {
    return this.props.radioButtons ? this.props.radioButtons : false;
  };

  /**
   * Function that runs on change
   * @public
   */
  getOnChange = (event) => {
    const { onChange } = this.props;
    return onChange ? onChange(event) : null;
  };

  /**
   * Function that runs on click
   * @public
   */
  getOnClick = (event) => {
    const { onClick } = this.props;
    return onClick ? onClick(event) : null;
  };

  /**
   * Function to return props.styleType
   * @public
   */
  getStyleType = () => {
    return this.props.styleType ? this.props.styleType : "";
  };

  /**
   * Function to return props.direction
   * @public
   */
  getDirection = () => {
    return this.props.direction ? this.props.direction : "vertical";
  };

  /**
   * Function to return props.validationMessage, has fall back to 'Please select option'
   * @public
   */
  getValidationMesage = () => {
    return this.props.validationMessage
      ? this.props.validationMessage
      : "Please select option";
  };

  /**
   * Function to render out radio buttons'
   * @public
   */
  renderChildren = () => {
    const radioButtons = this.getRadioButtons();

    if (!radioButtons) return null;

    return radioButtons.map((radioButton) => {
      return (
        <RadioButton
          id={radioButton.id}
          key={`key-${radioButton.label}-${radioButton.value}`}
          isDisabled={this.isDisabled() || radioButton.isDisabled}
          label={radioButton.label}
          value={radioButton.value}
          checked={radioButton.value === this.getValue()}
          name={this.getName()}
          styleType={this.getStyleType()}
          onClick={(event) => {
            this.getOnClick(event);
          }}
          onChange={(event) => {
            this.getOnChange(event);
          }}
          direction={this.getDirection()}
        ></RadioButton>
      );
    });
  };

  /**
   * Returns legend stored against this radio Button
   * @public
   */
  getLegend = () => {
    const { legend } = this.props;
    if (!legend) return null;
    return <Legend>{legend}</Legend>;
  };

  render() {
    return (
      <RadioGroupElement
        styleType={this.getStyleType()}
        direction={this.getDirection()}
      >
        {this.getLegend()}
        {this.renderChildren()}
        {this.getStyleType() === STYLETYPE_ERROR && (
          <P styleType={STYLETYPE_ERROR}>{this.getValidationMesage()}</P>
        )}
      </RadioGroupElement>
    );
  }
}

export default RadioGroup;
