import styled from "styled-components";

// @ts-ignore
export const TableElement = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: ${(props) => props.theme.fontSizeSmall};
  & tr {
    text-align: left;
    font-size: 1.2em;
    height: 2.813rem;
    background: ${(props) => props.theme.agWhite};
    border: 2px solid ${(props) => props.theme.agLighterShadeGray};

    & td {
      width: ${(props) =>
        props.evenSpace ? 100 / props.evenSpace + "%" : "auto"};
      padding: 0.4rem;
      vertical-align: top;
      white-space: pre-line;
    }

    & td:empty:before {
      content: "None";
    }

    & th {
      width: ${(props) =>
        props.evenSpace ? 100 / props.evenSpace + "%" : "auto"};
      background: ${(props) => props.theme.agMischkaGray};
      padding: 0.4rem;
      color: ${(props) => props.theme.agWhite};
    }

    &:nth-child(odd) {
      background: ${(props) => props.theme.agSmokeWhite};
    }
  }
`;
TableElement.displayName = "TableElement";
