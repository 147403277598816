import styled, { css } from "styled-components";

export const Heading4Element = styled.h4`
  font-family: ${(props) => props.theme.baseFont};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  line-height: ${(props) => props.theme.lineHeightBase};
  font-size: ${(props) => props.theme.fontSizeOne};
  color: ${(props) => props.theme.agCommonBlack};
  letter-spacing: 0.01em;
  margin-bottom: 0.8rem;

  ${(props) =>
    props.styleType === "radioOptionsLabels" &&
    css`
      align-self: center;
      margin-bottom: 0rem;
      color: ${(props) => props.theme.agWhite};
      font-size: ${(props) => props.theme.fontSizeSmall};
      font-weight: ${(props) => props.theme.baseFontWeightHeavy};
    `}
`;
Heading4Element.displayName = "Heading4Element";
