import React, { Component } from "react";
import { Container, Wrapper, LinkButton, Section } from "@core";
import {
  ContextHeader,
  DataTable,
  Loader,
  BottomButtonBar,
  defaultTheme
} from "@UIKit";
import { navigate } from "@reach/router";
import { P } from "@typography";
import AgBoxApiRequests from "../../../apis/agbox/requests";
import { filterList, sortMethod, storeSession, getSession } from "@base/common";

import {
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  ORGANISATION_URL,
  AGBOX_API_URL,
  AGBOX_API_KEY,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  DATASETS_URL,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  NEW_URL,
  DATASETS_SECTION,
  CREATE_PERMISSION,
  BUTTON_TYPE_PRIMARY_FULLWIDTH,
  BUTTON_TYPE_PRIMARY
} from "@base/constants";

const { requestOrganisationDatasets } = AgBoxApiRequests(
  AGBOX_API_URL,
  AGBOX_API_KEY
);

class DatasetOverview extends Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      datasets: [],
      isLoading: false,
      filterText: "",
      currentPageNumber: 1
    };
  }

  componentDidMount() {
    this.loadDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("DATASETS_TEXT");
  };

  getSecondHeaderText = () => {
    const { orgId, getLabel, availableOrganisations } = this.props;
    if (!availableOrganisations || !availableOrganisations.length) return "";
    const org = availableOrganisations.find((org) => org.orgId === orgId);
    if (!org) return "";
    return getLabel("DATASETS_FOR_ORG_TEXT", {
      item: org.title
    });
  };

  getTableData = () => {
    const { orgId } = this.props;
    const filteredDatasets = this.getFilteredDatasets();
    const sortedDatasets = this.handleSort(filteredDatasets, "name", "asc");
    const paginatedDatasets = this.getPaginatedDatasets(sortedDatasets);

    return paginatedDatasets.map((dataset) => {
      const title = (
        <LinkButton
          url={`/${orgId}/${DATASETS_URL}/${dataset.name}`}
          buttonLabel={dataset.name}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      );
      return {
        cells: [title]
      };
    });
  };

  getTableHeader = () => {
    const { getLabel } = this.props;
    return {
      cells: [{ content: getLabel("DATASET_TEXT") }]
    };
  };

  getNoResultsText = () => {
    const { getLabel } = this.props;
    return getLabel("NO_RESULTS_LABEL");
  };

  returnToOverviewAction = () => {
    const { hideModal, orgId } = this.props;
    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/`);
  };

  scrollToTop = () => window.scrollTo({ top: 0 });

  setErrorModalContent = (errorMessage) => {
    const { setModalContent, getLabel } = this.props;
    this.scrollToTop();
    setModalContent({
      showCloseButton: false,
      header: getLabel("GENERIC_ERROR_HEADING"),
      body: <P>{errorMessage}</P>,
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("ORGANISATION_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
  };

  showErrorModal = (error) => {
    const { showModal } = this.props;
    this.setErrorModalContent(error.message);
    showModal();
  };

  getDatasets = async () => {
    const { orgId, token } = this.props;
    let availableDatasets = await requestOrganisationDatasets(
      orgId,
      token,
      this.controller.signal
    );

    return availableDatasets.items;
  };

  loadDetails = async () => {
    this.setState({ isLoading: true });
    try {
      const datasets = await this.getDatasets();
      this.setState({
        datasets,
        isLoading: false
      });
    } catch (error) {
      this.setState({ isLoading: false });
      this.showErrorModal(error);
    }
  };

  getFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("DATASETS_TEXT")
    });
  };

  getFilterPlaceholderText = () => {
    const { getLabel } = this.props;
    return getLabel("DATASETS_FILTER_PLACEHOLDER_LABEL");
  };

  setFilterText = (value) => {
    this.setState({
      filterText: value,
      currentPageNumber: 1
    });
  };

  getFilterText = () => {
    const { filterText } = this.state;
    return filterText;
  };

  getFilteredDatasets = () => {
    const { datasets } = this.state;
    if (!this.getFilterText() && datasets) {
      return datasets;
    }
    return datasets ? filterList(datasets, this.getFilterText(), "name") : [];
  };

  handleSort = (datasets, key, direction) => {
    return sortMethod(datasets, key, direction);
  };

  getOrgDatasetsTotal = () => {
    const filteredDatasets = this.getFilteredDatasets();
    return filteredDatasets ? filteredDatasets.length : 0;
  };

  showDropdown = () => {
    return this.getOrgDatasetsTotal() > PAGINATION_OPTIONS[0];
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getPageLimit = () => {
    const result = getSession(`org-datasets-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  dropDownOnSelectFunction = (value) => {
    this.setState({ currentPageNumber: 1 });
    storeSession(`org-datasets-${PAGINATION_KEY}`, value);
  };

  getDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit()
    });
  };

  getPageDropDown = () => {
    return {
      showDropdown: this.showDropdown(),
      dropDownValue: this.getPageLimit(),
      dropDownLabel: this.getDropDownLabel(),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: this.dropDownOnSelectFunction
    };
  };

  showPagination = () => {
    return this.getOrgDatasetsTotal() > this.getPageLimit();
  };

  setPaginationContent = (value) => {
    this.setState({ currentPageNumber: value });
  };

  getCurrentPageNumber = () => {
    const { currentPageNumber } = this.state;
    return currentPageNumber;
  };

  getPaginatedDatasets = (datasets) => {
    const { currentPageNumber } = this.state;
    const pageLimit = this.getPageLimit();
    const lastDatasetIndex = currentPageNumber * pageLimit;
    const firstDatasetIndex = lastDatasetIndex - pageLimit;
    return datasets.slice(firstDatasetIndex, lastDatasetIndex);
  };

  getPaginationContent = () => {
    return {
      setPaginationContent: this.setPaginationContent,
      activeNumber: this.getCurrentPageNumber(),
      numberOfButtons: this.getPageLimit(),
      totalResults: this.getOrgDatasetsTotal(),
      showPagination: this.showPagination(),
      paginationInput: true
    };
  };

  createNavigationLink = (link) => {
    return () => {
      navigate(link);
    };
  };

  createNewDatasetLink = () => {
    const { orgId } = this.props;
    return this.createNavigationLink(
      `/${ORGANISATION_URL}/${orgId}/${DATASETS_URL}/${NEW_URL}`
    );
  };

  hasCreatePermission = () => {
    const { getHasPermission, orgId } = this.props;
    return getHasPermission(orgId, DATASETS_SECTION, CREATE_PERMISSION);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY_FULLWIDTH,
        isDisabled: false,
        onClick: this.createNewDatasetLink(),
        buttonLabel: getLabel("ADD_NEW_LABEL", {
          item: getLabel("DATASET_TEXT")
        }),
        icon: {
          type: "plus",
          bgWidth: "25px",
          bgHeight: "15px",
          iconHeight: "16px",
          iconWidth: "15px",
          iconColor: defaultTheme.agWhite
        }
      }
    ];
  };

  getContextHeaderButtons = () => {
    const { getLabel } = this.props;
    return this.hasCreatePermission()
      ? [
          {
            buttonStyleType: BUTTON_TYPE_PRIMARY,
            isDisabled: false,
            buttonLabel: getLabel("ADD_NEW_LABEL", {
              item: getLabel("DATASET_TEXT")
            }),
            onClick: this.createNewDatasetLink()
          }
        ]
      : [];
  };

  render() {
    const { datasets, isLoading } = this.state;
    return (
      <Container styleType={CONTENT_VIEW}>
        <Wrapper data-name={"Wrapper"} subStyle="App" styleType={CONTENT_VIEW}>
          <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
            <ContextHeader
              headerTitle={this.createHeaderText()}
              contextMenu={{ visible: false }}
              buttons={this.getContextHeaderButtons()}
            />
          </Container>
          <Section styleType={DATA_TABLE_SECTION_STYLING}>
            {isLoading ? (
              <Loader fullPage={false} />
            ) : datasets && datasets.length > 0 ? (
              <DataTable
                title={this.getSecondHeaderText()}
                header={this.getTableHeader()}
                rows={this.getTableData()}
                noResultsText={this.getNoResultsText()}
                tableFilter={{
                  filterLabel: this.getFilterLabel(),
                  showFilter: true,
                  filterFunction: this.setFilterText,
                  isDisabled: false,
                  placeholderText: this.getFilterPlaceholderText(),
                  inputId: this.getFilterLabel()
                }}
                pagination={this.getPaginationContent()}
                pageDropDown={this.getPageDropDown()}
              />
            ) : (
              <div>{this.getNoResultsText()}</div>
            )}
          </Section>
          {this.hasCreatePermission() && (
            <BottomButtonBar buttons={this.getButtons()} />
          )}
        </Wrapper>
      </Container>
    );
  }
}
export default DatasetOverview;
