import { connect } from "react-redux";
import InvitedUserDetails from "./InvitedUserDetails";
import * as actions from "../../../actions";
import {
  getLabels,
  getPageTitle,
  getAbortController,
  getInvitedUser,
  getInvitedUserLoading,
  getSelectedUserStatus,
  getSelectedOrg,
  getHasPermission
} from "../../../selectors";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    abortController: getAbortController(state),
    selectedInvitedUser: getInvitedUser(state),
    selectedInvitedUserLoading: getInvitedUserLoading(state),
    selectedInvitedUserStatus: getSelectedUserStatus(state),
    selectedOrganisation: getSelectedOrg(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType)
  }),
  actions
)(InvitedUserDetails);
