import { handleActions } from "redux-actions";
import {
  LOAD_WORKFLOW,
  LOADED_WORKFLOW,
  LOAD_WORKFLOW_PROPERTY_GROUPS,
  LOADED_WORKFLOW_PROPERTY_GROUPS,
  LOAD_WORKFLOW_PROPERTIES,
  LOADED_WORKFLOW_PROPERTIES,
  UPDATE_WORKFLOW,
  UPDATED_WORKFLOW,
  CREATE_WORKFLOW,
  CREATED_WORKFLOW,
  CREATE_WORKFLOW_FAILED,
  STATUS_EMPTY,
  STATUS_CREATING,
  STATUS_ERROR,
  STATUS_UPDATING,
  STATUS_LOADING,
  STATUS_READY
} from "@base/constants";

export const defaultState = {
  status: STATUS_EMPTY,
  available: null
};
// skipcq JS-0128
export default handleActions(
  {
    [LOAD_WORKFLOW]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      available: null
    }),

    [LOADED_WORKFLOW]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      available: payload
    }),
    [LOAD_WORKFLOW_PROPERTY_GROUPS]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      propertyGroups: {
        isLoading: true
      }
    }),
    [LOADED_WORKFLOW_PROPERTY_GROUPS]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      propertyGroups: {
        available: payload.items,
        isLoading: false
      }
    }),
    [LOAD_WORKFLOW_PROPERTIES]: (state) => ({
      ...state,
      status: STATUS_LOADING,
      properties: {
        isLoading: true
      }
    }),
    [LOADED_WORKFLOW_PROPERTIES]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      properties: {
        available: payload.items,
        isLoading: false
      }
    }),
    [UPDATE_WORKFLOW]: (state) => ({
      ...state,
      status: STATUS_UPDATING
    }),

    [UPDATED_WORKFLOW]: (state, { payload }) => ({
      ...state,
      status: STATUS_READY,
      available: payload
    }),
    [CREATE_WORKFLOW]: (state) => ({
      ...state,
      status: STATUS_CREATING
    }),
    [CREATED_WORKFLOW]: (state) => {
      return {
        ...state,
        status: STATUS_READY
      };
    },
    [CREATE_WORKFLOW_FAILED]: (state) => ({
      ...state,
      status: STATUS_ERROR
    })
  },
  defaultState
);
