import React from "react";
import {
  AttachmentListWrapper,
  AttachmentListItem,
  AttachmentListItemLink
} from "../style";
import { Button } from "@core";
import { defaultTheme } from "@UIKit";
import PropTypes from "prop-types";

const AttachmentList = (props) => (
  <AttachmentListWrapper>
    {props.attachments.map((attachment, i) => {
      return (
        <AttachmentListItem
          key={attachment.url ? attachment.url : attachment.name}
        >
          {attachment.url ? (
            <AttachmentListItemLink href={attachment.url} target="blank">
              {attachment.name}
            </AttachmentListItemLink>
          ) : (
            attachment.name
          )}
          {props.editable && (
            <Button
              buttonStyleType={"close"}
              isDisabled={false}
              onClick={() => props.clearAttachment(attachment, i)}
              buttonLabel={""}
              icon={{
                type: "close",
                bgWidth: "30px",
                bgHeight: "30px",
                iconHeight: "20px",
                iconWidth: "20px",
                iconColor: defaultTheme.agDarkRed
              }}
            />
          )}
        </AttachmentListItem>
      );
    })}
  </AttachmentListWrapper>
);

AttachmentList.propTypes = {
  /** If the attachments can be edited (i.e cleared etc) */
  editable: PropTypes.bool,
  /** List of file objects */
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string
    })
  ),
  /** Called on click of clear button when editable is true */
  clearAttachment: PropTypes.func
};
export default AttachmentList;
