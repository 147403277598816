import styled, { css } from "styled-components";

// @ts-ignore

export const SwitchWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 3.875rem;
  height: 1.688rem;
`;
SwitchWrapper.displayName = "SwitchWrapper";

export const SwitchInput = styled.input`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  background-color: ${(props) => props.theme.agLighterShadeGray};
  border-radius: 1.563rem;
  transition: transform 0.2s ease;
  border: 1px solid ${(props) => props.theme.agWhite};
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${(props) => props.theme.agLighterShadeGray};
    border-radius: 1.563rem;
    top: 0;
    left: 0;
    transition: transform 0.2s ease;
    border: 1px solid ${(props) => props.theme.agWhite};
  }
  &:before {
    position: absolute;
    content: "";
    left: 0.125rem;
    top: 0.125rem;
    width: 1.9em;
    height: 1.9em;
    background-color: ${(props) => props.theme.agDimGray};
    border-radius: 50%;
    transition: transform 0.2s ease;
    z-index: 1;
    transform: translateX(2.6em);
  }
  &:checked {
    &:before {
      background-color: ${(props) => props.theme.agGreen};
      transform: translateX(0);
    }
  }
`;
SwitchInput.displayName = "SwitchInput";

export const ToggleLabel = styled.p`
  position: absolute;
  display: flex;
  width: 12.5rem;
  height: 1.688rem;
  top: 0;
  left: 3.875rem;
  align-items: center;
  color: ${(props) => props.theme.agDarkestGray};
  font-family: ${(props) => props.theme.baseFont};
  margin: 0;
  padding-left: 0.625rem;
  line-height: ${(props) => props.theme.lineHeightBase};
  font-size: ${(props) => props.theme.fontSizeBase};
  font-weight: 600;
  font-size: 0.8em;
`;
ToggleLabel.displayName = "ToggleLabel";
