import { createActions } from "redux-actions";
import {
  LOAD_ROLE,
  LOADED_ROLE,
  LOAD_ROLE_WORKFLOWS,
  LOADED_ROLE_WORKFLOWS,
  LOAD_ROLE_WORKFLOWS_ERROR,
  LOAD_ROLE_USERS,
  LOADED_ROLE_USERS,
  CREATE_ROLE,
  CREATED_ROLE,
  UPDATE_ROLE,
  UPDATE_ROLE_ADMIN,
  UPDATED_ROLE,
  REMOVE_ROLE,
  REMOVED_ROLE
} from "@base/constants";

export const {
  loadRole,
  loadedRole,
  loadRoleWorkflows,
  loadedRoleWorkflows,
  loadRoleWorkflowsError,
  loadRoleUsers,
  loadedRoleUsers,
  createRole,
  createdRole,
  updateRole,
  updateRoleAdmin,
  updatedRole,
  removeRole,
  removedRole
} = createActions({
  [LOAD_ROLE]: (orgId, roleId) => ({
    orgId,
    roleId
  }),
  [LOADED_ROLE]: (role) => role,
  [LOAD_ROLE_WORKFLOWS]: (orgId, roleId) => ({
    orgId,
    roleId
  }),
  [LOADED_ROLE_WORKFLOWS]: (roleId, roleWorkflows) => ({
    roleId,
    roleWorkflows
  }),
  [LOAD_ROLE_WORKFLOWS_ERROR]: () => {},
  [LOAD_ROLE_USERS]: (orgId, roleId) => ({
    orgId,
    roleId
  }),
  [LOADED_ROLE_USERS]: (roleId, roleUsers) => ({
    roleId,
    roleUsers
  }),
  [CREATE_ROLE]: (orgId, data) => ({ orgId, data }),
  [CREATED_ROLE]: (role) => role,
  [UPDATE_ROLE]: (orgId, roleId, data) => ({
    orgId,
    roleId,
    data
  }),
  [UPDATE_ROLE_ADMIN]: (orgId, roleId, data) => ({
    orgId,
    roleId,
    data
  }),
  [UPDATED_ROLE]: (orgId, roleId) => ({
    orgId,
    roleId
  }),
  [REMOVE_ROLE]: (orgId, roleId, newRoleId) => ({ orgId, roleId, newRoleId }),
  [REMOVED_ROLE]: () => {}
});
