import styled, { css } from "styled-components";
import {
  ACCORDION_HEADER_STYLING,
  ACCORDION_CONTENT_STYLING
} from "@base/constants";

export const AccordionWrapper = styled.div``;
AccordionWrapper.displayName = "AccordionWrapper";

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  border: ${(props) => `2px solid ${props.theme.agWhite}`};
  border-radius: 0.125rem;
  padding: 0.3rem;
  margin: 1em 0;
`;
Table.displayName = "Table";

export const Tr = styled.tr`
  text-align: left;
  margin-top: 0;
  padding-left: ${(props) =>
    props.styleType === ACCORDION_CONTENT_STYLING ? "2.5em" : "0"};
  background-color: ${(props) => props.backgroundColor};
`;
Tr.displayName = "Tr";

export const Thead = styled.thead`
  text-align: left;
`;
Thead.displayName = "Thead";

export const Th = styled.th`
  box-sizing: border-box;
  padding: 0.4em;
  background-color: ${(props) => props.theme.agMischkaGray};
  color: ${(props) => props.theme.agWhite};
  font-weight: 300;
  font-size: calc(1rem + 0.3vw);
  border-right: ${(props) => `2px solid ${props.theme.agWhite}`};
  display: ${(props) => (props.hasSort ? "flex" : "table-cell")}
  &:last-child {
    border-right: ${(props) => `2px solid ${props.theme.agMischkaGray}`};
  }
  label {
    color: ${(props) => props.theme.agWhite};
    font-weight: 300;
    font-size: calc(1rem + 0.3vw);
  }

  ${(props) =>
    props.styleType === ACCORDION_HEADER_STYLING &&
    css`
      padding: 0.4em;
      height: 3em;
      width: 2.5em;
      > div {
        width: 100%;
      }
    `}
`;
Th.displayName = "Th";

export const Tbody = styled.tbody``;
Tbody.displayName = "Tbody";

export const Td = styled.td`
  box-sizing: border-box;
  font-weight: 300;
  font-size: calc(1rem + 0.3vw);
  padding: 0.5em;
  font-family: Poppins;
  color: ${(props) => props.theme.agCommonBlack};
  border-right: ${(props) =>
    `2px solid ${
      props.styleType === "no-border"
        ? "transparent"
        : props.theme.agMischkaGray
    }`};
  &:last-child {
    border-right: 2px solid #ffffff;
  }
  * {
    font-weight: 300;
    font-size: calc(1rem + 0.3vw);
  }
  label,
  input,
  button > span {
    font-size: ${(props) => props.theme.fontSizeBase};
  }

  a {
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-thickness: calc(0.1wv);
    text-decoration-color: ${(props) => props.theme.agDimGray};
    &:focus {
      text-decoration-style: dashed;
      text-decoration-thickness: calc(0.0625rem 0.1wv);
      text-decoration-color: ${(props) => props.theme.agBrightGreen};

      span {
        color: ${(props) => props.theme.agBrightGreen};
      }
    }
  }
`;
Td.displayName = "Td";

export const TableTextWrapper = styled.div`
  border: ${(props) => `2px solid ${props.theme.agWhite}`}
  padding: 0.4em;
`;
TableTextWrapper.displayName = "TableTextWrapper";

export const TableTextContainer = styled.div`
  padding: 1em;
  background-color: ${(props) => props.theme.agSmokeWhite};
`;
TableTextContainer.displayName = "TableTextContainer";
