import { connect } from "react-redux";
import DatasetDetail from "./DatasetDetail";
import * as actions from "../../../actions";
import {
  getLabels,
  getPageTitle,
  getSelectedOrgDatasets,
  getSelectedOrgDatasetsLoading,
  getAbortController,
  getHasPermission
} from "../../../selectors";

export default connect(
  (state) => ({
    propState: state,
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    abortController: getAbortController(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType),
    selectedOrgDatasets: getSelectedOrgDatasets(state),
    selectedOrgDatasetsLoading: getSelectedOrgDatasetsLoading(state)
  }),
  actions
)(DatasetDetail);
