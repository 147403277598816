import React, { Component } from "react";
import {
  Container,
  Wrapper,
  Section,
  FormLabel,
  TextArea,
  Form,
  FormInput,
  Fieldset
} from "@core";
import { Loader, ContextHeader, BottomButtonBar, RadioGroup } from "@UIKit";
import {
  STYLETYPE_ERROR,
  STATUS_UPDATING,
  STATUS_READY,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  CONTENT_FADE_TIMEOUT,
  STYLETYPE_FORM_FIELD,
  STYLETYPE_CODE,
  LABEL_2D,
  LABEL_3D,
  BUTTON_TYPE_PRIMARY,
  BUTTON_TYPE_SECONDARY,
  BUTTON_TYPE_PRIMARY_FULLWIDTH,
  BUTTON_TYPE_SECONDARY_FULLWIDTH,
  FIELD_SIZE_SMALL,
  STYLETYPE_HELPER,
  STYLETYPE_TEXT,
  HEIGHT_AUTO_TEXT,
  ORGANISATION_URL
} from "@base/constants";
import { navigate } from "@reach/router";
import { CSSTransition } from "react-transition-group";
import { P, Legend } from "@typography";

class UpdateOrganisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rawMode: false,
      orgName: "",
      enable3D: null,
      orgDescription: "",
      defaultSpatialReference: "",
      organisationJSON: "",
      jsonTextInputValue: "",
      originalState: {}
    };
  }

  componentDidMount() {
    const { orgId, loadOrganisation } = this.props;
    loadOrganisation(orgId);
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  static getDerivedStateFromProps(props, state) {
    const { selectedOrganisation } = props;
    if (
      selectedOrganisation &&
      selectedOrganisation.title &&
      !state.organisationJSON &&
      !state.jsonTextInputValue &&
      !state.orgName &&
      !state.orgDescription
    ) {
      const {
        title,
        preferences,
        description,
        kpinUpdater,
        kpinUpdaterEnabled
      } = selectedOrganisation;
      const { enable3D, defaultSpatialReference } = preferences;
      return {
        enable3D,
        orgName: title,
        orgDescription: description,
        defaultSpatialReference,
        organisationJSON: JSON.stringify(
          { title, kpinUpdater, kpinUpdaterEnabled, preferences },
          "",
          2
        ),
        jsonTextInputValue: JSON.stringify(
          { title, kpinUpdater, kpinUpdaterEnabled, preferences },
          "",
          2
        ),
        originalState: {
          enable3D,
          orgName: title,
          orgDescription: description,
          defaultSpatialReference
        }
      };
    }
    return state;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      getLabel,
      pageTitle,
      setPageTitle,
      selectedOrganisation,
      organisationsStatus
    } = this.props;
    const { title } = selectedOrganisation;

    if (title) {
      const docTitle = `${getLabel("UPDATE_ORGANISATION_TEXT", {
        item: title
      })}`;
      if (pageTitle !== docTitle) {
        setPageTitle(docTitle);
      }
    }

    if (
      prevProps.organisationsStatus === STATUS_UPDATING &&
      organisationsStatus === STATUS_READY
    ) {
      this.showSuccess();
    }
  }

  getHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      item: getLabel("ORGANISATION_TEXT"),
      action: getLabel("UPDATE_ACTION_LABEL")
    });
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  valueIsEmpty = (field) => {
    return !this.getValue(field);
  };

  callAction = async () => {
    try {
      const {
        rawMode,
        orgName,
        enable3D,
        orgDescription,
        defaultSpatialReference,
        jsonTextInputValue
      } = this.state;
      const { orgId, updateOrganisation, showModal } = this.props;

      let data = {};
      if (rawMode) {
        data = jsonTextInputValue;
      } else {
        data = JSON.stringify({
          title: orgName,
          description: orgDescription,
          preferences: { enable3D, defaultSpatialReference }
        });
      }

      this.showLoading();
      showModal();

      const results = await updateOrganisation(orgId, data);
    } catch (e) {
      throw new Error(e);
    }
  };

  showLoading = () => {
    const { showModal, setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("UPDATE_ACTION_LABEL"),
        item: getLabel("ORGANISATION_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_UPDATED_LABEL", {
        item: getLabel("ORGANISATION_TEXT")
      }),
      body: getLabel("ITEM_UPDATED_SUCCESS", {
        item: getLabel("ORGANISATION_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_DETAILS", {
            item: getLabel("ORGANISATION_TEXT")
          }),
          onClick: this.returnToOrganisationDetails
        }
      ]
    });
    return null;
  };

  returnToOrganisationDetails = () => {
    const { hideModal, orgId } = this.props;

    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}`);
  };

  showMessage = () => {
    const { setModalContent, showModal, getLabel } = this.props;
    showModal();
    setModalContent({
      showCloseButton: false,
      header: getLabel("UPDATE_ACTION_HEADER_LABEL", {
        item: getLabel("ORGANISATION_TEXT")
      }),
      body: getLabel("BACK_TO_DETAILS_MESSAGE"),
      buttons: [
        {
          buttonStyleType: BUTTON_TYPE_PRIMARY_FULLWIDTH,
          buttonLabel: getLabel("YES_LABEL"),
          onClick: this.callAction
        },
        {
          buttonStyleType: BUTTON_TYPE_SECONDARY_FULLWIDTH,
          buttonLabel: getLabel("NO_LABEL"),
          onClick: this.returnToOrganisationDetails
        }
      ]
    });
    return null;
  };

  getStateChanged = () => {
    const {
      rawMode,
      orgName,
      enable3D,
      orgDescription,
      organisationJSON,
      jsonTextInputValue,
      defaultSpatialReference,
      originalState
    } = this.state;

    if (rawMode && jsonTextInputValue !== organisationJSON) {
      return true;
    }
    if (
      !rawMode &&
      (orgName !== originalState.orgName ||
        enable3D !== originalState.enable3D ||
        orgDescription !== originalState.orgDescription ||
        jsonTextInputValue !== organisationJSON ||
        defaultSpatialReference !== originalState.defaultSpatialReference)
    ) {
      return true;
    }
    return false;
  };

  getButtons = () => {
    const { getLabel } = this.props;
    const stateChanged = this.getStateChanged();
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        isDisabled: this.isInvalid(),
        onClick: this.callAction,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("ORGANISATION_TEXT"),
          action: getLabel("UPDATE_ACTION_LABEL")
        })
      },
      {
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        isDisabled: false,
        onClick: this.resetOrganisationDetails,
        buttonLabel: getLabel("GENERIC_ACTION_MESSAGE", {
          item: getLabel("ORGANISATION_TEXT"),
          action: getLabel("RESET_LABEL")
        })
      },
      {
        onClick: stateChanged
          ? this.showMessage
          : this.returnToOrganisationDetails,
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        buttonLabel: getLabel("RETURN_TO_DETAILS", {
          item: getLabel("ORGANISATION_TEXT")
        })
      }
    ];
  };

  isInvalid = () => {
    const { rawMode } = this.state;
    if (rawMode) {
      return this.valueIsEmpty("jsonTextInputValue");
    } else {
      return this.valueIsEmpty("orgName");
    }
  };

  resetOrganisationDetails = () => {
    const { rawMode } = this.state;
    const { selectedOrganisation } = this.props;
    const {
      title,
      description,
      preferences: { enable3D, defaultSpatialReference }
    } = selectedOrganisation;

    if (rawMode) {
      this.setState({
        jsonTextInputValue: this.state.organisationJSON
      });
    } else {
      this.setState({
        enable3D,
        orgName: title,
        orgDescription: description,
        defaultSpatialReference
      });
    }
  };

  renderEnable3Doptions = () => {
    const { getLabel } = this.props;
    return (
      <RadioGroup
        legend={getLabel("ENABLE_3D_LABEL")}
        value={this.getValue("enable3D")}
        isDisabled={false}
        name={"enable3DForm"}
        radioButtons={[
          {
            label: LABEL_3D,
            value: true
          },
          {
            label: LABEL_2D,
            value: false
          }
        ]}
        onClick={(event) => {
          event = event == "true";
          this.setValue(event, "enable3D");
        }}
      />
    );
  };

  isLoadingOrganisation = () => {
    const { selectedOrganisation, selectedOrganisationLoading } = this.props;
    return !selectedOrganisation || selectedOrganisationLoading;
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isLoadingOrganisation() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.getHeaderText()}
                contextMenu={{ visible: false }}
              />
            </Container>
            <RadioGroup
              value={this.getValue("rawMode")}
              isDisabled={false}
              name={"updateOrgansiationForm"}
              radioButtons={[
                {
                  label: getLabel("RAW_JSON_INPUT_LABEL"),
                  value: true
                },
                {
                  label: getLabel("FORM_INPUT_LABEL"),
                  value: false
                }
              ]}
              onClick={(event) => {
                event = event == "true";
                this.setValue(event, "rawMode");
              }}
            ></RadioGroup>

            <Form>
              <CSSTransition
                in={this.getValue("rawMode")}
                timeout={CONTENT_FADE_TIMEOUT}
                classNames="css-transition"
                unmountOnExit
              >
                <Section>
                  <FormLabel
                    htmlFor={"update-organisation-jsontextarea"}
                    styleType={
                      this.valueIsEmpty("jsonTextInputValue")
                        ? STYLETYPE_ERROR
                        : ""
                    }
                  >
                    {getLabel("ADD_JSON", {
                      item: getLabel("ORGANISATION_TEXT")
                    })}
                  </FormLabel>
                  <TextArea
                    id={"update-organisation-jsontextarea"}
                    maxLength={300000}
                    value={this.getValue("jsonTextInputValue")}
                    onChange={(value) => {
                      this.setValue(value, "jsonTextInputValue");
                    }}
                    styleType={
                      this.valueIsEmpty("jsonTextInputValue")
                        ? STYLETYPE_ERROR
                        : STYLETYPE_CODE
                    }
                  ></TextArea>
                </Section>
              </CSSTransition>
              <CSSTransition
                in={!this.getValue("rawMode")}
                timeout={CONTENT_FADE_TIMEOUT}
                classNames="css-transition"
                unmountOnExit
              >
                <Container>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      styleType={
                        this.valueIsEmpty("orgName") ? STYLETYPE_ERROR : ""
                      }
                      htmlFor="org-name"
                    >
                      {getLabel("FORM_LABEL_ORG_NAME")}
                    </FormLabel>
                    <FormInput
                      value={this.getValue("orgName")}
                      onChange={(value) => {
                        this.setValue(value, "orgName");
                      }}
                      styleType={
                        this.valueIsEmpty("orgName") ? STYLETYPE_ERROR : ""
                      }
                      required={true}
                      ariaDescribedBy={
                        this.valueIsEmpty("orgName") ? "orgName-error" : null
                      }
                      id="org-name"
                      aria-invalid={this.valueIsEmpty("orgName")}
                    />
                    {this.valueIsEmpty("orgName") && (
                      <P styleType={"error"} id="orgName-error">
                        {getLabel("FORM_FIELD_EMPTY_LABEL")}
                      </P>
                    )}
                  </Container>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel
                      htmlFor="organisation-description"
                      id="org-description-label"
                    >
                      {getLabel("FORM_LABEL_DESCRIPTION")}
                    </FormLabel>
                    <TextArea
                      value={this.getValue("orgDescription")}
                      onChange={(value) => {
                        this.setValue(value, "orgDescription");
                      }}
                      id="organisation-description"
                      maxLength={500}
                      size={FIELD_SIZE_SMALL}
                      styleType={STYLETYPE_TEXT}
                      labelledBy={
                        "org-description-label org-description-helper"
                      }
                    />
                    <P styleType={STYLETYPE_HELPER} id="org-description-helper">
                      {getLabel("RULE_MAX_CHAR_LENGTH_LABEL", {
                        item: 500
                      })}
                    </P>
                  </Container>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <Fieldset>{this.renderEnable3Doptions()}</Fieldset>
                  </Container>
                  <Container styleType={STYLETYPE_FORM_FIELD}>
                    <FormLabel htmlFor="default-spatial-reference">
                      {getLabel("SPATIAL_REFERENCE_LABEL")}
                    </FormLabel>
                    <FormInput
                      value={this.getValue("defaultSpatialReference")}
                      onChange={(value) => {
                        this.setValue(value, "defaultSpatialReference");
                      }}
                      required={true}
                      type={"number"}
                      id="default-spatial-reference"
                    />
                  </Container>
                </Container>
              </CSSTransition>
              {this.isInvalid() ? (
                <P styleType={STYLETYPE_ERROR}>
                  {getLabel("PLEASE_FILL_FORM")}
                </P>
              ) : null}
              <BottomButtonBar buttons={this.getButtons()} />
            </Form>
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default UpdateOrganisation;
