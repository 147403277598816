import React, { Component } from "react";
import {
  Loader,
  ContextHeader,
  BottomButtonBar,
  defaultTheme,
  DataTable
} from "@UIKit";
import { Container, Wrapper, Section, LinkButton } from "@core";
import { navigate } from "@reach/router";
import {
  filterList,
  filterByValue,
  getSession,
  storeSession
} from "@base/common";
import {
  NEW_URL,
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  BUTTON_TYPE_PRIMARY,
  LANDKIND_ADMIN_CONSOLE_TEXT,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  CREATE_PERMISSION,
  ORGANISATIONS_SECTION,
  PAGINATION_OPTIONS,
  PAGINATION_KEY,
  ASSETS_PROXY_SUBSTRING,
  ORGANISATION_URL,
  USERS_URL,
  REACT_APP_ASSET_PROXY_URL_ENV
} from "@base/constants";

class OrganisationOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organisationsFilterText: "",
      organisationsPageNumber: 1,
      usersFilterText: "",
      usersPageNumber: 1
    };
  }
  componentDidMount() {
    this.loadDetails();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  loadDetails = () => {
    const {
      loadOrganisations,
      organisationsLoading,
      userSuperHuman,
      loadLandkindUsers
    } = this.props;
    if (!organisationsLoading) loadOrganisations();
    if (userSuperHuman) loadLandkindUsers();
  };

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  componentDidUpdate() {
    const { pageTitle, setPageTitle, organisations } = this.props;
    if (pageTitle !== LANDKIND_ADMIN_CONSOLE_TEXT) {
      setPageTitle(LANDKIND_ADMIN_CONSOLE_TEXT);
    }

    if (
      organisations &&
      organisations.length === 1 &&
      !this.hasCreatePermission()
    ) {
      this.directToOrg(organisations);
    }
  }

  directToOrg = (org) => {
    navigate(`/${ORGANISATION_URL}/${org[0].orgId}`);
  };

  setFilterText = (value) => {
    this.setState({
      organisationsFilterText: value,
      organisationsPageNumber: 1
    });
  };

  getFilterText = () => {
    const { organisationsFilterText } = this.state;
    return organisationsFilterText;
  };

  getUserName = () => {
    const { userName } = this.props;
    return userName ? userName : "";
  };

  getOrganisationsLoading = () => {
    const { organisationsLoading } = this.props;
    return organisationsLoading ? organisationsLoading : false;
  };

  getOrganisations = () => {
    const { organisations } = this.props;
    return organisations
      ? filterList(organisations, this.getFilterText())
      : false;
  };

  createNavigationLink = (link) => {
    return () => {
      navigate(link);
    };
  };

  createHeaderText = () => {
    return this.props.getLabel("WELCOME_HEADER_TEXT", {
      name: this.getUserName() ? this.getUserName() : ""
    });
  };

  hasCreatePermission = () => {
    const { getHasPermission } = this.props;
    return getHasPermission(null, ORGANISATIONS_SECTION, CREATE_PERMISSION);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: BUTTON_TYPE_PRIMARY,
        isDisabled: false,
        onClick: this.createNavigationLink(NEW_URL),
        buttonLabel: getLabel("ADD_NEW_LABEL", {
          item: getLabel("ORGANISATION_TEXT")
        }),
        icon: {
          type: "plus",
          bgWidth: "25px",
          bgHeight: "15px",
          iconHeight: "16px",
          iconWidth: "15px",
          iconColor: defaultTheme.agWhite
        }
      }
    ];
  };

  getContextHeaderButtons = () => {
    const { getLabel } = this.props;
    return this.hasCreatePermission()
      ? [
          {
            buttonStyleType: BUTTON_TYPE_PRIMARY,
            isDisabled: false,
            onClick: this.createNavigationLink(NEW_URL),
            buttonLabel: getLabel("ADD_NEW_LABEL", {
              item: getLabel("ORGANISATION_TEXT")
            })
          }
        ]
      : [];
  };

  getResultsSubtext = () => {
    const { getLabel } = this.props;
    const { organisationsPageNumber } = this.state;
    const pageLimit = this.getPageLimit();
    const total = this.getOrganisationsTotal();
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      organisationsPageNumber === 1
        ? organisationsPageNumber
        : (organisationsPageNumber - 1) * pageLimit + 1;

    const limitEndNumber = organisationsPageNumber * pageLimit;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;

    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  getFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("ORGANISATIONS_TEXT")
    });
  };

  getTableHeader = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        {
          content: getLabel("FORM_LABEL_ORG_NAME")
        },
        {
          content: getLabel("PROPERTIES_TEXT")
        },
        {
          content: getLabel("USERS_TEXT")
        }
      ]
    };
  };

  getOrganisationsList = () => {
    const { organisationsPageNumber } = this.state;
    const pageLimit = this.getPageLimit();
    const orgList = this.getOrganisations();
    if (!orgList) return;
    const lastOrg = organisationsPageNumber * pageLimit;
    const firstOrg = lastOrg - pageLimit;
    const currentOrgList = orgList.slice(firstOrg, lastOrg);
    return currentOrgList;
  };

  getOrgLogo = (org) => {
    if (!org.logo && !org.orgLogo) return "";
    const logoUrl = org.logo || org.orgLogo;

    let logoFix = logoUrl.split("https://" || "http://");
    logoFix[logoFix.length - 1] = logoFix[logoFix.length - 1].replace(
      "//",
      "/"
    );
    logoFix = logoFix.join("https://" || "http://");

    if (logoFix.includes(ASSETS_PROXY_SUBSTRING)) return logoFix;
    const altAssetsSubstring = REACT_APP_ASSET_PROXY_URL_ENV.replace(
      "landkind.com",
      "agbox.io"
    );

    return `${REACT_APP_ASSET_PROXY_URL_ENV}/${logoUrl.replace(
      new RegExp(
        `(https://|http://|${REACT_APP_ASSET_PROXY_URL_ENV}/|${altAssetsSubstring}/)`
      ),
      ""
    )}`;
  };

  getTableData = () => {
    const orgList = this.getOrganisationsList();
    if (!orgList) return;
    return orgList.map((org) => {
      const title = (
        <LinkButton
          url={`/${org.orgId}`}
          buttonLabel={org.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
          image={{
            source: this.getOrgLogo(org),
            altText: `${org.title}-logo`,
            width: "5em",
            height: "2.2em",
            onError: (e) => {
              e.target.onerror = null;
              e.target.style.display = "none";
              e.target.setAttribute("aria-hidden", true);
            }
          }}
        />
      );

      return {
        cells: [title, org.counts.properties, org.counts.users]
      };
    });
  };

  setPaginationContent = (value) => {
    this.setState({ organisationsPageNumber: value });
  };

  getPageLimit = () => {
    const result = getSession(`organisations-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getCurrentPageNumber = () => {
    const { organisationsPageNumber } = this.state;
    return organisationsPageNumber;
  };

  getOrganisationsTotal = () => {
    if (!this.getOrganisations()) return [];
    return this.getOrganisations().length;
  };

  showDropdown = () => {
    return this.getOrganisationsTotal() > this.getDropDownOptions()[0].value;
  };

  showPagination = () => {
    return this.getOrganisationsTotal() > this.getPageLimit();
  };

  getPaginationContent = () => {
    return {
      setPaginationContent: this.setPaginationContent,
      activeNumber: this.getCurrentPageNumber(),
      numberOfButtons: this.getPageLimit(),
      totalResults: this.getOrganisationsTotal(),
      showPagination: this.showPagination(),
      paginationInput: true
    };
  };

  dropDownOnSelectFunction = (value) => {
    this.setState({ organisationsPageNumber: 1 });
    storeSession(`organisations-${PAGINATION_KEY}`, value);
  };

  getDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getPageLimit()
    });
  };

  getDropDownOptions = () => {
    const { getLabel } = this.props;
    return PAGINATION_OPTIONS.map((value) => ({
      value,
      label: getLabel("DATA_TABLE_DROPDOWN_LABEL", { item: value })
    }));
  };

  getPageDropDown = () => {
    return {
      showDropdown: this.showDropdown(),
      dropDownValue: this.getPageLimit(),
      dropDownLabel: this.getDropDownLabel(),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: this.dropDownOnSelectFunction
    };
  };

  getNoResultsText = () => {
    const { getLabel } = this.props;
    const filterText = this.getFilterText();
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getUserSuperHuman = () => {
    const { userSuperHuman } = this.props;
    return userSuperHuman ? userSuperHuman : false;
  };

  getLandkindUsers = () => {
    const { landkindUsers } = this.props;
    return landkindUsers
      ? filterByValue(landkindUsers, this.getUsersFilterText())
      : false;
  };

  getLandkindUsersTotal = () => {
    if (!this.getLandkindUsers()) return [];
    return this.getLandkindUsers().length;
  };

  getLandkindUsersLoading = () => {
    const { landkindUsersLoading } = this.props;
    return landkindUsersLoading ? landkindUsersLoading : false;
  };

  getLandkindUsersTableHeader = () => {
    const { getLabel } = this.props;
    return {
      cells: [
        {
          content: getLabel("FORM_FIRST_NAME_LABEL")
        },
        {
          content: getLabel("FORM_LAST_NAME_LABEL")
        },
        {
          content: getLabel("FORM_EMAIL_LABEL")
        }
      ]
    };
  };

  getUsersList = () => {
    const { usersPageNumber } = this.state;
    const pageLimit = this.getUsersPageLimit();
    const usersList = this.getLandkindUsers();
    if (!usersList) return;
    const lastUser = usersPageNumber * pageLimit;
    const firstUser = lastUser - pageLimit;
    const currentUsersList = usersList.slice(firstUser, lastUser);
    return currentUsersList;
  };

  getLandkindUsersTableData = () => {
    const usersList = this.getUsersList();
    if (!usersList) return [];
    return usersList.map((user) => {
      const title = (
        <LinkButton
          url={`users/${user.userId}`}
          buttonLabel={user.firstName}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      );

      return {
        cells: [title, user.lastName, user.emailAddress]
      };
    });
  };

  setUsersFilterText = (value) => {
    this.setState({
      usersFilterText: value,
      usersPageNumber: 1
    });
  };

  getUsersFilterText = () => {
    const { usersFilterText } = this.state;
    return usersFilterText;
  };

  setUsersPaginationContent = (value) => {
    this.setState({ usersPageNumber: value });
  };

  getUsersPageLimit = () => {
    const result = getSession(`users-${PAGINATION_KEY}`);
    return result || this.getDropDownOptions()[0].value;
  };

  getUsersPageNumber = () => {
    const { usersPageNumber } = this.state;
    return usersPageNumber;
  };

  showUsersDropdown = () => {
    return this.getLandkindUsersTotal() > this.getDropDownOptions()[0].value;
  };

  showUsersPagination = () => {
    return this.getLandkindUsersTotal() > this.getUsersPageLimit();
  };

  getUsersPaginationContent = () => {
    return {
      setPaginationContent: this.setUsersPaginationContent,
      activeNumber: this.getUsersPageNumber(),
      numberOfButtons: this.getUsersPageLimit(),
      totalResults: this.getLandkindUsersTotal(),
      showPagination: this.showUsersPagination(),
      paginationInput: true
    };
  };

  usersOnSelectFunction = (value) => {
    this.setState({ usersPageNumber: 1 });
    storeSession(`users-${PAGINATION_KEY}`, value);
  };

  getUsersDropDownLabel = () => {
    const { getLabel } = this.props;
    return getLabel("DATA_TABLE_DROPDOWN_LABEL", {
      item: this.getUsersPageLimit()
    });
  };

  getUsersPageDropDown = () => {
    return {
      showDropdown: this.showUsersDropdown(),
      dropDownValue: this.getUsersPageLimit(),
      dropDownLabel: this.getUsersDropDownLabel(),
      dropDownOptions: this.getDropDownOptions(),
      dropDownOnSelectFunction: this.usersOnSelectFunction
    };
  };

  getUsersNoResultsText = () => {
    const { getLabel } = this.props;
    const filterText = this.getUsersFilterText();
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getUsersResultsSubtext = () => {
    const { getLabel } = this.props;
    const { usersPageNumber } = this.state;
    const pageLimit = this.getUsersPageLimit();
    const total = this.getLandkindUsersTotal();
    if (!total) return getLabel("DATA_TABLE_NO_RESULTS_SUBTEXT");
    const limitStart =
      usersPageNumber === 1
        ? usersPageNumber
        : (usersPageNumber - 1) * pageLimit + 1;

    const limitEndNumber = usersPageNumber * pageLimit;
    const limitEnd = limitEndNumber > total ? total : limitEndNumber;

    return getLabel("DATA_TABLE_RESULTS_SUBTEXT", {
      limitStart,
      limitEnd,
      total
    });
  };

  getUsersFilterLabel = () => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel("USERS_TEXT")
    });
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.getOrganisationsLoading() ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper
            data-name={"Wrapper"}
            subStyle="App"
            styleType={CONTENT_VIEW}
          >
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.createHeaderText()}
                contextMenu={{ visible: false }}
                buttons={this.getContextHeaderButtons()}
              />
            </Container>

            <Section styleType={DATA_TABLE_SECTION_STYLING}>
              <DataTable
                loading={this.getOrganisationsLoading()}
                noResultsText={this.getNoResultsText()}
                title={getLabel("YOUR_ORGANISATIONS_TEXT")}
                showResultsText={true}
                resultsSubtext={this.getResultsSubtext()}
                tableFilter={{
                  filterLabel: this.getFilterLabel(),
                  showFilter: true,
                  filterFunction: this.setFilterText,
                  isDisabled: false,
                  placeholderText: getLabel(
                    "ORGANISATION_FILTER_PLACEHOLDER_LABEL"
                  ),
                  inputId: this.getFilterLabel()
                }}
                header={this.getTableHeader()}
                rows={this.getTableData()}
                pagination={this.getPaginationContent()}
                pageDropDown={this.getPageDropDown()}
              />
            </Section>

            <Section styleType={DATA_TABLE_SECTION_STYLING}>
              <DataTable
                loading={this.getLandkindUsersLoading()}
                noResultsText={this.getUsersNoResultsText()}
                title={getLabel("LANDKIND_USERS_LABEL")}
                showResultsText={true}
                resultsSubtext={this.getUsersResultsSubtext()}
                tableFilter={{
                  filterLabel: this.getUsersFilterLabel(),
                  showFilter: true,
                  filterFunction: this.setUsersFilterText,
                  isDisabled: false,
                  placeholderText: getLabel("USERS_FILTER_PLACEHOLDER_LABEL"),
                  inputId: this.getUsersFilterLabel()
                }}
                header={this.getLandkindUsersTableHeader()}
                rows={this.getLandkindUsersTableData()}
                pagination={this.getUsersPaginationContent()}
                pageDropDown={this.getUsersPageDropDown()}
              />
            </Section>

            {this.hasCreatePermission() && (
              <BottomButtonBar buttons={this.getButtons()} />
            )}
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default OrganisationOverview;
