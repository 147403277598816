import React from "react";
import PropTypes from "prop-types";
import {
  ComboboxList,
  ComboboxListItem,
  ComboboxListItemSubtitle
} from "../style";

const ComboboxDropdown = (props) => {
  return (
    <ComboboxList role="listbox" id={`${props.id}-autocomplete`}>
      {props.listItems.map((listItem) => (
        <ComboboxListItem
          key={listItem.value}
          role="option"
          tabIndex={0}
          aria-selected={listItem.selected}
          onClick={() => props.onSelectListItem(listItem)}
          selected={listItem.selected}
          hidden={!props.showList}
          disabled={listItem.disabled}
        >
          {listItem.label}
          {listItem.subtitle && (
            <ComboboxListItemSubtitle>
              {listItem.subtitle}
            </ComboboxListItemSubtitle>
          )}
        </ComboboxListItem>
      ))}
    </ComboboxList>
  );
};
ComboboxDropdown.propTypes = {
  id: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      selected: PropTypes.bool,
      label: PropTypes.string,
      disabled: PropTypes.bool
    })
  ),
  showList: PropTypes.bool,
  onSelectListItem: PropTypes.func
};
export default ComboboxDropdown;
