import React, { Component } from "react";
import { Wrapper, Container, Icon, Image } from "@core";
import {
  LOADER_SIZE_REGULAR,
  LOADER_ID,
  LOADER_FADE_TIMEOUT
} from "@base/constants";
import { defaultTheme } from "../../";
import { LoadingTextWrapper, LoadingTextContainer, LoadingText } from "./style";
import { CSSTransition } from "react-transition-group";

import PropTypes from "prop-types";

/**
 * The loading spinner used throughout the app. This can be rendered in either regular or small size.
 */
class Loader extends Component {
  static propTypes = {
    /** Whether to take up the entire page or simply relative positioning */
    fullPage: PropTypes.bool,
    /** The text that will show under the loading spinner */
    loadingText: PropTypes.string,
    /** What size the spinner is - can be LOADER_SIZE_SMALL or LOADER_SIZE_REGULAR - regular is a full space icon with text below, while small renders the loading text adjacent to the spinner*/
    size: PropTypes.string,
    /** The height of the loading wrapper component - should include the unit */
    height: PropTypes.string,
    /** Whether the loader is visible */
    visible: PropTypes.bool,
    /** Image includes object */
    image: PropTypes.object
  };
  static defaultProps = {
    fullPage: false,
    size: LOADER_SIZE_REGULAR,
    //height: "100%",
    visible: true
  };

  getLoadingText = () => {
    const { loadingText } = this.props;
    return loadingText && loadingText !== "" ? loadingText : false;
  };

  loadingSize = () => {
    const { size } = this.props;
    return size ? size : LOADER_SIZE_REGULAR;
  };

  loadingHeight = () => {
    const { height } = this.props;
    return height ? height : false;
  };

  getFullPage = () => {
    const { fullPage } = this.props;
    return fullPage ? fullPage : false;
  };

  getVisible = () => {
    const { visible } = this.props;
    return visible ? visible : false;
  };

  getImage = () => {
    const { image } = this.props;
    if (!image) return false;
    const { altText, source, styleType } = image;
    return (
      <Image
        altText={altText}
        source={source}
        styleType={styleType ? styleType : ""}
        width={"400px"}
      />
    );
  };

  render() {
    return (
      <CSSTransition
        in={this.getVisible()}
        timeout={LOADER_FADE_TIMEOUT}
        classNames="css-transition"
        unmountOnExit
      >
        <Wrapper
          data-name={"LoaderWrapper"}
          loaderHeight={this.loadingHeight()}
          styleType={LOADER_ID}
          fullPage={this.getFullPage()}
        >
          <Container
            data-name={"LoaderContainer"}
            size={this.loadingSize()}
            styleType={LOADER_ID}
          >
            {this.getImage()}
            <Icon
              data-name={"Loading Icon"}
              type={"loading"}
              spinning
              iconWidth={
                this.loadingSize() === LOADER_SIZE_REGULAR ? "80px" : "40px"
              }
              iconHeight={
                this.loadingSize() === LOADER_SIZE_REGULAR ? "80px" : "40px"
              }
              bgColor={defaultTheme.agBrightGreen}
              iconColor={defaultTheme.agWhite}
              bgShape="round"
            />
            {this.getLoadingText() ? (
              <LoadingTextWrapper data-name="LoadingTextWrapper">
                <LoadingTextContainer data-name="LoadingText">
                  <LoadingText
                    data-name="LoadingText"
                    size={this.loadingSize()}
                  >
                    {this.getLoadingText()}
                  </LoadingText>
                </LoadingTextContainer>
              </LoadingTextWrapper>
            ) : null}
          </Container>
        </Wrapper>
      </CSSTransition>
    );
  }
}

export default Loader;
