import React from "react";
import { LegendElement } from "./style";
import PropTypes from "prop-types";

/** Renders Heading4 Element  NOTE text is WHITE! - Used for the header component, always use on an element with a background color*/
const Legend = (props) => {
  return (
    <LegendElement styleType={props.styleType}>{props.children}</LegendElement>
  );
};

Legend.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.string
};
export default Legend;
