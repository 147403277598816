import styled, { css } from "styled-components";
import {} from "@base/constants";

import { FormInput } from "@core";

// @ts-ignore

export const DropdownInputElement = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => props.theme.agLighterGray};

  & input {
    border: none;

    :hover {
      cursor: pointer;
    }
  }
`;
DropdownInputElement.displayName = "DropdownInputElement";
