import React from "react";
import { FormElement } from "./style";
import PropTypes from "prop-types";

/** Renders a Fieldset to hold form set content */
const Form = (props) => {
  return (
    <FormElement data-name={"FormElement"} styleType={props.styleType}>
      {props.children}
    </FormElement>
  );
};

Form.propTypes = {
  children: PropTypes.array,
  styleType: PropTypes.string
};

export default Form;
