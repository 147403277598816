import React from "react";
import { FieldsetElement } from "./style";
import PropTypes from "prop-types";

/** Renders a Fieldset to hold form set content */
const Fieldset = (props) => {
  return (
    <FieldsetElement data-name={"FieldsetElement"}>
      {props.children}
    </FieldsetElement>
  );
};

Fieldset.propTypes = {
  children: PropTypes.any
};

export default Fieldset;
