import { connect } from "react-redux";
import UpdateSuperHumanUsersDetail from "./UpdateSuperHumanUsersDetail";
import * as actions from "../../../actions";
import {
  getLabels,
  getPageTitle,
  getAbortController,
  getHasPermission,
  getSelectedUser,
  getSelectedUserStatus,
  getLandkindUserStatus
} from "../../../selectors";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    abortController: getAbortController(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType),
    selectedUser: getSelectedUser(state),
    selectedUserStatus: getSelectedUserStatus(state),
    landkindUserStatus: getLandkindUserStatus(state)
  }),
  actions
)(UpdateSuperHumanUsersDetail);
