import React, { Component } from "react";
import { Container, Wrapper, Section, CheckboxV2, LinkButton } from "@core";
import { Loader, ContextHeader, BottomButtonBar, DataTable } from "@UIKit";
import { Heading3, P } from "@typography";

import {
  USERS_URL,
  CONTENT_VIEW,
  STATUS_READY,
  PROPERTIES_URL,
  STATUS_REMOVING,
  STATUS_RESENDING,
  PROPERTY_GROUPS_URL,
  BUTTON_TYPE_SECONDARY,
  CONTEXT_HEADER_CONTAINER,
  ALWAYS,
  NEVER,
  OPTIONAL,
  DELETE_PERMISSION,
  INVITES_SECTION,
  UPDATE_PERMISSION,
  UPDATE_USER_INVITE_URL,
  PROPERTIES_SECTION,
  PROPERTY_GROUPS_SECTION,
  READ_PERMISSION,
  SECTION_HEADER_STYLING,
  HEIGHT_AUTO_TEXT,
  DATA_TABLE_SECTION_STYLING,
  DATA_TABLE_BUTTON_TITLE_STYLING,
  PROPERTY_GROUP_TEXT,
  PROPERTY_TEXT,
  ORGANISATION_URL
} from "@base/constants";
import moment from "moment";
import { navigate } from "@reach/router";
import { filterList } from "@base/common";

class InvitedUserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendEmailInvite: null,
      propertyFilterText: "",
      propertyGroupFilterText: ""
    };
  }

  componentDidMount() {
    this.loadInvitedUser();
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  componentDidUpdate(prevProps) {
    const {
      pageTitle,
      setPageTitle,
      selectedInvitedUser,
      selectedInvitedUserStatus
    } = this.props;

    if (selectedInvitedUser) {
      const { firstName, lastName } = selectedInvitedUser;
      if (!firstName && !lastName) return;
      const name = `${firstName} ${lastName}`;
      if (name && pageTitle !== name) {
        setPageTitle(name);
      }
    }
    if (
      prevProps.selectedInvitedUserStatus === STATUS_RESENDING &&
      selectedInvitedUserStatus === STATUS_READY
    ) {
      this.showResendInviteSuccess();
    }

    if (
      prevProps.selectedInvitedUserStatus === STATUS_REMOVING &&
      selectedInvitedUserStatus === STATUS_READY
    ) {
      this.showRemoveInviteSuccess();
    }
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  loadInvitedUser = () => {
    const { orgId, inviteId, loadInvitedUser, loadOrganisation } = this.props;
    loadInvitedUser(orgId, inviteId);
    loadOrganisation(orgId);
  };

  isInvitedUserDetailsLoading = () => {
    const { selectedInvitedUser, selectedInvitedUserLoading } = this.props;
    return !selectedInvitedUser || selectedInvitedUserLoading;
  };

  selectedInvitedUser = () => {
    const { selectedInvitedUser } = this.props;
    return selectedInvitedUser ? selectedInvitedUser : false;
  };

  getInvitedUserName = () => {
    const { getLabel } = this.props;
    const user = this.selectedInvitedUser();
    const { firstName, lastName } = user;
    if (!firstName && !lastName)
      return getLabel("INVITED_USER_LABEL", {
        item: getLabel("DETAILS_TEXT")
      });

    return firstName && lastName
      ? `${firstName} ${lastName}`
      : firstName
      ? firstName
      : lastName;
  };

  resendUserInvite = () => {
    try {
      const { orgId, showModal, inviteId, resendInvite } = this.props;
      const { sendEmailInvite } = this.state;
      const sendEmail =
        this.getSendVerificationEmailOption() === ALWAYS
          ? true
          : this.getSendVerificationEmailOption() === NEVER ||
            sendEmailInvite === null
          ? false
          : sendEmailInvite;

      this.showLoading("RESENDING_LABEL", "INVITE_LABEL");
      showModal();

      resendInvite(orgId, inviteId, sendEmail, {});
    } catch (e) {
      throw new Error(e);
    }
  };

  showLoading = (action, item) => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel(action),
        item: getLabel(item)
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  showResendInviteSuccess = () => {
    const { setModalContent, getLabel, hideModal } = this.props;
    this.setState({ sendEmailInvite: false });
    setModalContent({
      showCloseButton: false,
      header: getLabel("INVITE_SENT_LABEL", {
        item: getLabel("INVITE_LABEL")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("USERS_TEXT")
          }),
          onClick: this.returnToOverviewAction
        },
        {
          buttonLabel: getLabel("CLOSE_MODAL_BUTTON_LABEL"),
          onClick: hideModal
        }
      ]
    });
    return null;
  };

  showRemoveInviteSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_REMOVED_LABEL", {
        item: getLabel("INVITE_LABEL")
      }),
      body: getLabel("ITEM_REMOVED_LABEL", {
        item: getLabel("INVITE_LABEL")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("USERS_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  returnToOverviewAction = () => {
    const { hideModal, orgId } = this.props;
    hideModal();
    navigate(`/${ORGANISATION_URL}/${orgId}/${USERS_URL}`);
  };

  removeInvitation = async () => {
    try {
      const { orgId, inviteId } = this.props;

      const { removeInvite, showModal } = this.props;
      this.showLoading("REMOVING_LABEL", "INVITE_LABEL");
      showModal();

      await removeInvite(orgId, inviteId);
    } catch (e) {
      throw new Error(e);
    }
  };

  showRemoveInvitationConfirmation = () => {
    const { showModal, setModalContent, getLabel, hideModal } = this.props;

    setModalContent({
      header: getLabel("INVITATION_ACTION_LABEL", {
        item: getLabel("REMOVE_ACTION_LABEL")
      }),
      body: getLabel("CONFIRM_REMOVE_INVITE"),
      buttons: [
        {
          buttonLabel: getLabel("INVITATION_ACTION_LABEL", {
            item: getLabel("REMOVE_ACTION_LABEL")
          }),
          onClick: this.removeInvitation
        },
        {
          buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
          onClick: hideModal
        }
      ]
    });
    showModal();
  };

  setSendEmailInvite = () => {
    this.setState({
      sendEmailInvite: !this.state.sendEmailInvite
    });
  };

  renderSendInviteCheckbox = () => {
    const { getLabel } = this.props;
    const { sendEmailInvite } = this.state;

    if (
      this.getSendVerificationEmailOption() === OPTIONAL ||
      this.getSendVerificationEmailOption() === undefined ||
      this.getSendVerificationEmailOption() === null
    ) {
      return (
        <CheckboxV2
          handleUpdate={this.setSendEmailInvite}
          name={"sendEmailInvite"}
          value={sendEmailInvite}
          checked={sendEmailInvite}
          disabled={false}
          title={getLabel("SEND_EMAIL_INVITE_LABEL")}
        />
      );
    }
  };

  showResendUserInviteConfirmation = () => {
    const { showModal, setModalContent, getLabel, hideModal } = this.props;

    setModalContent({
      header: getLabel("INVITATION_ACTION_LABEL", {
        item: getLabel("RESEND_ACTION_LABEL")
      }),
      body: <Container>{this.renderSendInviteCheckbox()}</Container>,
      buttons: [
        {
          onClick: this.resendUserInvite,
          buttonLabel: getLabel("INVITATION_ACTION_LABEL", {
            item: getLabel("RESEND_ACTION_LABEL")
          })
        },
        {
          buttonLabel: getLabel("BUTTON_CANCEL_LABEL"),
          onClick: hideModal
        }
      ]
    });
    showModal();
  };

  getSendVerificationEmailOption = () => {
    const { selectedOrganisation } = this.props;
    const { preferences } = selectedOrganisation;
    return preferences && preferences.sendVerificationEmails;
  };

  updateUserInviteAction = () => {
    const { hideModal, orgId, inviteId } = this.props;
    hideModal();
    navigate(
      `/${ORGANISATION_URL}/${orgId}/users/${inviteId}/${UPDATE_USER_INVITE_URL}`
    );
  };

  getHasPermission = (section, type) => {
    const { orgId, getHasPermission } = this.props;
    return getHasPermission(orgId, section, type);
  };

  getContextMenu = () => {
    const { getLabel } = this.props;
    const childLinks = [];
    if (this.getHasPermission(INVITES_SECTION, UPDATE_PERMISSION)) {
      childLinks.push(
        ...[
          {
            isDisabled: false,
            onClick: this.showResendUserInviteConfirmation,
            buttonLabel: getLabel("INVITATION_ACTION_LABEL", {
              item: getLabel("RESEND_ACTION_LABEL")
            })
          },
          {
            isDisabled: false,
            onClick: this.updateUserInviteAction,
            buttonLabel: getLabel("INVITATION_ACTION_LABEL", {
              item: getLabel("UPDATE_ACTION_LABEL")
            })
          }
        ]
      );
    }
    if (this.getHasPermission(INVITES_SECTION, DELETE_PERMISSION)) {
      childLinks.push({
        isDisabled: false,
        onClick: this.showRemoveInvitationConfirmation,
        buttonLabel: getLabel("INVITATION_ACTION_LABEL", {
          item: getLabel("DELETE_ACTION_LABEL")
        })
      });
    }
    return {
      childLinks,
      isDisabled: false,
      visible: childLinks.length ? true : false
    };
  };

  getInvitedUserDetails = () => {
    const { getLabel } = this.props;
    const selectedInvitedUser = this.selectedInvitedUser();

    if (selectedInvitedUser && Object.keys(selectedInvitedUser).length === 0)
      return;

    const invitedUser = {
      ...selectedInvitedUser,
      roleTitle: selectedInvitedUser.membershipData.roleTitle
    };

    const details = [
      { value: "status", label: getLabel("FORM_STATUS_LABEL") },
      { value: "email", label: getLabel("INVITED_USER_EMAIL_LABEL") },
      {
        value: "invitedByFirstName",
        label: getLabel("INVITED_USER_INVITED_BY_LABEL")
      },
      {
        value: "invitedDate",
        label: getLabel("INVITED_USER_INVITED_DATE_LABEL")
      },
      {
        value: "inviteLastSentDate",
        label: getLabel("INVITED_USER_LAST_SENT_DATE_LABEL")
      },
      { value: "expiryDate", label: getLabel("INVITATION_EXPIRY_DATE_LABEL") },
      { value: "roleTitle", label: getLabel("ROLE_TEXT") }
    ];

    return details
      .filter((item) => {
        return !!invitedUser[item.value] && invitedUser[item.value] !== "";
      })
      .map((item) => {
        const userDetails = invitedUser[item.value];
        const userItem =
          userDetails.includes("GMT") || item.value === "expiryDate"
            ? moment(userDetails).format("DD MMMM YYYY")
            : userDetails;
        return (
          <P styleType={"descriptionText"} key={item.value}>
            {item.label}: {userItem}
          </P>
        );
      })
      .filter((item) => item);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: BUTTON_TYPE_SECONDARY,
        isDisabled: false,
        onClick: this.returnToOverviewAction,
        buttonLabel: getLabel("BACK_TO_OVERVIEW_BUTTON", {
          item: getLabel("USERS_TEXT")
        })
      }
    ];
  };

  setFilterText = (type, value) => {
    const filterText = `${type}FilterText`;
    const pageNumber = `${type}PageNumber`;
    this.setState({
      [filterText]: value,
      [pageNumber]: 1
    });
  };

  getFilterText = (type) => {
    return this.state[`${type}FilterText`];
  };

  getNoResultsText = (type) => {
    const { getLabel } = this.props;
    const filterText = this.getFilterText(type);
    if (filterText)
      return getLabel("NO_MATCHING_RESULTS_FOR_FILTER", {
        filterText
      });
    return getLabel("NO_RESULTS_LABEL");
  };

  getFilterPlaceholderText = (type) => {
    const { getLabel } = this.props;
    return getLabel(
      type === PROPERTY_GROUP_TEXT
        ? "PROPERTY_GROUP_FILTER_PLACEHOLDER_LABEL"
        : "PROPERTIES_FILTER_PLACEHOLDER_LABEL"
    );
  };

  getFilterLabel = (type) => {
    const { getLabel } = this.props;
    return getLabel("GENERIC_ACTION_MESSAGE", {
      action: getLabel("FILTER_ACTION_LABEL"),
      item: getLabel(
        type === PROPERTY_GROUP_TEXT
          ? "PROPERTY_GROUPS_TEXT"
          : "PROPERTIES_TEXT"
      )
    });
  };

  getPropertyGroupsTableData = (type) => {
    const { orgId } = this.props;
    const invitedUser = this.selectedInvitedUser();
    if (!invitedUser) return [];
    const {
      membershipData: { propertyGroups }
    } = invitedUser;
    if (propertyGroups.length === 0) return [];
    const groups = filterList(propertyGroups, this.getFilterText(type));
    return groups.map((group) => {
      const title = this.getHasPermission(
        PROPERTY_GROUPS_SECTION,
        READ_PERMISSION
      ) ? (
        <LinkButton
          url={`/${orgId}/${PROPERTY_GROUPS_URL}/${group.groupId}`}
          buttonLabel={group.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      ) : (
        group.title
      );

      return {
        cells: [title]
      };
    });
  };

  getPropertiesTableData = (type) => {
    const { orgId } = this.props;
    const invitedUser = this.selectedInvitedUser();
    if (!invitedUser) return [];
    const {
      membershipData: { properties }
    } = invitedUser;
    if (properties.length === 0) return [];
    const propertiesList = filterList(properties, this.getFilterText(type));
    return propertiesList.map((prop) => {
      const title = this.getHasPermission(
        PROPERTIES_SECTION,
        READ_PERMISSION
      ) ? (
        <LinkButton
          url={`/${orgId}/${PROPERTIES_URL}/${prop.propId}`}
          buttonLabel={prop.title}
          buttonStyleType={DATA_TABLE_BUTTON_TITLE_STYLING}
        />
      ) : (
        prop.title
      );

      return {
        cells: [title]
      };
    });
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        {this.isInvitedUserDetailsLoading() === true ? (
          <Loader fullPage={false} />
        ) : (
          <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
            <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
              <ContextHeader
                headerTitle={this.getInvitedUserName()}
                contextMenu={this.getContextMenu()}
              />
            </Container>
            <Section>
              <Heading3 styleType={SECTION_HEADER_STYLING}>
                {getLabel("INVITED_USER_LABEL", {
                  item: getLabel("DETAILS_TEXT")
                })}
              </Heading3>
              {this.getInvitedUserDetails()}
            </Section>
            <Section styleType={DATA_TABLE_SECTION_STYLING}>
              <DataTable
                noResultsText={this.getNoResultsText(PROPERTY_GROUP_TEXT)}
                title={getLabel("INVITED_USER_LABEL", {
                  item: getLabel("USER_PROPERTY_GROUP_MEMBERSHIP_HEADING_TEXT")
                })}
                tableFilter={{
                  filterLabel: this.getFilterLabel(PROPERTY_GROUP_TEXT),
                  showFilter: true,
                  filterFunction: (e) =>
                    this.setFilterText(PROPERTY_GROUP_TEXT, e),
                  placeholderText:
                    this.getFilterPlaceholderText(PROPERTY_GROUP_TEXT),
                  inputId: this.getFilterLabel(PROPERTY_GROUP_TEXT)
                }}
                header={{
                  cells: [{ content: getLabel("PROPERTY_GROUPS_TEXT") }]
                }}
                rows={this.getPropertyGroupsTableData(PROPERTY_GROUP_TEXT)}
              />
            </Section>

            <Section styleType={DATA_TABLE_SECTION_STYLING}>
              <DataTable
                noResultsText={this.getNoResultsText(PROPERTY_TEXT)}
                title={getLabel("INVITED_USER_LABEL", {
                  item: getLabel("USER_PROPERTY_MEMBERSHIP_HEADING_TEXT")
                })}
                tableFilter={{
                  filterLabel: this.getFilterLabel(PROPERTY_TEXT),
                  showFilter: true,
                  filterFunction: (e) => this.setFilterText(PROPERTY_TEXT, e),
                  placeholderText: this.getFilterPlaceholderText(PROPERTY_TEXT),
                  inputId: this.getFilterLabel(PROPERTY_TEXT)
                }}
                header={{
                  cells: [{ content: getLabel("PROPERTIES_TEXT") }]
                }}
                rows={this.getPropertiesTableData(PROPERTY_TEXT)}
              />
            </Section>
            <BottomButtonBar buttons={this.getButtons()} />
          </Wrapper>
        )}
      </Container>
    );
  }
}

export default InvitedUserDetails;
