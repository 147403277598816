import React, { Component } from "react";
import { ContextHeader, BottomButtonBar, Loader } from "@UIKit";
import {
  CONTENT_VIEW,
  CONTEXT_HEADER_CONTAINER,
  STYLETYPE_FORM_FIELD,
  FIELD_SIZE_LARGE,
  STYLETYPE_TEXT,
  ORGANISATION_URL,
  RENDERERS_URL,
  STATUS_CREATING,
  STATUS_READY,
  HEIGHT_AUTO_TEXT
} from "@base/constants";
import { Container, Wrapper, TextArea, Section, FormLabel } from "@core";
import { navigate } from "@reach/router";

class AddRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rendererDetails: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedOrgCreateRendererStatus } = this.props;
    if (
      prevProps.selectedOrgCreateRendererStatus === STATUS_CREATING &&
      selectedOrgCreateRendererStatus === STATUS_READY
    ) {
      this.showSuccess();
    }
  }

  componentWillUnmount() {
    this.abortRequests();
  }

  abortRequests = () => {
    const { abortController, setAbortController } = this.props;
    if (!abortController) return;
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
  };

  callCreateRenderer = async () => {
    try {
      const { showModal, orgId } = this.props;
      const rendererDetails = this.getValue("rendererDetails");
      const { createRenderer } = this.props;
      this.showLoading();
      showModal();
      await createRenderer(orgId, rendererDetails);
    } catch (e) {
      throw new Error(e);
    }
  };

  createHeaderText = () => {
    const { getLabel } = this.props;
    return getLabel("CREATE_NEW_LABEL", {
      item: getLabel("RENDERER_TEXT")
    });
  };

  getValue = (field) => {
    if (
      field === null ||
      field === undefined ||
      this.state[field] === undefined
    )
      return false;
    return this.state[field];
  };

  setValue = (value, field) => {
    this.setState({
      [field]: value
    });
  };

  returnToOverviewAction = () => {
    const { orgId } = this.props;
    navigate(`/${ORGANISATION_URL}/${orgId}/${RENDERERS_URL}/`);
  };

  getButtons = () => {
    const { getLabel } = this.props;
    return [
      {
        buttonStyleType: "primary",
        isDisabled: !this.getValue("rendererDetails"),
        onClick: this.callCreateRenderer,
        buttonLabel: getLabel("ADD_NEW_LABEL", {
          item: getLabel("RENDERER_TEXT")
        })
      },
      {
        buttonStyleType: "secondary",
        isDisabled: false,
        onClick: this.returnToOverviewAction,
        buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
          item: getLabel("RENDERER_TEXT")
        })
      }
    ];
  };

  showLoading = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ACTION_MESSAGE", {
        action: getLabel("CREATING_LABEL"),
        item: getLabel("RENDERERS_TEXT")
      }),
      body: <Loader fullPage={false} height={HEIGHT_AUTO_TEXT} />,
      buttons: []
    });
    return null;
  };

  showSuccess = () => {
    const { setModalContent, getLabel } = this.props;
    setModalContent({
      showCloseButton: false,
      header: getLabel("ITEM_CREATED_LABEL", {
        item: getLabel("RENDERERS_TEXT")
      }),
      body: getLabel("ITEM_CREATED_LABEL", {
        item: getLabel("RENDERERS_TEXT")
      }),
      buttons: [
        {
          buttonLabel: getLabel("RETURN_TO_OVERVIEW", {
            item: getLabel("RENDERERS_TEXT")
          }),
          onClick: this.returnToOverviewAction
        }
      ]
    });
    return null;
  };

  render() {
    const { getLabel } = this.props;
    return (
      <Container styleType={CONTENT_VIEW}>
        <Wrapper data-name={"Wrapper"} styleType={CONTENT_VIEW}>
          <Container direction={"row"} styleType={CONTEXT_HEADER_CONTAINER}>
            <ContextHeader
              headerTitle={this.createHeaderText()}
              contextMenu={{ visible: false }}
            />
          </Container>
          <Container>
            <Section>
              <Container styleType={STYLETYPE_FORM_FIELD}>
                <FormLabel>
                  {getLabel("ORGANISATION_RENDERER_JSON_LABEL")}
                </FormLabel>
                <TextArea
                  value={this.getValue("rendererDetails")}
                  onChange={(value) => {
                    this.setValue(value, "rendererDetails");
                  }}
                  id="renderer-details"
                  size={FIELD_SIZE_LARGE}
                  styleType={STYLETYPE_TEXT}
                  maxLength={100000000}
                />
              </Container>
            </Section>
          </Container>
          <BottomButtonBar buttons={this.getButtons()} />
        </Wrapper>
      </Container>
    );
  }
}

export default AddRenderer;
