import React from "react";
import PropTypes from "prop-types";
import { LISTCONTENT_ITEM_LIST_ID } from "@base/constants";
import { Container } from "@core";
import { ListContentChildItemSpan } from "../style";

/**
 * Returns a array of span
 * @public
 */
const renderChildren = (childLinks) => {
  if (!childLinks) return;

  return childLinks
    .filter((item) => {
      return item.buttonLabel && item.buttonLabel !== "";
    })
    .map((item, index) => {
      return (
        <ListContentChildItemSpan key={index}>
          {item.buttonLabel}
        </ListContentChildItemSpan>
      );
    });
};

/**
 * Renders a list of buttons on the right hand side of a ListContentItem
 */

const ListContentItemList = (props) => {
  return (
    <Container
      data-name={"ListContentItemList"}
      styleType={LISTCONTENT_ITEM_LIST_ID}
    >
      {renderChildren(props.childLinks)}
    </Container>
  );
};

ListContentItemList.propTypes = {
  /** An array of children to render as buttons */
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      /** The label for the childs button  */
      buttonLabel: PropTypes.string,
      /** The link for the childs button (optional) if not supplied, then button will be disabled and render just a text  */
      onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      /** The icon for the childs button (optional) if ommitted, link will be rendered without an icon */
      iconType: PropTypes.string
    })
  )
};

export default ListContentItemList;
