import { connect } from "react-redux";
import UpdateUserInvite from "./UpdateUserInvite";
import * as actions from "../../../actions";
import {
  getLabels,
  getPageTitle,
  getAbortController,
  getInvitedUser,
  getInvitedUserLoading,
  getSelectedUserStatus,
  getSelectedOrg,
  getOrgsStatus,
  getSelectedOrgRoles,
  getSelectedOrgRolesLoading,
  getSelectedOrgProperties,
  getSelectedOrgPropertiesLoading,
  getSelectedOrgPropertyGroups,
  getSelectedOrgPropertyGroupsLoading,
  getSelectedOrgPropertiesTotal,
  getOrgPropertiesPageNumber,
  getOrgPropertiesOffset
} from "../../../selectors";
import { STATUS_LOADING } from "../../../constants";

export default connect(
  (state) => ({
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    abortController: getAbortController(state),
    selectedInvitedUser: getInvitedUser(state),
    selectedInvitedUserStatus: getSelectedUserStatus(state),
    selectedOrgStatus: getOrgsStatus(state),
    selectedOrganisationRoles: getSelectedOrgRoles(state),
    selectedOrganisationRolesLoading: getSelectedOrgRolesLoading(state),
    selectedOrganisation: getSelectedOrg(state),
    selectedOrganisationProperties: getSelectedOrgProperties(state),
    selectedOrganisationPropertiesLoading:
      getSelectedOrgPropertiesLoading(state),
    selectedOrgPropertyGroups: getSelectedOrgPropertyGroups(state),
    selectedOrgPropertyGroupsLoading:
      getSelectedOrgPropertyGroupsLoading(state),
    selectedOrgPropertiesTotal: getSelectedOrgPropertiesTotal(state),
    selectedOrgPropertiesPageNumber: getOrgPropertiesPageNumber(state),
    selectedOrgPropertiesOffset: getOrgPropertiesOffset(state),
    selectedInvitedUserLoading: getInvitedUserLoading(state),
    userInviteDetailsLoaded:
      getOrgsStatus(state) !== STATUS_LOADING &&
      !getInvitedUserLoading(state) &&
      !getSelectedOrgRolesLoading(state) &&
      !getSelectedOrgPropertiesLoading(state) &&
      !getSelectedOrgPropertyGroupsLoading(state)
  }),
  actions
)(UpdateUserInvite);
