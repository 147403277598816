import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Container } from "@core";
import { defaultTheme } from "@UIKit";
import {
  ACCORDION_ROW_STYLING,
  ACCORDION_CONTENT_STYLING,
  STYLE_TYPE_BUTTON_CELL,
  STYLE_TYPE_DATA_TABLE_ROW
} from "@base/constants";
import { Tbody, Tr, Td } from "../style";

class DataTableRows extends Component {
  constructor(props) {
    super(props);
  }
  static propTypes = {
    /** rows return array */
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        /** An array of cells */
        cells: PropTypes.array,
        /** Content that shows when the row is expanded */
        content: PropTypes.any
      })
    ),
    /** innerStyleType return string */
    innerStyleType: PropTypes.string,
    /** unique id of the table */
    id: PropTypes.string
  };

  getRows = () => {
    const { rows } = this.props;
    return rows || [];
  };

  showButtonCell = () => {
    return this.getRows().some((item) => item.content);
  };

  render() {
    return (
      <Tbody innerStyleType={this.props.innerStyleType}>
        {this.getRows().map((row, index) => (
          <React.Fragment key={`${this.props.id}-row-${index}`}>
            <Tr
              innerStyleType={row.innerStyleType}
              backgroundColor={
                index % 2 ? defaultTheme.agWhite : defaultTheme.agSmokeWhite
              }
            >
              {this.showButtonCell() && (
                <Td styleType={STYLE_TYPE_BUTTON_CELL}>
                  <Container
                    direction={"row"}
                    styleType={ACCORDION_ROW_STYLING}
                    backgroundStyle={row.content ? true : false}
                  >
                    {row.content && (
                      <Button
                        buttonLabel={""}
                        isDisabled={row.content ? false : true}
                        onClick={() => row.handleUpdateRowExpanded(row.id)}
                        key={"toggleRow"}
                        buttonStyleType={STYLE_TYPE_DATA_TABLE_ROW}
                        icon={
                          row.content
                            ? {
                                type: "down-arrow",
                                bgWidth: "23px",
                                bgHeight: "23px",
                                iconHeight: "23px",
                                iconWidth: "23px",
                                bgColor: defaultTheme.agWhite,
                                iconColor: defaultTheme.agBrightGreen,
                                rotation: row.expanded === true ? 360 : 270
                              }
                            : {}
                        }
                        ariaLabel={
                          row.expanded === true
                            ? "Collapse items"
                            : "Expand items"
                        }
                        ariaControls={row.id}
                      />
                    )}
                  </Container>
                </Td>
              )}
              {row.cells &&
                row.cells.map((cell, i) => (
                  <Td key={`${this.props.id}-row-${index}-cell-${i}`}>
                    {cell}
                  </Td>
                ))}
            </Tr>
            {row.expanded && (
              <Tr
                expanded={row.expanded}
                styleType={ACCORDION_CONTENT_STYLING}
                backgroundColor={
                  index % 2 ? defaultTheme.agWhite : defaultTheme.agSmokeWhite
                }
              >
                <Td styleType={"no-border"}></Td>
                <Td colSpan={row.cells.length}>{row.content}</Td>
              </Tr>
            )}
          </React.Fragment>
        ))}
      </Tbody>
    );
  }
}

export default DataTableRows;
