import { connect } from "react-redux";
import AddDatasets from "./AddDatasets";
import * as actions from "../../../actions";
import {
  getLabels,
  getPageTitle,
  getSelectedOrgDatasets,
  getSelectedOrgDatasetsLoading,
  getAbortController,
  getHasPermission,
  getDatasetTemplates,
  getDatasetTemplatesStatus,
  getSelectedOrgCreateDatasetStatus,
  getToken
} from "../../../selectors";
import { STATUS_LOADING } from "@base/constants";

export default connect(
  (state) => ({
    propState: state,
    pageTitle: getPageTitle(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    abortController: getAbortController(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType),
    selectedOrgDatasets: getSelectedOrgDatasets(state),
    selectedOrgDatasetsLoading: getSelectedOrgDatasetsLoading(state),
    datasetTemplates: getDatasetTemplates(state),
    datasetTemplatesLoading:
      getDatasetTemplatesStatus(state) === STATUS_LOADING,
    token: getToken(state),
    selectedOrgCreateDatasetStatus: getSelectedOrgCreateDatasetStatus(state)
  }),
  actions
)(AddDatasets);
