import { navigate } from "@reach/router";
import React, { Component } from "react";
import { ORGANISATION_URL, USERS_URL } from "@base/constants";

class Error extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    const { errorMessage } = this.props;
    if (errorMessage && errorMessage !== prevProps.errorMessage) {
      this.showErrorModal();
    }
  }

  getCloseButtonLabel = () => {
    const { getLabel } = this.props;
    const { pathname } = window.location;
    return getLabel(pathname === "/" ? "SIGN_OUT_LABEL" : "GO_BACK_LABEL");
  };

  showErrorModal = () => {
    const { errorMessage, getLabel, setModalContent, showModal } = this.props;
    setModalContent({
      showCloseButton: true,
      header: getLabel("GENERIC_ERROR_HEADING"),
      body: errorMessage,
      buttons: [
        {
          buttonLabel: this.getCloseButtonLabel(),
          onClick: this.hideErrorModal
        }
      ]
    });
    showModal();
    return null;
  };

  signOut = () => {
    const { invalidateAuthentication } = this.props;
    invalidateAuthentication();
  };

  navigateToPreviousView = () => {
    const { pathname } = window.location;
    let newPath = pathname.charAt(pathname.length - 1) === "/" ? "../" : "./";
    const paths = pathname.split("/").filter((item) => item);
    if (
      paths[0] === USERS_URL ||
      (paths[0] === ORGANISATION_URL && paths.length === 2)
    ) {
      newPath = "/";
    }
    navigate(newPath);
  };

  hideErrorModal = () => {
    const { pathname } = window.location;
    const { hideModal, setError } = this.props;
    hideModal();
    setError({ message: "" });
    return pathname === "/" ? this.signOut() : this.navigateToPreviousView();
  };

  render() {
    return <div style={{ padding: "1em 1.2em" }}>{this.props.children}</div>;
  }
}

export default Error;
