import styled, { css } from "styled-components";
import { Heading2Element } from "@typography/Heading2/style";
import { ButtonElement } from "../../core/Button/style";

// @ts-ignore

export const ContextHeaderElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 1rem;
  width: 100%;

  ${Heading2Element} {
    flex-grow: 100;
    font-weight: 300;
    line-height: ${(props) => props.theme.lineHeightBase};
    margin-bottom: 0rem;
    border-bottom: none;
    font-size: clamp(0.9rem, 3vw, 3rem);
  }

  @media (max-width: 790px) {
    flex-wrap: wrap;
    margin-bottom: 3rem;
  }

  ${ButtonElement} {
    margin-left: 0.938rem;
  }
`;
