export default (API_URL, API_KEY) => ({
  createOrganisation: (data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "POST",
        body: data,
        redirect: "follow",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  createProperty: (orgId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/properties/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "POST",
        body: data,
        redirect: "follow",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  createProperties: (orgId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/properties/organisations/${orgId}/bulk`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "POST",
        body: data,
        redirect: "follow",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  createWorkflow: (orgId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/workflows/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "POST",
        body: data,
        redirect: "follow",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  createRenderer: (orgId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/organisations/${orgId}/renderers`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        method: "POST",
        body: JSON.stringify(data),
        redirect: "follow",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  createRole: (orgId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/roles/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "POST",
        body: JSON.stringify(data),
        redirect: "follow",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  createPropertyGroup: (orgId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/propertyGroups/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "POST",
        body: data,
        redirect: "follow",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  requestCreatePropertyStatus: (orgId, propId, jobId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/properties/${propId}/organisations/${orgId}/jobs/${jobId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          method: "GET",
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  requestCreatePropertiesStatus: (orgId, jobId, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/jobs/organisations/${orgId}/jobs/${jobId}/`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${token}`
        },
        method: "GET",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  createUserInvite: (orgId, body, sendEmail, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/invites/organisations/${orgId}?sendEmail=${sendEmail}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },

          method: "POST",
          body,
          redirect: "follow",
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  resendUserInvite: (orgId, inviteId, sendEmail, body, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/invites/${inviteId}/organisations/${orgId}?sendEmail=${sendEmail}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          method: "POST",
          body,
          redirect: "follow",
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  addFeaturesToLayer: (orgId, dataSet, layerId, body, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_URL}/dataSets/${dataSet}/organisations/${orgId}/FeatureServer/${layerId}/applyEdits`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`
          },
          method: "POST",
          body,
          redirect: "follow",
          signal
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  },
  createDataset: (orgId, data, token, signal) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/dataSets/organisations/${orgId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        method: "POST",
        body: data,
        redirect: "follow",
        signal
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.message) reject(new Error(result.message));
          return resolve(result);
        })
        .catch((e) => reject(e));
    });
  }
});
