import { connect } from "react-redux";
import RendererDetail from "./RendererDetail";
import * as actions from "../../../actions";
import {
  getAbortController,
  getLabels,
  getSelectedOrgRendererLoading,
  getSelectedOrgRenderer,
  getHasPermission
} from "../../../selectors";

export default connect(
  (state) => ({
    abortController: getAbortController(state),
    getLabel: (stringConstant, data) =>
      getLabels(state, { stringConstant: stringConstant, data: data }),
    selectedOrganisationRenderer: getSelectedOrgRenderer(state),
    selectedOrganisationRendererLoading: getSelectedOrgRendererLoading(state),
    getHasPermission: (orgId, section, permissionType) =>
      getHasPermission(state, orgId, section, permissionType)
  }),
  actions
)(RendererDetail);
