import React, { Component } from "react";
import { Container, Button } from "@core";
import { CSSTransition } from "react-transition-group";
import { CONTENT_FADE_TIMEOUT, CONTEXT_MENU_ID } from "@base/constants";
import PropTypes from "prop-types";
import { defaultTheme } from "@UIKit";
import ContextMenuButtonList from "./subComponents/ContextMenuButtonList";

/**
 * The context menu provides a way to provide a list of links in a dropdown.
 The element is made up of a button that reveals or hides a list of buttons.
 */
class ContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false
    };
  }
  static propTypes = {
    /** Whether the context menu is visible or not */
    visible: PropTypes.bool,
    /** Whether the context menu is disabled or not */
    isDisabled: PropTypes.bool,
    /** An array of children to render as buttons */
    childLinks: PropTypes.arrayOf(
      PropTypes.shape({
        /** Whether the child button is disabled or not */
        isDisabled: PropTypes.bool,
        /** The label for the childs button  */
        buttonLabel: PropTypes.string,
        /** The link for the childs button (optional) if not supplied, then button will be disabled and render just a text  */
        onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        /** The icon for the childs button (optional) if ommitted, link will be rendered without an icon */
        iconType: PropTypes.string
      })
    )
  };

  static defaultProps = {
    visible: true,
    isDisabled: false
  };

  /**
   * Returns the childLinks property
   * @public
   */
  getChildLinks = () => {
    const { childLinks } = this.props;
    return childLinks && childLinks.length
      ? childLinks.map((item) => ({
          ...item,
          onClick: () => this.handleClickItem(item)
        }))
      : false;
  };

  handleClickItem = (item) => {
    if (item.onClick) item.onClick();
    this.toggleOpen();
  };

  /**
   * Toggles the state of the open button
   * @private
   */
  toggleOpen = () => {
    const { openMenu } = this.state;
    this.setState({
      openMenu: !openMenu
    });
  };

  /**
   * Returns the open state of the menu
   * @private
   */
  getOpen = () => {
    const { openMenu } = this.state;
    return openMenu;
  };

  /**
   * Returns the disabled state of the menu
   * @private
   */
  getDisabled = () => {
    const { isDisabled } = this.props;
    return !!isDisabled;
  };

  render() {
    return (
      <Container styleType={CONTEXT_MENU_ID}>
        <Button
          buttonStyleType={CONTEXT_MENU_ID}
          isDisabled={this.getDisabled()}
          onClick={this.toggleOpen}
          buttonLabel={""}
          icon={{
            type: "context-menu",
            bgWidth: "30px",
            bgHeight: "30px",
            iconHeight: "20px",
            iconWidth: "20px",
            iconColor: defaultTheme.agCommonBlack
          }}
        />
        <CSSTransition
          in={this.getOpen()}
          timeout={CONTENT_FADE_TIMEOUT}
          classNames="css-transition"
          unmountOnExit
        >
          <ContextMenuButtonList
            childLinks={this.getChildLinks()}
          ></ContextMenuButtonList>
        </CSSTransition>
      </Container>
    );
  }
}

export default ContextMenu;
