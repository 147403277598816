import React, { Component } from "react";
import { AccordionWrapper } from "./style";
import PropTypes from "prop-types";
import { P, Heading3 } from "@typography";
import DataTableHeader from "./subComponents/DataTableHeader";
import DataTableRows from "./subComponents/DataTableRows";
import { Table, Thead, TableTextWrapper, TableTextContainer } from "./style";
import { Container } from "@core";
import {
  DATA_TABLE_HEADING_STYLING,
  DATA_TABLE_FILTER_INPUT_STYLING,
  DATA_TABLE_PAGINATION_STYLING,
  DATA_TABLE_TITLE_CONTAINER_STYLING,
  HEIGHT_AUTO_TEXT
} from "@base/constants";
import { FilterInput, Dropdown, PaginationV2, Loader } from "@UIKit";

class DataTable extends Component {
  state = { width: window.innerWidth };

  static propTypes = {
    /** header data for the table */
    header: PropTypes.shape({
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.any,
          sort: PropTypes.shape({
            /** accessible label text for the button */
            label: PropTypes.string,
            /** the sort button direction */
            direction: PropTypes.string,
            /** Called on click of sort button */
            onClick: PropTypes.func
          })
        })
      ),
      /** setToggleOpen function toggles button */
      setToggleOpen: PropTypes.func,
      /** toggleOpen return true or false */
      toggleOpen: PropTypes.bool,
      /** showToggle return true or false */
      showToggle: PropTypes.bool
    }),
    /** rows for the table */
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        /** an array of cells for the row */
        cells: PropTypes.array,
        /** content to go inside the expanded accordian */
        content: PropTypes.any
      })
    ),
    title: PropTypes.string,
    /** pagination returns object */
    pagination: PropTypes.object,
    /** pageDropDown returns object */
    pageDropDown: PropTypes.object,
    /** styleType returns string */
    styleType: PropTypes.string,
    /** innerStyleType returns string */
    innerStyleType: PropTypes.string,
    /** A unique id for the table */
    id: PropTypes.string,
    /** Text that describes results counts */
    resultsSubtext: PropTypes.string,
    /** Whether or not to show the results subtext */
    showResultsText: PropTypes.bool,
    tableFilter: PropTypes.shape({
      filterFunction: PropTypes.func,
      filterLabel: PropTypes.string,
      isDisabled: PropTypes.bool,
      showFilter: PropTypes.bool,
      inputId: PropTypes.string
    }),
    /** Indicates whether the table content is loading or not */
    loading: PropTypes.bool,
    /** Text that shows if no rows in the table */
    noResultsText: PropTypes.string,
    /**The component to use for the table title */
    TitleComponent: PropTypes.any
  };
  static defaultProps = {
    TitleComponent: Heading3
  };

  updateWindowDimensions = () => {
    if (window.innerWidth > 750) {
      this.setState({ width: window.innerWidth });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  getAccordionHeader = () => {
    const { header } = this.props;
    return header || null;
  };

  setToggleOpen = () => {
    const { setToggleOpen } = this.getAccordionHeader();
    const rows = this.getAccordionRows();
    if (!rows) return;
    return setToggleOpen(rows);
  };

  getAccordionRows = () => {
    const { rows } = this.props;
    return rows || [];
  };

  getTableTitle = () => {
    const { title } = this.props;
    return title || "";
  };

  getTableResultsSubtext = () => {
    const { resultsSubtext } = this.props;
    return resultsSubtext || "";
  };

  getTableShowResultsText = () => {
    const { showResultsText, loading } = this.props;
    if (loading) return false;
    return showResultsText || false;
  };

  getTableFilterContent = () => {
    const { tableFilter } = this.props;
    return tableFilter || null;
  };

  setFilterText = (event) => {
    const tableFilter = this.getTableFilterContent();
    if (!tableFilter) return false;
    const { filterFunction } = tableFilter;
    return filterFunction ? filterFunction(event) : null;
  };

  getFilterLabel = () => {
    const tableFilter = this.getTableFilterContent();
    if (!tableFilter) return "";
    const { filterLabel } = tableFilter;
    return filterLabel ? filterLabel : "";
  };

  getIsDisabled = () => {
    const tableFilter = this.getTableFilterContent();
    if (!tableFilter) return false;
    const { isDisabled } = tableFilter;
    return isDisabled ? isDisabled : false;
  };

  getShowFilter = () => {
    const tableFilter = this.getTableFilterContent();
    if (!tableFilter) return false;
    const { showFilter } = tableFilter;
    return showFilter ? showFilter : false;
  };

  getInputId = () => {
    const tableFilter = this.getTableFilterContent();
    if (!tableFilter) return false;
    const { inputId } = tableFilter;
    return inputId ? { inputId: `${inputId}-input` } : null;
  };

  getPageDropDownContent = () => {
    const { pageDropDown } = this.props;
    return pageDropDown ? pageDropDown : {};
  };

  showDropdown = () => {
    const pageDropDown = this.getPageDropDownContent();
    const { showDropdown } = pageDropDown;
    return showDropdown ? showDropdown : false;
  };

  getDropDownValue = () => {
    const pageDropDown = this.getPageDropDownContent();
    const { dropDownValue } = pageDropDown;
    return dropDownValue ? dropDownValue : "";
  };

  getDropDownLabel = () => {
    const pageDropDown = this.getPageDropDownContent();
    const { dropDownLabel } = pageDropDown;
    return dropDownLabel ? dropDownLabel : "";
  };

  getDropDownOptions = () => {
    const pageDropDown = this.getPageDropDownContent();
    const { dropDownOptions } = pageDropDown;
    return dropDownOptions ? dropDownOptions : null;
  };

  dropDownFunction = (value) => {
    const pageDropDown = this.getPageDropDownContent();
    const { dropDownOnSelectFunction } = pageDropDown;
    return dropDownOnSelectFunction
      ? dropDownOnSelectFunction(value.value)
      : null;
  };

  getPaginationContent = () => {
    const { pagination } = this.props;
    return pagination ? pagination : {};
  };

  getShowPagination = () => {
    const pagination = this.getPaginationContent();
    const { showPagination } = pagination;
    return showPagination ? showPagination : false;
  };

  getPaginationNumberOfButtons = () => {
    const pagination = this.getPaginationContent();
    const { numberOfButtons } = pagination;
    return numberOfButtons ? numberOfButtons : 5;
  };

  getPaginationTotalResults = () => {
    const pagination = this.getPaginationContent();
    const { totalResults } = pagination;
    return totalResults ? totalResults : 5;
  };

  getPaginationActiveNumber = () => {
    const pagination = this.getPaginationContent();
    const { activeNumber } = pagination;
    return activeNumber ? activeNumber : 1;
  };

  showPaginationInput = () => {
    const pagination = this.getPaginationContent();
    const { paginationInput } = pagination;
    return paginationInput ? paginationInput : false;
  };

  setPaginationContent = (page) => {
    const pagination = this.getPaginationContent();
    const { setPaginationContent } = pagination;
    return setPaginationContent ? setPaginationContent(page) : null;
  };
  getLoading = () => {
    const { loading } = this.props;
    return loading;
  };

  getFilterPlaceholderText = () => {
    const tableFilter = this.getTableFilterContent();
    if (!tableFilter) return "";
    const { placeholderText } = tableFilter;
    return placeholderText || "";
  };

  getNoResultsText = () => {
    const { noResultsText } = this.props;
    return noResultsText || "No results";
  };

  isSmallScreen = () => {
    const { width } = this.state;
    return width < 1050;
  };

  render() {
    const { TitleComponent } = this.props;
    const isSmallScreen = this.isSmallScreen();
    return (
      <AccordionWrapper>
        <Container
          direction={"row"}
          styleType={DATA_TABLE_TITLE_CONTAINER_STYLING}
        >
          {this.getTableTitle() && (
            <TitleComponent styleType={DATA_TABLE_HEADING_STYLING}>
              {this.getTableTitle()}
            </TitleComponent>
          )}
          {this.getTableShowResultsText() && !isSmallScreen && (
            <P>{this.getTableResultsSubtext()}</P>
          )}
          {this.getShowFilter() && (
            <FilterInput
              {...this.getInputId()}
              label={this.getFilterLabel()}
              liveFilter={true}
              isDisabled={this.getIsDisabled()}
              placeholder={this.getFilterPlaceholderText()}
              filterFunction={(event) => {
                this.setFilterText(event);
              }}
              styleType={DATA_TABLE_FILTER_INPUT_STYLING}
            />
          )}
        </Container>
        {this.getTableShowResultsText() && isSmallScreen && (
          <Container>
            <P>{this.getTableResultsSubtext()}</P>
          </Container>
        )}
        {this.getLoading() ? (
          <Loader height={HEIGHT_AUTO_TEXT} />
        ) : this.getAccordionRows().length ? (
          <Table>
            <Thead>
              <DataTableHeader
                {...this.getAccordionHeader()}
                id={this.props.id}
              />
            </Thead>
            <DataTableRows
              rows={this.getAccordionRows()}
              innerStyleType={this.props.innerStyleType}
              id={this.props.id}
            />
          </Table>
        ) : (
          <TableTextWrapper>
            <TableTextContainer>
              <P>{this.getNoResultsText()}</P>
            </TableTextContainer>
          </TableTextWrapper>
        )}
        {(this.showDropdown() || this.getShowPagination()) && (
          <Container
            direction={"row"}
            styleType={DATA_TABLE_PAGINATION_STYLING}
          >
            {this.showDropdown() && (
              <Dropdown
                placeholder={"Select option"}
                onClick={this.dropDownFunction}
                label={this.getDropDownLabel()}
                value={this.getDropDownValue()}
                options={this.getDropDownOptions()}
              />
            )}
            {this.getShowPagination() && (
              <PaginationV2
                currentPageNumber={this.getPaginationActiveNumber()}
                listPerPageNum={this.getPaginationNumberOfButtons()}
                totalItems={this.getPaginationTotalResults()}
                setPaginationContent={this.setPaginationContent}
                showPaginationInput={this.showPaginationInput()}
              />
            )}
          </Container>
        )}
      </AccordionWrapper>
    );
  }
}

export default DataTable;
